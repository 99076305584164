import React, { ReactNode, MouseEvent } from "react";

interface ListViewProps {
    children?: ReactNode;
    datasource: any[];
    onItemClick?: (dataItem: any) => void;
    renderItem: (dataItem: any, index: number) => ReactNode;
}

export class ListView extends React.Component<ListViewProps> {
    constructor(props: ListViewProps) {
        super(props);
    }

    render() {
        const {children, datasource, renderItem, onItemClick} = this.props;

        return (
            <>
                <ul className="list-group">
                    {datasource.map((dataItem, index) => {
                        return (
                            <li key={index} className={`list-group-item ${onItemClick && "list-group-item-action"}`}
                                onClick={(e: MouseEvent<HTMLElement>) => {
                                    if (onItemClick) {
                                        onItemClick(dataItem);
                                    }
                                }}>
                                {renderItem(dataItem, index)}
                            </li>
                        )
                    })}
                </ul>
            </>
        )
    }
}
