import React from 'react';

export const AdjustmentNotification = () => {

    return (
        <div className='p-3 position-fixed' style={{right: 0}}>
            <div
                className='toast'
                style={{'opacity': '1'}}
                role='alert'
                aria-live='assertive'
                aria-atomic='true'
            >
                <div className='mb-0 toast-body alert alert-info'>
                    <p className='mb-1'>
                        There are currently adjustments that need to be made to correct the batch
                    </p>
                    <button className='btn btn-primary'>View Adjustments</button>
                </div>
            </div>
        </div>
    );
}