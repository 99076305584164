import {TextField} from "../../ui/Input/TextField";
import React from "react";


export class AssetSearchInputForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        }
    }

    render() {
        const {onChange, label} = this.props;
        return <>
            <TextField label={label} value={this.state.value} type={"search"} onChange={(e) => {
                if (onChange) {
                    this.setState({value: e.target.value});
                    onChange(e.target.value);
                }
            }}/>
        </>
    }
}