import swal from 'sweetalert';

const confirmAlertDialogue = async (msg) => {
    let confirm = await swal({
        title: msg,
        text: "Are you sure?",
        buttons: {
            cancel: {
                text: "Cancel",
                value: false,
                visible: true,
                className: "btn btn-default class4"
            },
            confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "btn btn-primary ",
                closeModal: true
            }
        }
    })
    return confirm;
}

export default confirmAlertDialogue;