import React from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {bindActionCreators} from 'redux';
import * as AuthActions from '../../actions/authActions';
import WifiSetting from '../../components/SettingsPage/WifiSetting';

export class WifiSettingContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            update: true
        }

    }

    render() {
        const {authActions, auth} = this.props;
        return (
            <div className="app sidebar-mini rtl emp-sidebar">
                <div>
                    <Header
                        isLoggedIn={auth.isLoggedIn}
                        user={auth.user}
                        logout={authActions.logout}
                        headerclassName="app-header"
                    />
                    <Sidebar isLoggedIn={auth.isLoggedIn}
                             user={auth.user}
                             logout={authActions.logout}
                             headerclassName="app-header"
                    />
                </div>
                <WifiSetting/>
            </div>


        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WifiSettingContainer));
