import React from 'react';
import AreaTypeForm from "./AreaTypeForm";
import PropTypes from "prop-types";


export class AreaTypeEditDetailPage extends React.Component {
    render() {
        const {areaType} = this.props;

        return (
            <main className="app-content">
                <section className="emp_org_settings_sec emp_report_sec emp_circle_box_sec">
                    <div className="card emp-box emp-shadow">
                        <div className={"bg-white container-fluid mb-4"}>
                            <AreaTypeForm areaType={areaType} initialValues={areaType} onSubmit={this.props.onSubmit.bind(this)}/>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

AreaTypeEditDetailPage.propTypes = {
    areaType: PropTypes.object.isRequired,
    initialValues: PropTypes.object,
    onSubmit: PropTypes.func.isRequired
}