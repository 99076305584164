import React, {useEffect, useState, useRef, useCallback} from 'react';
import {GoogleMap, Marker, Polygon, useLoadScript} from "@react-google-maps/api";

import {AutocompleteCmp} from "./AutocompleteCmp";
import {DrawingCmp} from "./DrawingCmp";

const ZOOM_LEVEL = 15;
const MAP_CENTER = { lat: 34.035083, lng: -118.378808 };

const findPolygonCenter = (coordinates) => {
  const yCoordinates = coordinates.map(coord => coord.lng);
  const xCoordinates = coordinates.map(coord => coord.lat);
  const minX = Math.min(...xCoordinates);
  const maxX = Math.max(...xCoordinates);

  const minY = Math.min(...yCoordinates);
  const maxY = Math.max(...yCoordinates);

  const lat = minX + ((maxX - minX)/2);
  const lng = minY + ((maxY - minY)/2);
  
  return {lat,lng}
}

export function GeofenceMap(props){
    const [autoComplete, setAutoComplete] = useState(null);
    const [coordinates, setCoordinates] = useState([]);
    const [mapCoordinates, setMapCoordinates] = useState(MAP_CENTER);
    const [map, setMap] = useState({});
    const [autoCompleteMarker, setAutoCompleteMarker] = useState(null);
    const polygonRef = useRef(null);
    const listenersRef = useRef([]);

    useEffect(()=>{
      if (!props.conditions[0].coordinates) return;
      // if props already have coordinates, update the state so that it can be displayed on the map
      if (coordinates.length < props.conditions[0].coordinates.length){
        const convertedCoordinates = props.conditions[0].coordinates.map(coords => {
          return {lat: coords[0], lng: coords[1]}
        });
        setCoordinates(convertedCoordinates);
        setMapCoordinates(findPolygonCenter(convertedCoordinates));
      }

      const formCoordinates = convertToFormCoordinates(coordinates);
      props.onGeoFenceChange(formCoordinates);
    }, [coordinates, props.conditions[0].coordinates]);

    const onLoadPolygon = useCallback(
      polygon => {
        polygonRef.current = polygon;
        const path = polygon.getPath();
        listenersRef.current.push(
          path.addListener("set_at", onEditPolygon),
          path.addListener("insert_at", onEditPolygon),
          path.addListener("remove_at", onEditPolygon)
        );
      },
      [onEditPolygon]
    );

    const onEditPolygon = useCallback(() => {
      if (polygonRef.current) {
        const nextPath = returnCoordinatesFromPolygon(polygonRef.current);
        setCoordinates(nextPath);
      }
    }, [setCoordinates]);

    const returnCoordinatesFromPolygon = (polygon) => {
      return polygon
        .getPath()
        .getArray()
        .map(latLng => {
          return { lat: latLng.lat(), lng: latLng.lng() };
        });
    }

    const onPolygonComplete = useCallback(
      polygon => {
        // create a tmp array that holds current coordinates (if they exist)
        // this is neccessary cause useCallback does not have acces to up to date state 
        const coordinates = polygonRef.current ? returnCoordinatesFromPolygon(polygonRef.current) : [];
        polygonRef.current = polygon;
        const path = polygon.getPath();
        listenersRef.current.push(
          path.addListener("set_at", onEditPolygon),
          path.addListener("insert_at", onEditPolygon),
          path.addListener("remove_at", onEditPolygon)
        );

        const currentCoordinates = returnCoordinatesFromPolygon(polygonRef.current);

        const newCoordinates = [...coordinates, ...currentCoordinates];
        setCoordinates(newCoordinates);

      },
      [onEditPolygon]
    );

    const convertToFormCoordinates = (coordinates) => {
      const newCoordinates = [];

      for (let c of coordinates){
        newCoordinates.push([c.lat, c.lng]);
      }

      return newCoordinates;
    }

    const renderMap = () => {
      const onMapLoad = (e) => {
        setMap(e);
      }

      const onLoadAutoComplete = (autoComplete) => {
        setAutoComplete(autoComplete);
      }

      const onPlaceChanged = () => {
        if(autoComplete === null){
          console.log('Autocomplete is not loaded yet!');
          return;
        }
        const place = autoComplete.getPlace(); 
        if (place.geometry) {
          const {location} = place.geometry;
          setAutoCompleteMarker(location);
          map.panTo(location);
          map.setZoom(ZOOM_LEVEL);
        }
      }

      const renderAutocompleteMarker = () => {
        if(!autoCompleteMarker) return;

        return (
            <Marker
                position={autoCompleteMarker}
            />
        );
      }

        return <GoogleMap
                    mapContainerClassName="App-map"
                    center={mapCoordinates}
                    zoom={ZOOM_LEVEL}
                    version="weekly"
                    on
                    onLoad={onMapLoad}
                    mouseup={(e)=>console.log(e)}
                    mouse_up={(e)=>console.log(e)}
                    clickableIcons={false}
                    options={{styles:[{elementType:"labels", featureType: "poi.business", stylers:[{ visibility: "off",}],}],}}
                >
                {renderAutocompleteMarker()}
            <AutocompleteCmp
              onLoad={onLoadAutoComplete}
              onPlaceChanged={onPlaceChanged} 
            /> 
            <DrawingCmp
                onLoad={(e)=>console.log(e)}
                onOverlayComplete={(e)=>console.log("overlay:",e)} 
                onPolygonComplete={onPolygonComplete}
            />
            {props.conditions[0].coordinates && props.conditions[0].coordinates.length && (
              <Polygon
                editable
                draggable
                path={coordinates}
                onLoad={onLoadPolygon}
                onMouseUp={onEditPolygon}
                onDragEnd={onEditPolygon}
              /> 
            )}

          
        </GoogleMap>
      }

      return renderMap();
}