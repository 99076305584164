import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import LoginForm from '../../components/LoginForm/LoginForm';
import { withRouter } from 'react-router';

import './login.scss';

export const Login = ({ authActions }) => (

        <div className="my-login-page">
            <LoginForm />
        </div>
);

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch)
});

export default withRouter(connect(null, mapDispatchToProps)(Login));