import React from "react";
import {Field, Form, InjectedFormProps, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {TextField} from "../../ui/Input/TextField";
import {SelectField} from "../../ui/Input/SelectField";
import {Asset} from "../../api/model/Asset";

interface TitleFieldProps {
    input: any;
    label: string;
    meta: { touched: boolean; error: string };
}

const titleField = ({input, label, meta: {touched, error}}: TitleFieldProps) => {
    return <TextField label={label} {...input} />;
};

interface SelectFieldProps {
    input: any;
    label: string;
    items: any;
}

const selectField = ({input, label, items}: SelectFieldProps) => {
    return (
        <SelectField
            label={label}
            placeholder=""
            value={input.value}
            {...input}
            items={items}
        />
    );
};

interface AssignmentFormProps {
    areaId: object;
    assets: Asset[];
    onSubmit: Function;
}

class AssignmentForm extends React.Component<
    AssignmentFormProps & InjectedFormProps
> {
    constructor(props: AssignmentFormProps & InjectedFormProps) {
        super(props);
    }

    renderSelectFieldRow(fieldName: string, label: string, items: any) {
        return (
            <div className="row mt-2 mb-3">
                <div className={"col-12"}>
                    <Field name={fieldName} label={label} items={items} component={selectField}/>
                </div>
            </div>
        );
    }

    render() {
        const {error, handleSubmit, pristine, reset, submitting, assets} = this.props;

        const assetItems = assets.map((asset: Asset) => ({value: asset.id, label: asset.name}));

        return (
            <Form onSubmit={handleSubmit(this.props.onSubmit)}>
                <div className={"container-fluid"}>
                    <div className={"row mt-2 mb-2"}>
                        <h1 className={"emp-type-h2 col"}>{"Assign Assets"}</h1>
                    </div>

                    <div className={"row mb-2"}>
                        <div className={"col-12"}>
                            <div className="emp-type-subtitle1">
                                {this.renderSelectFieldRow("assetId", "Asset", assetItems)}
                            </div>
                        </div>
                    </div>
                    <div className={"row mb-2"}>
                        <div className={"col"}></div>
                        <div className={"col-3 col-sm-2"}>
                            <button type="submit" disabled={submitting} className={"btn btn-primary"}>
                                <span className={"emp-type-btn-primary"}>SAVE</span>
                            </button>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }
}

export const validate = (values: any) => {
    const errors = {};
    return errors;
};

export const mapStateToProps = (state) => {
    return {
        initialValues: {
            assetId: state.assetReducer.asset.id,
            areaId: state.areaReducer.area.id
        }
    };
};

export const FORM_NAME = "deviceAssignmentForm";

const wrappedForm = connect(mapStateToProps)(
    reduxForm({
        form: FORM_NAME,
        enableReinitialize: true,
        validate,
    })(AssignmentForm)
);

export default wrappedForm;