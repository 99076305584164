import {getAlertConditionFields} from "../../utils/alertDataHelper";
import {isTemperatureField} from "../../utils/utils";
import {convertTemperature, getDesiredUnits, MeasurementUnit} from "../../utils/formatHelper";

export class IncidentReport {
    id;
    trigger_id;
    incident_timestamp;
    status;
    created_at;
    updated_at;
    incident_id;
    report_date;
    group_name;
    device_name;
    device_id;
    description;
    user_id;
    trigger_title;
    trigger_type;
    trigger_conditions;
    incident_datapoint;
    organization_id;
    reasons;
    storage_unit_types;
    author;
    users;
    groups;


    constructor(id, trigger_id, incident_timestamp, status, created_at, updated_at, incident_id, report_date, group_name, device_name, device_id, description, user_id, trigger_title, trigger_type, trigger_conditions, incident_datapoint, organization_id, reasons, storage_unit_types, author, users, groups) {
        this.id = id;
        this.trigger_id = trigger_id;
        this.incident_timestamp = incident_timestamp;
        this.status = status;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.incident_id = incident_id;
        this.report_date = report_date;
        this.group_name = group_name;
        this.device_name = device_name;
        this.device_id = device_id;
        this.description = description;
        this.user_id = user_id;
        this.trigger_title = trigger_title;
        this.trigger_type = trigger_type;
        this.trigger_conditions = trigger_conditions;
        this.incident_datapoint = incident_datapoint;
        this.organization_id = organization_id;
        this.reasons = reasons;
        this.storage_unit_types = storage_unit_types;
        this.author = author;
        this.users = users;
        this.groups = groups;
    }

    static deserialize(data, systemOfUnits) {
        const trigger_type = data.trigger_type;
        let conditions = data.trigger_conditions ? data.trigger_conditions.map(getAlertConditionFields) : [];
        let incidentDatapoint = data.incident_datapoint;

        if (isTemperatureField(trigger_type)) {
            const desiredUnits = getDesiredUnits(systemOfUnits, MeasurementUnit.Celsius);
            conditions.forEach(condition => {
                condition.threshold = convertTemperature(Number(condition.threshold), MeasurementUnit.Celsius, desiredUnits, 2);
            });
            incidentDatapoint = convertTemperature(Number(incidentDatapoint), MeasurementUnit.Celsius, desiredUnits, 2);
        }

        return new IncidentReport(
            data.id,
            data.trigger_id,
            data.incident_timestamp,
            data.status,
            data.created_at,
            data.updated_at,
            data.incident_id,
            data.report_date,
            data.group_name,
            data.device_name,
            data.device_id,
            data.description,
            data.user_id,
            data.trigger_title,
            trigger_type,
            conditions,
            incidentDatapoint,
            data.organization_id,
            data.reasons,
            data.storage_unit_types,
            data.author,
            data.users,
            data.groups
        )
    }

    getAlertConditionFields(alertCondition) {
        let fields = alertCondition.split(' '); // ex. "x > 99"
        return {
            condition: fields[1] || ' ',
            threshold: fields[2] || ' '
        }
    }

}

export class IncidentReason {
    id;
    label;

    constructor(id, label) {
        this.id = id;
        this.label = label;
    }

    static deserialize(data) {
        return new IncidentReason(
            data.id,
            data.label
        );
    }
}

export class IncidentStorageUnitType {
    id;
    label;

    constructor(id, label) {
        this.id = id;
        this.label = label;
    }

    static deserialize(data) {
        return new IncidentStorageUnitType(data.id, data.label);
    }
}

export default class NewIncidentReportResponse {
    errors;
    report;
    reasons;
    storage_unit_types;


    constructor(errors, report, reasons, storageUnitTypes) {
        this.errors = errors;
        this.report = report;
        this.reasons = reasons;
        this.storage_unit_types = storageUnitTypes;
    }

    static fromJson(data, systemOfUnits) {
        return new NewIncidentReportResponse(
            data.errors,
            IncidentReport.deserialize(data.report, systemOfUnits),
            data.reasons ? data.reasons.map(IncidentReason.deserialize) : [],
            data.storage_unit_types ? data.storage_unit_types.map(IncidentStorageUnitType.deserialize) : []
        )
    }
}