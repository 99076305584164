import { formTemplateConstants } from "../constants/formTemplate.constants";
import * as formTemplateApi from "../api/formTemplateApi";
import { FormTemplateProps } from "../formSchema";

export const fetchFormTemplates = ()=> ({
    type: formTemplateConstants.FETCH_FORM_TEMPLATES,
    payload: formTemplateApi.fetchFormTemplates()
});

export const setFormTemplate = (template: FormTemplateProps)=> ({
    type: formTemplateConstants.SET_FORM_TEMPLATE,
    payload: template
});