import React from 'react';
import {TextAreaField} from '../TextAreaField/TextAreaField';

export class IncidentReportDescriptionSection extends React.Component {

    render() {
        return (
            <div className="card shadow emp-form-card mb-3 emp_create_report_card report_section">
                <div className="card-header emp_create_report_header">
                    Describe incident in detail
                </div>
                <div className="card-body emp_create_report_body pb-2">
                    <div className="row">  
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">                 
                            <div className="form-group">
                                <TextAreaField
                                    name="description"
                                    placeholder=""
                                    maxLength={1000}
                                    rows={6} cols={100}
                                    className="form-control textarea_modifier"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        )
    }
  
}

export default IncidentReportDescriptionSection;