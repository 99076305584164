import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import AuditLog from "./model/AuditLog";
import * as Moment from 'moment';

export const fetchAuditLogDataByDate = ({start_date, end_date, limit}) => {

	if (start_date) {
		start_date = Moment(start_date, 'MM-DD-YYYY hh:mm A').toISOString();
	}

	if (end_date) {
		end_date = Moment(end_date, 'MM-DD-YYYY hh::mm A').toISOString();
	}
	
	return axios.get(`${BASE_URL()}/api/v1/audit_log`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': storage.get('token')
            },
            params: {
                start_time: start_date,
                end_time: end_date,
                limit: limit
            }
        }
    ).then(response => {
		return response.data.map(AuditLog.fromJson)
	});
}
