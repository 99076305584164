import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import {Link} from 'react-router';
import * as Moment from 'moment';

export class IncidentListView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [ // react table columns definition
                { Header: () => this.styleCell('Alert Title',null), 
                                accessor: 'title', 
                                columnName:'Alert Title',  width: 325,
                                Cell: (row) => this.styleTitleCell(row.value,'text-center text-blue') },
                // { Header: () => this.styleCell('Sensor',null),
                //                 accessor: 'sensorName',
                //                 columnName:'Sensor',
                //                 Cell: (row) => this.styleCell(row.value,'text-center text-blue') },
                { Header: () => this.styleCell('Name',null),
                    accessor: 'deviceName',
                    columnName:'Name',
                    Cell: (row) => this.styleDeviceNameCell(row,'text-center text-blue') },

                { Header: () => this.styleCell('Date and Time',null),
                                accessor: 'alertDate', 
                                columnName:'Date and time of alert', 
                                Cell: (row) => this.styleCell(row.value,'text-center') },
                { Header: () => this.styleCell('Status',null), 
                                accessor: 'status',  
                                columnName:'Status', width: 125,
                                Cell: (row) => this.styleStatusCell(row.value,'text-center') }, 
                { Header: () => this.styleCell('User',null), 
                                accessor: 'user', 
                                columnName:'User', 
                                Cell: (row) => this.styleCell(row.value,'text-center') },
                { Header: () => this.styleCell('Notes',null), 
                                accessor: 'notes_url',  
                                columnName:'Notes', width: 60,
                                Cell: (row) => this.styleNotesCell(row.value,'text-center') },     
            ]
        }
    }

    styleCell = (label, classes) => {
        if (classes)
          return <div className={classes}>{label}</div>
        return <div>{label}</div>
    }

    styleDeviceNameCell = (row, classes) => {
            return <div className={classes}><Link to={`environment/device/${row.original.sensorName}`} >
                <span className={classes}>{row.value}</span></Link></div>
    }

    styleTitleCell = (value, classes) => {
        return (
            <div>
                <i className={"fa fa-circle dot-emp-box mr-2" + (value.resolved ? " text-success" : "")}></i>
                <Link to={`incidents/${value.id}`}><span className={classes}>{value.title}</span></Link>
            </div>
        )
    }

    styleNotesCell = (value, classes) => {
        return (
            <div>
                <Link to={value} >
                    <img src={require("../../styles/images/icon/icn_file_blue.png")} alt="file_image"/>                 
                </Link>
            </div>   
        )
    }

    styleStatusCell = (value, classes) => {
        let isResolved = value==="Resolved" ? true : false;
        return (
            <div className={isResolved ? "text-success" : "text-danger"}>
                <span className="mr-1">
                    <img src={require(isResolved ? "../../styles/images/icon/icn_check.png" : "../../styles/images/icon/icn_ex.png")}
                        alt={this.props.status}/>
                </span>
                {value}
            </div>
        )
    }

    render() {
        return (
            <React.Fragment>
                <ReactTable 
                    columns={this.state.columns}
                    data={this.createTableListItems()}
                    className='-striped'
                    showPagination={true}
                    minRows={5}
                />            
            </React.Fragment>
        );

    }

    getReportLink(reportId, incidentId) {
        return reportId ? 
            `/incidents/${incidentId}/report/${reportId}/edit` : 
            `/incidents/${incidentId}/report/new`
    }

    createTableListItems() {
        return this.props.items.map( (item) => { 
            return {
                title: {title: item.title, id:item.incident_id, resolved: item.status === "Resolved"},
                sensorName: item.sensorName,
                deviceName: item.device_name,
                alertDate: Moment.utc(item.alertDate).local().format('YYYY/MM/DD hh:mm a'),
                status: item.status,
                user: item.user ? `${item.user.first_name} ${item.user.last_name}` : '',
                notes_url: this.getReportLink(item.report_id,item.incident_id)
            }
          })
    }
}
