import * as utils from "../../utils/utils";
import {convertTemperature, getTemperatureString, MeasurementUnit, SystemOfUnits} from "../../utils/formatHelper";
import {AlertTypes} from "../../components/AlertForm/AlertFormConstants";

export class AlertModel {
    active;
    clear_data_points;
    client_id;
    conditions;
    created_at;
    description;
    field;
    level;
    metric;
    period;
    schema;
    statistic;
    title;
    trigger_minutes;
    trigger_id;
    updated_at;
    version;
    devices;
    groups;
    type;
    trigger_data_points;

    constructor(active, clear_data_points, client_id, conditions, created_at, description, field, level, metric, period, schema, statistic, title, trigger_minutes, trigger_id, updated_at, version, devices = [], groups = [], type, trigger_data_points) {
        this.active = active;
        this.clear_data_points = clear_data_points;
        this.client_id = client_id
        this.conditions = conditions
        this.created_at = created_at;
        this.description = description;
        this.field = field;
        this.level = level;
        this.metric = metric;
        this.period = period;
        this.schema = schema;
        this.statistic = statistic;
        this.title = title;
        this.trigger_minutes = trigger_minutes;
        this.trigger_id = trigger_id;
        this.updated_at = updated_at;
        this.version = version;
        this.devices = devices.filter(d => d);
        this.groups = groups.filter(g => g);
        this.type = AlertModel.parseTypeEnum(type);
        this.conditionUnits = AlertModel.getConditionUnits(this.type);
        this.alertTitle = AlertModel.getAlertTitle(this.type); // for alert edit
        this.trigger_data_points = trigger_data_points;
    }

    static fromJson(json, format = null) {
        const alertModel = new AlertModel(json['active'],
            json['clear_data_points'],
            json['client_id'],
            json['conditions'] || [],
            json['created_at'],
            json['description'],
            json['field'],
            json['level'],
            json['metric'],
            json['period'],
            json['schema'],
            json['statistic'],
            json['title'],
            json['trigger_minutes'],
            json['trigger_id'],
            json['updated_at'],
            json['version'], json['devices'] || [],
            json['groups'] || [],
            this.getTypeFromField(json['schema'], json['field']),
            json['trigger_data_points']
        );

        if (utils.isTemperatureField(alertModel.field)) {
            const desiredUnits = format === SystemOfUnits.Imperial ? MeasurementUnit.Fahrenheit : MeasurementUnit.Celsius;
            const newConditions = alertModel.conditions.map(condition => {
                condition.threshold = convertTemperature(Number(condition.threshold), MeasurementUnit.Celsius, desiredUnits, 2);
                return condition;
            });
            alertModel.conditions = newConditions;
            alertModel.conditionUnits = getTemperatureString(format)
        }
        return alertModel;
    }

    static parseTypeEnum(type) {
        switch (type) {
            case 'temperature':
                return AlertTypes.TEMPERATURE;
            case 'humidity':
                return AlertTypes.HUMIDITY;
            case 'battery':
                return AlertTypes.BATTERY;
            case 'connection':
                return AlertTypes.CONNECTION;
            case 'geofence':
                return AlertTypes.GEOFENCE;
            case 'metric':
                return AlertTypes.METRIC;
            default:
                return null;
        }
    }

    static getTypeFromField(schema, field) {

        if (schema === 'metric') {
            return 'metric';
        }

        switch (field) {
            case 'ambient_temperature':
                return 'temperature'
            case 'probe_temperature':
                return 'temperature'
            case 'battery_voltage':
                return 'battery'
            case 'battery_percentage':
                return 'battery'
            case 'NULL':
                return 'connection'
            case 'humidity':
                return 'humidity'
            case 'geofence':
                return 'geofence'
            default:
                return 'metric'
        }
    }

    static getConditionUnits(type) {
        switch (type) {
            case AlertTypes.TEMPERATURE:
                return '°C';
            case AlertTypes.HUMIDITY:
            case AlertTypes.BATTERY:
                return '%'
            case AlertTypes.CONNECTION:
            default:
                return '';
        }
    }

    static getAlertTitle(type) {
        switch (type) {
            case AlertTypes.TEMPERATURE:
                return 'Alert if temperature is';
            case AlertTypes.HUMIDITY:
                return 'Alert if humidity is';
            case AlertTypes.BATTERY:
                return 'Alert if battery percentage is';
            case AlertTypes.CONNECTION:
                return 'Connection';
            default:
                return '';
        }
    }
}