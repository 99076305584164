import React from 'react';
import {Column, Table} from "../../../../api/model/DatasetTable";

interface Props {
    table: Table;
}

export class ColumnsTabPage extends React.Component<Props> {
    render() {
        const {table} = this.props;
        console.log("table", table);

        if (!table) {
            return null;
        }

        return (
            <div className="container-fluid">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Data Type</th>
                            <th>Nullable</th>
                            <th>Comment</th>
                        </tr>
                        </thead>
                        <tbody>
                        {table.columns.map((column: Column, index: number) => (
                            <tr key={index}>
                                <td>{column.name}</td>
                                <td>{column.data_type}</td>
                                <td>{column.nullable ? 'Yes' : 'No'}</td>
                                <td>{column.comment}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
