import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';

export const fetchScheduledReportData = (values) => {
    return axios.get(`${BASE_URL()}/api/v1/scheduled_reports/`,  {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response => response.data);

};

export const updateScheduledReport = (values) => {
    if (values['scheduled_report']['id']){
        return axios.put(`${BASE_URL()}/api/v1/scheduled_reports/${values['scheduled_report']['id']}`,values, { headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response =>  response.data);
    }
    else{
        return axios.post(`${BASE_URL()}/api/v1/scheduled_reports`,values, { headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response =>  response.data);
    }

};

export const deleteScheduledReport = (values) => {
    return axios.delete(`${BASE_URL()}/api/v1/scheduled_reports/${values}`, { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': storage.get('token')
    }}).then(response => {
        return response.data;
    });

};