import React, { Component } from "react";
import { ListView } from "../../../ui/ListView/ListView";
import * as $ from "jquery";
import DeviceAssignmentForm from "../DeviceAssignmentForm";
import {ApiDevice} from "../../../api/model/Device";
import {DeviceAssignment} from "../../../api/model/DeviceAssignment";

interface Props {
    onDeleteAssignmentClick: (item: DeviceAssignment) => void;
    onAssignSubmit: (values: any) => Promise<void>;
    onItemClick: (item: DeviceAssignment) => void;
    deviceAssignments: DeviceAssignment[];
    devices: ApiDevice[];
    assetId: string;
}

interface State {}

export class AssetAssignmentsTabPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.onAssignSubmit = this.onAssignSubmit.bind(this);
    }

    renderTextRow = (label: string, text: string) => {
        return (
            <div className="row mt-2">
                <span className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-3 emp-type-subtitle1">{label}</span>
                <span className={"col-6 col-sm col-md col-lg col-xl emp-type-subtitle1"}>{text}</span>
            </div>
        );
    }

    onDeleteItemClick = (item) => {
        this.props.onDeleteAssignmentClick(item);
    }

    renderItem = (item: DeviceAssignment, index) => {
        return (
            <div className="container-fluid">
                <div className={"row"}>
                    <div className={"col"}/>
                    <div className="col-1">
                        <button className={"btn btn-link"} onClick={(e) => {
                            e.preventDefault();
                            this.onDeleteItemClick(item);
                        }}><i className="fa fa-trash" aria-hidden="true"></i></button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-2">
                        <img className={"placeholder img-thumbnail"}
                             src={item.device?.imageUrl && item.device.imageUrl.url || "https://via.placeholder.com/150"}/>
                    </div>
                    <div className="col-12 col-sm-10">
                        {this.renderTextRow("Name", item.device?.name || '')}
                        {this.renderTextRow("Device Type", item.device?.deviceType || '')}
                        {this.renderTextRow("Device Id", item.device?.identity || '')}
                    </div>
                </div>
            </div>
        )
    }

    onAssignClick = () => {
        $("#assign_device").modal("show")
    }

    async onAssignSubmit (values) {
        await this.props.onAssignSubmit(values);
        $("#assign_device").modal("hide");
    }

    render() {
        const {deviceAssignments, devices} = this.props;
        const unAssignedDevices = devices.filter(device => !deviceAssignments.find(assignment => assignment.deviceId === device.id.toString()));

        return (
            <>
                <div className={"container-fluid"}>
                    <div className={"row mt-2 mb-2"}>
                        <div className={"col"}>
                        </div>
                        <div className={"col-4 col-sm-3"}>

                            <button className={"btn btn-link"} data-toggle="modal" onClick={this.onAssignClick}><span
                                className={"emp-type-btn-primary"}>ASSIGN</span></button>

                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col"}>
                            <ListView
                                datasource={deviceAssignments}
                                renderItem={this.renderItem}
                                onItemClick={this.props.onItemClick}
                            />
                        </div>
                    </div>

                </div>
                <div className="modal fade" id="assign_device" role="dialog" aria-labelledby="add_user" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <DeviceAssignmentForm assetId={this.props.assetId} devices={unAssignedDevices} onSubmit={this.onAssignSubmit}/>
                            {/*<UserProfileForm*/}
                            {/*    title={this.state.title}*/}
                            {/*    initialValues={this.state.currentUser}*/}
                            {/*    onFormSubmit={this.onUserFormSubmit}*/}
                            {/*    onFormCancel={this.onUserFormCancel}/>*/}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}