import React from 'react';
import {browserHistory, Link} from 'react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {reset} from 'redux-form';
import * as WorkflowActions from "../../actions/workflowActions";
import * as WorkflowScheduleActions from "../../actions/workflowScheduleActions";
import moment from "moment";
import ScheduledJobStatusTag from "./ScheduledJobTag";
import ScheduleDetails from "./ScheduleDetails";
import {confirmOrCancelDialogue, confirmDialogue} from "../../utils/dialogueFunctions";
import LoadingMessage from "../LoadingMessage/LoadingMessage";


export class WorkflowScheduleDetailPage extends React.Component<{
    workflowScheduleActions,
    scheduleId: string,
    scheduledJobReducer,
    workflowScheduleReducer,
    workflowReducer,
    loadMoreJobs,
    moreJobsLoading
}, {}> {
    state;
    lastElementRef;
    observer: IntersectionObserver | null;

    constructor(props) {
        super(props);
        this.state = {};
        this.lastElementRef = React.createRef();
        this.observer = null;
    }

    async handleDelete() {
        const workflowSchedule = this.props.workflowScheduleReducer.selectedWorkflowSchedule;
        let confirm = await confirmOrCancelDialogue(`Delete Workflow "${workflowSchedule.title}"?`);
        if (!confirm) return;

        await this.props.workflowScheduleActions.deleteWorkflowSchedule(this.props.scheduleId);
        browserHistory.push("/workflows");
    }

    async handleRunNowClick() {
        try {
            await this.props.workflowScheduleActions.createWorkflowScheduleRun(this.props.scheduleId);
            await confirmDialogue("Workflow run has been scheduled");
        } catch (e) {
            console.error(e);
            await confirmDialogue("Workflow run has failed", "Failure");
        }
    }

    renderHeaderBar() {
        return (<div className="row">
            <div className="col-sm-12">
                <div className="app-title border-bottom">
                    <div className={"schedule-detail-header"}>
                        <div className="media align-items-center">
                            <Link to="/workflows">
                    <span className="mr-3 emp_icn_middle">
                    <img
                        src={require("../../styles/images/icon/ic_left-arrow.png")}
                        alt="Icon"
                    />
                    </span>
                            </Link>
                            <div className="media-body">
                                <h1 className="mt-0 text-capitalize">
                                    Schedules
                                </h1>
                                <span className="sub_txt_title">
                {" "}
                                    All created schedules {" "}
                    </span>
                            </div>
                        </div>
                    </div>
                    <div className="schedule-detail-btns">
                        <div className="col-xs-4">
                            <button className="btn btn-primary"
                                    onClick={this.handleRunNowClick.bind(this)}>Run Now
                            </button>
                        </div>
                        <div className="col-xs-4">
                            <Link to={`/workflow_schedules/${this.props.scheduleId}/edit`}>
                                <button className="btn btn-primary">Edit</button>
                            </Link>
                        </div>
                        <div className="col-xs-4">
                            <button className="btn btn-primary"
                                    onClick={this.handleDelete.bind(this)}>Delete
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }

    componentDidMount() {
        this.setupObserver();
    }

    componentWillUnmount() {
        if(this.observer){
            this.observer.disconnect();
        }
    }

    setupObserver() {
        const options: any = {
            root: null,
            rootMargin: "150px",
            threshold: 1.0
        };

        this.observer = new IntersectionObserver(this.handleObserver.bind(this), options);
        if (this.lastElementRef.current) {
            this.observer.observe(this.lastElementRef.current);
        }
    }

    handleObserver(entries) {
        const target = entries[0];
        if (target.isIntersecting) {
            // Fetch more scheduledJobs here
            this.props.loadMoreJobs();
        }
    }

    componentDidUpdate() {
        if (this.lastElementRef.current && this.observer) {
            this.observer.disconnect();
            this.observer.observe(this.lastElementRef.current);
        }
    }

    renderHistory() {
        const dateFormat = "MM/DD/YYYY hh:mm:ss A";
        const scheduledJobs = this.props.scheduledJobReducer.scheduledJobs;
        return scheduledJobs.map((scheduledJob, idx) => {
            const isLastElement = idx === scheduledJobs.length - 1;

            return (
                <Link to={`/scheduled_jobs/${scheduledJob.id}`}
                      className="row col-sm-12 col-md-10 col-lg-10 col-xl-8 shadow emp-scheduled-job-card mb-2"
                      key={idx}>
                    <div className="col-sm-12 col-md-9 col-lg-9 col-xl-9" ref={isLastElement ? this.lastElementRef : null}>
                        <div className="schedule-detail-timestamp">
                            <div className="schedule-detail-label pr-1">last
                                updated:
                            </div>
                            <div>{moment(scheduledJob.updated_at).format(dateFormat)}</div>
                        </div>
                        <div className="schedule-detail-timestamp">
                            <div className="schedule-detail-label pr-1">created:</div>
                            <div>{moment(scheduledJob.created_at).format(dateFormat)}</div>
                        </div>
                    </div>
                    <div
                        className="col-sm-12 col-md-3 col-lg-3 col-xl-3 scheduled-job-status-area">
                        <div className={""}>
                            <span className="schedule-detail-label pr-1">Status:</span>
                            <ScheduledJobStatusTag status={scheduledJob.status}/>
                        </div>
                    </div>
                </Link>
            )
        });
    }

    render() {
        return (
            <main className="app-content">
                <section className="emp_org_settings_sec emp_report_sec schedule-detail-page">
                    {this.renderHeaderBar()}
                    <ScheduleDetails workflowSchedule={this.props.workflowScheduleReducer.selectedWorkflowSchedule}
                                     workflow={this.props.workflowReducer.selectedWorkflow}/>
                    <h4>
                        Job History</h4>
                    <div className={"emp-scheduled-job-history mt-2 ml-3"}>
                        {this.props.scheduledJobReducer.scheduledJobs.length ? this.renderHistory() :
                            <div>No Scheduled Jobs created</div>}
                        {this.props.moreJobsLoading && <LoadingMessage/>}
                    </div>
                </section>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        workflowReducer: state.workflowReducer,
        workflowScheduleReducer: state.workflowScheduleReducer,
        scheduledJobReducer: state.scheduledJobReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    workflowActions: bindActionCreators(WorkflowActions, dispatch),
    workflowScheduleActions: bindActionCreators(WorkflowScheduleActions, dispatch),
    reset: reset
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowScheduleDetailPage);
