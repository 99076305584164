import {shipmentsConstants} from "../constants/shipments.constants";
import {shipmentInspectionsConstants} from "../constants/shipmentInspections.constants";


const initialState = {
    shipments: [],
    loading: false,
    shipment: null
};

export const shipmentsReducer = (state = initialState, action) => {
    switch (action.type) {
        case shipmentsConstants.CREATE_SHIPMENT_FULFILLED:
            state = {
                ...state,
                shipments: [...state.shipments, action.payload]
            };
            return state;
        case shipmentsConstants.GET_SHIPMENT_FULFILLED:
            state = {
                ...state,
                shipment: action.payload
            };
            return state;
        case shipmentsConstants.QUERY_SHIPMENTS_DATA_FULFILLED:
            state = {
                ...state,
                shipments: action.payload
            };
            return state;

        case shipmentsConstants.UPDATE_SHIPMENT_FULFILLED:
            state = {
                ...state,
                shipments: state.shipments.map(shipment =>
                    shipment.id === action.payload.id ? action.payload : shipment
                ),
            };
            return state;
        case shipmentInspectionsConstants.UPDATE_SHIPMENT_INSPECTION_FULFILLED:
            let updatedInspections = state.shipment.inspections.map(inspection =>
                inspection.id === action.payload.id ? action.payload : inspection
            );

            state = {
                ...state,
                shipment: {...state.shipment, inspections: updatedInspections},
            };
            return state;
        case shipmentInspectionsConstants.CREATE_SHIPMENT_INSPECTION_FULFILLED:
            let newInspections = [...state.shipment.inspections, action.payload];

            state = {
                ...state,
                shipment: {...state.shipment, inspections: newInspections},
            };
            return state;
        case shipmentInspectionsConstants.DELETE_SHIPMENT_INSPECTION_FULFILLED:
            state = {
                ...state,
                shipment: {...state.shipment, inspections: state.shipment.inspections.filter(inspection=>inspection.id !== action.payload.id)},
            };
            return state;
        default:
            return state;
    }
};