import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as AssetActions from '../../actions/assetActions';
import * as AssetTypeActions from '../../actions/assetTypeActions';
import * as DeviceAssignmentActions from '../../actions/deviceAssignmentActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {browserHistory, withRouter} from 'react-router';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import AssetEditDetailPage from "../../components/Asset/AssetEditDetailPage";

export class AssetEditDetailPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            metrics: []
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        const assetId = this.props.params.asset_id;
        this.setState({loading: true});
        await Promise.all([
            this.props.assetActions.getAsset(assetId),
            this.props.assetTypeActions.listAssetTypes(),
            this.props.deviceAssignmentActions.listDeviceAssignments(assetId, ['device'])
        ]);

        this.setState({loading: false})
    }

    async onSubmit(values) {
        this.setState({loading: true});
        values.id = this.props.params.asset_id;
        await this.props.assetActions.updateAsset(this.props.params.asset_id, values);
        browserHistory.push(`/assets/${this.props.params.asset_id}`);
    }

    render() {
        const {auth, authActions, asset, assetTypes, deviceAssignments} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <AssetEditDetailPage asset={asset} assetTypes={assetTypes} deviceAssignments={deviceAssignments}
                                         onSubmit={this.onSubmit}/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    asset: state.assetReducer.asset,
    assetTypes: state.assetTypeReducer.assetTypes,
    deviceAssignments: state.deviceAssignmentReducer.deviceAssignments
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    assetActions: bindActionCreators(AssetActions, dispatch),
    assetTypeActions: bindActionCreators(AssetTypeActions, dispatch),
    deviceAssignmentActions: bindActionCreators(DeviceAssignmentActions, dispatch)
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetEditDetailPageContainer));