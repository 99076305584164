import React from "react"

export const SectionTitle = ({isAdmin, section, showBom, showAdjustment, bom, batchAdjustments}) => {
    return (
      <div className="pb-3 mb-4 border-bottom d-flex justify-content-between align-items-center">
        <div>
          {/* Batch Info */}
          {section.section_name === 'Batch Information' && (
            <p className="sop-section-title">{section.section_name}</p>
          )}
          {/* Ingredients */}
          {section.section_name === 'Ingredients' && (
            <>
              <p className="sop-section-title sop-section-title--sm">
                {section.section_name}
              </p>
              <p className="sop-section-subtitle">
                {section.section_description}
              </p>
            </>
          )}

          {/* "Steps" */}
          {!['Batch Information', 'Ingredients'].includes(
            section.section_name
          ) && (
            <>
              <p className="sop-section-title sop-section-title--sm">
                {section.section_name}
              </p>
              <p className="sop-section-subtitle">
                {section.section_description}
              </p>
            </>
          )}
        </div>


        <div>
          {(batchAdjustments && (isAdmin || section.show_adjustment_btn)) && (
            <button onClick={showAdjustment} className="btn btn-primary">
              Add Adjustments
            </button>
          )}
          {(bom && (isAdmin || section.show_adjustment_btn)) && (
          <button
            onClick={showBom}
            className="ml-4 btn btn-outline-primary"
          >
            View BOM
          </button>
          )}
        </div>
      </div>
    )
}