

import {assetTypeConstants} from '../constants/assetType.constants';
import * as assetTypeApi from "../api/assetTypeApi";

export const listAssetTypes = (data)=> ({
    type: assetTypeConstants.LIST_ASSET_TYPES,
    payload: assetTypeApi.listAssetTypes(data)
});

export const getAssetType = (values)=> ({
    type: assetTypeConstants.GET_ASSET_TYPE,
    payload: assetTypeApi.getAssetType(values)
});

export const createAssetType = (values)=> ({
    type: assetTypeConstants.CREATE_ASSET_TYPE,
    payload: assetTypeApi.createAssetType(values)
});

export const updateAssetType = (id,values)=> ({
    type: assetTypeConstants.UPDATE_ASSET_TYPE,
    payload: assetTypeApi.updateAssetType(id,values)
});

export const deleteAssetType = (id)=> ({
    type: assetTypeConstants.DELETE_ASSET_TYPE,
    payload: assetTypeApi.deleteAssetType(id)
});

export const resetAssetType = () => ({
    type: assetTypeConstants.RESET_ASSET_TYPE,
    payload: async () => {}
})