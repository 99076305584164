import store from '../store/store';
import * as GatewayActions from '../actions/gatewayAction';
import * as SensorActions from '../actions/sensorAction';
import * as ApiKeysActions from '../actions/apiKeysAction';
import * as GroupActions from '../actions/groupAction';
import * as ScheduledReportActions from '../actions/scheduledReportAction';

export const apiKeysData =() => {
    store.dispatch(ApiKeysActions.fetchApiKeysData());
};
export const fetchGateway =(values) => {
    store.dispatch(GatewayActions.fetchGateway(values));
};

export const fetchSensor =(values) => {
    store.dispatch(SensorActions.getSensor(values));
};

export const fetchGroup =(values) => {
    store.dispatch(GroupActions.fetchGroup(values));
};

export const fetchScheduledReport =(values) => {
    store.dispatch(ScheduledReportActions.fetchScheduledReportData(values));
};
