import React from "react";
import { getMetric } from '../../../api/metricApi';

import {ApiDevice} from "../../../api/model/Device";
import {MeasurementsTabPage} from "../../Devices/Tabs/MeasurementsTabPage";
import Moment from "moment/moment";
import {SystemOfUnits} from "../../../utils/formatHelper";
import {MetricData} from "../../Visualization/MetricGraph";
import {SelectField} from "../../../ui/Input/SelectField";

type Props = {
    devices: ApiDevice[];
    getMetric: typeof getMetric;
}

type State = {
    selectedDevice: ApiDevice | null;
};

export class AssetMeasurementsTabPage extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            selectedDevice: null
        }
    }

    onSelectChange = (e) => {
        const deviceIdentity = e.target.value;
        const device = this.props.devices.find((device) => device.identity === deviceIdentity);

        this.setState((prevState) => ({
            ...prevState,
            selectedDevice: device!
        }));
    }

    componentDidMount() {
        this.setState((prevState) => ({
            selectedDevice: this.props.devices ? this.props.devices[0] : null
        }));
    }

    render() {
        const {devices} = this.props;
        if (!devices) {
            return null;
        }
        return (

            <>
                <div className={"mt-2"}>


                    <SelectField items={devices.map((device) => ({value: device.identity, label: device.name}))}
                                 onChange={this.onSelectChange}
                                 value={this.state.selectedDevice?.identity}
                    />
                    <div className={"mt-4"}>
                        {this.state.selectedDevice &&
                            <MeasurementsTabPage device={this.state.selectedDevice} getMetric={this.props.getMetric}/>}
                    </div>
                </div>
            </>
        );
    }

}