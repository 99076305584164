import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

const renderField = ({ input, label, value,labelClass,spanClass,htmlFor,id,toggleBtn,errorMsg,checked, meta: { pristine,touched, asyncValidating, error } }) =>  {
	return (
		<div className="d-inline" >
			<input type="radio" {...input}  id={id} className="custom-control-input" />
			<label className={labelClass} htmlFor={htmlFor}>{label}</label>
			{
				errorMsg && error && touched &&
				<div className="form-group__error">{error}</div>
			}
		</div>
	);
}
export const RadioBtn = ({ type, name, label,value,labelClass,spanClass,id,htmlFor,toggleBtn,handleChange,checked,errorMsg }) => (
	<Field
		type={type}
		name={name}
		label={label}
		value={value}
		id={id}
		labelClass={labelClass}
		spanClass={spanClass}
		htmlFor={htmlFor}
		onChange={handleChange}
		toggleBtn={toggleBtn}
		errorMsg={errorMsg}
		checked={checked}
		component={renderField}
		/>
);

RadioBtn.propTypes = {
	type: PropTypes.string,
	name: PropTypes.string,
	label: PropTypes.string,
	labelClass: PropTypes.string,
	spanClass: PropTypes.string,
	id: PropTypes.string,
	toggleBtn: PropTypes.string,
	handleChange: PropTypes.func,
	errorMsg: PropTypes.string,
	htmlFor: PropTypes.string
};

RadioBtn.defaultProps = {
	type: 'radio',
	label: null,
	labelClass: '',
	spanClass: '',
	id: '',
	checked: '',
	toggleBtn: '',
	errorMsg: '',
	htmlFor: '',
};