import {datasetTablesConstants} from "../constants/datasetTables.constants";
import {DatasetTablesApi} from "../api/datasetTablesApi";

const datasetTablesApi = new DatasetTablesApi();

export const listTables = (databaseName: string, limit = 10, cursor = null) => ({
    type: datasetTablesConstants.LIST_DATASET_TABLES,
    payload: datasetTablesApi.queryDatasetTables(databaseName, limit, cursor)

});

export const getTable = (databaseName: string, tableName: string) => ({
    type: datasetTablesConstants.FETCH_DATASET_TABLE,
    payload: datasetTablesApi.getDatasetTable(databaseName, tableName)
});