import { useState, useEffect, useCallback } from 'react';
import debounce from 'lodash/debounce';
import * as fieldInstanceApi from '../api/fieldInstanceApi';
import {
  FormInstanceProps,
  FormSectionProps,
  FieldInstanceProps,
  FormTemplateProps,
} from '../formSchema';
import { findMissingFields, SORT_BY_FIELD_INDEX } from '../utils/formUtils';
// import {
//   updateFormInstance,
//   fetchFormInstance,
// } from '../actions/formInstanceAction';

const useFormSection = ({
  formInstance,
  formTemplate,
  user,
  formInstanceActions,
}: {
  formInstance: FormInstanceProps;
  formTemplate: FormTemplateProps;
  user: any;
  formInstanceActions: typeof import('../actions/formInstanceAction');
}) => {
  const [loading, setLoading] = useState(true);
  const [section, setSection] = useState<FormSectionProps | null>(null);
  const [fieldInstances, setFieldInstances] = useState<FieldInstanceProps[]>(
    []
  );
  const [error, setError] = useState('');
  const [formIndex, setFormIndex] = useState(0);
  const [saving, setSaving] = useState(false);
  const [standardYield, setStandardYield] = useState('');
  const [batchAdjust, setBatchAdjust] = useState(false);

  const fetchSection = async (sectionId) => {
    try {
      const response = await formInstanceActions.fetchFormInstanceSection(
        sectionId,
        formInstance.id
      );
      return response;
    } catch (error) {
      console.error('Failed to fetch form instance section', error);
    }
  };

  // Fetch section data on form index change or batch adjust toggle
  useEffect(() => {
    const batchAdjSection = formTemplate.sections.find(
      (section) => section.batch_adjustment
    ) as FormSectionProps;
    const section = formTemplate.sections[formIndex - 1];
    const activeSection =
      batchAdjust && batchAdjSection ? batchAdjSection : section;
    setSection(activeSection);

    if (!activeSection) return;

    fetchSection(activeSection.id).then((res: any) => {
      const fieldInstances = res.value.sort((a, b) =>
        SORT_BY_FIELD_INDEX(a.field, b.field)
      );

      setFieldInstances(fieldInstances);

      setLoading(false);
    });
  }, [formIndex, batchAdjust, formTemplate]);

  // Set standard yield on form instance change
  useEffect(() => {
    if (!formInstance || !formInstance.standard_yield) return;
    setStandardYield(formInstance.standard_yield.value || '');
  }, [formInstance]);

  const saveField = async (update) => {
    setSaving(true);

    if (!formInstance || !formTemplate) {
      return;
    }

    try {
      await fieldInstanceApi.updateFieldInstance(update.id, update);
    } catch (error) {
      console.error('Failed to update field instance', error);
    }

    try {
      const updatedFormInstance = {
        ...(formInstance as FormInstanceProps),
        status: 'in_progress',
      };

      await formInstanceActions.updateFormInstance(
        formInstance.id,
        updatedFormInstance
      );
      await formInstanceActions.fetchFormInstance(formInstance.id);
    } catch (error) {
      console.error('Failed to update form instance', error);
    } finally {
      setSaving(false);
    }
  };

  const debouncedSaveField = useCallback(
    debounce((fieldInstance) => {
      saveField(fieldInstance);
    }, 200),
    []
  );

  const handleFieldUpdate = (fieldInstance) => {
    setFieldInstances(
      (prev) =>
        prev &&
        prev.map((instance) =>
          instance.id === fieldInstance.id
            ? {
                ...instance,
                error: '',
                value: fieldInstance?.value,
                updated_at: new Date().toISOString(),
                updated_by: user.user_id,
              }
            : instance
        )
    );

    debouncedSaveField(fieldInstance);
  };

  const handleMissingFields = () => {
    const incompleteFields = findMissingFields(fieldInstances);

    if (incompleteFields?.length > 0) {
      setError('Please fill out all required fields');

      const incompleteIds = incompleteFields.map(
        (fieldInstance) => fieldInstance.id
      );
      setFieldInstances((prev) =>
        prev.map((instance) =>
          incompleteIds.includes(instance.id)
            ? { ...instance, error: 'Field required' }
            : instance
        )
      );

      return false;
    } else {
      setError('');
      return true;
    }
  };

  const validateSection = (): boolean => {
    if (!handleMissingFields()) {
      return false;
    }

    setFieldInstances([]);
    return true;
  };

  const toggleBatchAdjust = () => {
    setBatchAdjust((prev) => !prev);

    const batchAdjSection = formTemplate.sections.find(
      (section) => section.batch_adjustment
    ) as FormSectionProps;

    const section = formTemplate.sections[formIndex];

    const activeSection =
      !batchAdjust && batchAdjSection ? batchAdjSection : section;

    setSection(activeSection);

    // const fieldInstances = formInstance.field_instances
    //   .filter((instance) => instance.field.section_id === activeSection.id)
    //   .sort(SORT_BY_FIELD_INDEX)

    setFieldInstances([]);
    // setFieldInstances(fieldInstances)
  };

  const addAdjustmentInstances = (fieldInstances) => {
    setFieldInstances((prev) => [...prev, ...fieldInstances]);
  };

  return {
    loading,
    section,
    fieldInstances,
    error,
    formIndex,
    setFormIndex,
    setFieldInstances,
    saving,
    standardYield,
    batchAdjust,
    handleFieldUpdate,
    validateSection,
    toggleBatchAdjust,
    addAdjustmentInstances,
  };
};

export default useFormSection;
