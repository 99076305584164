import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import {WorkflowScheduleModel} from './model/WorkflowSchedule';


export const createWorkflowSchedule = (values) => {
    let schedulePayload = {
        'schedule_interval': values['schedule_interval'],
        'workflow_id': values['workflow_id'],
        'start_date': values['start_date'],
        'compute_size': values['compute_size'],
        'enabled': true,
        'workflow_params': values['workflow_params'] || {},
        'title': values['title'],
        'description': values['description'],
    };
    return axios.post(`${BASE_URL()}/api/v1/schedules`, schedulePayload, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return WorkflowScheduleModel.fromJson(response.data.item);
    });
};

export const createWorkflowScheduleRun = (scheduleId) => {
    return axios.post(`${BASE_URL()}/api/v1/schedules/${scheduleId}/create_schedule_run`, {},{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response;
    });
};

export const updateWorkflowSchedule = (values) => {
    let schedulePayload = {
        'schedule_interval': values['schedule_interval'],
        'workflow_id': values['workflow_id'],
        'start_date': values['start_date'],
        'compute_size': values['compute_size'],
        'enabled': true,
        'workflow_params': values['workflow_params'] || {},
        'title': values['title'],
        'description': values['description'],
    };

    return axios.put(`${BASE_URL()}/api/v1/schedules/${values.id}`, schedulePayload, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    }).then(response => {
        return WorkflowScheduleModel.fromJson(response.data.item);
    });
};

export const listWorkflowSchedules = () => {
    return axios.get(`${BASE_URL()}/api/v1/schedules`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response.data['items'].map(item => WorkflowScheduleModel.fromJson(item));
    });
};

export const deleteWorkflowSchedule = (scheduleId) => {
    return axios.delete(`${BASE_URL()}/api/v1/schedules/${scheduleId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return {id: scheduleId}
    });
};

export const getWorkflowSchedule = (scheduleId) => {
    return axios.get(`${BASE_URL()}/api/v1/schedules/${scheduleId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return WorkflowScheduleModel.fromJson(response.data.item);
    });
};
