export const shipmentsConstants = {
    QUERY_SHIPMENTS_DATA: 'QUERY_SHIPMENTS_DATA',
    QUERY_SHIPMENTS_DATA_FULFILLED: 'QUERY_SHIPMENTS_DATA_FULFILLED',

    CREATE_SHIPMENT: 'CREATE_SHIPMENT',
    CREATE_SHIPMENT_FULFILLED: 'CREATE_SHIPMENT_FULFILLED',

    GET_SHIPMENT: 'GET_SHIPMENT',
    GET_SHIPMENT_FULFILLED: 'GET_SHIPMENT_FULFILLED',

    UPDATE_SHIPMENT: 'UPDATE_SHIPMENT',
    UPDATE_SHIPMENT_FULFILLED: 'UPDATE_SHIPMENT_FULFILLED'
};