import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as DeviceActions from '../../actions/deviceAction';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {withRouter, RouteComponentProps} from 'react-router';
import {DeviceDetailPage} from '../../components/Devices/DeviceDetailPage'
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import * as DeviceAction from "../../actions/deviceAction";
import * as MetricActions from "../../actions/metricAction";
import {getMetric} from "../../api/metricApi";
import * as DatasetTableActions from "../../actions/datasetTablesActions";
import {Table} from "../../api/model/DatasetTable";
import {TableDetailPage} from "../../components/Databases/Tables/TableDetailPage";

interface StateProps {
    auth: { user: any, isLoggedIn: boolean }
    table: Table;
}

interface DispatchProps {
    authActions: typeof AuthActions;
    datasetTableActions: typeof DatasetTableActions;
}

type Props = StateProps & DispatchProps & RouteComponentProps<any, any>;

interface State {
    loading: boolean;
}



class TablesDetailPageContainer extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
        this.onTabChange = this.onTabChange.bind(this);
    }

    async componentDidMount() {
        const {database_name: databaseName, id} = this.props.params;
        console.log("databaseName", databaseName, "id", id)
        this.setState({loading: true})
        this.props.datasetTableActions.getTable(databaseName, id)
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.table !== this.props.table && this.props.table) {
            this.setState({loading: false})
        }
    }

    onTabChange = async (tab) => {
        switch(tab) {
            default:
                break;
        }
    }

    render() {
        const {auth, authActions, table} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <TableDetailPage
                        table={table}
                        onTabChange={this.onTabChange}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    table: state.datasetTablesReducer.table
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    datasetTableActions: bindActionCreators(DatasetTableActions, dispatch),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TablesDetailPageContainer));