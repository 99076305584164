import {apiKeysConstants} from '../constants/apiKeys.constants.js';

const initialState = {
    apiKeys: {},
    apiKeysList: [],
    updated: false,
    deleted: false
};

export const apiKeysReducer = (state = initialState, action) => {
    let api_keys;
    switch (action.type) {
        case apiKeysConstants.CREATE_API_KEYS_FULFILLED:
            api_keys = [...state.apiKeysList,action.payload];
            state = {
                ...state,
                apiKeysList: api_keys,
                updated: true
            };
            return state;
        case apiKeysConstants.FETCH_API_KEYS_DATA_FULFILLED:
            state = {
                ...state,
                apiKeysList: action.payload
                //event: action.payload.sponsor
            };
            return state;

        default:
            return state;
    }
};