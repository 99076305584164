export const deviceTypeConstants = {
    GET_DEVICE_TYPE: 'GET_DEVICE_TYPE',
    GET_DEVICE_TYPE_FULFILLED: 'GET_DEVICE_TYPE_FULFILLED',

    LIST_DEVICE_TYPES: 'LIST_DEVICE_TYPES',
    LIST_DEVICE_TYPES_FULFILLED: 'LIST_DEVICE_TYPES_FULFILLED',

    CREATE_DEVICE_TYPE: 'CREATE_DEVICE_TYPE',
    CREATE_DEVICE_TYPE_FULFILLED: 'CREATE_DEVICE_TYPE_FULFILLED',

    DELETE_DEVICE_TYPE: 'DELETE_DEVICE_TYPE',
    DELETE_DEVICE_TYPE_FULFILLED: 'DELETE_DEVICE_TYPE_FULFILLED',

    UPDATE_DEVICE_TYPE: 'UPDATE_DEVICE_TYPE',
    UPDATE_DEVICE_TYPE_FULFILLED: 'UPDATE_DEVICE_TYPE_FULFILLED',

    RESET_DEVICE_TYPE: 'RESET_DEVICE_TYPE',
    RESET_DEVICE_TYPE_FULFILLED: 'RESET_DEVICE_TYPE_FULFILLED',
};
