import React from 'react';
import {Link} from "react-router";
import WorkEventForm from "./WorkEventForm";

export class WorkEventPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: '0'
        };
    }

    renderHeaderBar() {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="app-title border-bottom">
                        <div>
                            <div className="media align-items-center">
                                <Link to="/alerts">
                  <span className="mr-3 emp_icn_middle">
                    <img
                        src={require("../../styles/images/icon/ic_left-arrow.png")}
                        alt="Icon"
                    />
                  </span>
                                </Link>

                                <div className="media-body">
                                    <h1 className="mt-0 text-capitalize">
                                        Go Back Here
                                        {/*    TODO: figure out correct title*/}
                                    </h1>
                                    <span className="sub_txt_title">
                    {" "}
                                        {/*                      TODO: subtitle to go back*/} lorum ipsum
                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    render() {
        return (
            <main className="app-content">
                <section
                    className="emp_org_settings_sec emp_report_sec emp_circle_box_sec emp_alert_sec emp_temp_alert_sec">
                    {this.renderHeaderBar()}
                    <WorkEventForm
                        workActivities={this.props.workActivities}
                        failureModes={this.props.failureModes}
                        onFormSubmit={this.props.onFormSubmit}
                    />
                </section>
            </main>
        )
    }
}

export default WorkEventPage;