import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';

import * as DeviceActions from '../../actions/deviceAction';
import * as DeviceTypeActions from '../../actions/deviceTypesActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {browserHistory, withRouter} from 'react-router';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import {DeviceEditDetailPage} from "../../components/Devices/DeviceEditDetailPage";

export class DeviceEditDetailPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        const id = this.props.params.id;
        this.setState({loading: true});
        await this.props.deviceActions.getDevice(id);
        this.props.deviceTypeActions.listDeviceTypes();
        this.setState({loading: false})
    }

    async onSubmit(values) {
        this.setState({loading: true});
        values.identity = this.props.params.id;
        await this.props.deviceActions.updateDevice(values.identity, values);
        browserHistory.push(`/devices/${values.identity}`);
    }

    render() {
        const {auth, authActions, device, deviceTypes} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <DeviceEditDetailPage device={device}
                                          deviceTypes={deviceTypes}
                                         onSubmit={this.onSubmit}/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    device: state.deviceReducer.device,
    deviceTypes: state.deviceTypesReducer.deviceTypes
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    deviceActions: bindActionCreators(DeviceActions, dispatch),
    deviceTypeActions: bindActionCreators(DeviceTypeActions, dispatch),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceEditDetailPageContainer));