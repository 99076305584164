import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AreaActions from '../../actions/areaActions';
import * as AuthActions from '../../actions/authActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import {AreasPage} from "../../components/Area/AreasPage";
import {browserHistory} from "react-router";

function debounce(callback, delay) {
    let timeout;

    return function() {
        // Get the arguments passed to the debounced function
        const args = arguments;

        // Clear any existing timeout
        clearTimeout(timeout);

        // Set a new timeout to delay the execution of the callback function
        timeout = setTimeout(() => {
            callback(...args);
        }, delay);
    }
}

class AreasContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.debounceSearch = debounce(this.onSearchInput.bind(this), 600);
        this.debounceSearch = this.debounceSearch.bind(this);
    }

    async componentDidMount() {
        this.setState({loading: true})
        await this.props.areaActions.listAreas();
        this.setState({loading: false})
    }

    onItemClick(item) {
        browserHistory.push(`/areas/${item.id}`);
    }

    async onSearchInput(value) {
        await this.props.areaActions.listAreas({name: value});
    }

    onDeleteItemClick = async (item) => {
        await this.props.areaActions.deleteArea(item.id);
    }

    render() {
        const {auth, authActions, areas} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <AreasPage areas={areas}
                               onDeleteItemClick={this.onDeleteItemClick}
                                onItemClick={this.onItemClick.bind(this)}
                                onSearchInput={this.debounceSearch}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    areas: state.areaReducer.areas,
});

const mapDispatchToProps = (dispatch) => ({
    areaActions: bindActionCreators(AreaActions, dispatch),
    authActions: bindActionCreators(AuthActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(AreasContainer);