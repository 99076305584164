import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as AssetTypeActions from '../../actions/assetTypeActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {withRouter} from 'react-router';
import {AssetTypeDetailPage} from '../../components/AssetTypes/AssetTypeDetailPage'
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";

class AssetTypeDetailPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    async componentDidMount() {
        const id = this.props.params.id;
        this.setState({loading: true})
        await this.props.assetTypeActions.getAssetType(id);
        this.setState({loading: false})
    }

    render() {
        const {auth, authActions, assetType} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <AssetTypeDetailPage assetType={assetType}/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    assetType: state.assetTypeReducer.assetType,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    assetTypeActions: bindActionCreators(AssetTypeActions, dispatch),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetTypeDetailPageContainer));