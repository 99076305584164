import React from 'react';
import {bindActionCreators} from 'redux';
import {browserHistory} from 'react-router';
import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import {Form, reduxForm , reset, initialize} from 'redux-form';
import {InputField} from '../InputField/InputField';
import {SelectField} from '../SelectField/SelectField';
import {DateTimeField} from '../DateTimeField/DateTimeField';
import * as scheduledReportAction from '../../actions/scheduledReportAction';
import { Link } from 'react-router';
import store from '../../store/store';
import swal from 'sweetalert';
import SearchInput from "../SearchInput/SearchInput.js";
import {RadioBtn} from '../RadioBtn/RadioBtn';
import validate from './scheduledReportValidate.js';
import * as $ from 'jquery';

export class ScheduledReportSetting extends React.Component {
    constructor(props){
        super(props);
        this.state={
            alertClass: 'd-none',
            scheduledReportList: [],
            successMessage: '',
            errorMsg: 'Please add recipients',
            currentScheduledReport: {},
            reportTypeOptions: {
            disable: 'Disable',
            humidity_csv_report: 'Temperature/Humidity CSV Report',
            humidity_pdf: 'Temperature/Humidity PDF',
            individual_sensor_csv_report:'Individual sensor CSV report'
                },
            group_id: ''

        };
        this.handleChange = this.handleChange.bind(this);
        this.setCurrentReport = this.setCurrentReport.bind(this);
        this.deleteReport = this.deleteReport.bind(this);
    }
    componentWillReceiveProps (nextProps) {
        if (this.state.scheduledReportList.length < 1){
            this.setState({scheduledReportList: nextProps.scheduledReportReducer.scheduledReportList});
        }
        if (nextProps.scheduledReportReducer.updated){
            $('.close_gateway_modal').click();
            this.setState({alertClass: 'fade in',scheduledReportList: nextProps.scheduledReportReducer.scheduledReportList});
            setTimeout(() => this.setState({alertClass: 'fade up'}) ,2000);
            nextProps.scheduledReportReducer.updated = false;
        }
        if (nextProps.scheduledReportReducer.deleted){
            this.setState({alertClass: 'fade in',scheduledReportList: nextProps.scheduledReportReducer.scheduledReportList,successMessage: 'Scheduled Report deleted successfully'});
            setTimeout(() => this.setState({alertClass: 'fade up'}) ,2000);
            nextProps.scheduledReportReducer.deleted = false;
        }

    }
    handleChange(params,type) {
        let state = store.getState();
        let currentScheduledReport = this.state.currentScheduledReport;
        currentScheduledReport['group_name'] = params['name'];
        state.form.scheduledReportForm.values.scheduled_report.group_id = params['id'];
        this.setState({group_id: params['id'],currentScheduledReport: currentScheduledReport});
    }
    componentDidMount(){
        //Custom();
    }
    setCurrentReport(scheduledReport) {
        let state = store.getState();
        store.dispatch(reset('scheduledReportForm'));
        if (scheduledReport['id']) {
            state.form.scheduledReportForm.initial.scheduled_report = scheduledReport;
            setTimeout(() =>  {return $('.gateway_name').focus()},500);
            this.setState({title: 'Edit gateway',group_id: scheduledReport.group_id, currentScheduledReport: scheduledReport, successMessage: 'Scheduled Report updated successfully'});
        }else{
            store.dispatch(initialize('scheduledReportForm', {scheduled_report: {}}));
            this.setState({title: 'Activate Devices',group_id: '',currentScheduledReport: {},successMessage: 'Scheduled Report created successfully'})
        }
    }

    deleteReport(id){
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover this Scheduled Report!",
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    className: "btn btn-default class4"
                },
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "btn btn-primary ",
                    closeModal: true
                }
            }
        }).then(function(isConfirm) {
            if (isConfirm) {
                store.dispatch(scheduledReportAction.deleteScheduledReport(id));
            }
        });
    }

    render(){
        const { initialValues,handleSubmit,pristine,auth,invalid,submitFailed}= this.props;
        const options=[{id: 'disable',name:'Disable'},
            {id: 'humidity_csv_report',name: 'Temperature/Humidity CSV Report'},
            {id: "humidity_pdf",name: 'Temperature/Humidity PDF'},
            {id: "individual_sensor_csv_report",name:'Individual sensor CSV report'}];
        return(
            <main className="app-content">
                <div className={`login w-25 alert alert-dismissible alert-success ${this.state.alertClass}`}>
                    {this.state.successMessage}
                    <button className="close" onClick={()=> this.setState({alertClass: 'd-none'})}>x</button>
                </div>
                <div className="app-title border-bottom mb-0">
                    <div className="media-body">
                        <h5 className="mt-0 settings_users_hd_title">Scheduled Reports</h5>
                    </div>

                    <ul className="app-breadcrumb breadcrumb">
                        <li className="emp-title-item">
                            <div className="app-title-right-widget-small">
                                <a href="#" onClick={()=> this.setCurrentReport({})} className="app-title-btn-live app-title-shadow" data-toggle="modal" data-target="#create_schedule">
                                    <span className="mr-4 emp_icn_middle">
                                        <img src={require("../../styles/images/icon/plush12x12.png")} />
                                    </span>
                                    Create Schedule
                                </a>

                            </div>
                        </li>
                    </ul>
                </div>
                <div className="modal fade bd-example-modal-lg" id="create_schedule" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                        <div className="modal-content">
                            <Form onSubmit={handleSubmit} noValidate={true}>
                                <div className="modal-header">
                                    <h5 className="modal-title" id="exampleModalLongTitle">Create a new schedule</h5>
                                    <button type="button" className="close_gateway_modal close emp_opacity_1" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true"><i className="fa fa-times-circle text-primary"></i></span>
                                    </button>
                                </div>
                                <div className="modal-body scheduled-report">

                                    <div className="row">
                                        <div className="col-xl-12">
                                            <SelectField
                                                name="scheduled_report[report_type]"
                                                className="custom-select cst-select-box input_modifier shadow_select_hover"
                                                type="select"
                                                label="Report Type"
                                                options={options}
                                                parentClass="form-group"
                                                />
                                            <div className="form-group">
                                                <label className="label_modifier">Repeat</label>
                                                <ul className="checkbox_list_inline">
                                                    <li className="checkbox_label_item sm_checkbox_label_item">
                                                        <div className="option_list mb-1">
                                                            <div className="custom-control custom-radio">
                                                                <RadioBtn
                                                                    name="scheduled_report[repeat]"
                                                                    type="radio"
                                                                    value="weekly"
                                                                    label="Weekly"
                                                                    labelClass="custom-control-label"
                                                                    id={`repeat_0`}
                                                                    htmlFor={`repeat_0`}

                                                                    />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="checkbox_label_item sm_checkbox_label_item">
                                                        <div className="option_list mb-1">
                                                            <div className="custom-control custom-radio">
                                                                <RadioBtn
                                                                    name="scheduled_report[repeat]"
                                                                    type="radio"
                                                                    value="daily"
                                                                    label="Daily"
                                                                    labelClass="custom-control-label"
                                                                    id={`repeat_1`}
                                                                    htmlFor={`repeat_1`}

                                                                    />

                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="form-group">
                                                <label className="label_modifier">Repeat</label>
                                                <ul className="checkbox_list_inline">
                                                    <li className="checkbox_label_item sm_checkbox_label_item">
                                                        <div className="option_list mb-1">
                                                            <div className="custom-control custom-radio">
                                                                <RadioBtn
                                                                    name="scheduled_report[day_of_repeat]"
                                                                    type="radio"
                                                                    value="mon"
                                                                    label="Mon"
                                                                    labelClass="custom-control-label"
                                                                    id={`day_of_repeat_0`}
                                                                    htmlFor={`day_of_repeat_0`}

                                                                    />

                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="checkbox_label_item sm_checkbox_label_item">
                                                        <div className="option_list mb-1">
                                                            <div className="custom-control custom-radio">
                                                                <RadioBtn
                                                                    name="scheduled_report[day_of_repeat]"
                                                                    type="radio"
                                                                    value="tue"
                                                                    label="Tue"
                                                                    labelClass="custom-control-label"
                                                                    id={`day_of_repeat_1`}
                                                                    htmlFor={`day_of_repeat_1`}

                                                                    />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="checkbox_label_item sm_checkbox_label_item">
                                                        <div className="option_list mb-1">
                                                            <div className="custom-control custom-radio">
                                                                <RadioBtn
                                                                    name="scheduled_report[day_of_repeat]"
                                                                    type="radio"
                                                                    value="wed"
                                                                    label="Wed"
                                                                    labelClass="custom-control-label"
                                                                    id={`day_of_repeat_2`}
                                                                    htmlFor={`day_of_repeat_2`}

                                                                    />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="checkbox_label_item sm_checkbox_label_item">
                                                        <div className="option_list mb-1">
                                                            <div className="custom-control custom-radio">
                                                                <RadioBtn
                                                                    name="scheduled_report[day_of_repeat]"
                                                                    type="radio"
                                                                    value="thu"
                                                                    label="Thu"
                                                                    labelClass="custom-control-label"
                                                                    id={`day_of_repeat_3`}
                                                                    htmlFor={`day_of_repeat_3`}

                                                                    />

                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="checkbox_label_item sm_checkbox_label_item">
                                                        <div className="option_list mb-1">
                                                            <div className="custom-control custom-radio">
                                                                <RadioBtn
                                                                    name="scheduled_report[day_of_repeat]"
                                                                    type="radio"
                                                                    value="fri"
                                                                    label="Fri"
                                                                    labelClass="custom-control-label"
                                                                    id={`day_of_repeat_4`}
                                                                    htmlFor={`day_of_repeat_4`}

                                                                    />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="checkbox_label_item sm_checkbox_label_item">
                                                        <div className="option_list mb-1">
                                                            <div className="custom-control custom-radio">
                                                                <RadioBtn
                                                                    name="scheduled_report[day_of_repeat]"
                                                                    type="radio"
                                                                    value="sat"
                                                                    label="Sat"
                                                                    labelClass="custom-control-label"
                                                                    id={`day_of_repeat_5`}
                                                                    htmlFor={`day_of_repeat_5`}

                                                                    />
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <li className="checkbox_label_item sm_checkbox_label_item">
                                                        <div className="option_list mb-1">
                                                            <div className="custom-control custom-radio">
                                                                <RadioBtn
                                                                    name="scheduled_report[day_of_repeat]"
                                                                    type="radio"
                                                                    value="sun"
                                                                    label="Sun"
                                                                    labelClass="custom-control-label"
                                                                    id={`day_of_repeat_6`}
                                                                    htmlFor={`day_of_repeat_6`}

                                                                    />
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <DateTimeField
                                                type="text"
                                                name="scheduled_report[scheduled_time]"
                                                placeholder=""
                                                label="Time of day"
                                                className="form-control input_modifier time_picker border-right-0"
                                                pickerType='timePicker'
                                                dataFormat="hh:mm A"
                                                parentClass="form-group"
                                                inputGroup={[{
                                                inputGroupClass: 'input-group mb-3',
                                                iconClass: 'fa fa-clock-o text-primary'
                                            }]}
                                                />

                                            <div className="form-group search-member-container">
                                                <label className="label_modifier">Recipient(s)</label>
                                                <div className="input-group mb-3 emp_btn_addons">
                                                    <SearchInput handleChange={this.handleChange} value={this.state.currentScheduledReport.group_name} type="group"/>
                                                    <div className="input-group-append">
                                                        <button onClick={() => {
                                                            $('.close_gateway_modal').click();
                                                            return browserHistory.push('/settings/users');
                                                        }} className="btn btn-primary btn_radius" type="button">
                                                            <span className="mr-2 emp_icn_middle">
                                                                <img src={require("../../styles/images/icon/icn_link.png")} alt="link" />
                                                            </span> Manage</button>
                                                    </div>
                                                </div>

                                                {submitFailed && !this.state.group_id &&
                                                <div className="form-group__error">{this.state.errorMsg}</div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="modal-footer justify-content-between">
                                    <a href="#" className="create-group text-left text-primary" data-dismiss="modal">
                                        <span className="mr-2 emp_icn_middle">
                                            <img src={require("../../styles/images/icon/icn_close.png")} alt="Icon" />
                                        </span>
                                        <span className="ml-2 cancel_btn">Cancel</span>
                                    </a>
                                    <button type="submit" className="btn btn-next btn-primary emp-cst-btn emp-btn">
                                        <span className="mr-2 emp_icn_middle">
                                            <img src={require("../../styles/images/icon/ic_checking.png")} alt="Icon" />
                                        </span>Save</button>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
                <section className="emp_org_settings_sec emp_report_sec">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="table-wrapper mt-3">
                                <div className="table-responsive text-nowrap">
                                    <table className="table table-striped table_history">
                                        <thead>
                                        <tr>
                                            <th scope="col">Report Type</th>
                                            <th scope="col">Repeat</th>
                                            <th scope="col">Send at</th>
                                            <th scope="col">Target</th>
                                            <th scope="col" className="text-right">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.scheduledReportList.map((scheduled_report,index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{this.state.reportTypeOptions[scheduled_report.report_type]}</td>
                                                        <td>{scheduled_report.repeat}</td>
                                                        <td>{scheduled_report.repeat == 'weekly' ? (scheduled_report.day_of_repeat+' '+ scheduled_report.scheduled_time) : scheduled_report.scheduled_time}</td>
                                                        <td>Entire Group</td>
                                                        <td className="text-right">
                                                            <a  href="#" onClick={()=> this.setCurrentReport(scheduled_report)} className="app-title-btn-live app-title-shadow" data-toggle="modal" data-target="#create_schedule">
                                                                <img src={require("../../styles/images/icon/ic_settings.png")} alt="setting" />
                                                            </a>
                                                            <a  onClick={() => this.deleteReport(scheduled_report.id) } href="#"><img className="ml-2" src={require("../../styles/images/icon/icn_trash.png")} /></a>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        scheduledReportReducer: state.scheduledReportReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    scheduledReportAction: bindActionCreators(scheduledReportAction, dispatch)
});


export default reduxForm({
    form: 'scheduledReportForm',
    validate,
    initialValues: {scheduled_report: {}},
    onSubmit: (values, dispatch) => dispatch(scheduledReportAction.updateScheduledReport(values))
})(connect(mapStateToProps, mapDispatchToProps)(ScheduledReportSetting));