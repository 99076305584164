import React from "react";
import PropTypes from "prop-types";
import Tab from "../Tab/Tab";

class Tabs extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            activeTab: this.props.children[0].props.label
        };
    }

    onTabItemClicked = (tab) => {
        this.setState({activeTab: tab});
        if (this.props.onChange) {
            this.props.onChange(tab);
        }
    }

    render() {
        const {children} = this.props;
        const {activeTab} = this.state;

        return (
            <div className="tabs">
                <ol className="tab-list">
                    {children.map((child) => {
                        const {label, className} = child.props;
                        return (
                            <Tab
                                activeTab={activeTab}
                                key={label}
                                label={label}
                                className={className}
                                onClick={this.onTabItemClicked}
                            />
                        )
                    })}
                </ol>
                <div className="tab-content">
                    {children.map((child) => {
                        if (child.props.label !== activeTab) return undefined;
                        return child.props.children;
                    })}
                </div>
            </div>
        )
    }
}


Tabs.propTypes = {
    /**
     * The content of the component.
     */
    children: PropTypes.node,
    /**
     * The component used for the root node.
     * Either a string to use a HTML element or a component.
     */
    component: PropTypes.elementType,
    /**
     * The default value. Use when the component is not controlled.
     */
    defaultValue: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.number, PropTypes.string]),

    /**
     * Callback invoked when new value is being set.
     */
    onChange: PropTypes.func,
    /**
     * The component orientation (layout flow direction).
     * @default 'horizontal'
     */
    orientation: PropTypes.oneOf(['horizontal', 'vertical']),
    /**
     * If `true` the selected tab changes on focus. Otherwise it only
     * changes on activation.
     */
    selectionFollowsFocus: PropTypes.bool,
    /**
     * The size of the component.
     */
    size: PropTypes /* @typescript-to-proptypes-ignore */.oneOfType([
        PropTypes.oneOf(['sm', 'md', 'lg']),
        PropTypes.string,
    ]),

    /**
     * The value of the currently selected `Tab`.
     * If you don't want any selected `Tab`, you can set this prop to `false`.
     */
    value: PropTypes.oneOfType([PropTypes.oneOf([false]), PropTypes.number, PropTypes.string]),

}

export default Tabs;