import store from '../store/store';
import {browserHistory} from 'react-router';
import * as WifiActions from '../actions/wifiAction';

export const wifiData =() => {
    let state = store.getState();
    if ( state.authReducer.user && state.authReducer.user.role == "user") {
        browserHistory.push('/');
    }
    else {
        let wifiNetworks = state.wifiReducer.wifiNetworks;
        store.dispatch(WifiActions.fetchWifiData());
    }
};