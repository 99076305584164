import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import LoadingMessage from "../LoadingMessage/LoadingMessage";
import {ProbesListView} from "./ProbesListView";
import * as ProbesActions from "../../actions/probesActions";

export class ProbesPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    async componentDidMount(){
        await this.props.probesActions.queryProbes();
        this.setState({loading: false});
    }

    render(){
        const { initialValues, handleSubmit }= this.props;
        if (this.state.loading){
            return <LoadingMessage/>;
        }
        return(
            <main className="app-content">
                <div className="app-title border-bottom">
                    <div>
                        <h1>Probes</h1>
                    </div>
                </div>
                <section className="emp_org_settings_sec emp_report_sec">
                    <div className="row">
                        <div className="col-xl-12 ">
                            <ProbesListView
                                items={this.props.probesReducer.probes}
                            />
                        </div>
                    </div>

                </section>
            </main>
        );
    }

}

const mapStateToProps = (state) => {
    return ({
        probesReducer: state.probesReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    probesActions: bindActionCreators(ProbesActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProbesPage);