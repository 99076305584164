import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as WifiActions from '../../actions/wifiAction';
import * as $ from 'jquery';
import swal from 'sweetalert';
import store from '../../store/store';
import WifiForm from './WifiSettingForm';
import {EmpiricBleFactory} from '../../utils/bleClient';
import {isAdmin} from '../../utils/utils';

const SecurityType = {
    wpa_psk: 'WPA(PSK) - Encrypted',
    wpa_enterprise: 'WPA(ENTERPRISES) - Encrypted'
}

export class WifiSetting extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            currentWifiNetwork: {},
            networkList: [],
            successMessage: '',
            alertClass: 'd-none',
            title: 'Add',
            valid: false,
            gatewayConnect: false,
            bleClient: null
        };
        this.setCurrentWifi = this.setCurrentWifi.bind(this);
        this.connectGateway = this.connectGateway.bind(this);
        this.disconnectGateway = this.disconnectGateway.bind(this);
        this.deleteWifi = this.deleteWifi.bind(this);
        this.handleWifiUpdate = this.handleWifiUpdate.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.wifiReducer.updated) {
            nextProps.wifiReducer.updated = false;
            let alert = this.state.networkList.length < 1 ? 'd-none' : 'fade in';
            this.setState({alertClass: alert, networkList: nextProps.wifiReducer.wifiNetworks});
            if (alert === 'fade in') this.scheduleClearMessage();
        }
    }

    scheduleClearMessage = () => {
        this.timer = setTimeout(
            () => {
                this.setState({alertClass: 'fade up'})
                this.timer = setTimeout(() => this.setState({alertClass: 'd-none'}), 225);
            },
            2000
        );
    }

    gatewayConnectButton() {
        if (this.state.gatewayConnect)
            return (
                <button className="btn btn-primary ml-3" onClick={this.disconnectGateway}>
                    Disconnect from Gateway
                </button>
            )
        return (
            <button className="btn btn-primary ml-3" onClick={this.connectGateway}>
                Connect to Gateway <i className="fa-brands fa-bluetooth-b" aria-hidden="true"></i>
            </button>
        )
    }

    async connectGateway() {
        let bleClient = this.state.bleClient;
        if (!bleClient) {
            bleClient = EmpiricBleFactory()
        }
        try {
            await bleClient.request();
            if (bleClient.device) {
                await bleClient.connect();
                await bleClient.setDeviceCharacteristic();
                this.setState({bleClient: bleClient, gatewayConnect: true})
            }

        } catch (err) {
            // to do: this will catch both user cancel and ble connect errors.
            // need to differentiate and show feedback if problem was ble connect.
        }
    }

    async disconnectGateway() {
        let bleClient = this.state.bleClient;
        if (!bleClient) {
            console.log('Something went wrong! no Ble Client but in discconnect gateway!');
            this.setState({gatewayConnect: false})
        } else {
            if (bleClient.disconnect())
                this.setState({gatewayConnect: false})
        }
    }

    async btConnectWifi(wifi) {
        let bleClient = this.state.bleClient;
        if (!bleClient) {
            console.log('Something went wrong! no Ble Client but trying to connect wifi!');
            this.setState({gatewayConnect: false})
        } else {
            let payload = {ssid: wifi.name, pass: wifi.password};
            if (wifi.security_type === "wpa_enterprise")
                payload.username = wifi.username;
            await bleClient.writeWifi(payload);
            swal({
                icon: 'success',
                title: 'Bluetooth Connect',
                text: `Wifi ${wifi.name} successfully connected`
            });
        }
    }

    setCurrentWifi(wifi) {
        if (wifi['id']) {
            setTimeout(() => {
                return $('.input_modifier').focus()
            }, 500);
            this.setState({title: 'Edit', currentWifiNetwork: wifi, successMessage: 'Wifi updated successfully'});
        } else {
            this.setState({title: 'Add', currentWifiNetwork: {}, successMessage: 'Wifi created successfully'})
        }

    }

    deleteWifi(wifi) {
        this.setCurrentWifi(wifi)
        swal({
            title: "Delete Wi-Fi",
            text: `Delete ${wifi.name} Wi-Fi network?`,
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    className: "btn btn-default class4"
                },
                confirm: {
                    text: "Delete",
                    value: true,
                    visible: true,
                    className: "btn btn-primary ",
                    closeModal: true
                }
            }
        }).then((isConfirm) => {
            if (isConfirm) {
                this.setState({successMessage: 'Wifi successfully deleted'});
                store.dispatch(WifiActions.deleteWifiNetwork(wifi.id))
            }
        });
    }

    handleWifiUpdate(wifi) {
        $("#add_wifi_network").modal("hide");
        store.dispatch(WifiActions.createWifiNetwork({wifi_network: {...this.state.currentWifiNetwork, ...wifi}}));
    }

    render() {
        const options = [
            {value: 'wpa_psk', label: 'WPA (PSK)- Encrypted'},
            {value: 'wpa_enterprise', label: 'WPA - Enterprise Encrypted'}
        ];
        const {auth} = this.props;
        return (
            <main className="app-content">

                <div className={`login w-25 alert alert-dismissible alert-success ${this.state.alertClass}`}>
                    {this.state.successMessage}
                    <button className="close" onClick={() => this.setState({alertClass: 'd-none'})}>x</button>
                </div>

                <div className="app-title border-bottom">
                    <div className="media-body">
                        {/*<span className="sub_txt_title setting_user_txt_title">EMPIRIC SOLUTIONS - TEST</span>*/}
                        <h5 className="mt-0 settings_users_hd_title">Wifi</h5>
                    </div>

                    <ul className="app-breadcrumb breadcrumb add-wifi-header-block">
                        {isAdmin(auth.user) &&
                            <li className="emp-title-item">
                                <div className="active-device-btn text-right">
                                    {this.gatewayConnectButton()}
                                </div>
                            </li>
                        }
                        <li className="emp-title-item">
                            <div className="active-device-btn text-right">
                                <a href="#" onClick={() => this.setCurrentWifi({})} className="create-group"
                                   data-toggle="modal" data-target="#add_wifi_network">
                                    <img src={require("../../styles/images/icon/ic_add.png")} alt="add"/> <span>Add a Wi-Fi Network</span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>

                <section className="emp_org_settings_sec emp_report_sec settings_users_sec setting-wifi-sec">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card shadow emp-form-card mb-3 emp_create_report_card border-0 mt-3">
                                <div className="card-body emp_create_report_body border-bottom">
                                    <div className=" cst-table-header">
                                        Wi-Fi Networks
                                    </div>
                                </div>

                                <div className="table-wrapper border-bottom">
                                    <div className="mx-3">
                                        <div className="table-responsive text-nowrap">
                                            <table className="table table_history mb-0">
                                                <tbody>
                                                {this.state.networkList.sort((a, b) => b.priority - a.priority).map((wifi, index) => (
                                                    <tr key={`${wifi.name}-${index}`} className="border-bottom">
                                                        <td className="pl-0 pr-0">{wifi.name}</td>
                                                        <td className="pl-0">{SecurityType[wifi.security_type]}</td>
                                                        {this.state.gatewayConnect &&
                                                            <td className="pr-0 text-right">
                                                                <a href="#" onClick={() => this.btConnectWifi(wifi)}
                                                                   className="create-group">
                                                                    <i className="fa fa-bluetooth fa-2x"
                                                                       aria-hidden="true"></i>
                                                                </a>
                                                            </td>
                                                        }
                                                        <td className="pr-0 text-right">
                                                            <a href="#" data-toggle="modal"
                                                               onClick={() => this.setCurrentWifi(wifi)}
                                                               data-target="#add_wifi_network"><img className="mr-2"
                                                                                                    src={require("../../styles/images/icon/pencil_icn.png")}
                                                                                                    alt="pen"/></a>
                                                            {isAdmin(auth.user) &&
                                                                <a href="#"
                                                                   onClick={(e) => this.deleteWifi(wifi)}>
                                                                    <img
                                                                        className="mr-2"
                                                                        src={require("../../styles/images/icon/icn_trash.png")}
                                                                        alt="trashcan"
                                                                    />
                                                                </a>
                                                            }
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </section>
                {this.renderWifiModalForm(options)}
            </main>
        );
    }

    renderWifiModalForm(options) {
        return <div className="modal fade" id="add_wifi_network" tabIndex="-1" role="dialog"
                    aria-labelledby="add_wifi_network" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">

                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLongTitle">{this.state.title} Wi-Fi
                            Configuration</h5>
                        <button type="button" className="close emp_opacity_1" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">
                                        <img src={require("../../styles/images/icon/icn_close.png")} alt="icn"/>
                                    </span>
                        </button>
                    </div>
                    <WifiForm
                        options={options}
                        wifi={this.state.currentWifiNetwork}
                        handleSubmit={this.handleWifiUpdate}
                        networkList={this.state.networkList}
                    />
                </div>
            </div>
        </div>
    }
}


const mapStateToProps = (state) => {
    return ({
        auth: state.authReducer,
        wifiReducer: state.wifiReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    wifiActions: bindActionCreators(WifiActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(WifiSetting);

