import React from 'react';
import {Link} from "react-router";
import WorkflowScheduleForm from "./WorkflowScheduleForm";
import {WorkflowModel} from '../../api/model/Workflow';


export class WorkflowSchedulePage extends React.Component<{
    onFormSubmit: (values: any, dispatch: any) => Promise<void>,
    workflows: WorkflowModel[],
    buttonText: string,
    initialValues,
    onWorkflowChange: (e) => void,
    selectedWorkflow: WorkflowModel
}, {}> {
    constructor(props) {
        super(props);
    }

    renderHeaderBar() {
        return <div className="row">
            <div className="col-sm-12">
                <div className="app-title border-bottom">
                    <div>
                        <div className="media align-items-center">
                            <Link to="/workflows">
                    <span className="mr-3 emp_icn_middle">
                    <img
                        src={require("../../styles/images/icon/ic_left-arrow.png")}
                        alt="Icon"
                    />
                    </span>
                            </Link>
                            <div className="media-body">
                                <h1 className="mt-0 text-capitalize">
                                    Workflows
                                </h1>
                                <span className="sub_txt_title">
                {" "}
                                    All created workflows {" "}
                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    render() {
        return (
            <main className="app-content">
                <section
                    className="emp_org_settings_sec emp_report_sec emp_circle_box_sec emp_alert_sec emp_temp_alert_sec">
                    {this.renderHeaderBar()}
                    <div className="row">
                        <div className="col-xl-12">
                            <WorkflowScheduleForm
                                onFormSubmit={this.props.onFormSubmit}
                                workflows={this.props.workflows}
                                buttonText={this.props.buttonText}
                                initialValues={this.props.initialValues}
                                onWorkflowChange={this.props.onWorkflowChange}
                                selectedWorkflow={this.props.selectedWorkflow}
                            />
                        </div>
                    </div>
                </section>
            </main>

        );
    }
}