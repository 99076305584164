import axios from 'axios';
import storage from "../utils/storage";
import { BASE_URL } from '../config';

// Fetch all FieldInstances
export const fetchFieldInstances = () => {
    return axios.get(`${BASE_URL()}/api/v1/field_instances`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

// Fetch a single FieldInstance by ID
export const fetchFieldInstance = (id) => {
    return axios.get(`${BASE_URL()}/api/v1/field_instances/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

// Create a new FieldInstance
export const createFieldInstance = (FieldInstanceData) => {
    return axios.post(`${BASE_URL()}/api/v1/field_instances`, FieldInstanceData, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

// Update an existing FieldInstance
export const updateFieldInstance = (id, FieldInstanceData) => {
    return axios.put(`${BASE_URL()}/api/v1/field_instances/${id}`, FieldInstanceData, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

// Delete a FieldInstance
export const deleteFieldInstance = (id) => {
    return axios.delete(`${BASE_URL()}/api/v1/field_instances/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};