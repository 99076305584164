import {alertConstants} from '../constants/alert.constants';
import * as alertApi from "../api/alertApi";

export const fetchAlertData = ()=> ({
    type: alertConstants.FETCH_ALERT_DATA,
    payload: alertApi.fetchAlertData()
});

export const createAlert = (values,successCallback,errorCallback, format=null)=> ({
    type: alertConstants.CREATE_ALERT,
    payload: alertApi.createAlert(values,successCallback,errorCallback, format)
});

export const getAlert = (triggerId, format=null)=> ({
    type: alertConstants.GET_ALERT,
    payload: alertApi.getAlert(triggerId, format)
});

export const updateAlert = (id,values, format=null)=> ({
    type: alertConstants.UPDATE_ALERT,
    payload: alertApi.updateAlert(id,values, format)
});

export const searchAlert = (values)=> ({
    type: alertConstants.SEARCH_ALERT,
    payload: alertApi.searchAlert(values)
});

export const resetAlertForEdit = ()=> ({
    type: alertConstants.RESET_ALERT_FOR_EDIT,
    payload: null
});

export const initializeAlertForEdit = (values)=> ({
    type: alertConstants.INITIALIZE_ALERT_FOR_EDIT,
    payload: values
});

export const deleteAlert = (triggerId)=> ({
    type: alertConstants.DELETE_ALERT,
    payload: alertApi.deleteAlert(triggerId)
});

export const deleteAlerts = (triggerIds)=> ({
  type: alertConstants.DELETE_ALERTS,
  payload: alertApi.deleteAlerts(triggerIds)
});
