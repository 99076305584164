import React from "react";


export class TabWrapper extends React.Component<{label: string, className: string, children: any}> {
    render() {
        return (
            <>
                {this.props.children}
            </>
        );
    }
}
