import { customDashboardConstants } from '../constants/customDashboard.constants';
import { CustomDashboardModel } from "../api/model/CustomDashboard";

export interface State {
    dashboards: CustomDashboardModel[];
    createdDashboard: CustomDashboardModel;
    updatedDashboard: CustomDashboardModel;
    selectedDashboard: CustomDashboardModel;
}

interface Action {
    type: string;
    payload: CustomDashboardModel | CustomDashboardModel[];
}

const initialState: State = {
    dashboards: [],
    createdDashboard: {} as CustomDashboardModel,
    updatedDashboard: {} as CustomDashboardModel,
    selectedDashboard: {} as CustomDashboardModel
};

export const customDashboardReducer = (state: State = initialState, action: Action): State => {
    let newDashboards: CustomDashboardModel[];
    switch (action.type) {
        case customDashboardConstants.LIST_CUSTOM_DASHBOARDS:
        case customDashboardConstants.LIST_CUSTOM_DASHBOARDS_FULFILLED:
            state = {
                ...state,
                dashboards: action.payload as CustomDashboardModel[]
            };
            return state;
        case customDashboardConstants.UPDATE_CUSTOM_DASHBOARD:
        case customDashboardConstants.UPDATE_CUSTOM_DASHBOARD_FULFILLED:
            newDashboards = state.dashboards.filter(dashboard => dashboard.id !== (action.payload as CustomDashboardModel).id);
            newDashboards.push(action.payload as CustomDashboardModel);
            state = {
                ...state,
                updatedDashboard: action.payload as CustomDashboardModel,
                dashboards: newDashboards,
                selectedDashboard: action.payload as CustomDashboardModel,
            };
            return state;
        case customDashboardConstants.CREATE_CUSTOM_DASHBOARD:
        case customDashboardConstants.CREATE_CUSTOM_DASHBOARD_FULFILLED:
            newDashboards = [...state.dashboards];
            newDashboards.push(action.payload as CustomDashboardModel);
            state = {
                ...state,
                createdDashboard: action.payload as CustomDashboardModel,
                dashboards: newDashboards,
                selectedDashboard: action.payload as CustomDashboardModel
            };
            return state;
        case customDashboardConstants.CHANGE_CUSTOM_DASHBOARD:
            state = {
                ...state,
                selectedDashboard: action.payload as CustomDashboardModel,
            };
            return state;
        case customDashboardConstants.DELETE_CUSTOM_DASHBOARD:
        case customDashboardConstants.DELETE_CUSTOM_DASHBOARD_FULFILLED:
            newDashboards = state.dashboards.filter(dashboard => dashboard.id !== (action.payload as CustomDashboardModel).id);
            state = {
                ...state,
                selectedDashboard: {} as CustomDashboardModel,
                dashboards: newDashboards
            };
            return state;
        case customDashboardConstants.BULK_DELETE_CUSTOM_DASHBOARD:
        case customDashboardConstants.BULK_DELETE_CUSTOM_DASHBOARD_FULFILLED:
            newDashboards = state.dashboards.filter(dashboard => {
                //searches for the index of the deleted dashboard. If it returns -1, the dashboard isn't in the deleted dashboard list
                return (action.payload as CustomDashboardModel[]).findIndex(deletedDashboard => deletedDashboard.id === dashboard.id) === -1;
            });
            state = {
                ...state,
                selectedDashboard: {} as CustomDashboardModel,
                dashboards: newDashboards
            };
            return state;
        case customDashboardConstants.GET_CUSTOM_DASHBOARD:
        case customDashboardConstants.GET_CUSTOM_DASHBOARD_FULFILLED:
            state = {
                ...state,
                selectedDashboard: action.payload as CustomDashboardModel
            };
            return state;
        default:
            return state;
    }
};
