import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import ChangePasswordPage from '../../components/ChangePasswordPage/ChangePasswordPage';
import { withRouter } from 'react-router';

//import './login.scss';

export const ChangePassword = ({ params,authActions }) => {
    return (

        <div className="my-login-page">
            <ChangePasswordPage params={params}/>
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch)
});

export default withRouter(connect(null, mapDispatchToProps)(ChangePassword));