import {scheduledReportConstants} from '../constants/scheduledReport.constants.js';

const initialState = {
    apiKeys: {},
    scheduledReportList: [],
    updated: false,
    deleted: false
};

export const scheduledReportReducer = (state = initialState, action) => {
    switch (action.type) {
        case scheduledReportConstants.FETCH_SCHEDULED_REPORT_DATA_FULFILLED:
            state = {
                ...state,
                scheduledReportList: action.payload.scheduled_reports
            };
            return state;
        case scheduledReportConstants.DELETE_SCHEDULED_REPORT_FULFILLED:
            state = {
                ...state,
                scheduledReportList: action.payload.scheduled_reports,
                deleted: true
            };
            return state;
        case scheduledReportConstants.CREATE_SCHEDULED_REPORT_FULFILLED:
            state = {
                ...state,
                scheduledReportList: action.payload.scheduled_reports,
                updated: true
                //event: action.payload.sponsor
            };
            return state;

        default:
            return state;
    }
};