import {gatewayConstants} from '../constants/gateway.constants.js';

const initialState = {
    gateway: {},
    gatewayList: [],
    groupList: [],
    updated: false,
    deleted: false
};

export const gatewayReducer = (state = initialState, action) => {
    switch (action.type) {
        case gatewayConstants.DELETE_GATEWAY_FULFILLED:
            state = {
                ...state,
                gatewayList: action.payload,
                deleted: true
            };
            return state;
        case gatewayConstants.CREATE_GATEWAY_FULFILLED:
            state = {
                ...state,
                gatewayList: action.payload,
                updated: true
            };
            return state;
        case gatewayConstants.FETCH_GATEWAY_DATA_FULFILLED:
            state = {
                ...state,
                gatewayList: action.payload
                //event: action.payload.sponsor
            };
            return state;
        case gatewayConstants.FETCH_GATEWAY_FULFILLED:
            state = {
                ...state,
                gateway: action.payload
                //event: action.payload.sponsor
            };
            return state;

        default:
            return state;
    }
};