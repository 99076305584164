import React, { ChangeEvent } from 'react';
import {SelectField} from "../../ui/Input/SelectField";


interface IntervalOption {
    label: string;
    value: string;
}

interface Props {
    onRefresh: () => Promise<void>;
}

interface State {
    intervalOptions: IntervalOption[];
    selectedInterval: string;
}

class RefreshIntervalComponent extends React.Component<Props, State> {
    private timeoutId: NodeJS.Timeout | null = null;

    constructor(props: Props) {
        super(props);

        this.state = {
            intervalOptions: [
                { label: "Off", value: "off" },
                { label: "1m", value: "1" },
                { label: "2m", value: "2" },
                { label: "5m", value: "5" },
                { label: "15m", value: "15" },
            ],
            selectedInterval: "1",
        };
    }

    componentDidMount() {
        this.refreshData();
    }

    refreshData = () => {
        const { selectedInterval } = this.state;

        if (selectedInterval !== "off") {
            this.props.onRefresh()
            this.timeoutId = setTimeout(this.refreshData, Number(selectedInterval) * 60 * 1000);
        }
    }

    componentWillUnmount() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
    }

    handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
        const selectedInterval: string = event.target.value;
        this.setState({ ...this.state, selectedInterval: selectedInterval }, () => {
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
            }
            this.refreshData();
        });
    }

    render() {
        const { intervalOptions, selectedInterval } = this.state;

        return (
            <SelectField
                name="refreshInterval"
                value={selectedInterval}
                items={intervalOptions}
                onChange={this.handleSelectChange}
                label="Refresh"
            />
        );
    }
}

export default RefreshIntervalComponent;
