import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AssetActions from '../../actions/assetActions';
import * as AuthActions from '../../actions/authActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import {AssetsPage} from "../../components/Asset/AssetsPage";
import {browserHistory} from "react-router";
import {debounce} from "../../utils/debounce";

class AssetsContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.debounceSearch = debounce(this.onSearchInput.bind(this), 600);
        this.debounceSearch = this.debounceSearch.bind(this);
    }

    async componentDidMount() {
        this.setState({loading: true})
        await this.props.assetActions.listAssets();
        this.setState({loading: false})
    }

    onItemClick(item) {
        browserHistory.push(`/assets/${item.id}`);
    }

    async onSearchInput(value) {
        await this.props.assetActions.listAssets({name: value});
    }

    onDeleteItemClick = async (item) => {
        await this.props.assetActions.deleteAsset(item.id);
    }

    render() {
        const {auth, authActions, assets} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <AssetsPage assets={assets}
                                onItemClick={this.onItemClick.bind(this)}
                                onSearchInput={this.debounceSearch}
                                onDeleteItemClick={this.onDeleteItemClick}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    assets: state.assetReducer.assets,
});

const mapDispatchToProps = (dispatch) => ({
    assetActions: bindActionCreators(AssetActions, dispatch),
    authActions: bindActionCreators(AuthActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(AssetsContainer);