import * as formCommentApi from "../api/formCommentsApi";
import { formCommentConstants } from "../constants/formComment.constants";

export const fetchFormComments = (value)=> ({
    type: formCommentConstants.FETCH_FORM_COMMENTS,
    payload: formCommentApi.fetchFormComments(value)
});

export const submitFormComment = (value)=> ({
    type: formCommentConstants.SUBMIT_FORM_COMMENT,
    payload: formCommentApi.createFormComment(value)
});
