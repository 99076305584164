import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {withRouter} from 'react-router';
import * as AuthActions from '../../actions/authActions';
import * as AlertActions from "../../actions/alertAction";

interface StateProps {
    auth: { user: any; isLoggedIn: boolean };
    org: {
        address: string | null;
        default_logo: { url: string | null };
        google_sso_domain: string | null;
        id: number;
        logo: { url: string | null };
        logo_type: string | null;
        name: string;
        system_of_units: string | null;
        temperature_unit: string | null;
    };
}

interface DispatchProps {
    authActions: typeof AuthActions;
    alertActions: typeof AlertActions;
}


type Props = StateProps & DispatchProps & {children: React.ReactElement};


class SOPFormPageContainer extends Component<Props> {


    componentDidMount() {
        this.props.alertActions.resetAlertForEdit();
    }

    render() {
        const {authActions, auth, org} = this.props;
        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>

                {React.cloneElement(this.props.children, {user: auth.user, org,})}
            </div>
        );
    }
}

const mapStateToProps = (state): StateProps => ({
    auth: state.authReducer,
    org: state.generalSettingReducer
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    alertActions: bindActionCreators(AlertActions, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SOPFormPageContainer));
