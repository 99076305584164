import {incidentConstants} from '../constants/incident.constants.js';
import * as Moment from 'moment';

const initialState = {
    incidents: [],
    queryParams:  {
        start_date: calculateDate(-3000),
        end_date: calculateDate(1000)
    }
};

function calculateDate (offset) {
    let format = 'YYYY-MM-DD';
    return Moment.utc(new Date((new Date()).valueOf() + offset * 3600 * 24).toISOString()).format(format);
}

export const incidentReducer = (state = initialState, action) => {
    switch (action.type) {
        case incidentConstants.FETCH_INCIDENT_DATA:
            state = {
                ...state,
                incidents: action.payload
            };
            return state;

        case incidentConstants.FETCH_INCIDENT_FULFILLED:
            state = {
                ...state,
                incident: action.payload
            };
            return state;
        case incidentConstants.FETCH_INCIDENT_DATA_FULFILLED:    
            state = {
                ...state,
                incidents: action.payload
            };
            return state;

        case incidentConstants.UPDATE_QUERY_PARAMS:
            state = {
                ...state,
                queryParams: action.payload.query_params
            }
            return state;

        default:
            return state;
    }
};