

import {assetConstants} from '../constants/asset.constants';
import * as assetApi from "../api/assetApi";

export const listAssets = (data)=> ({
    type: assetConstants.LIST_ASSETS,
    payload: assetApi.listAssets(data)
});

export const getAsset = (values)=> ({
    type: assetConstants.GET_ASSET,
    payload: assetApi.getAsset(values)
});

export const createAsset = (values)=> ({
    type: assetConstants.CREATE_ASSET,
    payload: assetApi.createAsset(values)
});

export const updateAsset = (id,values)=> ({
    type: assetConstants.UPDATE_ASSET,
    payload: assetApi.updateAsset(id,values)
});

export const deleteAsset = (id)=> ({
    type: assetConstants.DELETE_ASSET,
    payload: assetApi.deleteAsset(id)
});

export const resetAsset = () => ({
    type: assetConstants.RESET_ASSET,
    payload: async () => {}
})