import React from "react";
import Select from "react-select";
import {Field} from 'redux-form';

import {listAssets} from "../../api/assetApi";
import {searchDeviceActive} from "../../api/deviceApi";
import {ShipmentContactsApi} from "../../api/shipmentContactsApi";
import {ValidationFunction} from "../../utils/validateFunctions";


interface Props {
    name: string,
    isMulti: boolean,
    objectSet: string,
    label: string,
    placeholder?: string,
    validate?: ValidationFunction[],
}

interface State {
    data: object[],
}

export default class ObjectSetDropdown extends React.Component<Props, State>{
    constructor(props) {
        super(props);
        this.state = {
            data: [],
        }
    }

    async loadData(objectSet){
        switch (objectSet) {
            case "Devices":
                const devices = await searchDeviceActive({});
                return devices.map(device => ({label: device.name, value: device.identity}));
            case "Assets":
                const assets = await listAssets({});
                return assets.map(asset => ({label: asset.name, value: asset.id}));
            case "ShipmentContacts":
                const shipmentContactsApi = new ShipmentContactsApi();
                const contacts = await shipmentContactsApi.queryShipmentContacts();
                return contacts.map(contact => ({label: contact.contact_name, value: contact.id}))
        }
    }

    async componentDidMount() {
        const data = await this.loadData(this.props.objectSet);
        this.setState({data});
    }

    renderSelect({input, className, type, placeholder, meta: {touched, error, submitFailed}}) {
        let errorStyle = "";
        let errorMessage : React.ReactElement | undefined;
        if (submitFailed && error) {
            errorStyle = "input_error"
            errorMessage = <div className="form-group__error">{error}</div>
        }

        //TODO: figure out which font family to use
        const customStyles = {
            control: (provided, state) => ({
                ...provided,
                ...provided, borderRadius: "1px", minHeight: "48px", border: "1px solid currenctColor"
            }), dropdownIndicator: (styles) => ({
                ...styles, paddingTop: 0, paddingBottom: 0, padding: 0, border: "none"
            }), placeholder: (provided, state) => ({
                ...provided,
                ...provided, color: "#6f81a5"
            }), indicatorSeparator: (provided, state) => ({ display: "none" })
        };

        return (<React.Fragment>
            <div>
                <Select
                    {...input}
                    className={errorStyle}
                    options={this.state.data}
                    styles={customStyles}
                    isMulti={this.props.isMulti}
                    isSearchable
                    onBlur={() => null} /*TODO: this somehow makes it work on mobile, it should be refactored*/
                    placeholder={this.props.placeholder || "Select..."}
                />
            </div>
            <div>
                {errorMessage}
            </div>
        </React.Fragment>)
    }


    render() {
        return (
            <div className="form-group">
                <div className="form-group col-xl-12 col-lg-12 pl-0 pr-0">
                    <label className="label_modifier col-sm-12 pl-0">{this.props.label}</label>
                    <Field name={this.props.name}
                           component={this.renderSelect.bind(this)} type="select" validate={this.props.validate}/>
                </div>
            </div>
        );
    }
}
