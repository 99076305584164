export const areaConstants = {
    GET_AREA: 'GET_AREA',
    GET_AREA_FULFILLED: 'GET_AREA_FULFILLED',

    LIST_AREAS: 'LIST_AREAS',
    LIST_AREAS_FULFILLED: 'LIST_AREAS_FULFILLED',

    CREATE_AREA: 'CREATE_AREA',
    CREATE_AREA_FULFILLED: 'CREATE_AREA_FULFILLED',

    DELETE_AREA: 'DELETE_AREA',
    DELETE_AREA_FULFILLED: 'DELETE_AREA_FULFILLED',

    UPDATE_AREA: 'UPDATE_AREA',
    UPDATE_AREA_FULFILLED: 'UPDATE_AREA_FULFILLED',

    RESET_AREA: 'RESET_AREA',
    RESET_AREA_FULFILLED: 'RESET_AREA_FULFILLED'
};