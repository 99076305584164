import React from "react";
import PropTypes from 'prop-types';

export default class DropdownSelect extends React.Component {

    constructor(props) {
        super(props);

        this.handleDropdownClick = this.handleDropdownClick.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleDropdownSelect = this.handleDropdownSelect.bind(this);
    }

    handleDropdownClick(e) {
        // click on dropdown should show or hide the dropdown <ul/>
        const {nextElementSibling} = e.target;
        nextElementSibling.style.display = nextElementSibling.style.display === 'block' ? 'none' : 'block';

        if (this.props.onMenuClickListener) {
            this.props.onMenuClickListener(e);
        }
    }

    handleMouseLeave(e) {
        e.currentTarget.style.display = "none";
    }

    handleDropdownSelect(e) {
        // this will hide the dropdown <ul/> element after selecting a dropdown <li/>
        e.target.parentNode.style.display = "none";

        if (this.props.onItemClickListener) {
            this.props.onItemClickListener(e);
        }
    }

    render() {
        const {items, label} = this.props;
        return (
            <div className="dropdown">
                <div
                    className="btn btn-outline-secondary dropdown-toggle"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={this.handleDropdownClick}
                >
                    {label}
                </div>
                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink" onMouseLeave={this.handleMouseLeave}>
                    <li>{!items.length && "Loading..."}</li>

                    {items.map((value, idx) => {
                        return <li
                            className="dropdown dropend dropdown-item"
                            key={idx}
                            value={value}
                            onClick={this.handleDropdownSelect}
                        >
                            {value}
                        </li>
                    })}
                    {this.props.children}
                </ul>
            </div>
        )
    }
}

DropdownSelect.propTypes = {
    label: PropTypes.string,
    items: PropTypes.array,
    onMenuClickListener: PropTypes.func,
    onItemClickListener: PropTypes.func
}