import {BASE_URL} from "../config";
import storage from "../utils/storage";
import axios from 'axios';
import * as Moment from 'moment';
import LocationTelemetry from './model/locationTelemetry';
import {GeoJsonGeometry} from './model/locationTelemetry';


export function getByDeviceId(request) {

    let format = 'YYYY-MM-DD';

    let query = {};

    if (request.start_time && request.end_time) {
        query.start_time = Moment.utc(request.start_time).format(format);
        query.end_time =  Moment.utc(request.end_time).format(format);
    }

    query.cursor = request.cursor;
    query.limit = request.limit;

    return axios.get(`${BASE_URL()}/api/v1/location_telemetry/${request.device_id}`, {
        params: query,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response.data.items.map((item) => {
            const geometry = new GeoJsonGeometry(
                item.geometry.type,
                item.geometry.coordinates.map(coord => Number(coord)),
                item.geometry.properties);
            return new LocationTelemetry(item.device_id, item.timestamp, geometry, item.device_name);
        })
    });
}

export function listDeviceLocations(request) {
    let query = {};

    query.cursor = request.cursor;
    query.limit = request.limit;

    return axios.get(`${BASE_URL()}/api/v1/location_telemetry`, {
        params: query,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response.data.items.map((item) => {
            const geometry = new GeoJsonGeometry(
                item.geometry.type,
                item.geometry.coordinates.map(coord => Number(coord)),
                item.geometry.properties);
            return new LocationTelemetry(item.device_id, item.timestamp, geometry, item.device_name);
        })
    });
}