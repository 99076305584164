import {areaTypeConstants} from '../constants/areaType.constants'

const initialState = {
    areaType: {},
    areaTypes: [],
    updated: false,
};

export const areaTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case areaTypeConstants.GET_AREA_TYPE_FULFILLED:
            state = {
                ...state,
                areaType: action.payload,
                updated: true
            };
            return state;
        case areaTypeConstants.LIST_AREA_TYPES_FULFILLED:
            state = {
                ...state,
                areaTypes: action.payload,
                updated: true
            }
            return state;
        case areaTypeConstants.UPDATE_AREA_TYPE_FULFILLED:
        case areaTypeConstants.CREATE_AREA_TYPE_FULFILLED:
            state = {
                ...state,
                areaType: action.payload,
                updated: true
            };
            return state;
        case areaTypeConstants.DELETE_AREA_TYPE_FULFILLED:
            state = {
                ...state,
                areaTypes: state.areaTypes.filter(areaType => areaType.id !== action.payload.id),
                updated: true
            }
            return state;
        case areaTypeConstants.RESET_AREA_TYPE_FULFILLED:
            state = {
                ...state,
                areaType: {},
                updated: true
            }
            return state;
        default:
            return state;
    }
};