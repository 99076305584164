import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import {FailureModeModel} from './model/FailureMode';


export class FailureModeApi {
    constructor() {

    }

    listFailureModes(){
        return axios.get(`${BASE_URL()}/api/v1/failure_modes`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': storage.get('token')
            }
        }).then(response => {
            return response.data['items'].map(item => FailureModeModel.fromJson(item));
        });
    };

    getFailureMode(failureModeId){
        return axios.get(`${BASE_URL()}/api/v1/failure_modes/${failureModeId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': storage.get('token')
            }
        }).then(response => {
            return FailureModeModel.fromJson(response.data.item);
        });
    }
}