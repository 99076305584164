import React, { useState, useEffect } from 'react'
import { FieldError } from './FieldError'

const convertDate = (date: string, format: string) => {
  // Format fieldVal based on field type
  if (format === 'datetime-btn') {
    return (new Date(date)).toLocaleString()
  }
  if (format === 'date-btn') {
    return (new Date(date)).toLocaleDateString()
  }
  if (format === 'time-btn') {
    return (new Date(date)).toLocaleTimeString()
  }
}

export const DateButton = ({ value, label, fieldType, onChange, classes, error }) => {
  const [clickedDate, setDate] = useState<string | null>(null)

  useEffect(() => {
    if (value) {
      setDate(value)
    }
  }, [value])

  const handleStart = () => {
    const event = (new Date()).toISOString()
    setDate(event)
    onChange(event)
  }


  return (
    <div className={`my-3 col-6 ${classes}`}>
      <div className='form-input'>
        {!clickedDate && (
          <button className="btn btn-primary" onClick={handleStart}>
            <div id="datebutton">{label}</div>
          </button>
        )}
        {clickedDate && (
          <p className='mb-0'>
            {label && `${label}:`} {convertDate(clickedDate, fieldType)}
          </p>
        )}
      </div>

      {error && <FieldError error={error} />}
    </div>
  )
}

export default DateButton