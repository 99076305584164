import swal from 'sweetalert';

export const confirmOrCancelDialogue = async (msg: string): Promise<boolean> => {
    let confirm = await swal({
        title: msg,
        text: "Are you sure?",
        buttons: {
            cancel: {
                text: "Cancel",
                value: false,
                visible: true,
                className: "btn btn-default class4"
            },
            confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "btn btn-primary ",
                closeModal: true
            }
        }
    });
    return confirm;
}

export const confirmDialogue = async (title: string, text: string = "Success!"): Promise<boolean> => {
    let confirm = await swal({
        title: title,
        text: text,
        buttons: {
            confirm: {
                text: "OK",
                value: true,
                visible: true,
                className: "btn btn-primary ",
                closeModal: true
            }
        }
    });
    return confirm;
}
