import {groupConstants} from '../constants/group.constants';
import * as groupApi from "../api/groupApi";

export const fetchGroupData = ()=> ({
    type: groupConstants.FETCH_GROUP_DATA,
    payload: groupApi.fetchGroupData()
});

export const fetchGroup = (values)=> ({
    type: groupConstants.FETCH_GROUP,
    payload: groupApi.fetchGroup(values)
});

export const createGroup = (values)=> ({
    type: groupConstants.CREATE_GROUP,
    payload: groupApi.createGroup(values)
});

export const updateGroup = (id,values)=> ({
    type: groupConstants.UPDATE_GROUP,
    payload: groupApi.updateGroup(id,values)
});

export const deleteGroup = (id)=> ({
    type: groupConstants.DELETE_GROUP,
    payload: groupApi.deleteGroup(id)
});