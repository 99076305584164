import React from 'react';
import {Table, Column} from "../../../../api/model/DatasetTable";

interface Props {
    table: Table;
}

export class PartitionsTabPage extends React.Component<Props> {
    render() {
        const {table} = this.props;

        return (
            <div className="container-fluid">
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Data Type</th>
                            <th>Nullable</th>
                            <th>Comment</th>
                        </tr>
                        </thead>
                        <tbody>
                        {table.partition_keys.map((partition: Column, index: number) => (
                            <tr key={index}>
                                <td>{partition.name}</td>
                                <td>{partition.data_type}</td>
                                <td>{partition.nullable ? 'Yes' : 'No'}</td>
                                <td>{partition.comment}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}
