import axios from 'axios';
import storage from "../utils/storage";
import { BASE_URL } from '../config';


// Fetch form comments for a specific form
export const fetchFormComments = (id) => {
    return axios.get(`${BASE_URL()}/api/v1/form_comments/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

// Create a new form comment
export const createFormComment = (commentData) => {
    return axios.post(`${BASE_URL()}/api/v1/form_comments`, commentData, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};
