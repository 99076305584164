import React from "react";
import {connect} from "react-redux";
import {formValueSelector, reduxForm} from "redux-form";

import {Link} from "react-router";

import TimeSlider from "./TimeSlider.js";
import ConnectionTriggerCard from "./ConnectionTriggerCard";
import GenericTriggerCard from "./GenericTriggerCard.js";
import GenericMetricCard from "./GenericMetricCard.js";
import GeofenceTriggerCard from "./GeofenceTriggerCard.js";
import * as AlertActions from "../../actions/alertAction";
import {AlertTypes} from "./AlertFormConstants";
import TemperatureTriggerCard from "./TemperatureTriggerCard";
import {getTemperatureString} from "../../utils/formatHelper";

const createInitialValues = (pathName, conditionUnits) => {
    const emptyValues = {
        title: "",
        level: "warning",
        conditions: [{condition: "<", threshold: ""}],
        field: "",
        schema: "",
        trigger_minutes: "",
        client_id: "",
        description: "",
        period: 300,
        devices: [],
        groups: [],
        conditionUnits: "",
        transitionType: "EXIT",
    };
    switch (pathName) {
        case "/temperature_alert":
            return {
                ...emptyValues,
                ...{
                    type: AlertTypes.TEMPERATURE,
                    field: "probe_temperature",
                    schema: "monitor",
                    title: "Temperature",
                    alertTitle: "Alert if temperature is",
                    conditionUnits: conditionUnits,
                },
            };
        case "/battery_alert":
            return {
                ...emptyValues,
                ...{
                    type: AlertTypes.BATTERY,
                    field: "battery_percentage",
                    schema: "monitor",
                    title: "Battery",
                    alertTitle: "Alert if battery percentage is",
                    conditionUnits: "%",
                },
            };
        case "/connection_alert":
            return {
                ...emptyValues,
                ...{
                    type: AlertTypes.CONNECTION,
                    field: "NULL",
                    title: "Connection",
                    schema: "missing_data",
                    alertTitle: "Connection",
                },
            };
        case "/humidity_alert":
            return {
                ...emptyValues,
                ...{
                    type: AlertTypes.HUMIDITY,
                    field: "humidity",
                    schema: "monitor",
                    title: "Humidity",
                    alertTitle: "Alert if humidity is",
                    level: "warning",
                    conditionUnits: "%",
                },
            };
        case "/geofence_alert":
            return {
                ...emptyValues,
                ...{
                    type: AlertTypes.GEOFENCE,
                    field: "geofence",
                    schema: "geofence",
                    title: "Geofence",
                    alertTitle: "Alert if humidity is",
                    level: "warning",
                    conditionUnits: "%",
                    conditions: [{type: "polygon", coordinates: [], properties: {transition_type: "EXIT"}}],
                },
            };
        case "/metric_alert":
            return {
                ...emptyValues,
                ...{
                    type: AlertTypes.METRIC,
                    field: null,
                    schema: "metric",
                    title: "Metric",
                    alertTitle: "Alert if metric is",
                    level: "warning",
                    conditionUnits: "",
                    period: 300
                },
            };
        default:
            return emptyValues; // should never get here
    }
};

const validate = (values) => {
    const errors = {};
    const requiredFields = ["title", "trigger_minutes"];
    const atLeastOneValueFields = ["devices", "groups", "conditions"];
    // Required fields checks
    for (let field of requiredFields) {
        if (!values[field]) errors[field] = "Required";
    }
    for (let field of atLeastOneValueFields) {
        if (values[field].length < 1)
            errors[field] = {_error: "At least 1 value is required"};
    }


    // valid values check
    let minutes = Number(values["trigger_minutes"]);

    if (minutes === 0) {
        errors["trigger_minutes"] = {
            _error: "Value must be greater than 0"
        };
    }

    if (minutes && minutes % 5 !== 0) {
        let low = 5 * Math.floor(minutes / 5);
        let high = 5 * Math.ceil(minutes / 5);
        errors[
            "trigger_minutes"
            ] = `Value must be multiple of 5 minutes.  Nearest valid values are ${low} and ${high}`;
    }
    values["conditions"].forEach((condition) => {
        if (!condition.threshold)
            errors["conditions"] = {
                _error: "Each condition must have a threshold value.",
            };

        if (condition.hasOwnProperty("coordinates")) {
            if (condition.coordinates.length < 3) {
                errors["conditions"] = [
                    {
                        coordinates: {
                            _error: "Need at least 3 coordinates.",
                        }
                    }
                ]
            }
        }
    });
    return errors;
};

export class AlertForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: "0",
            deviceList: [],
            userList: [],
            type: "",
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if ("alert_properties" in this.props) {
            // for edit, curent alert provided in alertProperties
            this.props.dispatch(
                AlertActions.initializeAlertForEdit(this.props.alert_properties, this.props.alert_properties.conditionUnits)
            );
        } else {
            // for create, initial values based on path
            const conditionUnits = getTemperatureString(this.props.generalSettingsReducer.organization.system_of_units);
            this.props.dispatch(
                AlertActions.initializeAlertForEdit(
                    createInitialValues(window.location.pathname, conditionUnits)
                )
            );
        }
    }

    render() {
        const {handleSubmit, submitButtonLabel} = this.props;
        let navStyle = {
            width: this.state.width,
        };
        return (
            <main className="app-content">
                <section
                    className="emp_org_settings_sec emp_report_sec emp_circle_box_sec emp_alert_sec emp_temp_alert_sec">
                    {this.renderHeaderBar()}
                    {this.renderAlertInputForm(handleSubmit, navStyle, submitButtonLabel)}
                </section>
            </main>
        );
    }

    renderHeaderBar() {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="app-title border-bottom">
                        <div>
                            <div className="media align-items-center">
                                <Link to="/alerts">
                  <span className="mr-3 emp_icn_middle">
                    <img
                        src={require("../../styles/images/icon/ic_left-arrow.png")}
                        alt="Icon"
                    />
                  </span>
                                </Link>

                                <div className="media-body">
                                    <h1 className="mt-0 text-capitalize">
                                        {this.state.type} Alert
                                    </h1>
                                    <span className="sub_txt_title">
                    {" "}
                                        Choose an alert type to configure{" "}
                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderAlertInputForm(handleSubmit, navStyle, submitButtonLabel) {
        // TODO: Refactor this functionality. A better design would be to have the alerts something like /alert/<type>
        return (
            <div className="row">
                <div className="col-xl-12">
                    <form
                        key="alertForm1"
                        onSubmit={handleSubmit(this.props.onAlertSubmit)}
                        className="emp_create_report_form mt-3"
                        noValidate
                    >
                        {this.renderTriggerSectionByType()}

                        <div className="bg_transparent_box">
                            <hr/>
                            {/*// TODO: Reenable do not distrub feature*/}
                            {/*{this.renderDoNotDisturb()}*/}
                            {/*<hr className="my-4"/>*/}
                        </div>

                        <div className="active-device-btn text-right">
                            <a href="/alerts" className="create-group mr-4">
                                <img
                                    src={require("../../styles/images/icon/icn_close.png")}
                                    alt="Icon"
                                />
                                <span className="ml-2">Cancel</span>
                            </a>
                            <button className="btn btn-next btn-primary emp-cst-btn emp-btn">
                <span className="mr-2 emp_icn_middle">
                  <img
                      src={require("../../styles/images/icon/ic_checking.png")}
                      alt="Icon"
                  />
                </span>
                                {submitButtonLabel}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    renderDoNotDisturb() {
        return (
            <div>
                <div className="form-group">
                    <span className="bg_transparent_title ml-0">Do not disturb</span>
                </div>
                <div className="form-group">
                    <div className="media align-items-center">
            <span className="mr-3">
              <label className="emp_switch">
                <input checked="" type="checkbox"/>
                <span className="emp_slider round"></span>
              </label>
            </span>
                        <div className="media-body">
                            <p className="mb-0">
                                Disable notifications between the following times (shown in Los
                                Angeles - PST/PDT):
                            </p>
                        </div>
                    </div>
                </div>
                <div className="col-sm-7">
                    <div className="form-group custom-slider">
                        <TimeSlider/>
                    </div>
                </div>
                <div className="form-group mt-50">
                    <a href="#" className="create-group mr-4">
                        <img
                            src={require("../../styles/images/icon/ic_add.png")}
                            alt="ic_add"
                        />{" "}
                        &nbsp;<span>Add another time range</span>
                    </a>
                </div>
            </div>
        );
    }

    renderTriggerSectionByType() {
        // TODO: This should be refactored to use the viewModel design pattern. This would allow easily injecting in the data needed for the form with code reuse
        let {
            type,
            title,
            alertTitle,
            level,
            conditionUnits,
            field,
            transitionType,
            conditions,
            period
        } = this.props.initialValues;
        const isNewAlert = !this.props.hasOwnProperty("alert_properties");

        switch (type) {
            case AlertTypes.TEMPERATURE:
                return (
                    <TemperatureTriggerCard
                        title={title}
                        alertTitle={alertTitle}
                        level={level}
                        field={field}
                        conditionUnits={conditionUnits}
                        alertType={type.name}
                        isNewAlert={isNewAlert}
                        devices={this.props.devices}
                        groups={this.props.groups}
                    />
                );
            case AlertTypes.HUMIDITY:
                return (
                    <GenericTriggerCard
                        title={title}
                        alertTitle={alertTitle}
                        level={level}
                        conditionUnits={conditionUnits}
                        alertType={type.name}
                        isNewAlert={isNewAlert}
                        devices={this.props.devices}
                        groups={this.props.groups}
                    />
                );
            case AlertTypes.BATTERY:
                return (
                    <GenericTriggerCard
                        title={title}
                        alertTitle={alertTitle}
                        level={level}
                        conditionUnits={conditionUnits}
                        alertType={type.name}
                        isNewAlert={isNewAlert}
                        devices={this.props.devices}
                        groups={this.props.groups}
                    />
                );

            case AlertTypes.CONNECTION:
                return (
                    <ConnectionTriggerCard
                        title={title}
                        level={level}
                        alertType={type.name}
                        isNewAlert={isNewAlert}
                        devices={this.props.devices}
                        groups={this.props.groups}
                    />
                );

            case AlertTypes.GEOFENCE:
                return (
                    <GeofenceTriggerCard
                        title={title}
                        level={level}
                        alertType={type.name}
                        transitionType={transitionType}
                        conditions={conditions}
                        isNewAlert={isNewAlert}
                        devices={this.props.devices}
                        groups={this.props.groups}
                    />
                );
            case AlertTypes.METRIC:
                return (
                    <GenericMetricCard
                        title={title}
                        alertTitle={alertTitle}
                        level={level}
                        conditionUnits={conditionUnits}
                        alertType={type.name}
                        isNewAlert={isNewAlert}
                        field={field}
                        period={period}
                        devices={this.props.devices}
                        groups={this.props.groups}
                    />
                );

            default:
                return null; // First time before component did mount! throw Error(`Unknown type found for ${schema}`)
        }
    }
}

const mapStateToProps = (state) => {
    return {
        device: state.deviceReducer,
        initialValues: state.alertReducer.alert,
        generalSettingsReducer: state.generalSettingReducer
    };
};

const FORM_NAME = "alertForm";
AlertForm = connect(mapStateToProps)(
    reduxForm({
        form: FORM_NAME,
        enableReinitialize: true,
        validate,
    })(AlertForm)
);

const selector = formValueSelector(FORM_NAME)
AlertForm = connect(state => {
    const devices = selector(state, 'devices')
    const groups = selector(state, 'groups')
    return {
        devices,
        groups
    }
})(AlertForm)

export default AlertForm;
