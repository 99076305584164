import React from "react";
import {Field} from 'redux-form';
import {ValidationFunction} from "../../utils/validateFunctions";


export default class FormRadioButtonComponent extends React.Component<{
    name: string,
    helpText?: string | undefined,
    items: { value: any, label: string }[],
    default?: { value: any, label: string }[],
    validate?: ValidationFunction[],
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void,
    label: string,
}, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="form-group">
                <label className="label_modifier">{this.props.label}</label>
                <div className={"emp-radio-btn-container"}>
                    {this.props.items.map((item, idx) => <label key={idx}><Field
                        name={this.props.name}
                        value={item.value}
                        type={"radio"}
                        onChange={this.props.onChange}
                        component={"input"}/>
                        <span>{item.label}</span>
                    </label>)}
                </div>
                <span className="label_modifier">{this.props.helpText}</span>
            </div>
        )
    }
}
