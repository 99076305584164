import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as DeviceTypeActions from '../../actions/deviceTypesActions';
import * as DeviceTypeMetricsActions from '../../actions/deviceTypeMetricsActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {browserHistory, withRouter} from 'react-router';
import {DeviceTypeDetailPage} from '../../components/DeviceTypes/DeviceTypeDetailPage';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import DeviceType from "../../api/model/DeviceType";
import DeviceTypeMetric from "../../api/model/DeviceTypeMetric";

interface DeviceTypeDetailPageContainerProps {
    auth: any;
    deviceType: DeviceType;
    deviceTypeMetrics: DeviceTypeMetric[];
    params: { id: string };
    authActions: typeof AuthActions;
    deviceTypeActions: typeof DeviceTypeActions;
    deviceTypeMetricsActions: typeof DeviceTypeMetricsActions;
}

interface DeviceTypeDetailPageContainerState {
    loading: boolean;
}

class DeviceTypeDetailPageContainer extends React.Component<DeviceTypeDetailPageContainerProps, DeviceTypeDetailPageContainerState> {
    constructor(props: DeviceTypeDetailPageContainerProps) {
        super(props);
        this.state = {
            loading: false,
        }
    }

    async componentDidMount() {
        const id = this.props.params.id;
        this.setState({loading: true});
        await this.props.deviceTypeActions.getDeviceType(id);
        await this.props.deviceTypeMetricsActions.listDeviceTypeMetrics({deviceTypeId: id});
        this.setState({loading: false});
    }

    onTabChange = async (tab) => {
        switch (tab) {
            case "Assignments":
                break;
            case 'Metrics':
                break;
            default:
                break;
        }
    }

    onAddMetricSubmit = async (values) => {
        await this.props.deviceTypeMetricsActions.createDeviceTypeMetric(
            this.props.params.id, {
                device_type_metric: {
                    name: values.name,
                    device_type_id: this.props.params.id,
                    display_name: values.display_name,
                    description: values.description,
                }
            });
    }

    onDeleteMetricClick = async (deviceTypeMetric: DeviceTypeMetric) => {
        await this.props.deviceTypeMetricsActions.deleteDeviceTypeMetric(this.props.params.id, deviceTypeMetric.name);
    }

    onItemClick = async (item) => {

    }

    render() {
        const {auth, authActions, deviceType, deviceTypeMetrics} = this.props;
        const {loading} = this.state;

        return (
            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <DeviceTypeDetailPage
                        deviceType={deviceType}
                        deviceTypeMetrics={deviceTypeMetrics}
                        onTabChange={this.onTabChange}
                        onAddMetricSubmit={this.onAddMetricSubmit}
                        onDeleteMetricClick={this.onDeleteMetricClick}
                        onItemClick={this.onItemClick}

                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state: any) => ({
    auth: state.authReducer,
    deviceType: state.deviceTypesReducer.deviceType,
    deviceTypeMetrics: state.deviceTypeMetricsReducer.deviceTypeMetrics,
});

const mapDispatchToProps = (dispatch: any) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    deviceTypeActions: bindActionCreators(DeviceTypeActions, dispatch),
    deviceTypeMetricsActions: bindActionCreators(DeviceTypeMetricsActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceTypeDetailPageContainer));
