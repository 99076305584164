import React from 'react';

export const createFormFilters = (queryBuilder) => {
    const filters = [];
    queryBuilder.forEach(query => {
        let {filtername, operator, value} = query;

        if(!value) return;

        if(isNumber(value)){
            value = Number(value);
        }

        if (isString(value)) {
            value = value.toLowerCase()
        }

        switch (operator) {
            case "=":
                filters.push((item) => formatFilterValue(convertToNumber(item[filtername])) === value);
                break;
            case "<":
                filters.push((item) => formatFilterValue(convertToNumber(item[filtername])) < value);
                break;
            case ">":
                filters.push((item) => formatFilterValue(convertToNumber(item[filtername])) > value);
                break;
            case ">=":
                filters.push((item) => formatFilterValue(convertToNumber(item[filtername])) >= value);
                break;
            case "<=":
                filters.push((item) => formatFilterValue(convertToNumber(item[filtername])) <= value);
                break;
            case "!=":
                filters.push((item) => formatFilterValue(convertToNumber(item[filtername])) !== value);
                break;
            case "not Contains":
                filters.push((item) => !formatFilterValue(item[filtername]).includes(value));
                break;
            case "contains":
                filters.push((item) => formatFilterValue(item[filtername]).includes(value));
                break;
            case "is One Of":
                const searchTerms = value.split(",").map(term => term.trim());
                filters.push((item) => {
                    const res = searchTerms.map((term) => {
                        return formatFilterValue(item[filtername]).includes(term);
                    });
                    return res.some((element)=>element === true);
                });
                break;
        }
    });
    return filters;
}

function isString(str) {
    return typeof(str) === 'string';
}

function isNumber(value) {
    const number = Number(value);
    return !Number.isNaN(number);
}

function convertToNumber(value){
    const number = Number(value);
    if (!Number.isNaN(number)) return number;
    return value;
}

function formatFilterValue(value) {
    if (isString(value)) {
        value = value.toLowerCase();
    }

    if (React.isValidElement(value)) {
        if (value.type === 'a' && value.props.children && isString(value.props.children)) {
            return value.props.children.toLowerCase()
        }
    }

    return value;
}