import React, {Component} from "react";
import {Table} from "../../../api/model/DatasetTable";
import {ListView} from "../../../ui/ListView/ListView";
import {SearchInputField} from "../../../ui/Input/SearchInputField";


type Props = {
    tables: Table[];
    onItemClick: (e) => void;
    onSearchInput: (item: string) => void;
}

type State = {}

const tableListItem = (item: Table, index) => {
    const renderTextRow = (label, text) => {
        return (
            <div className="row mt-2">
                <span className="col-6 col-sm-4 col-md-4 col-lg-3 emp-type-subtitle1">{label}</span>
                <span className={"col-6 col-sm col-md col-lg emp-type-subtitle1"}>{text}</span>
            </div>
        );
    }
    return <div className="container-fluid">
        <div className="row">
            <div className="col-2">
                <img className={"placeholder img-thumbnail"} src={`https://via.placeholder.com/150?text=Table`}/>
            </div>
            <div className="col">
                {renderTextRow("Database", item.database_name)}
                {renderTextRow("Name", item.table_name)}
            </div>
        </div>
    </div>
}

export class TablesPage extends Component<Props, State> {

    render() {
        const {tables, onItemClick, onSearchInput} = this.props;
        return (
            <main className="app-content">
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <h2 className="emp-type-h2">Tables</h2>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-12">
                            <SearchInputField label={"Search"} onChange={onSearchInput}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <ListView
                                datasource={tables}
                                renderItem={tableListItem}
                                onItemClick={onItemClick}
                            />
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}