import {sensorConstants} from '../constants/sensor.constants.js';

const initialState = {
    sensor: {},
    sensorList: [],
    groupList: [],
    updated: false,
    deleted: false
};

export const sensorReducer = (state = initialState, action) => {
    switch (action.type) {
        case sensorConstants.DELETE_SENSOR_FULFILLED:
            state = {
                ...state,
                sensorList: action.payload,
                deleted: true
            };
            return state;
        case sensorConstants.UPDATE_SENSOR_FULFILLED:
        case sensorConstants.CREATE_SENSOR_FULFILLED:
            state = {
                ...state,
                sensorList: state.sensorList.map(sensor => sensor.id === action.payload.id ? action.payload: sensor),
                updated: true
            };
            return state;
        case sensorConstants.FETCH_SENSOR_DATA_FULFILLED:
            state = {
                ...state,
                sensorList: action.payload
                //event: action.payload.sponsor
            };
            return state;
        case sensorConstants.FETCH_SENSOR_FULFILLED:
            state = {
                ...state,
                sensor: action.payload
                //event: action.payload.sponsor
            };
            return state;
        default:
            return state;
    }
};