export const datasetDbConstants = {
    QUERY_DATASET_DBS_DATA: 'QUERY_DATASET_DBS_DATA',
    QUERY_DATASET_DBS_DATA_FULFILLED: 'QUERY_DATASET_DBS_DATA_FULFILLED',

    CREATE_DATASET_DB: 'CREATE_DATASET_DB',
    CREATE_DATASET_DB_FULFILLED: 'CREATE_DATASET_DB_FULFILLED',

    GET_DATASET_DB: 'GET_DATASET_DB',
    GET_DATASET_DB_FULFILLED: 'GET_DATASET_DB_FULFILLED',

    UPDATE_DATASET_DB: 'UPDATE_DATASET_DB',
    UPDATE_DATASET_DB_FULFILLED: 'UPDATE_DATASET_DB_FULFILLED',

    DELETE_DATASET_DB: 'DELETE_DATASET_DB',
    DELETE_DATASET_DB_FULFILLED: 'DELETE_DATASET_DB_FULFILLED',
};
