import React from "react";
import {ListView} from "../../../ui/ListView/ListView";
import * as $ from "jquery";
import AssignmentForm from "../AssignmentForm";
import {Device} from "../../../api/model/Device";
import {Asset} from "../../../api/model/Asset";
import {DeviceAssignment} from "../../../api/model/DeviceAssignment";

interface AreaAssignmentsTabPageProps {
    deviceAssignments: Array<any>;
    onItemClick: (item: any) => void;
    onDeleteAssignmentClick: (item: any) => void;
    onAssignSubmit: (values: any) => Promise<void>;
    areaId: string;
    devices: Device[]
    assets: Asset[];
}

export class AreaAssignmentsTabPage extends React.Component<AreaAssignmentsTabPageProps> {
    constructor(props: AreaAssignmentsTabPageProps) {
        super(props);
    }

    renderTextRow(label: string, text: string) {
        return (
            <div className="row mt-2">
                <span className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-3 emp-type-subtitle1">{label}</span>
                <span className={"col-6 col-sm col-md col-lg col-xl emp-type-subtitle1"}>{text}</span>
            </div>
        );
    }

    onDeleteItemClick = (item: any) => {
        this.props.onDeleteAssignmentClick(item);
    };

    renderItem = (item: DeviceAssignment, index: number) => {
        return (
            <div className="container-fluid">
                <div className={"row"}>
                    <div className={"col"}/>
                    <div className="col-1">
                        <button className={"btn btn-link"} onClick={(e) => {
                            e.stopPropagation(); // prevent event from bubbling up
                            this.onDeleteItemClick(item)
                        }
                        }>
                            <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-sm-2">
                        <img
                            className={"placeholder img-thumbnail"}
                            src={item.asset?.imageUrl && item.asset.imageUrl || "https://via.placeholder.com/150"}
                        />
                    </div>
                    <div className="col-12 col-sm-10">
                        {this.renderTextRow("Name", item.asset?.name || '')}
                        {this.renderTextRow("Asset Id", item.asset?.id || '')}
                    </div>
                </div>
            </div>
        );
    };

    onAssignClick = () => {
        $("#assign_device").modal("show");
    };

    onAssignSubmit = async (values: any) => {
        await this.props.onAssignSubmit(values);
        $("#assign_device").modal("hide");
    };

    render() {
        const {deviceAssignments, assets} = this.props;

        const assignedAssets = assets?.filter(asset => deviceAssignments.find(deviceAssignment => deviceAssignment.assetId === asset.id));
        const unAssignedAssets = assets?.filter(asset => !deviceAssignments.find(deviceAssignment => deviceAssignment.assetId === asset.id));

        return (
            <>
                <div className={"container-fluid"}>
                    <div className={"row mt-2 mb-2"}>
                        <div className={"col"}></div>
                        <div className={"col-4 col-sm-3"}>
                            <button className={"btn btn-link"} data-toggle="modal" onClick={this.onAssignClick}>
                                <span className={"emp-type-btn-primary"}>ASSIGN</span>
                            </button>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col"}>
                            {assignedAssets && (<ListView
                                datasource={deviceAssignments}
                                renderItem={this.renderItem}
                                onItemClick={this.props.onItemClick}
                            />)
                            }
                        </div>
                    </div>
                </div>
                <div
                    className="modal fade"
                    id="assign_device"
                    tabIndex={-1}
                    role="dialog"
                    aria-labelledby="add_device"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <AssignmentForm
                                areaId={this.props.areaId}
                                assets={unAssignedAssets}
                                onSubmit={this.onAssignSubmit}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}