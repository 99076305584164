import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {FieldArray} from 'redux-form';
import {setQueryBuilder} from '../../actions/filterFieldAction';
import {filterConstants} from '../../constants/filterform.constants';
import {operator} from './operator';

const FIELD_ARR_NAME = filterConstants.QUERY_BUILDER_ARR;

export class FilterForm extends React.Component {

    render() {
        return <FieldArray name={FIELD_ARR_NAME} component={this.renderFilterSelector.bind(this)} />
    }

    renderFilterSelector({ fields, meta: { error, submitFailed } }) {
        const handleClick = () => {
            const {dimensions} = this.props;
            fields.push({filtername:dimensions[0].name, operator: operator.equals});

            this.props.setQueryBuilder(fields);
        }

        return (
            <button type="button" className="btn btn-primary" onClick={handleClick.bind(this)}>
                Add Filter
            </button>
        );
    }
}


const mapStateToProps = (state) => {

    return ({
        filterFields: state.filterFormReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    setQueryBuilder: bindActionCreators(setQueryBuilder, dispatch)
});

export default connect
(mapStateToProps, mapDispatchToProps)
(FilterForm)