export class AssetType {
    id: string;
    organizationId: number;
    name: string;
    description: string;
    assetCategory: string;
    iconUrl: string;
    imageUrl: string;
    createdById: string;
    updatedById: string;
    createdAt: Date;
    updatedAt: Date;

    constructor(
        id: string,
        organizationId: number,
        name: string,
        description: string,
        assetCategory: any,
        iconUrl: string,
        imageUrl: string,
        createdById: string,
        updatedById: string,
        createdAt: Date,
        updatedAt: Date
    ) {
        this.id = id;
        this.organizationId = organizationId;
        this.name = name;
        this.description = description;
        this.assetCategory = assetCategory;
        this.iconUrl = iconUrl;
        this.imageUrl = imageUrl;
        this.createdById = createdById;
        this.updatedById = updatedById;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}
