import {SystemOfUnits} from "./formatHelper";

export function getAlertConditionFields(alertCondition) {
    let fields = alertCondition.split(' '); // ex. "x > 99"
    return { 
        condition: fields[1] || ' ', 
        threshold: fields[2] || ' '
    }
}

export function getAlertConditionUnits(alertType, systemOfUnits) {
    switch (alertType) {
        case 'battery_percentage':
        case 'battery_voltage':
        case 'humidity':
            return '%';
        case 'ambient_temperature':
            return systemOfUnits === SystemOfUnits.Imperial ? '°F' : '°C';
        case 'probe_temperature':
            return systemOfUnits === SystemOfUnits.Imperial ? '°F': '°C';
        default:
            return '';
    }
}

export function formatAlertType(alertType) {
    return alertType.charAt(0).toUpperCase() + alertType.substring(1);     
}
