import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as AssetActions from '../../actions/assetActions';
import * as DeviceAssignmentActions from '../../actions/deviceAssignmentActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {browserHistory, withRouter} from 'react-router';
import {AssetDetailPage} from '../../components/Asset/AssetDetailPage'
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import * as DeviceAction from "../../actions/deviceAction";
import {createDeviceAssignment} from "../../actions/deviceAssignmentActions";
import {getMetric} from "../../api/metricApi";

class AssetDetailPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.onTabChange = this.onTabChange.bind(this);
        this.onAssignSubmit = this.onAssignSubmit.bind(this);
        this.onDeleteAssignmentClick = this.onDeleteAssignmentClick.bind(this);
    }

    async componentDidMount() {
        const assetId = this.props.params.asset_id;
        this.setState({loading: true})
        await this.props.assetActions.getAsset(assetId);
        this.setState({loading: false})
        await this.props.deviceAssignmentActions.listDeviceAssignments({assetId: this.props.params.asset_id, expand: ['device']});
        await this.props.deviceActions.searchDevices({expand: ['device_type']});
    }

    async onTabChange(tab) {
        switch(tab) {
            case "Assignments":
                break;
            default:
                break;
        }
    }

    async onAssignSubmit(values) {
        await this.props.deviceAssignmentActions.createDeviceAssignment({
            device_assignment: {
                asset_id: values.assetId,
                device_id: values.deviceId
            }
        });
    }

    async onDeleteAssignmentClick(assignment) {
        await this.props.deviceAssignmentActions.deleteDeviceAssignment(assignment.id);
    }

    onAssignItemClick = async (item) => {
        browserHistory.push(`/devices/${item.device.identity}`);
    }

    render() {
        const {auth, authActions, asset, deviceAssignments, devices} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <AssetDetailPage
                        asset={asset}
                        onTabChange={this.onTabChange}
                        onAssignSubmit={this.onAssignSubmit}
                        onAssignItemClick={this.onAssignItemClick}
                        onDeleteAssignmentClick={this.onDeleteAssignmentClick}
                        deviceAssignments={deviceAssignments}
                        devices={devices}
                        getMetric={getMetric}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    asset: state.assetReducer.asset,
    deviceAssignments: state.deviceAssignmentReducer.deviceAssignments,
    devices: state.deviceReducer.devices
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    assetActions: bindActionCreators(AssetActions, dispatch),
    deviceAssignmentActions: bindActionCreators(DeviceAssignmentActions, dispatch),
    deviceActions: bindActionCreators(DeviceAction, dispatch),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetDetailPageContainer));