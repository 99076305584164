import React, {Component} from "react";
import {Field, Form, InjectedFormProps, reduxForm} from "redux-form";
import * as $ from "jquery";
import {NoLabelFileInputField} from "../FileInputField/NoLabelFileInputField";
import {connect} from "react-redux";
import {TextField} from "../../ui/Input/TextField";
import DeviceType from "../../api/model/DeviceType";

interface CustomFieldProps {
    input: any;
    label: string;
    meta: {
        touched: boolean;
        error: string
    };
}

const titleField = ({input, label, meta: {touched, error}}: CustomFieldProps) => {
    return <TextField label={label} {...input} />;
};

interface DeviceTypeFormProps {
    deviceType: DeviceType;
    initialValues?: DeviceType;
    onSubmit: (data: any) => void;
}

class DeviceTypeForm extends Component<
    DeviceTypeFormProps & InjectedFormProps<{}, DeviceTypeFormProps>
> {
    constructor(props: DeviceTypeFormProps & InjectedFormProps<{}, DeviceTypeFormProps>) {
        super(props);
    }

    render() {
        const {
            error,
            handleSubmit,
            pristine,
            reset,
            submitting,
            deviceType
        } = this.props;

        return (
            <Form onSubmit={handleSubmit(this.props.onSubmit)}>
                <div className={"container-fluid"}>
                    <div className={"row mt-2"}>
                        <span className={"emp-type-h2 col"}>{deviceType?.name || "Device Type"}</span>
                    </div>

                    <div className={"row"}>
                        <div className={"col"}>
                            <div className="emp-type-subtitle1">
                                <div className={"mt-4"}>{this.renderCard()}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        );
    }

    renderTextRow(fieldName: string, label: string) {
        return (
            <div className="row mb-3">
                <div className={"col-12 col-sm-6"}>
                    <Field name={fieldName} label={label} component={titleField}/>
                </div>
            </div>
        );
    }

    renderImage() {
        const handleFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
            let name =
                e.currentTarget.files && e.currentTarget.files.length > 0
                    ? $.trim(e.currentTarget.files[0].name)
                    .substring(0, 30)
                    .trim(this) + "..."
                    : "";
            $(".organization_logo").html(name);
            if (e.target.files && e.target.files.length > 0) {
                let url = URL.createObjectURL(e.target.files[0]);
                $("#preview_img").attr("src", url);
            } else {
                $("#preview_img").attr("src", require("../../styles/images/icon/logo.png"));
            }
        };
        const {deviceType} = this.props;
        const imageUrl = deviceType?.image_url
            ? deviceType.image_url
            : "https://via.placeholder.com/250?text=Device+PlaceHolder";

        return (
            <>
                <div className={"row mb-3 mb-sm-2"}>
                    <div className={"col-12"}>
                        <img
                            id="preview_img"
                            className="placeholder img-thumbnail"
                            src={imageUrl}
                        ></img>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12"}>
                        <NoLabelFileInputField
                            type="file"
                            name="image"
                            placeholder="New York, NY, USA"
                            //label="Choose Logo "
                            HandleChange={(e) => handleFiles(e)}
                            //iconClass="fa fa-info-circle"
                            className="display-none"
                            inputGroupLabel={""}
                            inputGroupLabelClass="custom-file-label emp_cst_file_label organization_logo pointer-none"
                            inputGroupClass="input-group mb-3 cst-upload"
                            htmlFor="inputGroupFile01"
                            // @ts-ignore
                            HandleClick={(e) => handleFiles(e)}
                            id="inputGroupFile01"
                        />
                    </div>
                </div>
            </>
        );
    }
    renderCard() {
        const {
            error,
            handleSubmit,
            pristine,
            reset,
            submitting,
            deviceType
        } = this.props;

        return (
            <>
                <div className="row mb-2">
                    <div className="col-12 col-sm-4">{this.renderImage()}</div>

                    <div className="col">
                        <>
                            {this.renderTextRow("name", "Name")}
                            {this.renderTextRow("description", "Description")}
                            <div className={"row"}>
                                <div className={"col-0 col-sm"}></div>
                                <div className={"col-12 col-sm-8"}>
                                    <button
                                        type="submit"
                                        disabled={submitting}
                                        className={"btn btn-primary"}
                                    >
                                        <span className={"emp-type-btn-primary"}>SAVE</span>
                                    </button>
                                </div>
                            </div>
                        </>
                    </div>
                </div>
            </>
        );
    }
}

export const validate = (values: any) => {
    const errors = {};
    return errors;
};

export const mapStateToProps = (state) => {
    return {
        deviceType: state.deviceTypesReducer.deviceType,
        initialValues: state.deviceTypesReducer.deviceType

    };
};

export const FORM_NAME = "deviceTypeForm";

const wrappedForm = connect(mapStateToProps)(
    reduxForm<{}, DeviceTypeFormProps>({
        form: FORM_NAME,
        enableReinitialize: true,
        validate,
    })(DeviceTypeForm)
);

export default wrappedForm;