import React from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {IncidentListView} from "./IncidentListView";

export class IncidentHistoryTab extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <div className={`tab-pane fade`} id="pills-history" role="tabpanel"
                    aria-labelledby="pills-history-tab">
            {/*<div className="row">
                                        <div className="col-sm-12">
                                            <div className="alert_box_btn">
                                                <ul className="alert_btn_list">
                                                    <li className="alert_btn_itm">
                                                        <button type="button" className="btn btn-next btn-success emp-cst-btn">
                                                            <span className="mr-2 emp_icn_middle">
                                                                <img src={require("../../styles/images/icon/ic_checking.png")} alt="Icon" />
                                                            </span>Resolved</button>
                                                    </li>
                                                    <li className="alert_btn_itm">
                                                        <button type="button" className="btn btn-primary emp-cst-btn">Erase</button>
                                                    </li>
                                                    <li className="alert_btn_itm">
                                                        <button type="button" className="btn btn-primary emp-cst-btn">Export</button>
                                                    </li>
                                                    <li className="alert_btn_itm v-pipe-xs-hide">
                                                        <span className="v-pipe"></span>
                                                    </li>
                                                    <li className="alert_btn_itm">
                                                        <a href="#" className="create-group">
                                                            <img src={require("../../styles/images/icon/icn_close.png")} alt="Icon" /><span className="ml-2">Cancel</span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>*/}
            <IncidentListView items={this.props.items}/>
            <div className="row">
                <div className="col-sm-12">
                    <div className="active-device-btn text-right mt-4">
                        <a href="#"
                           className="btn btn-next btn-primary emp-cst-btn emp-btn xs_mr_0 text-white">
                            Download
                        </a>

                    </div>
                </div>
            </div>
        </div>;
    }
}

IncidentHistoryTab.propTypes = {
    items: PropTypes.array,
};