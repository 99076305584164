import React from "react";

import {Link} from 'react-router';
import {formatTimestampDateTime} from "../../utils/formatHelper";

export default class DashboardNumberCardV2 extends React.Component {

    render(){
        const lastDataPoint = this.props.data[this.props.data.length - 1];
        return (
            <>
                <div className="card-body emp-device-card-body text-align-center">
                    <Link to={`/environment/device/${this.props.instance_id}`} draggable={false}>
                        <div className="emp-device-card-content">
                            <div>{this.props.data && this.props.data.length > 0 ? lastDataPoint["0"] ?
                                <span style={{color: this.props.colorHex}}>{lastDataPoint["0"].toFixed(2)} {this.props.unit} </span>
                                : <i className="fa fa-minus"></i> : <div/> }</div>
                        </div>
                    </Link>
                </div>
                <div className="emp-device-card-secondary-text">last update: {this.props.data && this.props.data.length > 0 && formatTimestampDateTime(lastDataPoint.x)}</div>
            </>
        );

    }

}
