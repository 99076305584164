export class GetLocationTelemetryRequest {
    device_id;
    start_date;
    end_date;
    limit;
    constructor(device_id, start_date=null, end_date=null, limit=50, cursor=null) {
        this.device_id = device_id;
        this.start_date = start_date;
        this.end_date = end_date;
        this.limit = limit;
        this.cursor = cursor;
    }
}

export class ListCurrentLocationTelemetryRequest {
    limit;
    cursor;

    constructor(limit=100, cursor=null) {
        this.limit = limit;
        this.cursor = cursor;
    }
}