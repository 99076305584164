import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

const renderField = ({ input, label, placeholder, maxLength, autoComplete, type, className, iconClass, parentClass, meta: { touched, asyncValidating, error } }) => {
    return (
        <div>
            {
                type == "search" &&
                    <input
                        {...input}
                        maxLength={maxLength}
                        autoComplete={autoComplete}
                        placeholder={placeholder}
                        type={type}
                        className={className}
                        />
            }
            {
                type != "search" &&
                <div className={parentClass ? parentClass : 'form-group'}>
                    { label !== null && <label className="label_modifier form-group__label">{label}<i  className={iconClass}></i></label> }
                    <div className={`form-group__field ${asyncValidating ? 'async-validating' : ''}`}>
                        <input
                            {...input}
                            maxLength={maxLength}
                            autoComplete={autoComplete}
                            placeholder={placeholder}
                            type={type}
                            className={className}
                            />
                    </div>
                    { touched && error && <div className="form-group__error">{error}</div>}
                </div>
            }
        </div>


    );
};

export const InputField = ({ type, name, label, placeholder, maxLength, autoComplete , className, iconClass, parentClass, errors,mask}) => (
    <Field
        type={type}
        placeholder={placeholder}
        name={name}
        label={label}
        maxLength={maxLength}
        autoComplete={autoComplete}
        className={className}
        iconClass={iconClass}
        parentClass={parentClass}
        {...mask}
        component={renderField}
    />
);

InputField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    iconClass: PropTypes.string,
    parentClass: PropTypes.string,
    maxLength: PropTypes.number,
    autoComplete: PropTypes.oneOf(['on', 'off'])
};

InputField.defaultProps = {
    type: 'text',
    label: null,
    placeholder: '',
    className: '',
    parentClass: '',
    maxLength: null,
    autoComplete: null
};