import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AssetTypeActions from '../../actions/assetTypeActions';
import * as AuthActions from '../../actions/authActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import {AssetTypesPage} from "../../components/AssetTypes/AssetTypesPage";
import {browserHistory} from "react-router";
import {AssetType} from "../../api/model/AssetType";
import {debounce} from "../../utils/debounce";

class AssetTypesContainer extends React.Component<{assetTypeActions: typeof AssetTypeActions, auth, authActions, assetTypes: AssetType[]}, {loading: boolean}> {
    readonly debounceSearch;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.debounceSearch = debounce(this.onSearchInput.bind(this), 600).bind(this)
    }

    async componentDidMount() {
        this.setState({loading: true})
        await this.props.assetTypeActions.listAssetTypes({});
        this.setState({loading: false})
    }

    onItemClick(item) {
        browserHistory.push(`/asset_types/${item.id}`);
    }

    onDeleteItemClick = async (item) => {
        await this.props.assetTypeActions.deleteAssetType(item.id);
    }

    onSearchInput = async (value: string) => {
        await this.props.assetTypeActions.listAssetTypes({name: value});
    }

    render() {
        const {auth, authActions, assetTypes} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <AssetTypesPage assetTypes={assetTypes}
                                    onDeleteItemClick={this.onDeleteItemClick}
                                    onItemClick={this.onItemClick.bind(this)}
                                    onSearchInput={this.debounceSearch}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    assetTypes: state.assetTypeReducer.assetTypes,
});

const mapDispatchToProps = (dispatch) => ({
    assetTypeActions: bindActionCreators(AssetTypeActions, dispatch),
    authActions: bindActionCreators(AuthActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(AssetTypesContainer);