import React from 'react';
import {Form, reduxForm} from 'redux-form';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { Link } from 'react-router';
import * as AuthActions from '../../actions/authActions';
import validate from './validate';
import {InputField} from '../InputField/InputField';

const ChangePasswordPage = ({ handleSubmit, submitting, event, auth, authActions }) => {
    return (
    <section className="emp-sec emp-login-sec">
        <div className="container">
            <div className="row">
                <div className="col-sm-12 text-center">
                    <div className="emp-brand">
                        <img src={require('../../styles/images/icon/logo.png')} alt="Logo" />
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                    <div className="emp-login-form">
                        <div className="card shadow emp-form-card">
                            <div className="card-body">
                                <div className="emp-form-heading text-center">
                                    <h2>Change Password</h2>
                                </div>
                                <Form onSubmit={handleSubmit} className="login-form" noValidate={true}>
                                    <InputField
                                        type="password"
                                        name="user[password]"
                                        placeholder="Password"
                                        className="pl-0 rounded-0 form-control border-right-0 border-left-0 border-top-0"
                                        />

                                    <InputField
                                        type="password"
                                        name="user[password_confirmation]"
                                        placeholder="Confirm Password"
                                        className="pl-0 rounded-0 form-control border-right-0 border-left-0 border-top-0"
                                        />
                                    <div className="form-group">
                                        <button  type="submit" className="btn btn-primary btn-block">Change Password</button>
                                    </div>
                                    {<div className="mt-5 text-center">
                                       Back to  <Link className="text-primary" to="/auth/login" >Sign in</Link>
                                    </div> }
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    );
}

const mapStateToProps = (state) => {
    return ({
        event: state.authReducer
    });
};

const mapDispatchToProps = (state,dispatch) => {
    return ({
        authActions: bindActionCreators(AuthActions, dispatch)
    });
}

export default reduxForm({
    form: 'passwordChangeForm',
    validate,
    initialValues: {user: {reset_password_token: {}}},
    onSubmit: (values, dispatch) => dispatch(AuthActions.changePassword(values))
})(connect(null, mapDispatchToProps)(ChangePasswordPage));