export const gatewayConstants = {
    DELETE_GATEWAY: 'DELETE_GATEWAY',
    DELETE_GATEWAY_FULFILLED: 'DELETE_GATEWAY_FULFILLED',

    FETCH_GATEWAY: 'FETCH_GATEWAY',
    FETCH_GATEWAY_FULFILLED: 'FETCH_GATEWAY_FULFILLED',

    FETCH_GATEWAY_DATA: 'FETCH_GATEWAY_DATA',
    FETCH_GATEWAY_DATA_FULFILLED: 'FETCH_GATEWAY_DATA_FULFILLED',

    CREATE_GATEWAY: 'CREATE_GATEWAY',
    CREATE_GATEWAY_FULFILLED: 'CREATE_GATEWAY_FULFILLED'
};