import {generalSettingConstants} from '../constants/generalSetting.constants';

const initialState = {
    organization: {
        address: {}
    }
};

export const generalSettingReducer = (state = initialState, action) => {
    switch (action.type) {
        case generalSettingConstants.UPDATE_GENERAL_SETTING_FULFILLED:
            state = {
                ...state,
                organization: action.payload,
                updated: true
            };
            return state;
        case generalSettingConstants.FETCH_ORGANIZATION_DATA_FULFILLED:
            state = {
                ...state,
                organization: action.payload
            };
            return state;

        default:
            return state;
    }
};