import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as AreaTypeActions from '../../actions/areaTypeActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {browserHistory, withRouter} from 'react-router';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import {AreaTypeEditDetailPage} from "../../components/AreaTypes/AreaTypeEditDetailPage";

class AreaTypeEditDetailPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            metrics: []
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        const id = this.props.params.id;
        this.setState({loading: true})
        await this.props.areaTypeActions.getAreaType(id);
        this.setState({loading: false})
    }

    async onSubmit(values) {
        this.setState({loading: true});
        values.id = this.props.params.id;
        await this.props.areaTypeActions.updateAreaType(this.props.params.id, values);
        this.setState({loading: false});
        browserHistory.push(`/area_types/${this.props.params.id}`);
    }

    render() {
        const {auth, authActions, areaType} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage /> :
                    <AreaTypeEditDetailPage
                        areaType={areaType}
                        initialValues={areaType}
                        onSubmit={this.onSubmit}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    areaType: state.areaTypeReducer.areaType,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    areaTypeActions: bindActionCreators(AreaTypeActions, dispatch),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AreaTypeEditDetailPageContainer));