import React from 'react';
import AreaForm from "./AreaForm";


export default class AreaEditDetailPage extends React.Component {
    render() {
        const {area, areas, areaTypes} = this.props;
        return (
            <main className="app-content">
                <section className="emp_org_settings_sec emp_report_sec emp_circle_box_sec">
                    <div className="card emp-box emp-shadow">
                        <div className={"bg-white container-fluid mb-4"}>
                            <AreaForm
                                area={area}
                                areas={areas}
                                areaTypes={areaTypes}
                                initialValues={area}
                                onAreaSubmit={this.props.onSubmit.bind(this)}
                            />
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}