import {MeasurementUnit} from "../../utils/formatHelper";


export default class Metric {
    timestamp;
    value;
    instance_id;
    metric;
    unit;


    constructor(timestamp, value, instance_id, metric, unit) {
        this.timestamp = timestamp;
        this.value = value;
        this.instance_id = instance_id;
        this.metric = metric;
        this.unit = this.convertToMeasurementUnit(unit);
    }

    convertToMeasurementUnit(unit) {
        switch (unit) {
            case "Celsius":
                return MeasurementUnit.Celsius
            case "Fahrenheit":
                return MeasurementUnit.Fahrenheit
            case "m/s^2":
                return MeasurementUnit.MetersPerSecondSquared
            case "millivolts":
                return MeasurementUnit.Millivolts
            case "Percent":
                return MeasurementUnit.Percent
            case "None":
            default:
                return MeasurementUnit.None
        }
    }
}