import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {withRouter} from 'react-router';
import {CreateAlertPage} from '../../components/CreateAlertPage/CreateAlertPage';
import * as AlertActions from "../../actions/alertAction";

interface StateProps {
    auth: { user: any, isLoggedIn: boolean }
}

interface DispatchProps {
    authActions: typeof AuthActions;
    alertActions: typeof AlertActions;
}


type Props = StateProps & DispatchProps;

interface State {
    update: boolean;
}

class CreateAlertPageContainer extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            update: true,
        };
    }

    componentDidMount() {
        this.props.alertActions.resetAlertForEdit();
    }

    render() {
        const {authActions, auth} = this.props;
        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                <CreateAlertPage/>
            </div>
        );
    }
}

const mapStateToProps = (state): StateProps => ({
    auth: state.authReducer
});

const mapDispatchToProps = (dispatch): DispatchProps => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    alertActions: bindActionCreators(AlertActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateAlertPageContainer));
