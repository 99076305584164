import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import {WorkEventModel} from "./model/WorkEvent"


export class WorkEventApi {
    constructor() {

    }

    async createWorkEvent(values) {
        let payload = {
            work_activity: values['work_activity'],
            event_timestamp: values['event_timestamp'],
            work_type: values['work_type'],
            failure_mode: values['failure_mode'],
            device_ids: values['device_ids'],
            time_spent: values['time_spent'] * 60,
            avoided_downtime: values['avoided_downtime'] * 60,
            system_provided: values['system_provided'],
            apply_learning: values['apply_learning'],
        }

        try {
            let response = await axios.post(`${BASE_URL()}/api/v1/work_events`, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': storage.get('token')
                }
            });
            return WorkEventModel.fromJson(response.data.item);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async updateWorkEvent(workEvent) {
        try {
            let response = await axios.put(`${BASE_URL()}/api/v1/work_events/${workEvent.id}`, workEvent, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': storage.get('token')
                }
            });
            return WorkEventModel.fromJson(response.data.item);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async getWorkEvent(workEventId) {
        try {
            let response = await axios.get(`${BASE_URL()}/api/v1/work_events/${workEventId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': storage.get('token')
                }
            });
            return WorkEventModel.fromJson(response.data.item);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async queryWorkEvents(limit) {
        try {
            let response = await axios.get(`${BASE_URL()}/api/v1/work_events`, {
                params: {
                    limit: limit
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': storage.get('token')
                }
            });
            return response.data['items'].map(item => WorkEventModel.fromJson(item));
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}