import {networkConfigurationConstants} from '../constants/networkConfiguration.constants';

export const networkConfigurationReducer = (state = [], action) => {
    switch (action.type) {
        case networkConfigurationConstants.GET_NETWORK_CONFIGURATIONS:
        case networkConfigurationConstants.GET_NETWORK_CONFIGURATIONS_FULFILLED:
            return action.payload;
        default:
            return state
    }

};

