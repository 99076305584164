import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router';
import {organizationData} from '../../utils/organizationDataHelper';
import store from '../../store/store';
import * as generalSettingAction from '../../actions/generalSettingAction';
import {imagePath} from '../../utils/imagePath';
import './header.scss';

export class Header  extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };

    }
    componentDidMount() {
        if (!this.props.generalSetting.organization['id'])
            store.dispatch(generalSettingAction.fetchOrganizationData());
    }

    componentWillUnmount() {}

    render () {

        const { isLoggedIn,logout,user,generalSetting } = this.props;
        let logo_url;
        if (generalSetting.organization && generalSetting.organization['id'])
            logo_url=  generalSetting.organization.logo_type == "default" ? imagePath(this.props.generalSetting.organization.default_logo.url) : imagePath(this.props.generalSetting.organization.logo.url);
        else
            logo_url=require("../../styles/images/icon/logo.png");
        return (

            <div>
                {
                    isLoggedIn &&
                    <header className={this.props.headerclassName}>
                        <Link to="/" className="app-header__logo"><img width="70%" src={require('../../styles/images/thumbnails/new_logo.png')}/></Link>
                        <a className="app-sidebar__toggle" href="#" data-toggle="sidebar" aria-label="Hide Sidebar"></a>
                        <a className="app-sidebar__toggle-xs-circle-times" href="#" data-toggle="sidebar" aria-label="Hide Sidebar">
                            <i className="fa fa-times"></i>
                        </a>
                        <ul className="navbar-left-menu emp-drop-down">
                            <li className="dropdown border-right">
                                <Link className="app-nav__item" to="/" ><div className="wender-circle">
                                    <img src={logo_url}
                                         id="preview_org" alt="Logo" crossOrigin="anonymous" />
                                </div> {generalSetting.organization.name}</Link>
                                {/*<ul className="dropdown-menu settings-menu dropdown-menu-right">
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            <div className="media align-items-center">
                                                <img className="mr-3"  src={require('../../styles/images/icon/ic_group.png')} alt="group_img" />
                                                <div className="media-body">
                                                    <h6 className="mt-0 mb-0 emp-dropdown-itm">Wander Group 1</h6>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            <div className="media align-items-center">
                                                <img className="mr-3"  src={require('../../styles/images/icon/ic_group.png')} alt="group_img" />
                                                <div className="media-body">
                                                    <h6 className="mt-0 mb-0 emp-dropdown-itm">Wander Group 2</h6>
                                                </div>
                                            </div>
                                        </a>

                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            <div className="media align-items-center">
                                                <img className="mr-3"  src={require('../../styles/images/icon/ic_group.png')} alt="group_img" />
                                                <div className="media-body">
                                                    <h6 className="mt-0 mb-0 emp-dropdown-itm">Wander Group 3</h6>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                    <li>
                                        <a className="dropdown-item" href="#">
                                            <div className="media align-items-center">
                                                <img className="mr-3"  src={require('../../styles/images/icon/ic_group.png')} alt="group_img" />
                                                <div className="media-body">
                                                    <h6 className="mt-0 mb-0 emp-dropdown-itm">Wander Group 4</h6>
                                                </div>
                                            </div>
                                        </a>
                                    </li>
                                </ul>*/}
                            </li>
                        </ul>
                        <ul className="app-nav navbar-right-menu emp-drop-down">
                            <li className="dropdown border-left">
                                <Link className={`app-nav__item ${(window.location.pathname.includes('groups_devices')) ? 'active' : ''}`} to="/groups_devices" >
                                    <span className="nav-icon-img" ><img src={require('../../styles/images/icon/ic_group.png')} alt="group_img" className="header-icon" /> </span> Groups & Devices <i className="fa fa-angle-down ml-2 text-primary"></i>
                                </Link>
                            </li>
                            {/*<li className="dropdown border-left">*/}
                                {/*<Link aria-label="Open Profile Menu" className={`app-nav__item ${(window.location.pathname.includes('support')) ? 'active' : ''}`} to="/support" >*/}
                                    {/*<span className="nav-icon-img"><img  src={require('../../styles/images/icon/ic_i.png')} className="header-icon" alt="group_img" /> </span> Support <i className="fa fa-angle-down ml-2 text-primary"></i>*/}
                                {/*</Link>*/}
                                {/*<ul className="dropdown-menu settings-menu dropdown-menu-right ">*/}
                                    {/*<li>*/}
                                        {/*<a className="dropdown-item" href="#">*/}
                                            {/*<div className="media align-items-center">*/}
                                                {/*<img className="mr-3"  src={require('../../styles/images/icon/ic_i.png')} alt="group_img" />*/}
                                                {/*<div className="media-body">*/}
                                                    {/*<h6 className="mt-0 mb-0 emp-dropdown-itm">Support 1</h6>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</a>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                        {/*<a className="dropdown-item" href="#">*/}
                                            {/*<div className="media align-items-center">*/}
                                                {/*<img className="mr-3"  src={require('../../styles/images/icon/ic_i.png')} alt="group_img" />*/}
                                                {/*<div className="media-body">*/}
                                                    {/*<h6 className="mt-0 mb-0 emp-dropdown-itm">Support 2</h6>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</a>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                        {/*<a className="dropdown-item" href="#">*/}
                                            {/*<div className="media align-items-center">*/}
                                                {/*<img className="mr-3"  src={require('../../styles/images/icon/ic_i.png')} alt="group_img" />*/}
                                                {/*<div className="media-body">*/}
                                                    {/*<h6 className="mt-0 mb-0 emp-dropdown-itm">Support 3</h6>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</a>*/}
                                    {/*</li>*/}
                                    {/*<li>*/}
                                        {/*<a className="dropdown-item" href="#">*/}
                                            {/*<div className="media align-items-center">*/}
                                                {/*<img className="mr-3"  src={require('../../styles/images/icon/ic_i.png')} alt="group_img" />*/}
                                                {/*<div className="media-body">*/}
                                                    {/*<h6 className="mt-0 mb-0 emp-dropdown-itm">Support 3</h6>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</a>*/}
                                    {/*</li>*/}
                                {/*</ul>*/}
                            {/*</li>*/}
                            <li className="dropdown border-left" >
                                <a className={`app-nav__item ${(window.location.pathname.includes('profile')) ? 'active' : ''}`} href="#" data-toggle="dropdown" aria-label="Open Profile Menu">
                                    <span className="nav-icon-img"><img src={require('../../styles/images/icon/ic_user.png')} alt="group_img" /> </span>{user.email}<i className="fa fa-angle-down ml-2 text-primary"></i>
                                </a>
                                <ul className="dropdown-menu settings-menu dropdown-menu-right">
                                    <li>
                                        <button onClick={logout} className="dropdown-item cursor-pointer" type="button">
                                            <div className="media align-items-center">
                                                <img className="mr-3" src={require('../../styles/images/icon/icn_logout.png')} alt="New Features" />
                                                    <div className="media-body">
                                                        <h6 className="mt-0 mb-0 emp-dropdown-itm"> Sign Out</h6>
                                                    </div>
                                            </div>
                                        </button>
                                    </li>
                                </ul>
                            </li>
                        </ul>

                    </header>
                }
                {
                   /* isLoggedIn &&
                    <nav className="navbar navbar-expand-lg navbar-light"> <Link to="/" className="navbar-brand"><span>Empiric</span></Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon1"><i className="fa fa-bars"></i> </span> </button>
                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul className="navbar-nav justify-content-end dropdown-user">

                                <li className="nav-item">
                                    <div className="dropdown">
                                        <a className="nav-link dropdown-toggle1" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            {user && user.first_name} <i className="fa fa-angle-down"></i>
                                        </a>
                                        <div className="dropdown-menu dropdown-user-menu" aria-labelledby="dropdownMenuLink">
                                            <Link to="/edit_profile" className="dropdown-item">Edit
                                                Profile</Link>
                                            <Link to="/update_password" className="dropdown-item">Change Password</Link>
                                            <a onClick={logout} className="dropdown-item" href="/">Logout</a>
                                        </div>
                                    </div>
                                </li>
                                {
                                    isLoggedIn &&  user.profile_picture && user.profile_picture.url &&
                                    <li className="nav-item d-flex align-items-center">
                                        <div className="user-avatar-block">
                                            <img className="rounded-circle"
                                                 src={imagePath(user.profile_picture.url)}
                                                 alt="User ProfilePicture"/>
                                        </div>
                                    </li>
                                }
                                </ul>
                            </div>
                        </nav>*/

                }

                {
                    !isLoggedIn &&
                    <nav className="navbar navbar-expand-lg navbar-light"> <Link to="/" className="navbar-brand"><span className="text-primary">Empiric</span></Link>

                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon1"><i className="fa fa-bars"></i> </span> </button>
                        <div className="collapse navbar-collapse" id="navbarNavDropdown">
                            <ul className="navbar-nav justify-content-end">
                                <li className="nav-item"> <a className="nav-link" href="#"> About</a> </li>
                                <span className="pipe">|</span>
                                <li className="nav-item"> <a className="nav-link" href="#"> Contact</a> </li>
                                <li className="nav-item"><Link to="/auth/login" className="nav-link login">Login</Link> </li>
                                <li className="nav-item nav-item-sign-up"><Link to="/auth/register" className="nav-link sign-up">Sign Up</Link> </li>
                            </ul>
                        </div>
                    </nav>
                }
            </div>

        );
    }
}
const mapStateToProps = (state) => {
    return ({
        generalSetting: state.generalSettingReducer
    });
};
const mapDispatchToProps = (dispatch) => ({
    generalSettingAction: bindActionCreators(generalSettingAction, dispatch)
});
export default withRouter(connect(mapStateToProps,mapDispatchToProps)(Header));