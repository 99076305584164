import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as DeviceActions from '../../actions/deviceAction';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {withRouter} from 'react-router';
import {DeviceDetailPage} from '../../components/Devices/DeviceDetailPage'
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import * as DeviceAction from "../../actions/deviceAction";
import * as MetricActions from "../../actions/metricAction";
import {getMetric} from "../../api/metricApi";

class DeviceDetailPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.onTabChange = this.onTabChange.bind(this);
    }

    async componentDidMount() {
        const id = this.props.params.id;
        this.setState({loading: true})
        await this.props.deviceActions.getDevice(id, ['device_type']);
        this.setState({loading: false})
    }

    async onTabChange(tab) {
        switch(tab) {
            default:
                break;
        }
    }

    render() {
        const {auth, authActions, device} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <DeviceDetailPage
                        device={device && device.device ? device.device : {}}
                        onTabChange={this.onTabChange}
                        getMetric={getMetric}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    device: state.deviceReducer.device
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    deviceActions: bindActionCreators(DeviceAction, dispatch),
    metricActions: bindActionCreators(MetricActions, dispatch)
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceDetailPageContainer));