import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {browserHistory} from 'react-router';
import * as AuthActions from '../../actions/authActions';
import * as GroupActions from '../../actions/groupAction';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import { withRouter } from 'react-router';
import GroupForm from '../../components/GroupForm/GroupForm';

export class GroupFormContainer extends React.Component {
    constructor(props){
        super(props);
        this.state={
            update: true
        }
        this.onCreateGroup = this.onCreateGroup.bind(this)
    }

    onCreateGroup(values){
        this.props.groupActions.createGroup({group: values});
        browserHistory.push('/groups_devices')
    }

    render() {
        const {authActions, auth } = this.props;
        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                    />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"/>
                <GroupForm onSubmitGroup={this.onCreateGroup} formTitle="Create Group" submitLabel="Create Group" />
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    groupActions: bindActionCreators(GroupActions, dispatch)
});


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(GroupFormContainer));