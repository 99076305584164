/**
 * Connects to an EmpiricBLE Gateway
 *
 * A typical API usage:
 *
 * client = EmpiricBle()
 * await client.request()
 * await client.connect()
 * await client.setDeviceCharacteristic()
 * await client.writeWifi(payload)
 */

 // for development testing with no Gateway, set env variable NO_GATEWAY=1
 // example: PORT=3001 REACT_APP_NO_GATEWAY=1 npm run start
export function EmpiricBleFactory() {
    if (process.env.REACT_APP_NO_GATEWAY === '1')
        return new EmpiricBleDev()
    return new EmpiricBle();
}

class EmpiricBle {

    constructor() {
        this.device = null;
        this.wifi = null;
        this.onDisconnected = this.onDisconnected.bind(this);
    }

    /* the Device characteristic providing Wifi information */
    async setDeviceCharacteristic() {
        const service = await this.device.gatt.getPrimaryService(0xfff1);
        const wifi = await service.getCharacteristic(
            "e107f0b6-85df-11ea-bc55-0242ac130003"
        );
        this.wifi = wifi;
    }

    /**
     * Initiates request to connect to EmpiricGateway. Gateway will broadcast BLE services, allowing to connect.
     * Browsers without a navigator
     * @returns {Promise.<void>}
     */
    async request() {
        let options = {
            filters: [
                {
                    name: "EmpiricGateway"
                }
            ],
            optionalServices: [0xfff0, 0xfff1]
        };
        if (navigator.bluetooth == undefined) {
            alert("Sorry, Your device does not support Web BLE!");
            return;
        }
        this.device = await navigator.bluetooth.requestDevice(options);
        if (!this.device) {
            throw "No device selected";
        }
        this.device.addEventListener("gattserverdisconnected", this.onDisconnected);
    }

    /**
     * Connects to BLE of device
     * @returns {Promise.<*>}
     */
    async connect() {
        if (!this.device) {
            return Promise.reject("Device is not connected.");
        }
        await this.device.gatt.connect();
    }

    /**
     * Reads wifi from device
     *
     * @returns {Promise.<*|String>}
     */
    async readWifi() {
        let wifiStatus = await this.wifi.readValue();
        return this.decode(wifiStatus);
    }

    /**
     * Sends a wifi payload over BLE. Payloads larger than 300 bytes are chunked into multiple writes and reassembled.
     * There is no response from the device when writing.
     *
     * To avoid overloading the device on multiple writes, a timeout is used to introduce a delay
     *
     * Payload should be JSON structure of {ssid: abc, pass: abc}
     * Enterprise wifi uses {ssid: abc, pass: abc, username: abc}
     * @param data
     * @returns {Promise.<void>}
     */
    async writeWifi(data) {
        let that = this;
        data = JSON.stringify(data);
        let bytes = data;

        let maxChunk = 300;
        let j = 0;
        if ( bytes.length > maxChunk ) {
            for ( let i = 0; i < bytes.length; i += maxChunk ) {
                let subStr;
                if ( i + maxChunk <= bytes.length ) {
                    subStr = bytes.substring(i, i + maxChunk);

                } else {
                    bytes += "EOR";
                    subStr = bytes.substring(i, bytes.length);
                }

                setTimeout(writeStrToCharacteristic, 550 * j, subStr);
                j++;
            }
        } else {
            bytes += "EOR";
            writeStrToCharacteristic(bytes);
        }

        async function writeStrToCharacteristic (str) {
            let encoder = new TextEncoder('utf-8');
            let bytes = encoder.encode(str);
            await that.wifi.writeValue(bytes);
        }
    }

    /* disconnect from peripheral */
    disconnect() {
        if (!this.device) {
            return Promise.reject("Device is not connected.");
        }
        return this.device.gatt.disconnect();
    }

    /* handler to run when device successfully disconnects */
    onDisconnected() {
        alert("Device is disconnected.");
        window.location.reload();
    }
    
      /* helper function to decode message sent from peripheral */
    decode(buf) {
        let dec = new TextDecoder("utf-8");
        return dec.decode(buf);
    }

}

class EmpiricBleDev extends EmpiricBle {
    constructor() {
        console.log('Ble No Gateway Stub')
        super();
    }

    delay = ms => new Promise(res => setTimeout(res, ms));

    async setDeviceCharacteristic() {
        console.log('ble dev set device characteristic')
        /* tbd - needs to set this.wifi */
    }

    async request() {
        alert('BLE Dev - pair request stub')
        /* to do: consider supporting error flows through alert dialog */
        /* tbd: minimal this.device props to support the flow */
        /* tbd: any need to simulate listener for disconnect actions?  */
        this.device={name:"Empiric Gateway"}
    }

    async connect() {
        console.log('ble dev connect')
        if (!this.device) {
            return Promise.reject("Device is not connected.");
        }
        // tbd: real code calls device.gatt.connect.  Any need to update this.device?
    }

    async readWifi() {
        console.log('ble dev read wifi')
        // tbd: return decoded wifi status
        return "connected"
    }

    async writeWifi(data) {
        console.log('ble dev write wifi',data)
        this.delay(750)
    }

    /* disconnect from peripheral */
    disconnect() {
        console.log('ble dev disconnect')
        if (!this.device) {
            return Promise.reject("Device is not connected.");
        }
        // tbd: real code calls device.gatt.disconnect.  Any need to update this.device? and what is return
        return true;
    }
}
