import {WorkflowParamsInterface} from "./Workflow";

export class WorkflowScheduleModel {
    id: string;
    owner_id: string;
    schedule_interval: ScheduleIntervalModel | null;
    workflow_id: string;
    start_date: string;
    enabled: boolean;
    workflow_params: WorkflowParamsInterface["properties"];
    title: string;
    description: string;
    owner;
    compute_size?: string;

    constructor(id, owner_id, schedule_interval, workflow_id, start_date, enabled, workflow_params, title, description, owner, compute_size) {
        this.id = id;
        this.owner_id = owner_id;
        this.schedule_interval = schedule_interval;
        this.workflow_id = workflow_id;
        this.start_date = start_date;
        this.enabled = enabled;
        this.workflow_params = workflow_params;
        this.title = title;
        this.description = description;
        this.owner = owner;
        this.compute_size = compute_size;
    }

    static fromJson(json) {
        const scheduleInterval: ScheduleIntervalModel | null =
            json['schedule_interval'] ? ScheduleIntervalModel.fromJson(json['schedule_interval']) : null;

        return new WorkflowScheduleModel(json['id'],
            json['owner_id'],
            scheduleInterval,
            json['workflow_id'],
            json['start_date'],
            json['enabled'],
            json['workflow_params'],
            json['title'],
            json['description'],
            json['owner'],
            json['compute_size']
        )
    }
}

export const IntervalType = Object.freeze({
    SIMPLE: "Simple",
    CRON: "Cron",
});

export class ScheduleIntervalModel {
    interval_type: string;
    interval: string;

    constructor(interval_type: string, interval: string) {
        this.interval_type = interval_type;
        this.interval = interval;
    }

    static fromJson(json) {
        return new ScheduleIntervalModel(json['interval_type'],
            json['interval'],
        )
    }
}