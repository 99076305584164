import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as DeviceTypeActions from '../../actions/deviceTypesActions';
import * as AuthActions from '../../actions/authActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import {DeviceTypesPage} from "../../components/DeviceTypes/DeviceTypesPage";
import {browserHistory} from "react-router";
import DeviceType from "../../api/model/DeviceType";
import {debounce} from "../../utils/debounce";

class DeviceTypesContainer extends React.Component<{
    deviceTypeActions,
    auth,
    authActions,
    deviceTypes: DeviceType[]
}, { loading: boolean }> {
    readonly debounceSearch;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.debounceSearch = debounce(this.onSearchInput.bind(this), 600).bind(this)
    }

    async componentDidMount() {
        this.setState({loading: true})
        await this.props.deviceTypeActions.listDeviceTypes();
        this.setState({loading: false})
    }

    onItemClick(item) {
        browserHistory.push(`/device_types/${item.id}`);
    }

    onSearchInput = async (value: string) => {
        await this.props.deviceTypeActions.listDeviceTypes({name: value});
    }

    render() {
        const {auth, authActions, deviceTypes} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <DeviceTypesPage deviceTypes={deviceTypes}
                                     onItemClick={this.onItemClick.bind(this)}
                                     onSearchInput={this.debounceSearch}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    deviceTypes: state.deviceTypesReducer.deviceTypes,
});

const mapDispatchToProps = (dispatch) => ({
    deviceTypeActions: bindActionCreators(DeviceTypeActions, dispatch),
    authActions: bindActionCreators(AuthActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceTypesContainer);
