import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import store from '../../store/store';
import * as GatewayActions from '../../actions/gatewayAction';
import {fetchGateway} from '../../utils/fetchDataHelper';

export class GatewayPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            gateway: {}
        };

    }
    componentWillReceiveProps (nextProps) {
        if (!this.state.gateway['id'] && nextProps.gatewayReducer.gateway['id']){
            this.setState({gateway: nextProps.gatewayReducer.gateway});
        }
    }
    componentDidMount(){
        fetchGateway(this.props.params['id'])
    }

    render(){

        return(
            <main className="app-content">
                <div className="app-title border-bottom">
                    <div className="media-body">
                        <span className="sub_txt_title setting_user_txt_title">EMPIRIC SOLUTIONS - TEST</span>
                        <h5 className="mt-0 settings_users_hd_title">{this.state.gateway.name}</h5>
                    </div>
                </div>
                <section className="emp_org_settings_sec emp_report_sec settings_users_sec emp_gatway_box_scc emp_gatway_circle_sec">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-12">
                            <div className="card shadow emp_gatway_box_card">
                                <div className="card-body">
                                    <h5 className="card-title text-center">Connection</h5>
                                    <div className="media">
                                        <div className="circle_box text-center mr-4">
                                            <img src={require("../../styles/images/icon/icn_wifi_lg.png")} className="img-fluid" alt="Connection Status" />
                                                <p className="circle_sm_txt mb-0">Connection Status</p>
                                            </div>
                                            <div className="media-body circle_box_gray">
                                                <div className="card-body bg_gray_box">
                                                    <ul className="list-unstyled mb-0">
                                                        <li className="media d-flex align-items-center">
                                                            <i className="mr-3 fa fa-circle text-success icn_dots"></i>
                                                            <div className="media-body">
                                                                online
                                                            </div>
                                                        </li>
                                                        <li className="media my-3 d-flex align-items-center">
                                                            <i className="mr-3 fa fa-circle icn_warning icn_dots"></i>
                                                            <div className="media-body">
                                                                Low
                                                            </div>
                                                        </li>
                                                        <li className="media d-flex align-items-center">
                                                            <i className="mr-3 fa fa-circle text-danger icn_dots"></i>
                                                            <div className="media-body">
                                                                Offline
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card_footer_box">
                                            <div className="table-responsive text-nowrap">
                                                <table className="table table-borderless sensor_id_table">
                                                    <thead className="border-bottom">
                                                    <tr>
                                                        <th scope="col">Gateway ID</th>
                                                        <th scope="col">Wifi</th>
                                                        <th scope="col">Active</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr>
                                                        <th>f0:0b:a1:80:50:48</th>
                                                        <td>Cell</td>
                                                        <td>&nbsp;</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-12">
                                <div className="card shadow emp_gatway_box_card">
                                    <div className="card-body">
                                        <h5 className="card-title text-center">Battery</h5>
                                        <div className="media">
                                            <div className="circle_box text-center mr-4">
                                                <img src={require("../../styles/images/icon/icn_battery_lg.png")} alt="Battery" className="img-fluid" />
                                                    <p className="circle_sm_txt mb-0">Battery Level</p>
                                                </div>
                                                <div className="media-body circle_box_gray">
                                                    <div className="card-body bg_gray_box">
                                                        <ul className="list-unstyled mb-0">
                                                            <li className="media d-flex align-items-center">
                                                                <i className="mr-3 fa fa-circle text-success icn_dots"></i>
                                                                <div className="media-body">
                                                                    High
                                                                </div>
                                                            </li>
                                                            <li className="media my-3 d-flex align-items-center">
                                                                <i className="mr-3 fa fa-circle icn_warning icn_dots"></i>
                                                                <div className="media-body">
                                                                    Low
                                                                </div>
                                                            </li>
                                                            <li className="media d-flex align-items-center">
                                                                <i className="mr-3 fa fa-circle text-danger icn_dots"></i>
                                                                <div className="media-body">
                                                                    Dead
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card_footer_box">
                                                <div className="table-responsive text-nowrap">
                                                    <table className="table table-borderless sensor_id_table">
                                                        <thead className="border-bottom">
                                                        <tr>
                                                            <th scope="col">Gateway ID</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        <tr>
                                                            <th>f0:0b:a1:80:50:48</th>
                                                        </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </main>
        );
    }
}

const mapStateToProps = (state) => {

    return ({
        gatewayReducer: state.gatewayReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    GatewayActions: bindActionCreators(GatewayActions, dispatch)
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(GatewayPage));