export function debounce(callback, delay) {
    let timeout;

    return function () {
        // Get the arguments passed to the debounced function
        const args = Array.from(arguments);

        // Clear any existing timeout
        clearTimeout(timeout);

        // Set a new timeout to delay the execution of the callback function
        timeout = setTimeout(() => {
            console.log('go')
            callback(...args);
        }, delay);
    }
}