

export class GeoJsonGeometry {
    coordinates;
    properties;
    type;
    device_name;

    constructor(type, coordinates, properties, device_name) {
        this.type = type;
        this.coordinates = coordinates;
        this.properties = properties;
        this.device_name = device_name;
    }
}

export default class LocationTelemetry {
    deviceId;
    timestamp;
    geometry;
    device_name;
    constructor(deviceId, timestamp, geometry, device_name) {
        this.deviceId = deviceId;
        this.timestamp = timestamp;
        this.geometry = geometry;
        this.device_name = device_name;
    }
}