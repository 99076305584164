import React from "react";

interface SubItem {
    value: string;
    label: string;
}

interface Item {
    value: string;
    label: string;
    items: SubItem[];
}

export type Items = Item[];

export class NestedSelectField extends React.Component<{
    onMenuClickListener?: (e) => void,
    onItemClickListener?: (e) => void,
    items: Items,
    name?: string,
    label?: string,
    value?: string,
    multiple?: boolean,
    onChange?: (e) => void,
    onFocus?: (e) => void,
    onBlur?: (e) => void,
    error?: boolean,
    errorLabel?: string
},{}> {

    // ... other methods here

    render() {
        const {items, label, name, value, multiple, onChange, onFocus, onBlur, error, errorLabel} = this.props;
        return (
            <>
                <div className={"emp-input-container" + (error ? ' input_error' : '')}>
                    <select name={name} value={value} multiple={multiple} onChange={onChange} onFocus={onFocus} onBlur={onBlur}>
                        <option value="" disabled selected></option>
                        {items.map((item) => (
                            <optgroup key={item.value} label={item.label}>
                                {item.items && item.items.map((subItem) => (
                                    <option key={subItem.value} value={subItem.value}>
                                        {subItem.label}
                                    </option>
                                ))}
                            </optgroup>
                        ))}
                    </select>
                    <label className={value && 'filled'}>{label}</label>
                </div>
                {error && <span className="emp-type-caption emp-color-error">{errorLabel}</span>}
            </>
        )
    }
}
