import React from "react";
import {Field, Form, reduxForm} from "redux-form";
import * as $ from "jquery";
import {NoLabelFileInputField} from "../FileInputField/NoLabelFileInputField";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {TextField} from "../../ui/Input/TextField";
import {SelectField} from "../../ui/Input/SelectField";
import {AssetType} from "../../api/model/AssetType";

const titleField = ({input, label, meta: {touched, error}}) => {
    return <TextField label={label} error={touched && error} errorLabel={error} {...input}/>
}

const selectField = ({input, label, meta: {touched, error}}) => {
    return <SelectField label={label}
                        placeholder=""
                        value={input.value}
                        error={touched && error}
                        errorLabel={error}
                        {...input}
                        items={
                            [
                                {value: "machine", label: "Machine"},
                                {value: "device", label: "Device"},
                                {value: "person", label: "Person"}
                            ]
                        }
    />
}

class AssetTypeForm extends React.Component<{
    assetType: AssetType,
    initialValues?: Object,
    submitting: boolean,
    handleSubmit: (e) => void,
    onSubmit: (e) => void
}> {
    render() {
        const {handleSubmit, submitting, assetType} = this.props;

        return <Form onSubmit={handleSubmit(this.props.onSubmit)}>
            <div className={"row mt-2"}>
                <h1 className={"emp-type-h2 col"}>{assetType.name || 'Asset Type'}</h1>
            </div>

            <div className={"row"}>
                <div className={"col"}></div>
                <div className={"col-4 col-sm-3"}>
                    <button type="submit" disabled={submitting} className={"btn-primary"}><span
                        className={"emp-type-btn-primary"}>Save</span>
                    </button>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <div className="emp-type-subtitle1">
                        <div className={"mt-4"}>
                            {this.renderAssetCard()}
                        </div>
                    </div>
                </div>
            </div>
        </Form>;
    }

    renderTextRow(fieldName, label) {
        return (
            <div className="row mt-2 mb-3">
                <div className={"col-12 col-sm-6"}>
                    <Field name={fieldName} label={label} component={titleField}/>
                </div>
            </div>
        );
    }

    renderSelectFieldRow(fieldName, label) {
        return (
            <div className="row mt-2 mb-3">
                <div className={"col-12 col-sm-6"}>
                    <Field name={fieldName} label={label} component={selectField}/>
                </div>
            </div>
        );
    }

    renderAssetImage() {
        const handleFiles = (e) => {
            let name = e.currentTarget.files.length > 0 ? $.trim(e.currentTarget.files[0].name).substring(0, 30).trim(this) + "..." : '';
            $('.organization_logo').html(name);
            if (e.target.files.length > 0) {
                let url = URL.createObjectURL(e.target.files[0]);
                $('#preview_img').attr('src', url);
            } else {
                $('#preview_img').attr('src', require("../../styles/images/icon/logo.png"));
            }
        };

        const {assetType} = this.props;
        const imageUrl = assetType.imageUrl ? assetType.imageUrl : `https://via.placeholder.com/250?text=${assetType.name || "Asset Type"}`;
        return <>
            <div className={"mb-2"}>
                <img id="preview_img" className="placeholder img-thumbnail"
                     src={imageUrl}></img>
            </div>
            <NoLabelFileInputField
                type="file"
                name="image"
                placeholder="New York, NY, USA"
                //label="Choose Logo "
                HandleChange={(e) => handleFiles(e)}
                //iconClass="fa fa-info-circle"
                className="custom-file-input form-control input_modifier pointer-none"
                inputGroupLabel={""}
                inputGroupLabelClass="custom-file-label emp_cst_file_label organization_logo pointer-none"
                inputGroupClass="input-group mb-3 cst-upload"
                htmlFor="inputGroupFile01"
                id="inputGroupFile01"
            />

        </>
    }


    renderAssetCard() {
        return (
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-2">
                        {this.renderAssetImage()}
                    </div>

                    <div className="col">
                        <div className={"container-fluid"}>
                            {this.renderTextRow("name", "Name")}
                            {this.renderTextRow("description", "Description")}
                            {this.renderSelectFieldRow("assetCategory", "Asset Category")}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export const validate = (values) => {
    const errors = {};
    let requiredFields = ['name', 'description', 'assetCategory'];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};
export const mapStateToProps = (state) => {
    return ({
        assetType: state.assetTypeReducer.assetType,
        initialValues: state.assetTypeReducer.assetType
    });
};
export const FORM_NAME = "assetTypeForm";

const wrappedForm = connect(mapStateToProps)(
    reduxForm({
        form: FORM_NAME,
        enableReinitialize: true,
        validate
    })(AssetTypeForm)
);

export default wrappedForm;