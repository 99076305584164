import React from 'react';
import {Form, reduxForm} from 'redux-form';
import { Link } from 'react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
// import { Link } from 'react-router';
import * as AuthActions from '../../actions/authActions';
import validate from './validate';
import {InputField} from '../InputField/InputField';
import './loginform.scss';
import '../../styles/custom/_custom_variables.scss'

export class LoginForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alertClass: 'd-none'
        };
    }
    componentWillReceiveProps (nextProps) {
        if (nextProps.error && this.state.alertClass != 'fade in'){
            this.setState({alertClass: 'fade in'});
            setTimeout(() => this.setState({alertClass: 'fade up'}) ,2000)
        }
        else if (this.state.alertClass != 'd-none') {
            this.setState({alertClass: 'd-none'})
        }
    }
    render() {
        const { error, handleSubmit } = this.props;

        return (
        <section className="emp-sec emp-login-sec">
            <div className="container">
                <div className="row">
                    <div className="col-sm-12 text-center">
                        <div className="emp-brand">
                            <img src={require('../../styles/images/icon/new_logo.png')} alt="Logo" />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                        <div className="emp-login-form">
                            <div className="card shadow emp-form-card">
                                <div className="card-body">
                                    <div className="emp-form-heading text-center">
                                        <h2>Sign In</h2>
                                    </div>
                                    <div className={`login w-25 alert alert-dismissible alert-danger ${this.state.alertClass}`}>
                                        {error}
                                        <button className="close" onClick={()=> this.setState({alertClass: 'd-none'})}>x</button>
                                    </div>
                                    <Form onSubmit={handleSubmit} className="login-form" noValidate={true}>
                                        <div className="form-group">
                                            <label className="label_modifier">Work Email</label>
                                            <InputField
                                                type="email"
                                                name="user[email]"
                                                className="form-control input_modifier"
                                                />

                                        </div>
                                        <div className="form-group">
                                            <label className="label_modifier">Password</label>

                                            <InputField
                                                name="user[password]"
                                                type="password"
                                                className="form-control input_modifier"
                                                />
                                        </div>

                                        <div className="form-group text-center">
                                            <button type="submit"
                                                    className="btn btn-primary emp-btn emp-form-btn">Continue
                                            </button>
                                        </div>
                                        <div className="form-group text-center form-bottom-links">
                                            <p>Forgot your Password? <Link to="/auth/forgot_password">Reset Password</Link></p>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
    }
}


const mapStateToProps = (state) => ({
    auth: state.authReducer
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch)
});

export default reduxForm({
    form: 'login',
    validate,
    onSubmit: (values, dispatch) => dispatch(AuthActions.login(values))
})(connect(mapStateToProps, mapDispatchToProps)(LoginForm))
