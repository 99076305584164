export const deviceAssignmentConstants = {
    GET_DEVICE_ASSIGNMENT: 'GET_DEVICE_ASSIGNMENT',
    GET_DEVICE_ASSIGNMENT_FULFILLED: 'GET_DEVICE_ASSIGNMENT_FULFILLED',

    LIST_DEVICE_ASSIGNMENTS: 'LIST_DEVICE_ASSIGNMENTS',
    LIST_DEVICE_ASSIGNMENTS_FULFILLED: 'LIST_DEVICE_ASSIGNMENTS_FULFILLED',

    CREATE_DEVICE_ASSIGNMENT: 'CREATE_DEVICE_ASSIGNMENT',
    CREATE_DEVICE_ASSIGNMENT_FULFILLED: 'CREATE_DEVICE_ASSIGNMENT_FULFILLED',

    DELETE_DEVICE_ASSIGNMENT: 'DELETE_DEVICE_ASSIGNMENT',
    DELETE_DEVICE_ASSIGNMENT_FULFILLED: 'DELETE_DEVICE_ASSIGNMENT_FULFILLED',

    UPDATE_DEVICE_ASSIGNMENT: 'UPDATE_DEVICE_ASSIGNMENT',
    UPDATE_DEVICE_ASSIGNMENT_FULFILLED: 'UPDATE_DEVICE_ASSIGNMENT_FULFILLED',
};