export class CustomDashboardModel {
    tenant_id: string;
    id: string;
    dashboard_title: string;
    owner: string;
    breakpoints: IDashboardGrid;
    columns: IDashboardGrid;
    widgets: WidgetModel[];

    constructor(
        tenant_id: string,
        id: string,
        dashboard_title: string,
        owner: string,
        breakpoints: IDashboardGrid,
        columns: IDashboardGrid,
        widgets: WidgetModel[] = [],
    ) {
        this.tenant_id = tenant_id;
        this.id = id;
        this.dashboard_title = dashboard_title;
        this.owner = owner;
        this.breakpoints = breakpoints;
        this.columns = columns;
        this.widgets = widgets;
    }

    static fromJson(json: any): CustomDashboardModel {
        return new CustomDashboardModel(
            json['tenant_id'],
            json['id'],
            json['dashboard_title'],
            json['owner'],
            json['breakpoints'],
            json['columns'],
            json['widgets'],
        );
    }
}

export interface IDashboardGrid {
    [key: string]: number;
}

export class WidgetModel {
    id: string;
    widget_type: string;
    color_hex: string;
    label: string;
    metric: string;
    unit: string | null;
    instance_id: string;
    layouts: Record<string, WidgetLayout>;
    properties: Record<string, any>

    constructor(
        id: string,
        widget_type: string,
        color_hex: string,
        label: string,
        metric: string,
        unit: string | null,
        instance_id: string,
        layouts: Record<string, WidgetLayout>,
        properties: Record<string, any>,
    ) {
        this.id = id;
        this.widget_type = widget_type;
        this.color_hex = color_hex;
        this.label = label;
        this.metric = metric;
        this.unit = null;
        this.instance_id = instance_id;
        this.layouts = layouts;
        this.properties = properties || {};
    }

    static fromJson(json: any): WidgetModel {
        return new WidgetModel(
            json['id'],
            json['widget_type'],
            json['color_hex'],
            json['label'],
            json['metric'],
            json['unit'],
            json['instance_id'],
            json['layouts'],
            json['properties']
        );
    }
    //TODO: remove hardcoded values
    static convertToWidgetModel(values: any): WidgetModel {
        const converted: any = {};
        converted['id'] = values['id'];
        converted['widget_type'] = values['widget_type'];
        converted['label'] = values['label'];
        converted['layouts'] = values['layouts'] || createNewWidgetLayout(values['id']);
        converted['properties'] = values['properties'] || {};
        return this.fromJson(converted);
    }
}

function createNewWidgetLayout(id: string): Record<string, WidgetLayout> {
    const newLgLayout = { x: 0, y: 0, w: 4, h: 2, minW: 4, minH: 2, i: id, maxH: 1000, maxW: 1000 };
    const newSmLayout = { x: 0, y: 0, w: 6, h: 2, minW: 5, minH: 2, i: id, maxH: 1000, maxW: 1000 };

    const newLayouts: Record<string, WidgetLayout> = {
        sm: WidgetLayout.fromJson(newSmLayout),
        lg: WidgetLayout.fromJson(newLgLayout),
    };

    return newLayouts;
}

export class ChartMetricModel {
    metric_name: string;
    instance_id: string;
    color_hex: string;
    unit: string;

    constructor(
        metric_name: string,
        instance_id: string,
        color_hex: string,
        unit: string
    ) {
        this.metric_name = metric_name;
        this.instance_id = instance_id;
        this.color_hex = color_hex;
        this.unit = unit;
    }

    static fromJson(json: any): ChartMetricModel {
        return new ChartMetricModel(
            json['metric_name'],
            json['instance_id'],
            json['color_hex'],
            json['unit'],
        );
    }

    toCustomJson(): any {
        return {
            metric: { metric_name: this.metric_name, instance_id: this.instance_id },
            color_hex: this.color_hex,
            unit: this.unit,
        };
    }
}

export class WidgetLayout {
    x: number;
    y: number;
    w: number;
    h: number;
    i: string;
    minW: number;
    minH: number;
    maxW: number;
    maxH: number;

    constructor(
        x: number,
        y: number,
        w: number,
        h: number,
        i: string,
        minW: number,
        minH: number,
        maxW: number,
        maxH: number,
    ) {
        this.x = x;
        this.y = y;
        this.w = w;
        this.h = h;
        this.i = i;
        this.minW = minW;
        this.minH = minH;
        this.maxW = maxW;
        this.maxH = maxH;
    }

    static fromJson(json: any): WidgetLayout {
        return new WidgetLayout(
            json['x'],
            json['y'],
            json['w'],
            json['h'],
            json['i'],
            json['minW'],
            json['minH'],
            json['maxW'],
            json['maxH'],
        );
    }
}
