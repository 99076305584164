import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import {Area} from "./model/Area";
import {convertApiResponseToAreaType} from "./areaTypeApi";
import {buildAxiosErrorHandlerConfig} from "../utils/utils";



const convertApiResponseToArea = (response) => {
    return new Area(
        response['id'],
        response['name'],
        response['description'],
        response['area_type_id'],
        response['parent_id'],
        response['created_by_id'],
        response['updated_by_id'],
        new Date(response['created_at']),
        new Date(response['updated_at']),
        response['image_url'] ? response['image_url']['url'] : null,
        response['icon_url'] ? response['icon_url']['url'] : null,
        response['area_type'] ? convertApiResponseToAreaType(response['area_type']) : null,
        response['parent'] ? convertApiResponseToArea(response['parent']) : null,
        response['children'] ? response['children'].map(convertApiResponseToArea) : [],
        response['bounds'] ? response['bounds'] : null
    )
}

const convertAreaFormToApiRequest = (values) => {
    return {
        id: values.id,
        name: values.name,
        description: values.description,
        area_type_id: values.areaTypeId,
        parent_id: values.parentId,
        created_by_id: values.createdById,
        updated_by_id: values.updatedById,
        created_at: values.createdAt,
        updated_at: values.updatedAt,
        ...((values.image && values.image.length) && {image_url: values.image[0]}),
        ...((values.iconUrl && values.imageUrl.length) && {icon_url: values.iconUrl[0]}),
        bounds: values.bounds
    }
}


export const listAreas = (data) => {
    return axios.get(`${BASE_URL()}/api/v1/areas`, {
        params: data,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data.items.map(item => convertApiResponseToArea(item)));
};



export const getArea = (id, options) => {
    return axios.get(`${BASE_URL()}/api/v1/areas/${id}`, buildAxiosErrorHandlerConfig({
        params: {
            ...options
        },
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    })).then(response => convertApiResponseToArea(response.data));
};

export const createArea = (data) => {
    const formData = new FormData();
    const request = convertAreaFormToApiRequest(data);

    Object.keys(request).forEach(key => {
        formData.append(`area[${key}]`, request[key]);
    });
    return axios.post(`${BASE_URL()}/api/v1/areas`, formData, buildAxiosErrorHandlerConfig({
        headers: {
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    })).then(response => convertApiResponseToArea(response.data));
};

export const updateArea = (id, data) => {
    const formData = new FormData();
    const request = convertAreaFormToApiRequest(data);

    Object.keys(request).forEach(key => {
        formData.append(`area[${key}]`, request[key]);
    });

    return axios.put(`${BASE_URL()}/api/v1/areas/${id}`, formData, buildAxiosErrorHandlerConfig({
        headers: {
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    })).then(response => convertApiResponseToArea(response.data));
};

export const deleteArea = (id) => {
    return axios.delete(`${BASE_URL()}/api/v1/areas/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    })
        .then(response => response.data);
};
