import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {reset} from "redux-form";
import * as AuthActions from '../../actions/authActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {browserHistory, withRouter} from 'react-router';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import * as WorkflowActions from "../../actions/workflowActions";
import * as WorkflowScheduleActions from "../../actions/workflowScheduleActions";
import {WorkflowSchedulePage} from '../../components/Workflow/WorkflowSchedulePage';
import {IntervalType} from "../../api/model/WorkflowSchedule";
import {WORKFLOW_SCHEDULE_FORM_NAME, INTERVAL_SCHEDULE_OPTS} from "../../components/Workflow/WorkflowScheduleForm";

import {formatWorkflowParamsForServer, formatScheduleIntervalForServer} from "../../components/Workflow/FormValueConversionHelper";
import * as Moment from "moment/moment";

export class CreateWorkflowScheduleContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            initialValues: {}
        }
        this.onWorkflowFormSubmit = this.onWorkflowFormSubmit.bind(this);
    }

    async componentDidMount() {
        await this.props.workflowActions.listWorkflows();
        this.setState({...this.state, loading: false});
    }

    async onWorkflowFormSubmit(values, dispatch) {
        const workflow = this.props.workflowReducer.selectedWorkflow;
        const convertedParamValues = formatWorkflowParamsForServer({...values.workflow_params}, workflow.ui_schema);
        const newValues = {...values, workflow_params: convertedParamValues};
        const scheduleValues = formatScheduleIntervalForServer({...values});


        try {
            const newSchedule = await this.props.workflowScheduleActions.createWorkflowSchedule({...newValues, ...scheduleValues});
            browserHistory.push(`/workflow_schedules/${newSchedule.value.id}`);
        } catch (e) {
            console.error(e)
        }
    }

    onWorkflowChange(workflow) {
        this.props.workflowActions.selectWorkflow(workflow);
        const uiSchemaProps = workflow.ui_schema.properties;
        const newInitialValues = {workflow_id: workflow.id, workflow_params: {}};

        for (let key in uiSchemaProps) {
            if (uiSchemaProps[key].hasOwnProperty("ui:default")) {
                newInitialValues["workflow_params"][key] = uiSchemaProps[key]["ui:default"];
            }
        }

        this.props.dispatch(this.props.reset(WORKFLOW_SCHEDULE_FORM_NAME));
        this.setState({...this.state, initialValues: newInitialValues});
    }

    render() {
        const {auth, authActions} = this.props;
        const {loading} = this.state;

        return (
            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <WorkflowSchedulePage
                        onFormSubmit={this.onWorkflowFormSubmit}
                        workflows={this.props.workflowReducer.workflows}
                        buttonText={"Submit"}
                        initialValues={{...STATIC_FORM_VALUES, ...this.state.initialValues}}
                        onWorkflowChange={this.onWorkflowChange.bind(this)}
                        selectedWorkflow={this.props.workflowReducer.selectedWorkflow}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    workflowReducer: state.workflowReducer,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    workflowScheduleActions: bindActionCreators(WorkflowScheduleActions, dispatch),
    workflowActions: bindActionCreators(WorkflowActions, dispatch),
    reset: reset,
    dispatch: dispatch
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateWorkflowScheduleContainer));

const STATIC_FORM_VALUES = {
    interval: INTERVAL_SCHEDULE_OPTS[0].value,
    interval_type: IntervalType.SIMPLE,
    start_date: Moment(new Date((new Date()).valueOf()).toISOString()).local().format('MM-DD-YYYY hh:mm A'),
    compute_size: "default"
}