import moment from 'moment';
import React, {Component} from "react";
import {bindActionCreators} from 'redux';
import {browserHistory} from 'react-router';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {Responsive, WidthProvider} from 'react-grid-layout';

import * as CustomDashboardActions from "../../actions/customDashboardAction";
import * as GeneralSettingsActions from '../../actions/generalSettingAction';
import * as UserActions from '../../actions/userAction';

import DashboardSelectionNav from "./DashboardSelectionNav";
import MetricWidget from "./MetricWidget";
import MetricChart from "./MetricChart";
import EditableInlineField from '../EditableInlineField/EditableInlineField';
import confirmDeleteAlertDialogue from './confirmDeleteAlertDialogue';
import LoadingMessage from "../LoadingMessage/LoadingMessage";

import {DASHBOARD_GRID_BPS, DASHBOARD_GRID_COLS} from "../../config";
import {CustomDashboardModel, WidgetLayout, IDashboardGrid} from "../../api/model/CustomDashboard";
import {State as CustomDashboardReducer} from "../../reducers/customDashboardReducer";
import RefreshIntervalComponent from "./RefreshIntervalComponent";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

const ResponsiveGridLayout = WidthProvider(Responsive);
const Moment = moment;


class NewDashboardDetailView extends Component<{
    generalSettingsActions: typeof GeneralSettingsActions;
    customDashboardActions: typeof CustomDashboardActions;
    userActions: typeof UserActions;
    customDashboardReducer: CustomDashboardReducer;
    generalSettingsReducer: any;
    userReducer: any;
    dashboardId: string;
    params: any;
    location: any;
    children: any;
},{
    loading: boolean;
    layouts: Record<string, WidgetLayout[]>;
    mounted: boolean;
    breakpoint: IDashboardGrid | null;
    showWidgetForm: boolean;
    editedDevice: any;
    start_time: string;
    end_time: string;
    period: string;
    isDraggable: boolean;
    layoutSize: any;
}>{
    gridLayoutRef: any;
    setGridLayoutRef: Function;

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            layouts: {lg: [], sm: []},
            mounted: false,
            breakpoint: null,
            showWidgetForm: false,
            editedDevice: null,
            start_time: new Date((new Date()).valueOf() - 1000 * 3600 * 24).toISOString(),
            end_time: new Date().toISOString(),
            period: '1H',
            isDraggable: false,
            layoutSize: null,
        }
        this.gridLayoutRef = null;
        this.setGridLayoutRef = element => {
            this.gridLayoutRef = element;
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.gridLayoutRef && this.gridLayoutRef.state && this.gridLayoutRef.state.width !== this.state.layoutSize){
            this.setState({...this.state, layoutSize: this.gridLayoutRef.state.width})
        }
    }

    async componentDidMount(){
        await this.props.generalSettingsActions.fetchOrganizationData();

        let selectedDashboard = this.props.customDashboardReducer.dashboards.find(dashboard=>dashboard.id === this.props.dashboardId);
        if(selectedDashboard){
            this.props.customDashboardActions.selectCustomDashboard(selectedDashboard);
        }else{
            try{
                await this.props.customDashboardActions.getCustomDashboard(this.props.dashboardId);
            }
            catch(e){
                console.log(e)
                return browserHistory.push("/environment/custom_dashboard");
            }
        }

        const smLayouts: WidgetLayout[] = [];
        const lgLayouts: WidgetLayout[] = [];

        this.props.customDashboardReducer.selectedDashboard.widgets.forEach(widget=>{
            const {sm, lg} = widget.layouts;
            smLayouts.push(sm);
            lgLayouts.push(lg);
        });

        this.setState({
            ...this.state,
            layouts: {sm: smLayouts, lg: lgLayouts},
            loading: false
        });
    }

    displayWidgets() {
        if (!this.props.customDashboardReducer.selectedDashboard.hasOwnProperty("id")) return null;
        const layout = this.state.layoutSize >= DASHBOARD_GRID_BPS.lg ? "lg" : "sm";

        return this.props.customDashboardReducer.selectedDashboard.widgets.map((device, idx) => {
            return (
                <div className="card shadow emp-dashboard-device-card text-center p-3" key={device.id}>
                    <div className="emp-device-card-settings mr-2">
                        <Link to={`/environment/custom_dashboard/${this.props.dashboardId}/widgets/${device.id}/edit`}>
                            <i className="fa-solid fa-pen"></i>
                        </Link>
                        <i className="fa fa-trash-o fa-lg" aria-hidden="true" onClick={() => this.handleDeleteWidget(device.id)}></i>
                    </div>
                    
                    <MetricWidget
                        start_time={this.state.start_time}
                        properties={device.properties}
                        end_time={this.state.end_time}
                        period={this.state.period}
                        limit={3500}
                        system_of_units={this.props.generalSettingsReducer.organization.system_of_units}
                        render={({data, loading, error}) =>
                            <MetricChart
                                chartHeight={300}
                                chartType={device.widget_type}
                                data={data}
                                loading={loading}
                                error={error}
                                title={device.label ? device.label : device.instance_id}
                                width={device.layouts[layout].w}
                                layoutSize={this.state.layoutSize}
                            />
                        }
                    />
                </div>
            );
        });
    }

    async onLayoutChange(newLayout: WidgetLayout[], layouts: Record<string, WidgetLayout[]>) {
        if(!this.props.customDashboardReducer.selectedDashboard.hasOwnProperty("id")) return;
        
        const newSelectedDashboard: CustomDashboardModel = {...this.props.customDashboardReducer.selectedDashboard};
        const dashboardSizes: string[] = Object.keys(layouts);
        newSelectedDashboard.widgets.forEach(widget => {
            dashboardSizes.forEach(size=>{
                const newLayout = layouts[size].find(layout => layout.i === widget.id);
                if(!newLayout) return;
                widget.layouts[size] = newLayout;
            });
        });
        this.setState({...this.state, layouts: layouts});
        try{
            await this.props.customDashboardActions.updateCustomDashboard(newSelectedDashboard);
        }
        catch(e){
            console.log(e);
            browserHistory.push("/environment/custom_dashboard");
        }
    }

    async handleDeleteWidget(id) {
        const widget = this.props.customDashboardReducer.selectedDashboard.widgets.find(widget=>widget.id === id);
        if(!widget) {
            return console.log("Widget not found");
        }
        const confirmTitle: string = widget.label ? widget.label : widget.instance_id;
        let confirm = await confirmDeleteAlertDialogue(`Delete Widget "${confirmTitle}"?`);
        if (!confirm) return;

        const newWidgets = this.props.customDashboardReducer.selectedDashboard.widgets.filter(widget => widget.id !== id);
        const newDashboard = {...this.props.customDashboardReducer.selectedDashboard};
        newDashboard.widgets = newWidgets;

        try{
            await this.props.customDashboardActions.updateCustomDashboard(newDashboard)
        }
        catch(e){
            console.log(e);
            browserHistory.push("/environment/custom_dashboard");
        }

    }

    onDateFormSubmit(values,dispatch) {
        this.setState({
            ...this.state,
            start_time: Moment(values.device_data.start_date, 'MM-DD-YYYY hh:mm A').toISOString(),
            end_time: Moment(values.device_data.end_date, 'MM-DD-YYYY hh:mm A').toISOString(),
            period: values.device_data.period
        });
    }

    async handleDeleteDashboard(){
        let confirm = await confirmDeleteAlertDialogue(`Delete Dashboard "${this.props.customDashboardReducer.selectedDashboard.dashboard_title}"?`);
        if (!confirm) return;

        this.setState({...this.state, loading: true});

        try{
            await this.props.customDashboardActions.deleteCustomDashboard(this.props.customDashboardReducer.selectedDashboard.id);
        }
        catch(e){
            console.log(e);
        }
        return browserHistory.push("/environment/custom_dashboard");
    }

    async handleDashboardTitleUpdate(index, newName){
        if (newName.length) {
            const newDashboard = this.props.customDashboardReducer.selectedDashboard;
            newDashboard.dashboard_title = newName;
            this.setState({...this.state, loading: true});
            try{
                await this.props.customDashboardActions.updateCustomDashboard(newDashboard);
            }
            catch(e){
                console.log(e);
                return browserHistory.push("/environment/custom_dashboard");
            }
            this.setState({...this.state, loading: false});
        }
    }

    renderDashboardTitle(){
        if (!this.props.customDashboardReducer.selectedDashboard.hasOwnProperty("id")) return null;

        return <div className="emp-dashboard-title-area">
                <EditableInlineField value={this.props.customDashboardReducer.selectedDashboard.dashboard_title} onUpdate={this.handleDashboardTitleUpdate.bind(this)}>
                    <h4>{this.props.customDashboardReducer.selectedDashboard.dashboard_title}</h4>
                </EditableInlineField>
                <i className="fa fa-trash-o fa-lg" aria-hidden="true" onClick={this.handleDeleteDashboard.bind(this)}></i>
            </div>
    }

    async handleRefresh(){
        this.setState({...this.state, end_time: new Date().toISOString()});
    }

    render(){
        if (this.state.loading) {
            return <LoadingMessage/>;
        }

        const options = [
            {value: 'minutes', label: 'Minutes'},
            {value: 'hour', label: 'Hour'},
            {value: 'day', label: 'Day'},
            {value: 'week', label: 'Week'},
            {value: 'month', label: 'Month'}
        ];

        return (
            <main className="app-content">
                <div className="emp-dashboard-detail-view">
                    <div className="app-title border-bottom">
                        {this.renderDashboardTitle()}
                        <DashboardSelectionNav className="mb-2" onSubmitHandler={this.onDateFormSubmit.bind(this)} options={options}/>
                    </div>
                    <section className="emp_org_settings_sec p emp_report_sec">
                        <div className="row col-md-12 justify-content-end ml-0 pr-2">
                            <div className={"flex-center"}>
                                <button className="btn btn-primary" onClick={()=>this.setState({...this.state, isDraggable: !this.state.isDraggable})}>
                                    <i className="fa fa-pencil"></i>{" "}{this.state.isDraggable ? "Stop Editing Dashboard" : "Edit Dashboard"}
                                </button>
                                <button className="btn btn-primary ml-2" onClick={()=>browserHistory.push(`${window.location.pathname}/widgets/create_new_widget`)}>
                                    <i className="fa fa-plus"></i>{" "}Add New Chart
                                </button>
                            </div>
                            <div className={"ml-3"}>
                                <RefreshIntervalComponent onRefresh={this.handleRefresh.bind(this)}/>
                            </div>
                        </div>
                        <ResponsiveGridLayout
                            layouts={this.state.layouts}
                            onLayoutChange={this.onLayoutChange.bind(this)}
                            measureBeforeMount={true}
                            onBreakpointChange={(b)=>console.log(b)}
                            useCSSTransforms={this.state.mounted}
                            preventCollision={false}
                            breakpoints={DASHBOARD_GRID_BPS}
                            cols={DASHBOARD_GRID_COLS}
                            isDraggable={this.state.isDraggable}
                            ref={this.setGridLayoutRef}
                        >
                            {this.displayWidgets()}
                        </ResponsiveGridLayout>
                    </section>
                </div>
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        userReducer: state.userReducer,
        customDashboardReducer: state.customDashboardReducer,
        auth: state.authReducer,
        generalSettingsReducer: state.generalSettingReducer,
    });
};

const mapDispatchToProps = (dispatch) => ({
    userActions: bindActionCreators(UserActions, dispatch),
    customDashboardActions: bindActionCreators(CustomDashboardActions, dispatch),
    generalSettingsActions: bindActionCreators(GeneralSettingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewDashboardDetailView);
