import React from 'react';
import PropTypes from 'prop-types';
import {ListView} from "../../ui/ListView/ListView";
import {Link} from "react-router";
import {AreaType} from "../../api/model/AreaType";
import {SearchInputField} from "../../ui/Input/SearchInputField";


const renderTextRow = (label, text) => {
    return (
        <div className="row mt-2">
            <span className="col-6 col-sm-4 col-md-4 col-lg-3 emp-type-subtitle1">{label}</span>
            <span className={"col-6 col-sm col-md col-lg emp-type-subtitle1"}>{text}</span>
        </div>
    );
}

type Props = {
    areaTypes: AreaType[],
    onItemClick: (e) => void,
    onDeleteItemClick: (item: AreaType) => void,
    onSearchInput: (query: string) => void
}


export class AreaTypesPage extends React.Component<Props> {
    renderAreaTypesListItem = (item, index) => {
        return <div className="container-fluid">
            <div className={"row"}>
                <div className={"col"}/>
                <div className="col-1">
                    <button className={"btn btn-link"} onClick={(e) => {
                        e.stopPropagation();
                        this.props.onDeleteItemClick(item);
                    }}><i
                        className="fa fa-trash" aria-hidden="true"></i></button>
                </div>
            </div>
            <div className="row">
                <div className="col-2">
                    <img className={"placeholder img-thumbnail"} src={item.imageUrl || `https://via.placeholder.com/150?text=${item.name}`}/>
                </div>
                <div className="col">
                    {renderTextRow("Name", item.name)}
                    {renderTextRow("Description", item.description)}
                </div>
            </div>
        </div>
    }

    render() {
        const {areaTypes, onItemClick, onSearchInput} = this.props;
        return (
            <main className="app-content">
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <h2 className="emp-type-h2">Area Types</h2>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col">
                            <Link to="/area_types/new">
                                <button className="btn btn-primary emp-type-btn-primary">CREATE</button>
                            </Link>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12">
                            <SearchInputField label={"Search"} onChange={onSearchInput}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <ListView
                                datasource={areaTypes}
                                renderItem={this.renderAreaTypesListItem}
                                onItemClick={onItemClick}
                            />
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

