import React from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router';
import {Field, formValueSelector, reduxForm} from "redux-form";
import {NewSearchableDropDownField} from "../SearchableDropDownField/NewSearchableDropDownField";
import {DeviceItemField, MemberItemField } from "../SearchableDropDownField/SearchableDropDownField";
import DeviceActiveSearchInputDropDownField from "../SearchInput/DeviceActiveSearchInputDropDownField";
import MemberDropDownField from "../SearchInput/MemberDropDownField";


const validate = (values) => {
    const errors = {};

    const requiredFields = ["name"];
    for (let field of requiredFields) {
        if (!values[field]) errors[field] = "Required";
    }

    const atLeastOneValueFields = ["devices", "contacts"];
    for (let field of atLeastOneValueFields) {
        if (!values[field] || values[field].length < 1) {
            errors[field] = {_error: "At least 1 value is required"};
        }
    }

    return errors;
}

export class GroupForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        };
    }


    renderField({input, className, type, placeholder, meta: {touched, error, submitFailed}}) {
        let fieldStyle = "";
        let errorMessage = "";
        if (touched && error) {
            fieldStyle = "input_error"
            errorMessage = <div className="form-group__error">{error}</div>
        }
        return (
            <React.Fragment>
                <div>
                    <input {...input} placeholder={placeholder}
                           className={`form-control textarea_modifier shadow_select_hover ${fieldStyle}`} type={type}/>
                </div>
                <div>
                    {errorMessage}
                </div>
            </React.Fragment>
        )
    }

    componentDidMount(){
        if(this.props.group){
            this.props.initialize({name: this.props.group.name,
                devices: this.props.group.devices,
                contacts: this.props.group.users});
        }
    }

    onSubmit(values, dispatch) {
        const data = {name: values.name};

        data['group_items_attributes'] = {};
        let index=0;
        values.contacts.forEach((user) => {
            data['group_items_attributes'][index]={};
            data['group_items_attributes'][index]['member_id'] = user.id;
            data['group_items_attributes'][index]['member_type'] = 'User';
            index+=1;
        });

        values.devices.forEach((device) => {
            data['group_items_attributes'][index]={};
            data['group_items_attributes'][index]['member_id'] = device.id;
            data['group_items_attributes'][index]['member_type'] = 'Device';
            index+=1;
        });

        if(this.props.group){
            data['id'] = this.props.group.id;
        }

        this.props.onSubmitGroup(data, dispatch);
    }

    render(){
        const {handleSubmit} = this.props;
        return(
            <main className="app-content">
                <section className="emp-sec emp-active-device-sec create_new_report_sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 offset-xl-2 col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-12 offset-sm-0">
                                <div className="emp-active-title">
                                    <div className="media align-items-center">
                                        <Link to="/groups_devices">
                                            <span className="mr-3 emp_icn_middle">
                                                <img src={require("../../styles/images/icon/ic_left-arrow.png")} alt="Icon" />
                                            </span>
                                        </Link>
                                        <div className="media-body">
                                            <h5 className="mt-0">{this.props.formTitle}</h5>
                                        </div>
                                    </div>
                                </div>
                                <form className="emp_create_report_form" key={FORM_NAME}
                                      onSubmit={handleSubmit(this.onSubmit.bind(this))}
                                      noValidate>
                                    <div className="card shadow emp-form-card mb-3 emp_create_report_card">
                                        <div className="card-header emp_create_report_header">
                                            <div className="row">
                                                <div className="col-xl-6">
                                                    <div className="form-group mb-0">
                                                        <label className="label_modifier">Name of group</label>
                                                        <Field name="name" placeholder="Type in the name of the group"
                                                               component={this.renderField} type="text"/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body emp_create_report_body emp_create_notify_body">
                                            <div className="row">
                                                <div className="col-xl-6">
                                                    <div className="form-group search-member-container">
                                                        <NewSearchableDropDownField
                                                            fieldName={'contacts'}
                                                            itemFieldComponent={MemberItemField}
                                                            title={'Contacts'}
                                                            placeholder="Type Contact Names Here"
                                                            isDisabled={false}
                                                            render={({
                                                                         placeholder,
                                                                         isDisabled,
                                                                         handleChange,
                                                                         classNames
                                                                     }) =>
                                                                <MemberDropDownField
                                                                    placeholder={placeholder}
                                                                    isDisabled={isDisabled}
                                                                    classNames={classNames}
                                                                    handleChange={handleChange}
                                                                    shouldRenderSuggestions={()=>true}
                                                                    selections={this.props.contacts}/>}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xl-6">
                                                    <div className="form-group search-member-container">
                                                        <NewSearchableDropDownField
                                                            fieldName={'devices'}
                                                            itemFieldComponent={DeviceItemField}
                                                            title={'Devices'}
                                                            placeholder="Device Name"
                                                            isDisabled={false}
                                                            render={({
                                                                         placeholder,
                                                                         isDisabled,
                                                                         handleChange,
                                                                         classNames
                                                                     }) =>
                                                                <DeviceActiveSearchInputDropDownField
                                                                    placeholder={placeholder}
                                                                    isDisabled={isDisabled}
                                                                    handleChange={handleChange}
                                                                    classNames={classNames}
                                                                    shouldRenderSuggestions={()=>true}
                                                                    selections={this.props.devices}
                                                                />
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mt-3">
                                                <div className="col-lg-6">
                                                    <a href="#" className="text-primary">{this.props.contacts.length} members</a>
                                                </div>
                                                <div className="col-lg-6">
                                                    <a href="#" className="text-primary">{this.props.devices.length} devices</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="active-device-btn text-right">
                                        <button type="submit" className={`btn btn-next btn-primary emp-cst-btn emp-btn `} data-toggle="modal" data-target="#create_group_alert">
                                            <span className="mr-2 emp_icn_middle">
                                                <img src={require("../../styles/images/icon/ic_checking.png")} alt="Icon" />
                                            </span>{this.props.submitLabel}</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

const FORM_NAME = 'groupForm';

GroupForm = reduxForm({
    form: FORM_NAME,
    validate
})(GroupForm)

const selector = formValueSelector(FORM_NAME)
GroupForm = connect(state => {
    const devices = selector(state, 'devices')
    const contacts = selector(state, 'contacts')
    return {
        devices: devices || [],
        contacts: contacts || []
    }
})(GroupForm)

export default GroupForm;