import React from 'react';
import { form_roles } from '../../formSchema';
import Select from 'react-select';

export const MemberRow = ({ user, removeUser, updateUserRole  }) => {

    const handleRoleSelect = (e) => {
        updateUserRole(user, e);
    };

    const handleUserRemoval = () => {
        removeUser(user);
    }


    return (
      <tr>
        <td className="">
          <p className="mb-0 font-weight-bold">{user.first_name}</p>
          <p className="mb-0 text-muted">{user.email}</p>
        </td>
        <td>
          <Select
            onChange={handleRoleSelect}
            options={form_roles.map((role) => ({
              value: role,
              label: role
            }))}
            placeholder={user.form_role ? user.form_role : "-Select-"}
            className="form-group__field text-capitalize"

          />
        </td>
        <td className="pl-3">
          <i
            className="cursor-pointer fa-solid fa-trash"
            onClick={handleUserRemoval}
          />
        </td>
      </tr>
    )
};

export const MemberTable = ({ children }) => {
    return (
        <div className='p-4 my-2 bg-white shadow-sm text-left'>
            <table className='w-100'>
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Role</th>
                        {/* <th>Tasks</th> */}
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {children}
                </tbody>
            </table>
        </div>
    );
};