import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as WorkflowScheduleActions from "../../actions/workflowScheduleActions";
import * as ScheduledJobActions from "../../actions/scheduledJobAction";
import {getUserById} from "../../api/userApi";
// import * as UserActions from "../../api/userApi";
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {withRouter} from 'react-router';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import ScheduledJobDetailPage from "../../components/Workflow/ScheduledJobDetailPage";

export class ScheduledJobDetailContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            owner: null
        }
    }

    async componentDidMount() {
        // these calls needs to happen in sequence bc the second takes the id from the first 
        await this.props.scheduledJobActions.getScheduledJob(this.props.params.scheduledJobId);
        await this.props.workflowScheduleActions.getWorkflowSchedule(this.props.scheduledJobs.selectedScheduledJob.schedule_id);

        this.setState({loading: false});
    }

    render() {
        const {auth, authActions} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <ScheduledJobDetailPage/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    workflowSchedules: state.workflowScheduleReducer,
    scheduledJobs: state.scheduledJobReducer
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    workflowScheduleActions: bindActionCreators(WorkflowScheduleActions, dispatch),
    scheduledJobActions: bindActionCreators(ScheduledJobActions, dispatch),
    getUserById: getUserById
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduledJobDetailContainer));
