import AuthServiceAuditFormatter from "./formatters/AuthServiceAuditFormatter"
import TriggerServiceAuditFormatter from "./formatters/TriggerServiceAuditFormatter";
import IncidentServiceAuditFormatter from "./formatters/IncidentServiceAuditFormatter";
import UserServiceAuditFormatter from "./formatters/UserServiceAuditFormatter";

export default class AuditLogEventFormatter {

    formatLogEvent(auditLog) {
        switch (auditLog.service) {
            case "AuthService":
                return new AuthServiceAuditFormatter().formatLogEvent(auditLog)
            case "TriggerService":
                return new TriggerServiceAuditFormatter().formatLogEvent(auditLog)
            case "IncidentService":
                return new IncidentServiceAuditFormatter().formatLogEvent(auditLog);
            case "UserService":
                return new UserServiceAuditFormatter().formatLogEvent(auditLog);
        }
    }
}