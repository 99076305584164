import React from "react";
import { FieldArray, Field } from "redux-form";
import { ValidationFunction } from "../../utils/validateFunctions";


export default class FormArrayComponent extends React.Component<{
    name?: string,
    helpText?: string | undefined,
    items?: { value: any, label: string }[],
    default?: { value: any, label: string }[],
    validate?: ValidationFunction[],
    label?: string,
    inputComponent: any,
}, {}> {
    constructor(props) {
        super(props);
        this.renderComponentGroup = this.renderComponentGroup.bind(this);
        this.renderField = this.renderField.bind(this);
    }

    renderField({ input, label, type, onDelete, meta: { touched, error } }) {
        const InputComponent = this.props.inputComponent;

        return (
            <div className={"row"}>
                <div className={"col-11 mt-3 pr-0"}>
                    <InputComponent
                        {...input}
                        type={type}
                        label={label}
                        error={touched && error}
                        errorLabel={error}
                    />
                </div>
                <div className={"col-1 pl-1 mt-3 flex-center"}>
                    <button
                        type="button"
                        className={`bg-transparent border-0`}
                        title={`Remove ${label}`}
                        onClick={onDelete}
                    >
                        <i className="fa-solid fa-circle-minus"></i>
                    </button>
                </div>
            </div>
        );
    }

    renderComponentGroup({ fields, meta: { error, submitFailed } }) {
        const showError = submitFailed && error;
        return (
            <>
                <button
                    type="button"
                    onClick={() => fields.push()}
                    className={"btn btn-primary"}
                >
                    {`Add ${this.props.label}`}
                </button>
                {fields.map((item, index) => (
                    <React.Fragment key={index}>
                        <Field
                            name={item}
                            type="text"
                            label={this.props.label}
                            validate={this.props.validate}
                            component={this.renderField}
                            onDelete={() => fields.remove(index)}
                        />
                    </React.Fragment>
                ))}
                <div className={"emp-type-caption emp-color-error pt-1"}>
                    {showError && <span>{error}</span>}
                </div>
            </>
        );
    }

    render() {
        return (
            <div className="form-group">
                <label className="label_modifier">{this.props.label}</label>
                <div>
                    <FieldArray
                        name={this.props.name}
                        component={this.renderComponentGroup}
                        items={this.props.items}
                        validate={this.props.validate}
                    />
                    <span className="label_modifier">{this.props.helpText}</span>
                </div>
            </div>
        );
    }
}
