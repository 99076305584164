import React from "react";
import {Field} from 'redux-form';
import * as Proptype from "prop-types";

class FilterGroup extends React.Component {
    render = () => {
        return <ul key={this.props.index} className="padding-left-1rem margin-top-1rem">
            <li className="list-inline-item">
                <Field
                    name={`${this.props.filterGroup}.filtername`}
                    component="select"
                    className='custom-select cst-select-box input_modifier shadow_select_hover'

                >
                    {this.props.dimensions.map((dimension, index) => {
                        return <option key={index} value={dimension.name}>{dimension.shortTitle}</option>
                    })}
                </Field>
            </li>
            <li className="list-inline-item">
                <Field
                    name={`${this.props.filterGroup}.operator`}
                    component="select"
                    className='custom-select cst-select-box input_modifier shadow_select_hover'
                >
                    {
                        this.props.operators.map((operator, index) => {
                            return <option key={index}>{operator}</option>
                        })
                    }

                </Field>
            </li>

            <li className="list-inline-item">
                <Field
                    name={`${this.props.filterGroup}.value`}
                    component="input"
                    type="text"
                    placeholder="Value"
                    className='form-control textarea_modifier shadow_select_hover'
                />
            </li>
            <a
                href="#" role="button"
                className="ml-2"
                onClick={() => this.props.fields.remove(this.props.index)}
            >
                <img src={require("../../styles/images/icon/icn_close.png")} alt="Remove" />
            </a>
        </ul>;
    }
}

FilterGroup.propTypes = {
    dimensions: Proptype.array,
    operators: Proptype.array,
    values: Proptype.array,
    index: Proptype.number,
    filterGroup: Proptype.string
}

export default FilterGroup;