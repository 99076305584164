import {EMAIL_REGEXP} from '../../config';

export default function validate(values) {
    let errors = {};
    errors['user'] = {};
    console.log("In validate" + values);
    if (values['user']) {
        if (!values['user']['email']) {
            errors['user']['email'] = 'Email is required';
        } else if (!EMAIL_REGEXP.test(values['user']['email'])) {
            errors['user']['email'] = 'Email must be valid';
        }
        console.log("In validate if");

        if (!values['user']['password']) {
            errors['user']['password'] = 'Password is required';
        }
        // else if (values['user']['password'].length < PASSWORD_MIN_LEN) {
        //     errors['user']['password'] = `Password length should be at lease ${PASSWORD_MIN_LEN} symbols`;
        // }
        console.log("errors" + errors.email);

    }
    console.log(errors);
    return errors;
};