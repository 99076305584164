import React from 'react';
import {RadioBtn} from '../RadioBtn/RadioBtn';

export class GeoFenceTransitionSelection extends React.Component {
    render() {
        return <div className="inline_radio_btn">
            <div className="custom-control custom-radio">
                <RadioBtn
                    name={`conditions[0].properties.transition_type`}
                    type="radio"
                    value={"EXIT"}
                    label="is outside geofence"
                    labelClass="custom-control-label"
                    id={`outside_geofence`}
                    htmlFor={`outside_geofence`}
                />
            </div>
            <div className="custom-control custom-radio">
                <RadioBtn
                    name={`conditions[0].properties.transition_type`}
                    type="radio"
                    value={"ENTER"}
                    label="is inside geofence"
                    labelClass="custom-control-label"
                    id={`inside_geofence`}
                    htmlFor={`inside_geofence`}
                />
            </div>
        </div>;
    }
}