import React from "react";
import {Field, Form, reduxForm} from "redux-form";
import * as $ from "jquery";
import {NoLabelFileInputField} from "../FileInputField/NoLabelFileInputField";
import {connect} from "react-redux";
import {TextField} from "../../ui/Input/TextField";
import {SelectField} from "../../ui/Input/SelectField";
import {Area} from "../../api/model/Area";
import {AreaType} from "../../api/model/AreaType";

const titleField = ({input, label, meta: {touched, error}}) => {
    return (
        <TextField label={label} error={touched && error} errorLabel={error} {...input}/>
    );

}

const selectField = ({input, label, items, meta: {touched, error}}) => {
    return <SelectField label={label}
                        placeholder=""
                        error={touched && error}
                        errorLabel={error}
                        value={input.value}
                        {...input}
                        items={items}
    />
}

class AreaForm extends React.Component<{
    handleSubmit: (e) => void,
    submitting: boolean,
    area: Area,
    areaTypes: AreaType[],
    areas: Area[],
    onAreaSubmit: (e) => void
}> {
    constructor(props) {
        super(props);
    }

    render() {
        const {handleSubmit, area, onAreaSubmit} = this.props;

        return <Form onSubmit={handleSubmit(onAreaSubmit)}>
            <div className={"container-fluid"}>
                <div className={"row mt-2"}>
                    <span className={"emp-type-h2 col"}>{area.name || 'Area'}</span>
                </div>

                <div className={"row"}>
                    <div className={"col"}>
                        <div className="emp-type-subtitle1">
                            <div className={"mt-4"}>
                                {this.renderAreaCard()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>;
    }

    renderTextRow(fieldName, label) {
        return (
            <div className="row mb-3">
                <div className={"col-12 col-sm-6"}>
                    <Field name={fieldName} label={label} component={titleField}/>
                </div>
            </div>
        );
    }

    renderSelectFieldRow(fieldName, label, items) {
        return (
            <div className="row mt-2 mb-3">
                <div className={"col-12 col-sm-6"}>
                    <Field name={fieldName} label={label} items={items} component={selectField}/>
                </div>
            </div>
        );
    }

    renderAreaImage() {
        const handleFiles = (e) => {
            let name = e.currentTarget.files.length > 0 ? $.trim(e.currentTarget.files[0].name).substring(0, 30).trim(this) + "..." : '';
            $('.organization_logo').html(name);
            if (e.target.files.length > 0) {
                let url = URL.createObjectURL(e.target.files[0]);
                $('#preview_img').attr('src', url);
            } else {
                $('#preview_img').attr('src', require("../../styles/images/icon/logo.png"));
            }
        };

        const {area} = this.props;
        const imageUrl = area.imageUrl ? area.imageUrl : "https://via.placeholder.com/250?text=Area+PlaceHolder";
        return <>
            <div className={"row mb-3 mb-sm-2"}>
                <div className={"col-12"}>
                    <img id="preview_img" className="placeholder img-thumbnail"
                         src={imageUrl}></img>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-12"}>
                    <NoLabelFileInputField
                        type="file"
                        name="image"
                        placeholder="New York, NY, USA"
                        //label="Choose Logo "
                        HandleChange={(e) => handleFiles(e)}
                        //iconClass="fa fa-info-circle"
                        className="display-none"
                        inputGroupLabel={""}
                        inputGroupLabelClass="custom-file-label emp_cst_file_label organization_logo pointer-none"
                        inputGroupClass="input-group mb-3 cst-upload"
                        htmlFor="inputGroupFile01"
                        id="inputGroupFile01"
                    />
                </div>
            </div>
        </>
    }


    renderAreaCard() {
        const {submitting, area, areaTypes, areas} = this.props;

        const areaTypeItems = areaTypes.map(areaType => ({value: areaType.id, label: areaType.name}))
        const parentItems = areas
            .filter(areaItem => areaItem.id !== area.id)
            .map(area => ({value: area.id, label: area.name}))
        return (
            <>
                <div className="row mb-2">
                    <div className="col-12 col-sm-4">
                        {this.renderAreaImage()}
                    </div>

                    <div className="col">
                        <>
                            {this.renderTextRow("name", "Name")}
                            {this.renderTextRow("description", "Description")}
                            {this.renderSelectFieldRow("areaTypeId", "Area Type", areaTypeItems)}
                            {this.renderSelectFieldRow("parentId", "Parent", parentItems)}
                            <div className={"row"}>
                                <div className={"col-0 col-sm"}></div>
                                <div className={"col-12 col-sm-8"}>
                                    <button type="submit" disabled={submitting} className={"btn btn-primary"}><span
                                        className={"emp-type-btn-primary"}>SAVE</span>
                                    </button>
                                </div>
                            </div>
                        </>
                    </div>
                </div>

            </>
        );
    }
}

export const validate = (values) => {
    const errors = {};
    let requiredFields = ['name', 'description', 'areaTypeId'];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};
export const FORM_NAME = "areaForm";

const wrappedForm = connect()(
    reduxForm({
        form: FORM_NAME,
        enableReinitialize: true,
        validate
    })(AreaForm)
);

export default wrappedForm;