import {assetConstants} from '../constants/asset.constants.js';

const initialState = {
    asset: {},
    assets: [],
    updated: false,
};

export const assetReducer = (state = initialState, action) => {
    switch (action.type) {
        case assetConstants.GET_ASSET_FULFILLED:
            state = {
                ...state,
                asset: action.payload,
                updated: true
            };
            return state;
        case assetConstants.LIST_ASSETS_FULFILLED:
            state = {
                ...state,
                assets: action.payload,
                updated: true
            }
            return state;
        case assetConstants.UPDATE_ASSET_FULFILLED:
        case assetConstants.CREATE_ASSET_FULFILLED:
            state = {
                ...state,
                asset: action.payload,
                updated: true
            };
            return state;

        case assetConstants.DELETE_ASSET_FULFILLED:
            state = {
                ...state,
                assets: state.assets.filter(asset => asset.id !== action.payload.id),
                updated: true
            }
            return state;
        case assetConstants.RESET_ASSET_FULFILLED:
            state = {
                ...state,
                asset: {},
                updated: true
            }
            return state;
        default:
            return state;
    }
};