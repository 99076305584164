export const shipmentInspectionsConstants = {
    QUERY_SHIPMENT_INSPECTIONS_DATA: 'QUERY_SHIPMENT_INSPECTIONS_DATA',
    QUERY_SHIPMENT_INSPECTIONS_DATA_FULFILLED: 'QUERY_SHIPMENT_INSPECTIONS_DATA_FULFILLED',

    CREATE_SHIPMENT_INSPECTION: 'CREATE_SHIPMENT_INSPECTION',
    CREATE_SHIPMENT_INSPECTION_FULFILLED: 'CREATE_SHIPMENT_INSPECTION_FULFILLED',

    DELETE_SHIPMENT_INSPECTION: 'DELETE_SHIPMENT_INSPECTION',
    DELETE_SHIPMENT_INSPECTION_FULFILLED: 'DELETE_SHIPMENT_INSPECTION_FULFILLED',

    GET_SHIPMENT_INSPECTION: 'GET_SHIPMENT_INSPECTION',
    GET_SHIPMENT_INSPECTION_FULFILLED: 'GET_SHIPMENT_INSPECTION_FULFILLED',

    UPDATE_SHIPMENT_INSPECTION: 'UPDATE_SHIPMENT_INSPECTION',
    UPDATE_SHIPMENT_INSPECTION_FULFILLED: 'UPDATE_SHIPMENT_INSPECTION_FULFILLED'
};