import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Form, reduxForm} from 'redux-form';
import {InputField} from '../InputField/InputField';
import {DateTimeField} from '../DateTimeField/DateTimeField';
import {SelectField} from '../SelectField/SelectField';
import {Link, withRouter} from 'react-router';
import LoadingMessage from "../LoadingMessage/LoadingMessage";
import * as AlertActions from '../../actions/alertAction';
import {AlertListView} from "./AlertListView";

export class AlertsPage extends React.Component {
    constructor(props) {
        super(props);
        this.onActiveChange = this.onActiveChange.bind(this)
        this.onDeleteAlert = this.onDeleteAlert.bind(this);
        this.onDeleteAlerts = this.onDeleteAlerts.bind(this);
        this.state = { loading: true }
    }

    async componentDidMount(){
        await this.props.AlertActions.fetchAlertData();
        this.setState({loading: false})
    }

    onActiveChange(alert,checked) {
        alert.active = checked;
        this.props.AlertActions.updateAlert(alert.trigger_id, alert);
    }

    onDeleteAlert(triggerId) {
        this.props.AlertActions.deleteAlert(triggerId);
    }

    async onDeleteAlerts(idArray) {
        this.setState({loading: true});
        await this.props.AlertActions.deleteAlerts(idArray);
        this.setState({loading: false});
    }
    render(){
        const { initialValues,handleSubmit }= this.props;
        if (this.state.loading){
            return <LoadingMessage/>;
        }
        return(
            <main className="app-content">
                <div className="app-title border-bottom">
                    <div>
                        <h1>Alerts</h1>
                    </div>
                    {/*<Form onSubmit={handleSubmit} noValidate={true}>*/}
                        {/*<ul className="app-breadcrumb breadcrumb">*/}
                            {/*<li className="emp-title-item em-title-checkbox app-search">*/}
                                {/*<InputField*/}
                                    {/*type="search"*/}
                                    {/*name="title"*/}
                                    {/*className="app-search__input"*/}
                                    {/*placeholder="Alert Title"*/}
                                    {/*/>*/}
                                {/*<button className="app-search__button"><i className="fa fa-search text-primary"></i></button>*/}

                            {/*</li>*/}

                            {/*<li className="emp-title-item emp_select_itme mr-4">*/}
                                {/*<SelectField*/}
                                    {/*name="alert_type"*/}
                                    {/*className="custom-select cst-select-box input_modifier shadow_select_hover app-title-shadow"*/}
                                    {/*type="select"*/}
                                    {/*placeholder="Type of alert"*/}
                                    {/*parentClass="w-100"*/}
                                    {/*options={['temperature','battery_level_alert','connection_alert']}*/}
                                    {/*/>*/}

                            {/*</li>*/}
                            {/*/!*<li className="emp-title-item date-field">*/}
                                {/*<DateTimeField*/}
                                    {/*type="text"*/}
                                    {/*name="start_date"*/}
                                    {/*placeholder="Start Date"*/}
                                    {/*className="datepicker--input"*/}
                                    {/*dataFormat="DD-MM-YYYY"*/}
                                    {/*/>*/}
                            {/*</li>*/}
                            {/*<li className="emp-title-item">*/}
                                {/*<div className="app-title-right-widget-small">*/}
                                    {/*<div className="info border-info">*/}
                                        {/*<hr className="border-top-info" />*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</li>*/}
                            {/*<li className="emp-title-item date-field">*/}
                                {/*<DateTimeField*/}
                                    {/*type="text"*/}
                                    {/*name="end_date"*/}
                                    {/*placeholder="Start Date"*/}
                                    {/*className="datepicker--input"*/}
                                    {/*dataFormat="DD-MM-YYYY"*/}
                                    {/*parentClass=""*/}
                                    {/*/>*/}
                            {/*</li>*/}
                            {/*<li className="emp-title-item">*/}
                                {/*<div className="app-title-right-widget-small">*/}
                                    {/*<div className="info title-icn-calender bg-transparent">*/}
                                        {/*<img src={require("../../styles/images/icon/icn_calender.png")} alt="Calender Icon" />*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</li>*/}
                            {/*<li className="emp-title-item">*/}
                                {/*<div className="app-title-right-widget-small">*/}
                                    {/*<button type="submit" className="border-0 app-title-btn-live app-title-shadow"> Live</button>*/}
                                {/*</div>*/}
                            {/*</li>*!/*/}
                        {/*</ul>*/}
                    {/*</Form>*/}
                </div>
                <section className="emp_org_settings_sec emp_report_sec">
                    <div className="row">
                        <div className="col">
                            <div className="active-device-btn text-right">
                                <Link to="/create_alert">
                                    <button className="btn btn-primary">Create Alert</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 ">
                           <AlertListView
                              items={this.props.alertReducer.alerts} 
                              onActiveChange={this.onActiveChange} 
                              onDeleteAlert={this.onDeleteAlert}
                              onDeleteAlerts={this.onDeleteAlerts}
                            />
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        alertReducer: state.alertReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    AlertActions: bindActionCreators(AlertActions, dispatch)
});

export default reduxForm({
    form: 'alertSearchForm',
    onSubmit: (values, dispatch) => dispatch(AlertActions.searchAlert(values))
})(connect(mapStateToProps, mapDispatchToProps)(AlertsPage));