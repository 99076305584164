import {datasetTablesConstants} from '../constants/datasetTables.constants';

const initialState = {
    tables: [],
    table: null
};

export const datasetTablesReducer = (state = initialState, action) => {
    switch (action.type) {
        case datasetTablesConstants.LIST_DATASET_TABLES_FULFILLED:
            state = {
                ...state,
                tables: action.payload
            };
            return state;

        case datasetTablesConstants.FETCH_DATASET_TABLE_FULFILLED:
            state = {
                ...state,
                table: action.payload
            }
            return state;
        default:
            return state;
    }
};