import React from 'react'
import {Field} from 'redux-form'


function renderField({input, className, size, normalize, meta: {touched, error}}) {
    let fieldStyle = "";
    let errorMessage = "";
    // any validation errors are stored with the phone field
    if (input.name === 'phone' && touched && error) {
        fieldStyle = "input_error"
        errorMessage = <div className="form-group__error">{error}</div>
    }
    return (
        <React.Fragment>
            <input {...input}
                   size={size} maxLength={size}
                   className={`${className} ${fieldStyle}`} type="text" normalize={normalize}
            />
            {errorMessage}
        </React.Fragment>
    )
}

function PhoneField({className}) {

    function normalizePhone(value, previousValue, allvalues) {
        if (!value) {
            return value
        }
        const onlyNums = value.replace(/[^\d]/g, '')
        if (!previousValue || value.length > previousValue.length) {
            // typing forward
            if (onlyNums.length === 3) {
                return onlyNums + '-'
            }
            if (onlyNums.length === 6) {
                return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-'
            }
        }
        if (onlyNums.length <= 3) {
            return onlyNums
        }
        if (onlyNums.length <= 6) {
            return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3)
        }
        return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 10)
    }

    return (

        <div className={className}>
            <Field name="country" size="5" component={renderField}/>
            <Field name="phone" size="15" component={renderField} normalize={normalizePhone}/>
        </div>
    )
}

export default PhoneField;