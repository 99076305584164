import {EMAIL_REGEXP, FIRST_NAME_REGEXP, LAST_NAME_REGEXP, PASSWORD_MIN_LEN} from '../../config';

export default function validate (values) {
	const errors = {};
	errors['user'] = {};
	let value  = values['user'];
	if (value){
		if (!value['first_name']) {
			errors['user']['first_name'] = 'First name is required';
		} else if (!FIRST_NAME_REGEXP.test(value['first_name'])) {
			errors['user']['first_name'] = 'First name is not valid';
		}

		if (!value['last_name']) {
			errors['user']['last_name'] = 'Last name is required';
		} else if (!LAST_NAME_REGEXP.test(value['last_name'])) {
			errors['user']['last_name'] = 'Last name is not valid';
		}

		if (!value['email']) {
			errors['user']['email'] = 'Email is required';
		} else if (!EMAIL_REGEXP.test(value['email'])) {
			errors['user']['email'] = 'Email address must be valid';
		}

		if (!value['password']) {
			errors['user']['password'] = 'Password is required';
		} else if (value['password'].length < PASSWORD_MIN_LEN) {
			errors['user']['password'] = `Password should be at least ${PASSWORD_MIN_LEN} characters.`;
		}

		if (!value['password_confirmation']) {
			errors['user']['password_confirmation'] = 'Confirmation password is required';
		} else if (value['password_confirmation'].length < PASSWORD_MIN_LEN) {
			errors['user']['password_confirmation'] = `Password confirmation should be at least ${PASSWORD_MIN_LEN} characters.`;
		} else if (value['password_confirmation'] !== value.password) {
			errors['user']['password_confirmation'] = `Password does not match.`;
		}
		if (!value['role']){
			errors['user']['role']= 'Role is required';
		}
	}
	else{
		errors['user'] = {
			first_name: 'First Name is required',
			last_name: 'Last Name is required',
			email: 'Email is required',
			role: 'Role is required',
			password: 'Password is required',
			password_confirmation: 'Confirmation password is required'
		};
	}


	return errors;
};