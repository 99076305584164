import {schemaRegistryConstants} from '../constants/schemaRegistry.constants';
import * as schemaRegistryApi from "../api/schemaRegistryApi";

export const createSchema = (values,successCallback,errorCallback)=> ({
    type: schemaRegistryConstants.CREATE_SCHEMA_REGISTRY,
    payload: schemaRegistryApi.create(values,successCallback,errorCallback)
});

export const loadSchemas = (values) => ({
  type: schemaRegistryConstants.FETCH_SCHEMA_REGISTRY,
  payload: schemaRegistryApi.list(values)
})