import {wifiConstants} from '../constants/wifi.constants';
import * as wifiApi from "../api/wifiApi";

export const fetchWifiData = ()=> ({
    type: wifiConstants.FETCH_WIFI_DATA,
    payload: wifiApi.fetchWifiData()
});

export const createWifiNetwork = (values)=> ({
    type: wifiConstants.UPDATE_WIFI,
    payload: wifiApi.createWifiNetwork(values)
        .then(response => {
            return response
        })
});

export const deleteWifiNetwork = (wifiId)=> ({
    type: wifiConstants.DELETE_WIFI,
    payload: wifiApi.deleteWifi(wifiId)
        .then(response => {
            return response
        })
});