import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {reset} from 'redux-form';
import {Link} from 'react-router';
import LoadingMessage from "../LoadingMessage/LoadingMessage";
import * as WorkflowActions from "../../actions/workflowActions";
import * as WorkflowScheduleActions from "../../actions/workflowScheduleActions";
import WorkflowScheduleListItem from "./WorkflowScheduleListItem";
import {confirmOrCancelDialogue} from "../../utils/dialogueFunctions";
import {TextField} from "../../ui/Input/TextField";


export class WorkflowPage extends React.Component<{
    workflowActions,
    listWorkflowSchedules: Function,
    listWorkflows: Function,
    workflowScheduleActions,
    workflowScheduleReducer,
    workflowReducer,
    selectedWorkflow: object,
    selectWorkflow: Function,
    deleteWorkflowSchedule: Function,
},{
    workflowIdMap: object,
    loading: boolean,
    searchTerm: string,
}> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            workflowIdMap: {},
            searchTerm: '',
        }
    }

    async componentDidMount() {
        await Promise.all([this.props.workflowActions.listWorkflows(), this.props.workflowScheduleActions.listWorkflowSchedules()]);
        this.props.workflowActions.selectWorkflow({});

        const workflows = this.props.workflowReducer.workflows;
        const workflowIdMap = {};
        workflows.forEach((workflow) => {
            workflowIdMap[workflow.id] = workflow.workflow_type;
        });

        this.setState({...this.state, loading: false, workflowIdMap: workflowIdMap});
    }

    async handleDeleteItem(id) {
        const workflowSchedule = this.props.workflowScheduleReducer.workflowSchedules.find((schedule) => schedule.id === id);
        if(!workflowSchedule) {
            console.log(`Workflow schedule with id ${id} not found`)
            return;
        }
        let confirm = await confirmOrCancelDialogue(`Delete Workflow "${workflowSchedule.title}"?`);
        if (!confirm) return;

        this.setState({...this.state, loading: true, workflowIdMap: this.state.workflowIdMap});
        await this.props.workflowScheduleActions.deleteWorkflowSchedule(id);
        this.setState({...this.state, loading: false, workflowIdMap: this.state.workflowIdMap});
    }

    renderScheduleItems() {
        const workflowSchedules = this.props.workflowScheduleReducer.workflowSchedules;
        workflowSchedules.sort((a, b) => {
            if(a.title.toLowerCase() < b.title.toLowerCase()) { return -1; }
            if(a.title.toLowerCase() > b.title.toLowerCase()) { return 1; }
            return 0;
        });

        const filteredSchedules = workflowSchedules.filter(schedule =>
            schedule.title.toLowerCase().includes(this.state.searchTerm.toLowerCase())
        );

        return filteredSchedules.map((schedule, idx) => {
            const workflowType = this.state.workflowIdMap[schedule.workflow_id];
            return <WorkflowScheduleListItem
                key={idx}
                title={schedule.title}
                type={workflowType}
                startDate={schedule.start_date}
                enabled={schedule.enabled}
                intervalType={schedule.schedule_interval?.interval_type}
                interval={schedule.schedule_interval?.interval}
                scheduleId={schedule.id}
                onDelete={this.handleDeleteItem.bind(this)}
            />
        });
    }

    render() {
        if (this.state.loading) {
            return <LoadingMessage/>;
        }
        return (
            <main className="app-content">
                <div className="app-title border-bottom">
                    <div>
                        <h1>Workflows</h1>
                    </div>
                </div>
                <section className="emp_org_settings_sec emp_report_sec">
                    <div className="row">
                        <div className="col">
                            <div className="active-device-btn text-right pb-2">
                                <Link to="/workflows/create_workflow_schedule">
                                    <button className="btn btn-primary">
                                        <i className="fa fa-plus pr-1"></i><span>Create Workflow</span></button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 ">
                            <div className="emp-device-card">
                                <TextField
                                    label={"Search..."}
                                    value={this.state.searchTerm}
                                    onChange={(e)=>this.setState({...this.state, searchTerm: e.target.value})}/>
                            </div>
                        </div>
                    </div>
                    {this.state.loading ? <LoadingMessage/> : (
                        <div className="row">
                            <div className="col-xl-12 ">
                                {this.renderScheduleItems()}
                            </div>
                        </div>
                    )}
                </section>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        workflowScheduleReducer: state.workflowScheduleReducer,
        workflowReducer: state.workflowReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    workflowActions: bindActionCreators(WorkflowActions, dispatch),
    workflowScheduleActions: bindActionCreators(WorkflowScheduleActions, dispatch),
    reset: reset
});

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowPage);
