import {workflowConstants} from '../constants/workflow.constants';
import * as workflowApi from "../api/workflowApi";


export const listWorkflows = () => (dispatch) => {
    return dispatch({
        type: workflowConstants.LIST_WORKFLOWS,
        payload: workflowApi.listWorkflows()
    });
};

export const getWorkflow = (workflowId) => (dispatch) => {
    return dispatch({
        type: workflowConstants.GET_WORKFLOW,
        payload: workflowApi.getWorkflow(workflowId)
    });
};

export const selectWorkflow = (selectedWorkflow) => ({
    type: workflowConstants.SELECT_WORKFLOW,
    payload: selectedWorkflow
});
