import {scheduledJobConstants} from '../constants/scheduledJob.constants';

const initialState = {
    scheduledJobs: [],
    selectedScheduledJob: {},
    cursor: null
};

export const scheduledJobReducer = (state = initialState, action) => {
    switch (action.type) {
        case scheduledJobConstants.CLEAR_SCHEDULED_JOBS:
            state = {
                ...state,
                scheduledJobs: [],
                cursor: null
            }
            return state;
        case scheduledJobConstants.LIST_SCHEDULED_JOBS:
        case scheduledJobConstants.LIST_SCHEDULED_JOBS_FULFILLED:
            const sortedScheduledJobs = action.payload.items.sort((a, b) => {
                if (new Date(a.updated_at) === new Date(b.updated_at)) return 0;
                else if (new Date(a.updated_at) > new Date(b.updated_at)) return -1;
                else return 1;
            });
            state = {
                ...state,
                scheduledJobs: [...state.scheduledJobs, ...sortedScheduledJobs],
                cursor: action.payload.cursor
            };
            return state;
        case scheduledJobConstants.GET_SCHEDULED_JOB:
        case scheduledJobConstants.GET_SCHEDULED_JOB_FULFILLED:
            state = {
                ...state,
                selectedScheduledJob: action.payload
            }
        case scheduledJobConstants.SELECT_SCHEDULED_JOB:
            state = {
                ...state,
                selectedScheduledJob: action.payload,
            };
            return state;
        default:
            return state;
    }
};