
export default class SensorGatewayConnection {
    sensorId;
    gatewayId;
    gatewayName;
    connectionStartTime;
    constructor(sensorId, gatewayId, gatewayName, connectionStartTime) {
        this.sensorId = sensorId;
        this.gatewayId = gatewayId;
        this.gatewayName = gatewayName;
        this.connectionStartTime = connectionStartTime;
    }

    static fromJson(data) {
        return new SensorGatewayConnection(
            data["sensor_id"],
            data['gateway_id'],
            data['gateway_name'],
            data['connection_start_time']
        )
    }
}