import React from "react";

export default class IncidentServiceAuditFormatter {

    formatLogEvent(auditLog) {
        switch (auditLog.access_type) {
            case "create":
                let title = auditLog.record ? auditLog.record.id : "";
                return `resolved incident '${title}'`
            default:
                console.error("Received unknown access type: " + auditLog.access_type)
        }
    }
}