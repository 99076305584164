import DeviceType from "./DeviceType";

export class Device {
    id;
    name;
    macAddress;
    serialNumber;
    deviceType;
    status;
    organizationId;
    createdAt;
    updatedAt;
    productId;
    gatewayId;
    note;
    thingIdentity;
    temperatureOffset;
    identity;
    hardwareVersion;
    imageUrl;

    constructor(id, name, macAddress, serialNumber, deviceType, status, organizationId, createdAt, updatedAt, productId, gatewayId, note, thingIdentity, temperatureOffset, identity, hardwareVersion, imageUrl) {
        this.id = id;
        this.name = name;
        this.macAddress = macAddress;
        this.serialNumber = serialNumber;
        this.deviceType = deviceType;
        this.status = status;
        this.organizationId = organizationId;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.productId = productId;
        this.gatewayId = gatewayId;
        this.note = note;
        this.thingIdentity = thingIdentity;
        this.temperatureOffset = temperatureOffset;
        this.identity = identity;
        this.hardwareVersion = hardwareVersion;
        this.imageUrl = imageUrl;
    }
}

export interface ApiDevice {
    id: number;
    name: string;
    mac_address: string;
    serial_number: string;
    legacy_device_type: number;
    status: string;
    organization_id: number;
    created_at: string;
    updated_at: string;
    product_id: string;
    gateway_id: number;
    note: string | null;
    thing_identity: string | null;
    temperature_offset: number;
    identity: string;
    hardware_version: string;
    description: string;
    image_url: {
        url: string | null;
    };
    icon_url: {
        url: string | null;
    };
    probe_enabled: boolean;
    device_type_id: string;
    device_type?: DeviceType
}
