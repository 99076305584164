import {alertConstants} from '../constants/alert.constants.js';

const emptyAlert={ 
    'field': '',
    'title': '',
    'schema': '',
    'level': 'warning',
    'conditions': [],
    'alertTitle': '',
    'trigger_minutes': '',
    'client_id': '', 
    'description': '',
    'period': 300,
    'devices': [],
    'groups': [],
    'conditionUnits': ''};

const initialState = {
    alerts: [],
    alert: emptyAlert
};

export const alertReducer = (state = initialState, action) => {
    switch (action.type) {
        case alertConstants.FETCH_ALERT_DATA_FULFILLED:
        case alertConstants.DELETE_ALERT_FULFILLED:
            state = {
                ...state,
                alerts: action.payload
            };
            return state;
        case alertConstants.DELETE_ALERTS_FULFILLED:
            let deletedAlerts = action.payload.deleted_ids;
            let newAlerts = state.alerts.filter( 
               alert => !deletedAlerts.find( deletedId => deletedId === alert.trigger_id
            ))
            state = {
              ...state,
              alerts: newAlerts
            }
            return state;
        case alertConstants.SEARCH_ALERT_FULFILLED:
            state = {
                ...state,
                alerts: action.payload.alerts
            };
            return state;
        case alertConstants.RESET_ALERT_FOR_EDIT:
            state = {
                ...state,
                alert: emptyAlert
            };
            return state; 
        case alertConstants.INITIALIZE_ALERT_FOR_EDIT:
            state = {
                ...state,
                alert: action.payload
            };
            return state;   
        case alertConstants.GET_ALERT_FULFILLED:
            let alert = action.payload;
            state = {
                ...state,
                alert: (alert) ? {...emptyAlert, ...alert} : emptyAlert  // should never be empty!
            };
            return state;
        case alertConstants.UPDATE_ALERT_FULFILLED:
            // if the updated alert is in the state's alert list, reflect the new information
            if (action.payload) {
                let update = action.payload
                let index = state.alerts.findIndex ( (a) => a.trigger_id === update.trigger_id )
                if (index >= 0) {
                    let tempAlerts = [...state.alerts]
                    tempAlerts[index] = update;
                    state = {
                        ...state,
                        alerts: tempAlerts
                    }
                }
            }
            return state;
        default:
            return state;
    }
};