import React, { useEffect, useState } from 'react'
import FieldTypes from './FormFields'
import { SORT_BY_FIELD_INDEX, mapToFieldInstances } from '../../utils/formUtils'
import { createFieldInstance } from '../../api/fieldInstanceApi'
import { FieldInstanceProps } from '../../formSchema'

const AdjustmentRow = ({ instance, childInstances, handleFieldUpdate }) => {
  const { field } = instance
  const childFields = field.children.sort(SORT_BY_FIELD_INDEX)
  const chemistFields = childFields.slice(0, -2)
  const operatorFields = childFields.slice(-2)

  const setClasses = (index, childField) => {
    let classes = ''
    if (index === 0) classes += ' col-sm-2 px-0'
    if (childField.field_name === 'Instructions') {
      classes += ' col-sm-6 px-2'
    } else {
      classes += ' col-sm-2 px-2'
    }
    if (index === field.children.length - 1) classes += ' col-sm-2 px-0'
    if (childField.field_type !== 'signature') classes += ' col-sm-2'

    return classes
  }

  return (
    <div className="row my-n4">
      <div className="col-8 py-2">
        <div className="d-flex align-items-center">
          {chemistFields.map((childField, index) => (
            <FieldTypes
              key={childField.id}
              hideLabel
              classes={setClasses(index, childField)}
              instance={childInstances.find(
                (child) => child.field_id === childField.id
              )}
              onChange={handleFieldUpdate}
            />
          ))}
        </div>
      </div>
      <div className="col-4">
        <div className="d-flex align-items-center py-2">
          {operatorFields.map((childField) => (
            <FieldTypes
              key={childField.id}
              hideLabel
              classes="col-sm-6 px-2"
              instance={childInstances.find(
                (child) => child.field_id === childField.id
              )}
              onChange={handleFieldUpdate}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const AdjustmentHeader = () => (
  <>
    <div className="row align-items-center mt-4">
      <div className="col-8 px-0">
        <div className="header" data-type="chemist">
          Completed by Chemist
        </div>
        {/* Chemist fields here */}
      </div>
      <div className="col-4 px-0">
        <div className="header" data-type="operator">
          Completed by Operator
        </div>
        {/* Operator fields here */}
      </div>
    </div>

    <div className="row align-items-center mb-2 py-2 border-bottom header-row">
      <div className="col-8">
        <div className="d-flex align-items-center">
          {/* <div className="col-2">Time</div> */}
          <div className="col-2">Chemist Initial</div>
          <div className="col-2">Test ID</div>
          <div className="col-2">Original Results</div>
          <div className="col-6">Instructions</div>
        </div>
      </div>
      <div className="col-4">
        <div className="d-flex  align-items-center">
          <div className="col-6">Adjusted Result</div>
          <div className="col-6">Operator Initials</div>
          {/* <div className="col-4">Time</div > */}
        </div>
      </div>
    </div>
  </>
)

export const BatchAdjustmentForm = ({
  batchAdjustmentFieldInstances: fieldInstances,
  formTemplate,
  onSave,
  handleFieldUpdate,
  addAdjustmentInstances
}) => {
  const [adjBtnDisabled, setAdjBtnDisabled] = useState(false)
  const [error, setError] = useState('')
  const [batchAdjs, setBatchAdjs] = useState(
    fieldInstances.filter((instance) =>
      instance.field.field_name.includes('batch_adjustment')
    )
  )
  // const [fieldInstances, setFieldInstances] = useState(batchAdjustmentFieldInstances)

  const startDate = fieldInstances.find(
    (instance) =>
      instance.field.field_name === 'Date' && instance.field.field_index === 1
  )
  const signtureField = fieldInstances.find(
    (instance) =>
      instance.field.field_name === 'Product Approved By' &&
      instance.field.field_index === 3
  )

  const handleAddAdjustment = async () => {
    setAdjBtnDisabled(true)
    // Check last adjustment for completion

    // Need to confirm Chemist section is complete,
    // const chemistFields = batchAdjs[batchAdjs.length - 1].field.children.slice(
    //   0,
    //   -2
    // )
    // Map chemist fields to instances
    // const childInstances = mapToFieldInstances(fieldInstances, chemistFields)

    // Validation
    // if (!childInstances.every((child) => child.value)) {
    //   setError('Please complete the last adjustment')
    //   return
    // }

    setError('')

    // TODO: Add field instance
    const res = await createFieldInstance({ id: batchAdjs[0].id })

    // // Update field instance id
    // // Use first adjustment as template
    // const fieldInstances = batchAdjs[0]
    // Map child instances to field instances
    // Adding field to instance value
    const newAdjustment = res.data.child_instances.map((childInstance) => {
      const field = batchAdjs[0].field.children.find(
        (field) => field.id === childInstance.field_id
      )
      return { ...childInstance, field: field }
    })

    addAdjustmentInstances(newAdjustment)
    // setFieldInstances((prev) => [...prev, ...newAdjustment])
    delete res.data.child_instances

    setBatchAdjs((prev) => [...prev, res.data])
    setAdjBtnDisabled(false)
  }

  return (
    <div className="app-content batch-adjustment">
      <div className="border-bottom pb-3 mb-2">
        <p className="sop-section-title">Batch Adjustment Worksheet</p>
      </div>

      <div>
        <p>
          <b>Product Name</b>: {formTemplate.product_name}
        </p>
        <FieldTypes
          classes="col-sm-3"
          instance={startDate}
          onChange={handleFieldUpdate}
        />

        <AdjustmentHeader />

        {batchAdjs.map((instance, index) => (
          <AdjustmentRow
            key={index}
            instance={instance}
            childInstances={fieldInstances
              .filter(
                (childInstance) => childInstance.parent_id === instance.id
              )
              .sort((a, b) => SORT_BY_FIELD_INDEX(a.field, b.field))}
            handleFieldUpdate={handleFieldUpdate}
          />
        ))}

        <div className="d-flex align-items-center my-4">
          <FieldTypes
            classes={`col-4 px-2`}
            instance={signtureField}
            onChange={handleFieldUpdate}
          />
          <div className="col-4 text-right">
            <p className="mb-2 text-danger">{error}&nbsp;</p>
            <button
              disabled={adjBtnDisabled}
              onClick={() => handleAddAdjustment()}
              className="btn btn-outline-primary"
            >
              <i className="mr-2 fa-solid fa-plus"></i>
              Add Adjustment
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
