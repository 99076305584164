import {alertReducer} from "./alertReducer";
import {apiKeysReducer} from "./apiKeysReducer";
import {areaReducer} from "./areaReducer";
import {areaTypeReducer} from "./areaTypeReducer";
import {assetReducer} from "./assetReducer";
import {assetTypeReducer} from "./assetTypeReducer";
import {auditLogReducer} from "./auditLogReducer";
import {authReducer} from "./authReducer";
import {combineReducers} from 'redux';
import {customDashboardReducer} from "./customDashboardReducer.ts";
import {datasetTablesReducer} from "./datasetTablesReducer";
import {deviceAssignmentReducer} from "./deviceAssignmentReducer";
import {deviceReducer} from "./deviceReducer";
import {environmentReducer} from "./environmentReducer";
import {failureModeReducer} from "./failureModeReducer";
import {filterFormReducer} from "./filterFormReducer";
import {gatewayReducer} from "./gatewayReducer";
import {generalSettingReducer} from "./generalSettingReducer";
import {groupReducer} from "./groupReducer";
import {incidentReducer} from "./incidentReducer";
import {incidentReportReducer} from "./incidentReportReducer";
import {locationTelemetryReducer} from "./locationTelemetryReducer";
import {metricReducer} from './metricReducer';
import {networkConfigurationReducer} from "./networkConfigurationReducer";
import {probesReducer} from './probesReducer';
import {reducer as formReducer} from 'redux-form'
import {reportReducer} from "./reportReducer";
import {scheduledJobReducer} from './scheduledJobReducer';
import {scheduledReportReducer} from "./scheduledReportReducer";
import {schemaRegistryReducer} from "./schemaRegistryReducer";
import {sensorReducer} from "./sensorReducer";
import {shipmentContactsReducer} from './shipmentContactsReducer';
import {shipmentInspectionsReducer} from './shipmentInspectionsReducer';
import {shipmentsReducer} from './shipmentsReducer';
import {userReducer} from "./userReducer";
import {wifiReducer} from "./wifiReducer";
import {workActivityReducer} from "./workActivityReducer";
import {workEventReducer} from "./workEventReducer";
import {workflowReducer} from './workflowReducer';
import {workflowScheduleReducer} from './workflowScheduleReducer';
import {datasetDbReducer} from "./datasetDbReducer";
import {deviceTypesReducer} from "./deviceTypesReducer";
import {deviceTypeMetricsReducer} from "./deviceTypeMetricsReducer";
import { formTemplateReducer } from "./formTemplateReducer";
import { formInstanceReducer } from "./formInstanceReducer";
import { formCommentReducer } from "./formCommentReducer";

const reducers = combineReducers({
    alertReducer,
    apiKeysReducer,
    areaReducer,
    areaTypeReducer,
    assetReducer,
    assetTypeReducer,
    auditLogReducer,
    authReducer,
    customDashboardReducer,
    datasetTablesReducer,
    deviceAssignmentReducer,
    deviceReducer,
    deviceTypesReducer,
    deviceTypeMetricsReducer,
    environmentReducer,
    failureModeReducer,
    filterFormReducer,
    form: formReducer,
    gatewayReducer,
    generalSettingReducer,
    groupReducer,
    incidentReducer,
    incidentReportReducer,
    locationTelemetryReducer,
    metricReducer,
    networkConfigurationReducer,
    probesReducer,
    reportReducer,
    scheduledJobReducer,
    scheduledReportReducer,
    schemaRegistryReducer,
    sensorReducer,
    shipmentContactsReducer,
    shipmentInspectionsReducer,
    shipmentsReducer,
    userReducer,
    wifiReducer,
    workActivityReducer,
    workEventReducer,
    workflowReducer,
    workflowScheduleReducer,
    datasetDbReducer,
    formTemplateReducer,
    formCommentReducer,
    formInstanceReducer
});

export default reducers;