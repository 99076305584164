import React from 'react';
import {Link} from 'react-router';
import moment from "moment/moment";
import {ScheduledJobModel} from "../../api/model/ScheduledJob";
import {getTagColorByType} from "./ScheduledJobTag";
import WorkflowTypeTag from "./WorkflowTypeTag";


export class ScheduledJobListItem extends React.Component<{
    scheduledJob: ScheduledJobModel,
    title: string,
    workflowType: string,
},{
}> {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const scheduledJob = this.props.scheduledJob;
        const dateFormat = "MM/DD/YY hh:mm A";
        return (
            <Link to={`/scheduled_jobs/${scheduledJob.id}`}>
                <div className="card shadow emp-device-card p-3 scheduled-job-list-item emp-type-body1">
                    <div className={"row"}>
                        <div className={"col-9"}>
                            <div>{this.props.title}</div>
                        </div>
                        <div className={"col-3 text-right"}>
                            <WorkflowTypeTag type={this.props.workflowType}/>
                        </div>
                    </div>
                    <div className={"row pt-3 emp-type-caption"}>
                        <div className={"col-9"}>
                            <div><span className="color-form-label">Job Run: </span>{moment(scheduledJob.created_at).format(dateFormat)}</div>
                        </div>
                        <div className={"col-3 text-right"}>
                            <div className={`${getTagColorByType(scheduledJob.status)} background-none`}>
                                {scheduledJob.status}
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        )
    }
}