import React from "react";
import PropTypes from "prop-types";

class Tab extends React.Component {
    onClick = () => {
        const { label, onClick } = this.props;
        onClick(label);
    }

    render() {
        const {
            onClick,
            props: {
                activeTab,
                label,
                className
            },
        } = this;

        let classNames = 'tab-list-item';

        if (activeTab === label) {
            classNames += ' tab-list-active';
        }
        if (className) {
            classNames = `${classNames} ${className}`
        }

        return (
            <li
                className={classNames}
                onClick={onClick}
            >
                {label}
            </li>
        );
    }
}

Tab.propTypes = {
    activeTab: PropTypes.string.isRequired,
    className: PropTypes.string,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};

export default Tab;