import {validateDateString} from "./utils";
import {EMAIL_REGEXP} from "../config";

export interface ValidationFunction {
    (value: any, dateFormat?: string): string | undefined;
}

export const validateDateTimeFormat: ValidationFunction = (e) => {
    if (!e || validateDateString(e)) return undefined;
    return "Invalid date format";
}

export const validateDateFormat: ValidationFunction = (e, dateFormat) => {
    if (!e || validateDateString(e, dateFormat || "YYYY-MM-DD")) return undefined;
    return "Invalid date format";
}

export const required: ValidationFunction = (value: any) => (value && value.length ? undefined : 'Required');

export const isEmailValid: ValidationFunction = (value: any) => (value && EMAIL_REGEXP.test(value) ? undefined : 'Invalid email address');

export const allElementsAreFalse: ValidationFunction = (value: any) => {
    const areAllElementsFalse = value.every(obj => !Object.values(obj)[0]);
    return areAllElementsFalse ? "Required" : undefined;
};