import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import {Header} from '../../components/Header/Header';

export  const LoaderContainer = ({ authActions,auth }) => {
    return (
        <div className="auth-sections">
            <Header
                isLoggedIn={auth.isLoggedIn}
                user={auth.user}
                logout={authActions.logout} />
            <div className="loader_container">
                {/*<img src={require('../../styles/images/icon/loader_body.svg')}/>*/}
            </div>
        </div>
    )
};
const mapStateToProps = (state) => ({
    auth: state.authReducer
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoaderContainer);