export class Column {
    name: string;
    data_type: string;
    comment?: string;
    nullable: boolean;

    constructor(name: string, data_type: string, nullable: boolean, comment?: string) {
        this.name = name;
        this.data_type = data_type;
        this.nullable = nullable;
        if (comment) {
            this.comment = comment;
        }
    }

    static from_json(json: any): Column {
        return new Column(json.name, json.data_type, json.nullable, json.comment);
    }
}

export class Table {
    database_name: string;
    table_name: string;
    location?: string;
    columns: Column[];
    partition_keys: Column[];
    properties: { [key: string]: string };

    constructor(
        database_name: string,
        table_name: string,
        columns: Column[],
        partition_keys: Column[],
        properties: { [key: string]: string },
        location?: string
    ) {
        this.database_name = database_name;
        this.table_name = table_name;
        this.columns = columns;
        this.partition_keys = partition_keys;
        this.properties = properties;
        if (location) {
            this.location = location;
        }
    }

    static from_json(json: any): Table {
        const columns = json.columns.map((column: any) => Column.from_json(column));
        const partition_keys = json.partition_keys.map((column: any) => Column.from_json(column));

        return new Table(json.database_name, json.table_name, columns, partition_keys, json.properties, json.location);
    }
}
