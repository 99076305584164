export class WorkflowModel {
    id :string;
    title :string;
    description :string;
    owner_id :string;
    workflow_params :WorkflowParamsInterface;
    workflow_type :string;
    updated_at :string;
    created_at :string;
    version :string;
    ui_schema :UiSchemaInterface;

    constructor(id, title, description, owner_id, workflow_params, workflow_type, updated_at, created_at, version, ui_schema) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.owner_id = owner_id;
        this.workflow_params = workflow_params;
        this.workflow_type = workflow_type;
        this.updated_at = updated_at;
        this.created_at = created_at;
        this.version = version;
        this.ui_schema = ui_schema;
    }

    static fromJson(json) {
        return new WorkflowModel(json['id'],
            json['title'],
            json['description'],
            json['owner_id'],
            json['workflow_params'],
            json['workflow_type'],
            json['updated_at'],
            json['created_at'],
            json['version'],
            json['ui_schema'],
        )
    }
}

export interface WorkflowParamsInterface {
    properties: {
        [key: string]: {
            [key: string]: any;
        };
    };
    definitions: {
        [key: string]: {
            [key: string]: any;
        };
    };
    dependencies: {
        [key: string]: {
            [key: string]: any;
        };
    };
    required: string[];
}

export interface UiSchemaInterface {
    properties: {
        [key: string]: {
            "ui:widget": string,
            [key: string]: any;
        };
    };
    'ui:order': string[];
}
