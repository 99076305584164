import {metricConstants} from '../constants/metric.constants';

const initialState = {
    metrics: {
        items: []
    },
};

export const metricReducer = (state = initialState, action) => {
    switch (action.type) {
        case metricConstants.LIST_METRICS:
        case metricConstants.LIST_METRICS_FULFILLED:
            state = {
                ...state,
                metrics: action.payload
            };
            return state;
        case metricConstants.GET_METRIC_FULFILLED:
            state = {
                ...state,
                metric: action.payload
            }
            return state;
        default:
            return state;
    }
};