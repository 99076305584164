import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {browserHistory} from 'react-router';

import * as AuthActions from '../../actions/authActions';
import * as AlertActions from '../../actions/alertAction';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import AlertForm from '../../components/AlertForm/AlertForm';
import { withRouter } from 'react-router';
import * as GeneralSettingsActions from "../../actions/generalSettingAction";
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";

export class EditAlertPageContainer extends React.Component {

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
        this.state = {
            loading: false
        }
    }

    async componentDidMount() {
        await this.props.generalSettingsActions.fetchOrganizationData();
        this.props.AlertActions.getAlert(this.props.params.id, this.props.generalSettingsReducer.organization.system_of_units);
    }

    async onSubmit(values,dispatch) {
        this.setState({...this.state, loading: true});
        await dispatch(AlertActions.updateAlert(this.props.params.id,values, this.props.generalSettingsReducer.organization.system_of_units));
        this.setState({...this.state, loading: false});
        browserHistory.push('/alerts');
    }

    render() {

        const {authActions, auth } = this.props;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                    />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                { this.state.loading ? <LoadingMessage/> :
                <AlertForm onAlertSubmit={this.onSubmit} 
                           alert_properties={this.props.alert} 
                           submitButtonLabel="Update Alert" /> }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.authReducer,
        alert: state.alertReducer.alert,
        generalSettingsReducer: state.generalSettingReducer
    }
};

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    AlertActions: bindActionCreators(AlertActions, dispatch),
    generalSettingsActions: bindActionCreators(GeneralSettingsActions, dispatch)
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EditAlertPageContainer));