import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {reset} from 'redux-form';
import {Link} from 'react-router';
import * as WorkflowActions from "../../actions/workflowActions";
import * as WorkflowScheduleActions from "../../actions/workflowScheduleActions";
import * as Moment from "moment";


export class ScheduledJobDetailPage extends React.Component<{
    scheduledJobReducer,
    workflowScheduleReducer,
},{}> {
    constructor(props) {
        super(props);
        this.state = {}
    }

    renderHeaderBar() {
        return (<div className="row">
            <div className="col-sm-12">
                <div className="app-title border-bottom">
                    <div>
                        <div className="media align-items-center">
                            <Link
                                to={`/workflow_schedules/${this.props.scheduledJobReducer.selectedScheduledJob.schedule_id}`}>
                    <span className="mr-3 emp_icn_middle">
                    <img
                        src={require("../../styles/images/icon/ic_left-arrow.png")}
                        alt="Icon"
                    />
                    </span>
                            </Link>
                            <div className="media-body">
                                <h1 className="mt-0 text-capitalize">
                                    Scheduled Jobs
                                </h1>
                                <span className="sub_txt_title">
                {" "}
                                    All Jobs for this schedule {" "}
                    </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }

    render() {
        const {selectedWorkflowSchedule} = this.props.workflowScheduleReducer;
        const {selectedScheduledJob} = this.props.scheduledJobReducer;

        return (
            <main className="app-content">
                <section className="emp_org_settings_sec emp_report_sec">
                    {this.renderHeaderBar()}
                    <div className="card emp-box emp-shadow element_emp_equal incident-detail-card">
                        <div className={"container mt-4 mb-4"}>
                            <div className="row">
                                <div className="col">
                                    <h3>Job Details</h3>
                                </div>
                            </div>
                            <ul className="list-group row">
                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                    <h6 className="mb-0">Title</h6>
                                    <span>{selectedWorkflowSchedule.title}</span>
                                </li>
                                <li className={"list-group-item d-flex justify-content-between align-items-center flex-wrap"}>
                                    <h6 className="mb-0">Status</h6>
                                    <span>{selectedScheduledJob.status}</span>
                                </li>
                                <li className={"list-group-item d-flex justify-content-between align-items-center flex-wrap"}>
                                    <h6 className="mb-0">Created at</h6>
                                    <span>
                    {Moment.utc(selectedScheduledJob.created_at, "YYYY-MM-DD HH:mm A").format("MM-DD-YYYY hh:mm A")}
                  </span>
                                </li>
                                <li className={"list-group-item d-flex justify-content-between align-items-center flex-wrap"}>
                                    <h6 className="mb-0">
                                        Requested by
                                    </h6>
                                    <span>
                    {`${selectedScheduledJob.owner.first_name} ${selectedScheduledJob.owner.last_name}`}
                  </span>
                                </li>
                            </ul>
                        </div>
                        <div className="container mb-4">
                            <div className="row">
                                <div className="col">
                                    <h3>Outputs</h3>
                                </div>
                            </div>
                            {this.renderArtifacts(selectedScheduledJob.artifacts)}
                        </div>

                    </div>
                </section>
            </main>
        );
    }

    renderArtifacts(artifacts) {
        if (!artifacts || !artifacts.length) {
            return <ul className="list-group row">
                <li className="list-group-item">No items found</li>
            </ul>
        }
        const items = artifacts.map(artifact => {
            return <li className="list-group-item list-group-item-action col-xs-12">
                <a href={artifact.location} target="_blank">{artifact.display_name}</a>
            </li>
        })

        return <>
            <ul className="list-group row">
                {items}
            </ul>
        </>
    }
}

const mapStateToProps = (state) => {
    return ({
        workflowScheduleReducer: state.workflowScheduleReducer,
        scheduledJobReducer: state.scheduledJobReducer,
        auth: state.authReducer,
    });
};

const mapDispatchToProps = (dispatch) => (
    {
        workflowActions: bindActionCreators(WorkflowActions, dispatch),
        workflowScheduleActions: bindActionCreators(WorkflowScheduleActions, dispatch),
        reset: reset
    }
);

export default connect(mapStateToProps, mapDispatchToProps)(ScheduledJobDetailPage);
