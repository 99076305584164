import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as IncidentActions from '../../actions/incidentActions';
import * as EnvironmentActions from '../../actions/environmentAction';
import * as GeneralSettingsActions from '../../actions/generalSettingAction';
import * as MetricActions from '../../actions/metricAction';
import * as Moment from 'moment';
import MetricGraph, {MetricData, MetricPlotBand, MetricPlotLine} from "../Visualization/MetricGraph";

export class IncidentDetailPage extends React.Component {

    emptyIncident = {
        title: '', metric: '', description: '', status: '',
        threshold: '', thresholds: [], trigger_data_points: '', clear_data_points: '',
        detail: {state: {stateReasonData: {timestamp: '', statistic: '', period: '', recent_datapoints: []}}}
    }

    constructor(props) {
        super(props);
        this.state = {
            incidentLoading: true,
            deviceDataLoading: true
        }
    }

    async componentWillMount() {
        let query_params = {
            incident_id: this.props.incidentId
        };
        await this.props.generalSettingsActions.fetchOrganizationData();
        this.props.incidentActions.fetchIncidentById(query_params, this.props.generalSettingsReducer.organization.system_of_units);
    }


    componentDidUpdate(prevProps) {
        if (this.props.incident !== prevProps.incident) {
            this.fetchMetricData(this.props.incident, this.props.generalSettingsReducer.organization.system_of_units);
            this.setState((state) => {
                return {
                    ...state,
                    incidentLoading: false
                }
            })
        }

        if (this.props.environmentReducer.detailDeviceData !== prevProps.environmentReducer.detailDeviceData) {
            this.setState((state) => {
                return {
                    ...state,
                    deviceDataLoading: false
                }
            })
        }

        if (this.props.metricReducer.metric !== prevProps.metricReducer.metric) {
            this.setState((state) => {
                return {
                    ...state,
                    deviceDataLoading: false
                }
            })
        }
    }


    fetchMetricData(incident, unitFormat) {
        let format = 'MM-DD-YYYY hh:mm A';
        if (incident.trigger.schema === 'monitor') {
            this.props.environmentActions.getDeviceAndTelemetry({
                mac_address: incident.metric,
                device_data: {
                    start_date: Moment(new Date(new Date(incident.timestamp + 'Z').valueOf() - 1000 * 60 * 60).toISOString()).format(format),
                    end_date: Moment(new Date(new Date(incident.timestamp + 'Z').valueOf() + 1000 * 60 * 60).toISOString()).format(format)
                },
                max_items: 50000,
                format: unitFormat
            });
        } else if (incident.trigger.schema === "metric") {
            const startTime = new Date(new Date(incident.timestamp + 'Z').valueOf() - 1000 * incident.trigger.period * 5 * incident.trigger.trigger_data_points).toISOString()
            const endTime = new Date(new Date(incident.timestamp + 'Z').valueOf() + 1000 * incident.trigger.period * 3 * incident.trigger.trigger_data_points).toISOString()
            const periods = {
                60: '1m',
                300: '5m',
                3600: '1h',
                86400: '1d',
                604800: '1w'

            }
            this.props.metricActions.getMetric(incident.trigger.metric, incident.trigger.field, startTime, endTime, periods[incident.trigger.period], 5000, unitFormat, null);
        }

    }

    getIncidentValues(incidentId) {
        let incident = this.emptyIncident;

        const convertThresholdsIntervalToMinutes = (t) => {
            return t * 5
        }

        if (this.props.incident) {
            let item = this.props.incident;
            incident.device_name = item.device_name;
            incident.title = item.title || '';
            incident.metric = item.metric || '';
            incident.description = item.description || '';
            incident.status = item.detail.state.value || '';
            if (item.detail && item.detail.state) {
                if (item.detail.state.stateReasonData && item.detail.state.stateReasonData.threshold) {
                    incident.threshold = `Threshold of ${item.detail.state.stateReasonData.threshold} has been breached`;
                }
                if (item.detail.state.stateReasonData && item.detail.state.stateReasonData.thresholds) {
                    const {thresholds} = item.detail.state.stateReasonData;
                    const minutes = convertThresholdsIntervalToMinutes(thresholds);
                    const value = item.trigger.schema === "missing_data" ? minutes : thresholds
                    incident.thresholds = `Threshold of ${value} has been breached`;
                }

                incident.status = item.detail.state.value || '';
                incident.timestamp = item.detail.state.timestamp || '';
                incident.statistic = item.detail.state.stateReasonData.statistic || '';
                incident.period = item.detail.state.stateReasonData.period || '';
                incident.recentDatapoints = item.detail.state.stateReasonData.recent_datapoints
                    .map(point => Number(point).toFixed(2)) || [];
            }
            incident.trigger_data_points = item.trigger_data_points || '';
            incident.clear_data_points = item.clear_data_points || '';
            incident.trigger = item.trigger;
        }
        return incident;
    }

    renderDetailSectionItem = (label, value) => {
        if (!value) return null;
        return (
            <div className="mb-3">
                <div className="pb-1 incident-detail-label">{label}</div>
                <div className="pb-1">{value}</div>
            </div>
        )
    };

    convertPeriodToMinutes = (p) => {
        return p / 60
    }

    renderEditAlert = () => {
        if (!this.props.incidentReducer.hasOwnProperty("incident")) return null;

        const {trigger_id} = this.props.incidentReducer.incident;
        return <a href={`/alerts/${trigger_id}/edit`}>
            <button className="btn btn-primary">Edit Alert</button>
        </a>
    }

    renderReport = () => {
        if (!this.props.incidentReducer.hasOwnProperty("incident")) return null;

        const {status, report_id} = this.props.incidentReducer.incident;
        return <a href={ status === 'Resolved' ? `/incidents/${this.props.incidentId}/report/${report_id}/edit` : `/incidents/${this.props.incidentId}/report/new`}>
            <button className="btn btn-primary">Create Report</button>
        </a>
    }

    renderIncidentCard = (incident) => {


        /**
         * id: unique identifier of incident
         detail_type: Schema of detail
         sensor_id: Unique identifier of sensor alarm was fired on
         client_id: Unique Id of client who owns sensor
         timestamp: timestamp when incident occurred
         detail:
         state:
         value: Enum of [ALARM, OK, INSUFFICIENT_DATA]
         reason: String giving description why state is updated
         timestamp: UTC timestamp of state chage
         state_data:
         version: Unique version identifier
         query_date: timestamp of when state was queried
         start_date: timestamp of start of state window
         statistic: type of datapoint ENUM of [SUM, AVG, N]
         period: Period used for alarm, value in seconds
         threshold: Value used for alarm threshold

         previous_state:
         value: Enum of [ALARM, OK, UNKNOWN]
         reason: String giving description why state is updated
         timestamp: UTC timestamp of state change
         state_reason_data:
         version: Unique version identifier
         query_date: timestamp of when state was queried
         start_date: timestamp of start of state window
         statistic: type of datapoint ENUM of [SUM, AVG, N]
         period: Period used for alarm, value in seconds
         threshold: Value used for alarm threshold

         **/

        return (
            <div className="card emp-box emp-shadow element_emp_equal incident-detail-card">
                <div className="card-header bg-white">
                    <span className="text-center incident-detail-title">Incident Detail</span>
                    <span className="ml-auto">{this.renderEditAlert()}</span>
                    <span className="ml-1">{this.renderReport()}</span>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            {/*}   {this.renderMetricGraph()}*/}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-3 pr-5 mr-2 border-right">

                            {this.renderDetailSectionItem("Title", incident.title)}
                            {this.renderDetailSectionItem("Description", incident.description)}
                            {this.renderDeviceDetailItem(incident)}
                            {incident.thresholds ?
                                this.renderDetailSectionItem("Thresholds", incident.thresholds)
                                : this.renderDetailSectionItem("Threshold", incident.threshold)}
                            {this.renderDetailSectionItem("State", incident.status)}
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-3">
                            {this.renderDetailSectionItem(
                                "Timestamp",
                                Moment.utc(incident.timestamp).local().format('YYYY/MM/DD hh:mm a')
                            )}
                            {this.renderDetailSectionItem("Statistic", incident.statistic)}
                            {this.renderDetailSectionItem("Period Minutes",
                                this.convertPeriodToMinutes(incident.period))}
                            {this.renderDetailSectionItem("Minutes to alarm", incident.trigger_data_points)}
                            {this.renderDetailSectionItem("Recent Datapoints", incident.recentDatapoints ? incident.recentDatapoints.join(" ") : '')}
                        </div>
                    </div>
                </div>
            </div>);
    }

    renderDeviceDetailItem(incident) {
        let item;
        if (!incident.trigger) {
            return null;
        }
        switch (incident.trigger.schema) {
            case 'monitor':
                item = <a
                    href={`/environment/device/${incident.metric}/`}>{this.renderDetailSectionItem("Device", incident.device_name)}</a>;
                break;
            case 'metric':
                item = <>{this.renderDetailSectionItem("Instance Id", incident.trigger.metric)}{this.renderDetailSectionItem("Metric", incident.trigger.field)}</>
                break;
            default:
                item = <a
                    href={`/environment/device/${incident.metric}/`}>{this.renderDetailSectionItem("Device", incident.device_name)}</a>;
        }
        return item;
    }

    getBreachStartPoint(incident) {
        const dataPoints = incident.trigger_data_points;

        return new Date(Moment.utc(incident.timestamp).valueOf()) - 1000 * incident.trigger.period * (dataPoints + 1);
    }

    getYPlotLines(trigger) {
        return trigger.conditions.map(condition => {
            let threshold = Number(condition.threshold);
            return new MetricPlotLine(threshold);
        });
    }

    renderMetricChart() {
        let metrics = []
        let xPlotBands = []
        let yPlotLines = []

        if (this.props.incident
            && this.props.incident.trigger
            && this.props.incident.trigger.schema === 'monitor') {
            const timestamps = this.props.environmentReducer.detailDeviceData.telemetry.map(telemetry => Moment.utc(telemetry.timestamp).toDate());
            let metric_values = this.props.environmentReducer.detailDeviceData.telemetry
                .map(telemetry => Number(telemetry[this.props.incident.trigger.field]))
                .map(item => Number(item.toFixed(2)));

            metrics = [
                new MetricData(
                    this.props.incident.trigger.field,
                    timestamps,
                    metric_values
                )
            ];


            xPlotBands = [
                new MetricPlotBand(this.getBreachStartPoint(this.props.incident), Moment.utc(this.props.incident.timestamp).valueOf())
            ]

            yPlotLines = this.getYPlotLines(this.props.incident.trigger);
        } else if (this.props.incident
            && this.props.incident.trigger
            && this.props.incident.trigger.schema === 'metric') {
            const timestamps = this.props.metricReducer.metric.items.map(item => Moment.utc(item.timestamp).toDate());
            let metric_values = this.props.metricReducer.metric.items
                .map(item => Number(item.value.toFixed(2)));

            metrics = [
                new MetricData(
                    this.props.incident.trigger.field,
                    timestamps,
                    metric_values
                )
            ];


            xPlotBands = [
                new MetricPlotBand(this.getBreachStartPoint(this.props.incident), Moment.utc(this.props.incident.timestamp).valueOf())
            ]

            yPlotLines = this.getYPlotLines(this.props.incident.trigger);
        }

        return (
            <MetricGraph
                title={this.props.incident.title + " Snapshot"}
                metrics={metrics}
                xPlotBands={xPlotBands}
                yPlotLines={yPlotLines}
                tickInterval={this.props.incident.trigger.period * 1000}
            />
        );
    }

    render() {
        let incident = this.getIncidentValues(this.props.incidentId);


        return (
            <main className="app-content">
                <section className="emp_org_settings_sec emp_report_sec emp_circle_box_sec">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="app-title border-bottom">
                                <div>
                                    <h1>{incident.title}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            {this.renderIncidentCard(incident)}
                        </div>
                        <div className="clearfix"></div>
                    </div>


                    <div className="row">
                        <div className="col-sm-12">
                            {!this.state.deviceDataLoading &&
                                this.renderMetricChart()}
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        incident: state.incidentReducer.incident,
        incidentReducer: state.incidentReducer,
        environmentReducer: state.environmentReducer,
        generalSettingsReducer: state.generalSettingReducer,
        metricReducer: state.metricReducer
    })
};

const mapDispatchToProps = (dispatch) => ({
    incidentActions: bindActionCreators(IncidentActions, dispatch),
    environmentActions: bindActionCreators(EnvironmentActions, dispatch),
    generalSettingsActions: bindActionCreators(GeneralSettingsActions, dispatch),
    metricActions: bindActionCreators(MetricActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(IncidentDetailPage);
