import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import {WorkflowModel} from './model/Workflow';


export const listWorkflows = () => {
    return axios.get(`${BASE_URL()}/api/v1/workflows`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response.data['items'].map(item => WorkflowModel.fromJson(item));
    });
};

export const getWorkflow = (workflowId) => {
    return axios.get(`${BASE_URL()}/api/v1/workflows/${workflowId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return WorkflowModel.fromJson(response.data.item);
    });
};