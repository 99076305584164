import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import {Asset} from "./model/Asset";
import {convertApiResponseToAssetType} from "./assetTypeApi";
import {buildAxiosErrorHandlerConfig} from "../utils/utils";



const convertApiResponseToAsset = (response): Asset => {
    return new Asset(
        response['id'],
        response['name'],
        response['description'],
        response['asset_type_id'],
        response['parent_id'],
        response['created_by_id'],
        response['updated_by_id'],
        new Date(response['created_at']),
        new Date(response['updated_at']),
        response['image_url'] ? response['image_url']['url'] : null,
        response['icon_url'] ? response['icon_url']['url'] : null,
        response['asset_type'] ? convertApiResponseToAssetType(response['asset_type']) : null
    )
}

const convertAssetFormToApiRequest = (values) => {
    return {
        id: values.id,
        name: values.name,
        description: values.description,
        asset_type_id: values.assetTypeId,
        parent_id: values.parentId,
        created_by_id: values.createdById,
        updated_by_id: values.updatedById,
        ...((values.image && values.image.length) && {image_url: values.image[0]}),
        ...((values.iconUrl && values.imageUrl.length) && {icon_url: values.iconUrl[0]}),
    }
}


export const listAssets = (data) => {
    return axios.get(`${BASE_URL()}/api/v1/assets`, {
        params: data,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data.items.map(item => convertApiResponseToAsset(item)));
};

export const getAsset = (id: string) => {
    return axios.get(`${BASE_URL()}/api/v1/assets/${id}`, buildAxiosErrorHandlerConfig({
        params: {
            expand: "asset_type"
        },
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    })).then(response => convertApiResponseToAsset(response.data));
};

export const createAsset = (data) => {
    const formData = new FormData();
    const request = convertAssetFormToApiRequest(data);

    Object.keys(request).forEach(key => {
        formData.append(`asset[${key}]`, request[key]);
    });
    return axios.post(`${BASE_URL()}/api/v1/assets`, formData, buildAxiosErrorHandlerConfig({
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    })).then(response => convertApiResponseToAsset(response.data));
};

export const updateAsset = (id: string, data) => {
    const formData = new FormData();
    const request = convertAssetFormToApiRequest(data);

    Object.keys(request).forEach(key => {
        formData.append(`asset[${key}]`, request[key]);
    });

    return axios.put(`${BASE_URL()}/api/v1/assets/${id}`, formData, buildAxiosErrorHandlerConfig({
        headers: {
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    })).then(response => convertApiResponseToAsset(response.data));
};

export const deleteAsset = (id) => {
    return axios.delete(`${BASE_URL()}/api/v1/assets/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    })
        .then(response => response.data);
};
