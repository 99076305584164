import React from 'react';
import { Field, FieldArray } from 'redux-form';

export class AlertConditionsSelector extends React.Component {
  constructor(props) {
    super(props);
    this.renderConditions = this.renderConditions.bind(this)
  }

  renderInputField = ({ children, input, meta: { touched, error } }) => {
    let fieldStyle = '';
    let errorMessage = '';
    if (touched && error) {
      fieldStyle = 'input_error'
      errorMessage = error;
    }
    return (
      <React.Fragment>
        <div className="input-icon input-icon-right">
          <input type="number" {...input}
            className={`form-control input_modifier shadow_select_hover ml-1 d-inline-block ${fieldStyle}`} />
          {children}
        </div>
        <div className="form-group__error">{errorMessage}</div>
      </React.Fragment>
    )
  };

  renderConditions({ fields, meta: { error, submitFailed } }) {
    return (
      <React.Fragment>
        {fields.map((condition, index) => (
            <div 
              key={`alert-condition-${index}`} 
              className="form-inline mt-2"
            >
              <Field id={'dummyDiv'} name={`${condition}.condition`} component='select'
                className='custom-select cst-select-box input_modifier shadow_select_hover col-sm-6 col-7'>
                <option value='<'>Less Than</option>
                <option value='=='>Equal To</option>
                <option value=">">Greater Than</option>
                <option value=">=">Greater Than or Equal To</option>
                <option value="<=">Less Than or Equal To</option>
              </Field>
              <Field
                name={`${condition}.threshold`}
                type="text"
                component={this.renderInputField}
                label="Threshold"
              >
                <i>{this.props.conditionUnits}</i>
              </Field>
              {(index > 0 || fields.length !== 1) &&
                <a
                  href="#" role="button"
                  className="ml-2"
                  onClick={() => fields.remove(index)}
                >
                  <img src={require("../../styles/images/icon/icn_close.png")} alt="Remove" />
                </a>
              }
            </div>
        ))}
        {submitFailed && error && <div className="form-group__error">{error}</div>}
        { fields.length < 3 &&
          <div>
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={() => fields.push({condition:'<'})}
            >
              <i className="fa fa-plus mr-1"></i>
              Add Condition
            </button>
          </div>
        }
      </React.Fragment>
    )
  }

  render() {
    return (
      <div id="dumbDiv">
        <label className="label_modifier col-sm-12 pl-0">{this.props.title}</label>
        <FieldArray name="conditions" component={this.renderConditions} />
      </div>
    );
  }
}
