export const sensorConstants = {
    DELETE_SENSOR: 'DELETE_SENSOR',
    DELETE_SENSOR_FULFILLED: 'DELETE_SENSOR_FULFILLED',

    FETCH_SENSOR: 'FETCH_SENSOR',
    FETCH_SENSOR_FULFILLED: 'FETCH_SENSOR_FULFILLED',

    FETCH_SENSOR_DATA: 'FETCH_SENSOR_DATA',
    FETCH_SENSOR_DATA_FULFILLED: 'FETCH_SENSOR_DATA_FULFILLED',

    CREATE_SENSOR: 'CREATE_SENSOR',
    CREATE_SENSOR_FULFILLED: 'CREATE_SENSOR_FULFILLED',

    UPDATE_SENSOR: 'UPDATE_SENSOR',
    UPDATE_SENSOR_FULFILLED: 'UPDATE_SENSOR_FULFILLED'
};