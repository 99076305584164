import React, {Component} from 'react';
import Tabs from "../../ui/Tabs/Tabs";
import {formatTimestampDateTime} from "../../utils/formatHelper";
import {Link} from "react-router";
import DeviceType from "../../api/model/DeviceType";
import {TabWrapper} from "../../ui/Tab/TabWrapper";
import DeviceTypeMetricsTabPage from "./Tabs/DeviceTypeMetricsTabPage";
import DeviceTypeMetric from "../../api/model/DeviceTypeMetric";

interface DeviceTypeDetailPageProps {
    deviceType: DeviceType;
    deviceTypeMetrics: Array<DeviceTypeMetric>;
    onTabChange?: (tabIndex: number) => void;
    onAddMetricSubmit: (values: any) => Promise<void>;
    onDeleteMetricClick: (item: any) => void;
    onItemClick: (item: any) => void;
}

export class DeviceTypeDetailPage extends Component<DeviceTypeDetailPageProps> {
    constructor(props: DeviceTypeDetailPageProps) {
        super(props);
    }

    renderTextRow(label: string, text: string) {
        return (
            <div className="row mt-2">
                <span className="col-6 col-sm-4 col-md-4 col-lg-3 emp-type-subtitle1">{label}</span>
                <span className={"col-6 col-sm col-md col-lg emp-type-subtitle1"}>{text}</span>
            </div>
        );
    }

    renderTabs() {
        return (
            <>
                <Tabs onChange={this.props.onTabChange}>
                    <TabWrapper key="1" className="emp-type-subtitle1" label="Summary">
                        {this.renderSummaryCard()}
                    </TabWrapper>
                    <TabWrapper key="2" className="emp-type-subtitle1" label="Metrics">
                        <DeviceTypeMetricsTabPage
                            deviceTypeId={this.props.deviceType.id}
                            deviceMetrics={this.props.deviceTypeMetrics || []}
                            onItemClick={this.props.onItemClick}
                            onDeleteMetricClick={this.props.onDeleteMetricClick}
                            onAddMetricSubmit={this.props.onAddMetricSubmit}
                        />
                    </TabWrapper>
                </Tabs>


            </>
        )
    }

    render() {
        const {deviceType} = this.props;
        const editUrl = `/device_types/${deviceType.id}/edit`;
        return (
            <main className="app-content">
                <section className="emp_org_settings_sec emp_report_sec emp_circle_box_sec">
                    <div className="card emp-box emp-shadow">
                        <div className={"bg-white container-fluid mb-4"}>
                            <div className={"row mt-2"}>
                                <h1 className={"emp-type-h2 col"}>{deviceType.name}</h1>
                            </div>

                            <div className={"row"}>
                                <div className={"col"}></div>
                                <div className={"col-4 col-sm-3"}>
                                    <Link to={editUrl}>
                                        <button className={"btn-outline-primary"}><span
                                            className={"emp-type-btn-primary"}>EDIT</span></button>
                                    </Link>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    {this.renderTabs()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }

    renderSummaryCard() {
        const {deviceType} = this.props;
        const imageUrl = deviceType.image_url ? deviceType.image_url : `https://via.placeholder.com/250?text=${deviceType.name || 'Device Type'}`

        return (
            <div className="container-fluid">

                <div className="row mb-2">
                    <div className="col-12 col-sm-2"><img className="placeholder img-thumbnail"
                                                          src={imageUrl}></img>
                    </div>
                    <div className="col-12 col-sm-10">
                        <div className={"row"}>
                            <div className="col-12 col-xl-6">
                                {this.renderTextRow("Name", deviceType.name)}
                                {this.renderTextRow("Description", deviceType.description)}
                            </div>
                            <div className="col-12 col-xl-6">
                                {this.renderTextRow("Created Date", formatTimestampDateTime(deviceType.created_at))}
                                {this.renderTextRow("Updated Date", formatTimestampDateTime(deviceType.updated_at))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}