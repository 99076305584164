import {workflowScheduleConstants} from '../constants/workflowSchedule.constants';
import * as workflowScheduleApi from "../api/workflowScheduleApi";

export const createWorkflowSchedule = (values) => (dispatch) => {
    return dispatch({
        type: workflowScheduleConstants.CREATE_WORKFLOW_SCHEDULE,
        payload: workflowScheduleApi.createWorkflowSchedule(values)
    });
};

export const listWorkflowSchedules = () => (dispatch) => {
    return dispatch({
        type: workflowScheduleConstants.LIST_WORKFLOW_SCHEDULES,
        payload: workflowScheduleApi.listWorkflowSchedules()
    });
};

export const updateWorkflowSchedule = (workflow) => (dispatch) => {
    return dispatch({
        type: workflowScheduleConstants.UPDATE_WORKFLOW_SCHEDULE,
        payload: workflowScheduleApi.updateWorkflowSchedule(workflow)
    });
};

export const deleteWorkflowSchedule = (id) => (dispatch) => {
    return dispatch({
        type: workflowScheduleConstants.DELETE_WORKFLOW_SCHEDULE,
        payload: workflowScheduleApi.deleteWorkflowSchedule(id)
    });
};

export const getWorkflowSchedule = (workflowId) => (dispatch) => {
    return dispatch({
        type: workflowScheduleConstants.GET_WORKFLOW_SCHEDULE,
        payload: workflowScheduleApi.getWorkflowSchedule(workflowId)
    });
};

export const selectWorkflowSchedule = (selectedWorkflow) => ({
    type: workflowScheduleConstants.SELECT_WORKFLOW_SCHEDULE,
    payload: selectedWorkflow
});

export const createWorkflowScheduleRun = (scheduleId) => (dispatch) => {
    return dispatch({
        type: workflowScheduleConstants.CREATE_WORKFLOW_SCHEDULE_RUN,
        payload: workflowScheduleApi.createWorkflowScheduleRun(scheduleId)
    });
};
