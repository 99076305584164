import { deviceTypeConstants } from '../constants/deviceTypes.constants';
import * as deviceTypesApi from '../api/deviceTypesApi';

export const listDeviceTypes = (data) => ({
    type: deviceTypeConstants.LIST_DEVICE_TYPES,
    payload: deviceTypesApi.listDeviceTypes(data),
});

export const getDeviceType = (values) => ({
    type: deviceTypeConstants.GET_DEVICE_TYPE,
    payload: deviceTypesApi.getDeviceType(values),
});

export const createDeviceType = (values) => ({
    type: deviceTypeConstants.CREATE_DEVICE_TYPE,
    payload: deviceTypesApi.createDeviceType(values),
});

export const updateDeviceType = (id, values) => ({
    type: deviceTypeConstants.UPDATE_DEVICE_TYPE,
    payload: deviceTypesApi.updateDeviceType(id, values),
});

export const deleteDeviceType = (id) => ({
    type: deviceTypeConstants.DELETE_DEVICE_TYPE,
    payload: deviceTypesApi.deleteDeviceType(id),
});

export const resetDeviceType = () => ({
    type: deviceTypeConstants.RESET_DEVICE_TYPE,
    payload: async () => {},
});
