import {assetTypeConstants} from '../constants/assetType.constants'

const initialState = {
    assetType: {},
    assetTypes: [],
    updated: false,
};

export const assetTypeReducer = (state = initialState, action) => {
    switch (action.type) {
        case assetTypeConstants.GET_ASSET_TYPE_FULFILLED:
            state = {
                ...state,
                assetType: action.payload,
                updated: true
            };
            return state;
        case assetTypeConstants.LIST_ASSET_TYPES_FULFILLED:
            state = {
                ...state,
                assetTypes: action.payload,
                updated: true
            }
            return state;
        case assetTypeConstants.UPDATE_ASSET_TYPE_FULFILLED:
        case assetTypeConstants.CREATE_ASSET_TYPE_FULFILLED:
            state = {
                ...state,
                assetType: action.payload,
                updated: true
            };
            return state;
        case assetTypeConstants.DELETE_ASSET_TYPE_FULFILLED:
            state = {
                ...state,
                assetTypes: state.assetTypes.filter(assetType => assetType.id !== action.payload.id),
                updated: true
            }
            return state;
        case assetTypeConstants.RESET_ASSET_TYPE_FULFILLED:
            state = {
                ...state,
                assetType: {},
                updated: true
            }
            return state;
        default:
            return state;
    }
};