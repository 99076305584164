import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

const renderField = ({ input, label, type,className,options,placeholder,parentClass, meta: { touched, asyncValidating, error } }) => {
    return (
        <div className={parentClass}>
            { label !== null && <label className="form-group__label">{label}</label> }
            <div className={`form-group__field ${asyncValidating ? 'async-validating' : ''}`}>
                <select
                    {...input}
                    type={type}
                    className={className}
                    >
                    { !input.value &&
                        <option value="">{placeholder ? placeholder : 'Please Select' }</option>
                    }
                    {
                        options.map(option => {
                            if (option.name)
                                return  <option key={option.id} value={option.id}>{option.name}</option>
                            else
                                return  <option key={option} value={option.id}>{option}</option>
                        })
                    }
                </select>
            </div>
            { touched && error && <div className="form-group__error">{error}</div>}
        </div>
    );
}
export const SelectField = ({ name, label,className,parentClass,options,placeholder}) => (
    <Field
        name={name}
        label={label}
        className={className}
        parentClass={parentClass}
        placeholder={placeholder}
        options={options}
        component={renderField}
        />
);

SelectField.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    parentClass: PropTypes.string,
    options: PropTypes.array,
};

SelectField.defaultProps = {
    name:'',
    label: null,
    placeholder: '',
    className: '',
    parentClass: '',
    options: [],
};