import {reportConstants} from "../constants/report.constants";

const initialState = {
    updated: false,
    items: []
};

export const reportReducer = (state = initialState, action) => {
    switch (action.type) {
        case reportConstants.FETCH_REPORT_DATA_FUILFILLED:
            state = {
                ...state,
                items: action.payload.items,
                updated:true
            };
            return state;
        default:
            return state;
    }
};

