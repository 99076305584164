import React from "react";
import {Field} from 'redux-form';


export default class FormInvisibleInputComponent extends React.Component<{
    name: string,
    type: string,
    value: any
}, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="form-group">
                <Field name={this.props.name}
                       component={() => <input type={"hidden"} />}
                       type={this.props.type}
                       value={this.props.value}
                       />
            </div>)
    }
}


