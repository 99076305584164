import React from 'react';
import {Form, reduxForm} from 'redux-form';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { Link } from 'react-router';
import * as AuthActions from '../../actions/authActions';
import validate from './validate';
import {InputField} from '../InputField/InputField';
export class UpdatePasswordPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            terms_and_condition: false
        };

    }


    render() {
        const { handleSubmit, submitting,logout, auth } = this.props;
        return (
            <section className="emp-sec emp-login-sec">
                {
                    this.state.terms_and_condition &&
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <div className="emp-brand">
                                    <img src={require('../../styles/images/icon/logo.png')} alt="Logo"/>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3">
                                <div className="emp-login-form">
                                    <div className="card shadow emp-form-card">
                                        <div className="card-body">
                                            <div className="emp-form-heading text-center">
                                                <h2>Terms of Service</h2>
                                            </div>
                                            <div className="emp-text-block text-center">
                                                <p className="text-confirm">
                                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus
                                                    vel orci aliquam, fringilla neque at, eleifend dui. Morbi in tellus
                                                    tortor. Sed id aliquet mauris. Quisque fermentum lectus id nulla
                                                    rhoncus maximus. Aenean dapibus semper libero, eu fermentum leo
                                                    accumsan hendrerit. Sed vulputate feugiat mauris. Duis fringilla
                                                    felis justo, nec laoreet ipsum dictum ut. Curabitur gravida turpis
                                                    quis nisl vulputate, in venenatis felis egestas. Fusce dignissim
                                                    gravida arcu, a convallis ex tincidunt ac. Nam condimentum efficitur
                                                    tortor ultricies posuere. Curabitur porttitor ex a urna luctus, in
                                                    volutpat mi pharetra. Nullam accumsan faucibus magna quis aliquam.
                                                    Sed tempus vitae justo ut condimentum. Sed efficitur egestas libero,
                                                    luctus tristique ante.

                                                    Proin mattis quis ex eget aliquet. Nullam ultricies vulputate quam,
                                                    at molestie metus consequat facilisis. Sed diam leo, suscipit vitae
                                                    nisl et, porta consequat libero. Aenean nec dui dui. Nam nisl dui,
                                                    euismod vel ornare quis, maximus ac lacus. Praesent feugiat sagittis
                                                    tellus. Sed dapibus nisi vel dapibus congue.

                                                </p>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div
                                className="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3 text-right">
                                <a role="button" href="#" onClick={() => {this.setState({terms_and_condition : false})}} className="btn btn-primary emp-btn emp-form-btn mt-4 text-capitalize">I agree</a>
                            </div>
                        </div>
                    </div>
                }
                {
                    !this.state.terms_and_condition &&
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 text-center">
                                <div className="emp-brand">
                                    <img src={require('../../styles/images/icon/logo.png')} alt="Logo"/>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            { auth.password_updated &&
                            <div
                                className="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3">
                                <div className="emp-login-form">
                                    <div className="card shadow emp-form-card">
                                        <div className="card-body">
                                            <div className="emp-form-heading text-center">
                                                <h2>Password successfully Created!</h2>
                                            </div>
                                            <div className="emp-text-block text-center">
                                                <button type="button" onClick={logout} role="button"
                                                        className="btn btn-primary emp-btn emp-form-btn">Continue
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {
                                !auth.password_updated &&
                                <div
                                    className="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                    <div className="emp-login-form">
                                        <div className="card shadow emp-form-card">
                                            <div className="card-body">
                                                <div className="emp-form-heading text-center">
                                                    <h2>Change your Password</h2>
                                                </div>
                                                <Form onSubmit={handleSubmit} className="login-form" noValidate={true}>

                                                    <InputField
                                                        type="password"
                                                        name="user[temporary_password]"
                                                        placeholder="Temporary Password"
                                                        className="form-control input_modifier"
                                                        label="Temporary Password"
                                                        />
                                                    <InputField
                                                        type="password"
                                                        name="user[password]"
                                                        placeholder="Password"
                                                        className="form-control input_modifier"
                                                        label="Password"
                                                        />

                                                    <InputField
                                                        type="password"
                                                        name="user[password_confirmation]"
                                                        placeholder="Confirm Password"
                                                        label="Confirm Password"
                                                        className="form-control input_modifier"
                                                        />

                                                    <div className="form-group text-center">
                                                        <button type="submit"
                                                                className="btn btn-primary emp-btn emp-form-btn">
                                                            Continue
                                                        </button>
                                                    </div>
                                                    <div className="form-group text-center form-bottom-links">
                                                        <p className="emp-text-gray">By signing up, I agree to Empiricʼs
                                                            <a onClick={() => {this.setState({terms_and_condition : true})}} href="#">Terms of Service.</a></p>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>
                    </div>
                }

            </section>

        );
    }
}

const mapStateToProps = (state) => {
    return ({
        authReducer: state.authReducer
    });
};

const mapDispatchToProps = (state,dispatch) => {
    return ({
        authActions: bindActionCreators(AuthActions, dispatch)
    });
};

export default reduxForm({
    form: 'passwordChangeForm',
    validate,
    initialValues: {user: {reset_password_token: {}}},
    onSubmit: (values, dispatch) => dispatch(AuthActions.updatePassword(values))
})(connect(mapStateToProps, mapDispatchToProps)(UpdatePasswordPage));