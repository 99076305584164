import React from 'react';
import * as authApi from '../../api/authApi';
import store from '../../store/store';
import {browserHistory} from 'react-router';
import swal from 'sweetalert';

export default class UnlockAccountPage extends React.Component {
    state = {
        loading: false,
        isUnlockValid: false,
        isEmailValid: false,
        errorMessage: ''
    };

    constructor (props) {
        super(props);

        this.onSubmit = this.onSubmit.bind(this);
    }

    async unlockAccount () {
        try {
            let state = store.getState();
            this.setState({ loading: true });
            let response = await authApi.unlock_account();
            if (response.data){

                this.setState({
                    loading: false,
                    isUnlockValid: true
                });

                swal({
                   icon: 'success',
                   title: 'Sent',
                   text: response.data.message,
                   className: 'error-handler-modal'
                });
                browserHistory.push('/auth/login');
            }
            else{
                this.setState({ loading: false });
                this.setState({
                    errorMessage: response.data.message
                })
            }

        } catch (e) {
            this.setState({
                loading: false,
                errorMessage: `Something went wrong unlocking your account`,
                isUnlockValid: false
            });
        }
    }

    onSubmit(e) {
        e.preventDefault();
        return this.unlockAccount();
    }

    render () {
        const { email, loading, errorMessage, isResetPasswordLinkSent } = this.state;

        return (
            <section className="emp-sec emp-login-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <div className="emp-brand">
                                <img src={require('../../styles/images/icon/logo.png')} alt="Logo" />
                            </div>
                        </div>
                    </div>
                    { !isResetPasswordLinkSent &&
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                <div className="emp-login-form">
                                    <div className="card shadow emp-form-card">
                                        <div className="card-body">
                                            <div className="emp-form-heading text-center">
                                                <h2>Unlock Account</h2>
                                            </div>
                                            <form className="forgot-password-form__form" onSubmit={this.onSubmit}
                                                  noValidate={true}>
                                                <div className="form-group text-center">
                                                    <button type="submit" disabled={loading}
                                                            className="btn btn-primary emp-btn emp-form-btn">Unlock Account
                                                    </button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
             </section>
        );
    }
}

