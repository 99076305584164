import React from 'react';
import {connect} from 'react-redux'
import {Field, reduxForm, formValueSelector, Form} from 'redux-form';
import {Link} from "react-router";
import {WorkType} from "../../api/model/WorkEvent"
import {WorkActivityModel} from "../../api/model/WorkActivity"
import {FailureModeModel} from "../../api/model/FailureMode"
import {NewSearchableDropDownField} from "../SearchableDropDownField/NewSearchableDropDownField";
import {DeviceItemField} from "../SearchableDropDownField/SearchableDropDownField";
import GroupAndSensorDeviceActiveDropDownField from "../SearchInput/GroupAndSensorDeviceActiveDropDownField";
import {DateTimeField} from "../DateTimeField/NewDateTimeField";
import {TextField} from "../../ui/Input/TextField";
import {SelectField} from "../../ui/Input/SelectField";
import * as Moment from "moment/moment";
import {required, validateDateTimeFormat} from "../../utils/validateFunctions";


const validate = values => {
    const errors = {devices:{}};
    const requiredFields = ['event_timestamp', 'work_type', 'failure_mode', 'work_activity', 'device_ids', 'time_spent',
        'avoided_downtime', 'system_provided', 'apply_learning'];
    for (let field of requiredFields) {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    }

    if (!values['devices']) {
        errors.devices = {_error: 'At least one device must be entered'};
    }
    return errors;
}

const FORM_NAME = "workEventForm";

const titleField = ({input, label, meta: {touched, error}}) => {
    return (
        <TextField label={label} {...input} error={touched && error} errorLabel={error}/>
    );

}

const getInputComponent = (inputProps, label) => <TextField label={label} {...inputProps}/>

const selectField = (props) => {
    const {input, label, items, meta: {error, touched}} = props;
    return <SelectField label={label}
                        placeholder=""
                        value={input.value}
                        {...input}
                        items={items}
                        error={touched && error}
                        errorLabel={error}
    />
}
class WorkEventForm extends React.Component<{
    initialValues?: Object,
    handleSubmit: (e) => void,
    onFormSubmit: (e) => void,
    workTypes: Object,
    workActivities: WorkActivityModel[],
    failureModes: FailureModeModel[],
    initialize: (e) => void,
    devices: [Object]
}, {}> {
    constructor(props) {
        super(props);
        this.state = {
            width: '0'
        };
    }

    renderDatapointRow(fieldName, label) {
        return (
            <div className="row mb-3">
                <div className={"col-12 col-sm-6"}>
                    <Field name={fieldName} label={label} component={titleField} type={"number"}/>
                </div>
            </div>
        );
    }

    renderTextRow(fieldName, label) {
        return (
            <div className="row mb-3">
                <div className={"col-12 col-sm-6"}>
                    <Field name={fieldName} label={label} component={titleField}/>
                </div>
            </div>
        );
    }

    renderSmallSelectFieldRow(fieldName, label, items) {
        return (
             <div className="mt-2 mb-3 ">
                <div className={"col-8 col-sm-4 pl-0"}>
                    <Field name={fieldName} label={label} items={items} component={selectField}/>
                </div>
             </div>
        );
    }

    renderSelectFieldRow(fieldName, label, items) {
        return (
            <div className="row mt-2 mb-3">
                <div className={"col-12 col-sm-6"}>
                    <Field name={fieldName} label={label} items={items} component={selectField}/>
                </div>
            </div>
        );
    }

    renderHeaderBar() {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="app-title border-bottom">
                        <div>
                            <div className="media align-items-center">
                                <Link to="/alerts">
                  <span className="mr-3 emp_icn_middle">
                    <img
                        src={require("../../styles/images/icon/ic_left-arrow.png")}
                        alt="Icon"
                    />
                  </span>
                                </Link>

                                <div className="media-body">
                                    <h1 className="mt-0 text-capitalize">
                                        Go Back Here
                                    {/*    TODO: figure out correct title*/}
                                    </h1>
                                    <span className="sub_txt_title">
                    {" "}
                  {/*                      TODO: subtitle to go back*/} lorum ipsum
                  </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onSubmit(values){
        values['device_ids'] = values.devices.map(device => device.identity);
        const workActivity = this.props.workActivities.find(activity => activity.id === values["work_activity"])
        values['work_activity'] = workActivity;
        const failureMode = this.props.failureModes.find(failureMode => failureMode.id === values["failure_mode"])
        values['failure_mode'] = failureMode;
        if(!workActivity || !failureMode){
            console.error("selected failure mode or activity couldn't be found");
            return;
        }
        values['system_provided'] = values['system_provided'] === "true" ? true : false;
        values['apply_learning'] = values['apply_learning'] === "true" ? true : false;
        this.props.onFormSubmit(values);
    }

    render() {
        const workTypes = Object.values(WorkType).map(workType => ({value: workType, label: workType}));
        const workActivities = this.props.workActivities.map(activity=>({value: activity.id, label: activity.title}));
        const failureModes = this.props.failureModes.map(failureMode=>({value: failureMode.id, label: failureMode.title}));
        const applyLearning = [{value: true, label: "Yes"}, {value: false, label: "No"}];
        const systemProvided = [{value: true, label: "Yes"}, {value: false, label: "No"}];

        return (
                <Form
                    key={FORM_NAME}
                    onSubmit={this.props.handleSubmit(this.onSubmit.bind(this))}
                    className="emp_create_report_form mt-3"
                    noValidate
                >
                    <div className="emp_create_report_form  card shadow emp-form-card mb-3 emp_create_report_card">
                        <div className="row  p-5">
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="emp-title-item env-date date-field col-6 pl-0 mb-4">
                                    {/*<label className="label_modifier col-sm-12 pl-0">Event Timestamp</label>*/}
                                    <DateTimeField
                                        type="text"
                                        name="event_timestamp"
                                        placeholder="Event Timestamp"
                                        className="form-control input_modifier app-title-shadow"
                                        parentClass="app-title-shadow"
                                        renderInput={(props)=>getInputComponent(props,"Event Timestamp")}
                                        validate={[validateDateTimeFormat, required]}
                                    />
                                </div>
                                {this.renderSelectFieldRow("failure_mode", "Failure Mode", failureModes)}
                                {this.renderSelectFieldRow("work_type", "Work Type", workTypes)}
                                {this.renderSelectFieldRow("work_activity", "Work Activity", workActivities)}
                                {this.renderDatapointRow("time_spent", "Time Spent (min)")}
                                {this.renderDatapointRow("avoided_downtime", "Avoided Downtime (min)")}
                            </div>

                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="form-group">
                                    <NewSearchableDropDownField
                                        fieldName={'devices'}
                                        itemFieldComponent={DeviceItemField}
                                        title={'This alert applies to'}
                                        placeholder=""
                                        isDisabled={false}
                                        render={({
                                                     placeholder,
                                                     isDisabled,
                                                     handleChange,
                                                     classNames
                                                 }) =>
                                            <GroupAndSensorDeviceActiveDropDownField
                                                placeholder={placeholder}
                                                isDisabled={isDisabled}
                                                handleChange={handleChange}
                                                classNames={classNames}
                                                shouldRenderSuggestions={()=>true}
                                                selections={this.props.devices}
                                                renderInputComponent={(props)=>getInputComponent(props,"Type Sensor or Group Name")}
                                            />
                                        }
                                    />
                                </div>

                                <div className="form-group mt-4">
                                    <label className="label_modifier col-sm-12 pl-0">Notes</label>
                                    <Field
                                        name={`notes`}
                                        component={"textarea"}
                                        rows={5}
                                        className={"form-control"}
                                    />
                                </div>
                                <div className={"pl-0"}>
                                    {this.renderSmallSelectFieldRow("apply_learning", "Apply Learning", applyLearning)}
                                    {this.renderSmallSelectFieldRow("system_provided", "System Provided", systemProvided)}
                                </div>

                            </div>
                        </div>

                    </div>
                    <div className="bg_transparent_box">
                        <hr/>
                    </div>
                    <div className="active-device-btn text-right">
                        <a href="/alerts" className="create-group mr-4">
                            <img
                                src={require("../../styles/images/icon/icn_close.png")}
                                alt="Icon"
                            />
                            <span className="ml-2">Cancel</span>
                        </a>
                        <button className="btn btn-next btn-primary emp-cst-btn emp-btn" type={"submit"}>
                            <span className="mr-2 emp_icn_middle">
                              <img
                                  src={require("../../styles/images/icon/ic_checking.png")}
                                  alt="Icon"
                              />
                            </span>
                            {"submit"}
                        </button>
                    </div>
                </Form>
        )
    }
}

const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state) => {
    let format = 'MM-DD-YYYY hh:mm A';
    let event_timestamp = Moment.utc(new Date((new Date()).valueOf() - 1000 * 3600 * 24).toISOString()).format(format);
    const devices = selector(state, 'devices');

    return ({
        devices: devices,
        initialValues: {
            event_timestamp: event_timestamp,
        },
    });
};

const wrappedForm = connect(mapStateToProps)(
    reduxForm({
        form: FORM_NAME,
        enableReinitialize: true,
        validate
    })(WorkEventForm)
);

export default wrappedForm;