import React, { Component } from 'react';
import {SelectField} from "../../ui/Input/SelectField";
import {change} from 'redux-form';
import {WORKFLOW_SCHEDULE_FORM_NAME} from "./WorkflowScheduleForm";


type State = {
    cronString: string;
    firstSelect: string;
    secondSelect: string;
    thirdSelect: string;
    fourthSelect: string;
    fifthSelect: string;
    sixthSelect: string;
};

type Props = {
    dispatch: any;
}

type Option = {
    value: number | string;
    label: string;
};


class CronJobBuilder extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            cronString: '* * * * *',
            firstSelect: 'Year',
            secondSelect: '*',
            thirdSelect: '*',
            fourthSelect: '*',
            fifthSelect: '*',
            sixthSelect: '*',
        };
    }

    handleClear = () => {
        this.setState({
            firstSelect: "Year",
            secondSelect: "*",
            thirdSelect: "*",
            fourthSelect: "*",
            fifthSelect: "*",
            sixthSelect: "*",
            cronString: "* * * * *",
        });
    };

    handleChange(e: React.ChangeEvent<HTMLSelectElement>, fieldName: string) {
        const value = e.target.value;
        this.setState({ [fieldName]: value } as Pick<State, keyof State>, () => {
            let minute = '*';
            let hour = '*';
            let dayOfMonth = '*';
            let month = '*';
            let dayOfWeek = '*';

            const { firstSelect, secondSelect, thirdSelect, fourthSelect, fifthSelect, sixthSelect } = this.state;

            if (firstSelect === 'Minute') {
                minute = '*';
            } else if (firstSelect === 'Hour') {
                minute = sixthSelect;
            } else if (['Day', 'Week'].includes(firstSelect)) {
                minute = sixthSelect;
                hour = fifthSelect;
            } else if (firstSelect === 'Month') {
                minute = sixthSelect;
                hour = fifthSelect;
                dayOfMonth = thirdSelect;
                dayOfWeek = '*';
            } else if (firstSelect === 'Year') {
                minute = sixthSelect;
                hour = fifthSelect;
                dayOfMonth = thirdSelect;
                month = secondSelect;
            }

            if (firstSelect === 'Week' || (firstSelect === 'Year' && secondSelect !== '*') || (firstSelect === 'Month' && thirdSelect !== '*')) {
                dayOfWeek = fourthSelect;
            }

            const cronString = `${minute} ${hour} ${dayOfMonth} ${month} ${dayOfWeek}`;

            this.setState({ cronString });
            this.props.dispatch(change(WORKFLOW_SCHEDULE_FORM_NAME, 'interval', cronString));
        });
    }

    renderFirstSelect() {
        const options = [
            { value: 'Year', label: 'Year' },
            { value: 'Month', label: 'Month' },
            { value: 'Week', label: 'Week' },
            { value: 'Day', label: 'Day' },
            { value: 'Hour', label: 'Hour' },
            { value: 'Minute', label: 'Minute' },
        ];

        return (
            <div className={"pb-1"}>
                <SelectField
                    label="Every"
                    items={options}
                    name="firstSelect"
                    value={this.state.firstSelect}
                    onChange={(e) => this.handleChange(e, 'firstSelect')}
                />
            </div>
        );
    }

    renderMonthSelect() {
        if (['Year', 'Month'].includes(this.state.firstSelect)) {
            const options = [
                { value: '*', label: 'Every month' },
                ...Array.from({ length: 12 }, (_, i) => ({
                    value: i + 1,
                    label: new Date(0, i + 1, 0).toLocaleString('en-US', { month: 'long' }),
                })),
            ];

            return (
                <div className={"pt-1 pb-1"}>
                    <SelectField
                        label={this.state.firstSelect === 'Year' ? 'in' : 'on'}
                        items={options}
                        name="secondSelect"
                        value={this.state.secondSelect}
                        onChange={(e) => this.handleChange(e, 'secondSelect')}
                    />
                </div>
            );
        }
        return null;
    }


    renderDayOfMonthSelect() {
        if (['Year', 'Month'].includes(this.state.firstSelect)) {
            const options: Option[] = [{ value: '*', label: 'Every day of the month' }];

            for (let i = 0; i <= 31; i++) {
                const dayOfTheMonthOption: Option = { value: i, label: i.toString()}
                options.push(dayOfTheMonthOption);
            }

            return (
                <div className={"pt-1 pb-1"}>
                    <SelectField
                        label="on"
                        items={options}
                        name="thirdSelect"
                        value={this.state.thirdSelect}
                        onChange={(e) => this.handleChange(e, 'thirdSelect')}
                    />
                </div>
            );
        }
        return null;
    }


    renderWeekdaySelect() {
        if (['Year', 'Week'].includes(this.state.firstSelect)) {
            const weekdayNames: string[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

            const dayOfWeekOptions: Option[] = weekdayNames.map((weekday, index) => ({
                value: index,
                label: weekday,
            }));

            const options: Option[] = [
                {value: '*', label: 'Every day of the week'},
                ...dayOfWeekOptions
            ];

            return (
                <div className={"pt-1 pb-1"}>
                    <SelectField
                        label={this.state.firstSelect === 'Week' ? 'on' : 'and'}
                        items={options}
                        name="fourthSelect"
                        value={this.state.fourthSelect}
                        onChange={(e) => this.handleChange(e, 'fourthSelect')}
                    />
                </div>
            );
        }
        return null;
    }


    renderHourSelect() {
        if (['Year', 'Month', 'Week', 'Day'].includes(this.state.firstSelect)) {
            const options: Option[] = [{ value: '*', label: 'Every hour' }];

            for (let i = 0; i < 24; i++) {
                const hourOption: Option = { value: i, label: i.toString()}
                options.push(hourOption);
            }

            return (
                <div className={"pt-1 pb-1"}>
                    <SelectField
                        label="at (hour)"
                        items={options}
                        name="fifthSelect"
                        value={this.state.fifthSelect}
                        onChange={(e) => this.handleChange(e, 'fifthSelect')}
                    />
                </div>
            );
        }
        return null;
    }

    renderMinuteSelect() {
        if (['Year', 'Month', 'Week', 'Day', 'Hour'].includes(this.state.firstSelect)) {
            const options: Option[] = [{ value: '*', label: 'Every minute' }];

            for (let i = 0; i < 60; i++) {
                const minuteOption: Option = { value: i, label: i.toString()}
                options.push(minuteOption);
            }

            return (
                <div className={"pt-1 pb-1"}>
                    <SelectField
                        label=": (minute)"
                        items={options}
                        name="sixthSelect"
                        value={this.state.sixthSelect}
                        onChange={(e) => this.handleChange(e, 'sixthSelect')}
                    />
                </div>
            );
        }
        return null;
    }


    render() {
        const { firstSelect } = this.state;
        const { cronString } = this.state;

        return (
            <div>
                {this.renderFirstSelect()}
                {firstSelect === 'Year' && this.renderMonthSelect()}
                {['Year', 'Month'].includes(firstSelect) && this.renderDayOfMonthSelect()}
                {['Year', 'Week', 'Month'].includes(firstSelect) && this.renderWeekdaySelect()}
                {['Year', 'Month', 'Week', 'Day'].includes(firstSelect) && this.renderHourSelect()}
                {['Year', 'Month', 'Week', 'Day', 'Hour'].includes(firstSelect) && this.renderMinuteSelect()}
                <div className={"pt-1 pb-1 d-flex justify-content-space-between flex-center"}>
                    <div>
                        <span>Cron String:</span> {cronString}
                    </div>
                    <button className={"btn btn-primary"} type={"button"} onClick={this.handleClear}>Clear</button>
                </div>
            </div>
        );
    }
}

export default CronJobBuilder;
