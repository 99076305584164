import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {reduxForm} from 'redux-form';
import LoadingMessage from '../LoadingMessage/LoadingMessage';
import * as DeviceActions from '../../actions/deviceAction';
import * as GeneralSettingsActions from '../../actions/generalSettingAction';
import * as IncidentActions from '../../actions/incidentActions';

import * as $ from 'jquery';
import {IncidentHistoryTab} from "./IncidentHistoryTab";
import {IncidentGeneralTab} from "./IncidentGeneralTab";
import {IncidentHeaderSelector} from "./IncidentHeaderSelector";

export class IncidentsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            incidents_tab: '',
            items: [],
            loading: false
        };
        this.handleQueryForm = this.handleQueryForm.bind(this);
    }

    async componentDidMount() {
        const queryParams = {
            limit: 200
        };
        this.setState({loading: true});
        await this.props.generalSettingsActions.fetchOrganizationData();
        await this.props.incidentActions.fetchIncidentByClientId({query_params: queryParams}, this.props.generalSettingsReducer.organization.system_of_units);
        this.setState({loading: false});
        if (this.props.location && this.props.location.state && this.props.location.state.incidents_tab === 'pills-history') {
            $('#pills-history-tab').addClass('active show');
            $('#pills-history').addClass('active show');
        } else {
            $('#pills-general-tab').addClass('active show');
            $('#pills-general').addClass('active show');
        }
    }

    handleQueryForm(values, dispatch) {
        dispatch(IncidentActions.fetchIncidentByClientId(values, this.props.generalSettingsReducer.organization.system_of_units));
        dispatch(IncidentActions.updateQueryParams(values));
    }

    render() {
        const {handleSubmit} = this.props;
        let incidentItems = [];
        let i = 0;
        // To Do: this should only be done on changes to incidents
        // (antipattern to do all of this in render method)
        for (let item of this.props.incidentReducer.incidents) {

            let incidentItem = {
                title: item.title || '',
                sensorName: item.metric,
                device_name: item.device_name,
                alertDate: item.timestamp,
                status: item.status,
                notesUrl: "",
                trigger_id: item.trigger_id,
                report_id: item.report_id,
                incident_id: item.incident_id,
                user: item.user,
                key: i.toString()
            };

            incidentItems.push(incidentItem);
            i += 1;
        }

        let sortIncidentItemsByTimestamp = (a, b) => {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.

            if (a.alertDate <= b.alertDate) {
                return 1;
            } else {
                return -1;
            }
        };

        incidentItems.sort(sortIncidentItemsByTimestamp);


        return (
            <main className="app-content">
                <div className="app-title">
                    <div>
                        <h1>Incidents</h1>
                    </div>
                    <IncidentHeaderSelector handleSubmit={handleSubmit(this.handleQueryForm)}/>
                </div>
                <section className="emp_org_settings_sec">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="tab-content mt-4" id="pills-tabContent">
                                {this.state.loading ? <LoadingMessage/> : <IncidentGeneralTab
                                    items={incidentItems}/>}
                                <IncidentHistoryTab items={incidentItems}/>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        device: state.deviceReducer,
        incidentReducer: state.incidentReducer,
        initialValues: {
            query_params: state.incidentReducer.queryParams
        },
        generalSettingsReducer: state.generalSettingReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    deviceActions: bindActionCreators(DeviceActions, dispatch),
    incidentActions: bindActionCreators(IncidentActions, dispatch),
    generalSettingsActions: bindActionCreators(GeneralSettingsActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
    form: 'incidentsDataSearchForm'
})(IncidentsPage));