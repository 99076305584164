export class Probe {
    id;
    device_id;
    organization_id;
    probe_cert_date;
    probe_identifier;
    probe_manufacturer;
    certificate_uri;
    device_name;

    constructor(id, device_id, organization_id, probe_cert_date, probe_identifier, probe_manufacturer,certificate_uri, device_name, status) {
        this.id = id;
        this.device_id = device_id;
        this.organization_id = organization_id;
        this.probe_cert_date = probe_cert_date;
        this.probe_identifier = probe_identifier;
        this.probe_manufacturer = probe_manufacturer;
        this.certificate_uri = certificate_uri;
        this.device_name = device_name;
        this.status = status;
    }

    static fromJson(json) {
        return new Probe(json['id'],
            json['device_id'],
            json['organization_id'],
            json['probe_cert_date'],
            json['probe_identifier'],
            json['probe_manufacturer'],
            json['certificate_uri'],
            json['device_name'],
            json['status']
        )
    }
}