import React from 'react';
import {ReportListView} from "../Reports/ReportListView";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router';
import * as SchemaRegistryActions from '../../actions/schemaRegistryAction'

class SchemaRegistryPage extends React.Component {
    constructor(props) {
        super(props);
        this.columns = [
            {fieldName: 'title', show: true, shortTitle: "Title", type: "string"},
            {fieldName: 'description', show: true, shortTitle: "Description", type: "string"},
            {fieldName: 'owner', show: true, shortTitle: "Owner", type: "string"}
        ]
    }


    componentWillMount() {
        this.props.schemaRegistryActions.loadSchemas()
    }

    render() {
        return (
            <main className="app-content">
                <div className="app-title border-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="active-device-btn text-right">
                                    <Link to="/data/schemas/create">
                                    <button className="btn btn-primary">Create Source</button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <ReportListView columns={this.columns.map(reportListViewAdapter)}
                                                data={this.props.schemas}/>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }
}

const styleCell = (label, classes) => {
    if (classes)
        return <div className={classes}>{label}</div>
    return <div>{label}</div>
}

function reportListViewAdapter(column) {

    return {
        accessor: column.fieldName,
        Header: () => styleCell(column.shortTitle, 'text-center'),
        show: column.show,
        Cell: (row) => styleCell(row.value, 'text-center')
    }
}

const mapStateToProps = (state) => {
    return {
        schemas: state.schemaRegistryReducer.schemas
    }
}

const mapDispatchToProps = (dispatch) => ({
    schemaRegistryActions: bindActionCreators(SchemaRegistryActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SchemaRegistryPage);