import React from 'react';
import {RadioBtn} from '../RadioBtn/RadioBtn';

export class AlertLevel extends React.Component {
    render() {
        let {level} = this.props;
        return <div className="inline_radio_btn">
            <div className="custom-control custom-radio">
                <RadioBtn
                    name={`level`}
                    type="radio"
                    value="alert"
                    label="Alert"
                    labelClass="custom-control-label"
                    id={`level_1`}
                    checked={level==="alert" ? true : false}
                    htmlFor={`level_1`}
                />
            </div>
            <div className="custom-control custom-radio">
                <RadioBtn
                    name={`level`}
                    type="radio"
                    value="warning"
                    label="Warning"
                    labelClass="custom-control-label"
                    id={`level_2`}
                    checked={level==="warning" ? true : false}
                    htmlFor={`level_2`}
                />
            </div>
        </div>;
    }
}