import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as DeviceActions from '../../actions/deviceAction';
import * as AuthActions from '../../actions/authActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import {browserHistory} from "react-router";
import {ApiDevice} from "../../api/model/Device";
import {DevicesPage} from "../../components/Devices/DevicesPage";

interface IProps {
    auth: any;
    authActions: typeof AuthActions;
    deviceActions: typeof DeviceActions;
    devices: ApiDevice[];
}

interface IState {
    loading: boolean;
}

function debounce(callback: (...args: any[]) => void, delay: number) {
    let timeout: NodeJS.Timeout;

    return function(...args: any[]) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            callback(...args);
        }, delay);
    }
}

class DevicesContainer extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
        }
        this.debounceSearch = debounce(this.onSearchInput.bind(this), 600);
    }

    debounceSearch: (...args: any[]) => void;

    async componentDidMount() {
        this.setState({loading: true})
        this.props.deviceActions.searchDevices({name: '', expand: []});
        this.setState({loading: false})
    }

    onItemClick = (item: any) => { // Replace 'any' with the proper type for 'item'
        browserHistory.push(`/devices/${item.identity}`);
    }

    onSearchInput = async (value: string) => {
        this.props.deviceActions.searchDevices({name: value, expand: ["device_type"]})
    }

    render() {
        const {auth, authActions, devices} = this.props;
        const {loading} = this.state;

        return (
            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <DevicesPage devices={devices}
                               onItemClick={this.onItemClick}
                               onSearchInput={this.debounceSearch}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    devices: state.deviceReducer.devices
});

const mapDispatchToProps = (dispatch) => ({
    deviceActions: bindActionCreators(DeviceActions, dispatch),
    authActions: bindActionCreators(AuthActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DevicesContainer);
