import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as DeviceActions from '../../actions/deviceAction';
import {Field} from "redux-form";
import {DeviceItemField} from "../SearchableDropDownField/SearchableDropDownField";
import {AlertConditionsSelector} from "./AlertConditionsSelector";
import * as MetricActions from "../../actions/metricAction";
import DropdownSelect from "../Dropdown/Dropdown";
import PropTypes from 'prop-types';
import {NewSearchableDropDownField} from "../SearchableDropDownField/NewSearchableDropDownField";
import GroupDropDownField from "../SearchInput/GroupDropDownField";
import GroupAndSensorDeviceActiveDropDownField from "../SearchInput/GroupAndSensorDeviceActiveDropDownField";

const datapointInputField = ({children, input, meta: {touched, error}}) => {
    let fieldStyle = "";
    let errorMessage = "";
    if (touched && error) {
        fieldStyle = "input_error"
        errorMessage = <div className="form-group__error">{error}</div>
    }
    return (
        <React.Fragment>
            <div>
                <input type="number" {...input} id="trigger_data_points"
                       className={`form-control m-sm-1 input_modifier input_w_5o ${fieldStyle}`}
                       min="1" max="60" step="1"/>
                {children}
            </div>
            <div>
                {errorMessage}
            </div>
        </React.Fragment>
    )
};

const titleField = ({input, meta: {touched, error}}) => {
    let fieldStyle = "";
    let errorMessage = ""
    if (touched && error) {
        fieldStyle = "input_error"
        errorMessage = <span className="form-group__error">{error}</span>
    }
    return (
        <div>
            <input type="text" {...input} placeholder={"Enter Title Here"}
                   className={`col-sm-12 ${fieldStyle}`}/>
            {errorMessage}
        </div>
    )
};

export class GenericMetricCard extends React.Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.metricActions.listMetrics();
    }

    renderInputField = ({children, input, size, meta: {touched, error}}) => {
        let fieldStyle = "";
        let errorMessage = "";
        if (touched && error) {
            fieldStyle = "input_error"
            errorMessage = error;
        }
        size = size || 10;
        return (
            <React.Fragment>
                <div>
                    <input type="text" {...input} size={size}
                           className={`form-control textarea_modifier shadow_select_hover ${fieldStyle}`}
                    />
                    {children}
                </div>
                <div className="form-group__error">{errorMessage}</div>
            </React.Fragment>
        )
    };

    renderMetricDropdown() {
        let label = this.props.field || "Choose a Metric";
        return (
            <Field name='field' component={
                (props) => {
                    return (<DropdownSelect
                        onItemClickListener={(e) => {
                            const value = e.target.getAttribute("value");
                            props.input.onChange(value)
                            label = value;
                        }}
                        items={this.props.metricReducer.metrics.items}
                        label={props.input.value || label}
                        {...props}
                    />)
                }
            }/>
        )
    }

    renderPeriodDropdown() {
        const periods = {
            '5 minutes': 300,
            '1 hour': 3600,
            '1 day': 3600 * 12
        }

        const findPeriod = (value) => {
            return Object.keys(periods).find((key) => {
                return periods[key] === value
            })
        }

        let label = this.props.period ? findPeriod(periods) : Object.keys(periods)[0];

        return (
            <Field name='period' component={
                (props) => {
                    const currentInputLabel = props.input.value ? findPeriod(props.input.value) : label;
                    return (
                        <DropdownSelect
                            onItemClickListener={(e) => {
                                const value = e.target.getAttribute("value");
                                props.input.onChange(periods[value]);
                                label = value;
                            }}
                            items={Object.keys(periods)}
                            label={currentInputLabel}
                            {...props}
                        />
                    )
                }
            }/>
        )
    }

    render() {
        return (
            <div className="card shadow emp-form-card mb-3 emp_create_report_card">
                <div className="card-header emp_create_report_header p-4">
                    <label className="label_modifier col-sm-12 pl-0">Alert Title</label>
                    <Field name='title' component={titleField}/>
                </div>
                <div className="card-body emp_create_report_body pb-2 emp_create_notify_body">
                    <div className="row emp_v_pipe_box">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="form-group">
                                <label className="label_modifier col-sm-12 pl-0">Select a Metric</label>
                                {this.renderMetricDropdown()}
                                {/*<Field*/}
                                {/*    name='field'*/}
                                {/*    component={() => {*/}
                                {/*        return <span*/}
                                {/*            className="col-sm-11">{formatAlertType(this.props.alertType)}</span>*/}
                                {/*    }}*/}
                                {/*/>*/}

                                <Field
                                    name='schema'
                                    value="metric"
                                    component={({input}) => {
                                        input.onChange('metric');
                                        return <input type='hidden' value={'metric'}/>
                                    }}

                                />
                            </div>

                            <div className="form-group">
                                <AlertConditionsSelector
                                    title={this.props.alertTitle}
                                    conditionUnits={this.props.conditionUnits}/>
                            </div>
                            <Field
                                name='level'
                                value="alert"
                                component={({input}) => {
                                    input.onChange('alert');
                                    return <input type='hidden' value={'alert'}/>
                                }}

                            />
                            {/*<div className="form-group">*/}
                            {/*    <AlertLevel level={this.props.level}/>*/}
                            {/*</div>*/}

                            <div className="form-group">
                                <label className="label_modifier col-sm-12 pl-0">Select a Period</label>
                                {this.renderPeriodDropdown()}
                            </div>
                            <div className="form-group">
                                <label className="label_modifier col-sm-12 pl-0">Datapoints to Alarm</label>
                                <div className="form-inline">
                                    <span>Send only if it holds for </span>
                                    <Field
                                        name={`trigger_data_points`}
                                        component={datapointInputField}>
                                        <label className="label-inline">datapoints</label>
                                    </Field>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="label_modifier col-sm-12 pl-0">Description</label>
                                <Field
                                    name={`description`}
                                    component={this.renderInputField}
                                    size={64}
                                />
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                            <div className="form-group">
                                <NewSearchableDropDownField
                                    fieldName={'devices'}
                                    itemFieldComponent={DeviceItemField}
                                    title={'This alert applies to'}
                                    placeholder="Type Sensor or Group Name"
                                    isDisabled={false}
                                    render={({
                                                 placeholder,
                                                 isDisabled,
                                                 handleChange,
                                                 classNames
                                             }) =>
                                        <GroupAndSensorDeviceActiveDropDownField
                                            placeholder={placeholder}
                                            isDisabled={isDisabled}
                                            handleChange={handleChange}
                                            classNames={classNames}
                                            selections={this.props.devices}
                                            shouldRenderSuggestions={()=>true}
                                        />
                                    }
                                />
                            </div>
                            <div className="form-group">
                                <NewSearchableDropDownField
                                    fieldName={'groups'}
                                    itemFieldComponent={DeviceItemField}
                                    title={'Notify these groups'}
                                    placeholder="Type Sensor or Group Name"
                                    isDisabled={false}
                                    render={({
                                                 placeholder,
                                                 isDisabled,
                                                 handleChange,
                                                 classNames
                                             }) =>
                                        <GroupDropDownField
                                            placeholder={placeholder}
                                            isDisabled={isDisabled}
                                            handleChange={handleChange}
                                            classNames={classNames}
                                            selections={this.props.groups}
                                            shouldRenderSuggestions={()=>true}
                                        />
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

GenericMetricCard.propTypes = {
    title: PropTypes.string,
    field: PropTypes.string,
    alertTitle: PropTypes.string,
    level: PropTypes.string,
    conditionUnits: PropTypes.string,
    alertType: PropTypes.string,
    period: PropTypes.number,
    isNewAlert: PropTypes.bool
}


const mapStateToProps = (state) => {

    return ({
        device: state.deviceReducer,
        metricReducer: state.metricReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    deviceActions: bindActionCreators(DeviceActions, dispatch),
    metricActions: bindActionCreators(MetricActions, dispatch),
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GenericMetricCard));