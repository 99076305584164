import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import ForgotPasswordPage from '../../components/ForgotPasswordPage/ForgotPasswordPage';
import { withRouter } from 'react-router';

//import './login.scss';

export const ForgotPassword = ({ authActions }) => (

    <div className="my-login-page">
        <ForgotPasswordPage />
    </div>
);

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch)
});

export default withRouter(connect(null, mapDispatchToProps)(ForgotPassword));