import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import {Probe} from "./model/Probe";


export class ProbesApi {
    async getProbe(probeId) {
        try {
            let response = await axios.get(`${BASE_URL()}/api/v1/probes/${probeId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': storage.get('token')
                }
            });
            return Probe.fromJson(response.data);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async queryProbes() {
        try {
            let response = await axios.get(`${BASE_URL()}/api/v1/probes`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': storage.get('token')
                }
            });
            return response.data.map(item => Probe.fromJson(item));
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}