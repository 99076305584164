import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {CSVLink} from 'react-csv';
import { utils, writeFile } from 'xlsx';

import {DateTimeField} from '../DateTimeField/NewDateTimeField';
import {reduxForm} from 'redux-form';
import * as GeneralSettingsActions from '../../actions/generalSettingAction';
import * as ReportActions from '../../actions/reportAction';
import {PeriodIntervalSelector} from "../../PeriodIntervalSelector/PeriodIntervalSelector";
import LoadingMessage from '../LoadingMessage/LoadingMessage';
import {createFormFilters} from "../FilterForm/createFormFilters";
import FilterGroup from "../FilterForm/FilterGroup";
import * as Moment from 'moment';
import {formatTemperature, formatTimestampDateTime} from '../../utils/formatHelper';
import ReportPdf from "./pdf/ReportPdf";
import ReportColumnSelector from "./ReportColumnSelector";
import {ReportListView} from "./ReportListView";
import {formValueSelector} from 'redux-form';
import {getDatedFileName, validateDateRange} from '../../utils/utils';
import {required, validateDateTimeFormat} from "../../utils/validateFunctions";
import FilterForm from "../FilterForm/FilterForm";
import {filterConstants} from '../../constants/filterform.constants';
import {operator} from "../FilterForm/operator";
import {Feature} from "@empiric/empiric-flagged-react";
import {TextField} from "../../ui/Input/TextField";

const FIELD_ARR_NAME = filterConstants.QUERY_BUILDER_ARR;

const dataSchemas = {
    sensor: [ // for csv generation
        {fieldName: 'timestamp', show: false, shortTitle: "Timestamp", type: "string"},
        {fieldName: 'name', show: false, shortTitle: "Device Name", type: "string"},  //Sensor
        // {fieldName: 'node_address', show: false, shortTitle: "Node Address", type: "string"},  //Sensor
        {fieldName: 'probe_temperature_min', show: false, shortTitle: "Probe Temperature Min", type: "number"},
        {fieldName: 'probe_temperature_max', show: false, shortTitle: "Probe Temperature Max", type: "number"},
        {fieldName: 'probe_temperature', show: false, shortTitle: "Probe Temperature", type: "number"},
        {fieldName: 'humidity_max', show: false, shortTitle: "Humidity Max", type: "string"},
        {fieldName: 'humidity_min', show: false, shortTitle: "Humidity Min", type: "string"},
        {fieldName: 'humidity', show: false, shortTitle: "Humidity", type: "string"},
        {fieldName: 'ambient_temperature', show: false, shortTitle: "Ambient Temperature", type: "number"},
        {
            fieldName: 'ambient_temperature_max',
            show: false,
            shortTitle: "Ambient Temperature Max",
            type: "number"
        },
        {
            fieldName: 'ambient_temperature_min',
            show: false,
            shortTitle: "Ambient Temperature Min",
            type: "number"
        },
        {fieldName: 'battery_voltage', show: false, shortTitle: "Battery Voltage", type: "number"},
        {fieldName: 'battery_voltage_max', show: false, shortTitle: "Battery Voltage Max", type: "number"},
        {fieldName: 'battery_voltage_min', show: false, shortTitle: "Battery Voltage Min", type: "number"},
        {fieldName: 'battery_percentage', show: false, shortTitle: "Battery Percentage", type: "string"},
        {fieldName: 'sensor_id', show: false, shortTitle: "Sensor Id", type: "string"},
        // {fieldName: 'client_id', show: false, shortTitle: "Client Id", type: "string"},
        {fieldName: 'product_id', show: false, shortTitle: "Sensor Type", type: "string"},
        {fieldName: 'groups', show: false, shortTitle: "Groups", type: "string"},
    ],

    // trackonomy: [
    //     {fieldName: 'timestamp', show: false, shortTitle: "Timestamp", type: "string"},
    //     {fieldName: 'name', show: false, shortTitle: "Device Name", type: "string"},  //Sensor
    //     {fieldName: 'action', show: false, shortTitle: "Action", type: "string"},  //Sensor
    //     {fieldName: 'location', show: false, shortTitle: "Location", type: "string"},  //Sensor
    // ],
    //
    // gatewayAssociation: [
    //     {fieldName: 'timestamp', show: false, shortTitle: "Timestamp", type: "string"},
    //     {fieldName: 'sensor', show: false, shortTitle: "Device Name", type: "string"},  //Sensor
    //     {fieldName: 'gateway', show: false, shortTitle: "Gateway Name", type: "string"},  //Sensor
    // ],

    sensorAssociation: [
        {fieldName: 'timestamp', show: false, shortTitle: "Timestamp", type: "string"},
        {fieldName: 'sensorId', show: false, shortTitle: "Device Name", type: "string"},  //Sensor
        {fieldName: 'gatewayName', show: false, shortTitle: "Gateway Name", type: "string"},  //Sensor
        {fieldName: 'status', show: false, shortTitle: "Status", type: "string"},  //Sensor
        {fieldName: 'lastConnected', show: false, shortTitle: "Last Connected", type: "string"},  //Sensor
    ],

    incident: [
        {fieldName: 'title', show: false, shortTitle: "Title", type: "string"},  //Sensor
        {fieldName: 'description', show: false, shortTitle: "Description", type: "string"},  //Sensor
        {fieldName: 'incident_timestamp', show: false, shortTitle: "Incident Timestamp", type: "string"},  //Sensor
        {fieldName: 'updated_at', show: false, shortTitle: "Updated At", type: "string"},  //Sensor
        {fieldName: 'resolver', show: false, shortTitle: "Resolver", type: "string"},  //Sensor
        {fieldName: 'location', show: false, shortTitle: "Location", type: "string"},  //Sensor
        {fieldName: 'reasons', show: false, shortTitle: "Reasons", type: "string"},  //Sensor
    ],
    metric: [
        {fieldName: 'timestamp', show: false, shortTitle: "Timestamp", type: "string"},
        {fieldName: 'value', show: false, shortTitle: "Value", type: "number"},
        {fieldName: 'instance_id', show: false, shortTitle: "Instance Id", type: "string"},
        {fieldName: 'metric', show: false, shortTitle: "Metric", type: "string"},
        {fieldName: 'unit', show: false, shortTitle: "Unit", type: "string"}
    ]
}


class Dimension {
    type;
    name;
    title;
    shortTitle;


    constructor(name, title, shortTitle, type) {
        this.name = name;
        this.title = title;
        this.shortTitle = shortTitle;
        this.type = type;
    }
}

const getInputComponent = (inputProps, label) => <TextField label={label} {...inputProps}/>

export class CreateReportPage extends React.Component {
    constructor(props) {
        super(props);
        this.onColumnsSelected = this.onColumnsSelected.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
        this.selectSourceBtnRef = React.createRef();
        this.state = {
            filterFields: [],
            availableDimensions: [],
            loading: false,
            columns: this.loadSchema('sensor'),
            schema: null
        };
    }

    loadSchema(schema) {
        return dataSchemas[schema]
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.reportReducer.updated) {
            this.setState(state => {
                return {
                    loading: false,
                    deviceData: nextProps.reportReducer.items
                }
            });
            nextProps.reportReducer.updated = false;
        }
    }

    async componentDidMount() {
        await this.props.generalSettingsActions.fetchOrganizationData();

        const {columns} = this.state;
        const availableDimensions = columns.map(this.columnToDimensionAdapter);
        this.setState({...this.setState, columns, availableDimensions});
    }

    columnToDimensionAdapter = (col) => {
        return new Dimension(col.fieldName, "", col.shortTitle, col.type);
    }

    onColumnsSelected(newColumns) {
        this.setState({columns: newColumns});
    }

    onColumnSelected(colIndex) {
        this.setState({
            ...this.state,
            columns: this.state.columns.map((col, index) => {
                if (index === colIndex) {
                    col.show = !col.show;
                }

                return col;
            })
        })
    }

    getCSVData() {
        const temperatureFields = [
            'probe_temperature_min', 'probe_temperature_max', 'probe_temperature',
            'ambient_temperature_min', 'ambient_temperature_max', 'ambient_temperature'
        ]
        const timestampFields = ['timestamp']
        // template contains the columns that are needed in the csv
        let template = {}
        for (let col of this.state.columns)
            if (col.show) template[col.fieldName] = '';

        let data = this.state.deviceData;
        // for each device data, extract the columns based on the template
        let {queryBuilder} = this.props;
        if (data && queryBuilder && queryBuilder.length && Object.keys(queryBuilder[0]).length >= 3) {
            data = createFormFilters(queryBuilder)
                .reduce((i, f) => i.filter(f), data);
        }


        if (!data) {
            return [];
        }

        return data.map((d) => {
            let row = {...template};
            Object.keys(d).forEach(function (key) {
                if (key in row) {
                    row[key] = d[key];
                    if (temperatureFields.includes(key))
                        row[key] = formatTemperature(row[key])
                    else if (timestampFields.includes(key))
                        row[key] = formatTimestampDateTime(row[key])
                }
            });

            return row;
        });
    }

    onSearchSubmit(values, dispatch) {
        values['schema'] = this.state.schema;
        values['format'] = this.props.generalSettingsReducer.organization.system_of_units;

        this.setState({loading: true});
        this.props.reportActions.loadData(values);
    }

    render() {
        if (this.state.loading)
            return (<LoadingMessage/>)
        const {handleSubmit} = this.props;
        return (
            <main className="app-content">
                <div className="app-title border-bottom">
                    <div>
                        <h1>Report</h1>
                    </div>
                    {this.renderDeviceDataForm(handleSubmit)}
                </div>
                {this.renderDeviceDataTable()}
            </main>
        );
    }

    generatePdf = () => {
        const pdfReport = new ReportPdf(getDatedFileName({extension: "pdf"}));
        const orgAddress = this.props.settings.organization.address;
        const address = {
            person: this.props.auth.user.first_name + ' ' + this.props.auth.user.last_name,
            address: orgAddress ? orgAddress.address1 : '',
            city_state_zip: this._format_city_state_zip_line(this.props.settings.organization.address || ''),
            email: this.props.auth.user.email,
            phone: this.props.auth.user.phone_number,

        }

        let data = {
            columns: this.state.columns.filter(column => column.show).map(column => column.shortTitle),
            items: this.getCSVData()
        }

        let logoUrl = this.props.settings.organization.logo.url ? this.props.settings.organization.logo.url : this.props.settings.organization.default_logo.url
        pdfReport.generate(data, address, logoUrl);
    }

    _format_city_state_zip_line(address) {
        if (!address) return "";
        let city = address.city || ''
        let state = address.state || '';
        let zip = address.zip || '';

        return `${city}, ${state}, ${zip}`
    }

    onSchemaSelected(schemaName) {
        this.selectSourceBtnRef.current.text = schemaName;
        this.setState({
            ...this.state,
            columns: this.loadSchema(schemaName),
            availableDimensions: this.loadSchema(schemaName).map(this.columnToDimensionAdapter),
            schema: schemaName
        });
    }

    generateXLSX(){
        const data = this.getCSVData();
        const ws = utils.json_to_sheet(data);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, "Data");
        const fileName = getDatedFileName({extension:"xlsx"});
        writeFile(wb, fileName);
    }

    renderDeviceDataForm(handleSubmit) {
        return <form onSubmit={handleSubmit(this.onSearchSubmit)}>
            <ul className="app-breadcrumb breadcrumb">
                {/*<li className="emp-title-item em-title-checkbox">*/}
                {/*<div className="custom-control custom-checkbox custom-checkbox-primary">*/}
                {/*<input type="checkbox" className="custom-control-input" id="customCheck1"/>*/}
                {/*<label className="custom-control-label" htmlFor="customCheck1">Temperature</label>*/}
                {/*</div>*/}
                {/*</li>*/}
                {/*<li className="emp-title-item em-title-checkbox">*/}
                {/*<div className="custom-control custom-checkbox custom-checkbox-violet">*/}
                {/*<input type="checkbox" className="custom-control-input" id="customCheck2"/>*/}
                {/*<label className="custom-control-label" htmlFor="customCheck2">Humdity </label>*/}
                {/*</div>*/}
                {/*</li>*/}
                <li className="emp-title-item">
                    <div className="dropdown">
                        <a className="btn btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                           ref={this.selectSourceBtnRef}>Select Source</a>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <a className="dropdown-item" href='#'
                               onClick={() => this.onSchemaSelected('sensor')}>Sensors</a>
                            {/*<a className="dropdown-item" href='#' onClick={() => this.onSchemaSelected('trackonomy')}>Trackonomy</a>*/}
                            <Feature name="assetsExperiment"><a className="dropdown-item" href='#' onClick={() => this.onSchemaSelected('sensorAssociation')}>Sensor Association</a></Feature>
                            <a className="dropdown-item" href='#'
                               onClick={() => this.onSchemaSelected('incident')}>Incident</a>
                            <a className="dropdown-item" href='#'
                               onClick={() => this.onSchemaSelected('metric')}>Metric</a>
                        </div>
                    </div>

                </li>
                <li className="emp-title-item">
                    <a className="color-white btn btn-primary" data-toggle="modal" data-target="#select_columns">
                        Select Columns
                    </a>
                </li>
                <li className="emp-title-item">
                    <FilterForm dimensions={this.state.availableDimensions}/>
                </li>
                <li className="emp-title-item">
                    <div className="dropdown">
                        <a className="btn btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                           data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export As</a>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <a className="dropdown-item" href='#' onClick={() => this.generateXLSX()}>XLSX (Excel)</a>
                            <a className="dropdown-item" href='#' onClick={() => this.generatePdf()}>PDF</a>
                            <CSVLink  filename={getDatedFileName({extension: "csv"})} data={this.getCSVData()}
                                      className="dropdown-item">
                                CSV (Raw Data)
                            </CSVLink>
                        </div>
                    </div>

                </li>
                <li className="emp-title-item">
                    <button className="btn btn-primary submit_button">Run</button>
                </li>
                <li className="emp-title-item">
                    {this.renderPeriodIntervalSelector()}
                </li>
                <li className="emp-title-item">
                    <span className="v-pipe-top"></span>
                </li>
                <li className="emp-title-item env-date date-field">
                    <DateTimeField
                        type="text"
                        name="start_date"
                        placeholder="Start Date"
                        className="form-control input_modifier border-0 app-title-shadow datepicker--input"
                        parentClass="app-title-shadow"
                        renderInput={(props) => getInputComponent(props, "Start Date")}
                        validate={[required, validateDateTimeFormat]}
                    />
                </li>
                <li className="emp-title-item">
                    <div className="app-title-right-widget-small">
                        <div className="info border-info">
                            <hr className="border-top-info"/>
                        </div>
                    </div>
                </li>
                <li className="emp-title-item env-date date-field">
                    <DateTimeField
                        type="text"
                        name="end_date"
                        placeholder="End Date"
                        className="form-control input_modifier border-0 app-title-shadow datepicker--input"
                        parentClass="app-title-shadow"
                        renderInput={(props) => getInputComponent(props, "End Date")}
                        validate={[required, validateDateTimeFormat]}
                    />
                </li>
                <li className="emp-title-item">
                    <div className="app-title-right-widget-small">
                        <div className="info title-icn-calender bg-transparent">
                            <img src={require("../../styles/images/icon/icn_calender.png")}
                                 alt="Calender Icon"/>
                        </div>
                    </div>
                </li>
            </ul>
            {this.props.queryBuilder && this.props.queryBuilder.map((field, index) => {
                return (
                    <FilterGroup
                        dimensions={this.state.availableDimensions}
                        operators={Object.values(operator)}
                        key={index}
                        filterGroup={`${FIELD_ARR_NAME}[${index}]`}
                        index={index}
                        fields={this.props.filterFields}
                    />
                )
            })}
        </form>;
    }

    renderPeriodIntervalSelector() {
        return (
            <PeriodIntervalSelector fieldName="period"/>);
    }

    renderDeviceDataTable() {

        return (
            <section className="emp_org_settings_sec emp_report_sec">
                <div className="row">
                    <div className="col">
                        <ReportColumnSelector
                            onColumnSelected={this.onColumnSelected.bind(this)}
                            columns={this.state.columns.map(reportColumnAdapter)}
                        />
                        {this.state.deviceData ? <ReportListView columns={this.state.columns.map(reportListViewAdapter)}
                                                                 data={this.createListItems()}
                                                                 onColumnsSelected={this.onColumnsSelected}/> : <div/>}
                    </div>
                </div>
            </section>);
    }

    createListItems() {
        this.state.deviceData.sort((a, b) => {
            return new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        });

        const items = this.state.deviceData.map(item => Object.assign({}, item));
        // TODO: This is a hack to format the timestamps. This should utilize the schema to read the
        // format parameter and properly format
        items.map((item) => {
            item.timestamp = formatTimestampDateTime(item.timestamp);

            if (item.updated_at) {
                item.updated_at = formatTimestampDateTime(item.updated_at);
            }

            if (item.incident_timestamp) {
                item.incident_timestamp = formatTimestampDateTime(item.incident_timestamp);
            }
            
            return item;
        })
        const {queryBuilder} = this.props;

        if (!queryBuilder || !queryBuilder.length) return items;

        if (Object.keys(queryBuilder[0]).length < 3) return items;

        return createFormFilters(queryBuilder)
            .reduce((i, f) => i.filter(f), items);
    }


}


function reportColumnAdapter(column) {
    return {
        columnName: column.shortTitle,
        show: column.show
    }
}


const styleCell = (label, classes) => {
    if (classes)
        return <div className={classes}>{label}</div>
    return <div>{label}</div>
}

function reportListViewAdapter(column) {

    return {
        accessor: column.fieldName,
        Header: () => styleCell(column.shortTitle, 'text-center p-1'),
        show: column.show,
        Cell: (row) => styleCell(row.value, 'text-center'),
        style: {'whiteSpace': 'normal'} // react-table uses nowrap as default
    }
}

const selector = formValueSelector('createReportSearchForm');

const mapStateToProps = (state) => {
    const queryBuilder = selector(state, FIELD_ARR_NAME);

    let format = 'MM-DD-YYYY hh:mm A';
    let start_date = Moment.utc(new Date((new Date()).valueOf() - 1000 * 3600 * 24).toISOString()).format(format);
    let end_date = Moment.utc(new Date((new Date()).valueOf() + 1000 * 3600 * 24).toISOString()).format(format);

    return ({
        initialValues: {
            period: '1h',
            start_date: start_date,
            end_date: end_date
        },
        reportReducer: state.reportReducer,
        device: state.deviceReducer,
        settings: state.generalSettingReducer,
        auth: state.authReducer,
        queryBuilder,
        filterFields: state.filterFormReducer,
        generalSettingsReducer: state.generalSettingReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    reportActions: bindActionCreators(ReportActions, dispatch),
    generalSettingsActions: bindActionCreators(GeneralSettingsActions, dispatch)
});

export default connect
(mapStateToProps, mapDispatchToProps)
(reduxForm({form: 'createReportSearchForm', validate: validateDateRange})
(CreateReportPage));

