import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import TemperatureTelemetry from "./model/TemperatureTelemetry";

export const getTelemetryData = (deviceId, data) => {
    return axios.get(`${BASE_URL()}/api/v1/telemetry/${deviceId}`, {
        params: data,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data.items.map(item => TemperatureTelemetry.fromJson(item, data.format)));
};

export const getBatchTelemetryData = (data) => {
    return axios.get(`${BASE_URL()}/api/v1/telemetry`, {
        params: data,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return {
            'items': response.data.items.map(item => TemperatureTelemetry.fromJson(item, data.format)),
        }
    });
};
