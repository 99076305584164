import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {browserHistory} from 'react-router';
import * as AuthActions from '../../actions/authActions';
import Header from '../../components/Header/Header';
import { withRouter } from 'react-router';
import GroupForm from '../../components/GroupForm/GroupForm'
import {Sidebar} from '../../components/Sidebar/Sidebar';
import * as GroupActions from '../../actions/groupAction';
import LoadingMessage from '../../components/LoadingMessage/LoadingMessage';


export class EditGroupFormContainer extends React.Component {
    constructor(props){
        super(props);
        this.state={
            update: true,
            loading: false
        }
        this.onUpdateGroup = this.onUpdateGroup.bind(this)
    }

    onUpdateGroup(values) {
        this.props.groupActions.updateGroup(values.id,{group: values});
        browserHistory.push('/groups_devices');
    }

    async componentDidMount() {
        this.setState({loading: true});
        try{
            await this.props.groupActions.fetchGroup(this.props.params['id']);
        } catch (e){
            console.log(e)
        }
        this.setState({loading: false});
    }

    render() {
        const {authActions, auth,params } = this.props;
        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                    />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"/>
                {this.state.loading ? <LoadingMessage/> : <GroupForm
                    params={params} onSubmitGroup={this.onUpdateGroup} formTitle="Update Group" submitLabel="Update Group" group={this.props.groupReducer.group}/>
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.authReducer,
    groupReducer: state.groupReducer
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    groupActions: bindActionCreators(GroupActions, dispatch)
});


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(EditGroupFormContainer));