import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { withRouter, browserHistory } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import * as CustomDashboardActions from "../../actions/customDashboardAction";
import * as UserActions from '../../actions/userAction';
import * as MetricActions from "../../actions/metricAction";
import * as SensorActions from "../../actions/sensorAction";
import {ApiDevice} from "../../api/model/Device";
import {GroupModel} from "../../api/model/GroupModel";
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import DashboardWidgetForm from "../../components/Environment/DashboardWidgetForm";
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import {DASHBOARD_COLORS} from "../../config";

import {WidgetModel, ChartMetricModel} from "../../api/model/CustomDashboard";
import * as AuthActions from '../../actions/authActions';

interface IProps {
    auth: any;
    userReducer: any;
    customDashboardReducer: any;
    sensorReducer: any;
    authActions: any;
    userActions: any;
    customDashboardActions: any;
    metricActions: any;
    SensorActions: any;
    params: {id: string};
    location: {query: {id: string}};
}

interface IState {
    devices: ApiDevice[];
    groups: GroupModel[];
    widget: {id, label, widget_type} | null;
    submitButtonLabel: string;
    loading: boolean;
}


export class CreateWidgetFormContainer extends React.Component<IProps,IState> {

    constructor(props) {
        super(props);
        this.state = {
            devices: [],
            groups: [],
            widget: null,
            submitButtonLabel: "Add Widget to Dashboard",
            loading: true
        }
    }

    async componentDidMount(){

        await Promise.all([
            this.props.userActions.getMemberGroups(),
            this.props.metricActions.listMetrics(),
            this.props.SensorActions.getAllSensors({status: 'active'})
        ]);
    
        const {groups} = this.props.userReducer;
        const devicesObj: {[key:string]: boolean} = {};
        const devices: ApiDevice[] = [];
        groups.forEach(group => {
            this.getDevicesForGroup(group.id).forEach((device) => {
                if (!devicesObj.hasOwnProperty(device.mac_address)) {
                    devicesObj[device.mac_address] = true;
                    devices.push(device);
                }
            });
        });

        if(!this.props.customDashboardReducer.selectedDashboard.hasOwnProperty("id")){
            const dashboardId = this.props.params.id;
            try{
                await this.props.customDashboardActions.getCustomDashboard(dashboardId);
            }
            catch(e){
                console.log(e);
                return browserHistory.push("/environment/custom_dashboard");
            }
        }

        const randomIndex: number = Math.floor(Math.random() * DASHBOARD_COLORS.length);
        const newMetric: ChartMetricModel = new ChartMetricModel("", "", DASHBOARD_COLORS[randomIndex], "");
        const properties = {metrics: [newMetric.toCustomJson()]};

        const widget = {
            id: uuidv4(),
            label: "",
            widget_type: "line",
            properties: properties
        };

        this.setState({
            ...this.state,
            devices: devices,
            groups: groups,
            loading: false,
            widget: widget,
        });
    }

    getDevicesForGroup(groupId) {
        const {sensorList} = this.props.sensorReducer
        if (!sensorList) {
            return [];
        }
        const tempSensorList = [...sensorList];
        tempSensorList.forEach(sensor => {
            const groups = sensor.group_items.map(group => group.group_id);
            sensor["groups"] = groups;
        })
        
        return tempSensorList.filter(sensor => sensor.groups.includes(groupId))
    }

    async onSubmit(values, dispatch) {
        const newSelectedDashboard = this.props.customDashboardReducer.selectedDashboard;
        let newWidget: WidgetModel = WidgetModel.convertToWidgetModel({...values});
        let newWidgets: WidgetModel[] = newSelectedDashboard.widgets;

        newWidgets.push(newWidget);
        newSelectedDashboard.widgets = newWidgets;

        try{
            this.setState({...this.state, loading: true});
            await this.props.customDashboardActions.updateCustomDashboard(newSelectedDashboard);
            this.setState({...this.state, loading: false});
        }
        catch(e){
            console.log(e);
            return browserHistory.push("/environment/custom_dashboard");
        }
        const dashboardId = this.props.params.id
        browserHistory.push(`/environment/custom_dashboard/${dashboardId}`);
    }

    render() {
        const {authActions, auth } = this.props;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                    />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {this.state.loading ? <LoadingMessage/> :
                    <DashboardWidgetForm
                        devices={this.state.devices}
                        onWidgetSubmit={this.onSubmit.bind(this)}
                        groups={this.state.groups}
                        submitButtonLabel={this.state.submitButtonLabel}
                        dashboardId={this.props.params.id}
                        initialValues={this.state.widget}
                        {...this.state.widget}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.authReducer,
        userReducer: state.userReducer,
        customDashboardReducer: state.customDashboardReducer,
        sensorReducer: state.sensorReducer
    }
};

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
    customDashboardActions: bindActionCreators(CustomDashboardActions, dispatch),
    metricActions: bindActionCreators(MetricActions, dispatch),
    SensorActions: bindActionCreators(SensorActions, dispatch) 
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(CreateWidgetFormContainer));