import React from "react";
import PropTypes from "prop-types";
import {FieldArray, Field, reduxForm, Form} from "redux-form";
import { connect } from "react-redux";

class SchemaRegistryForm extends React.Component {
    render() {
        const {handleSubmit} = this.props;
        return (
            <main className="app-content">
                <div className="app-title border-bottom">
                    <div className="container">
                        <div className="row">
                            <div className="col-6">
                                <Form className="emp_create_report_form"
                                      onSubmit={handleSubmit(this.props.onSubmit)}>
                                    <div className="card shadow emp-form-card mb-3 emp_create_report_card">
                                        {this.renderFormContent()}
                                    </div>

                                    <div className="active-device-btn text-right">
                                        <button type="submit"
                                                className={`btn btn-next btn-primary emp-cst-btn emp-btn `} value="Submit">
                                            <span className="mr-2 emp_icn_middle">
                                                <img src={require("../../styles/images/icon/ic_checking.png")}
                                                     alt="Icon"/>
                                            </span>Submit
                                        </button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        )
    }

    renderFormContent() {
        return <div className="card-header emp_create_report_header">
            <div className="row">
                <div className="col-12">
                    <div className="form-group">
                        <label className="label_modifier">Name of Schema</label>
                        <Field name="title" component="input" className="form-control input_modifier" placeholder="Schema Title"
                               type="text"/>
                    </div>

                    <div className="form-group">
                        <label className="label_modifier">Description</label>
                        <Field name="description" component="input" className="form-control input_modifier" placeholder="description"
                               type="text"/>
                    </div>

                    <div className="form-group">
                        <FieldArray name="fields" component={this.renderSchemaFields.bind(this)}/>
                    </div>
                </div>
            </div>
        </div>;
    }

    renderSchemaFields({fields, meta: {error, submitFailed}}) {
        return <>
            <button type="button" className="btn btn-primary mb-2" onClick={() => fields.push()}>
                Add Fields
            </button>
            {fields.map((dimension, index) => {
                return this.renderSchemaFieldItem(dimension);
            })}
        </>;
    }

    renderSchemaFieldItem(dimension) {
        return (
            <div>
                <div className="row">
                    <div className="col-s-12 col-md-4">
                        <label className="label_modifier">Title</label>
                        <Field name={`${dimension}.title`} component="input" className="form-control input_modifier emp_text_initial"/>
                    </div>
                    <div className="col-s-12 col-md-4">
                        <label className="label_modifier">Display Title</label>
                        <Field name={`${dimension}.shortTitle`} component="input" className="form-control input_modifier emp_text_initial"
                               label="Display Title"/>
                    </div>
                    <div className="col-s-12 col-md-4">
                        <label className="label_modifier">Data Type</label>
                        <Field
                            name={`${dimension}.type`}
                            component="select"
                            className='custom-select cst-select-box input_modifier shadow_select_hover'
                        >
                            {
                                Object.values(dataTypes).map((dataType, index) => {
                                    return <option key={index}>{dataType}</option>
                                })
                            }

                        </Field>
                    </div>
                </div>
            </div>
        );
    }
}

SchemaRegistryForm.propTypes = {
    onSubmit : PropTypes.func
}

const dataTypes = {
    number: "Number",
    string: "String",
}


const mapStateToProps = (state) => {
    return {
        initialValues: state.schemaRegistryReducer.schema,
    };
};


export default reduxForm({
    form: "schemaRegistryForm",
})(connect(mapStateToProps)(SchemaRegistryForm));