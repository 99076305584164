import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import Header from '../../components/Header/Header';
import {withRouter} from 'react-router';
import SchemaRegistryForm from '../../components/SchemaRegistry/SchemaRegistryForm'
import {Sidebar} from "../../components/Sidebar/Sidebar";
import * as SchemaRegistryActions from "../../actions/schemaRegistryAction";
import {browserHistory} from 'react-router';
import SchemaRegistryPage from "../../components/SchemaRegistry/SchemaRegistryPage";

export class ReportFormContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            update: true
        }

    }

    onSubmit = (values, dispatch) => {
        console.log("Submitting")
        console.log(values);
        this.props.schemaRegistryActions.createSchema(values);
    }

    render() {
        const {authActions, auth} = this.props;
        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                <SchemaRegistryPage onSubmit={this.onSubmit.bind(this)}/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    schemaRegistryActions: bindActionCreators(SchemaRegistryActions, dispatch)
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ReportFormContainer));