import {userConstants} from '../constants/user.constants.js';

const initialState = {
    user: {},
    userList: [],
    groups: [],
    updated: false
};

export const userReducer = (state = initialState, action) => {
    let users;
    switch (action.type) {
        case userConstants.CREATE_USER_FULFILLED:
        case userConstants.UPDATE_USER_FULFILLED:
            users = state.userList
            if (action.payload && action.payload.users)
                users = action.payload.users
            state = {
                ...state,
                userList: users,
                updated: true
            };
            return state;
        case userConstants.DELETE_USER_FULFILLED:
            users = action.payload.users ? action.payload.users : state.userList;
            state = {
                ...state,
                userList: users,
                updated: true
            };
            return state;
        case userConstants.UPDATE_USER_FULFILLED:
            state = {
                ...state,
                userList: action.payload.users,
                updated: true
            };
            return state;
        case userConstants.FETCH_USER_DATA_FULFILLED:
            state = {
                ...state,
                userList: action.payload.users,
                updated: true
                //event: action.payload.sponsor
            };
            return state;

        case userConstants.GET_MEMBER_GROUPS_FULFILLED:
            state = {
                ...state,
                groups: action.payload,
                updated: true
            }

        default:
            return state;
    }
};