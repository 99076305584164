import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import { CustomDashboardModel } from './model/CustomDashboard';

export const createCustomDashboard = (values) => {
    let payload = {
        'dashboard_title': values['dashboard_title'] || 'New Dashboard',
        'breakpoints': values['breakpoints'],
        'columns': values['columns'],
        'widgets': values['widgets'],
        'owner': values['owner'],
    };
    return axios.post(`${BASE_URL()}/api/v1/custom_dashboards`, payload, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        payload["id"] = response.data.item.id;
        return CustomDashboardModel.fromJson(payload);
    });
};

export const updateCustomDashboard = (dashboard) => {
    let payload = {
        'id': dashboard['id'],
        'dashboard_title': dashboard['dashboard_title'],
        'owner': dashboard['owner'],
        'breakpoints': dashboard['breakpoints'],
        'columns': dashboard['columns'],
        'widgets': dashboard['widgets'],
    };

    return axios.put(`${BASE_URL()}/api/v1/custom_dashboards/${dashboard.id}`, payload, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    }).then(response => {
        return CustomDashboardModel.fromJson(response.data.item);
    });
};

export const listCustomDashboards = () => {
    return axios.get(`${BASE_URL()}/api/v1/custom_dashboards`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response.data['items'].map(item => CustomDashboardModel.fromJson(item));
    });
};

export const deleteCustomDashboard = (dashboardId) => {
    return axios.delete(`${BASE_URL()}/api/v1/custom_dashboards/${dashboardId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return CustomDashboardModel.fromJson(response.data.item);
    });
};

export const getCustomDashboard = (dashboardId) => {
    return axios.get(`${BASE_URL()}/api/v1/custom_dashboards/${dashboardId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return CustomDashboardModel.fromJson(response.data.item);
    });
};

export const deleteCustomDashboards = async (dashboardIds) => {
    let payload = {
        'ids': Object.keys(dashboardIds),
    };
    return axios.post(`${BASE_URL()}/api/v1/custom_dashboards/bulk_delete`, payload, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response.data.items.map(id => CustomDashboardModel.fromJson(id));
    });
};