import { PASSWORD_MIN_LEN} from '../../config';

export default function validate (values) {
    const errors = {};
    errors['organization'] = {};
    let value  = values['organization'];
    if (value.hasOwnProperty("logo_image") && value.logo_image.length){
        const allowedExtensions = /(\jpg|\jpeg|\png|\gif)$/i;
        let extension;

        if (typeof(value.logo_image) === "string"){
            const splits = value.logo_image.split(".")
            extension = splits.pop().toLowerCase();
        }else {
            extension = value.logo_image[0].type;
        }

        if (!allowedExtensions.exec(extension)) {
            errors['organization']['logo_image'] = "File format not allowed. Allowed formats: .jpg, .jpeg, .png and .gif";
        }

        const maxBytes = 5 * 1000 * 1000;
        if(value.logo_image[0].size > maxBytes){
            errors['organization']['logo_image'] = `File too large. Maximum size ${maxBytes / 1000 / 1000}MB`;
        }
    }
    if (value && value['id']){
        if (!value['name']) {
            errors['organization']['name'] = 'Name is required';
        }

        if (!value['system_of_units']) {
            errors['organization']['system_of_units'] = 'System of Units is required';
        }
        if (!value['google_sso_domain']) {
            errors['organization']['google_sso_domain'] = `Google Single-Sign-On-Domain is required.`;
        }
        if (value.logo_type == 'domain' && !value.logo_url){
            errors['organization']['logo_url'] = `Domain is required.`;
        }
        if (value.logo_type == 'upload' && value.logo_image && value.logo_image.length < 1){
            errors['organization']['logo_image'] = `Logo is required.`;
        }

    }
    //else{
    //	errors['wifi_network'] = {
    //		password: 'Password is required',
    //		username: 'User Name is required',
    //		name: 'Name is required'
    //	};
    //}


    return errors;
};