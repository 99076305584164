import React from 'react';
import {isEqual} from "lodash";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import * as DeviceActions from '../../actions/deviceAction';
import {Field} from "redux-form";
import {AlertLevel} from "./AlertLevel";
import {DeviceItemField} from "../SearchableDropDownField/SearchableDropDownField";
import {formatAlertType} from "../../utils/alertDataHelper";
import {GeofenceMap} from "../GeofenceMap/GeofenceMap";
import {GeoFenceTransitionSelection} from "../AlertForm/GeoFenceTransitionSelection";
import {NewSearchableDropDownField} from "../SearchableDropDownField/NewSearchableDropDownField";
import GroupDropDownField from "../SearchInput/GroupDropDownField";
import GroupAndSensorDeviceActiveDropDownField from "../SearchInput/GroupAndSensorDeviceActiveDropDownField";


const datapointInputField = ({children, input, meta: {touched, error}}) => {
    let fieldStyle = "";
    let errorMessage = "";
    if (touched && error) {
        fieldStyle = "input_error"
        errorMessage = <div className="form-group__error">{error}</div>
    }
    return (
        <React.Fragment>
            <div>
                <input type="number" {...input} id="trigger_minutes"
                       className={`form-control m-sm-1 input_modifier input_w_5o ${fieldStyle}`}
                       min="5" max="500" step="5"/>
                {children}
            </div>
            <div>
                {errorMessage}
            </div>
        </React.Fragment>
    )
};

const titleField = ({input, meta: {touched, error}}) => {
    let fieldStyle = "";
    let errorMessage = ""
    if (touched && error) {
        fieldStyle = "input_error"
        errorMessage = <span className="form-group__error">{error}</span>
    }
    return (
        <div>
            <input type="text" {...input} placeholder={"Enter Title Here"}
                   className={`col-sm-12 ${fieldStyle}`}/>
            {errorMessage}
        </div>
    )
};


export class GeofenceTriggerCard extends React.Component {
    renderMap = () => {
        return (
            <React.Fragment>
                <div className="modal" tabIndex="-1" role="dialog" id="geofenceModal">
                    <div className="modal-dialog modal-lg" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">New Geofence Alert</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <Field
                                    name="conditions[0].type"
                                    value="polygon"
                                    component={() => <input type='hidden' value={'polygon'}/>}
                                />
                                <Field name="conditions[0].coordinates" component={({input}) => (
                                    <GeofenceMap onGeoFenceChange={input.onChange} conditions={this.props.conditions}/>
                                )}/>
                                <Field name="conditions[0].properties" component={GeoFenceTransitionSelection}/>
                            </div>
                            <div className="modal-footer">
                                <div className="geofence-modal-footer">
                                    <button type="button" className="btn btn-next btn-primary emp-cst-btn emp-btn"
                                            data-dismiss="modal">
                                <span className="mr-2 emp_icn_middle">
                                    <img
                                        src={require("../../styles/images/icon/ic_checking.png")}
                                        alt="Icon"
                                    />
                                </span>Save
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    renderInputField = ({children, input, size, meta: {touched, error}}) => {
        let fieldStyle = "";
        let errorMessage = "";
        if (touched && error) {
            fieldStyle = "input_error"
            errorMessage = error;
        }
        size = 10;
        return (
            <React.Fragment>
                <div>
                    <input type="text" {...input} size={size}
                           className={`form-control textarea_modifier shadow_select_hover ${fieldStyle}`}
                    />
                    {children}
                </div>
                <div className="form-group__error">{errorMessage}</div>
            </React.Fragment>
        )
    };

    renderCoordinatesError = ({meta: {touched, error}}) => {
        let errorMessage = "";
        if (touched && error) {
            errorMessage = error;
        }
        return (
            <React.Fragment>
                <div className="form-group__error">{errorMessage}</div>
            </React.Fragment>
        )
    };

    shouldComponentUpdate(nextProps, nextState) {
        return !isEqual(nextProps, this.props);
    }

    render() {
        return (
            <div>
                {this.renderMap()}
                <div className="card shadow emp-form-card mb-3 emp_create_report_card">
                    <div className="card-header emp_create_report_header p-4">
                        <label className="label_modifier col-sm-12 pl-0">Alert Title</label>
                        <Field name='title' component={titleField}/>
                    </div>
                    <div className="card-body emp_create_report_body pb-2 emp_create_notify_body">
                        <div className="row emp_v_pipe_box">
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="form-group">
                                    <label className="label_modifier col-sm-12 pl-0">Type of alert</label>
                                    <Field
                                        name='field'
                                        component={() => {
                                            return <span
                                                className="col-sm-11">{formatAlertType(this.props.alertType)}</span>
                                        }}
                                    />

                                    <Field
                                        name='schema'
                                        value="monitor"
                                        component={() => <input type='hidden' value={'monitor'}/>}
                                    />
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-next btn-primary emp-cst-btn emp-btn" data-toggle="modal"
                                            data-target="#geofenceModal" type="button">
                                <span className="mr-2 emp_icn_middle">
                                <img
                                    src={require("../../styles/images/icon/ic_checking.png")}
                                    alt="Icon"
                                />
                                </span>
                                        Edit area of alert
                                    </button>
                                    <Field name="conditions[0].coordinates" component={this.renderCoordinatesError}/>
                                    {/* <AlertConditionsSelector
                                    title={this.props.alertTitle} 
                                    conditionUnits={this.props.conditionUnits}/> */}
                                </div>
                                <div className="form-group">
                                    <AlertLevel level={this.props.level}/>
                                </div>
                                <div className="form-group form-inline">
                                    <span className="m-sm-1">Send only if it holds for</span>
                                    <Field
                                        name={`trigger_minutes`}
                                        component={datapointInputField}>
                                        <label className="m-sm-1 label-inline">minutes (intervals of 5 minutes)</label>
                                    </Field>
                                </div>
                                <div className="form-group">
                                    <label className="label_modifier col-sm-12 pl-0">Description</label>
                                    <Field
                                        name={`description`}
                                        component={this.renderInputField}
                                        size={64}
                                    />
                                </div>
                            </div>
                            {/*<span className="emp_v_pipe emp_v_pipe_md_hide"></span>*/}
                            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                <div className="form-group">
                                    <NewSearchableDropDownField
                                        fieldName={'devices'}
                                        itemFieldComponent={DeviceItemField}
                                        title={'This alert applies to'}
                                        placeholder="Type Sensor or Group Name"
                                        isDisabled={false}
                                        render={({
                                                     placeholder,
                                                     isDisabled,
                                                     handleChange,
                                                     classNames
                                                 }) =>
                                            <GroupAndSensorDeviceActiveDropDownField
                                                placeholder={placeholder}
                                                isDisabled={isDisabled}
                                                handleChange={handleChange}
                                                classNames={classNames}
                                                shouldRenderSuggestions={()=>true}
                                                selections={this.props.devices}
                                            />
                                        }
                                    />
                                </div>
                                <div className="form-group">
                                    <NewSearchableDropDownField
                                        fieldName={'groups'}
                                        itemFieldComponent={DeviceItemField}
                                        title={'Notify these groups'}
                                        placeholder="Type Sensor or Group Name"
                                        isDisabled={false}
                                        render={({
                                                     placeholder,
                                                     isDisabled,
                                                     handleChange,
                                                     classNames
                                                 }) =>
                                            <GroupDropDownField
                                                placeholder={placeholder}
                                                isDisabled={isDisabled}
                                                handleChange={handleChange}
                                                classNames={classNames}
                                                shouldRenderSuggestions={()=>true}
                                                selections={this.props.groups}
                                            />
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    renderNotificationSelections() {
        return <div className="form-group">
            <div className="media align-items-center">
                            <span className="mr-3">
                            <label className="emp_switch">
                            <input checked="true" type="checkbox"/>
                            <span className="emp_slider round"/>
                            </label>
                            </span>
                <div className="media-body">
                    <p className="mb-0">Send notifications even during do not disturb hours</p>
                </div>
            </div>
        </div>
    }
}

const mapStateToProps = (state) => {
    return ({
        device: state.deviceReducer,
    });
};

const mapDispatchToProps = (dispatch) => ({
    deviceActions: bindActionCreators(DeviceActions, dispatch)
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GeofenceTriggerCard));