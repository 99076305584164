import axios from 'axios';
import {BASE_URL} from "../config";
import storage from "../utils/storage";
import Metric from "./model/Metric";
import {getDesiredUnits, MeasurementUnit, UnitOfMeasurement} from "../utils/formatHelper";

export const getMetric = (instance_id: string, metric: string, start_time: string, end_time: string, unit: string, limit: number | null, systemOfUnits?: string, cursor?: string | null) => {
    return axios.get(`${BASE_URL()}/api/v1/metrics/query`, {
        params: {
            instance_id: instance_id,
            metric: metric,
            start_time: start_time,
            end_time: end_time,
            unit: unit,
            limit: limit,
            cursor: cursor
        },
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return {
            'items': response.data.items
                .map((item) => new Metric(item.timestamp, item.value, item.instance_id, item.name, item.unit))
                .map((item: Metric) => {

                    if (item.unit === MeasurementUnit.None) {
                        item.unit = inferUnitsFromMetricName(item.metric);
                    }
                    
                    let desiredUnits = getDesiredUnits(systemOfUnits, item.unit)

                    if (desiredUnits === MeasurementUnit.None) {
                        desiredUnits = inferUnitsFromMetricName(item.metric);
                    }

                    item.value = new UnitOfMeasurement(item.value, item.unit).formatUnit(desiredUnits);
                    item.unit = desiredUnits;
                    return item;
                })
                .sort((a: Metric, b: Metric) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()),
            'nextCursor': response.data.nextCursor
        }

    });
};

function inferUnitsFromMetricName(metricName: string): string | null {
    console.log(`Inferring units from metric name: ${metricName}`)
    if (metricName.includes('humidity')) {
        return MeasurementUnit.Percent;
    } else if (metricName.includes("rms_acc")) {
        return MeasurementUnit.MetersPerSecondSquared;

    } else if (metricName.includes("battery_voltage")) {
        return MeasurementUnit.Millivolts;
    } else if (metricName.includes("ambient_temperature")) {
        return MeasurementUnit.Celsius;
    }
    else {
        return MeasurementUnit.None;
    }
}

export const listMetrics = () => {
    return axios.get(`${BASE_URL()}/api/v1/metrics`, {
        params: {},
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return {
            'items': response.data.items
        }

    });
};