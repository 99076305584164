export const WorkType = Object.freeze({
    ROUTINE: "ROUTINE",
    NON_ROUTINE: "NON_ROUTINE",
});

export class WorkEventModel {
    id;
    title;
    updated_at;
    created_at;
    work_activity;
    event_timestamp;
    work_type;
    failure_mode;
    device_ids;
    time_spent;
    avoided_downtime;
    system_provided;
    apply_learning;
    tenant_id;
    owner_id;

    constructor(id, title, updated_at, created_at, work_activity, event_timestamp, work_type, failure_mode, device_ids,
                time_spent, avoided_downtime, system_provided, apply_learning, tenant_id, owner_id) {
        this.id = id;
        this.title = title;
        this.updated_at = updated_at;
        this.created_at = created_at;
        this.work_activity = work_activity;
        this.event_timestamp = event_timestamp;
        this.work_type = work_type;
        this.failure_mode = failure_mode;
        this.device_ids = device_ids;
        this.time_spent = time_spent;
        this.avoided_downtime = avoided_downtime;
        this.system_provided = system_provided;
        this.apply_learning = apply_learning;
        this.tenant_id = tenant_id;
        this.owner_id = owner_id;
    }

    static fromJson(json) {
        return new WorkEventModel(json['id'],
            json['title'],
            json['updated_at'],
            json['created_at'],
            json['work_activity'],
            json['event_timestamp'],
            json['work_type'],
            json['failure_mode'],
            json['device_ids'],
            json['time_spent'],
            json['avoided_downtime'],
            json['system_provided'],
            json['apply_learning'],
            json['system_provided'],
            json['tenant_id'],
            json['owner_id'],
        )
    }
}