import {groupConstants} from '../constants/group.constants.js';

const initialState = {
    groups: [],
    group: {}
};

export const groupReducer = (state = initialState, action) => {

    switch (action.type) {
        case groupConstants.FETCH_GROUP_DATA_FULFILLED:
            state = {
                ...state,
                groups: action.payload.groups
            };
            return state;
        case groupConstants.FETCH_GROUP_FULFILLED:
            state = {
                ...state,
                group: action.payload.group
            };
            return state;
        case groupConstants.CREATE_GROUP_FULFILLED:
            state = {
                ...state,
                groups: action.payload.groups || []
            };
            return state;

        case groupConstants.UPDATE_GROUP_FULFILLED:
            state = {
                groups: state.groups.map(group =>
                    group.id === action.payload.group.id ? action.payload.group : group
                ),
            }
            return state;
        case groupConstants.DELETE_GROUP_FULFILLED:
            state = {
                ...state,
                groups: action.payload.groups
            }
            return state;
        default:
            return state;
    }
};