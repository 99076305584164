import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as AreaActions from '../../actions/areaActions';
import * as AssetActions from '../../actions/assetActions';
import * as DeviceAssignmentActions from '../../actions/deviceAssignmentActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {browserHistory, withRouter} from 'react-router';
import {AreaDetailPage} from '../../components/Area/AreaDetailPage'
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import * as DeviceAction from "../../actions/deviceAction";
import {getDeviceAssignment} from "../../actions/deviceAssignmentActions";

class AreaDetailPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.onTabChange = this.onTabChange.bind(this);
    }

    async componentDidMount() {
        const areaId = this.props.params.id;
        await this.loadData(areaId);
    }

    async loadData(areaId) {
        this.setState({loading: true})
        await this.props.areaActions.getArea(areaId, {expand: ["area_type", "parent_area", "children_areas"]});
        await this.props.deviceAssignmentActions.listDeviceAssignments({areaId: areaId, expand: ['area', 'asset', 'device']});
        await this.props.assetActions.listAssets();
        this.setState({loading: false})
    }

    async componentDidUpdate(prevProps) {
        const areaId = this.props.params.id;
        if (areaId !== prevProps.params.id) {
            await this.loadData(areaId)
        }
    }

    onAssignSubmit = async (values) => {
        await this.props.deviceAssignmentActions.createDeviceAssignment({
            device_assignment: {
                area_id: values.areaId,
                asset_id: values.assetId,
                device_id: values.deviceId
            }
        });
    }

    onDeleteAssignmentClick = async (assignment) => {
        await this.props.deviceAssignmentActions.deleteDeviceAssignment(assignment.id);
    }

    async onTabChange(tab) {
        switch (tab) {
            case "SubAreas":
                break;
            case "Assignments":
                await this.props.deviceAssignmentActions.listDeviceAssignments({
                    areaId: this.props.params.id,
                    expand: ['device', 'asset']
                });
                await this.props.deviceActions.searchDevices({});
                break;
            default:
                break;
        }
    }

    onSubAreasItemClick = async (item) => {
        browserHistory.push(`/areas/${item.id}`);
    }

    onAssignItemClick = async (item) => {
        browserHistory.push(`/assets/${item.assetId}`);
    }

    render() {
        const {auth, authActions, area, deviceAssignments, devices, assets} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <AreaDetailPage
                        area={area}
                        assets={assets}
                        onTabChange={this.onTabChange}
                        onAssignSubmit={this.onAssignSubmit}
                        onDeleteAssignmentClick={this.onDeleteAssignmentClick}
                        onAssignItemClick={this.onAssignItemClick}
                        onSubAreasItemClick={this.onSubAreasItemClick}
                        deviceAssignments={deviceAssignments}
                        devices={devices}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    area: state.areaReducer.area,
    deviceAssignments: state.deviceAssignmentReducer.deviceAssignments,
    devices: state.deviceReducer.devices,
    assets: state.assetReducer.assets,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    areaActions: bindActionCreators(AreaActions, dispatch),
    assetActions: bindActionCreators(AssetActions, dispatch),
    deviceAssignmentActions: bindActionCreators(DeviceAssignmentActions, dispatch),
    deviceActions: bindActionCreators(DeviceAction, dispatch),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AreaDetailPageContainer));