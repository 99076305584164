import { datasetDbConstants } from "../constants/datasetDb.constants";
import { DatasetDbModel } from "../api/model/DatasetDb";

type DatasetDbState = {
    datasetDbs: DatasetDbModel[],
    selectedDatasetDb: DatasetDbModel | {},
};

const initialState: DatasetDbState = {
    datasetDbs: [],
    selectedDatasetDb: {},
};

export const datasetDbReducer = (state = initialState, action: { type: string, payload: any }): DatasetDbState => {
    let newDatasetDbs;
    switch (action.type) {
        case datasetDbConstants.QUERY_DATASET_DBS_DATA:
        case datasetDbConstants.QUERY_DATASET_DBS_DATA_FULFILLED:
            state = {
                ...state,
                datasetDbs: action.payload,
            };
            return state;
        case datasetDbConstants.UPDATE_DATASET_DB:
        case datasetDbConstants.UPDATE_DATASET_DB_FULFILLED:
            newDatasetDbs = state.datasetDbs.filter((datasetDb: DatasetDbModel) => datasetDb.database_name !== action.payload.database_name);
            newDatasetDbs.push(action.payload);
            state = {
                ...state,
                datasetDbs: newDatasetDbs,
                selectedDatasetDb: action.payload,
            };
            return state;
        case datasetDbConstants.CREATE_DATASET_DB:
        case datasetDbConstants.CREATE_DATASET_DB_FULFILLED:
            newDatasetDbs = state.datasetDbs;
            newDatasetDbs.push(action.payload);
            state = {
                ...state,
                datasetDbs: newDatasetDbs,
                selectedDatasetDb: action.payload,
            };
            return state;
        case datasetDbConstants.GET_DATASET_DB:
        case datasetDbConstants.GET_DATASET_DB_FULFILLED:
            state = {
                ...state,
                selectedDatasetDb: action.payload,
            };
            return state;
        case datasetDbConstants.DELETE_DATASET_DB:
        case datasetDbConstants.DELETE_DATASET_DB_FULFILLED:
            newDatasetDbs = state.datasetDbs.filter((datasetDb: DatasetDbModel) => datasetDb.database_name !== action.payload.database_name);
            state = {
                ...state,
                selectedDatasetDb: {},
                datasetDbs: newDatasetDbs,
            };
            return state;
        default:
            return state;
    }
};
