export const shipmentContactsConstants = {
    QUERY_SHIPMENT_CONTACTS_DATA: 'QUERY_SHIPMENT_CONTACTS_DATA',
    QUERY_SHIPMENT_CONTACTS_DATA_FULFILLED: 'QUERY_SHIPMENT_CONTACTS_DATA_FULFILLED',

    CREATE_SHIPMENT_CONTACT: 'CREATE_SHIPMENT_CONTACT',
    CREATE_SHIPMENT_CONTACT_FULFILLED: 'CREATE_SHIPMENT_CONTACT_FULFILLED',

    DELETE_SHIPMENT_CONTACT: 'DELETE_SHIPMENT_CONTACT',
    DELETE_SHIPMENT_CONTACT_FULFILLED: 'DELETE_SHIPMENT_CONTACT_FULFILLED',

    GET_SHIPMENT_CONTACT: 'GET_SHIPMENT_CONTACT',
    GET_SHIPMENT_CONTACT_FULFILLED: 'GET_SHIPMENT_CONTACT_FULFILLED',

    UPDATE_SHIPMENT_CONTACT: 'UPDATE_SHIPMENT_CONTACT',
    UPDATE_SHIPMENT_CONTACT_FULFILLED: 'UPDATE_SHIPMENT_CONTACT_FULFILLED'
};