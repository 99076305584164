import React from 'react';
import {ListView} from "../../ui/ListView/ListView";
import {Link} from "react-router";
import {SearchInputField} from "../../ui/Input/SearchInputField";
import DeviceType from "../../api/model/DeviceType";

const renderTextRow = (label, text) => {
    return (
        <div className="row mt-2">
            <span className="col-6 col-sm-4 col-md-4 col-lg-3 emp-type-subtitle1">{label}</span>
            <span className={"col-6 col-sm col-md col-lg emp-type-subtitle1"}>{text}</span>
        </div>
    );
}
const renderDeviceTypesListItem = (item: DeviceType, index) => {
    return <div className="container-fluid">
        <div className="row">
            <div className="col-2">
                <img className={"placeholder img-thumbnail"}
                     src={item.image_url ?? `https://via.placeholder.com/150?text=${item.name}`}/>
            </div>
            <div className="col">
                {renderTextRow("Name", item.name)}
                {renderTextRow("Description", item.description)}
            </div>
        </div>
    </div>
}

export class DeviceTypesPage extends React.Component<{
    deviceTypes: DeviceType[],
    onItemClick: (item: DeviceType) => void,
    onSearchInput: (search: string) => void
}> {
    render() {
        const {deviceTypes, onItemClick} = this.props;
        return (
            <main className="app-content">
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <h2 className="emp-type-h2">Device Types</h2>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col">
                            <Link to="/device_types/new">
                                <button className="btn btn-primary emp-type-btn-primary">CREATE</button>
                            </Link>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12">
                            <SearchInputField label={"Search"} onChange={this.props.onSearchInput}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <ListView
                                datasource={deviceTypes}
                                renderItem={renderDeviceTypesListItem}
                                onItemClick={onItemClick}
                            />
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
