import React from "react";

export default class UserServiceAuditFormatter {

    formatLogEvent(auditLog) {
        let title;
        switch (auditLog.access_type) {
            case "create":
                title = auditLog.record ? auditLog.record.email : "";
                return `Created user '${title}'`
            case "delete":
                title = auditLog.record ? auditLog.record.email : "";
                return `Deleted user '${title}'`

            default:
                console.error("Received unknown access type: " + auditLog.access_type)
        }
    }
}