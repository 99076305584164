import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import ExperimentTreatment from "./model/ExperimentTreatment";


export const getExperimentTreatmentByUser = async (experiment_id) => {
    try {
        let response = await axios.get(`${BASE_URL()}/api/v1/feature_flag/${experiment_id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': storage.get('token')
            }
        });

        const {data} = response;

        return new ExperimentTreatment(
            data['client_id'],
            data['identifier'],
            data['feature_flag_id'],
            data['treatment'],
            data['payload']
        )
    } catch (error) {
        throw error;
    }
};