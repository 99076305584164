export const SORT_BY_FIELD_INDEX = (a, b) =>
  a.field_index && b.field_index ? a.field_index - b.field_index : 0

export const mapToFieldInstances = (fieldInstances, children) => {
  return children
    .sort(SORT_BY_FIELD_INDEX)
    .map((field) =>
      fieldInstances.find((instance) => instance.field.id === field.id)
    )
}

export const findMissingFields = (fieldInstances) => {
  return fieldInstances
    .filter((field) => !field.value && field.field.is_required)
    .map((field) => ({ ...field, error: 'Required' }))
}