

export default class AuthServiceAuditFormatter {

    formatLogEvent(auditLog) {
        switch (auditLog.resource) {
            case "session":
                switch (auditLog.access_type) {
                    case "create":
                        return `logged in`

                    case "locked":
                        return `locked account`
                }

                break
            default:
                console.error("Unknown type received formatting auditServiceLog: " + auditLog.resource)
                return ""
        }
    }
}