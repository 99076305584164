import React from 'react';
import {Link} from "react-router";
import {Table, Column} from "../../../api/model/DatasetTable";
import {TabWrapper} from "../../../ui/Tab/TabWrapper";
import Tabs from "../../../ui/Tabs/Tabs";
import {ColumnsTabPage} from "./Tabs/ColumnsTablePage";
import {PartitionsTabPage} from "./Tabs/PartitionsTabPage";

interface Props {
    onTabChange: (index: number) => void;
    table: Table;
}

export class TableDetailPage extends React.Component<Props> {

    renderTextRow(label, text) {
        return (
            <div className="row mt-2">
                <span className="col-6 col-sm-4 col-md-4 col-lg-3 emp-type-subtitle1">{label}</span>
                <span className={"col-6 col-sm col-md col-lg emp-type-subtitle1"}>{text}</span>
            </div>
        );
    }

    renderTabs = () => {
        const {table} = this.props;
        return (
            <>
                <Tabs onChange={this.props.onTabChange}>
                    <TabWrapper className="emp-type-subtitle1" label="Schema">
                        <div className={"mt-4"}>
                            <ColumnsTabPage table={table}/>
                        </div>
                    </TabWrapper>
                    <TabWrapper className="emp-type-subtitle1" label="Summary">
                        <div className={"mt-4"}>
                            {this.renderSummaryCard()}
                        </div>
                    </TabWrapper>

                    <TabWrapper className="emp-type-subtitle1" label="Partitions">
                        <div className={"mt-4"}>
                            <PartitionsTabPage table={table}/>
                        </div>
                    </TabWrapper>

                </Tabs>
            </>
        )
    }

    render() {
        const {table} = this.props;

        const editUrl = `/tables/${table.table_name}/edit`;
        return (
            <main className="app-content">
                <section className="emp_org_settings_sec emp_report_sec emp_circle_box_sec">
                    <div className="card emp-box emp-shadow">
                        <div className={"bg-white container-fluid mb-4"}>
                            <div className={"row mt-2"}>
                                <h1 className={"emp-type-h2 col"}>{table.table_name}</h1>
                            </div>

                            <div className={"row"}>
                                <div className={"col"}>
                                    {this.renderTabs()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }

    renderSummaryCard() {
        const {table} = this.props;
        return (
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-12 col-sm-10">
                        <div className={"row"}>
                            <div className="col-12 col-xl-6">
                                {this.renderTextRow("Database Name", table.database_name)}
                                {this.renderTextRow("Table Name", table.table_name)}
                                {this.renderTextRow("Location", table.location)}
                            </div>
                            <div className="col-12 col-xl-6">
                                {this.renderTextRow("Properties", JSON.stringify(table.properties))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
