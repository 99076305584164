import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import Moment from 'moment';
import * as Datetime from 'react-datetime';
import store from '../../store/store';
let $ = window.jQuery = require('jquery');
const renderField = ({ input, label, placeholder, maxLength,value, autoComplete, type,parentClass,className,inputGroup,pickerType,dataFormat, meta: { touched, asyncValidating, error } }) => {
    const setValue=(param,dataFormat) => {
        let moment = Moment;
        let time;
        if (typeof param === 'string' || param instanceof String)
            time= param;
        else
            time= param._i ? param._i : moment(param._d).format('hh:mm A');
        let base = time.replace(/\_|:| |/g,'');
        if (base.length >= 6){
            let checkTime = moment(time,'hh:mm A').format(dataFormat);
            return (checkTime === "Invalid date") ? '' : checkTime;
        }
        else{
            return time;
        }
    };
    const setData = (p) => {
        let moment = Moment;
        let state = store.getState();
        let time = moment(p, 'hh:mm A').format('hh:mm A');
        if (time != "Invalid date"){
            state.form.scheduledReportForm.values.scheduled_report.scheduled_time = time;
            $('.time_picker').val(time);
        }else{
            state.form.scheduledReportForm.values.scheduled_report.scheduled_time = '';
            $('.time_picker').val('');
        }
        return true
    };
    return (
        <div className={parentClass}>
            { label !== null && <label className="form-group__label label_modifier">{label}</label> }
            <div className={`form-group__field ${inputGroup.length > 0 ? inputGroup[0].inputGroupClass : ''} ${asyncValidating ? 'async-validating' : ''}`}>
                <div className="date_time_react">
                    {
                        pickerType === 'datePicker' &&
                        <Datetime  inputProps= {{...input,readOnly: true,className:className}} isValidDate={v=> v.startOf('day') >= Moment().startOf('day')} onChange={param => input.onChange(param.format(dataFormat))} dateFormat={dataFormat} timeFormat={false} />
                    }
                    {
                        pickerType === 'timePicker' &&
                        <Datetime  inputProps= {{...input,autoComplete:"off",placeholder: placeholder,className:className}} onBlur={(p)=> setData(p)} onChange={param =>  {let val= setValue(param,dataFormat); return input.onChange(val)}} timeFormat={dataFormat} dateFormat={false} />
                    }
                    {
                        pickerType === 'yearPicker' &&
                        <Datetime  inputProps= {{...input,readOnly: true,className:className}} onChange={param => input.onChange(param.format(dataFormat))} dateFormat={dataFormat} timeFormat={false} />
                    }
                    {
                        !pickerType &&
                        <Datetime  inputProps= {{...input,placeholder: placeholder,readOnly: true,className:className}} onChange={param => input.onChange(param.format(dataFormat))} dateFormat={dataFormat} timeFormat={false} />
                    }

                </div>
                {
                    inputGroup.length > 0 &&
                    <div className="input-group-append cursor-pointer">
                        <span className="input-group-text bg-input-text"
                              id="basic-addon2" onClick={(e) => {$(e.currentTarget).parents('.form-group').find('input').click()}} ><i className={inputGroup[0].iconClass}></i> </span>
                    </div>

                }

            </div>
            { touched && error && <div className="form-group__error">{error}</div>}
        </div>
    );
}

export const DateTimeField = ({ type, name, label, placeholder, maxLength,value, autoComplete ,parentClass,className,inputGroup,pickerType ,dataFormat}) => (
    <Field
        type={type}
        placeholder={placeholder}
        name={name}
        label={label}
        maxLength={maxLength}
        autoComplete={autoComplete}
        className={className}
        parentClass={parentClass}
        inputGroup={inputGroup}
        pickerType={pickerType}
        dataFormat={dataFormat}
        component={renderField}
        />
);

DateTimeField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    parentClass: PropTypes.string,
    maxLength: PropTypes.number,
    inputGroup: PropTypes.array,
    pickerType: PropTypes.string,
    value: PropTypes.string,
    dataFormat: PropTypes.string,
    autoComplete: PropTypes.oneOf(['on', 'off'])
};

DateTimeField.defaultProps = {
    type: 'text',
    label: null,
    placeholder: '',
    className: '',
    parentClass: '',
    value: '',
    inputGroup: [],
    maxLength: null,
    pickerType: '',
    dataFormat: 'DD-MM-YYYY',
    autoComplete: null
};