import {
    calculateLeftMargin,
    getXAxisFormatter,
    numberFormatter,
    tooltipFormatter,
    xAxisTickCalculator,
    yAxisTickCalculator
} from "./ChartUtils";
import {CartesianGrid, Legend, ReferenceArea, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import * as Moment from "moment/moment";
import React from "react";
import "./CartesianChart.css"

export const CartesianChart = ({
                                   children, ChartComponent, onMouseDown, onMouseUp, onMouseMove, chartData,
                                   xAxisMin, xAxisMax, zoomedXMin, zoomedXMax, width, layoutSize, yDomain
                               }) => {

    const yTicksArray = yAxisTickCalculator(yDomain[0], yDomain[1]);
    
    const xTicksArray = xAxisTickCalculator({
        minPoint: xAxisMin,
        maxPoint: xAxisMax,
        width,
        layoutSize
    });
    const leftMargin = calculateLeftMargin({tickArray: yTicksArray});

    return (
        <ResponsiveContainer width="100%" height="100%">
            <ChartComponent
                className="cartesian-chart"
                margin={{ left: leftMargin, bottom: 32 }}
                syncId="syncId"
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseMove={onMouseMove}
                data={chartData}
            >
                <XAxis
                    axisLine={{ stroke: "#616161" }}
                    tickLine={{ stroke: "#616161" }}
                    tick={{ fill: "#616161" }}
                    angle={-30}
                    interval={0}
                    ticks={xTicksArray}
                    textAnchor="end"
                    tickFormatter={getXAxisFormatter(
                        Moment(xAxisMin),
                        Moment(xAxisMax)
                    )}
                    dataKey="x"
                    minTickGap={1}
                    allowDataOverflow={true}
                    domain={[xAxisMin, xAxisMax]}
                    type="number"
                    scale={"time"}
                    height={35}
                />
                <YAxis
                    axisLine={{ stroke: "#616161" }}
                    tickLine={{ stroke: "#616161" }}
                    tick={{ fill: "#616161" }}
                    minTickGap={0.1}
                    ticks={yTicksArray}
                    allowDataOverflow={true}
                    domain={[yTicksArray[0], yTicksArray[yTicksArray.length - 1]]}
                    yAxisId="1"
                    type={"number"}
                    tickFormatter={numberFormatter}
                />
                <CartesianGrid
                    vertical={false}
                    strokeDasharray="3 3"
                    stroke="#e0e0e0"
                />
                {children}
                {zoomedXMin && zoomedXMax ? (
                    <ReferenceArea
                        yAxisId="1"         x1={zoomedXMin}
                        x2={zoomedXMax}
                        strokeOpacity={0.3}
                    />
                ) : null}
                <Legend
                    verticalAlign={"bottom"}
                    height={"35"}
                    wrapperStyle={{
                        position: "relative",
                        marginTop: "5px",
                        lineHeight: "25px",
                        fontFamily: "Roboto, sans-serif",
                        fontSize: "12px",
                        color: "#616161",
                    }}
                />
                <Tooltip
                    labelFormatter={tooltipFormatter}
                    formatter={numberFormatter}
                    contentStyle={{
                        background: "#ffffff",
                        border: "1px solid #e0e0e0",
                        borderRadius: "4px",
                        fontFamily: "Roboto, sans-serif",
                        fontSize: "12px",
                        color: "#616161",
                    }}
                />
            </ChartComponent>
        </ResponsiveContainer>

    )
};