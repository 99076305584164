import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as DeviceTypeActions from '../../actions/deviceTypesActions';
import Header from '../../components/Header/Header';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { browserHistory, withRouter } from 'react-router';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import { DeviceTypeEditDetailPage } from "../../components/DeviceTypes/DeviceTypeEditDetailPage";
import DeviceType from "../../api/model/DeviceType";

interface DeviceTypeCreateDetailPageContainerProps {
    auth: any;
    deviceType: DeviceType;
    authActions: typeof AuthActions;
    deviceTypeActions: typeof DeviceTypeActions;
}

interface DeviceTypeCreateDetailPageContainerState {
    loading: boolean;
}

class DeviceTypeCreateDetailPageContainer extends React.Component<DeviceTypeCreateDetailPageContainerProps, DeviceTypeCreateDetailPageContainerState> {
    constructor(props: DeviceTypeCreateDetailPageContainerProps) {
        super(props);
        this.state = {
            loading: false,
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        this.setState({ loading: true })
        await this.props.deviceTypeActions.resetDeviceType();
        this.setState({ loading: false })
    }

    async onSubmit(values) {
        this.setState({ loading: true });
        await this.props.deviceTypeActions.createDeviceType(values);
        this.setState({ loading: false });
        browserHistory.push(`/device_types`);
    }

    render() {
        const { auth, authActions, deviceType } = this.props;
        const { loading } = this.state;

        return (
            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header" />
                {loading ? <LoadingMessage /> :
                    <DeviceTypeEditDetailPage deviceType={deviceType} onSubmit={this.onSubmit} />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    deviceType: state.deviceTypesReducer.deviceType,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    deviceTypeActions: bindActionCreators(DeviceTypeActions, dispatch),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(DeviceTypeCreateDetailPageContainer));
