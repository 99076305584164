import React from "react";
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import { withRouter } from 'react-router';
import * as AuditLogActions from '../../actions/auditLogAction';
import * as AuthActions from '../../actions/authActions';
import AuditLogPage from '../../components/AuditLog/AuditLogPage';


class AuditLogContainer extends React.Component{
    render(){
        const {authActions, auth } = this.props;
        return (
            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                    />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                        user={auth.user}
                        logout={authActions.logout}
                        headerclassName="app-header"/>
                <AuditLogPage/>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auditLogReducer: state.auditLogReducer.auditLog,
    auth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    AuditLogActions: bindActionCreators(AuditLogActions, dispatch),
});


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(AuditLogContainer));