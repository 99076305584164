import {FailureModeApi} from "../api/failureModeApi";
import {failureModeConstants} from "../constants/failureMode.constants";

const failureModeApi = new FailureModeApi();

export const listFailureModes = () => {
    return {
        type: failureModeConstants.LIST_FAILURE_MODES,
        payload: failureModeApi.listFailureModes()
    }
};

export const getFailureMode = (failureModeId) => ({
    type: failureModeConstants.GET_FAILURE_MODE,
    payload: failureModeApi.getFailureMode(failureModeId)
});

