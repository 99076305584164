import {getBatchTelemetryData, getTelemetryData} from "./telemetry";
import * as SensorApi from "./sensorApi";
import * as DeviceApi from "./deviceApi";
import * as Moment from 'moment';
import {formatTimestampDateTime, SystemOfUnits} from "../utils/formatHelper";
import {BASE_URL} from "../config";
import storage from "../utils/storage";
import axios from 'axios';
import SensorGatewayConnection from "./model/SensorGatewayConnection";

export const downloadBatchData = async (values) => {
    switch (values['schema']) {
        case 'sensor':
            return downloadBatchDeviceData(values)
        case 'trackonomy':
            return downloadTrackonomyData(values)
        default:
            return downloadBatchDeviceData(values)
    }
}

export const downloadTrackonomyData = async (values) => {
    function offsetTime(minutes) {
        let timeNow = Moment(Date.now());
        timeNow.subtract(minutes, 'minutes');
        return timeNow;
    }

    return new Promise((resolve, reject) => {
        let items = [{
            'timestamp': formatTimestampDateTime(Date.now()),
            'name': 'PKG#453112',
            'location': 'Site #511',
            'action': "ENTERED_FACILITY"
        }, {
            'timestamp': formatTimestampDateTime(offsetTime(5)),
            'name': 'PKG#453110',
            'location': 'Site #412',
            'action': "ENTERED_FACILITY"
        }, {
            'timestamp': formatTimestampDateTime(offsetTime(6)),
            'name': 'PKG#477112',
            'location': 'Site #311',
            'action': "EXITED_FACILITY"
        }, {
            'timestamp': formatTimestampDateTime(offsetTime(10)),
            'name': 'PKG#723413',
            'location': 'Site #311',
            'action': "ENTERED_FACILITY"
        }, {
            'timestamp': formatTimestampDateTime(offsetTime(15)),
            'name': 'PKG#9888112',
            'location': 'Site #311',
            'action': "ENTERED_FACILITY"
        }, {
            'timestamp': formatTimestampDateTime(Date.now()),
            'name': 'PKG#934423',
            'location': 'Site #311',
            'action': "EXITED_FACILITY"
        }, {
            'timestamp': formatTimestampDateTime(Date.now()),
            'name': 'PKG#230099',
            'location': 'Site #311',
            'action': "ENTERED_FACILITY"
        }
        ]
        resolve({
            devices: items
        })
    })
}


export const searchDeviceData = async (values) => {
    let devices = await SensorApi.getAllSensors({status: 'active'});

    let start_time;
    let end_time;
    let unit;

    if (values && values.device_data && values.device_data.start_date) {
        start_time = Moment(values.device_data.start_date, 'MM-DD-YYYY hh:mm A').toISOString();
        end_time = Moment(values.device_data.end_date, 'MM-DD-YYYY hh:mm A').toISOString();
    } else {
        start_time = new Date((new Date()).valueOf() - 1000 * 3600 * 24).toISOString();
        end_time = new Date().toISOString();
    }

    if (values && values.device_data && values.device_data.period) {
        unit = values.device_data.period
    } else {
        unit = '5m'
    }

    // TODO: This is going to have problems scaling and should be paged within the component
    let deviceData = await Promise.all(devices.map(async device => {
        let request = {
            'start_time': start_time,
            'end_time': end_time,
            'unit': unit,
            'sort': values.sort || 'asc',
            'format': values.format || SystemOfUnits.Metric
        };

        if (values.max_items) {
            request['max_items'] = values.max_items;
        }
        let data = await getTelemetryData(device.mac_address, request);
        return {
            // for convenience, copying the device's groups to an array at top level of object
            groups: device.group_items.map(g => g.group_id),
            telemetry: data,
            mac_address: device.mac_address,
            device: device
        };
    }));
    return [...deviceData];
};

export const getDeviceAndTelemetry = async (values) => {
    let start_time;
    let end_time;
    let unit;

    if (!values.mac_address) {
        return {}
    }

    // TODO: This should be using the SensorApi. The sensorApi does the lookup by deviceId, so this is more difficult
    let deviceResponse = await DeviceApi.getDevice(values.mac_address);
    let device = deviceResponse.device;

    if (values && values.device_data && values.device_data.start_date) {
        start_time = Moment(values.device_data.start_date, 'MM-DD-YYYY hh:mm A').toISOString();
        end_time = Moment(values.device_data.end_date, 'MM-DD-YYYY hh:mm A').toISOString();
    } else {
        start_time = new Date((new Date()).valueOf() - 1000 * 3600 * 24).toISOString();
        end_time = new Date().toISOString();
    }

    if (values && values.device_data && values.device_data.period) {
        unit = values.device_data.period
    } else {
        unit = '5m'
    }

    let request = {
        'start_time': start_time,
        'end_time': end_time,
        'unit': unit,
        'sort': values.sort || 'asc',
        'format': values.format || SystemOfUnits.Metric
    };

    if (values.max_items) {
        request['max_items'] = values.max_items;
    }


    let telemetry = [];
    telemetry = await getTelemetryData(values.mac_address, request);

    return {device: device, telemetry: telemetry};
};

export const downloadBatchDeviceData = async (values) => {
    let start_time;
    let end_time;

    if (values && values.device_data && values.device_data.start_date) {
        start_time = Moment(values.device_data.start_date, 'MM-DD-YYYY hh:mm A').toISOString();
        end_time = Moment(values.device_data.end_date, 'MM-DD-YYYY hh::mm A').toISOString();
    } else {
        start_time = new Date((new Date()).valueOf() - 1000 * 3600 * 24).toISOString();
        end_time = new Date().toISOString();
    }

    let request = {
        'start_time': start_time,
        'end_time': end_time,
        'unit': values.device_data.period,
    };

    let results = await getBatchTelemetryData(request);

    return {
        group: '1',
        devices: results.items,
    }

};

export const getGatewayConnectionHistoryData = async (values) => {
    // let start_time;
    // let end_time;
    //
    // if (values && values.device_data && values.device_data.start_date) {
    //     start_time = Moment(values.device_data.start_date, 'MM-DD-YYYY hh:mm A').toISOString();
    //     end_time = Moment(values.device_data.end_date, 'MM-DD-YYYY hh::mm A').toISOString();
    // } else {
    //     start_time = new Date((new Date()).valueOf() - 1000 * 3600 * 24).toISOString();
    //     end_time = new Date().toISOString();
    // }

    let request = {
        // 'start_time': start_time,
        // 'end_time': end_time,
    };

    return axios.get(`${BASE_URL()}/api/v1/sensor_gateway_connections/${values.device_id}`, {
        params: request,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return {history: response.data.items.map(SensorGatewayConnection.fromJson)}
    });
}
