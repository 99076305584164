import Moment from 'moment';
import {MeasurementUnit} from "./formatHelper";


function getTemperatureLabel(unit) {
    switch (unit) {
        case MeasurementUnit.Fahrenheit:
            return '°F'
        case MeasurementUnit.Celsius:
            return '°C'
        default:
            return '°C'
    }
}

export const chartData = (data, tickInterval, units = MeasurementUnit.Celsius) => {
    var moment = Moment;
    let humidity_data = [];
    let prob_temperature_data = [];
    let emb_temperature_data = [];
    let time = new Date();


    let title = data.device.name;
    //  let subtitle = data.telemetry && data.telemetry.length !== 0 ? data.telemetry[0]['sensor_id'] : '';
    let subtitle = ''  // use this value to display secondary string under title

    let humidityTotal = 0;
    let probTempTotal = 0;
    let ambientTempTotal = 0;

    data.telemetry.map(d => {
        if (time > new Date(d['timestamp'])) {
            time = new Date(d['timestamp']);
        }
        let humidity = Number(d.humidity);
        humidityTotal += humidity;
        humidity_data.push([moment.utc(d['timestamp']).valueOf(), humidity])
    });
    data.telemetry.map(d => {
        let probTemp = Number(d.probe_temperature);
        probTempTotal += probTemp;
        prob_temperature_data.push([moment.utc(d['timestamp']).valueOf(), Number(probTemp.toFixed(2))])
    });
    data.telemetry.map(d => {
        let ambientTemperature = Number(d.ambient_temperature);
        ambientTempTotal += ambientTemperature;
        emb_temperature_data.push([moment.utc(d['timestamp']).valueOf(), Number(ambientTemperature.toFixed(2))])
    });

    const options = {
        chart: {
            zoomType: 'x'
        },

        xAxis: {
            type: 'datetime',
            //labels: {
            //    format: '{value:%P}'
            //},
            dateTimeLabelFormats: {
                hour: '%I %p',
                minute: '%I:%M %p'
            },
            tickInterval: tickInterval,
            height: 150,
            gapSize: 2,

        },
        tooltip: {
            backgroundColor: 'rgba(0,0,0,0.8)',
            borderColor: 'rgba(0,0,0,0.2)',
            style: {
                color: 'white',

            },

            borderRadius: 4,
            borderWidth: 1,
            crosshairs: true,
            formatter: function () {
                var tooltip;
                let text = this.series.name == 'Humidity' ? '%' : getTemperatureLabel(units);
                if (this.key === 'last') {
                    tooltip = '<b>Final result is </b> ' + this.y;
                } else {
                    tooltip = '<span style="color:' + this.series.color + '">' + moment(this.x).format('llll') + '</span> <b style="color:' + this.series.color + '"> ' + this.y + '' + text + '</b><br/></div>';
                }
                return tooltip;
            }
        },

        plotOptions: {
            series: {
                gapSize: 1,
                marker: {
                    enabled: false
                },
                events: {
                    legendItemClick: function (event) {
                        return true

                    }
                },
                ordinal: false,
                tickInterval: tickInterval,
            }
        },
        title: {
            text: title,
            align: 'left'

        },
        subtitle: {
            text: subtitle,
            align: 'left'
        },
        yAxis: [{
            height: 150,
            lineWidth: 1,
            labels: {
                format: '{value}%'
            },
            // plotBands: [{
            //     color: 'red', // Color value
            //     width: 1,
            //     value: 4,
            //     dashStyle: 'dot'
            // }, {
            //     color: 'red', // Color value
            //     width: 1,
            //     value: -2,
            //     dashStyle: 'dot'
            // }],
            title: {
                text: ''
            }

        }, { // Secondary yAxis
            height: 150,
            title: {
                text: ''
            },
            labels: {
                format: `{value}${getTemperatureLabel(units)}`
            },
            opposite: true
        }],
        legend: {
            layout: "horizontal",
            align: "right",
            verticalAlign: "top",
            symbolWidth: 0,
            useHTML: true,
            labelFormatter: function () {
                let fcolor = this.name == 'Humidity' ? '#c624c9' : '#FFFFFF';

                let getLegendText = function () {
                    return this.name === 'Humidity' ? 'Humidity<br/>' + (humidityTotal / data.telemetry.length).toFixed(2) + '%'
                        : this.name === 'Probe Temperature' ? 'Probe Temp.<br/>' + (probTempTotal / data.telemetry.length).toFixed(2) + getTemperatureLabel(units)
                            : 'Ambient Temp.<br/>' + (ambientTempTotal / data.telemetry.length).toFixed(2) + getTemperatureLabel(units);
                };
                let text = getLegendText.call(this);

                return `<div style="height:35px;border-radius:4px;margin-right:10px;padding-top: 3px;width:110px;color: ${fcolor};text-align:center;background-color: ${this.color} !important;">${text} </div>`
            }
        },
        series: [{

            name: "Humidity",
            color: '#DEACE3',
            lineWidth: '1.5',
            type: 'spline',
            gapSize: 1,

            //data: [43934, 52503, 57177, 69658, 97031, 119931, 137133, 154175],
            data: humidity_data,
            marker: {radius: 3}
        }, data.device.probe_enabled ? {
            name: "Probe Temperature",
            color: '#43dfdf',
            type: 'spline',
            lineWidth: '1.5',
            yAxis: 1,
            data: prob_temperature_data,
            marker: {radius: 3},
        } : null, {
            name: "Ambient Temperature",
            color: '#0273eb',
            type: 'spline',
            lineWidth: '1.5',
            yAxis: 1,
            data: emb_temperature_data,
            marker: {radius: 4},
        }
        ].filter(el => el !== null)
    };
    return options;
};
