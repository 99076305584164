import React from 'react';
import Tabs from "../../ui/Tabs/Tabs";
import {MeasurementsTabPage} from "./Tabs/MeasurementsTabPage";
import {formatTimestampDateTime} from "../../utils/formatHelper";
import {Link} from "react-router";
import {ApiDevice, Device} from "../../api/model/Device";
import {TabWrapper} from "../../ui/Tab/TabWrapper";
import { getMetric } from '../../api/metricApi';

interface Props {
    onTabChange: (index: number) => void;
    device: ApiDevice;
    getMetric: typeof getMetric
}

export class DeviceDetailPage extends React.Component<Props> {
    constructor(props) {
        super(props);
    }

    renderTextRow(label, text) {
        return (
            <div className="row mt-2">
                <span className="col-6 col-sm-4 col-md-4 col-lg-3 emp-type-subtitle1">{label}</span>
                <span className={"col-6 col-sm col-md col-lg emp-type-subtitle1"}>{text}</span>
            </div>
        );
    }

    renderTabs() {
        return (
            <>
                <Tabs onChange={this.props.onTabChange}>
                    <TabWrapper className="emp-type-subtitle1" label="Summary">
                        <div className={"mt-4"}>
                            {this.renderSummaryCard()}
                        </div>
                    </TabWrapper>

                    <TabWrapper className="emp-type-subtitle1" label="Measurements">
                        <MeasurementsTabPage device={this.props.device} getMetric={this.props.getMetric}/>
                    </TabWrapper>
                    {/*<div className="emp-type-subtitle1" label="Events">*/}
                    {/*    <EventsPage/>*/}
                    {/*</div>*/}
                </Tabs>
            </>
        )
    }

    render() {
        const editUrl = `/devices/${this.props.device.identity}/edit`;
        return (
            <main className="app-content">
                <section className="emp_org_settings_sec emp_report_sec emp_circle_box_sec">
                    <div className="card emp-box emp-shadow">
                        <div className={"bg-white container-fluid mb-4"}>
                            <div className={"row mt-2"}>
                                <h1 className={"emp-type-h2 col"}>{this.props.device.name}</h1>
                            </div>

                            <div className={"row"}>
                                <div className={"col"}></div>
                                <div className={"col-4 col-sm-3"}>
                                    <Link to={editUrl}>
                                        <button className={"btn-outline-primary"}><span
                                            className={"emp-type-btn-primary"}>EDIT</span></button>
                                    </Link>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    {this.renderTabs()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }

    renderSummaryCard() {
        const {device} = this.props;
        const imageUrl = device.image_url && device.image_url.url ? device.image_url.url : `https://via.placeholder.com/250?text=${device.device_type?.name || 'Device'}`

        return (
            <div className="container-fluid">

                <div className="row mb-2">
                    <div className="col-12 col-sm-2"><img className="placeholder img-thumbnail"
                                                          src={imageUrl}></img>
                    </div>
                    <div className="col-12 col-sm-10">
                        <div className={"row"}>
                            <div className="col-12 col-xl-6">
                                {this.renderTextRow("Name", device.name)}
                                {this.renderTextRow("Device Type", device.device_type?.name)}
                                {this.renderTextRow("Description", device.description)}
                                {this.renderTextRow("Mac address", device.mac_address)}
                            </div>
                            <div className="col-12 col-xl-6">
                                {this.renderTextRow("Product id", device.product_id)}
                                {this.renderTextRow("Created Date", formatTimestampDateTime(device.created_at))}
                                {this.renderTextRow("Updated Date", formatTimestampDateTime(device.updated_at))}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}