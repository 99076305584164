import {CartesianChart} from "./CartesianChart";
import {Bar, BarChart, Line, LineChart} from "recharts";
import DashboardNumberCardV2 from "./DashboardNumberCardV2";
import React from "react";

function renderXLines(series){
    const lines = [];
    for (let key in series) {
        if(parseInt(key) >= 0) {
            lines.push(<Line
                key={key}
                stackId={key}
                dataKey={key}
                yAxisId={"1"}  //if we switch this with key.toString() or comment it out the page crashes
                name={series[key].name}
                stroke={series[key].colorHex}
                dot={false}
                animationDuration={300}
                strokeWidth={1.5}
            />)
        }
    }
    return lines;
}

function renderBars(series){
    const bars = [];
    for (let key in series) {
        if(parseInt(key) >= 0) {
            bars.push(<Bar
                key={key}
                stackId={key}
                dataKey={key}
                name={series[key].name}
                fill={series[key].colorHex}
                yAxisId={"1"}  //if we switch this with key.toString() or comment it out the page crashes
            />)
        }
    }
    return bars;
}

export const TypeToChartComponent = {
    line: ({
               series, height, unit, xAxisMin, xAxisMax, zoomedXMin, zoomedXMax,
               onMouseDown, onMouseUp, onMouseMove, width, layoutSize, chartData, yDomain
           }) => (
        <CartesianChart height={height} ChartComponent={LineChart} unit={unit} xAxisMin={xAxisMin}
                        layoutSize={layoutSize} chartData={chartData}
                        xAxisMax={xAxisMax} onMouseDown={onMouseDown} onMouseUp={onMouseUp} yDomain={yDomain}
                        onMouseMove={onMouseMove} zoomedXMin={zoomedXMin} zoomedXMax={zoomedXMax} width={width}>
            {renderXLines(series)}
            ))
        </CartesianChart>
    ),
    bar: ({
              series, height, unit, xAxisMin, xAxisMax, zoomedXMin, zoomedXMax, chartData, yDomain,
              onMouseDown, onMouseUp, onMouseMove, width, layoutSize
          }) => (
        <CartesianChart height={height} ChartComponent={BarChart} unit={unit} xAxisMin={xAxisMin}
                        layoutSize={layoutSize} chartData={chartData}
                        xAxisMax={xAxisMax} onMouseDown={onMouseDown} onMouseUp={onMouseUp} yDomain={yDomain}
                        onMouseMove={onMouseMove} zoomedXMin={zoomedXMin} zoomedXMax={zoomedXMax} width={width}>
            {renderBars(series)}
            ))
        </CartesianChart>
    ),
    number: ({series, chartData, loading, error}) => (
            <DashboardNumberCardV2
                                   data={chartData}
                                   colorHex={series["0"].colorHex}
                                   loading={loading}
                                   error={error}
                                   unit={series["0"].unit}
                                   instance_id={series["0"].instance_id}
            />
        )
}