import jwtDecode from 'jwt-decode';

export default function parseJWT (token) {
    let user = null;

    try {
        user = jwtDecode(token);
    } catch (e) {}

    return user;
}