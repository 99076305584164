import React from "react";
import {Field} from 'redux-form';
import {TextField} from "../../ui/Input/TextField";
import {ValidationFunction} from "../../utils/validateFunctions";


export default class FormInputComponent extends React.Component<{
    label: string,
    name: string,
    type: string,
    validate: ValidationFunction[],
    helpText: string | undefined,
    multiple: boolean,
}, {}> {
    constructor(props) {
        super(props);
    }

    renderField = ({input, label, type, multiple, helpText, meta: {touched, error}}) => {
        return (
            <TextField
                label={label}
                type={type}
                error={touched && error}
                errorLabel={error}
                {...input}
                multiple={multiple}
                helpText={helpText}
            />
        );
    }

    render() {
        return (
            <div className="form-group">
                <Field
                    name={this.props.name}
                    validate={this.props.validate}
                    component={this.renderField}
                    type={this.props.type}
                    label={this.props.label}
                    multiple={this.props.multiple}
                    helpText={this.props.helpText}
                />
            </div>
        )
    }
}

