import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import { withRouter } from 'react-router';
import UnlockAccountPage from "../../components/UnlockAccountPage/UnlockAccountPage";

//import './login.scss';

export const UnlockAccount = ({ authActions }) => (

    <div className="my-login-page">
        <UnlockAccountPage />
    </div>
);

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch)
});

export default withRouter(connect(null, mapDispatchToProps)(UnlockAccount));