import React from 'react';
import {FieldArray} from "redux-form";

export class NewSearchableDropDownField extends React.Component {
    constructor(props) {
        super(props);
        this.renderFields = this.renderFields.bind(this);
    }

    render() {
        return <div className="form-group">
            <div className="row mb-2">
                <div className="col-lg-12">
                    <FieldArray name={this.props.fieldName} component={this.renderFields.bind(this)} validate={this.props.validate}/>
                </div>
            </div>
        </div>
    };

    renderSearchInput() {
        const SearchInputComponent = this.props.searchInputComponent;
        return <SearchInputComponent/>
    }

    renderFields = ({fields, meta: {touched, error, submitFailed}}) => {
        const {render: SearchInput, placeholder} = this.props;
        const classNames = submitFailed && error ? "input_error" : "";
        return (
            <div>
                <div className="form-group search-member-container emp_position emp_position_inherit">
                    <label className="label_modifier">{this.props.title}</label>
                    {<SearchInput
                        placeholder={placeholder || ''}
                        handleChange={(suggestion) => fields.push(suggestion)}
                        isDisabled={this.props.isDisabled}
                        classNames={classNames}
                    />}
                </div>
                {submitFailed && error && <div className="form-group__error">{error}</div>}
                <ul>
                    {
                        fields.map((device, index) => {
                            const ItemFieldComponent = this.props.itemFieldComponent;
                            return (
                                <ItemFieldComponent item={fields.get(index)} onClickHandler={() => fields.remove(index)}
                                                    key={index} isDisabled={this.props.isDisabled}/>
                            );
                        })
                    }
                </ul>
            </div>
        );
    };
}