export const workEventConstants = {
    QUERY_WORK_EVENTS_DATA: 'QUERY_WORK_EVENTS_DATA',
    QUERY_WORK_EVENTS_DATA_FULFILLED: 'QUERY_WORK_EVENTS_DATA_FULFILLED',

    CREATE_WORK_EVENT: 'CREATE_WORK_EVENT',
    CREATE_WORK_EVENT_FULFILLED: 'CREATE_WORK_EVENT_FULFILLED',

    GET_WORK_EVENT: 'GET_WORK_EVENT',
    GET_WORK_EVENT_FULFILLED: 'GET_WORK_EVENT_FULFILLED',

    UPDATE_WORK_EVENT: 'UPDATE_WORK_EVENT',
    UPDATE_WORK_EVENT_FULFILLED: 'UPDATE_WORK_EVENT_FULFILLED',

    DELETE_WORK_EVENT: 'DELETE_WORK_EVENT',
    DELETE_WORK_EVENT_FULFILLED: 'DELETE_WORK_EVENT_FULFILLED',
};