import React from 'react';
import Tabs from "../../ui/Tabs/Tabs";
import {AssetAssignmentsTabPage} from "./Tabs/AssetAssignmentsTabPage";
import {AssetMeasurementsTabPage} from "./Tabs/AssetMeasurementsTabPage";
import {AssetEventsTabPage} from "./Tabs/AssetEventsTabPage";
import {formatTimestampDateTime} from "../../utils/formatHelper";
import {Link} from "react-router";
import PropTypes from "prop-types";

export class AssetTypeDetailPage extends React.Component {
    constructor(props) {
        super(props);
    }

    renderTextRow(label, text) {
        return (
            <div className="row mt-2">
                <span className="col-6 col-sm-4 col-md-4 col-lg-3 emp-type-subtitle1">{label}</span>
                <span className={"col-6 col-sm col-md col-lg emp-type-subtitle1"}>{text}</span>
            </div>
        );
    }

    renderTabs() {
        return (
            <>
                <Tabs>
                    <div className="emp-type-subtitle1" label="Summary">
                        <div className={"mt-4"}>
                            {this.renderDetailCard()}
                        </div>
                    </div>
                    {/*<div label="Assignments" className={"emp-type-subtitle1"}>*/}
                    {/*    <AssetAssignmentsTabPage deviceAssignments={this.props.deviceAssignments}/>*/}
                    {/*</div>*/}
                    <div className="emp-type-subtitle1" label="Measurements">
                        <AssetMeasurementsTabPage/>
                    </div>
                    <div className="emp-type-subtitle1" label="Events">
                        <AssetEventsTabPage/>
                    </div>
                </Tabs>
            </>
        )
    }

    render() {
        const {assetType} = this.props;
        return (
            <main className="app-content">
                <section className="emp_org_settings_sec emp_report_sec emp_circle_box_sec">
                    <div className="card emp-box emp-shadow">
                        <div className={"bg-white container-fluid mb-4"}>
                            <div className={"row mt-2"}>
                                <h1 className={"emp-type-h2 col"}>{assetType.name}</h1>
                            </div>

                            <div className={"row"}>
                                <div className={"col"}></div>
                                <div className={"col-4 col-sm-3"}>
                                    <Link to={`/asset_types/${assetType.id}/edit`}>
                                        <button className={"btn-outline-primary"}><span
                                            className={"emp-type-btn-primary"}>EDIT</span></button>
                                    </Link>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    {this.renderTabs()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }

    renderDetailCard() {
        const {assetType} = this.props;
        const imageUrl = assetType.imageUrl ? assetType.imageUrl : `https://via.placeholder.com/250?text=${assetType.name}`

        return (
            <div className="container-fluid">

                <div className="row mb-2">
                    <div className="col-12 col-sm-2"><img className="placeholder img-thumbnail"
                                                          src={imageUrl}></img>
                    </div>
                    <div className="col-12 col-sm-10">
                        <div className={"container-fluid"}>
                            {this.renderTextRow("Name", assetType.name)}
                            {this.renderTextRow("Description", assetType.description)}
                            {this.renderTextRow("Asset Category", assetType.assetCategory)}
                            {this.renderTextRow("Created Date", formatTimestampDateTime(assetType.createdAt))}
                            {this.renderTextRow("Updated Date", formatTimestampDateTime(assetType.updatedAt))}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

AssetTypeDetailPage.propTypes = {
    assetType: PropTypes.object.isRequired
}