import {auditLogConstants} from '../constants/auditLog.constants';

const initialState = {
    items: [],
    updated: false,
};

export const auditLogReducer = (state = initialState, action) => {
    switch (action.type) {
        case auditLogConstants.FETCH_AUDIT_LOG_DATA:
        case auditLogConstants.FETCH_AUDIT_LOG_DATA_FULFILLED:
            state = {
                ...state,
                items: action.payload,
                updated: true
            };
            return state;
        default:
            return state;
    }
};