export class ScheduledJobModel {
    id;
    tenant_id;
    owner_id;
    status;
    schedule_id;
    created_at;
    updated_at;
    owner;
    artifacts;
    metadata;

    constructor(id, tenant_id, owner_id, status, schedule_id, created_at, updated_at, owner, artifacts, metadata) {
        this.id = id;
        this.tenant_id = tenant_id;
        this.owner_id = owner_id;
        this.status = status;
        this.schedule_id = schedule_id;
        this.created_at = created_at;
        this.updated_at = updated_at;
        this.owner = owner;
        this.artifacts = artifacts;
        this.metadata = metadata;
    }

    static fromJson(json) {
        return new ScheduledJobModel(json['id'],
            json['tenant_id'],
            json['owner_id'],
            json['status'],
            json['schedule_id'],
            json['created_at'],
            json['updated_at'],
            json['owner'],
            json['artifacts'],
            json['metadata']
        )
    }
}

export const ScheduledJobStatus = Object.freeze({
    UNSUBMITTED: "UNSUBMITTED",
    RUNNING: "RUNNING",
    COMPLETED: "COMPLETED",
    FAILED: "FAILED"
});
