import {deviceAssignmentConstants} from '../constants/deviceAssignments.constants.js';

const initialState = {
    deviceAssignment: {},
    deviceAssignments: [],
    updated: false,
};

export const deviceAssignmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case deviceAssignmentConstants.GET_DEVICE_ASSIGNMENT_FULFILLED:
            state = {
                ...state,
                deviceAssignment: action.payload,
                updated: true
            };  
            return state;
        case deviceAssignmentConstants.LIST_DEVICE_ASSIGNMENTS_FULFILLED:
            state = {
                ...state,
                deviceAssignments: action.payload,
                updated: true
            };
            return state;
        case deviceAssignmentConstants.UPDATE_DEVICE_ASSIGNMENT_FULFILLED:
            state = {
                ...state,
                deviceAssignment: action.payload,
                deviceAssignments: [...state.deviceAssignments.filter(assignment => assignment.id !== action.payload.id), action.payload],
                updated: true
            };
            return state;
        case deviceAssignmentConstants.CREATE_DEVICE_ASSIGNMENT_FULFILLED:
            state = {
                ...state,
                deviceAssignment: action.payload,
                deviceAssignments: [...state.deviceAssignments, action.payload],
                updated: true
            };
            return state;

        case deviceAssignmentConstants.DELETE_DEVICE_ASSIGNMENT_FULFILLED:
            state = {
                ...state,
                deviceAssignments: state.deviceAssignments.filter(assignment => assignment.id !== action.payload.id),
                updated: true
            }
        default:
            return state;
    }
};