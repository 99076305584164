import React from "react";

export class AreaMeasurementsTabPage extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <>
                After 'while, <em>Crocodile</em>!
            </>
        )
    }

}