import React from "react";
import {ListView} from "../../../ui/ListView/ListView";

export class AssetAssignmentsTabPage extends React.Component {
    constructor(props) {
        super(props);

    }

    renderTextRow(label, text) {
        return (
            <div className="row mt-2">
                <span className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-3 emp-type-subtitle1">{label}</span>
                <span className={"col-6 col-sm col-md col-lg col-xl emp-type-subtitle1"}>{text}</span>
            </div>
        );
    }

    renderItem = (item, index) => {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-sm-2">
                        <img className={"placeholder img-thumbnail"}
                             src={item.iconUrl || "https://via.placeholder.com/150"}/>
                    </div>
                    <div className="col-12 col-sm-10">
                        {this.renderTextRow("Name", item.device.name)}
                        {this.renderTextRow("Device Type", item.device.deviceType)}
                        {this.renderTextRow("Device Id", item.device.identity)}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {deviceAssignments} = this.props;

        return (
            <>
                <ListView
                    datasource={deviceAssignments}
                    renderItem={this.renderItem}
                />
            </>
        )
    }
}