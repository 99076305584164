import axios from "axios";
import storage from "../utils/storage";
import { BASE_URL } from "../config";
import  DeviceTypeMetric  from "./model/DeviceTypeMetric";
import { buildAxiosErrorHandlerConfig } from "../utils/utils";

const convertApiResponseToDeviceTypeMetric = (data: any): DeviceTypeMetric => {
    return {
        device_type_id: data.device_type_id,
        name: data.name,
        display_name: data.display_name,
        description: data.description,
    };
};

export const listDeviceTypeMetrics = (deviceTypeId: string, params: any) => {
    return axios
        .get(`${BASE_URL()}/api/v1/device_types/${deviceTypeId}/device_type_metrics`, {
            params: params,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: storage.get("token"),
            },
        })
        .then((response) =>
            response.data.items.map((item: any) =>
                convertApiResponseToDeviceTypeMetric(item)
            )
        );
};

export const getDeviceTypeMetric = (deviceTypeId: string, name: string) => {
    return axios
        .get(`${BASE_URL()}/api/v1/device_types/${deviceTypeId}/device_type_metrics/${name}`, buildAxiosErrorHandlerConfig({
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: storage.get("token"),
            },
            defaultErrorHandler: true,
        }))
        .then((response) => convertApiResponseToDeviceTypeMetric(response.data));
};

export const createDeviceTypeMetric = (deviceTypeId: string, values: any) => {
    return axios
        .post(`${BASE_URL()}/api/v1/device_types/${deviceTypeId}/device_type_metrics`, values, buildAxiosErrorHandlerConfig({
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: storage.get("token"),
            },
            defaultErrorHandler: true,
        }))
        .then((response) => convertApiResponseToDeviceTypeMetric(response.data));
};

export const updateDeviceTypeMetric = (deviceTypeId: string, name: string, values: any) => {
    return axios
        .put(`${BASE_URL()}/api/v1/device_types/${deviceTypeId}/device_type_metrics/${name}`, values, buildAxiosErrorHandlerConfig({
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: storage.get("token"),
            },
            defaultErrorHandler: true,
        }))
        .then((response) => convertApiResponseToDeviceTypeMetric(response.data));
};

export const deleteDeviceTypeMetric = (deviceTypeId: string, name: string) => {
    return axios
        .delete(`${BASE_URL()}/api/v1/device_types/${deviceTypeId}/device_type_metrics/${name}`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: storage.get("token"),
            },
        });
};
