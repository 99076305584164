import axios from 'axios';
import swal from 'sweetalert';
import {lowerCase} from 'lodash';
import store from '../../store/store';
import * as authActions from '../../actions/authActions';

export default function errorHandler (error) {
    if (axios.isCancel(error)) {
        return Promise.reject(error);
    }

    switch (error.response.status) {
        case 400:
            if (error.config.defaultErrorHandler) {
                const errors = error.response.data.errors || '';
                swal({
                    icon: 'warning',
                    title: 'Bad Request.',
                    text: lowerCase(errors) !== 'bad request' ? errors : '',
                    className: 'error-handler-modal'
                });
            }
            break;
        case 401:
            // Do not redirect to login page on login request itself
            if (error.config.defaultErrorHandler!==false) {
                console.log('unauthorized, logging out ...');
                store.dispatch(authActions.logout('/auth/login'));
            }
            break;
        case 403:
            swal({
                icon: 'error',
                title: 'Oops.',
                text: "The action you've requested is restricted. Please refer to your system administrator.",
                className: 'error-handler-modal'
            });
            break;
        case 404:
            swal({
                icon: 'error',
                title: 'Oops.',
                text: "Seems like the resource you requested does not exist.",
                className: 'error-handler-modal'
            });
            break;
        case 500:
            swal({
                icon: 'error',
                title: 'Oops.',
                text: 'Seems like something went wrong!',
                className: 'error-handler-modal'
            });
            break;
        case 504:
            swal({
                icon: 'error',
                title: 'Oops.',
                text: 'Sorry, could not access the external resource to refine the data for your request, please try again later!',
                className: 'error-handler-modal'
            });
            break;
    }
    return Promise.reject(error.response);
}
