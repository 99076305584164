import axios from 'axios';
import store from '../store/store';
import storage from "../utils/storage";
import {BASE_URL} from '../config';

export const createUser = (values) => {
    return axios.post(`${BASE_URL()}/api/v1/users`, values, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response.data;
    }).catch((e) => {
        return null;
    });
};

// To Do: should really be able to combine updateUser and updateUserDetails.
// Need to understand how updateUserDetails is called.
export const updateUser = (id, values) => {
    return axios.put(`${BASE_URL()}/api/v1/users/${id}/update_user`, values, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response.data;
    }).catch((e) => {
        return null;
    });
};

export const updateUserDetails = (credentials) => {
    let state = store.getState();
    const formData = new FormData();
    if (credentials.user.profile_picture)
        formData.append('user[profile_picture]', credentials.user.profile_picture[0]);
    if (credentials.user.first_name)
        formData.append('user[first_name]', credentials.user.first_name);
    if (credentials.user.last_name)
        formData.append('user[last_name]', credentials.user.last_name);
    if (credentials.user.password)
        formData.append('user[password]', credentials.user.password);
    if (credentials.user.password_confirmation)
        formData.append('user[password_confirmation]', credentials.user.password_confirmation);
    return axios.put(`${BASE_URL()}/api/v1/users/${state.authReducer.user.user_id}`, formData, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response.data;
    });
};
export const searchMember = (name) => {
    //let state = store.getState();
    //let page = state.eventsReducer.page_no;
    //let form = state.form.eventSearchForm;
    //let search = {
    //    search:{
    //        location:  event_values.event &&  event_values.event.location ?  event_values.event.location : state.eventsReducer.event.location,
    //        category: event_values.event &&  event_values.event.category ? event_values.event.category : state.eventsReducer.event.category,
    //        date: event_values.event && event_values.event.date ? event_values.event.date : ''
    //    }
    //};
    return axios.get(`${BASE_URL()}	/api/v1/users`, {
        params: {name: name},
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};
export const fetchUserData = (values) => {
    return axios.get(`${BASE_URL()}/api/v1/users/user_list`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response.data;
    });

};

export const deleteUser = (values) => {
    return axios.delete(`${BASE_URL()}/api/v1/users/${values}`, {
        defaultErrorHandler: true,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response.data;
    });

};

export const getMemberGroups = () => {
    return axios.get(`${BASE_URL()}/api/v1/users/get_member_groups`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response.data.items;
    });
}

export const getUserById = (id) => {
    return axios.get(`${BASE_URL()}/api/v1/users/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response.data;
    });
}

