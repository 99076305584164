import {workEventConstants} from '../constants/workEvents.constants';

const initialState = {
    workevents: [],
    workEvent: {}
};

export const workEventReducer = (state = initialState, action) => {
    let newWorkEvents;
    switch (action.type) {
        case workEventConstants.QUERY_WORK_EVENTS_DATA:
        case workEventConstants.QUERY_WORK_EVENTS_DATA_FULFILLED:
            state = {
                ...state,
                workevents: action.payload
            };
            return state;
        case workEventConstants.CREATE_WORK_EVENT:
        case workEventConstants.CREATE_WORK_EVENT_FULFILLED:
            state = {
                ...state,
                workevents: action.payload
            };
            return state;
        case workEventConstants.DELETE_WORK_EVENT:
        case workEventConstants.DELETE_WORK_EVENT_FULFILLED:
            newWorkEvents = state.workevents.filter(workevent => workevent.id !== action.payload.id);
            state = {
                ...state,
                workevents: newWorkEvents
            };
            return state;
        case workEventConstants.GET_WORK_EVENT:
        case workEventConstants.GET_WORK_EVENT_FULFILLED:
            state = {
                ...state,
                workEvent: action.payload
            };
            return state;
        default:
            return state;
    }
};