export default class AuditLog {
    id
    timestamp
    client_id
    access_type
    modified_by
    resource
    service
    version
    updated_at
    record

    constructor(id, timestamp, client_id, access_type, modified_by, resource, version, updated_at, record, service="") {
        this.id = id;
        this.timestamp = timestamp;
        this.client_id = client_id;
        this.access_type = access_type;
        this.modified_by = modified_by;
        this.resource = resource;
        this.version = version;
        this.updated_at = updated_at;
        this.record = record;
        this.service = service;
    }


    static fromJson(payload) {
        return new AuditLog(
            payload['id'],
            payload['timestamp'],
            payload['client_id'],
            payload['access_type'],
            payload['modified_by'],
            payload['resource'],
            payload['version'],
            payload['updated_at'],
            payload['record'],
            payload['service']
        )
    }
}