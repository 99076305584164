import {WorkActivityApi} from "../api/workActivityApi";
import {workActivityConstants} from "../constants/workActivity.constants";

const workActiviyApi = new WorkActivityApi();

export const listWorkActivities = () => {
    return {
        type: workActivityConstants.LIST_WORK_ACTIVITIES,
        payload: workActiviyApi.listWorkActivities()
    }
};

export const getWorkActivity = (workActivityId) => ({
    type: workActivityConstants.GET_WORK_ACTIVITY,
    payload: workActiviyApi.getWorkActivity(workActivityId)
});

