import React from 'react';
import {bindActionCreators} from 'redux';
import { Link } from 'react-router';
import {connect} from 'react-redux';
import {Form, reduxForm} from 'redux-form';
import * as AuthActions from '../../actions/authActions';
import validate from './validate';
//import asyncValidate from './asyncValidate';
import {InputField} from '../InputField/InputField';
import {RadioBtn} from '../RadioBtn/RadioBtn';
import * as $ from 'jquery';

export const SignUpForm = ({ initialValues,handleSubmit, submitting, asyncValidating }) => (
	<section className="my-login-section">

		<div className="container-fluid">
			<div className="row">
				<div className="col-lg-7 col-md-7 col-sm-12">
					<div className="bg-hero-sec h-100 max-vh">
						<div className="text-holder text-white">
							<h1 className="mb-4">BigSponsorships</h1>
							<h2 className="mb-2">It has survived not only  five centuries, but also the leap</h2>
							<p>Into electronic typesetting, remaining essentially unchanged. It was popularised in
								with the release of Letraset sheets containing Lorem Ipsum passages</p>
						</div>
					</div>
				</div>
				<div className="clearfix"></div>
				<div className="col-lg-5 col-md-5 col-sm-12 d-flex align-items-center">
					<div className="card-wrapper">
						<div className="card fat">
							<div className="card-body">
								<div className="form-heading">
									<h2 className="text-primary mb-5">Sign Up</h2>
								</div>
								<Form className="sign-up-form" onSubmit={handleSubmit} noValidate={true}>
									<div className="row">
										<div className="col-md-6 col-md-12">
											<InputField
												name="user[first_name]"
												placeholder="First Name"
												className="pl-0 rounded-0 form-control border-right-0 border-left-0 border-top-0"
											/>
										</div>
										<div className="col-md-6 col-md-12">
											<InputField
												name="user[last_name]"
												placeholder="Last Name"
												className="pl-0 rounded-0 form-control border-right-0 border-left-0 border-top-0"
											/>
										</div>
									</div>

									<InputField
										type="email"
										name="user[email]"
										placeholder="E-Mail"
										className="pl-0 rounded-0 form-control border-right-0 border-left-0 border-top-0"
									/>

									<InputField
										type="password"
										name="user[password]"
										placeholder="Password"
										className="pl-0 rounded-0 form-control border-right-0 border-left-0 border-top-0"
									/>

									<InputField
										type="password"
										name="user[password_confirmation]"
										placeholder="Confirm Password"
										className="pl-0 rounded-0 form-control border-right-0 border-left-0 border-top-0"
									/>
									<div className="form-check-box mb-4">
										<RadioBtn
											name="user[role]"
											type="radio"
											value="2"
											label="I am a Brand or Marketing Agency"
											labelClass="cst-container brand-label"
											spanClass="cst-checkmark"
											handleChange={(e)=>{
                                                $('.brand-label').addClass('checked-role');
                                                $('.organizer-label').removeClass('checked-role');
											}}
											/>
									</div>
									<div className="form-check-box mb-4">
										<RadioBtn
											name="user[role]"
											type="radio"
											value="1"
											label="I am a Event Organiser"
											labelClass="cst-container organizer-label"
											spanClass="cst-checkmark"
											errorMsg="true"
											handleChange={(e)=>{
												$('.brand-label').removeClass('checked-role');
												$('.organizer-label').addClass('checked-role');
											}}
											/>
									</div>

									<button type="submit" disabled={submitting || asyncValidating} className="btn btn-primary btn-block">Register</button>
									{<div className="mt-5 text-center">
										Already have an account? <Link onClick={() => initialValues.user.role=''} to="/auth/login" >Login</Link>
									</div> }
								</Form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
);

const mapStateToProps = (state) => {
	if (state.authReducer.user.role) {
		state.form.signUpForm.initial.user.role = state.authReducer.user.role;
		if (['1','2'].includes(state.authReducer.user.role))
			state.authReducer.user.role = '';
	}
	return ({
		auth: state.authReducer
	});
};

const mapDispatchToProps = (dispatch) => ({
	authActions: bindActionCreators(AuthActions, dispatch)
});

export default reduxForm({
	form: 'signUpForm',
	validate,
	initialValues: {user: {}},
	onSubmit: (values, dispatch) => dispatch(AuthActions.signup(values))
})(connect(mapStateToProps, mapDispatchToProps)(SignUpForm));