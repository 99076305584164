import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as workEventActions from '../../actions/workEventActions';
import * as failureModeActions from '../../actions/failureModeActions';
import * as workActivityActions from '../../actions/workActivityActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {browserHistory, withRouter} from 'react-router';
import WorkEventPage from '../../components/WorkEvent/WorkEventPage';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import {WorkActivityModel} from "../../api/model/WorkActivity";
import {FailureModeModel} from "../../api/model/FailureMode";

export class WorkEventContainer extends React.Component<{
    workActivities: WorkActivityModel[],
    failureModes: FailureModeModel[],
    auth: { isLoggedIn: boolean, user },
    authActions,
    workEventActions,
    workActivityActions,
    failureModeActions
},{ loading: boolean }> {
    constructor(props){
        super(props);
        this.state={
            loading: false
        }
        this.onFormSubmit = this.onFormSubmit.bind(this)

    }

    componentDidMount() {
        Promise.all([
            this.props.failureModeActions.listFailureModes(),
            this.props.workActivityActions.listWorkActivities()
        ]);
    }

    async onFormSubmit(values){
        this.setState({loading: true});
        try{
            await this.props.workEventActions.createWorkEvent(values);
        } catch (e){
            console.log("err:", e)
        }
        this.setState({loading: false});
        //TODO: browserHistory.push();
    }

    render() {
        const {authActions, auth } = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <WorkEventPage
                        workActivities={this.props.workActivities}
                        failureModes={this.props.failureModes}
                        onFormSubmit={this.onFormSubmit}/>
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.authReducer,
    failureModes: state.failureModeReducer.failureModes,
    workActivities: state.workActivityReducer.workActivities
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    workEventActions: bindActionCreators(workEventActions, dispatch),
    failureModeActions: bindActionCreators(failureModeActions, dispatch),
    workActivityActions: bindActionCreators(workActivityActions, dispatch),
});


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(WorkEventContainer));