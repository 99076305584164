import React from 'react';

interface WorkflowTypeTagProps {
    type: string;
}

export default function WorkflowTypeTag(props :WorkflowTypeTagProps): React.ReactElement {
    if (!props.type || !props.type.length) return <div/>;

    return (
        <div className={`emp-tag align-self-center emp-tag-geekblue`}>
            {props.type}
        </div>
    )
}