import axios from 'axios';
import {BASE_URL} from '../config';
import store from '../store/store';
import storage from "../utils/storage";

export const login = (credentials) => {
    return axios.post(`${BASE_URL()}/users/sign_in`, credentials,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: false
    });
};

export const change_password = (credentials) => {
    let urlParams = getUrlParams();
    credentials.user.reset_password_token = urlParams['reset_password_token'];
    return axios.patch(`${BASE_URL()}/users/password`, credentials,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });
};

export const updatePassword = (credentials) => {
    let state = store.getState();
    return axios.put(`${BASE_URL()}/api/v1/users/${state.authReducer.user.id}`, credentials ,{ headers: {
        'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
    }}).then(response => {
        return response.data;
    });

};

export const signup = (credentials) => {
    return axios.post(`${BASE_URL()}/users`, credentials,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};

export const sendResetPasswordLink = (email) => {
    return axios.post(`${BASE_URL()}/users/password`, { email },{headers:
    { xsrfHeaderName: 'X-XSRF-TOKEN',
        xsrfCookieName: 'XSRF-TOKEN'
    }});
};

export const resetPassword = (token, email, password) => {
    return axios.post(`/auth/password/reset/${token}`, { email, password });
};

export const unlock_account = () => {
    let urlParams = getUrlParams();
    let unlock_token = urlParams['unlock_token'];

    return axios.get(`${BASE_URL()}/users/unlock`,{
        params: {
            unlock_token: unlock_token
        },
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    });
};

function getUrlParams() {
    var match,
        pl     = /\+/g,  // Regex for replacing addition symbol with a space
        search = /([^&=]+)=?([^&]*)/g,
        decode = function (s) { return decodeURIComponent(s.replace(pl, " ")); },
        query  = window.location.search.substring(1);

    let urlParams = {};
    while (match = search.exec(query))
        urlParams[decode(match[1])] = decode(match[2]);
    return urlParams;
}