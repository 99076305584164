import React from 'react'

export const StatusComponent = ({ formStatus }) => {
  const classes = {
    ready: 'btn-outline-primary',
    in_progress: 'btn-warning btn-in-progress',
    completed: 'btn-success'
  }

  const values = {
    ready: 'Ready to start',
    in_progress: 'In Progress',
    completed: 'Complete'
  }
  return <p className={`btn ${classes[formStatus]}`}> {values[formStatus]} </p>
}
