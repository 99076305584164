import React from "react";
import {Field} from 'redux-form';
import {ValidationFunction} from "../../utils/validateFunctions";


export default class FormCheckBoxComponent extends React.Component<{
    name: string,
    helpText: string | undefined,
    items: { value: any, label: string }[],
    default: { value: any, label: string }[],
    validate: ValidationFunction[],
    label: string,
}, {}> {
    constructor(props) {
        super(props);
    }

    renderCheckboxGroup({input, items, meta: {submitFailed, error}}) {
        const checkboxes = items.map((item, index) => (
            <label key={index} className="col-6">
                <input
                    type="checkbox"
                    name={`${input.name}[${index}]`}
                    value={item.value}
                    checked={input.value.indexOf(item.value) !== -1}
                    onChange={(event) => {
                        const selectedValues = [...input.value];
                        if (event.target.checked) {
                            selectedValues.push(item.value);
                        } else {
                            selectedValues.splice(selectedValues.indexOf(item.value), 1);
                        }
                        return input.onChange(selectedValues);
                    }}
                />
                <span>{item.label}</span>
            </label>
        ));

        const submissionError = submitFailed && error;

        return <div className={"col-12"}>
            <div className={(submissionError ? ' input_error' : '')}>{checkboxes}
            </div>
            {submissionError && <span className="emp-type-caption emp-color-error">{error}</span>}</div>;
    };

    render() {
        this.props.items.sort((a, b) => a.label.localeCompare(b.label));
        return (
            <div className="form-group">
                <label className="label_modifier">{this.props.label}</label>
                <div className={"emp-checkbox-container"}>
                    <Field name={this.props.name} component={this.renderCheckboxGroup.bind(this)} type="checkbox"
                           items={this.props.items} validate={this.props.validate}/>
                </div>
                <span className="label_modifier">{this.props.helpText}</span>
            </div>
        )
    }
}
