import React from "react";
import {DateTimeField} from "../DateTimeField/NewDateTimeField";
import {TextField} from "../../ui/Input/TextField";
import {ValidationFunction} from "../../utils/validateFunctions";


export default class FormDateTimeComponent extends React.Component<{
    name: string,
    label: string,
    className?: string,
    parentClassName?: string,
    validate: ValidationFunction[],
}, {}> {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div className="form-group">
                <div className="form-group col-xl-6 col-lg-8 col-sm-6 pl-0 pr-0">
                    <DateTimeField
                        type="text"
                        name={this.props.name}
                        className={`form-control input_modifier border-0 app-title-shadow datepicker--input ${this.props.className}`}
                        parentClass={`app-title-shadow workflow-schedule-date-field-border rounded ${this.props.parentClassName}`}
                        renderInput={(inputProps) => <TextField label={this.props.label} {...inputProps}/>}
                        validate={this.props.validate}
                    />
                </div>
            </div>
        )
    }
}