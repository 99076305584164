import React, {Component} from "react"; 
import {browserHistory} from 'react-router';
import ReactTable from 'react-table';
import {Link} from 'react-router';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {isAdmin} from '../../utils/utils';

import * as CustomDashboardActions from "../../actions/customDashboardAction";
import LoadingMessage from "../LoadingMessage/LoadingMessage";
import confirmDeleteAlertDialogue from './confirmDeleteAlertDialogue';

import {DASHBOARD_GRID_BPS, DASHBOARD_GRID_COLS} from "../../config";


class NewDashboardListView extends Component<{
    customDashboardActions,
    auth,
    customDashboardReducer
},{
    loading: boolean,
    columns: Array<object>,
    selectedRows: object,
    bulkSelect: boolean,
}>{

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            columns: [],
            selectedRows: {},
            bulkSelect: false,
        }
    }

    async componentDidMount() {
        await this.props.customDashboardActions.listCustomDashboards();
        
        const isDisabled = isAdmin(this.props.auth.user) ? false : true;
        
        const columns: Array<object> = [];
        if(!isDisabled){
            columns.push({
                Header: this.renderBulkSelect(),
                accessor: 'checkAction', columnName: ' ', width: 75,
                sortable: false,
                Cell: (row) => this.styleCheckboxCell(row.value),
            });
        } 

        columns.push(
            {
                Header: () => this.styleCell('Dashboard Title', null),
                accessor: 'title',
                columnName: 'Dashboard Title',
                filterable: true,
                Cell: (row) => this.styleTitleCell(row.value),
                filterMethod: (filter, row) => {
                    const id = filter.pivotId || filter.id
                    return row[id] !== undefined ? String(row[id].title.toLowerCase()).includes(filter.value.toLowerCase()) : true
                  },
                Filter: ({ filter, onChange }) =>
                <input
                    onChange={event => onChange(event.target.value)}
                    style={{ margin: "auto", width: "50%"}}
                    value={filter ? filter.value : ""}
                    className="form-control"
                    placeholder="Search..."
                />
            },
            {
                Header: () => this.styleCell('Actions', null),
                accessor: 'deleteAction', width: 125,
                columnName: 'Actions',
                sortable: false,
                Cell: (row) => this.styleDeleteActionCell(row.value, 'text-center', isDisabled)
            }
        );
        this.setState({...this.state, columns, loading: false});
    }

    async handleAddNewDashboard() {
        this.setState({...this.state, loading: true});
        const newDashboard = {
            dashboard_title: "New Dashboard", 
            owner: this.props.auth.user.user_id.toString(),
            breakpoints: DASHBOARD_GRID_BPS,
            columns: DASHBOARD_GRID_COLS,
            widgets:[]
        };
        try {
            await this.props.customDashboardActions.createCustomDashboard(newDashboard);
        }
        catch(e){
            console.log(e);
            this.setState({...this.state, loading: false});
            return;
        }
        return browserHistory.push(`/environment/custom_dashboard/${this.props.customDashboardReducer.selectedDashboard.id}`);
    }

    styleCell = (label, classes) => {
        if (classes)
            return <div className="alert-table-text-cell justify-content-center">{label}</div>
        return <div>{label}</div>
    }

    styleCheckboxCell = (id) => {
        return (
            <div className="table_checkbox_container">
                <input
                    checked={this.state.selectedRows[id] || false}
                    type="checkbox"
                    className="table_checkbox"
                    onChange={event => {
                        const selections = {...this.state.selectedRows};
                        if (selections[id]){
                            delete selections[id]
                        }
                        else {
                            selections[id] = true;
                        }
                        this.setState({
                            ...this.state,
                            selectedRows: selections,
                        });
                    }}
                />
            </div>
        )
    }

    styleTitleCell = (value) => {
        return (
            <div className="alert-table-text-cell justify-content-center">
                <Link to={`/environment/custom_dashboard/${value.dashboard_id}`}>
                    <span className="text-blue">{value.title}</span>
                </Link>
            </div>
        )
    }

    styleDeleteActionCell = (value, classes, isDisabled = false) => {
        const handleClick = async (e) => {
            if (isDisabled) {
                e.preventDefault();
            } else {
                let confirm = await confirmDeleteAlertDialogue(`Delete Widget "${value.title}"?`);
                if (!confirm) return;

                this.setState({...this.state, loading: true});
                try{
                    await this.props.customDashboardActions.deleteCustomDashboard(value.dashboard_id);
                }
                catch(e){
                    console.log(e);
                    await this.props.customDashboardActions.listCustomDashboards();
                }
                this.setState({...this.state, loading: false});
            }
        }
        return (
            <div className={classes}>
                <div onClick={handleClick.bind(this)}>
                    <img src={require("../../styles/images/icon/icn_trash.png")} alt="delete"/>
                </div>
            </div>
        )
    }

    renderBulkSelect() {
        return (
            <>
                {isAdmin(this.props.auth.user) && <div className="table_checkbox_container mb-1">
                    <input
                        type="checkbox"
                        className="table_checkbox"
                        onChange={() => this.updateSelectedRows(!this.state.bulkSelect)}
                    />
                </div>
                }
            </>

        );
    }

    updateSelectedRows = (checked) => {
        let selections = {};
        if (checked){
            this.props.customDashboardReducer.dashboards.forEach(dashboard => selections[dashboard.id] = true)
        }
        this.setState({
            ...this.state,
            selectedRows: selections,
            bulkSelect: checked
        });
    }

    createTableListItems() {
        // To Do: this should be maybe kept in state so not re-mapped on each render?
        const tableData = this.props.customDashboardReducer.dashboards.map((dashboard) => {
            return (
                {
                    title: {title: dashboard.dashboard_title, dashboard_id: dashboard.id},
                    deleteAction: {title: dashboard.dashboard_title, dashboard_id: dashboard.id},
                    checkAction: dashboard.id
                }
            )
        });
        return tableData.sort(function(a,b){
            if(a.title.title.toUpperCase() < b.title.title.toUpperCase()) { return -1; }
            if(a.title.title.toUpperCase() > b.title.title.toUpperCase()) { return 1; }
            return 0;
        });
    }

    async bulkDeleteAlert() {
        const message = Object.keys(this.state.selectedRows).length === this.props.customDashboardReducer.dashboards.length ? "Delete All Dashboards" : "Delete Selected Dashboards";
        let confirm = await confirmDeleteAlertDialogue(message);
        if (confirm) {
            this.setState({...this.state, loading: true});
            try{
                await this.props.customDashboardActions.deleteCustomDashboards(this.state.selectedRows);
            }
            catch(e){
                console.log(e);
                await this.props.customDashboardActions.listCustomDashboards();
            }
            this.setState({...this.state, selectedRows: {}, bulkSelect: false, loading: false});
        }
    }
    
    render(){
        if (this.state.loading) {
            return <LoadingMessage/>;
        }

        return (
            <main className="app-content">
                <div className="app-title border-bottom">
                    <h1>Dashboards</h1>
                </div>
                <section className="emp_org_settings_sec emp_report_sec">
                    <div className="row emp-dashboard-nav-area col-md-12">
                        <button className="btn btn-primary" onClick={this.handleAddNewDashboard.bind(this)}><i className="fa fa-plus"></i>{"  "}Create New Dashboard</button>
                        {Object.keys(this.state.selectedRows).length > 0 && <button className="btn btn-primary" onClick={() => this.bulkDeleteAlert()}><i className="fa fa-trash-o fa-lg pr-1"></i>{"  "}Delete</button>}
                    </div>
                    <div className="table-responsive-sm">
                        <ReactTable
                            columns={this.state.columns}
                            data={this.createTableListItems()}
                            className='-striped'
                            showPagination={true}
                            minRows={5}
                        />
                    </div>
                </section>
            </main>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        customDashboardReducer: state.customDashboardReducer,
        auth: state.authReducer,
    });
};

const mapDispatchToProps = (dispatch) => ({
    customDashboardActions: bindActionCreators(CustomDashboardActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewDashboardListView);