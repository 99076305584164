import React, {Component} from "react";
import {connect} from 'react-redux';
import MetricWidget from "./MetricWidget";
import MetricChart from "./MetricChart";
import {Field, FieldArray, formValueSelector, reduxForm, change} from "redux-form";
import {browserHistory} from 'react-router';
import {ApiDevice} from "../../api/model/Device";
import {GroupModel} from "../../api/model/GroupModel";
import {NestedSelectField, Items} from "../../ui/Input/NestedSelectField";
import {SelectField} from "../../ui/Input/SelectField";
import {TextField} from "../../ui/Input/TextField";
import {pickRandomColor} from "../../utils/utils";
import {DASHBOARD_COLORS} from "../../config";

const CHART_TYPE_ITEMS = [
    {value: "number", label: "Number"},
    {value: "line", label: "Line"},
    {value: "bar", label: "Bar"},
];

const titleField = ({input, label, meta: {touched, error}}) => {
    return (
        <TextField label={label} error={touched && error} errorLabel={error} {...input}/>
    );
}

function createDeviceItems(groups: GroupModel[], apiDevices: ApiDevice[]): Items {
    return groups.map(group => ({
        value: group.id,
        label: group.name,
        items: apiDevices
            .filter(device => device.organization_id === group.organization_id)
            .map(device => ({
                value: device.mac_address, // use the mac_address as the value
                label: device.name
            }))
    }));
}


class WidgetForm extends Component<{
    handleSubmit: any,
    metric: string,
    instance_id: string,
    widget_type: string,
    label: string,
    color_hex: string,
    reset: () => void,
    auth: any,
    groups: GroupModel[],
    devices: ApiDevice[],
    metricReducer: any,
    dashboardId: string,
    onWidgetSubmit: any,
    submitButtonLabel: string,
    id: string,
    properties: any,
    changeFieldValue: any,
},{}> {

    constructor(props) {
        super(props);
        this.state = {}
    }

    renderMetricSelectField({input, items, label, meta: {touched, error}}){
        return <SelectField
            {...input}
            error={touched && error}
            errorLabel={error}
            items={items}
            label={label}
        />
    }

    renderSelectField({input, items, label, meta: {touched, error}}){
        return <SelectField
            {...input}
            error={touched && error}
            errorLabel={error}
            items={items}
            label={label}
        />
    }

    displayCard() {

        return (
            <>
                <div className={"row col-12 ml-0 mb-2 pr-0 pl-0"}>
                    <div className={"col-8 pl-0"}>
                        <Field name="label" label={"Label (Optional)"} component={titleField} />
                    </div>
                    <div className={"col-4 pr-0"}>
                        <Field
                            name={"widget_type"}
                            items={CHART_TYPE_ITEMS}
                            label={"Chart type"}
                            component={this.renderSelectField.bind(this)}
                        />
                    </div>
                </div>
                <div className="card shadow emp-dashboard-device-card text-center">
                    <div className="emp-device-card-settings" onClick={() => this.props.reset()}>
                        <i className="fa fa-trash-o fa-lg" aria-hidden="true"></i>
                    </div>
                    {
                        <MetricWidget
                            start_time={new Date((new Date()).valueOf() - 1000 * 3600).toISOString()}
                            end_time={new Date().toISOString()}
                            period={'5m'}
                            limit={1000}
                            properties={this.props.properties}
                            render={({data, loading, error}) =>
                                <MetricChart
                                    title={this.props.label ? this.props.label : this.props.instance_id}
                                    chartHeight={300}
                                    chartType={this.props.widget_type}
                                    data={data}
                                    loading={loading}
                                    error={error}
                                />
                            }/>
                    }
                </div>
            </>
        );
    }

    renderDeviceField({input, label, meta: {touched, error}}) {
        const items = createDeviceItems(this.props.groups, this.props.devices);

        return (
            <NestedSelectField
                items={items}
                label={label}
                error={touched && error}
                errorLabel={error}
                {...input}
            />);
    }

    renderDeviceArrayField({ fields, meta: { error, submitFailed } }) {
        const metricItems = this.props.metricReducer.metrics.items.map(item => ({label: item, value: item}));
        const usedColors: string[] = this.props.properties?.metrics.map(metric => metric.color_hex);
        const newColor: string = pickRandomColor(DASHBOARD_COLORS, usedColors || []);

        const newMetric = {
            unit:"",
            color_hex: newColor,
            metric: {}
        }

        const displayCard = this.props.properties?.metrics?.length && this.props.properties.metrics[0].metric?.metric_name;
        const devicesLabel = displayCard ? "List of Device(s):" : "Choose a Device and Metric:";

        return (<>
                <div className="row">
                    <div className="pl-0 emp-type-subtitle1 mb-3">{devicesLabel}</div>
                </div>
                    {fields.map((deviceSelect, index)=>{
                        const showDelete = fields.length > 1;
                        return (
                            <div className={"row"} key={index}>
                                <div className="pr-2 pb-2">
                                    <Field
                                        name={`${deviceSelect}.metric.instance_id`}
                                        key={index}
                                        component={this.renderDeviceField.bind(this)}
                                        label={"Choose a Device"}
                                    />
                                </div>
                                <div className={"pb-2"}>
                                    <Field
                                        name={`${deviceSelect}.metric.metric_name`}
                                        items={metricItems}
                                        label={"Choose a Metric"}
                                        component={this.renderMetricSelectField.bind(this)}
                                    />
                                    <Field
                                        name={`${deviceSelect}.color_hex`}
                                        value={newMetric.color_hex}
                                        component={() => <input type='hidden' value={newMetric.color_hex}/>}
                                    />
                                </div>
                                <div className="flex-center pb-2">
                                    {showDelete && <button
                                        type="button"
                                        className={`bg-transparent border-0`}
                                        title={`Remove Metric`}
                                        onClick={() => fields.remove(index)}
                                    >
                                        <i className="fa-solid fa-circle-minus"></i>
                                    </button>}
                                </div>
                            </div>
                        );
                    })}
                <div className={"row mt-2"}>
                    <button type="button" className="btn btn-primary" onClick={() => fields.push(newMetric)}>
                        <i className="fa-solid fa-plus pr-1"></i>Add Device
                    </button>
                </div>
            </>
        );
    }

    render() {
        return (
            <main className="app-content">
                <div className="app-title border-bottom">
                    <h1>Explore</h1>
                    <div className="emp-close-query-builder-wrapper"
                         onClick={() => browserHistory.push(`/environment/custom_dashboard/${this.props.dashboardId}`)}>
                        <i className="fa fa-times fa-lg" aria-hidden="true"></i>
                    </div>
                </div>
                <div className="emp-query-builder pt-2">
                    <form onSubmit={this.props.handleSubmit(this.props.onWidgetSubmit)}>
                        <div className="row mt-4 pl-3">
                            <div className="col-6">
                                <FieldArray name='properties.metrics' component={this.renderDeviceArrayField.bind(this)} label={"Device"}/>
                            </div>
                            <div className="col-6">
                                {this.props.properties?.metrics?.length && this.props.properties.metrics[0].metric?.metric_name ? this.displayCard() : null}
                            </div>
                        </div>

                        <div className={"row border-bottom mt-4"}/>
                        <div className={"mt-4 text-center"}>
                            <button
                                disabled={!(this.props.properties?.metrics[0].metric?.metric_name && this.props.properties.metrics[0].metric?.instance_id)}
                                className="btn btn-primary"
                                type="submit"
                            >
                                {this.props.submitButtonLabel}
                            </button>
                        </div>
                    </form>

                    <Field
                        name='id'
                        value={this.props.id ? this.props.id : ""}
                        component={() => <input type='hidden' value={this.props.id ? this.props.id : ""}/>}
                    />
                </div>
            </main>
        )
    }
}

const FORM_NAME = "customDashboardWidgetForm";
const selector = formValueSelector(FORM_NAME)

const mapStateToProps = (state) => {
    const metricValue = selector(state, 'metric');
    const widgetTypeValue = selector(state, 'widget_type');
    const labelValue = selector(state, 'label');
    const deviceValue = selector(state, 'device');
    const colorHexValue = selector(state, 'color_hex');
    const instanceId = selector(state, 'instance_id');
    const properties = selector(state, 'properties');

    return ({
        auth: state.authReducer,
        metricReducer: state.metricReducer,
        metric: metricValue,
        widget_type: widgetTypeValue,
        label: labelValue,
        device: deviceValue,
        color_hex: colorHexValue,
        instance_id: instanceId,
        properties: properties,
    });
};

const mapDispatchToProps = (dispatch) => ({
    changeFieldValue: (field, value) => dispatch(change(FORM_NAME, field, value)),
});


export default connect(mapStateToProps, mapDispatchToProps)(
    reduxForm({
        form: FORM_NAME,
    })(WidgetForm)
);
