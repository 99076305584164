import React from 'react';
import {connect} from 'react-redux';
import {browserHistory, Link} from 'react-router';
import {bindActionCreators} from 'redux';

import {reduxForm} from 'redux-form';
import swal from 'sweetalert';

import IncidentReportGeneralSection from './IncidentReportGeneralSection';
import IncidentReportCheckboxSection from './IncidentReportCheckboxSection';
import IncidentReportDescriptionSection from './IncidentReportDescriptionSection';
import IncidentReportNotifySection from './IncidentReportNotifySection';

import * as IncidentReportActions from '../../actions/incidentReportAction';

export class IncidentReportForm extends React.Component {
    constructor(props) {
        super(props);
        this.onReportSubmit = this.onReportSubmit.bind(this)
    }

    render() {
        return (
            <main className="app-content">
                <section className="emp_org_settings_sec emp_report_sec emp_circle_box_sec emp_alert_sec emp_temp_alert_sec">
                    {this.renderHeaderBar()} 
                    <div className="row">
                        <div className="col-xl-9 col-lg-9 col-md-12 col-sm-12 ">
                            {this.renderForm()}
                        </div>
                    </div>
                </section>
            </main>
        );
    }

    renderHeaderBar() {
        return (
            <div className="row">
                <div className="col-sm-12">
                    <div className="app-title border-bottom">
                            <div className="media align-items-center">
                                <Link to="/incidents">
                                    <span className="mr-3 emp_icn_middle">
                                        <img src={require("../../styles/images/icon/ic_left-arrow.png")} alt="Icon"/>
                                    </span>
                                </Link>
                                <div className="media-body">
                                    <h1 className="mt-0 text-capitalize">Incident Report</h1>
                                    <span className="sub_txt_title">Please fill out the report</span>
                                </div>
                            </div>
                    </div>
                </div>
            </div>
        );
    }

    convertCheckboxes(formValues, masterList, listForUpdate) {
        // Redux form will return values array with any initital data 
        // and true/false for updated checks (no guarantee in order and maybe duplicates!)
        // So just looking for the true/false and applying those changes to listForUpdate
        // NOTE: the report 'list for update' array indices correspond to the masterlist table ids.
        return formValues.reduce ( (selected,r,index) => {
            if (typeof r === "boolean") {
                let id = masterList[index].id
                let i = selected.findIndex( (s) => s.id ===id)
                if (r) {
                    if (i === -1) {
                        selected.push({id: id})
                    }
                }
                else {
                    if (i > -1) {
                        selected.splice(i, 1);
                    }
                }
            }
            return selected},
        listForUpdate);
    }

    onReportSubmit(values,dispatch) {
        let primitiveReportFields = [
            'status',
            'description',
            'author',
            'report_date',
            'group_name',
            'device_id',
            'device_name',
            'description',
            'trigger_id',
            'trigger_title',
            'trigger_condition',
            'trigger_conditions',
            'trigger_type',
            'incident_timestamp',
            'incident_id',
            'incident_datapoint',
            'users',
            'groups'
        ]
        let report = { };
        // update report with each value from form
        // ToDo: better way?  Just really want to filter values into report...
        for (let key in values) {
            if (primitiveReportFields.includes(key))
                report[key] = values[key]
        }

        report.reasons = this.convertCheckboxes(
            values['reasons'], this.props.reasons, this.props.report.reasons)
        report.storage_unit_types = this.convertCheckboxes(
            values['storage_unit_types'], this.props.storageUnitTypes,this.props.report.storage_unit_types)

        this.props.IncidentReportActions.saveReport(
            report, 
            () => browserHistory.push('/incidents'),
            (errors) => {
                let msg = 'Unexpected error occurred';
                if ( Array.isArray(errors) && errors.length > 0 )
                     msg = errors[0];
                swal({
                    icon: 'error',
                    title: 'Save Incident Report',
                    text: msg,
                    className: 'error-handler-modal'
                });
            },
            this.props.systemOfUnits
        )
    }

    renderForm() {
        const { handleSubmit, reasons, storageUnitTypes, incident_device_group_names }= this.props;
        const { description } = this.props.initialValues;
        const { author, report_date, incident_timestamp, incident_datapoint } = this.props.report;
        const { device_id, device_name, users, groups } = this.props.report;
        const { trigger_title, trigger_condition, trigger_conditions, trigger_type } = this.props.report;

        return (
            <form key='incidentReportForm' className="emp_create_report_form" onSubmit={handleSubmit(this.onReportSubmit)}>
                <IncidentReportGeneralSection 
                    author={author} reportDate={report_date} incidentTimestamp={incident_timestamp} 
                    deviceId={device_id} deviceName={device_name} 
                    triggerTitle={trigger_title} triggerCondition={trigger_condition}
                    triggerConditions={trigger_conditions}
                    triggerType={trigger_type} incidentDatapoint={incident_datapoint}
                    deviceGroupNames={incident_device_group_names}
                    systemOfUnits={this.props.systemOfUnits}
                />
                <IncidentReportCheckboxSection choicesArray={storageUnitTypes} 
                    label="Storage Unit Type" name="storage_unit_types" selected={this.props.report.storage_unit_types} />
                <IncidentReportCheckboxSection choicesArray={reasons} 
                    label="Reason" name="reasons" selected={this.props.report.reasons} />
                <IncidentReportDescriptionSection description={description}/>
                <IncidentReportNotifySection users={users} groups={groups} />
                <div className="active-device-btn text-right">
                    <div className="active-device-btn text-right">
                        <button 
                            onClick={handleSubmit(values => 
                                this.onReportSubmit({ ...values, status: 'Resolved'})
                            )}
                            className="btn btn-success btn-primary emp-cst-btn emp-btn mr-4 xs_mr_0">
                            <span className="mr-2 emp_icn_middle">
                                <img src={require("../../styles/images/icon/ic_checking.png")} alt="Icon" />
                            </span>Resolve
                        </button>
                        <button 
                            onClick={handleSubmit(values => 
                                this.onReportSubmit({ ...values, status: 'Unresolved' })
                            )}
                           className="btn btn-success btn-primary emp-cst-btn emp-btn mr-4 xs_mr_0">
                            <span className="mr-2 emp_icn_middle">
                                <img src={require("../../styles/images/icon/ic_checking.png")} alt="Icon" />
                            </span>Save Draft
                        </button> 
                        <Link to="/incidents" className="create-group mr-4">
                            <span className="mr-3 emp_icn_middle">
                                <img src={require("../../styles/images/icon/icn_close.png")} alt="Icon"/>
                            </span>Cancel
                        </Link>                                             
                    </div>
                </div>
        </form>
        )
    }
}

const mapStateToProps = (state) => {
    return ({
        // why twice?  initialValues just used by redux form for input fields and
        // values to return on submit.  report is used for rendering report constants
        initialValues: state.incidentReportReducer.report,
        report: state.incidentReportReducer.report,
        systemOfUnits: state.generalSettingReducer.organization.system_of_units,
        incident_device_group_names: state.incidentReportReducer.incident_device_group_names,
        reasons: state.incidentReportReducer.reasons,
        storageUnitTypes: state.incidentReportReducer.storageUnitTypes
    });
};

const mapDispatchToProps = (dispatch) => ({
    IncidentReportActions: bindActionCreators(IncidentReportActions, dispatch),

});

export default connect(mapStateToProps,mapDispatchToProps)(reduxForm({
    form: 'incidentReportForm',
    enableReinitialize: true
})(IncidentReportForm));
