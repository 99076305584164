import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

const renderField = ({ input: {value: omitValue, ...inputProps }, label, rows, placeholder, resizable,className, meta: { touched, asyncValidating, error } ,...props}) => {
    return (
        <div className="form-group">
            { label !== null && <label className="form-group__label">{label}</label> }
            <div className={`form-group__field ${asyncValidating ? 'async-validating' : ''}`}>
                {
                    window.location.pathname.includes('edit') &&
                    <textarea {...inputProps} {...props} value={omitValue} placeholder={placeholder} rows={rows} className={`form-control ${className} ${!resizable ? 'no-resize' : '' }`} ></textarea>
                }
                {
                    !window.location.pathname.includes('edit') &&
                    <textarea {...inputProps} {...props} placeholder={placeholder} value={omitValue} rows={rows} className={`form-control ${className} ${!resizable ? 'no-resize' : '' }`} ></textarea>
                }
            </div>
            { touched && error && <div className="form-group__error">{error}</div>}
        </div>
    );
}

export const TextAreaField = ({ name, label, rows, cols, resizable, placeholder,className }) => (
    <Field
        placeholder={placeholder}
        name={name}
        label={label}
        rows={rows}
        cols={cols}
        resizable={resizable}
        className={className}
        component={renderField}
    />
);

TextAreaField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    rows: PropTypes.number,
    cols: PropTypes.number,
    className: PropTypes.string,
    resizable: PropTypes.bool
};

TextAreaField.defaultProps = {
    type: 'text',
    label: null,
    placeholder: '',
    className: '',
    rows: 3,
    cols: 20,
    resizable: false
};