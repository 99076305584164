export const authConstants = {
    LOGIN: 'AUTH_LOGIN',
    LOGIN_PENDING: 'AUTH_LOGIN_PENDING',
    LOGIN_FULFILLED: 'AUTH_LOGIN_FULFILLED',
    LOGIN_REJECTED: 'AUTH_LOGIN_REJECTED',

    SIGNUP: 'AUTH_SIGNUP',
    SIGNUP_PENDING: 'AUTH_SIGNUP_PENDING',
    SIGNUP_FULFILLED: 'AUTH_SIGNUP_FULFILLED',
    SIGNUP_REJECTED: 'AUTH_SIGNUP_REJECTED',

    LOGIN_WITH_FACEBOOK: 'AUTH_LOGIN_WITH_FACEBOOK',


    AUTHORIZE: 'AUTH_AUTHORIZE',

    LOGOUT: 'AUTH_LOGOUT',

    OPEN_SIGN_UP_MODAL: 'AUTH_OPEN_SIGN_UP_MODAL',
    CLOSE_SIGN_UP_MODAL: 'AUTH_CLOSE_SIGN_UP_MODAL',

    OPEN_FORGOT_PASSWORD_MODAL: 'AUTH_OPEN_FORGOT_PASSWORD_MODAL',
    CLOSE_FORGOT_PASSWORD_MODAL: 'AUTH_CLOSE_FORGOT_PASSWORD_MODAL',

    UPDATE_USER_DETAILS: 'UPDATE_USER_DETAILS',
    UPDATE_USER_DETAILS_FULFILLED: 'UPDATE_USER_DETAILS_FULFILLED',

    UPDATE_PASSWORD: 'UPDATE_PASSWORD',
    UPDATE_PASSWORD_FULFILLED: 'UPDATE_PASSWORD_FULFILLED',

    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    CHANGE_PASSWORD_FULFILLED: 'CHANGE_PASSWORD_FULFILLED'
};