import {incidentReportConstants} from '../constants/incidentReport.constants.js';

const emptyReport = { 
    status: '',
    author: {id: 0, first_name:'', last_name:''},
    report_date: '',
    group_name: '',
    device_id: '',
    device_name: '',
    description: '',
    reasons: [],
    storage_unit_types: [],
    users: [],
    groups: [],
    trigger_id: '',
    trigger_title: '',
    trigger_condition: '',
    trigger_type: '',
    incident_timestamp: '',
    incident_datapoint: ''
};

const initialState = {
    report: emptyReport,
    reasons: [],
    storageUnitTypes: [],
    incident_device_group_names: []
};

export const incidentReportReducer = (state = initialState, action) => {

    switch (action.type) {
        case incidentReportConstants.CLEAR_REPORT_FOR_EDIT:
            state = {
                ...state,
                report: emptyReport,
                incident_device_group_names: []
            };
            return state;   

        case incidentReportConstants.SAVE_REPORT_FULFILLED:
            state = {
                ...state,
                report: emptyReport,
                incident_device_group_names: []
            };
            return state;

        case incidentReportConstants.NEW_REPORT_FULFILLED:
        case incidentReportConstants.GET_REPORT_FULFILLED:
            state = {
                ...state,
                report: action.payload.report,
                incident_device_group_names: action.payload.device_group_names || [],
                reasons: action.payload.reasons,
                storageUnitTypes: action.payload.storage_unit_types
            };
            return state;
                
        default:
            return state;
    }
};