import {formTemplateConstants} from '../constants/formTemplate.constants';
import { FormTemplateProps } from '../formSchema';

const initialState: FormTemplateProps[] = [];

export const formTemplateReducer = (state = initialState, action) => {
    switch (action.type) {
        case formTemplateConstants.FETCH_FORM_TEMPLATES_FULFILLED:
            state = action.payload
            return state;

        case formTemplateConstants.SET_FORM_TEMPLATE:
            state = [action.payload]
            return state;

        default:
            return state;
    }
};