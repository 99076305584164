import axios from 'axios';
import storage from "../utils/storage";
import { BASE_URL } from '../config';
import {ApiDevice} from "./model/Device";

interface DeviceValues {
    device: {
        device_ids: Record<string, boolean>;
    };
}

interface UpdateDeviceValues {
    image?: File[];
    icon?: File[];
    [key: string]: any;
}

export const updateDeviceDetails = (deviceValues: DeviceValues) => {
    let device_ids: string[] = [];
    Object.keys(deviceValues.device.device_ids).forEach(id => deviceValues.device.device_ids[id] ? device_ids.push(id) : id)
    // @ts-ignore
    deviceValues.device['device_ids'] = device_ids;
    return axios.put(`${BASE_URL()}/api/v1/devices/add_device`, deviceValues, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);

};

export const activateDevice = (deviceValues: object) => {
    return axios.put(`${BASE_URL()}/api/v1/devices/activate_device`, deviceValues, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);

};

export const deactivateDevice = (macAddress: string) => {
    return axios.put(
        `${BASE_URL()}/api/v1/devices/${macAddress}/deactivate`,
        { mac_address: macAddress },
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': storage.get('token')
            }
        }
    ).then(response => response.data);
};

export const fetchDeviceData = async (data: object) => {
    return axios.get(`${BASE_URL()}/api/v1/devices/data`, {
        params: data,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

export const searchDevice = (name: string) => {
    return axios.get(`${BASE_URL()}/api/v1/devices`, {
        params: { name: name },
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

export const searchDeviceActive = ({name, expand}: {name?: string, expand?: string[]}) => {
    return axios.get(`${BASE_URL()}/api/v1/devices`, {
        params: { name, expand, status: 'active' },
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

interface SearchDeviceActiveByTypeParams {
    name: string;
    device_type: string;
}

export const searchDeviceActiveByType = ({ name, device_type }: SearchDeviceActiveByTypeParams) => {
    return axios.get(`${BASE_URL()}/api/v1/devices`, {
        params: { name: name, status: 'active', device_type: device_type },
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

export const getDevice = async (id: number, expand?: string[]): Promise<ApiDevice> => {
    return axios.get(`${BASE_URL()}/api/v1/devices/${id}`, {
        params: {
            expand: expand
        },
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }

    }).then(response => response.data)
};

export const updateDevice = async (id: number | string, values: UpdateDeviceValues) => {
    const formData = new FormData();

    if (values.image && values.image.length) {
        values.image_url = values.image[0]
    }

    if (values.icon && values.icon.length) {
        values.icon_url = values.icon[0]
    }

    Object.keys(values).forEach(key => {
        formData.append(`device[${key}]`, values[key]);
    });

    return axios.put(`${BASE_URL()}/api/v1/devices/${id}`, formData, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        //@ts-ignore
        defaultErrorHandler: true
    }).then(response => response.data);
};