import {isTemperatureField} from "../../utils/utils";
import {convertTemperature, MeasurementUnit, SystemOfUnits} from "../../utils/formatHelper";

export default class TemperatureTelemetry {
    product_id;
    constructor(clientId, sensorId, timestamp, nodeAddress, ambientTemperature, ambientTemperatureMin,
                ambientTemperatureMax, probeTemperature, probeTemperatureMin, probeTemperatureMax,
                batteryVoltage, batteryVoltageMin, batteryVoltageMax,
                humidity, humidityMin, humidityMax, batteryPercentage, name = "", productId = "", groups = "") {
        this.client_id = clientId;
        this.sensor_id = sensorId;
        this.timestamp = timestamp;
        this.node_address = nodeAddress;
        this.ambient_temperature = ambientTemperature;
        this.ambient_temperature = ambientTemperature;
        this.ambient_temperature_min = ambientTemperatureMin;
        this.ambient_temperature_max = ambientTemperatureMax;
        this.probe_temperature = probeTemperature;
        this.probe_temperature_min = probeTemperatureMin;
        this.probe_temperature_max = probeTemperatureMax;
        this.battery_voltage = batteryVoltage;
        this.battery_voltage_min = batteryVoltageMin;
        this.battery_voltage_max = batteryVoltageMax;
        this.humidity = humidity;
        this.humidity_min = humidityMin;
        this.humidity_max = humidityMax;
        this.name = name;
        this.battery_percentage = batteryPercentage;
        this.product_id = productId;
        this.groups = groups;

    }

    static fromJson(json, systemOfUnits=null) {

        for(let col of Object.keys(json)) {
            if (isTemperatureField(col)) {
                const desiredUnits = systemOfUnits === SystemOfUnits.Imperial ? MeasurementUnit.Fahrenheit : MeasurementUnit.Celsius;
                json[col] = convertTemperature(Number(json[col]), MeasurementUnit.Celsius, desiredUnits, 2);
            }
        }

        return new TemperatureTelemetry(json.client_id,
            json.sensor_id,
            json.timestamp,
            json.node_address,
            json.ambient_temperature,
            json.ambient_temperature_min,
            json.ambient_temperature_max,
            json.probe_temperature,
            json.probe_temperature_min,
            json.probe_temperature_max,
            json.battery_voltage,
            json.battery_voltage_min,
            json.battery_voltage_max,
            json.humidity,
            json.humidity_min,
            json.humidity_max,
            json.battery_percentage,
            json.name, json.product_id,
            json.groups);
    }

}
