import { PASSWORD_MIN_LEN} from '../../config';

export default function validate (values) {
    const errors = {};
    errors['scheduled_report'] = {};
    let value  = values['scheduled_report'];
    if (value){
        if (!value['report_type']) {
            errors['scheduled_report']['report_type'] = 'Please select Report Type';
        }
        if (!value['scheduled_time']) {
            errors['scheduled_report']['scheduled_time'] = `Scheduled Time is required.`;
        }
        if (!value['group_id']) {
            errors['scheduled_report']['group_id'] = `Scheduled Time is required.`;
        }


    }
    else{
    	errors['scheduled_report'] = {
            report_type: 'Please select Report Type',
    		repeat: 'Repeat is required',
            scheduled_time: 'Scheduled Time is required'
    	};
    }


    return errors;
};