import React, { useEffect, useState } from "react"
import { Link } from "react-router"
import { connect } from "react-redux";
import { FormInstanceProps, FormTemplateProps } from "../../formSchema";


type StateProps = {
    formTemplate: FormTemplateProps;
    formInstance: FormInstanceProps;
}

export const FormHeader = ({formTemplate, formInstance}) => {
    // Batch info: Work Center, Batch Number
    const [workCenter, setWorkCenter] = useState<string | null>(null);
    const [batchNumber, setBatchNumber] = useState<string | null>(null);

    useEffect(() => {
        // Confirm instance
        if (!formInstance) return;

        if (formInstance.workCenter) {
            setWorkCenter(formInstance.work_center.value || '');
        }
        if (formInstance.batch_number) {
            setBatchNumber(formInstance.batch_number.value || '');
        }
    }, [formInstance]);

    return (
        <div className='p-4 mx-0 row row-cols-3 align-items-center'>
                <div>
                    {/* Back Button */}
                    <Link to='/forms'>
                        <i className='fa fa-angle-left' />
                    </Link>
                </div>
                <div className='text-center d-flex flex-column'>
                    <p className='mb-0'>Forms</p>
                    <p className='mb-0'>{formTemplate.product_name}</p>
                    {batchNumber && <p className="mb-0">Batch: {batchNumber}</p>}
                    {workCenter && <p className="mb-0">Work Center: {workCenter}</p>}
                </div>
            </div>
    )
}

const mapStateToProps = (state): StateProps => ({
    formTemplate: state.formTemplateReducer[0],
    formInstance: state.formInstanceReducer.activeForm
});


export default connect(mapStateToProps)(FormHeader);