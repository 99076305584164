import React from 'react';

import {MemberItemField, DeviceItemField, SearchableDropDownField} from '../SearchableDropDownField/SearchableDropDownField';

export class IncidentReportNotifySection extends React.Component {

    render() {
        return (
            <div className="card shadow emp-form-card mb-3 emp_create_report_card report_section">
                <div className="card-header emp_create_report_header">
                    Notify
                </div>
                <div className="card-body emp_create_report_body pb-2 emp_create_notify_body">
                    <div className="row">
                        <div className="col-sm-12">
                            <SearchableDropDownField fieldName={'groups'} itemFieldComponent={DeviceItemField}
                                title={'Notify Groups'} type='group'/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <SearchableDropDownField fieldName={'users'} itemFieldComponent={MemberItemField}
                                title={'Notify Additional Users'} type='member'/>
                        </div>
                    </div>
                </div>
            </div>          
        )
    }
}

export default IncidentReportNotifySection;