import { PASSWORD_MIN_LEN} from '../../config';

export default function validate (values) {
	const errors = {};
	errors['user'] = {};
	let value  = values['user'];
	if (value){
		if (!value['temporary_password']) {
			errors['user']['temporary_password'] = 'Temporary Password is required';
		}
		if (!value['password']) {
			errors['user']['password'] = 'Password is required';
		} else if (value['password'].length < PASSWORD_MIN_LEN) {
			errors['user']['password'] = `Password should be at least ${PASSWORD_MIN_LEN} characters.`;
		}

		if (!value['password_confirmation']) {
			errors['user']['password_confirmation'] = 'Confirmation password is required';
		} else if (value['password_confirmation'].length < PASSWORD_MIN_LEN) {
			errors['user']['password_confirmation'] = `Password confirmation should be at least ${PASSWORD_MIN_LEN} characters.`;
		} else if (value['password_confirmation'] !== value.password) {
			errors['user']['password_confirmation'] = `Password does not match.`;
		}

	}
	else{
		errors['user'] = {
			temporary_password: 'Temporary Password is required',
			password: 'Password is required',
			password_confirmation: 'Confirmation password is required'
		};
	}


	return errors;
};