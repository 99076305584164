import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AreaTypeActions from '../../actions/areaTypeActions';
import * as AuthActions from '../../actions/authActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import {AreaTypesPage} from "../../components/AreaTypes/AreaTypesPage";
import {browserHistory} from "react-router";
import {AreaType} from "../../api/model/AreaType";
import {debounce} from "../../utils/debounce";

class AreaTypesContainer extends React.Component<{
    areaTypeActions: typeof AreaTypeActions, auth: { isLoggedIn: boolean, user },
    authActions, areaTypes: AreaType[]
}, { loading: boolean }> {
    private readonly debounceSearch;

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.debounceSearch = debounce(this.onSearchInput.bind(this), 600).bind(this)
    }

    async componentDidMount() {
        this.setState({loading: true})
        await this.props.areaTypeActions.listAreaTypes({});
        this.setState({loading: false})
    }

    onItemClick(item) {
        browserHistory.push(`/area_types/${item.id}`);
    }

    onDeleteItemClick = async (item) => {
        await this.props.areaTypeActions.deleteAreaType(item.id);
    }

    onSearchInput = async (value: string) => {
        await this.props.areaTypeActions.listAreaTypes({name: value});
    }

    render() {
        const {auth, authActions, areaTypes} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <AreaTypesPage
                        areaTypes={areaTypes}
                        onDeleteItemClick={this.onDeleteItemClick}
                        onItemClick={this.onItemClick.bind(this)}
                        onSearchInput={this.debounceSearch}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    areaTypes: state.areaTypeReducer.areaTypes,
});

const mapDispatchToProps = (dispatch) => ({
    areaTypeActions: bindActionCreators(AreaTypeActions, dispatch),
    authActions: bindActionCreators(AuthActions, dispatch)
});


export default connect(mapStateToProps, mapDispatchToProps)(AreaTypesContainer);