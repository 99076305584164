import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {Form, initialize, reduxForm, reset, Field} from 'redux-form';
import * as SensorAction from '../../actions/sensorAction';
import * as SensorApi from "../../api/sensorApi";
import {deactivateDevice} from "../../actions/deviceAction";
import {InputField} from '../InputField/InputField';
import store from '../../store/store';
import swal from 'sweetalert';
import * as $ from 'jquery';
import validate from './deviceValidate';
import EditableInlineField from '../EditableInlineField/EditableInlineField';
import {isAdmin} from '../../utils/utils';
import {SelectField} from "../../ui/Input/SelectField";
import {TextField} from "../../ui/Input/TextField";

// To Do: move this to separate components for re-use in other forms
const ModalCancelButton = () => (
    <a href="#" className="create-group text-left text-primary" data-dismiss="modal">
        <span className="mr-2 emp_icn_middle">
            <img src={require("../../styles/images/icon/icn_close.png")} alt="Icon" />
        </span>
        <span className="ml-2 cancel_btn">Cancel</span>
    </a>
)

const ModalSubmitButton = ({ label }) => (
    <button type="submit" className="btn btn-next btn-primary emp-cst-btn emp-btn">
        <span className="mr-2 emp_icn_middle">
            <img src={require("../../styles/images/icon/ic_checking.png")} alt="Icon" />
        </span>
        {label}
    </button>
)

export class SensorSetting extends React.Component {
    constructor(props) {
        super(props);
        this.timer = null;
        this.state = {
            title: "Add User",
            sensorList: [],
            alertClass: 'd-none',
            defaultForm: true,
            currentSensor: {},
            successMessage: '',
        };
        this.setCurrentSensor = this.setCurrentSensor.bind(this);
        this.deactivateSensor = this.deactivateSensor.bind(this);
        this.handleSensorNameUpdate = this.handleSensorNameUpdate.bind(this);
        this.handleOffsetSubmit = this.handleOffsetSubmit.bind(this);
    }

    sortArrByName(arr){
        arr.sort(function(a, b) {
            const nameA = a.name.toUpperCase();
            const nameB = b.name.toUpperCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
        });
    }

    async componentDidMount() {
        let activeSensors = await SensorApi.getAllSensors({status: 'active'});
        this.sortArrByName(activeSensors);
        this.setState({ sensorList: activeSensors});
    }

    componentWillUnmount() {
        if (this.timer) clearTimeout(this.timer);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.sensorReducer.updated) {
            $('.close_sensor_modal').click();
            if (this.state.currentSensor.id) {
                let current = nextProps.sensorReducer.sensorList.find(s => s.id === this.state.currentSensor.id)
                const {sensorList} = nextProps.sensorReducer;
                this.sortArrByName(sensorList);
                this.setState({ alertClass: 'fade in',
                    sensorList: sensorList ,
                    currentSensor: current
                });
            } else {
                const {sensorList} = nextProps.sensorReducer;
                this.sortArrByName(sensorList);
                this.setState({ alertClass: 'fade in', sensorList: sensorList });
            }
            this.scheduleClearMessage();
            nextProps.sensorReducer.updated = false;
        }
        if ( nextProps.deviceReducer.deactivated && this.state.currentSensor.id ) {
            let activeSensors = this.state.sensorList.filter (s => s.id !== this.state.currentSensor.id)
            this.sortArrByName(activeSensors);
            this.setState({
                alertClass: 'fade in',
                successMessage: 'Sensor deactivated successfully',
                sensorList: activeSensors
            });
            this.scheduleClearMessage();
            nextProps.deviceReducer.deactivate = false;
        }
    }

    scheduleClearMessage = () => {
        this.timer =setTimeout(
            () => {
                this.setState({ alertClass: 'fade up' })
                this.timer = setTimeout(() => this.setState({ alertClass: 'd-none' }), 225);
            },
            2000
        );
    }

    async setCurrentSensor(sensor) {
        let state = store.getState();
        console.log(sensor);
        store.dispatch(reset('sensorForm'));
        if (sensor['id']) {
            state.form.sensorForm.initial.device = sensor;
            setTimeout(() => {
                return $('.sensor_name').focus()
            }, 500);
            this.setState({
                title: 'Edit sensor',
                currentSensor: sensor,
                successMessage: 'Sensor updated successfully',
                defaultForm: false
            });
        } else {
            store.dispatch(initialize('sensorForm', {}));
            this.setState({ title: 'Activate Devices', successMessage: 'Sensor created successfully', defaultForm: true })
        }
    }

    deactivateSensor(sensor) {
        this.setCurrentSensor(sensor)
        swal({
            title: "Deactivate Sensor",
            text: `Deactivate ${sensor.name}?`,
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    className: "btn btn-default class4"
                },
                confirm: {
                    text: "Deactivate",
                    value: true,
                    visible: true,
                    className: "btn btn-primary ",
                    closeModal: true
                }
            }
        }).then(function (isConfirm) {
            if (isConfirm) {
                 store.dispatch(deactivateDevice(sensor.mac_address));
            }
        });
    }

    handleSensorNameUpdate(index, newName) {
        if (newName.length > 0) {
            let workList = [...this.state.sensorList];
            workList[index].name = newName;
            // Updating component state not required but if provides for a smoother user experience
            this.setState({ sensorList: workList })
            this.props.SensorAction.updateSensor({ device: workList[index] })
        }
    }
    render() {
        const { handleSubmit } = this.props;
        const { successMessage, alertClass } = this.state;

        return (
            <main className="app-content">
                <div className={`login w-25 alert alert-dismissible alert-success ${alertClass}`}>
                    {successMessage}
                    <button className="close" onClick={() => {
                        clearTimeout(this.timer);
                        this.setState({ alertClass: 'd-none' })
                    }}>x</button>
                </div>
                {this.renderHeader()}
                {this.renderSensorsTable(handleSubmit)}
            </main>
        );
    }

    renderSensorsTable(handleSubmit) {
        return <section className="emp_org_settings_sec emp_report_sec">
            <div className="row">
                <div className="col-xl-12">
                    <div className="table-wrapper mt-3">
                        <div className="table-responsive text-nowrap">
                            <table className="table table-striped table_history">
                                <thead>
                                    <tr>
                                        <th scope="col">Name</th>
                                        <th scope="col">Product ID</th>
                                        <th scope="col">Mac ID</th>
                                        {
                                            isAdmin(this.props.user) &&
                                            <th scope="col" className="text-center">Actions</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        this.state.sensorList
                                            .filter(sensor => sensor.status === 'active')
                                            .map((sensor, index) => {
                                            return this.renderSensorItem(index, sensor);
                                        })
                                    }


                                </tbody>
                            </table>
                        </div>
                        {this.renderEditModal(handleSubmit)}
                        {this.renderOffsetModal()}
                    </div>
                </div>
            </div>
        </section>;
    }

    renderHeader() {
        return (
          <div className="app-title border-bottom mb-0">
            <div className="media-body">
              <h5 className="mt-0 settings_users_hd_title">Sensors</h5>
            </div>
          </div>
        );
    }

    renderSensorItem(index, sensor) {
        return (<tr key={index}>
            <td>
                <EditableInlineField id={index} value={sensor.name} onUpdate={this.handleSensorNameUpdate}>
                    <span
                        // Disabling on click behavior while page is in progress
                        //className="cursor-pointer" onClick={() => browserHistory.push(`/settings/sensor/${sensor.id}`)}
                    >
                        {sensor.name}
                    </span>
                </EditableInlineField>
            </td>
            <td>{sensor.product_id}</td>
            <td>{sensor.mac_address}</td>
            {
                isAdmin(this.props.user) &&
                <td className="d-flex justify-content-center align-items-center">
                    <a href="#" data-toggle="modal"
                        onClick={async (e) => await this.setCurrentSensor(sensor)}
                        data-target="#edit_offset">
                        <img className="mr-2"
                            src={require("../../styles/images/icon/ic_settings.png")}
                            alt="settings"
                        />
                    </a>
                    <a href="#" data-toggle="modal"
                        onClick={async (e) => await this.setCurrentSensor(sensor)}
                        data-target="#activate_devices"><img className="mr-2"
                            src={require("../../styles/images/icon/pencil_icn.png")}
                            alt="pencil"
                        />
                    </a>
                    <a href="#"
                        onClick={(e) => this.deactivateSensor(sensor)}>
                        <i className="fa fa-times fa-3x"></i>
                    </a>
                </td>
            }
        </tr>
        )
    }

    renderEditModal(handleSubmit) {
        return (<div className="modal fade" id="activate_devices" tabIndex="-1" role="dialog"
            aria-labelledby="activate_devices" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document" >
                <div className="modal-content" >
                    <Form onSubmit={handleSubmit} noValidate={true}>
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">{this.state.title}</h5>
                            <button type="button" className="close_sensor_modal close emp_opacity_1"
                                data-dismiss="modal" aria-label="Close"><span className="mr-2 emp_icn_middle">
                                    <img src={require("../../styles/images/icon/icn_close.png")}
                                        alt="Icon" />
                                </span>

                            </button>
                        </div>
                        <div className="modal-body">

                            <div className="row">
                                <div className="col-xl-12">
                                    <InputField
                                        type="text"
                                        name="device[name]"
                                        className={`form-control sensor_name input_modifier emp_text_initial`}
                                        label="Name"
                                    />
                                    {
                                        this.state.defaultForm &&
                                        <div>
                                            <InputField
                                                type="text"
                                                name="device[product_id]"
                                                className={`form-control input_modifier emp_text_initial ${this.state.emailClass}`}
                                                label="Product Id"
                                            />
                                            <InputField
                                                type="text"
                                                name="device[mac_address]"
                                                className={`form-control input_modifier emp_text_initial ${this.state.emailClass}`}
                                                label="Mac Id"
                                            />
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <ModalCancelButton />
                            <ModalSubmitButton label="Save" />
                        </div>
                    </Form>
                </div>
            </div>
        </div>);
    }


    handleOffsetSubmit(e) {
        e.preventDefault();
        let state = store.getState();

        let updatedSensor = {
            device: {
                ...this.state.currentSensor,

                // temperature_offset: parseFloat(this.offsetEdit.current.value),
                temperature_offset: state.form.sensorForm.values.device.temperature_offset,
                probe_enabled: state.form.sensorForm.values.device.probe_enabled === "true",
            }
        }
        this.props.dispatch(SensorAction.updateSensor(updatedSensor))
        $("#edit_offset").modal("hide");
    }

    selectField = ({input, label, items, meta: {touched, error}}) => {
        return <SelectField label={label}
                            placeholder=""
                            error={touched && error}
                            errorLabel={error}
                            value={input.value}
                            {...input}
                            items={items}
        />
    }

    deviceOffsetField = ({input, label, meta: {touched, error}}) => {
    return (
        <TextField label={label} error={touched && error} errorLabel={error} {...input}/>
    );

}

    renderOffsetModal() {
        const { currentSensor } = this.state;
        return (
            <div className="modal fade" id="edit_offset" tabIndex="-1" role="dialog"
                aria-labelledby="edit_offset" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <form onSubmit={this.handleOffsetSubmit} style={{ width: "600px" }}>
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{currentSensor.name}</h5>
                                <button type="button" className="close_sensor_modal close emp_opacity_1"
                                    data-dismiss="modal" aria-label="Close">
                                    <span className="mr-2 emp_icn_middle">
                                        <img src={require("../../styles/images/icon/icn_close.png")} alt="Icon" />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                    <Field
                                           type={"number"}
                                           pattern="^[-+]?\d+(?:\.\d{1,2})?$"
                                           name={`device[temperature_offset]`}
                                           label={"Probe Offset"}
                                           component={this.deviceOffsetField}
                                    />

                                    {/*<input type="text" id="deviceOffset"*/}
                                    {/*    pattern="^[-+]?\d+(?:\.\d{1,2})?$"*/}
                                    {/*    ref={this.offsetEdit}*/}
                                    {/*    className="emp_numeric_input" />*/}
                            </div>
                            <div className="modal-body">
                                <div className="form-group" className="text-center">
                                    <Field name={`device[probe_enabled]`} label={"Probe Enabled"} items={[{ label: "Enabled", value: true}, {label: "Disabled", value: false  }]} component={this.selectField}/>
                                </div>
                            </div>
                            <div className="modal-footer justify-content-between">
                                <ModalCancelButton />
                                <ModalSubmitButton label="Save" />
                            </div>
                        </div>
                    </form>

                </div>
            </div>);
    }
}

const mapStateToProps = (state) => {
    return ({
        sensorReducer: state.sensorReducer,
        deviceReducer: state.deviceReducer,
        groupReducer: state.groupReducer,
    });
};

const mapDispatchToProps = (dispatch) => ({
    SensorAction: bindActionCreators(SensorAction, dispatch)
});


export default reduxForm({
    form: 'sensorForm',
    validate,
    initialValues: { device: {} },
    onSubmit: (values, dispatch) => dispatch(SensorAction.updateSensor(values))
})(connect(mapStateToProps, mapDispatchToProps)(SensorSetting));
