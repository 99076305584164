import {generalSettingConstants} from '../constants/generalSetting.constants';
import * as generalSettingApi from "../api/generalSettingApi";


export const updateGeneralSetting = (values)=> ({
    type: generalSettingConstants.UPDATE_GENERAL_SETTING,
    payload: generalSettingApi.updateGeneralSetting(values)
    .then(response => {
            return response
        })

});

export const fetchOrganizationData = (values)=> ({
    type: generalSettingConstants.FETCH_ORGANIZATION_DATA,
    payload: generalSettingApi.fetchOrganizationData(values)
        .then(response => {
            return response
        })

});