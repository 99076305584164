import React from 'react';
import swal from "@sweetalert/with-react";

import * as FormInstanceActions from "../../actions/formInstanceAction";
import LoadingMessage from '../LoadingMessage/LoadingMessage';
import { round } from './FormFields/Instruction';


interface BillOfMaterialsProps {
    code: string;
    name: string;
    percentage: string;
    targetChargeAmount: number;
    actual: string;
}

export const BillOfMaterials = ({ formInstance }) => {
    const productName = formInstance?.form_template?.product_name;
    const batchSize = formInstance?.standard_yield?.value;

    const [bomData, setBomData] = React.useState<BillOfMaterialsProps[]>([]);

    React.useEffect(() => {
        const fetchBomData = async () => {
            const bom_fields = await FormInstanceActions.formInstaceBomFields(formInstance.id).payload;

            // Transform Data
            const formatted_fields = bom_fields.map((field) => {
                const formatted = {
                    code: '',
                    name: '',
                    percentage: '',
                    targetChargeAmount: 0,
                    actual: 0
                }

                const colonIndex = field.code.indexOf(':');
                formatted.code = field.code.slice(colonIndex + 1).trim();

                // Extract the name, percentage, targetChargeAmount, and actual values
                field.fields.map((child) => {

                    // Value field
                    if (child.name.includes('add')) {
                        formatted.actual = child.value;
                    } else {
                        // Description field used to extract the name, percentage, and targetChargeAmount
                        const percentageIndex = child.name.indexOf('%');
                        formatted.percentage = child.name.slice(0, percentageIndex + 1);

                        const extractedPercentage = formatted.percentage.slice(0, -1);
                        console.log('extractedPercentage', extractedPercentage);
                        formatted.targetChargeAmount = round(parseInt(batchSize) * (parseFloat(extractedPercentage) / 100));

                        formatted.name = child.name.slice(percentageIndex + 1).trim();
                    }
                    return true
                })

                return formatted;
            })


            setBomData(formatted_fields);
        }

        fetchBomData();
    }, [batchSize, formInstance.id]);


    // Fetch the BOM data from api
    if (bomData.length <= 0) {
        return (
            <div className='tw-w-[800px]'>
                <LoadingMessage/>
            </div>
        )
    }

    return (
      <div className="tw-w-[800px]">
        {/* Header */}
        <div className="tw-flex tw-justify-between tw-border-0 tw-border-b-2 tw-border-black tw-border-solid tw-mb-4 tw-px-4 tw-pb-2 -tw-mx-5 ">
          <h1>Bill of Materials (BOM)</h1>

          <button
            onClick={() => {
              swal.close()
            }}
            className="btn"
          >
            <i className="fa-solid fa-xmark fa-2xl"></i>
          </button>
        </div>

        {/* Pre-table stats */}
        <div className="tw-flex tw-text-left tw-my-4 tw-border">
          <p className="tw-mb-0 tw-w-1/2">
            <b>Product Name:</b> {productName}
          </p>
          <p className="tw-mb-0 tw-w-1/2">
            <b>Batch Size:</b> {batchSize}
          </p>
        </div>

        {/* Table */}
        <table className="table">
          <thead className="tw-bg-[#E6EEFE]">
            <tr>
              <th>RM Name</th>
              <th>RM Code</th>
              <th>% Batch</th>
              <th>Target Charge Amount(lbs)</th>
              <th>Actual (lbs)</th>
            </tr>
          </thead>
          <tbody>
            {bomData.map((item, index) => (
              <tr key={index}>
                <td>{item.name}</td>
                <td>{item.code}</td>
                <td>{item.percentage}</td>
                <td>{item.targetChargeAmount}</td>
                <td>{item.actual || '---'}</td>
              </tr>
            ))}

            <tr>
              <td>Total</td>
              <td></td>
              <td>100%</td>
              <td>
                {bomData.reduce((acc, cur) => {
                  return acc + cur.targetChargeAmount
                }, 0)}
              </td>
              <td>
                {bomData.reduce((acc, cur) => {
                  if (cur.actual !== null) {
                    return acc + parseInt(cur.actual)
                  }
                  return acc;
                }, 0)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
}