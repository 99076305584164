import axios from 'axios';
import storage from "../utils/storage";
import { BASE_URL } from '../config';

// Fetch all FormTemplates
export const fetchFormTemplates = () => {
    return axios.get(`${BASE_URL()}/api/v1/form_template`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

// Fetch a single FormTemplate by ID
export const fetchFormTemplate = (id) => {
    return axios.get(`${BASE_URL()}/api/v1/form_template/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

// Create a new FormTemplate
export const createFormTemplate = (formTemplateData) => {
    return axios.post(`${BASE_URL()}/api/v1/form_template`, formTemplateData, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

// Update an existing FormTemplate
export const updateFormTemplate = (id, formTemplateData) => {
    return axios.put(`${BASE_URL()}/api/v1/form_template/${id}`, formTemplateData, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

// Delete a FormTemplate
export const deleteFormTemplate = (id) => {
    return axios.delete(`${BASE_URL()}/api/v1/form_template/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};