import React from 'react';
import { Link } from 'react-router';

export class Footer  extends React.Component {
    render () {
        const {isLoggedIn, user } = this.props;
        console.log("In header",user)
        return (
            <footer className="p-4">
                <div className="container">
                    <div className="row">

                        <div className="col-md-6 col-sm-12">
                            <div className="row">

                                <div className="col-sm-4 wow  fadeIn">
                                    <h6>Company</h6>
                                    <div className="list-group">
                                        <Link to="/Dashboard" className="list-group-item list-group-item-action ">The Team</Link>
                                        <Link to="/Dashboard" className="list-group-item list-group-item-action "> About us</Link>
                                        <Link to="/Dashboard" className="list-group-item list-group-item-action ">Blog</Link>
                                        <Link to="/Dashboard" className="list-group-item list-group-item-action ">Press</Link>
                                    </div>
                                </div>
                                <div className="col-sm-4 wow fadeIn">
                                    <h6>Privacy Policy</h6>
                                    <div className="list-group">
                                        <Link to="/Dashboard" className="list-group-item list-group-item-action ">Support</Link>
                                        <Link to="/Dashboard" className="list-group-item list-group-item-action ">Contact</Link>
                                        <Link to="/Dashboard" className="list-group-item list-group-item-action ">Cookies</Link>
                                        <Link to="/Dashboard" className="list-group-item list-group-item-action ">Terms of Service</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col-md-6 col-sm-5">
                            <div className="social-block">
                                <Link to="/Dashboard"><i className="fa fa-facebook-square fa-3x social"></i></Link>
                                <Link to="/Dashboard"><i className="fa fa-twitter-square fa-3x social"></i></Link>
                                <Link to="/Dashboard"><i className="fa fa-linkedin-square fa-3x social"></i></Link>
                                <Link to="/Dashboard"><i className="fa fa-envelope-square fa-3x social"></i></Link>
                            </div>
                        </div>
                        <div className="col-md-6 col-sm-7">
                            <div className="row">
                                {
                                    !isLoggedIn &&
                                    <div className="col-md-4 col-sm-4">
                                        <div className="footer-links-block">
                                            <Link to="/auth/login" >Login</Link>
                                            <Link to="/auth/register"> / Sign up</Link>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row text-center">
                        <div className="col-md-12">
                            <p className="text-opacity">Copyright @ Empiric Team</p>
                        </div>
                    </div>
                </div>
            </footer>
            );
    }
}