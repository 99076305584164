import React from 'react';
import * as Moment from 'moment';
import {getAlertConditionFields, getAlertConditionUnits} from '../../utils/alertDataHelper';

export class IncidentReportGeneralSection extends React.Component {

    renderFormGroup(label) {
        return (
            <div className="form-group">
                <label className="pl-0 emp_create_report_body">{label}</label>
            </div>
        )
    }

    formatDate(date) {
        return date ? Moment(date, "YYYY/MM/DD").format('MMM DD, YYYY') : ''
    }

    formatTimestamp(timestamp) {
        return timestamp ? Moment.utc(timestamp).local().format('YYYY/MM/DD hh:mm a') : ''
    }

    getTriggerLimitField() {
        let {triggerConditions, triggerType, systemOfUnits} = this.props;
        let limitTriggerTypes = ['battery_voltage', 'battery_percentage', 'ambient_temperature', 'probe_temperature', 'humidity'];
        if (limitTriggerTypes.indexOf(triggerType) < 0) {
            return null;
        }

        let alerts = []
        for (let condition of triggerConditions) {
            let threshold = condition;
            let limit = `${threshold.condition} ${threshold.threshold} ${getAlertConditionUnits(triggerType, systemOfUnits)}`
            alerts.push(<div key={alerts.length}>{this.renderFormGroup(`Alert Limit: ${limit}`)}</div>)
        }
        return (<div>{alerts}</div>);
    }

    getIncidentDatapointField() {
        let {incidentDatapoint, triggerType, systemOfUnits} = this.props;
        switch (triggerType) {
            case 'probe_temperature':
            case 'ambient_temperature':
                return (incidentDatapoint) ?
                    this.renderFormGroup(`Temperature: ${incidentDatapoint} ${getAlertConditionUnits(triggerType, systemOfUnits)}`)
                    : this.renderFormGroup('Temperature:')
            case "battery_percentage":
                return (incidentDatapoint) ?
                    this.renderFormGroup(`Battery Remaining: ${incidentDatapoint} ${getAlertConditionUnits(triggerType, systemOfUnits)}`) :
                    null
            case "humidity":
                return (incidentDatapoint) ?
                    this.renderFormGroup(`Humidity: ${incidentDatapoint} ${getAlertConditionUnits(triggerType, systemOfUnits)}`) :
                    null
            default:
                return null;
        }
    }

    renderDeviceGroupNames() {
        let {deviceGroupNames} = this.props;
        return (
            <div className="form-group">
                <label className="pl-0 emp_create_report_body">
                    {`Group Names: ${deviceGroupNames.join(', ')}`}
                </label>
            </div>
        )
    }

    render() {
        const {author, reportDate, incidentTimestamp, deviceId, deviceName} = this.props;
        const {triggerTitle} = this.props;

        return (
            <div className="card shadow emp-form-card mb-3 emp_create_report_card">
                <div className="card-body emp_create_report_body">
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            {this.renderFormGroup(`User: ${author.first_name} ${author.last_name}`)}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            {this.renderFormGroup(`Date of Report: ${this.formatDate(reportDate)}`)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            {this.renderFormGroup(`Incident Timestamp: ${this.formatTimestamp(incidentTimestamp)}`)}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            {this.getIncidentDatapointField()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            {this.renderFormGroup(`Device ID: ${deviceId}`)}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            {this.renderFormGroup(`Sensor Name: ${deviceName}`)}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            {this.renderFormGroup(`Alert Title: ${triggerTitle}`)}
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6">
                            {this.getTriggerLimitField()}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12">
                            {this.renderDeviceGroupNames()}
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default IncidentReportGeneralSection;