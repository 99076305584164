import React from 'react';
import DeviceForm from "./DeviceForm";
import DeviceType from "../../api/model/DeviceType";

interface Props {
    onSubmit: (values: any) => void;  // Use a more specific type in place of `any` if possible
    deviceTypes: DeviceType[];
}

export class DeviceEditDetailPage extends React.Component<Props, {}> {
    render() {
        return (
            <main className="app-content">
                <section className="emp_org_settings_sec emp_report_sec emp_circle_box_sec">
                    <div className="card emp-box emp-shadow">
                        <div className={"bg-white container-fluid mb-4"}>
                            <DeviceForm deviceTypes={this.props.deviceTypes} onSubmit={this.props.onSubmit}/>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}