export const customDashboardConstants = {
    LIST_CUSTOM_DASHBOARDS: 'LIST_CUSTOM_DASHBOARDS',
    LIST_CUSTOM_DASHBOARDS_FULFILLED: 'LIST_CUSTOM_DASHBOARDS_FULFILLED',

    CREATE_CUSTOM_DASHBOARD: 'CREATE_CUSTOM_DASHBOARD',
    CREATE_CUSTOM_DASHBOARD_FULFILLED: 'CREATE_CUSTOM_DASHBOARD_FULFILLED',

    UPDATE_CUSTOM_DASHBOARD: 'UPDATE_CUSTOM_DASHBOARD',
    UPDATE_CUSTOM_DASHBOARD_FULFILLED: 'UPDATE_CUSTOM_DASHBOARD_FULFILLED',

    DELETE_CUSTOM_DASHBOARD: 'DELETE_CUSTOM_DASHBOARD',
    DELETE_CUSTOM_DASHBOARD_FULFILLED: 'DELETE_CUSTOM_DASHBOARD_FULFILLED',

    BULK_DELETE_CUSTOM_DASHBOARD: 'BULK_DELETE_CUSTOM_DASHBOARD',
    BULK_DELETE_CUSTOM_DASHBOARD_FULFILLED: 'BULK_DELETE_CUSTOM_DASHBOARD_FULFILLED',

    CHANGE_CUSTOM_DASHBOARD: 'CHANGE_CUSTOM_DASHBOARD',

    GET_CUSTOM_DASHBOARD: 'GET_CUSTOM_DASHBOARD',
    GET_CUSTOM_DASHBOARD_FULFILLED: 'GET_CUSTOM_DASHBOARD_FULFILLED',
};