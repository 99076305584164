import {schemaRegistryConstants} from '../constants/schemaRegistry.constants';

const initialState = {
    schema: {},
    updated: false,
    deleted: false,
    schemas: []
};

export const schemaRegistryReducer = (state = initialState, action) => {

    switch (action.type) {
        case schemaRegistryConstants.CREATE_SCHEMA_REGISTRY_FULFILLED:
            state = {
                ...state,
                schema: action.payload,
                schemas: state.schemas.concat(action.payload),
                updated: true
            };
            return state;

        case schemaRegistryConstants.FETCH_SCHEMA_REGISTRY_FULFILLED:
            state = {
                ...state,
                schemas: action.payload
            }
            return state;
        default:
            return state;
    }
};