import {environmentConstants} from '../constants/environment.constants';
import * as environmentApi from "../api/environmentApi";

// search device data will search for all groups and devices in those groups
export const searchDeviceData = (values)=> ({
    type: environmentConstants.SEARCH_DEVICE_DATA,
    payload: environmentApi.searchDeviceData(values)
});

export const getBatchDeviceData = (values) => ({
    type: environmentConstants.REPORT_DATA_TO_CSV,
    payload: environmentApi.downloadBatchDeviceData(values)
});

export const loadData = (values) => ({
    type: environmentConstants.REPORT_DATA_TO_CSV,
    payload: environmentApi.downloadBatchData(values)
});

// retrieve the devie attributes (name, etc) and the telemetry events for a specific device
export const getDeviceAndTelemetry = (options) => ({
    type: environmentConstants.GET_DEVICE_AND_TELEMETRY,
    payload: environmentApi.getDeviceAndTelemetry(options)
});

export const getGatewayConnectionHistory = ({device_id}) => ({
    type: environmentConstants.GET_GATEWAY_CONNECTION_HISTORY,
    payload: environmentApi.getGatewayConnectionHistoryData({device_id: device_id})
});