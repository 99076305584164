export class AreaType {
    id: string;
    organizationId: number;
    name: string;
    description: string;
    iconUrl: string | null;
    imageUrl: string | null;
    createdById: string;
    updatedById: string;
    createdAt: Date;
    updatedAt: Date;

    constructor(
        id: string,
        organizationId: number,
        name: string,
        description: string,
        iconUrl: string | null,
        imageUrl: string | null,
        createdById: string,
        updatedById: string,
        createdAt: Date,
        updatedAt: Date
    ) {
        this.id = id;
        this.organizationId = organizationId;
        this.name = name;
        this.description = description;
        this.iconUrl = iconUrl;
        this.imageUrl = imageUrl;
        this.createdById = createdById;
        this.updatedById = updatedById;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
    }
}
