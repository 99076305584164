

import {areaConstants} from '../constants/area.constants';
import * as areaApi from "../api/areaApi";

export const listAreas = (data)=> ({
    type: areaConstants.LIST_AREAS,
    payload: areaApi.listAreas(data)
});

export const getArea = (id, options)=> ({
    type: areaConstants.GET_AREA,
    payload: areaApi.getArea(id, options)
});

export const createArea = (values)=> ({
    type: areaConstants.CREATE_AREA,
    payload: areaApi.createArea(values)
});

export const updateArea = (id,values)=> ({
    type: areaConstants.UPDATE_AREA,
    payload: areaApi.updateArea(id,values)
});

export const deleteArea = (id)=> ({
    type: areaConstants.DELETE_AREA,
    payload: areaApi.deleteArea(id)
});

export const resetArea = () => ({
    type: areaConstants.RESET_AREA,
    payload: async () => {}
})