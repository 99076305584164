import store from '../../store/store';

export default function authInterceptor (config) {
    const state = store.getState();

    if (state.authReducer.isLoggedIn && config.customHeaders) {
        config.headers.common['Authorization'] = `Bearer ${state.authReducer.token}`;
    }

    return config;
}