type CronPart = {
    minutes: string;
    hours: string;
    daysOfMonth: string;
    months: string;
    daysOfWeek: string;
};

const monthNames: string[] = [
    '', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
];

const dayNames: string[] = [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'
];

function pad(value: string): string {
    return value.split(',').map(v => v.padStart(2, '0')).join(',');
}

export default function cronToReadable(cronString: string): string {
    const cronParts = parseCronString(cronString);
    const time = formatTime(cronParts.minutes, cronParts.hours);
    const date = formatDate(cronParts.daysOfMonth, cronParts.months, cronParts.daysOfWeek);

    return `The cron job is scheduled to run ${time} ${date}.`;
}

function parseCronString(cronString: string): CronPart {
    const [minutes, hours, daysOfMonth, months, daysOfWeek] = cronString.split(' ');

    return { minutes, hours, daysOfMonth, months, daysOfWeek };
}

function formatTime(minutes: string, hours: string): string {
    const paddedMinutes = minutes === '*' ? minutes : pad(minutes);
    const paddedHours = hours === '*' ? hours : pad(hours);

    if (minutes.includes('*/') || hours.includes('*/')) {
        const minuteStep = minutes.startsWith('*/') ? parseInt(minutes.slice(2)) : 1;
        const hourStep = hours.startsWith('*/') ? parseInt(hours.slice(2)) : 1;
        return `at minute ${paddedMinutes} of every ${hourStep} hours`;
    }

    if (minutes === '*' && hours === '*') {
        return 'every minute';
    }
    if (minutes === '*' && hours !== '*') {
        return `every minute of hour ${paddedHours}`;
    }
    if (minutes !== '*' && hours === '*') {
        return `at minute ${paddedMinutes} of every hour`;
    }

    return `at ${paddedHours}:${paddedMinutes}`;
}

function formatDate(daysOfMonth: string, months: string, daysOfWeek: string): string {
    const dayString = daysOfMonth === '*' ? 'every day' : `on day ${daysOfMonth}`;
    const monthString = months === '*' ? 'every month' : `in ${formatMonths(months)}`;
    const dayOfWeekString = daysOfWeek === '*' ? '' : `and on ${formatDaysOfWeek(daysOfWeek)}`;

    return `${dayString} ${monthString} ${dayOfWeekString}`.trim();
}

function formatMonths(months: string): string {
    return months.split(',').map(m => monthNames[parseInt(m)]).join(', ');
}

function formatDaysOfWeek(daysOfWeek: string): string {
    if (daysOfWeek === '*') {
        return '';
    }

    const convertToDayName = (dayNumber: number): string => {
        return dayNames[dayNumber];
    };

    if(daysOfWeek.includes(',')){
        const dayNumbers = daysOfWeek.split(',').map(d => parseInt(d));
        const dayNamesList = dayNumbers.map(convertToDayName);
        return dayNamesList.join(', ');
    }

    if(daysOfWeek.includes('-')){
        const [startDay, endDay] = daysOfWeek.split('-').map(d => parseInt(d));
        return `${dayNames[startDay]} through ${dayNames[endDay]}`
    }

    const dayNumber = parseInt(daysOfWeek);
    return convertToDayName(dayNumber);
}
