import axios , {AxiosResponse} from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import {ScheduledJobModel} from './model/ScheduledJob';


export const listScheduledJobs = (
    scheduleId: string,
    cursor: string | null = null,
    limit: number = 500
): Promise<{ items: ScheduledJobModel[]; cursor: string | null }> => {
    const params: Object = {
        schedule_id: scheduleId,
        cursor: cursor,
        limit: limit,
    }

    const headers: Object = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: storage.get('token')
    }

    const config: any = {
        params: params,
        headers: headers,
    }
    return axios
        .get(`${BASE_URL()}/api/v1/scheduled_jobs`, config)
        .then((response: AxiosResponse) => {
            const items = response.data['items'].map((item: any) => ScheduledJobModel.fromJson(item));
            return {
                items: items,
                cursor: response.data['next_cursor'],
            };
        });
};

export const getScheduledJob = (
    scheduledJobId: string
): Promise<ScheduledJobModel> => {

    const headers: Object = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: storage.get('token')
    }

    const config: any = {
        headers: headers,
    }

    return axios
        .get(`${BASE_URL()}/api/v1/scheduled_jobs/${scheduledJobId}`, config)
        .then((response: AxiosResponse) => {
            return ScheduledJobModel.fromJson(response.data.item);
        });
};

