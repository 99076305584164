import * as Moment from "moment/moment";
import { precisionRound } from "../../utils/formatHelper";
import numeral from "numeral/numeral";

export const numberFormatter = (item: number): string => numeral(item).format("0,0.00");

export const calculateLeftMargin = ({ tickArray, unit }: { tickArray: number[]; unit?: string }): number => {
    let leftMargin = -10; //original left margin
    if (!tickArray.length) return leftMargin;

    const tickArrayStrLen = tickArray.map(tick => numberFormatter(tick).toString().length);
    let textLength = Math.max(...tickArrayStrLen);

    if (unit) {
        textLength += unit.length;
    }

    const maxTextLength = 5; //5 characters seem to fit on a card with the original left margin
    if (textLength > maxTextLength) {
        leftMargin += (textLength - maxTextLength) * 5; //each additional character requires a margin of 5
    }
    leftMargin += 3; //additional 3 to add margin from the value to the border;
    leftMargin += Math.floor(textLength / 10) * 5; // adding 5 for large numbers

    return leftMargin;
};

const dayDurationFormatter = (item: string): string => {
    const hour = Moment.utc(item).local().format("hh:mm a");
    if (hour === "12:00 am") {
        return Moment.utc(item).local().format("DD MMM");
    } else {
        return Moment.utc(item).local().format("hh:mm a");
    }
};

const weekDurationFormatter = (item: string): string => {
    const hour = Moment.utc(item).local().format("hh a");
    if (hour === "12 am") {
        return Moment.utc(item).local().format("DD MMM");
    } else {
        return Moment.utc(item).local().format("hh a");
    }
};

const monthDurationFormatter = (item: string): string => {
    const hour = Moment.utc(item).local().format("hh:mm a");
    return Moment.utc(item).local().format("DD MMM");
};

export const tooltipFormatter = (item: string): string => {
    return Moment.utc(item).local().format("YYYY-MM-DD hh:mm a");
};

const xFormatter = (formatter: (item: string) => string) => {
    return function (item: string): string {
        if (Moment.utc(item).isValid()) {
            return formatter(item);
        } else {
            return item;
        }
    };
};

export function getXAxisFormatter(startTime: Moment.Moment, endTime: Moment.Moment): (item: string) => string {
    const daysDiff = endTime.diff(startTime, "days");
    if (daysDiff === 0) {
        return xFormatter(dayDurationFormatter);
    } else if (daysDiff < 15) {
        return xFormatter(weekDurationFormatter);
    } else {
        return xFormatter(monthDurationFormatter);
    }
}

export const xAxisTickCalculator = ({
                                        minPoint,
                                        maxPoint,
                                        width,
                                        layoutSize,
                                    }: {
    minPoint: number;
    maxPoint: number;
    width: number;
    layoutSize: number;
}): number[] => {
    let ticks, minTicks, diff;
    if (layoutSize <= 350) {
        if (width === 5) {
            ticks = 7;
        } else if (width === 6) {
            ticks = 8;
        }
    } else if (layoutSize <= 500) {
        if (width === 5) {
            ticks = 9;
        } else if (width === 6) {
            ticks = 11;
        }
    } else if (layoutSize <= 650) {
        if (width === 5) {
            ticks = 11;
        } else if (width === 6) {
            ticks = 14;
        }
    } else if (layoutSize <= 1000) {
        if (width === 5) {
            ticks = 18;
        } else if (width === 6) {
            ticks = 21;
        }
    } else if (layoutSize > 1000) {
        if (layoutSize < 1250) {
            minTicks = 8;
        } else if (layoutSize < 1500) {
            minTicks = 12;
        } else {
            minTicks = 15;
        }
        diff = width - 4;                   //substract the min width of 4 (which equals length of minTicks)
        ticks = minTicks + (diff * 3);      //each additional tick takes a space of 3
    }

    const range = maxPoint - minPoint;
    const tickSpacing = Math.round(range / ticks);
    const tickArray: number[] = [];

    //excludes the last tick as it usually doesn't fit on the card
    for (let i = minPoint; i < maxPoint; i += tickSpacing) {
        tickArray.push(i);
    }
    return tickArray;
};


export const yAxisTickCalculator = (minPoint: number, maxPoint: number): number[] => {
    const MAX_TICKS = 4; //maximum ticks will be +1 since we're showing all ticks from niceMin to niceMax

    //y values are all equal (graph is a parallel to x Axis)
    if (minPoint === maxPoint) {
        return [minPoint - 1, minPoint, maxPoint + 1];
    }

    const calculate = () => {
        const range = niceNum(maxPoint - minPoint, false);
        let tickSpacing = niceNum(range / (MAX_TICKS - 1), true);
        if (tickSpacing < 0.01) {
            tickSpacing = 0.01; //min tick space, otherwise the spacing gets too small
        }
        const niceMin = Math.floor(minPoint / tickSpacing) * tickSpacing;
        const niceMax = Math.ceil(maxPoint / tickSpacing) * tickSpacing;
        return { niceMin, niceMax, tickSpacing };
    };

    const niceNum = (localRange: number, round: boolean): number => {
        let exponent: number;
        /** exponent of localRange */
        let fraction: number;
        /** fractional part of localRange */
        let niceFraction: number; /** nice, rounded fraction */

        exponent = Math.floor(Math.log10(localRange));
        fraction = localRange / Math.pow(10, exponent);

        if (round) {
            if (fraction < 1.5) {
                niceFraction = 1;
            } else if (fraction < 3) {
                niceFraction = 2;
            } else if (fraction < 7) {
                niceFraction = 5;
            } else {
                niceFraction = 10;
            }
        } else {
            if (fraction <= 1) {
                niceFraction = 1;
            } else if (fraction <= 2) {
                niceFraction = 2;
            } else if (fraction <= 5) {
                niceFraction = 5;
            } else {
                niceFraction = 10;
            }
        }

        return niceFraction * Math.pow(10, exponent);
    };

    const createTickArray = ({ niceMin, niceMax, tickSpacing }: { niceMin: number; niceMax: number; tickSpacing: number }): number[] => {
        const tickArray: number[] = [];
        // showing all ticks from niceMin to niceMax; need to round i in case of floating points
        for (let i = niceMin; i <= niceMax; i = precisionRound(i + tickSpacing, 2)) {
            tickArray.push(i);
        }
        return tickArray;
    };

    const { niceMin, niceMax, tickSpacing } = calculate();
    const tickArray = createTickArray({ niceMin, niceMax, tickSpacing });
    return tickArray;
};
