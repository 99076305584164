import React from 'react';
import {bindActionCreators} from 'redux';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import {Form, reduxForm} from 'redux-form';
import * as DeviceActions from '../../actions/deviceAction';
import store from '../../store/store';
import {groupData} from '../../utils/groupDataHelper';
import {SelectField} from '../SelectField/SelectField';
import validate from './validate';

export class DeviceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deviceList: [],
            activeDevices: [],
            currentDevice: {},
            errorMsg: ''
        };
        this.handleDevice = this.handleDevice.bind(this);
        this.handleValidation = this.handleValidation.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        if (this.state.deviceList.length < 1) {
            if (nextProps.device.data && nextProps.device.data.devices.length > 0) {
                let devices = nextProps.device.data.devices;
                this.setState({deviceList: devices});
            }
        }
    }

    handleDevice(e, device) {
        let devices;
        if (e.target.checked) {
            devices = [...this.state.activeDevices, device.id];
        } else {
            this.state.activeDevices.pop(device.id);
            devices = this.state.activeDevices
        }
        let msg = device.length < 1 ? 'Please select any Device.' : '';
        this.setState({activeDevices: devices, currentDevice: device, errorMsg: msg});
        let state = store.getState();
        state.form.deviceForm.values.device['device_ids'] = devices;
    }

    componentDidMount() {
        groupData();
    }

    handleValidation() {
        let msg;
        if (this.state.activeDevices.length > 0)
            msg = '';
        else
            msg = 'Please select any device';
        this.setState({errorMsg: msg});
        return true;
    }

    render() {
        const {initialValues, handleSubmit} = this.props;
        return (
            <main className="app-content">
                <section className="emp-sec emp-active-device-sec">
                    <div className="container">
                        <div className="row">
                            {this.renderForm(handleSubmit)}
                        </div>
                    </div>

                </section>
            </main>
        );
    }

    renderForm(handleSubmit) {
        return <Form onSubmit={handleSubmit} noValidate={true}>
            <div
                className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-12 offset-sm-0">
                {this.renderActivateTitle()}
                {this.renderActivateDescription()}
                {this.renderActivateCard()}
                {this.renderActivateBtn()}
            </div>
        </Form>;
    }

    renderActivateCard() {
        return <div className="card shadow emp-form-card">
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-12">
                        {this.renderSelectDevicesCard()}
                    </div>
                </div>
            </div>
        </div>;
    }

    renderActivateBtn() {
        return <div className="active-device-btn text-right">
            <button type={this.state.activeDevices.length > 0 ? 'submit' : 'button'}
                    onClick={() => this.handleValidation()}
                    className={`btn btn-next btn-primary emp-cst-btn emp-btn `}>
              <span className="mr-2 emp_icn_middle">
                    <img src={require("../../styles/images/icon/ic_checking.png")}
                         alt="Icon"/>
                </span>
                Activate Device
            </button>
        </div>;
    }

    renderActivateDescription() {
        return <div className="emp-active-content">
            <p>Activate your Empiric hardware by adding their hardware identifiers here. A
                hardware identifier is either serial number (e.g. 'XXXXXXXXXX') or, if a serial number is not provided,
                a MAC address (e.g. '00:00:00:00:00:00').</p>
        </div>;
    }

    renderActivateTitle() {
        return <div className="emp-active-title">
            <div className="media align-items-center">
                <Link to="/groups_devices">
                    <span className="mr-3 emp_icn_middle">
                        <img src={require("../../styles/images/icon/ic_left-arrow.png")}
                             alt="Icon"/>
                    </span>
                </Link>
                <div className="media-body">
                    <h5 className="mt-0">Activate Devices</h5>
                </div>
            </div>
        </div>;
    }

    renderGroupSelect() {
        return <div className="row">
            <label className="label_modifier col-lg-12">Which group would you
                like to associate your devices with?</label>
            <div className="col-lg-6">
                <SelectField
                    name="device[group_id]"
                    className="custom-select cst-select-box input_modifier shadow_select_hover"
                    type="select"
                    placeholder="Select Group"
                    options={this.props.groupReducer.groups}
                    parentClass="form-group"
                />
            </div>
        </div>;
    }

    renderSelectDevicesCard() {
        return <div className="card_activate_devices_block">
            <label className="opacity_6">Select devices</label>
            <div className="card-body border card_activate_devices_body">
                <ul className="active_device_dropdown">
                    <li className="dropdown">
                        <a className="app-nav__item" href="#"
                           data-toggle="dropdown"
                           aria-label="Open Profile Menu"
                           aria-expanded="false">
                            {

                                <ul className="card_activate_devices_ul d-flex justify-content-between align-items-center p-0 m-0">
                                    <li className="list-group-item1 opacity_6">
                                        Please select Device which you want
                                        to activate
                                    </li>
                                    <li className="list-group-item1"><img
                                        src={require("../../styles/images/icon/icn_select.png")}/>
                                    </li>
                                </ul>
                            }

                        </a>
                        <ul className="dropdown-menu card_activate_devices_menu" x-placement="bottom-start">
                            {
                                this.state.deviceList.length === 0 ? <li className="border" key={0}>
                                        <a className="dropdown-item" href="#">
                                            <ul className="card_activate_devices_ul d-flex justify-content-between align-items-center p-0 m-0">
                                                <li className="list-group-item1 opacity_6">
                                                    All Devices Activated
                                                </li>
                                            </ul>
                                        </a>
                                    </li> :
                                    this.state.deviceList
                                        .map((device, index) => this.renderDeviceItem(index, device))
                            }
                        </ul>
                    </li>
                </ul>
            </div>
            <div className="form-group__error">{this.state.errorMsg}</div>
        </div>;
    }

    renderDeviceItem(index, device) {
        return <li className="border" key={index}>
            <a className="dropdown-item" href="#">
                <ul className="card_activate_devices_ul d-flex justify-content-between align-items-center p-0 m-0">
                    <li className="list-group-item1 opacity_6 col-md-3">
                        {device.name}
                    </li>
                    <li className="list-group-item1 opacity_6 col-md-3">
                        <strong>{device.mac_address}</strong>
                    </li>
                    <li className="list-group-item1 col-md-3">
                        <div>
                            <input
                                value={device.id}
                                id={`activate_devices_${index}`}
                                type="checkbox"
                                onClick={(e) => this.handleDevice(e, device)}/>
                            <label
                                htmlFor={`activate_devices_${index}`}> </label>
                        </div>
                    </li>
                </ul>
            </a>
        </li>;
    }
}

const mapStateToProps = (state) => {
    return ({
        device: state.deviceReducer,
        groupReducer: state.groupReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    deviceActions: bindActionCreators(DeviceActions, dispatch)
});


export default reduxForm({
    form: 'deviceForm',
    validate,
    initialValues: {device: {}},
    onSubmit: (values, dispatch) => dispatch(DeviceActions.activateDevice(values))
})(connect(mapStateToProps, mapDispatchToProps)(DeviceForm));