import {gatewayConstants} from '../constants/gateway.constants';
import store from '../store/store';
import * as gatewayApi from "../api/gatewayApi";

export const updateGateway = (values)=> ({
    type: gatewayConstants.CREATE_GATEWAY,
    payload: gatewayApi.updateGateway(values)
        .then(response => {
            let state = store.getState();
            if (response.error) {
                state.form.userForm.syncErrors.user['email'] = response.error;
                return false
            }
            else
                return response;
        })

});

export const deleteGateway = (values)=> ({
    type: gatewayConstants.DELETE_GATEWAY,
    payload: gatewayApi.deleteGateway(values)

});


export const fetchGatewayData = (values)=> ({
    type: gatewayConstants.FETCH_GATEWAY_DATA,
    payload: gatewayApi.fetchGatewayData(values)
});

export const fetchGateway = (values)=> ({
    type: gatewayConstants.FETCH_GATEWAY,
    payload: gatewayApi.fetchGateway(values)
});
