export const scheduledJobConstants = {
    LIST_SCHEDULED_JOBS: 'LIST_SCHEDULED_JOBS',
    LIST_SCHEDULED_JOBS_FULFILLED: 'LIST_SCHEDULED_JOBS_FULFILLED',

    SELECT_SCHEDULED_JOB: 'SELECT_SCHEDULED_JOB',

    GET_SCHEDULED_JOB: 'GET_SCHEDULED_JOB',
    GET_SCHEDULED_JOB_FULFILLED: 'GET_SCHEDULED_JOB_FULFILLED',

    CLEAR_SCHEDULED_JOBS: 'CLEAR_SCHEDULED_JOBS'
};