import parseJWT from '../utils/parseJWT';
import storage from '../utils/storage';
import {authConstants} from '../constants/auth.constants';

const token = storage.get('token', null);
const user = parseJWT(token) || {};

const initialState = {
    loginLoading: false,
    signupLoading: false,

    token: token,
    isLoggedIn: token !== null,
    user: user,

    isOpenForgotPasswordModal: false,
    isOpenSignUpModal: false
};

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case authConstants.AUTHORIZE:
            const { token, user } = action.payload;
            state = {
                ...state,
                token: token,
                user: user,
                isLoggedIn: true,
            };

            return state;
        case  authConstants.LOGOUT:
            state = {
                ...state,
                isLoggedIn: false,
                password_updated: false,
                token: null,
                user: {}
            };

            return state;

        case authConstants.LOGIN_PENDING:
            state = {
                ...state,
                loginLoading: true
            };

            return state;
        case authConstants.LOGIN_FULFILLED:
            state = {
                ...state,
                loginLoading: false,
                user: action.payload.user
            };
            return state;
        case authConstants.LOGIN:
            // To Do: This code block is probably not used!
            //const { token, user } = action.payload;
            state = {
                ...state,
                token: token,
                user: user,
                isLoggedIn: true,
                loginLoading: false
            };
            return state;
        case authConstants.LOGIN_REJECTED:
            state = {
                ...state,
                isLoggedIn: false,
                loginLoading: false
            };
            return state;

        case authConstants.SIGNUP_PENDING:
            state = {
                ...state,
                signupLoading: true
            };

            return state;
        case authConstants.SIGNUP_FULFILLED:
            state = {
                ...state,
                signupLoading: false
            };

            return state;
        case authConstants.SIGNUP_REJECTED:
            state = {
                ...state,
                signupLoading: false
            };

            return state;

        case authConstants.OPEN_SIGN_UP_MODAL:
            state = {
                ...state,
                isOpenSignUpModal: true
            };

            return state;
        case authConstants.CLOSE_SIGN_UP_MODAL:
            state = {
                ...state,
                isOpenSignUpModal: false
            };

            return state;

        case authConstants.UPDATE_PASSWORD_FULFILLED:
            let updated = action.payload ? true : false;
            state = {
                ...state,
                password_updated: updated
            };
            return state;
        case authConstants.CHANGE_PASSWORD_FULFILLED:
            state = {
                ...state
            };
            return state;
        case authConstants.OPEN_FORGOT_PASSWORD_MODAL:
            state = {
                ...state,
                isOpenForgotPasswordModal: true
            };

            return state;
        case authConstants.CLOSE_FORGOT_PASSWORD_MODAL:
            state = {
                ...state,
                isOpenForgotPasswordModal: false
            };

            return state;

        default:
            return state;
    }
};