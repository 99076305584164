import React from 'react'
import { Link } from 'react-router'
import swal from '@sweetalert/with-react'
import { connect } from 'react-redux'

import * as FormAccessActions from '../../actions/formAccessAction'

import { StatusComponent } from './FormStatus'
import { MemberEdit } from './MemberEdit'
import LoadingMessage from '../LoadingMessage/LoadingMessage'

interface TableElProps {
  className?: string
  children: React.ReactNode
}

const TableCol = ({ className = '', children }: TableElProps) => {
  return <div className={`col ${className}`}>{children}</div>
}

const LinkCol = ({
  to,
  children
}: {
  to: string
  children: React.ReactNode
}) => (
  <>
    <TableCol>
      <Link to={to} className="text-body">
        {children}
      </Link>
    </TableCol>
  </>
)

const TableRow = ({ className = '', children }: TableElProps) => {
  return (
    <div className={`row row-cols-6 p-2 align-items-center ${className}`}>
      {children}
    </div>
  )
}

export const TableHeader = ({ adminUser }: { adminUser: boolean }) => (
  <TableRow className="bg-light">
    <TableCol className="checkbox-col th">
      <>
        {/* TODO: Handle checking multiple items */}
        {/* <input type='checkbox' /> */}
      </>
    </TableCol>
    <TableCol className="th">Date created</TableCol>
    <TableCol className="th">Product</TableCol>
    <TableCol className="th">Form Name</TableCol>
    <TableCol className="th">Status</TableCol>
    {adminUser && <TableCol className="action-col th">Actions</TableCol>}
  </TableRow>
)

interface StateProps {
  removeInstance: (formId: string) => void
  adminUser: boolean
  formList: any
  userList: any
}

export const TableBody = ({
  toggleSelected,
  removeInstance,
  formList,
  adminUser,
  userList
}) => {
  const archiveForm = (formId: string) => {
    return swal({
      icon: 'warning',
      title: 'Delete form instance.',
      text: 'Are you sure you want to delete this form instance?',
      buttons: ['Cancel', 'Delete'],
      className: 'error-handler-modal'
    }).then((value) => {
      if (value) {
        // update form Instances
        removeInstance(formId).then(() => {
          swal('Your form instance has been deleted!', {
            icon: 'success'
          })
        })
      }
    })
  }

  const formMembers = (formId: string) => {
    // Display loading message while fetching form members
    swal({
      buttons: false,
      content: <LoadingMessage />
    })

    const accessRes = FormAccessActions.fetchFormAccess(formId)
    accessRes.payload
      .then((accessMember) => {
        const formUsers = accessMember.map((member) => {
          const user = userList.find((user) => user.id === member.user_id)
          //   Include existing form_role and access_id
          return { ...user, form_role: member.form_role, access_id: member.id }
        })
        return swal({
          buttons: false,
          content: (
            <MemberEdit
              formUsers={formUsers}
              userList={userList}
              formId={formId}
            />
          )
        })
      })
      .catch((error) => {
        return swal({
          icon: 'error',
          title: 'Error fetching form members',
          text: 'An error occurred while fetching form members.',
          buttons: ['Ok'],
          className: 'error-handler-modal'
        })
      })
  }

  return formList.map((form) => {
    // Localized url value && added page 1 param
    const url = `forms/${form.id}/1`
    return (
      <TableRow key={form.id} className="bg-white">
        <TableCol className="checkbox-col">
          <input type="checkbox" onClick={() => toggleSelected(form.id)} />
        </TableCol>
        <LinkCol to={url}>{new Date(form.start_date).toDateString()}</LinkCol>
        <LinkCol to={url}>{form.product}</LinkCol>
        <LinkCol to={url}>{form.batch_number || '---'}</LinkCol>
        <LinkCol to={url}>
          <StatusComponent formStatus={form.status}></StatusComponent>
        </LinkCol>
        {adminUser && (
          <TableCol className="action-col">
            <div className="dropleft">
              <button
                className="btn dropdown-menu-lg-right"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="fa-solid fa-ellipsis-vertical fa-xl" />
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <button
                  className="dropdown-item"
                  onClick={() => formMembers(form.id)}
                  style={{ color: 'gray' }}
                >
                  View Members
                </button>
                <a
                  className="dropdown-item"
                  href="#"
                  onClick={() => archiveForm(form.id)}
                >
                  <i className="fas fa-trash-alt" style={{ color: 'red' }}></i>{' '}
                  &nbsp; <span style={{ color: 'red' }}>Delete</span>
                </a>
              </div>
            </div>
          </TableCol>
        )}
      </TableRow>
    )
  })
}

const mapStateToProps = (state, ownProps): StateProps => ({
  ...ownProps,
  userList: state.userReducer.userList
})

export default connect(mapStateToProps)(TableBody)
