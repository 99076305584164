import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as EnvironmentAction from '../../actions/environmentAction';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {withRouter} from 'react-router';
import WorkflowPage from '../../components/Workflow/WorkflowPage';

export class WorkflowPageContainer extends React.Component {
    render() {
        const {authActions, auth} = this.props;
        return (
            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                <WorkflowPage/>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    environmentActions: bindActionCreators(EnvironmentAction, dispatch)
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkflowPageContainer));