import React from 'react';
import { Autocomplete } from "@react-google-maps/api";

export class AutocompleteCmp extends React.Component {
    render(){
        return (
            <Autocomplete onLoad={this.props.onLoad} onPlaceChanged={this.props.onPlaceChanged}>
                <input
                    type="text"
                    placeholder="Address"
                    style={{
                        boxSizing: `border-box`,
                        border: `1px solid transparent`,
                        width: `240px`,
                        height: `32px`,
                        padding: `0 12px`,
                        borderRadius: `3px`,
                        boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        fontSize: `14px`,
                        outline: `none`,
                        textOverflow: `ellipses`,
                        position: "absolute",
                        left: "50%",
                        marginLeft: "-120px"
                    }}
                />
            </Autocomplete>
        );
    }
}
