import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as AssetActions from '../../actions/assetActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {browserHistory, withRouter} from 'react-router';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import AssetEditDetailPage from "../../components/Asset/AssetEditDetailPage";
import * as AssetTypeActions from "../../actions/assetTypeActions";

export class AssetCreateDetailPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        await Promise.all(
            [
                this.props.assetActions.resetAsset(),
                this.props.assetTypeActions.listAssetTypes()
            ]
        );
    }

    async onSubmit(values) {
        this.setState({loading: true});
        await this.props.assetActions.createAsset(values);
        this.setState({loading: false});
        browserHistory.push(`/assets/${this.props.asset.id}`);
    }

    render() {
        const {auth, authActions, asset, assetTypes} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <AssetEditDetailPage asset={asset} assetTypes={assetTypes} onSubmit={this.onSubmit}/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    asset: state.assetReducer.asset,
    assetTypes: state.assetTypeReducer.assetTypes
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    assetActions: bindActionCreators(AssetActions, dispatch),
    assetTypeActions: bindActionCreators(AssetTypeActions, dispatch),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetCreateDetailPageContainer));