import React from "react";
import {IntervalType, WorkflowScheduleModel} from '../../api/model/WorkflowSchedule';
import WorkflowTypeTag from './WorkflowTypeTag';
import {WorkflowModel, WorkflowParamsInterface, UiSchemaInterface} from "../../api/model/Workflow";
import {formatWorkflowParamsForClient, formatDateTimeString, getLabelFromObjectArr} from "./FormValueConversionHelper";
import {INTERVAL_SCHEDULE_OPTS, COMPUTE_SIZE_OPTIONS} from "./WorkflowScheduleForm";
import cronToReadable from "../../utils/cronToReadable";


export default class ScheduleDetails extends React.Component<{
    workflowSchedule: WorkflowScheduleModel,
    workflow: WorkflowModel,
}, {}> {
    renderInterval() {
        if (!this.props.workflowSchedule.schedule_interval) return "N/A";

        const scheduleInterval: {interval_type: string, interval: string} = this.props.workflowSchedule.schedule_interval;
        if (scheduleInterval.interval_type === IntervalType.SIMPLE) {
            return getLabelFromObjectArr(scheduleInterval.interval, INTERVAL_SCHEDULE_OPTS);
        } else {
            return <div>
                <div>{scheduleInterval.interval}</div>
                <div>{cronToReadable(scheduleInterval.interval)}</div>
            </div>
        }
    }

    renderParams() {
        const scheduleWorkflowParams: WorkflowParamsInterface["properties"] = this.props.workflowSchedule.workflow_params;
        const workflowWorkflowParams: WorkflowParamsInterface = this.props.workflow.workflow_params;
        const uiSchema: UiSchemaInterface = this.props.workflow.ui_schema;
        if (!scheduleWorkflowParams || !workflowWorkflowParams) return null;

        const elements: React.ReactElement[] = [];
        const invisibleFields: string[] = [];
        //loop through uiSchema.properties and push all keys with "ui:visible" set to false to invisibleFields
        if (uiSchema && uiSchema.properties) {
            Object.keys(uiSchema.properties).forEach(key => {
                if (uiSchema.properties[key]["ui:visible"] === false) {
                    invisibleFields.push(key);
                }
            });
        }

        const workflowParamsProps = workflowWorkflowParams.properties;
        if (!workflowParamsProps) {
            console.error("Workflow params properties are not defined");
            return null;
        }

        for (let key in scheduleWorkflowParams) {
            if (invisibleFields.includes(key)) continue;
            if (!workflowParamsProps[key]) {
                console.log(`Workflow param ${key} is not defined in workflow params`);
                continue;
            }

            const value = formatWorkflowParamsForClient(scheduleWorkflowParams[key], workflowParamsProps[key], uiSchema.properties[key]);

            elements.push(<div className="mb-3" key={key}>
                <div className="pb-1 schedule-detail-label">{workflowParamsProps[key].title}</div>
                <div className="pb-1">{value}</div>
            </div>);

        }
        return elements;
    }

    render() {
        const owner: string | null = this.props.workflowSchedule.owner
            ? `${this.props.workflowSchedule.owner.first_name} ${this.props.workflowSchedule.owner.last_name}` : null;

        const computeSize: string | undefined = this.props.workflowSchedule.compute_size;
        const computeSizeLabel: string = computeSize
            ? getLabelFromObjectArr(computeSize, COMPUTE_SIZE_OPTIONS) : "N/A";

        return <div className="card emp-box emp-shadow">
            <div className="card-header bg-white justify-content-space-between"><h4>Schedule Details</h4>
                <WorkflowTypeTag type={this.props.workflow.workflow_type}/></div>
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-12 col-md-6 col-lg-3 pr-5 mr-2 border-right">
                        <div className="mb-3">
                            <div className="pb-1 schedule-detail-label">Title</div>
                            <div className="pb-1">{this.props.workflowSchedule.title}</div>
                        </div>
                        <div className="mb-3">
                            <div className="pb-1 schedule-detail-label">Description</div>
                            <div className="pb-1">{this.props.workflowSchedule.description}</div>
                        </div>
                        <div className="mb-3">
                            <div className="pb-1 schedule-detail-label">Start Date</div>
                            <div
                                className="pb-1">{formatDateTimeString(this.props.workflowSchedule.start_date)}</div>
                        </div>
                        <div className="mb-3">
                            <div className="pb-1 schedule-detail-label">Interval</div>
                            <div
                                className="pb-1">{this.renderInterval()}</div>
                        </div>
                        <div className="mb-3">
                            <div className="pb-1 schedule-detail-label">Owner</div>
                            <div
                                className="pb-1">{owner}</div>
                        </div>
                        <div className="mb-3">
                            <div className="pb-1 schedule-detail-label">Compute Size</div>
                            <div
                                className="pb-1">{computeSizeLabel}</div>
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-3 pr-5 mr-2">
                        {this.renderParams()}
                    </div>
                </div>
            </div>
        </div>
    }
}