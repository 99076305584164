import React from "react";
import {DateTimeField} from "../DateTimeField/NewDateTimeField";
import {TextField} from "../../ui/Input/TextField";


interface CustomDateTimeFieldProps {
    name: string;
    className?: string;
    parentClassName?: string;
    label: string;
    validate?: any;
}

export const getCustomDateTimeField = (props: CustomDateTimeFieldProps) => (
    <DateTimeField
        type="text"
        name={props.name}
        className={`form-control input_modifier border-0 app-title-shadow datepicker--input ${props.className}`}
        parentClass={`app-title-shadow workflow-schedule-date-field-border rounded ${props.parentClassName}`}
        renderInput={(inputProps: any) => (
            <TextField label={props.label} {...inputProps} />
        )}
        validate={props.validate}
    />
);

interface CustomDateFieldProps extends CustomDateTimeFieldProps {}

export const getCustomDateField = (props: CustomDateFieldProps) => (
    <DateTimeField
        type="text"
        name={props.name}
        className={`form-control input_modifier border-0 app-title-shadow datepicker--input ${props.className}`}
        parentClass={`app-title-shadow workflow-schedule-date-field-border rounded ${props.parentClassName}`}
        renderInput={(inputProps: any) => (
            <TextField label={props.label} {...inputProps} />
        )}
        dataFormat={"YYYY-MM-DD"}
        timeFormat={false}
        validate={props.validate}
    />
);

export const getTextField = (inputProps: any, label: string) => (
    <TextField label={label} {...inputProps} />
);