

export default class IncidentReport {
    id;
    incident_timestamp;
    updated_at;
    title;
    description;
    resolver;
    location;
    reasons;

    constructor(id, incident_timestamp, updated_at, title, description, resolver, location, reasons) {
        this.id = id;
        this.incident_timestamp = incident_timestamp;
        this.updated_at = updated_at;
        this.title = title;
        this.description = description;
        this.resolver = resolver;
        this.location = location;
        this.reasons = reasons;
    }

    static fromJson(payload) {
        return new IncidentReport(payload.id,
            payload.incident_timestamp,
            payload.updated_at,
            payload.title,
            payload.description,
            payload.resolver,
            payload.location,
            payload.reasons)
    }
}