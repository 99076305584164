export const areaTypeConstants = {
    GET_AREA_TYPE: 'GET_AREA_TYPE',
    GET_AREA_TYPE_FULFILLED: 'GET_AREA_TYPE_FULFILLED',

    LIST_AREA_TYPES: 'LIST_AREA_TYPES',
    LIST_AREA_TYPES_FULFILLED: 'LIST_AREA_TYPES_FULFILLED',

    CREATE_AREA_TYPE: 'CREATE_AREA_TYPE',
    CREATE_AREA_TYPE_FULFILLED: 'CREATE_AREA_TYPE_FULFILLED',

    DELETE_AREA_TYPE: 'DELETE_AREA_TYPE',
    DELETE_AREA_TYPE_FULFILLED: 'DELETE_AREA_TYPE_FULFILLED',

    UPDATE_AREA_TYPE: 'UPDATE_AREA_TYPE',
    UPDATE_AREA_TYPE_FULFILLED: 'UPDATE_AREA_TYPE_FULFILLED',

    RESET_AREA_TYPE: 'RESET_AREA_TYPE',
    RESET_AREA_TYPE_FULFILLED: 'RESET_AREA_TYPE_FULFILLED'
};