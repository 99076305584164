import React from "react";
import { TextField } from "../../ui/Input/TextField";
import { Field, Form, InjectedFormProps, reduxForm } from "redux-form";
import { connect } from "react-redux";

interface CustomFieldProps {
    input: any;
    label: string;
    meta: {
        touched: boolean;
        error: string;
    };
}

const titleField = ({ input, label, meta: { touched, error } }: CustomFieldProps) => {
    return <TextField label={label} {...input} />;
};

interface DeviceMetricsFormProps {
    deviceTypeId: string;
    onSubmit: (values: { name: string; displayTitle: string }) => Promise<void>;
}

class DeviceMetricsForm extends React.Component<DeviceMetricsFormProps & InjectedFormProps> {
    renderTextRow(fieldName: string, label: string) {
        return (
            <div className="row mb-3">
                <div className={"col-12 col-sm-6"}>
                    <Field name={fieldName} label={label} component={titleField} />
                </div>
            </div>
        );
    }

    render() {
        const { handleSubmit, submitting } = this.props;

        return (
            <div className="container-fluid">
                <div className={"row mt-2 mb-2"}>
                    <h1 className={"emp-type-h2 col"}>{"Add Metric"}</h1>
                </div>
                <Form onSubmit={handleSubmit}>
                    {this.renderTextRow("name", "Name")}
                    {this.renderTextRow("display_name", "Display Name")}
                    {this.renderTextRow("description", "Description")}
                    <div className={"row mb-2"}>
                        <div className={"col"}></div>
                        <div className={"col-3 col-sm-2"}>
                            <button
                                type="submit"
                                disabled={submitting}
                                className={"btn btn-primary"}
                            >
                                <span className={"emp-type-btn-primary"}>SAVE</span>
                            </button>
                        </div>
                    </div>
                </Form>
            </div>
        );
    }
}

export const mapStateToProps = (state) => {
    return {
        deviceTypeMetric: state.deviceTypeMetricsReducer.deviceMetric,
        initialValues: state.deviceTypeMetricsReducer.deviceMetric,
    };
};

export const validate = (values: any) => {
    const errors = {};
    return errors;
};

export const FORM_NAME = "deviceTypeMetricsForm";

const wrappedForm = connect(mapStateToProps)(
    reduxForm<{}, DeviceMetricsFormProps>({
        form: FORM_NAME,
        enableReinitialize: true,
        validate,
    })(DeviceMetricsForm)
);

export default wrappedForm;
