import {probeConstants} from "../constants/probe.constants";


const initialState = {
    probes: [],
};

export const probesReducer = (state = initialState, action) => {
    switch (action.type) {
        case probeConstants.QUERY_PROBES_DATA_FULFILLED:
            state = {
                ...state,
                probes: action.payload
            };
            return state;
        default:
            return state;
    }
};