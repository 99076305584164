import React, { Component } from 'react';
import DeviceTypeForm from "./DeviceTypeForm";
import DeviceType from "../../api/model/DeviceType";

interface DeviceTypeEditDetailPageProps {
    onSubmit: (data: any) => void;
    deviceType: DeviceType;
    initialValues?: DeviceType;
}

export class DeviceTypeEditDetailPage extends Component<DeviceTypeEditDetailPageProps> {
    render() {
        return (
            <main className="app-content">
                <section className="emp_org_settings_sec emp_report_sec emp_circle_box_sec">
                    <div className="card emp-box emp-shadow">
                        <div className={"bg-white container-fluid mb-4"}>
                            <DeviceTypeForm deviceType={this.props.deviceType} initialValues={this.props.deviceType} onSubmit={this.props.onSubmit}/>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
