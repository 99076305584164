import React from 'react';
import {connect} from 'react-redux';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {chartData} from "../../utils/ChartData";

export class DashboardDeviceChart extends React.Component {
    render() {
        const {device} = this.props;
        Highcharts.setOptions({
            global: {
                useUTC: false
            }
        });
        let options = chartData(device, 5 * 60 * 1000, this.props.units);
        return (
            <div className="row mt-4">
                <div className="col-sm-10 offset-sm-1">
                    <div className="graph_box">
                        <HighchartsReact highcharts={Highcharts} options={options} />
                    </div>                     
                </div>
            </div>                     
        );
    }
}

export default DashboardDeviceChart;
