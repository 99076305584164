import {getByClientId, getById} from "../api/incidentApi";
import {incidentConstants} from "../constants/incident.constants";

export const fetchIncidentByClientId = (values, format=null) => ({
    type: incidentConstants.FETCH_INCIDENT_DATA,
    payload: getByClientId(values, format)
});

export const fetchIncidentById = (values, format) => ({
    type: incidentConstants.FETCH_INCIDENT,
    payload: getById(values, format)
});

export const updateQueryParams = (values)=> ({
    type: incidentConstants.UPDATE_QUERY_PARAMS,
    payload: values
});