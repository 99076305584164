import React from 'react';
import {Link} from 'react-router';
import WorkflowTypeTag from "./WorkflowTypeTag";
import * as Moment from "moment";

export default function WorkflowScheduleListItem(props) {

    function renderStatus() {
        if (props.enabled) {
            return <div className={"text-success"}>ACTIVE</div>
        } else {
            return <div className={"text-danger"}>INACTIVE</div>
        }
    }

    return (
        <div className="card shadow emp-device-card workflow-schedule-list-item">
            <div className="emp-schedule-item-body">
                <div className={"schedule-item-top"}>
                    <WorkflowTypeTag type={props.type}/>
                    <div className="dropdown dropleft">
                        <i className="fa-solid fa-ellipsis" data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false"></i>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            <Link to={`/workflow_schedules/${props.scheduleId}/edit`}>
                                <div className={"dropdown-item"}><i className="fa-solid fa-pen"></i> Edit</div>
                            </Link>
                            <div className={"dropdown-item"}
                                 onClick={() => props.onDelete(props.scheduleId)}><i
                                className="fa-solid fa-trash-can"></i> Delete
                            </div>
                        </div>
                    </div>
                </div>
                <Link to={`/workflow_schedules/${props.scheduleId}`}>
                    <div className="mt-2">
                        <h5>{props.title}</h5>
                    </div>
                    <div className="mt-2">
                        <i className="fa-solid fa-bell mr-2"></i>
                        {props.interval || "N/A"}
                    </div>
                    <div className={"schedule-item-status mt-1"}>
                        <div>started: {Moment(props.startDate).format("MM-DD-YYYY hh:mm:SS")}</div>
                        {renderStatus()}
                    </div>
                </Link>
            </div>
        </div>
    )
        ;
}