import {authConstants} from '../constants/auth.constants';
import {userConstants} from '../constants/user.constants';
import * as userApi from "../api/userApi";

export const createUser = (notUsed, values)=> ({
    type: userConstants.CREATE_USER,
    payload: userApi.createUser(values)
});

export const deleteUser = (values)=> ({
    type: userConstants.DELETE_USER,
    payload: userApi.deleteUser(values)

});

export const updateUser = (id, values)=> ({
    type: userConstants.UPDATE_USER,
    payload: userApi.updateUser(id, values)
});

export const fetchUserData = (values)=> ({
    type: userConstants.FETCH_USER_DATA,
    payload: userApi.fetchUserData(values)
});

export const updateUserDetails = (values)=> ({
    type: authConstants.UPDATE_USER_DETAILS,
    payload: userApi.updateUserDetails(values)
        .then(response => {
            let token = response.token;
            let user = response.user;
            return {
                type: authConstants.AUTHORIZE,
                payload: {
                    token,
                    user
                }
            }
        })
});

export const getMemberGroups = ()=> ({
    type: userConstants.GET_MEMBER_GROUPS,
    payload: userApi.getMemberGroups()
});