import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import SignUpForm from '../../components/SignUpForm/SignUpForm';
import { withRouter } from 'react-router';

import '../Login/login.scss';

export const SignUp = ({ authActions }) => (

    <div className="my-login-page">
        <SignUpForm />
    </div>
);

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch)
});

export default withRouter(connect(null, mapDispatchToProps)(SignUp));