import {workActivityConstants} from '../constants/workActivity.constants';

const initialState = {
    workActivities: [],
    workActivity: {}
};

export const workActivityReducer = (state = initialState, action) => {
    switch (action.type) {
        case workActivityConstants.LIST_WORK_ACTIVITIES:
        case workActivityConstants.LIST_WORK_ACTIVITIES_FULFILLED:
            state = {
                ...state,
                workActivities: action.payload
            };
            return state;
        case workActivityConstants.GET_WORK_ACTIVITY:
        case workActivityConstants.GET_WORK_ACTIVITY_FULFILLED:
            state = {
                ...state,
                workActivity: action.payload
            };
            return state;
        default:
            return state;
    }
};