import React from "react";
import PropTypes from "prop-types";


export class SelectField extends React.Component<{
    onMenuClickListener?: (e) => void,
    onItemClickListener?: (e) => void,
    items: {value: any, label: string}[],
    name?: string,
    label?: string
    value?: string,
    multiple?: boolean,
    onChange?: (e) => void,
    onFocus?: (e) => void,
    onBlur?: (e) => void,
    error?: boolean,
    errorLabel?: string
}> {

    constructor(props) {
        super(props);

        this.handleDropdownClick = this.handleDropdownClick.bind(this);
        this.handleMouseLeave = this.handleMouseLeave.bind(this);
        this.handleDropdownSelect = this.handleDropdownSelect.bind(this);
    }

    handleDropdownClick(e) {
        // click on dropdown should show or hide the dropdown <ul/>
        const {nextElementSibling} = e.target;
        nextElementSibling.nextElementSibling.style.display = nextElementSibling.nextElementSibling.style.display === 'block' ? 'none' : 'block';

        if (this.props.onMenuClickListener) {
            this.props.onMenuClickListener(e);
        }
    }

    handleMouseLeave(e) {
        e.currentTarget.style.display = "none";
    }

    handleDropdownSelect(e) {
        // this will hide the dropdown <ul/> element after selecting a dropdown <li/>
        e.target.parentNode.style.display = "none";

        if (this.props.onItemClickListener) {
            this.props.onItemClickListener(e);
        }
    }

    render() {
        const {items, label, name, value, multiple, onChange, onFocus, onBlur, error, errorLabel} = this.props;
        return (
            <>
            <div className={"emp-input-container" + (error ? ' input_error' : '')}>
                <select name={name} value={value} multiple={multiple} onChange={onChange} onFocus={onFocus} onBlur={onBlur}>
                    <option value="" disabled selected></option>
                    {items.map((item) => (
                        <option key={item.value} value={item.value}>
                            {item.label}
                        </option>
                    ))}
                </select>
                <label className={value && 'filled'}>{label}</label>
            </div>
                {error && <span className="emp-type-caption emp-color-error">{errorLabel}</span>}
            </>
        )
    }
}

