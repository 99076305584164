import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'
import swal from '@sweetalert/with-react'

import { NotFound } from '../../NotFound';
import { browserHistory } from 'react-router';

import { SectionTitle } from './SectionTitle'
import FieldList from './FieldList'
import { FormNavigator } from '../FormNavigator'
import { BatchAdjustmentForm } from '../BatchAdjustment'
import LoadingMessage from '../../LoadingMessage/LoadingMessage'
import useFormSection from '../../../hooks/useFormSection'
import { isAdmin } from '../../../utils/utils'
import { FormInstanceProps, FormTemplateProps } from '../../../formSchema'
import * as FormInstanceActions from '../../../actions/formInstanceAction'
import FormCommentsContainer from './FormComments'
import { BillOfMaterials } from '../BillOfMaterials'

type StateProps = {
  submitForm: () => void;
  user: any;
  index: number;
  formInstance: FormInstanceProps;
  formTemplate: FormTemplateProps;
  params: { [key: string]: any };
};

type DispatchProps = {
  formInstanceActions: typeof FormInstanceActions;
};

type Props = StateProps & DispatchProps;

const FormSection = ({
  user,
  formInstance,
  formTemplate,
  formInstanceActions,
  submitForm,
  params,
}: Props) => {
  const {
    loading,
    section,
    fieldInstances,
    error,
    formIndex,
    setFormIndex,
    // setFieldInstances,
    saving,
    standardYield,
    batchAdjust,
    handleFieldUpdate,
    validateSection,
    toggleBatchAdjust,
    addAdjustmentInstances,
  } = useFormSection({
    formInstance,
    formTemplate,
    user,
    formInstanceActions,
  });

  // Update form index based on URL step
  useEffect(() => {
    if (params.page) {
      setFormIndex(parseInt(params.page));
    }
  }, [params.page]);

  if (loading || fieldInstances.length <= 0) return <LoadingMessage />;

  const formWithBatchAdjustment = !!formTemplate?.sections?.find((section) => section.batch_adjustment)
  const maxPageIndex = formTemplate.sections.length - (formWithBatchAdjustment ? 1 : 0);

  const handleBack = () => {
    const newStep = formIndex - 1;
    if (newStep === 0) {
      return browserHistory.push('/forms');
    }
    setFormIndex(newStep);
    browserHistory.push(`/forms/${formInstance.id}/${newStep}`); // Update URL with the new step
  };

  const handleNext = () => {
    if (!validateSection()) return;


    if (formIndex === maxPageIndex) {
      submitForm()
    } else {
      const newStep = formIndex + 1
      setFormIndex(newStep)
      browserHistory.push(`/forms/${formInstance.id}/${newStep}`) // Update URL with the new step
    }
  };

  // If formIndex is within the valid range (0 to maxPageIndex), we display the NotFound component.
  //  This could be intentional to handle an edge case or to prevent navigation beyond the available sections.
  if (formIndex > maxPageIndex) {
    return <NotFound />;
  }

  const handleBOM = () => {
    return swal(<BillOfMaterials formInstance={formInstance} />, {
      buttons: false,
      className: 'tw-w-fit'
    })
  }

  if (batchAdjust) {
    return (
      <>
        <BatchAdjustmentForm
          batchAdjustmentFieldInstances={fieldInstances}
          formTemplate={formTemplate}
          handleFieldUpdate={handleFieldUpdate}
          addAdjustmentInstances={addAdjustmentInstances}
          onSave={handleFieldUpdate}
        />
        <FormNavigator
          index={1}
          maxIndex={1}
          onBack={toggleBatchAdjust}
          onNext={toggleBatchAdjust}
        >
          <div className='mr-4'>
            {error && <p className='mb-0 text-danger'>{error}</p>}
            <p className='mb-0'>
              {saving ? (
                'Saving...'
              ) : (
                <>
                  Saved:{' '}
                  {moment(formInstance.updated_at).format(
                    'MM/DD/YYYY h:mm:ss a'
                  )}
                </>
              )}
            </p>
          </div>
        </FormNavigator>
      </>
    );
  }


  return (
    <>
      <section className='bg-white flex-fill'>
        <div className='sop-form'>
          <SectionTitle
            isAdmin={isAdmin(user)}
            bom={formTemplate.bom_page}
            batchAdjustments={formTemplate.batch_adjustments}
            section={section}
            showBom={handleBOM}
            showAdjustment={toggleBatchAdjust}
          />
          <FieldList
            fieldInstances={fieldInstances}
            standardYield={standardYield}
            handleFieldUpdate={handleFieldUpdate}
          />

          <FormCommentsContainer />
        </div>
      </section>

      <FormNavigator
        index={formIndex}
        maxIndex={maxPageIndex}
        onBack={handleBack}
        onNext={handleNext}
        // onSubmit={submitForm}
      >
        <div className='mr-4'>
          {error && <p className='mb-0 text-danger'>{error}</p>}
          <p className='mb-0'>
            {saving ? (
              'Saving...'
            ) : (
              <>
                Saved:{' '}
                {moment(formInstance.updated_at).format('MM/DD/YYYY h:mm:ss a')}
              </>
            )}
          </p>
        </div>
      </FormNavigator>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  user: state.authReducer.user,
  formTemplate: state.formTemplateReducer[0],
  formInstance: state.formInstanceReducer.activeForm,
});

const mapDispatchToProps = (dispatch) => ({
  formInstanceActions: bindActionCreators(FormInstanceActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormSection);
