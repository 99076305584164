import * as locationTelemetryApi from "../api/locationTelemetryApi";
import {locationTelemetryConstants} from "../constants/locationTelemetry.constants";


export const getDeviceLocation = (request) => ({
    type: locationTelemetryConstants.GET_DEVICE_LOCATION,
    payload: locationTelemetryApi.getByDeviceId(request)
});

export const listDeviceLocations = (request) => ({
    type: locationTelemetryConstants.LIST_DEVICE_LOCATION,
    payload: locationTelemetryApi.listDeviceLocations(request)
})