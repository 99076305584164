import {wifiConstants} from '../constants/wifi.constants.js';

const initialState = {
    wifiNetworks: [],
    updated: false
};

export const wifiReducer = (state = initialState, action) => {

    switch (action.type) {
        case wifiConstants.FETCH_WIFI_DATA_FULFILLED:
            state = {
                ...state,
                wifiNetworks: action.payload,
                updated: true
            };
            return state;
        case wifiConstants.UPDATE_WIFI_FULFILLED:
            state = {
                ...state,
                wifiNetworks: action.payload,
                updated: true
            };
            return state;
        case wifiConstants.DELETE_WIFI_FULFILLED:
            const newWifiList = state.wifiNetworks.filter( wifi => wifi.id !== action.payload.id)
            state = {
                ...state,
                wifiNetworks: newWifiList,
                updated: true
            };
            return state;
        default:
            return state;
    }
};