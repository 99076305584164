import {scheduledReportConstants} from '../constants/scheduledReport.constants';
import * as scheduledReportApi from "../api/scheduledReportApi";

export const fetchScheduledReportData = (values)=> ({
    type: scheduledReportConstants.FETCH_SCHEDULED_REPORT_DATA,
    payload: scheduledReportApi.fetchScheduledReportData(values)
});

export const deleteScheduledReport = (values)=> ({
    type: scheduledReportConstants.DELETE_SCHEDULED_REPORT,
    payload: scheduledReportApi.deleteScheduledReport(values)

});

export const updateScheduledReport = (values)=> ({
    type: scheduledReportConstants.CREATE_SCHEDULED_REPORT,
    payload: scheduledReportApi.updateScheduledReport(values)
});