import React from 'react';

function LoadingMessage(props) {
    let loadingCenterStyle={
        display: 'grid',
        gridTemplateColumns: '1fr',
        gridTemplateRows: '65vh',
        alignItems: 'center',
        justifyItems: 'center'
    };
    return (
        <div style={loadingCenterStyle}>
            <i className="fa fa-cog fa-spin fa-5x" />
        </div>
    )
}

export default LoadingMessage;