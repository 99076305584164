export const ShipmentDeliveryEvent = Object.freeze({
    SIMPLE: "SIMPLE",
    REPORTS: "REPORTS",
    NONE: "NONE"
});

export const ShipmentExcursionSeverity = Object.freeze({
    SEV5: "Sev5",
    SEV4: "Sev4",
    SEV3: "Sev3",
    SEV2: "Sev2",
    SEV1: "Sev1",
});

export const ShipmentContactTypes = Object.freeze({
    EMAIL: "EMAIL",
    SMS: "SMS",
});

export class ShipmentContact {
    id;
    contact_name;
    contact_type;
    contact_address;
    severity;
    delivery_event;

    constructor(id, contact_name, contact_type, contact_address, severity, delivery_event=[]) {
        this.id = id;
        this.contact_name = contact_name;
        this.contact_type = contact_type;
        this.contact_address = contact_address;
        this.severity = severity;
        this.delivery_event = delivery_event;
    }

    static fromJson(json) {
        return new ShipmentContact(json['id'],
            json['contact_name'],
            json['contact_type'],
            json['contact_address'],
            json['severity'],
            json['delivery_event'],
        )
    }
}