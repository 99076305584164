import React, {Component} from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import FilterGroup from "./FilterGroup";

class Dimension {
    type: string;
    name: string;
    title: string;
    shortTitle: string;

    constructor(name: string, title: string, shortTitle: string, type: string) {
        this.name = name;
        this.title = title;
        this.shortTitle = shortTitle;
        this.type = type;
    }
}

type ReportColumn = {
    columnName: string;
    show: boolean;
}

const operator = {
    equals: '=',
    notEquals: '!=',
    contains: 'contains',
    notContains: 'notContains',
    gt: '>',
    gte: '>=',
    le: '<',
    lte: '<=',
    inDateRange: 'inDateRange',
    notInDateRange: 'notInDateRange',
    beforeDate: 'beforeDate',
    afterDate: 'afterDate'
}

interface ReportListViewProps {
    onColumnSelected?: string;
    columns: Array<ReportColumn>;
    data: Array<any>;
}

interface ReportListViewState {
    availableDimensions: Array<Dimension>;
    columns: Array<any>;
}

/**
 * A ReportListView
 *
 *
 */
export class ReportListView extends Component<ReportListViewProps, ReportListViewState> {
    constructor(props: ReportListViewProps){
        super(props);
        this.state = {
            availableDimensions: [],
            columns: []
        }
    }

    renderFilterSelector(fields: any) {
        return fields.map((filterGroup: any, index: number)=>{
            return (
                <FilterGroup
                    dimensions={this.state.availableDimensions}
                    operators={Object.values(operator)}
                    key={index}
                    filterGroup={filterGroup}
                    index={index}
                />
            );
        });
    }

    componentDidMount(){
        const {columns} = this.props;
        const availableDimensions = columns.map((col: any) => {
            return new Dimension(col.accessor, "", col.columnName, col.type);
        });
        this.setState({...this.setState, columns, availableDimensions});
    }

    render() {
        const {columns, data} = this.props;
        return (
            <React.Fragment>
                {/* <FieldArray name="sensorQueryBuilder" component={this.renderFilterSelector.bind(this)} /> */}
                <div className="table-wrapper mt-3">
                    {/* <div className="emp-table-controls">
                        <a className="btn btn-outline-primary" data-toggle="modal" data-target="#select_columns">
                            Select Columns
                        </a>
                    </div> */}
                    <div className="table-responsive">
                        {!columns.every(col => !col.show) && (
                            <ReactTable
                                columns={columns}
                                data={data}
                                className='-striped'
                                showPagination={true}
                                minRows={5}
                            />)
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
