import React from "react";
import {Field, Form, reduxForm} from "redux-form";
import * as $ from "jquery";
import {NoLabelFileInputField} from "../FileInputField/NoLabelFileInputField";
import {connect} from "react-redux";
import {TextField} from "../../ui/Input/TextField";
import {AreaType} from "../../api/model/AreaType";

const titleField = ({input, label, meta: {touched, error}}) => {
    return (<TextField label={label} error={touched && error} errorLabel={error} {...input}/>);
}
class AreaTypeForm extends React.Component<{
    handleSubmit: (e) => void,
    submitting: boolean,
    areaType: AreaType,
    onSubmit: (e) => void,
    initialValues?: Object
}> {
    constructor(props) {
        super(props);
    }

    render() {
        const {handleSubmit, submitting, areaType} = this.props;

        return <Form onSubmit={handleSubmit(this.props.onSubmit)}>
            <div className={"row mt-2"}>
                <h1 className={"emp-type-h2 col"}>{areaType.name || 'Area Type'}</h1>
            </div>

            <div className={"row"}>
                <div className={"col"}></div>
                <div className={"col-4 col-sm-3"}>
                    <button type="submit" disabled={submitting} className={"btn-primary"}><span
                        className={"emp-type-btn-primary"}>Save</span>
                    </button>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    <div className="emp-type-subtitle1">
                        <div className={"mt-4"}>
                            {this.renderAreaCard()}
                        </div>
                    </div>
                </div>
            </div>
        </Form>;
    }

    renderTextRow(fieldName, label) {
        return (
            <div className="row mt-2 mb-3">
                <div className={"col-12 col-sm-6"}>
                    <Field name={fieldName} label={label} component={titleField}/>
                </div>
            </div>
        );
    }

    renderAreaImage() {
        const handleFiles = (e) => {
            let name = e.currentTarget.files.length > 0 ? $.trim(e.currentTarget.files[0].name).substring(0, 30).trim(this) + "..." : '';
            $('.organization_logo').html(name);
            if (e.target.files.length > 0) {
                let url = URL.createObjectURL(e.target.files[0]);
                $('#preview_img').attr('src', url);
            } else {
                $('#preview_img').attr('src', require("../../styles/images/icon/logo.png"));
            }
        };

        const {areaType} = this.props;
        const imageUrl = areaType.imageUrl ? areaType.imageUrl : `https://via.placeholder.com/250?text=${areaType.name || "Area Type"}`;
        return <>
            <div className={"mb-2"}>
                <img id="preview_img" className="placeholder img-thumbnail"
                     src={imageUrl}></img>
            </div>
            <NoLabelFileInputField
                type="file"
                name="image"
                placeholder="New York, NY, USA"
                //label="Choose Logo "
                HandleChange={(e) => handleFiles(e)}
                //iconClass="fa fa-info-circle"
                className="custom-file-input form-control input_modifier pointer-none"
                inputGroupLabel={""}
                inputGroupLabelClass="custom-file-label emp_cst_file_label organization_logo pointer-none"
                inputGroupClass="input-group mb-3 cst-upload"
                htmlFor="inputGroupFile01"
                id="inputGroupFile01"
            />

        </>
    }


    renderAreaCard() {
        return (
            <div className="container-fluid">
                <div className="row mb-2">
                    <div className="col-2">
                        {this.renderAreaImage()}
                    </div>

                    <div className="col">
                        <div className={"container-fluid"}>
                            {this.renderTextRow("name", "Name")}
                            {this.renderTextRow("description", "Description")}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export const validate = (values) => {
    const errors = {};

    let requiredFields = ['name', 'description'];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });

    return errors;
};
export const FORM_NAME = "areaTypeForm";

const WrappedForm = connect()(
    reduxForm({
        form: FORM_NAME,
        enableReinitialize: true,
        validate
    })(AreaTypeForm)
);

export default WrappedForm;