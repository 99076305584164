import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import * as EnvironmentActions from '../../actions/environmentAction';
import * as UserActions from '../../actions/userAction';
import * as GeneralSettingsActions from '../../actions/generalSettingAction';
import LoadingMessage from "../LoadingMessage/LoadingMessage";
import DashboardDeviceCard from './DashboardDeviceCard';
import {SystemOfUnits} from "../../utils/formatHelper";


export class DashboardPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        };
    }

    async componentDidMount() {
        this.props.userActions.getMemberGroups();
        await this.props.generalSettingsActions.fetchOrganizationData();

        this.props.environmentActions.searchDeviceData({
            format: this.props.generalSettingsReducer.organization.system_of_units,
            sort: 'desc',
            max_items: 1
        });

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.environmentReducer.updated && nextProps.userReducer.updated) {
            this.setState(state => {
                return {
                    loading: false,
                }
            });
            nextProps.environmentReducer.updated = false;
            nextProps.userReducer.updated = false;
        }
    }

    getDevicesForGroup(groupId) {
        const {devicesData} = this.props.environmentReducer
        if (!devicesData) {
            return [];
        }

        //TODO: second filter is a hack
        return devicesData.filter(device => device.groups.includes(groupId))
            .filter(device => !device.device.name.includes("cm22"));
    }

    getContentArea() {
        const {groups} = this.props.userReducer

        if (this.state.loading) return <LoadingMessage/>

        if (groups.length === 0) {
            return (
                <div className="text-center mt-4">
                    <h1 className="mb-4">No Groups Found</h1>
                    <h2>Join a group and its sensors will show up here.</h2>
                </div>
            );
        }

        return (
            <React.Fragment>
                {groups.map((group) =>
                    <div key={`Group${group.id}`} className="inner_box mb-3">
                         <span className="badge badge-secondary emp_badge">
                            {group.name}
                        </span>
                        {this.getDevicesForGroup(group.id).map((device) =>
                            <DashboardDeviceCard key={device.device.name} device={device}
                                                 units={this.props.generalSettingsReducer.organization.system_of_units
                                                     ? this.props.generalSettingsReducer.organization.system_of_units : SystemOfUnits.Metric}/>
                        )}
                    </div>
                )}
            </React.Fragment>
        )
    }

    render() {
        return (
            <main className="app-content">
                <div className="app-title border-bottom">
                    <div>
                        <h1>Environment</h1>
                    </div>
                </div>
                <section className="emp_org_settings_sec emp_report_sec">
                    {this.getContentArea()}
                </section>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        environmentReducer: state.environmentReducer,
        deviceReducer: state.deviceReducer,
        userReducer: state.userReducer,
        generalSettingsReducer: state.generalSettingReducer,
    });
};

const mapDispatchToProps = (dispatch) => ({
    environmentActions: bindActionCreators(EnvironmentActions, dispatch),
    userActions: bindActionCreators(UserActions, dispatch),
    generalSettingsActions: bindActionCreators(GeneralSettingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
