import {incidentReportConstants} from '../constants/incidentReport.constants';
import * as incidentReportApi from "../api/incidentReportApi";

export const clearReportForEdit = ()=> ({
    type: incidentReportConstants.CLEAR_REPORT_FOR_EDIT,
    payload: null
});

export const newReport = (incidentId,triggerId, systemOfUnits)=> ({
    type: incidentReportConstants.NEW_REPORT,
    payload: incidentReportApi.newReport(incidentId,triggerId, systemOfUnits)
});

export const getReport = (id, systemOfUnits)=> ({
    type: incidentReportConstants.GET_REPORT,
    payload: incidentReportApi.getReport(id, systemOfUnits)
});

export const saveReport = (values,successCallback,errorCallback, systemOfUnits)=> ({
    type: incidentReportConstants.SAVE_REPORT,
    payload: incidentReportApi.saveReport(values,successCallback,errorCallback, systemOfUnits)
});