import React from 'react';
import {Form, reduxForm, Field, reset} from 'redux-form';
import {DeviceItemField, SearchableDropDownField} from "../SearchableDropDownField/SearchableDropDownField";
import {RadioBtn} from '../RadioBtn/RadioBtn';
import { isValidPhoneNumber } from '../../utils/phoneHelpers'
import PhoneField from './PhoneField';

const validate = values => {
    const errors = {};
    const requiredFields = ['first_name','last_name','email'];
    for (let field of requiredFields) {
        if (!values[field])
            errors[field]='Required'
    }
    if (values['phone'] && values['country']) {
        if (!isValidPhoneNumber(values['country'],values['phone']))
            errors['phone']="Full phone number in international compatible format, ex. +1 555-123-4567"
    }
    if(values['email']){
        if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values['email']))
            errors['email'] = 'Invalid email address';
    }
    return errors
}

export class UserProfileForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            width: '0'
        };
        this.handleCancel = this.handleCancel.bind(this);
    }

    componentDidMount() {
        // this is just to deal with redux-form bug (see https://github.com/redux-form/redux-form/issues/621)
        // without this code, user needs to refresh to see the initial values.
        this.props.initialize(this.props.initialValues);
    }

    handleCancel() {
        this.props.dispatch(reset('userForm'));
        this.props.onFormCancel();
    }

    renderField({ input, className, type, placeholder, meta: { touched, error, submitFailed } }) {
        let fieldStyle = "";
        let errorMessage = "";
        if (touched && error) {
            fieldStyle = "input_error"
            errorMessage = <div className="form-group__error">{error}</div>
        }
        return (
            <React.Fragment>
                <div>
                    <input {...input} placeholder={placeholder} className={`form-control input_modifier ${className} ${fieldStyle}`} type={type}/>
                </div>
                <div>
                    {errorMessage}
                </div>
            </React.Fragment>
        )
    }

    renderForm() {
        const { handleSubmit, submitting }= this.props;
        return (
            <form key='userForm' className="emp_create_report_form mt-3" onSubmit={handleSubmit(this.props.onFormSubmit)}>

                <div className="modal-header">
                    <h2 className="modal-title">{this.props.title}</h2>
                    <button type="button" className="close close_user_modal emp_opacity_1" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">
                            <img src={require("../../styles/images/icon/icn_close.png")} alt="close" />
                        </span>
                    </button>
                </div>

                <div className="modal-body">
                    <div className="row emp_v_pipe_box">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="form-group">
                                <label className="label_modifier col-sm-12 pl-0">First Name</label>
                                <Field className="col-sm-8" name="first_name" placeholder="First Name" component={this.renderField} type="text" />
                           </div>
                             <div className="form-group">
                                 <label className="label_modifier col-sm-12 pl-0">Last Name</label>
                                 <Field className="col-sm-8" name="last_name" placeholder="Last Name" component={this.renderField} type="text" />
                             </div>
                             <div className="form-group">
                                 <label className="label_modifier col-sm-12 pl-0">Email</label>
                                 <Field className="col-sm-8" name="email" placeholder="email@example.com" component={this.renderField} type="email" />
                             </div>
                            <div className="form-group">
                                <label className="label_modifier col-sm-12 pl-0">Phone Number</label>
                                <PhoneField  />
                                <small className="form_help_text">
                                    Your phone number is used to receive notifications (Optional)
                                </small>
                            </div>
                            <div className="form-group">
                                <label className="label_modifier col-sm-12 pl-0">Role</label>
                                <div className="inline_radio_btn">
                                    <div className="custom-control custom-radio">
                                        <RadioBtn name={`role`} type="radio" value="user"
                                        label="User" labelClass="custom-control-label"
                                        id={`user`} htmlFor={`user`}
                                        />
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <RadioBtn name={`role`} type="radio" value="admin"
                                        label="Admin" labelClass="custom-control-label"
                                        id={`admin`} htmlFor={`admin`}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal-footer justify-content-between border-0 pt-5">
                    <a className="create-group text-left text-primary" onClick={this.handleCancel}>
                        <img src={require("../../styles/images/icon/icn_close.png")} alt="close" />
                        <span className="ml-2 cancel_btn">Cancel</span>
                    </a>
                    <button type="submit" className="btn btn-next btn-primary emp-cst-btn emp-btn" >
                        <span className="mr-2 emp_icn_middle">
                             <img src={require("../../styles/images/icon/ic_checking.png")} alt="check" />
                        </span>
                        Save
                    </button>
                </div>
            </form>
        )
    }
    render(){
        return this.renderForm()
    }
}

export default reduxForm({
    form: 'userForm',
    validate,
    enableReinitialize: true,
  })(UserProfileForm)
