import {metricConstants} from '../constants/metric.constants';
import * as metricApi from "../api/metricApi";

export const listMetrics = ()=> ({
    type: metricConstants.LIST_METRICS,
    payload: metricApi.listMetrics()
});

export const getMetric = (instance_id, metric, start_time, end_time, unit, limit, systemOfUnits, cursor)=> ({
    type: metricConstants.GET_METRIC,
    payload: metricApi.getMetric(instance_id, metric, start_time, end_time, unit, limit, systemOfUnits, cursor)
});