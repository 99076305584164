import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {browserHistory} from 'react-router';
import {Form, reduxForm,reset,initialize} from 'redux-form';
import * as GatewayAction from '../../actions/gatewayAction';
import {deactivateDevice} from "../../actions/deviceAction";
import {InputField} from '../InputField/InputField';
import {TextAreaField} from '../TextAreaField/TextAreaField';
import store from '../../store/store';
import * as $ from 'jquery';
import swal from 'sweetalert';
import SearchSensor from './searchSensor.js';
import validate from './deviceValidate';
import {isAdmin} from '../../utils/utils';
import { gatewayData } from '../../utils/deviceDataHelper';
import * as NetworkConfigAction from '../../actions/networkConfigurationAction';


export class GatewaySetting extends React.Component {
    constructor(props){
        super(props);
        this.timer = null;
        this.state={
            title: "Add User",
            gatewayList:[],
            alertClass: 'd-none',
            defaultForm: true,
            currentGateway:{},
            successMessage: ''
        };
        this.setCurrentGateway = this.setCurrentGateway.bind(this);
        this.deactivateGateway = this.deactivateGateway.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        gatewayData();
    }
    
    componentDidUpdate(prevProps, prevState, snapshot){
        if(this.props.gatewayReducer.gatewayList.length !== prevProps.gatewayReducer.gatewayList.length){
            const deviceIds = this.state.gatewayList.map(gateway => gateway.identity);
            this.props.NetworkConfigAction.getNetworks({ deviceIds });
        }
    }
    
    componentWillReceiveProps (nextProps) {
        if (this.state.gatewayList.length < 1){
            this.setState({gatewayList: nextProps.gatewayReducer.gatewayList});
        }
        if (nextProps.gatewayReducer.updated){
            $('.close_gateway_modal').click();
            this.setState({alertClass: 'fade in',gatewayList: nextProps.gatewayReducer.gatewayList});
            this.scheduleClearMessage();
            nextProps.gatewayReducer.updated = false;
        }
        if (nextProps.deviceReducer.deactivated && this.state.currentGateway.id ) {
            let gateways = [...this.state.gatewayList]
            let gw = gateways.find( g => g.id === this.state.currentGateway.id)
            gw.status = "inactive"
            this.setState({
                alertClass: 'fade in',
                currentGateway : {...this.state.currentGateway, status: "inactive"},
                gatewayList: gateways,
                successMessage: 'Gateway deactivated successfully'
            });
            this.scheduleClearMessage();
            nextProps.deviceReducer.deactivate = false;
        }
        if(nextProps.gatewayReducer.gatewayList.length && this.state.gatewayList.length){
            const newGatewayList = [];
            this.state.gatewayList.forEach(gateway=>{
                const {identity} = gateway;
                const networkConfig = nextProps.networkConfigurationReducer.find(config=>config.device_id === identity);
                if(networkConfig && networkConfig.network) {
                    gateway["networkName"] = networkConfig.network.ssid || ''
                }
                else {
                    gateway["networkName"] = "";
                }
                newGatewayList.push(gateway);
            });
            this.setState({...this.state, gatewayList: newGatewayList});
        }
    }

    scheduleClearMessage = () => {
        this.timer =setTimeout(
            () => {
                this.setState({ alertClass: 'fade up' })
                this.timer = setTimeout(() => this.setState({ alertClass: 'd-none' }), 225);
            }, 
            2000
        );
    }

    setCurrentGateway(gateway) {
        let state = store.getState();
        store.dispatch(reset('gatewayForm'));
        if (gateway['id']) {
            state.form.gatewayForm.initial.device = gateway;
            setTimeout(() =>  {return $('.gateway_name').focus()},500);
            this.setState({title: 'Edit gateway', currentGateway: gateway, successMessage: 'Gateway updated successfully', defaultForm: false});
        }else{
            store.dispatch(initialize('gatewayForm', {}));
            this.setState({title: 'Activate Devices',successMessage: 'Gateway created successfully',defaultForm: true})
        }
    }

    deactivateGateway(gateway){
        this.setCurrentGateway(gateway)
        swal({
            title: "Deactivate Gateway",
            text: `Deactivate ${gateway.name}?`,
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    className: "btn btn-default class4"
                },
                confirm: {
                    text: "Deactivate",
                    value: true,
                    visible: true,
                    className: "btn btn-primary ",
                    closeModal: true
                }
            }
        }).then(function (isConfirm) {
            if (isConfirm) {
                 store.dispatch(deactivateDevice(gateway.mac_address));
            }
        });
    }

    handleClick (param) {
        let gatewayList  = this.props.gatewayReducer.groupList[param];
        this.setState({gatewayList: gatewayList});
    }

    render(){
        const { initialValues,handleSubmit }= this.props;
        return(
            <main className="app-content">
                <div className={`login w-25 alert alert-dismissible alert-success ${this.state.alertClass}`}>
                    {this.state.successMessage}
                    <button className="close" 
                        onClick={()=> {clearTimeout(this.timer); this.setState({alertClass: 'd-none'})}}
                    >x</button>
                </div>
                <div className="app-title border-bottom mb-0">
                    <div className="media-body">
                        <h5 className="mt-0 settings_users_hd_title">Gateways</h5>
                    </div>

                    {/*<ul className="app-breadcrumb breadcrumb ">*/}
                        {/*<li className="emp-title-item em-title-checkbox app-search search_sensors">*/}
                            {/*<div className="form-group emp_position mb-0 w-100">*/}
                                {/*<SearchSensor placeholder="Search Gateway groups" handleClick={this.handleClick} type="gateway" />*/}
                               {/*/!* <div className="emp_create_notify_box emp_absolute emp_position_inherit">*/}
                                    {/*<div className="card">*/}
                                        {/*<div className="card-body">*/}
                                            {/*<div className="media align-items-center">*/}
                                                {/*<div className="emp_user mr-3 ">*/}
                                                    {/*<img className="rounded-circle" src="images/icon/finance_department.png" alt="Generic placeholder image" />*/}
                                                {/*</div>*/}
                                                {/*<div className="media-body">*/}
                                                    {/*<span className="emp_txt_blured">Finance department (10)</span>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                            {/*<hr/>*/}
                                            {/*<div className="media align-items-center">*/}
                                                {/*<div className="emp_user mr-3 ">*/}
                                                    {/*<img className="rounded-circle" src="images/icon/freezer_team.png" alt="Generic placeholder image" />*/}
                                                {/*</div>*/}
                                                {/*<div className="media-body">*/}
                                                    {/*<span className="emp_txt_blured">Freezer team (8)</span>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}

                                            {/*<hr/>*/}
                                            {/*<div className="media align-items-center">*/}
                                                {/*<div className="emp_user mr-3 ">*/}
                                                    {/*<img className="rounded-circle" src="images/icon/freezer_maintenance.png" alt="Generic placeholder image" />*/}
                                                {/*</div>*/}
                                                {/*<div className="media-body">*/}
                                                    {/*<span className="emp_txt_blured">Freezer maintenance(3)</span>*/}
                                                {/*</div>*/}
                                            {/*</div>*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                {/*</div> *!/*/}
                            {/*</div>*/}
                        {/*</li>*/}
                        {/*/!**/}
                            {/*this.props.user && this.props.user.role == "admin" &&*/}
                            {/*<li className="emp-title-item">*/}
                                {/*<div className="app-title-right-widget-small">*/}
                                    {/*<a href="#" onClick={() => this.setCurrentGateway({})} className="app-title-btn-live app-title-shadow" data-toggle="modal" data-target="#activate_devices">*/}
                                    {/*<span className="mr-4 emp_icn_middle">*/}
                                        {/*<img src={require("../../styles/images/icon/plush.png")} alt="Icon" />*/}
                                    {/*</span>Activate Devices</a>*/}
                                {/*</div>*/}
                            {/*</li>*/}
                        {/**!/*/}

                    {/*</ul>*/}
                </div>
                <section className="emp_org_settings_sec emp_report_sec">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="table-wrapper mt-3">
                                <div className="table-responsive text-nowrap">
                                    <table className="table table-striped table_history">
                                        <thead>
                                        <tr>
                                            <th scope="col">Name</th>
                                            <th scope="col">Product ID</th>
                                            <th scope="col">Mac ID</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Connected</th>
                                            <th scope="col">Network Name</th>
                                            {
                                                isAdmin(this.props.user) &&
                                                <th scope="col" className="text-center">Actions</th>
                                            }

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            this.state.gatewayList.map((gateway,index) => {
                                                return (<tr  key={index}>
                                                        <td
                                                            // Disabling onClick while page is static
                                                            //onClick={() => browserHistory.push(`/settings/gateway/${gateway.id}`)} className="cursor-pointer"
                                                            >{gateway.name}</td>
                                                        <td>{gateway.product_id}</td>
                                                        <td>{gateway.mac_address}</td>
                                                        <td>{gateway.status}</td>
                                                        <td>{gateway.connected ? "true" : "false" }</td>
                                                        <td>{gateway.networkName}</td>
                                                        {
                                                        isAdmin(this.props.user) &&
                                                            <td className="d-flex justify-content-center align-items-center">
                                                                <a href="#" data-toggle="modal" onClick={(e)=> this.setCurrentGateway(gateway)} data-target="#activate_devices"><img className="mr-2" src={require("../../styles/images/icon/pencil_icn.png")} alt="pencil"  /></a>
                                                                <a href="#" onClick={(e) => this.deactivateGateway(gateway)}>                      
                                                                    <i className="fa fa-times fa-3x"></i>                    
                                                                </a>   
                                                            </td>
                                                        }
                                                    </tr>
                                                )
                                            })
                                        }
                                        </tbody>
                                    </table>
                                </div>

                                <div className="modal fade" id="activate_devices" tabIndex="-1" role="dialog" aria-labelledby="activate_devices" aria-hidden="true">
                                    <div className="modal-dialog modal-dialog-centered" role="document">
                                        <div className="modal-content">
                                            <Form onSubmit={handleSubmit} noValidate={true}>
                                                <div className="modal-header">
                                                    <h5 className="modal-title" id="exampleModalLongTitle">{this.state.title}</h5>
                                                    <button type="button" className="close_gateway_modal close emp_opacity_1" data-dismiss="modal" aria-label="Close"><span className="mr-2 emp_icn_middle">
                                                    <img src={require("../../styles/images/icon/icn_close.png")} alt="Icon" />
                                                </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">

                                                    <div className="row">
                                                        <div className="col-xl-12">
                                                            <InputField
                                                                type="text"
                                                                name="device[name]"
                                                                className={`form-control gateway_name input_modifier emp_text_initial`}
                                                                label="Name"
                                                                placeholder="fc:5eb6:80:50:48"
                                                                />
                                                            {
                                                                this.state.defaultForm &&
                                                                    <div>
                                                                        <InputField
                                                                            type="text"
                                                                            name="device[product_id]"
                                                                            className={`form-control input_modifier emp_text_initial `}
                                                                            label="Product Id"
                                                                            placeholder="IM31"
                                                                            />
                                                                        <InputField
                                                                            type="text"
                                                                            name="device[mac_address]"
                                                                            className={`form-control input_modifier emp_text_initial`}
                                                                            label="Mac Id"
                                                                            placeholder="f070b:a1780750748"
                                                                            />
                                                                    </div>

                                                            }
                                                            {
                                                                !this.state.defaultForm &&
                                                                <div className="form-group">
                                                                    <label className="label_modifier">Note</label>
                                                                    <TextAreaField
                                                                        name="device[note]"
                                                                        placeholder=""
                                                                        maxLength={1000}
                                                                        rows={3}
                                                                        className="form-control textarea_modifier"
                                                                        />
                                                                </div>
                                                            }

                                                            {/*<div className="form-group">
                                                                <label className="label_modifier">Asign sensor to a particular Gateway:</label>
                                                                <select className="custom-select cst-select-box input_modifier shadow_select_hover emp_text_initial">
                                                                    <option selected="">fc:5eb6:80:50:48</option>
                                                                    <option value="1">Do not assign to a particular Gateway</option>
                                                                    <option value="2">Freezer #1</option>
                                                                    <option value="3">f0:0b:a1:80:50:48</option>
                                                                </select>
                                                            </div>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer justify-content-between">
                                                    <a href="#" className="create-group text-left text-primary" data-dismiss="modal">
                                                    <span className="mr-2 emp_icn_middle">
                                                        <img src={require("../../styles/images/icon/icn_close.png")} alt="Icon" />
                                                    </span><span className="ml-2 cancel_btn ">Cancel</span>
                                                    </a>
                                                    <button type="submit" className="btn btn-next btn-primary emp-cst-btn emp-btn">
                                                    <span className="mr-2 emp_icn_middle">
                                                        <img src={require("../../styles/images/icon/ic_checking.png")} alt="Icon" />
                                                    </span>Save</button>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        gatewayReducer: state.gatewayReducer,
        groupReducer: state.groupReducer,
        deviceReducer: state.deviceReducer,
        networkConfigurationReducer: state.networkConfigurationReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    GatewayAction: bindActionCreators(GatewayAction, dispatch),
    NetworkConfigAction: bindActionCreators(NetworkConfigAction, dispatch)
});


export default reduxForm({
    form: 'gatewayForm',
    validate,
    initialValues: {device: {}},
    onSubmit: (values, dispatch) => dispatch(GatewayAction.updateGateway(values))
})(connect(mapStateToProps, mapDispatchToProps)(GatewaySetting));