import { formInstanceConstants } from "../constants/formInstance.constants";
import * as formInstanceApi from "../api/formInstanceApi";
import * as formSectionApi from "../api/formSectionApi";
import { EmpiricUser } from "../formSchema";

export const createFormInstance = (values)=> ({
    type: formInstanceConstants.CREATE_FORM_INSTANCE,
    payload: formInstanceApi.createFormInstance(values)
});

export const updateFormInstance = (id, values)=> ({
    type: formInstanceConstants.UPDATE_FORM_INSTANCE,
    payload: formInstanceApi.updateFormInstance(id, values)
});

export const fetchFormInstances = () =>  ({
    type: formInstanceConstants.FETCH_FORM_INSTANCES,
    payload: formInstanceApi.fetchFormInstances()
});

export const fetchFormInstance = (values)=> ({
    type: formInstanceConstants.FETCH_FORM_INSTANCE,
    payload: formInstanceApi.fetchFormInstance(values)
});

export const  archiveFormInstance = (formId: string) => ({
    type: formInstanceConstants.ARCHIVE_FORM_INSTANCE,
    payload: formInstanceApi.archiveFormInstance(formId)
});

export const fetchFormInstanceSection = (sectionId: string, instanceId: string) => ({
    type: formInstanceConstants.FETCH_FORM_INSTANCE_SECTION,
    payload: formSectionApi.fetchFormSection(sectionId, instanceId)
});

export const bulkFormAccessUpdate = (formId: string, values) => ({
    type: formInstanceConstants.BULK_UPDATE_FORM_ACCESS,
    payload: formInstanceApi.bulkUpdateFormAccess(formId, values)
});

export const exportFormInstance = (formId: string) => ({
    type: formInstanceConstants.EXPORT_FORM_INSTANCE,
    payload: formInstanceApi.exportFormInstance(formId)
});

export const formInstaceBomFields = (formId: string) => ({
    type: formInstanceConstants.FORM_INSTANCE_BOM_FIELDS,
    payload: formInstanceApi.formInstanceBomFields(formId)
});