import {WorkflowModel, WorkflowParamsInterface, UiSchemaInterface} from "../../api/model/Workflow";
import {ScheduleIntervalModel, WorkflowScheduleModel} from "../../api/model/WorkflowSchedule";

import * as Moment from "moment/moment";


function formatObjectSetValue(value: any): any {
    if (Array.isArray(value)) {
        return value.map((option) => option.value);
    }
    return value;
}

function formatEmailValue(value: any): any {
    if (typeof value === "string") {
        const trimmedValue = value.trim();
        if (trimmedValue.length > 0) {
            return trimmedValue.split(",").map((email) => email.trim());
        }
    }
    return value;
}

function removeEmptyStringsFromArray(arr: string[]): string[] {
    return arr.filter((str) => str.trim() !== "");
}

export function formatWorkflowParamsForServer(values: Object, uiSchema: UiSchemaInterface): Object {
    const formattedValues = {...values};
    for (const [key, value] of Object.entries(values)) {
        const uiSchemaForKey = uiSchema.properties[key];
        if (!uiSchemaForKey) {
            continue;
        }

        const widget = uiSchemaForKey["ui:widget"];

        switch (true) {
            case widget.startsWith("objectset"):
                formattedValues[key] = formatObjectSetValue(value);
                break;
            case widget === "input" && uiSchemaForKey["type"] === "email":
                formattedValues[key] = formatEmailValue(value);
                if (Array.isArray(formattedValues[key])) {
                    formattedValues[key] = removeEmptyStringsFromArray(
                        formattedValues[key]
                    );
                }
                break;
            case widget === "date_time":
                formattedValues[key] = new Date(value).toISOString();
                break;
            default:
                break;
        }
    }
    return formattedValues;
}

interface labelValuePair {
    label: string;
    value: string;
}

export function getLabelFromObjectArr(str: string, objArr: labelValuePair[]): string {
    const labelObj: labelValuePair | undefined = objArr.find((obj) => obj.value === str);
    if(!labelObj) {
        console.log("label was not found");
        return str;
    }
    return labelObj.value;
}

function getLabelsFromObjectArr(strArr: string[], objArr: labelValuePair[]): string[] {
    const labels: string[] = [];
    objArr.forEach(obj => {
        if (strArr.includes(obj.value)) {
            labels.push(obj.label);
        }
    });
    return labels;
}

export function formatWorkflowParamsForClient(value: any, workflowParamsForKey: Object, uiSchemaForKey: Object): any {
    const widget = uiSchemaForKey["ui:widget"];
    if (widget === "date_time") {
        return formatDateTimeString(value);
    }

    const uiEnum = uiSchemaForKey["ui:enum"];
    if (!uiEnum) {
        return formatValue(value);
    }

    const type = workflowParamsForKey["type"];
    switch (true) {
        case (type === "array" && widget === "checkbox"):
            return formatCheckboxArray(value, uiEnum);
        case (type === "string" && widget === "select"):
            return formatStringSelect(value, uiEnum);
        default:
            break;
    }

    return value;
}

function formatValue(value: any): string | string[] {
    return Array.isArray(value) ? value.join(', ') : value;
}

function formatCheckboxArray(value: any, uiEnum: any[]): string | string[]{
    const labels = getLabelsFromObjectArr(value, uiEnum);
    return labels.join(', ');
}

function formatStringSelect(value: any, uiEnum: any[]): string | string[]{
    const defaultValue = uiEnum.find((enumItem) => enumItem.value === value);
    return defaultValue.label;
}

export function formatDateTimeString(value: any, format: string = 'MM-DD-YYYY hh:mm A'): string {
    return Moment.utc(value).local().format(format);
}

export function formatWorkflowParamsForEditForm(workflowParams: WorkflowParamsInterface, uiSchema: UiSchemaInterface): WorkflowParamsInterface {
    const formattedParams = {...workflowParams};

    for (const [key, value] of Object.entries(workflowParams)) {
        const uiSchemaForKey = uiSchema.properties[key];
        if (!uiSchemaForKey) {
            continue;
        }

        const widget = uiSchemaForKey["ui:widget"];

        if(widget === "date_time") {
            const date = value;
            formattedParams[key] = formatDateTimeString(date);
        }
    }
    return formattedParams;
}

export function formatScheduleValuesForEditForm(scheduleValues: WorkflowScheduleModel): Object {
    const formattedScheduleValues = {...scheduleValues};

    const startDate = Moment.utc(formattedScheduleValues["start_date"]).local().format('MM-DD-YYYY hh:mm A');
    formattedScheduleValues["start_date"] = startDate;
    formattedScheduleValues["title"] = formattedScheduleValues["title"];
    formattedScheduleValues["description"] = formattedScheduleValues["description"];
    formattedScheduleValues["enabled"] = formattedScheduleValues["enabled"];
    formattedScheduleValues["workflow_id"] = formattedScheduleValues["workflow_id"];
    formattedScheduleValues["interval"] = formattedScheduleValues["schedule_interval"] ?
        formattedScheduleValues["schedule_interval"]['interval'] : null
    formattedScheduleValues["interval_type"] = formattedScheduleValues["schedule_interval"] ?
        formattedScheduleValues["schedule_interval"]['interval_type'] : null

    return formattedScheduleValues;
}

export interface ScheduleIntervalValues {
    schedule_interval: ScheduleIntervalModel | null;
    start_date: string;
}

export function formatScheduleIntervalForServer(values: Object): ScheduleIntervalValues {
    const scheduleInterval: ScheduleIntervalModel | null =
        values['interval'] ? ScheduleIntervalModel.fromJson(values) : null;

    const startDate: string = new Date(values['start_date']).toISOString();
    const startDateWithoutZ: string = startDate.substring(0, startDate.length - 1);

    return {
        schedule_interval: scheduleInterval,
        start_date: startDateWithoutZ
    }
}