import React from 'react';
import Field from "redux-form/es/Field";
import {SelectField} from "../ui/Input/SelectField";

const items = [
    {value: '5m', label: 'Min'},
    {value: '1h', label: 'Hour'},
    {value: '1d', label: 'Day'},
    {value: '1w', label: 'Week'},
    {value: '1m', label: 'Month'},
];

const selectField = ({input, label, items}) => {
    return <SelectField label={label}
                        placeholder=""
                        value={input.value}
                        {...input}
                        items={items}
    />
}

export class PeriodIntervalSelector extends React.Component<{
    fieldName: string
},{}> {
    render() {
        return (
            <div>
                <Field name={this.props.fieldName} label={"Aggregation"} items={items} component={selectField}/>
            </div>
        );
    }
}