import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as EnvironmentAction from '../../actions/environmentAction';
import * as WorkflowActions from "../../actions/workflowActions";
import * as WorkflowScheduleActions from "../../actions/workflowScheduleActions";
import * as ScheduledJobActions from "../../actions/scheduledJobAction";
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {withRouter} from 'react-router';
import ScheduledJobsPage from "../../components/Workflow/ScheduledJobsPage";
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";


export class ScheduledJobListContainer extends React.Component<{
    auth: { isLoggedIn: boolean, user },
    authActions,
    environmentActions,
    workflowActions,
    workflowScheduleActions,
    scheduledJobActions,
    scheduledJobReducer
},{
    loading: boolean,
}> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    async componentDidMount() {
        await this.props.scheduledJobActions.clearScheduledJobs();
        await Promise.all([this.props.workflowActions.listWorkflows(),
            this.props.workflowScheduleActions.listWorkflowSchedules(),
            this.props.scheduledJobActions.listScheduledJobs(null)
        ]);

        this.setState({loading: false});
    }

    async loadMoreJobs(){
        if (!this.props.scheduledJobReducer.cursor) {
            return;
        }
        await this.props.scheduledJobActions.listScheduledJobs(null, this.props.scheduledJobReducer.cursor);
    }

    render() {
        if (this.state.loading) {
            return <LoadingMessage/>;
        }

        const {authActions, auth} = this.props;
        return (
            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                <ScheduledJobsPage
                    loadMoreJobs={this.loadMoreJobs.bind(this)}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    scheduledJobReducer: state.scheduledJobReducer
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    environmentActions: bindActionCreators(EnvironmentAction, dispatch),
    scheduledJobActions: bindActionCreators(ScheduledJobActions, dispatch),
    workflowActions: bindActionCreators(WorkflowActions, dispatch),
    workflowScheduleActions: bindActionCreators(WorkflowScheduleActions, dispatch)
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ScheduledJobListContainer));