import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {browserHistory} from 'react-router';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import * as AuthActions from '../../actions/authActions';
import * as AlertActions from '../../actions/alertAction';
import Header from '../../components/Header/Header';
import { withRouter } from 'react-router';
import AlertForm from '../../components/AlertForm/AlertForm';
import * as GeneralSettingsActions from "../../actions/generalSettingAction";
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import swal from 'sweetalert';

export class NewAlertContainer extends React.Component {
    constructor(props){
        super(props);
        this.state={
            update: true,
            loading: false
        }
    }

    async onSubmit(values,dispatch) {
        this.setState({...this.state, loading: true});
        await dispatch(AlertActions.createAlert(values,
                () => browserHistory.push('/alerts'),
                (errors) => {
                    let msg = errors || 'Unexpected error occurred';
                    swal({
                        icon: 'error',
                        title: 'Create Alert',
                        text: msg[0].message,
                        className: 'error-handler-modal'
                    });
                },this.props.generalSettingsReducer.organization.system_of_units
        ))
        this.setState({...this.state, loading: false});
    }

    componentDidMount() {
        this.props.generalSettingsActions.fetchOrganizationData();
    }

    render() {
        const {authActions, auth } = this.props;
        return (
            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                    />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header" />
                {this.state.loading ? <LoadingMessage/> :
                    <AlertForm onAlertSubmit={this.onSubmit.bind(this)} submitButtonLabel="Create Alert"/>
                }
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.authReducer,
    generalSettingsReducer: state.generalSettingReducer
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    AlertActions: bindActionCreators(AlertActions, dispatch),
    generalSettingsActions: bindActionCreators(GeneralSettingsActions, dispatch)
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(NewAlertContainer));