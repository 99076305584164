import React from 'react';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import * as groupApi from '../../api/groupApi.js';
import * as deviceApi from "../../api/deviceApi";


export default class GroupAndSensorDeviceActiveDropDownField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            selectedSuggestion: null,
            loading: false,
            dataList: []
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.renderSuggestionItem = this.renderSuggestionItem.bind(this);
        this.dropdownData = this.dropdownData.bind(this);
    }

    onInputChange(e, {newValue}) {
        this.setState({
            value: newValue,
            selectedSuggestion: null
        });
    }

    async onSuggestionSelected(e, {suggestion}) {
        if (suggestion.hasOwnProperty("group") && suggestion.group) {
            const selectedGroup = await groupApi.fetchGroup(suggestion.id);
            const selections = this.props.selections || [];
            const identities = selections.map(item => item.identity);
            const devices = selectedGroup.group.devices.filter(item => !identities.includes(item.identity));

            devices.forEach(device => {
                this.props.handleChange(device);
            });
        } else {
            this.props.handleChange(suggestion);
        }
    }

    async onSuggestionsFetchRequested({value}) {
        try {
            this.setState({loading: true});
            let results;
            results = await Promise.all([
                groupApi.searchGroup({name: value}), deviceApi.searchDeviceActiveByType({
                    name: value,
                    device_type: "sensor"
                })
            ]);

            const dataList = [];
            results.forEach(current => {
                if (current.hasOwnProperty("groups")) {
                    dataList.push(...current.groups);
                } else if (Array.isArray(current)) {
                    const selections = this.props.selections || [];
                    const identities = selections.map(item => item.identity);
                    const filtered = current.filter(item => !identities.includes(item.identity));
                    dataList.push(...filtered);
                } else {
                    console.log("Unexpected api result")
                }
            });

            this.setState({
                loading: false,
                dataList: dataList
            });

        } catch (e) {
            if (!axios.isCancel(e)) {
                this.setState({loading: false});
            }
            console.error(e)
        }
    }

    onSuggestionsClearRequested() {
        this.setState({dataList: [], loading: false});
    }

    renderSuggestionItem(suggestion) {
        let title, option;
        if (suggestion.hasOwnProperty("group") && suggestion.group) {
            title = suggestion.name;
            option = this.dropdownData(title, suggestion.mac_address, require("../../styles/images/icon/ic_group.png"));
        } else {
            title = suggestion.name;
            option = this.dropdownData(title, suggestion.mac_address, require("../../styles/images/icon/ic_gateway.png"));
        }
        return option;
    }

    dropdownData(title, data, img) {
        return (
            <div className="emp_create_notify_box">
                <div className="card border-0">
                    <div className="card-body">
                        <div className="media align-items-center">
                            <div className="emp_user mr-3">
                                <img className="rounded-circle" src={img} alt="Generic placeholder"/>
                            </div>
                            <div className="media-body">
                                <h6 className="mt-0 mb-0">{title}</h6>
                                <span className="emp_txt_blured">{data}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    getSuggestionValue(suggestion) {
        return (suggestion.group ? suggestion.name : '');
    }

    render() {
        const {name, size, className, placeholder} = this.props;
        const {value, dataList} = this.state;
        // alert is being edited, sensor field should be disabled because we can't add multiple sensors with put requests
        const disabled = this.props.isDisabled ? true : false;

        const inputProps = {
            value: value,
            type: 'search',
            placeholder: placeholder || '',
            name: name,
            className: `form-control input_modifier ${this.props.classNames}`,
            onChange: this.onInputChange,
            onKeyDown: this.onKeyDown,
            onClick: event => event.target.select(),
            disabled: disabled
        };
        return (
            <div
                className={`destination-search-bar destination-search-bar--${size} ${className}`}>
                <Autosuggest
                    inputProps={inputProps}
                    suggestions={dataList}
                    highlightFirstSuggestion={true}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestionItem}
                    renderInputComponent={this.props.renderInputComponent}
                    shouldRenderSuggestions={this.props.shouldRenderSuggestions}
                />
            </div>
        );
    }
}

const propTypes = {
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
    handleChange: PropTypes.func,
    shouldRenderSuggestions: PropTypes.func,
    classNames: PropTypes.string,
    selections: PropTypes.array,
    renderInputComponent: PropTypes.func
};

const defaultProps = {
    placeholder: '',
    classNames: '',
    shouldRenderSuggestions: () => true,
    isDisabled: false,
    handleChange: (element) => element,
    selections: [],
    renderInputComponent: undefined
};