import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import IncidentReport from "./model/IncidentReport";
import NewIncidentReportResponse from "./model/NewIncidentReportResponse";
import {isTemperatureField} from "../utils/utils";
import {convertTemperature, getDesiredUnits, MeasurementUnit} from "../utils/formatHelper";

export const getReport = (id, systemOfUnits) => {
    return axios.get(`${BASE_URL()}/api/v1/incident_reports/${id}`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': storage.get('token')
            }
        }
    ).then(response => NewIncidentReportResponse.fromJson(response.data, systemOfUnits));
};

export const listReports = (startDate, endDate, limit) => {
    return axios.get(`${BASE_URL()}/api/v1/incident_reports`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': storage.get('token')
            },
            params: {
                start_date: startDate,
                end_date: endDate,
                limit: limit
            }
        }
    ).then(response => response.data.items.map(IncidentReport.fromJson));
}

export const newReport = (incidentId, triggerId, systemOfUnits = null) => {
    let query = `?incident_id=${incidentId}&trigger_id=${triggerId}`
    return axios.get(`${BASE_URL()}/api/v1/incident_reports/new${query}`,
        {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': storage.get('token')
            }
        }
    ).then(response => NewIncidentReportResponse.fromJson(response.data, systemOfUnits));
};

export const saveReport = (values, successCallback, errorCallback, systemOfUnits = null) => {
    let conditions = values.trigger_conditions || [];
    let incidentDatapoint = values.incident_datapoint;

    if (isTemperatureField(values.trigger_type)) {
        const valueUnits = getDesiredUnits(systemOfUnits, MeasurementUnit.Celsius);
        conditions = conditions
            .map(condition => {
                // The value is already converted from the get call, we need to convert it back to celsius.
                condition.threshold = convertTemperature(Number(condition.threshold), valueUnits, MeasurementUnit.Celsius, 2);
                condition = `x ${condition.condition} ${condition.threshold}`
                return condition;
            });
        incidentDatapoint = convertTemperature(Number(incidentDatapoint), valueUnits, MeasurementUnit.Celsius, 2);
    }


    let payload = {
        status: values.status,
        author: values.author.id,
        report_date: values.report_date,
        group_name: values.group_name,
        device_id: values.device_id,
        device_name: values.device_name,
        description: values.description,
        reason_ids: values.reasons ? values.reasons.map((reason) => reason.id) : [],
        storage_unit_type_ids: values.storage_unit_types ? values.storage_unit_types.map((type) => type.id) : [],
        user_ids: values.users ? values.users.map((user) => user.id) : [],
        group_ids: values.groups ? values.groups.map((group) => group.id) : [],
        trigger_id: values.trigger_id,
        trigger_title: values.trigger_title,
        trigger_condition: values.trigger_condition,
        trigger_conditions: conditions,
        trigger_type: values.trigger_type,
        incident_timestamp: values.incident_timestamp,
        incident_datapoint: incidentDatapoint,
        incident_id: values.incident_id
    };
    return axios.post(`${BASE_URL()}/api/v1/incident_reports`, payload, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        if (Array.isArray(response.data.errors) && response.data.errors.length === 0)
            successCallback();
        else
            errorCallback(response.data.errors)
    })
};
