import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import * as AuthActions from '../../actions/authActions';
import * as IncidentReportActions from '../../actions/incidentReportAction';
import * as GeneralSettingsActions from '../../actions/generalSettingAction';

import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import IncidentReportForm from '../../components/IncidentReportForm/IncidentReportForm'


export class EditIncidentReportContainer extends React.Component {

    async componentDidMount() {
        const reportId = this.props.params.id;
        await this.props.generalSettingsActions.fetchOrganizationData();
        this.props.IncidentReportActions.clearReportForEdit();
        this.props.IncidentReportActions.getReport(reportId, this.props.systemOfUnits);
    }

    render() {
        const {authActions, auth } = this.props;

        return (
            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                    />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                <IncidentReportForm />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    user: state.authReducer.user,
    systemOfUnits: state.generalSettingReducer.organization.system_of_units
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    IncidentReportActions: bindActionCreators(IncidentReportActions, dispatch),
    generalSettingsActions: bindActionCreators(GeneralSettingsActions, dispatch),
});

export default connect(mapStateToProps,mapDispatchToProps)(EditIncidentReportContainer);

