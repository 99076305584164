import React from "react";
import {Field, Form, reduxForm} from "redux-form";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {TextField} from "../../ui/Input/TextField";
import {bindActionCreators} from "redux";
import * as DeviceAction from "../../actions/deviceAction";
import {SelectField} from "../../ui/Input/SelectField";

const titleField = ({input, label, meta: {touched, error}}) => {
    return (
        <TextField label={label} {...input}/>
    );

}

const selectField = ({input, label, items}) => {
    return <SelectField label={label}
                        placeholder=""
                        value={input.value}
                        {...input}
                        items={items}
    />
}

class DeviceAssignmentForm extends React.Component {
    constructor(props) {
        super(props);
    }


    async onAssignmentSubmit(values) {

    }

    renderSelectFieldRow(fieldName, label, items) {
        return (
            <div className="row mt-2 mb-3">
                <div className={"col-12"}>
                    <Field name={fieldName} label={label} items={items} component={selectField}/>
                </div>
            </div>
        );
    }

    render() {
        const {error, handleSubmit, pristine, reset, submitting, devices} = this.props;

        const deviceItems = devices.map(device => ({value: device.id, label: device.name}));

        return <Form onSubmit={handleSubmit(this.props.onSubmit)}>
            <div className={"container-fluid"}>
                <div className={"row mt-2 mb-2"}>
                    <h1 className={"emp-type-h2 col"}>{'Assign Device'}</h1>
                </div>

                <div className={"row mb-2"}>
                    <div className={"col-12"}>
                        <div className="emp-type-subtitle1">
                            {this.renderSelectFieldRow("deviceId", "Device", deviceItems)}
                        </div>
                    </div>
                </div>
                <div className={"row mb-2"}>
                    <div className={"col"}></div>
                    <div className={"col-3 col-sm-2"}>
                        <button type="submit" disabled={submitting} className={"btn btn-primary"}><span
                            className={"emp-type-btn-primary"}>SAVE</span>
                        </button>
                    </div>
                </div>
            </div>
        </Form>;
    }

    renderTextRow(fieldName, label) {
        return (
            <div className="row mt-2 mb-3">
                <div className={"col-12 col-sm-6"}>
                    <Field name={fieldName} label={label} component={titleField}/>
                </div>
            </div>
        );
    }

}

DeviceAssignmentForm.propTypes = {
    assetId: PropTypes.object.isRequired,
    devices: PropTypes.object.isRequired,
    onSubmit: PropTypes.func.isRequired
}

export const validate = (values) => {
    const errors = {};
    return errors;
};
export const mapStateToProps = (state) => {
    return ({
        initialValues: {
            deviceId: state.deviceReducer.device.id,
            assetId: state.assetReducer.asset.id,
        }
    });
};

export const FORM_NAME = "deviceAssignmentForm";

DeviceAssignmentForm = connect(mapStateToProps)(
    reduxForm({
        form: FORM_NAME,
        enableReinitialize: true,
        validate
    })(DeviceAssignmentForm)
);

export default DeviceAssignmentForm;