import {deviceConstants} from '../constants/device.constants';
import store from '../store/store';
import {browserHistory} from 'react-router';
import * as deviceApi from "../api/deviceApi";
import * as UserActions from "./userAction.js";

export const deviceDetails = (values)=> ({
    type: deviceConstants.DEVICE,
    payload: deviceApi.updateDeviceDetails(values)
        .then((response) => redirectPath(response))
});

export const activateDevice = (values)=> ({
    type: deviceConstants.ACTIVATE_DEVICE,
    payload: deviceApi.activateDevice(values)
        .then((response) => redirectPath(response))
});

export const deactivateDevice = (macAddress)=> ({
    type: deviceConstants.DEACTIVATE_DEVICE,
    payload: deviceApi.deactivateDevice(macAddress)
});

export const fetchDeviceData = (values)=> ({
    type: deviceConstants.FETCH_DEVICE_DATA,
    payload: deviceApi.fetchDeviceData(values)
});

export const getDevice = (id, expand) => ({
    type: deviceConstants.GET_DEVICE,
    payload: deviceApi.getDevice(id, expand)
});

export const searchDevices = ({name, expand})=> ({
    type: deviceConstants.SEARCH_DEVICE_DATA,
    payload: deviceApi.searchDeviceActive({name, expand})
});

export const redirectPath = (response) => {
    const currentLocation = browserHistory.getCurrentLocation();
    let redirectTo ='/';
    if( response.success){
        let state = store.getState();
        state.deviceReducer.valid = true;
        state.deviceReducer.invalid = false;
        state.deviceReducer.updated = true;
        store.dispatch(UserActions.updateUserDetails(state.authReducer));
        redirectTo = '/groups_devices'
    }
    else{
        let state = store.getState();
        state.deviceReducer.invalid = true;
        state.deviceReducer.valid = false;
        state.deviceReducer.updated = true;
        redirectTo = currentLocation
    }

    browserHistory.push(redirectTo);
    return response
};

export const updateDevice = (id, values) => ({
    type: deviceConstants.UPDATE_DEVICE,
    payload: deviceApi.updateDevice(id, values)
});