import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';

export const getNetworkConfigurations = ({deviceIds}) => {

    return Promise.allSettled(deviceIds.map(device_id => {
        return axios.get(`${BASE_URL()}/api/v1/devices/${device_id}/network_configuration`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': storage.get('token')
            },
            defaultErrorHandler: false
        })
    }))
        .then(values => {
            const results = [];
            values.forEach(v => {
                if (v.status === "fulfilled") results.push(v.value.data.item);
            });
            return results;
        })
        .catch(error => {
            console.log(error);
            return [];
        });

};