import {ProbesApi} from "../api/probesApi";
import {probeConstants} from "../constants/probe.constants";

const probesApi = new ProbesApi();

export const queryProbes = () => {
    return {
        type: probeConstants.QUERY_PROBES_DATA,
        payload: probesApi.queryProbes()
    }
};

export const getProbe = (probeId) => ({
    type: probeConstants.GET_PROBE,
    payload: probesApi.getProbe(probeId)
});
