import React from "react";

import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";
import {getMetric} from "../../api/metricApi";
import isEqual from 'lodash/isEqual';


interface IMetricWidgetProps {
    start_time: string;
    end_time: string;
    period: string;
    limit: number;
    properties: Record<string, any>;
    system_of_units?: string;
    render: (props: {
        data: IMetricData[] | null;
        loading: boolean;
        error: any;
    }) => JSX.Element;
}

export interface IMetricData {
    instance_id?: string;
    metric: string;
    timestamps: string[];
    values: number[];
    unit: string | null;
    colorHex: string;
}

interface IMetricWidgetState {
    loading: boolean;
    error: any;
    data: IMetricData[] | null;
}

export default class MetricWidget extends React.Component<IMetricWidgetProps,IMetricWidgetState> {
    _isMounted = false;

    constructor(props: IMetricWidgetProps) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            data: null
        }
    }

    async componentDidMount() {
        this._isMounted = true;
        this.getMetricData();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    getMetricData() {
        if(!this.props.properties){
            console.log("No properties, incomplete Data");
            return;
        }
        const metrics = this.props.properties.metrics;
        const promises: Promise<{ items: any; nextCursor: any; }>[] = [];
        for (let i=0; i<metrics.length; i++){
            const instance_id = metrics[i].metric?.instance_id;
            const metric = metrics[i].metric?.metric_name;
            if(instance_id && metric){
                promises.push(getMetric(instance_id, metric, this.props.start_time, this.props.end_time, this.props.period, this.props.limit, this.props.system_of_units, null));
            }
        }
        Promise.all(promises)
            .then(response => response.map(res => res.items))
            .then(datapoints => {
                const data: IMetricData[] = [];
                for (let i=0; i<datapoints.length; i++){
                    data.push({
                        instance_id: metrics[i].metric.instance_id,
                        metric: metrics[i].metric.metric_name,
                        timestamps: datapoints[i].map(datapoint => datapoint.timestamp),
                        values: datapoints[i].map(datapoint => datapoint.value),
                        unit: datapoints[i].length > 0 ? datapoints[i][0].unit : null,
                        colorHex: metrics[i].color_hex
                    });
                }

                if (!this._isMounted) return;

                this.setState({
                    ...this.state,
                    loading: false,
                    data: data,
                    error: null
                });
            });
    }

    componentDidUpdate(prevProps: IMetricWidgetProps, prevState, snapshot) {
        if (isEqual(this.props.properties) !== isEqual(prevProps.properties)
            || this.props.start_time !== prevProps.start_time || this.props.end_time !== prevProps.end_time
            || this.props.period !== prevProps.period) {
            this.setState({...this.state, loading: true})
            this.getMetricData();
        }
    }


    render() {
        const {render: View} = this.props;
        return <View
            data={this.state.data}
            loading={this.state.loading}
            error={this.state.error}
        />
    }

}

