import React from 'react';
import ReactTable from 'react-table';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import * as EnvironmentActions from '../../actions/environmentAction';
import * as GeneralSettingsActions from '../../actions/generalSettingAction';
import DashboardDeviceChart from './DashboardDeviceChart';
import DashboardSelectionNav from './DashboardSelectionNav';
import LoadingMessage from "../LoadingMessage/LoadingMessage";
import DeviceConnectionTable from "./DeviceConnectionTable";
import {MeasurementUnit, SystemOfUnits} from "../../utils/formatHelper";

export class DeviceDetailsPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            device: {}
        };
        this.onDateFormSubmit = this.onDateFormSubmit.bind(this);
    }

    async componentDidMount() {
        await this.props.generalSettingsActions.fetchOrganizationData();
        this.props.environmentActions.getDeviceAndTelemetry({
            mac_address: this.props.deviceId,
            format: this.props.generalSettingsReducer.organization.system_of_units
        });

        console.log("fetching telemetry")

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.environmentReducer.updated) {
            this.setState(state => {
                return {
                    loading: false,
                    device: nextProps.environmentReducer.detailDeviceData
                }
            });
            nextProps.environmentReducer.updated = false;
        }
    }

    onDateFormSubmit(values,dispatch) {
       this.setState({loading: true})
       // ToDo: Don't really need to get device again...
       dispatch(EnvironmentActions.getDeviceAndTelemetry({
           mac_address: this.props.deviceId,
           format: this.props.generalSettingsReducer.organization.system_of_units,
           ...values
       }))
    }

    render() {
        let contentArea = (this.state.loading) ?
              <LoadingMessage />
             :<DashboardDeviceChart device={this.state.device} units={this.props.generalSettingsReducer.organization.system_of_units === SystemOfUnits.Imperial
                ? MeasurementUnit.Fahrenheit : MeasurementUnit.Celsius }/>
        const options = [
            {value: 'minutes', label: 'Minutes'},
            {value: 'hour', label: 'Hour'},
            {value: 'day', label: 'Day'},
            {value: 'week', label: 'Week'},
            {value: 'month', label: 'Month'}
        ];
        console.log("device",this.state.device);
        return (
            <main className="app-content">
                <div className="app-title border-bottom">
                    <h1>Device Detail</h1>
                    <DashboardSelectionNav onSubmitHandler={this.onDateFormSubmit} options={options}/>
                </div>
                {contentArea}
                <div className="mt-5">
                    <DeviceConnectionTable deviceId={this.props.deviceId}/>
                </div>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        environmentReducer: state.environmentReducer,
        generalSettingsReducer: state.generalSettingReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    environmentActions: bindActionCreators(EnvironmentActions, dispatch),
    generalSettingsActions: bindActionCreators(GeneralSettingsActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceDetailsPage);
