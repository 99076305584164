import React from "react";
import {ListView} from "../../../ui/ListView/ListView";
import DeviceTypeMetricsForm from "../DeviceTypeMetricsForm";
import DeviceTypeMetric from "../../../api/model/DeviceTypeMetric";
import * as $ from "jquery";

interface DeviceTypeMetricsTabPageProps {
    deviceTypeId: string;
    deviceMetrics: Array<DeviceTypeMetric>;
    onItemClick: (item: any) => void;
    onDeleteMetricClick: (item: any) => void;
    onAddMetricSubmit: (values: any) => Promise<void>;
}

export class DeviceTypeMetricsTabPage extends React.Component<DeviceTypeMetricsTabPageProps> {
    constructor(props: DeviceTypeMetricsTabPageProps) {
        super(props);
    }

    renderTextRow(label: string, text: string) {
        return (
            <div className="row mt-2">
                <span className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-3 emp-type-subtitle1">{label}</span>
                <span className={"col-6 col-sm col-md col-lg col-xl emp-type-subtitle1"}>{text}</span>
            </div>
        );
    }

    onAddMetricClick = () => {
        $("#assign_device").modal("show");
    };

    onAddMetricSubmit = async (values: any) => {
        await this.props.onAddMetricSubmit(values);
        $("#assign_device").modal("hide");
    };

    onDeleteItemClick = (item: DeviceTypeMetric) => {
        debugger;
        console.log(`delete item clicked ${item.name}`)
        this.props.onDeleteMetricClick(item);
    };

    renderItem = (item: DeviceTypeMetric, index: number) => {
        return (
            <div className="container-fluid">
                <div className={"row"}>
                    <div className={"col"}/>
                    <div className="col-1">
                        <button className={"btn btn-link"} onClick={() => this.onDeleteItemClick(item)}>
                            <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        {this.renderTextRow("Name", item.name)}
                        {this.renderTextRow("Display Title", item.display_name)}
                        {this.renderTextRow("Description", item.description)}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const {deviceMetrics, deviceTypeId} = this.props;

        return (
            <>
                <div className={"container-fluid"}>
                    <div className={"row mt-2 mb-2"}>
                        <div className={"col"}></div>
                        <div className={"col-4 col-sm-3"}>
                            <button className={"btn btn-link"} data-toggle="modal" onClick={this.onAddMetricClick}>
                                <span className={"emp-type-btn-primary"}>ADD METRIC</span>
                            </button>
                        </div>
                    </div>

                    <div className={"row mt-4"}>
                        <div className={"col"}>
                            <ListView
                                datasource={deviceMetrics}
                                renderItem={this.renderItem}
                                onItemClick={this.props.onItemClick}
                            />
                        </div>
                    </div>

                    <div
                        className="modal fade"
                        id="assign_device"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="add_device"
                        aria-hidden="true"
                    >
                        <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                                <DeviceTypeMetricsForm
                                    deviceTypeId={deviceTypeId}
                                    onSubmit={this.onAddMetricSubmit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default DeviceTypeMetricsTabPage;
