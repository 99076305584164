import store from '../store/store';
import * as DeviceActions from '../actions/deviceAction';
import * as GatewayActions from '../actions/gatewayAction';

export const deviceData =() => {
    store.dispatch(DeviceActions.fetchDeviceData());
};

export const inactiveDevices = () => {
    store.dispatch(DeviceActions.fetchDeviceData({status: 'inactive'}))
};

export const gatewayData =() => {
    store.dispatch(GatewayActions.fetchGatewayData());
};