import {jsPDF} from 'jspdf';
import React from 'react';
import heading from "./components/heading";
import table from "./components/table";

/**
 * Retrieves an image and converts to base64. Image must have CORS friendly params.
 * @param url
 * @param options
 * @param callback
 */
function getBase64ImageUri(url, options, callback) {
    var img = new Image(options.width,options.height);
    img.crossOrigin = "anonymous";

    img.onerror = function(error) {
        console.log(error);

    }

    img.onload = function() {
        callback(this);
    }
    img.src = url;
}


export default class ReportPdf {
    doc;
    reportName;

    constructor(reportName) {
        this.doc = new jsPDF('p', 'mm', 'a4', true);
        this.lineHeight = 12;
        this.offsetY = 10;
        this.reportName = reportName;

        this.fontSizes = {
            heading1: 32,
            heading2: 24,
            heading3: 20,
            heading4: 16,
            paragraph: 10
        }

        this.margin = {
            top: 25,
            left: 25,
            right: 25,
            bottom: 25
        }
    }

    /**
     * Generates a customer report based on provided data.
     *
     * @param data array of data objects to be displayed
     * @param address array of values to be written line by line
     * @param logo_url logo to be retrieved and inserted into report. Image must have CORS friendly headers. See AWS CORS bucket Policy.
     */
    generate(data, address, logo_url) {
        let startX = this.margin.left;
        let startY = this.margin.top;
        const pageHeight = this.doc.internal.pageSize.height;
        const pageWidth = this.doc.internal.pageSize.width;
        const pageCenterX = pageWidth / 2;
        const that = this;

        getBase64ImageUri(logo_url, {width: 500, height: 500}, (image) => {
            that.doc.addImage(image, (pageCenterX + startX), startY, 50, 50);
            startY = heading(that.doc, address, startX, startY, that.fontSizes, 6);
            startY += that.lineHeight * 2;

            startY = table(that.doc, data.columns, data.items, startY, that.fontSizes.paragraph, 8);

            that.doc.save(this.reportName);
        });
    }
}