import React from 'react';
import SearchInput from "../SearchInput/SearchInput.js";
import {Field, FieldArray} from "redux-form";


export const SearchTypes = {
    DEVICE_ACTIVE: "device-active"
};

export class DeviceItemField extends React.Component {

    render() {
        return <Field name={`${this.props.item.id}`} key={this.props.item.id} component={this.renderItem.bind(this)}/>;
    }

    renderItem() {
        return (<a href="#"><span
            className="badge badge-pill badge-primary emp_notify_badge">{`${this.props.item.name}`}
            {!this.props.isDisabled && <i
                className="fa fa-times-circle emp_notify_icn"
                onClick={this.props.onClickHandler.bind(this)}/>}
            </span></a>);
    }
}

export class ShipmentContactItemField extends React.Component {

    render() {
        return <Field name={`${this.props.item.id}`} key={this.props.item.id} component={this.renderItem.bind(this)}/>;
    }

    renderItem() {
        const title = this.props.item.contact_address;
        return (<a href="#"><span
            className="badge badge-pill badge-primary emp_notify_badge">{`${title}`}
            {!this.props.isDisabled && <i
                className="fa fa-times-circle emp_notify_icn"
                onClick={this.props.onClickHandler.bind(this)}/>}
            </span></a>);
    }
}

export class MemberItemField extends React.Component {

    render() {
        return <Field name={`${this.props.item.id}`} component={this.renderItem.bind(this)}/>;
    }

    renderItem() {
        return (<a href="#"><span
            className="badge badge-pill badge-primary emp_notify_badge">{`${this.props.item.email}`}<i
            className="fa fa-times-circle emp_notify_icn"
            onClick={this.props.onClickHandler.bind(this)}/> </span></a>);
    }
}

export class SearchableDropDownField extends React.Component {
    constructor(props) {
        super(props);
        this.renderFields = this.renderFields.bind(this);
    }

    render() {
        return  <div className="form-group">
            <div className="row mb-2">
                <div className="col-lg-12">
                    <FieldArray name={this.props.fieldName} component={this.renderFields.bind(this)}/>
                </div>
            </div>
        </div>
    };

    renderFields = ({fields, meta: {touched, error, submitFailed}}) => {
        let { placeholder } = this.props;
        return (
            <div>
                <div className="form-group search-member-container emp_position emp_position_inherit">
                    <label className="label_modifier">{this.props.title}</label>
                    <SearchInput placeholder={placeholder || ''} handleChange={(suggestion, type) => fields.push(suggestion)} type={this.props.type} isDisabled={this.props.isDisabled}/>
                </div>
                {submitFailed && error && <div className="form-group__error">{error}</div>}
                <ul>
                    {
                        fields.map((device, index) => {
                            const ItemFieldComponent = this.props.itemFieldComponent;
                            return (
                                <ItemFieldComponent item={fields.get(index)} onClickHandler={() => fields.remove(index)} key={index} isDisabled={this.props.isDisabled}/>
                            );
                        })
                    }
                </ul>
            </div>
        );
    };
}