import {sensorConstants} from '../constants/sensor.constants';
import * as sensorApi from "../api/sensorApi";

export const updateSensor = (values)=> ({
    type: sensorConstants.UPDATE_SENSOR,
    payload: sensorApi.updateSensor(values)
});

export const deleteSensor = (values)=> ({
    type: sensorConstants.DELETE_SENSOR,
    payload: sensorApi.deleteSensor(values)

});

export const getAllSensors = (values)=> ({
    type: sensorConstants.FETCH_SENSOR_DATA,
    payload: sensorApi.getAllSensors(values)
});

export const getSensor = (values)=> ({
    type: sensorConstants.FETCH_SENSOR,
    payload: sensorApi.getSensor(values)
});
