import React from 'react';

import { horizontalClasses } from '../FormFields';

interface TextAreaProps {
    label: string
    value: string
    onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
    orientation?: string
    classes?: string
}

export const TextArea = ({ label, value, orientation, onChange, classes }: TextAreaProps) => (
    <div
            className={`form-input my-3  ${classes || "col-sm-6"} ${
                orientation === "horizontal" && horizontalClasses
            }`}
        >
            {label && <label>{label}:</label>}


            <textarea rows={4} value={value} onChange={onChange} className='form-control' />
        </div>
)