import React from "react";
import {ListView} from "../../../ui/ListView/ListView";
import PropTypes from "prop-types";
export class SubAreasTabPage extends React.Component {
    constructor(props) {
        super(props);
    }

    renderTextRow(label, text) {
        return (
            <div className="row mt-2">
                <span className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-3 emp-type-subtitle1">{label}</span>
                <span className={"col-6 col-sm col-md col-lg col-xl emp-type-subtitle1"}>{text}</span>
            </div>
        );
    }

    renderItem = (item, index) => {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 col-sm-2">
                        <img className={"placeholder img-thumbnail"}
                             src={item.imageUrl || "https://via.placeholder.com/150"}/>
                    </div>
                    <div className="col-12 col-sm-10">
                        {this.renderTextRow("Name", item.name)}
                        {this.renderTextRow("Description", item.description)}

                    </div>
                </div>
            </div>
        )
    }
    render() {
        const {subAreas} = this.props;

        return (
            <>
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <ListView
                                datasource={subAreas}
                                renderItem={this.renderItem}
                                onItemClick={this.props.onItemClick}
                            />
                        </div>
                    </div>

                </div>
            </>
        )
    }
}

SubAreasTabPage.propTypes = {
    subAreas: PropTypes.array.isRequired,
    onItemClick: PropTypes.func
}