import {environmentConstants} from '../constants/environment.constants.js';

const initialState = {
    devicesData: [],
    updated: false,
    detailDeviceData: { device: {}, telemetry:[]},
    gatewayConnectionHistory: [],
    gatewayConnectionHistoryUpdated: false,
};

export const environmentReducer = (state = initialState, action) => {
    switch (action.type) {
        case environmentConstants.SEARCH_DEVICE_DATA_FULFILLED:
            state = {
                ...state,
                devicesData: action.payload,
                updated: true
            };
            return state;

        case environmentConstants.REPORT_DATA_TO_CSV_FULFILLED:
            state = {
                ...state,
                devicesData: action.payload.devices,
                updated:true
            };
            return state;

        case environmentConstants.GET_DEVICE_AND_TELEMETRY_FULFILLED:
            if (action.payload.device && action.payload.telemetry) {
                let newData = { device: action.payload.device, telemetry: action.payload.telemetry};
                state = {
                    ...state,
                    detailDeviceData: newData,
                    updated: true
                }; 
            }
            return state;
        case environmentConstants.GET_GATEWAY_CONNECTION_HISTORY_FULFILLED:
            state = {
                ...state,
                gatewayConnectionHistoryUpdated: true,
                gatewayConnectionHistory: action.payload.history
            }; 
            return state;
        default:
            return state;
    }
};

