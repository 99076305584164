import React from "react";

export default class TriggerServiceAuditFormatter {

    formatLogEvent(auditLog) {
        let title = auditLog.record ? auditLog.record.title : "";

        switch (auditLog.access_type) {
            case "create":
                return <a className="audit-log-link" href={`/alerts/${auditLog.resource}/edit`}>{`created alert '${title}'`}</a>
            case "delete":
                return `deleted alert '${auditLog.record.trigger_id}'`;
            case "update":
                return <a className="audit-log-link" href={`/alerts/${auditLog.resource}/edit`}>{`updated alert '${title}'`}</a>;
            default:
                console.error("Received unknown access type: " + auditLog.access_type);
        }
    }
}