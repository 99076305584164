import React, { useState } from "react";
import swal from '@sweetalert/with-react'

import * as FormInstanceActions from "../../actions/formInstanceAction";

import { MemberRow } from "../CreateSOPFormPage/MembersTable";
import Select from "react-select";
import { EmpiricUser } from "../../formSchema";


const sortUsersByEmail = (a: EmpiricUser, b: EmpiricUser): number =>
  a.email.localeCompare(b.email)

export const MemberEdit = ({
  formUsers: currFormUsers,
  userList: orgUsers,
  formId
}) => {
  const [error, setError] = useState('')

  // Exisiting form users
  const [formUsers, setFormUsers] = useState(currFormUsers)

  // Filter out users already in the form to user as select options
  const [selectUserList, setSelectUsers] = useState(
    orgUsers
      .filter((user) => !formUsers.find((formUser) => formUser.id === user.id))
      .sort(sortUsersByEmail)
  )

  //   Handle adding a new user to the form
  const handleUserSelect = (selectedUser) => {
    const existingUser = formUsers.find((user) => user.id === selectedUser.id && user._destroy)

    // If user is already in form, with destroy flag, remove destroy flag
    if (existingUser) {
      // Update to remove destroy flag
      existingUser._destroy = false;
      setFormUsers((prev) => prev.map(u => u.user_id === selectedUser.id ? existingUser : u))
    } else {
      // Add user to form users list
      setFormUsers((prev) => [...prev, selectedUser])
    }

    // Remove user from select option list
    setSelectUsers((prev) =>
      prev.filter((user) => user.id !== selectedUser.id).sort(sortUsersByEmail)
    )
  }

  //   Update user role
  const updateUserRole = (user, role) => {
    // Update user role in form users list
    setFormUsers((prev) =>
      prev.map((u) => (u.id === user.id ? { ...u, form_role: role.value.toLowerCase() } : u))
    )
  }

  const removeUser = (user) => {
    // Remove user from form users list if they are new
    if (!user.access_id) {
      setFormUsers((prev) => prev.filter((u) => u.id !== user.id))
    } else {
      // If user is not new we have to add the destroy flag
      setFormUsers((prev) =>
        prev.map((u) => (u.id === user.id ? { ...u, _destroy: true } : u))
      )
    }

    // Add user back to select list
    setSelectUsers((prev) => [...prev, user].sort(sortUsersByEmail))
  }

  const closeModal = () => {
    swal.close()
  }

  const handleSave = () => {
    // Filter out users that have been removed
    const activeUsers = formUsers.filter((user) => !user._destroy)

    // if no users are present, return error prompting for 1 user required
    if (activeUsers.length <= 0) {
      setError('At least one user is required')
      return
    }

    // Get all user roles
    const roles = activeUsers.map((user) => user.form_role)

    // Require a role for all users
    if (roles.length !== activeUsers.length) {
      setError('Please select a role for all users')
      return
    }

    // Require at least one operator
    if (!roles.includes('operator')) {
      setError('At least one user must be an operator')
      return
    }

    // Format users for request
    const users = formUsers.map((user) => ({
      id: user.access_id || null,
      user_id: user.id,
      form_role: user.form_role,
      _destroy: user._destroy || false
    }))

    // Send request to update form member access
    const res = FormInstanceActions.bulkFormAccessUpdate(formId, users)
    res.payload
      .then(() => {
        swal('Success', 'Form members updated successfully', 'success')
      })
      .catch((error) => {
        swal('Error', 'An error occurred while updating form members', 'error')
      })
  }

  return (
    <div className="text-left">
      <div className="mb-2 border-bottom">
        <h2>Form Members</h2>
        <p className="m-0">
          <i>Form require at least one Operator.</i>
        </p>
      </div>

      {/* Input to add new users */}
      <div>
        <Select
          isSearchable
          onChange={handleUserSelect}
          options={selectUserList.map((user) => ({
            ...user,
            value: user.id,
            label: user.email
          }))}
          value={null}
          placeholder="Select members"
          className="form-group__field"
        />
      </div>

      <div className="text-danger">{error}</div>

      {/* User List */}
      <table className="mx-2 my-2 justify-content-between align-items-center w-100">
        <tbody>
          {formUsers.map(
            (member) =>
              !member._destroy && (
                <MemberRow
                  key={member.id}
                  user={member}
                  updateUserRole={updateUserRole}
                  removeUser={removeUser}
                />
              )
          )}
        </tbody>
      </table>

      {/* Calls to action */}
      <div className="d-flex justify-content-end">
        <button className="mr-4 btn" onClick={() => closeModal()}>
          Cancel
        </button>
        <button className="btn btn-primary" onClick={() => handleSave()}>
          Save
        </button>
      </div>
    </div>
  )
}
