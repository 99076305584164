//import {EMAIL_REGEXP, FIRST_NAME_REGEXP, LAST_NAME_REGEXP, PASSWORD_MIN_LEN} from '../../config';

export default function validate (values) {
	const errors = {};
	errors['device'] = {};
	let value  = values['device'];
	if (value){

		if (!value['group_id']) {
			errors['device']['group_id'] = 'This field is required';
		}
	}
	else{
		errors['device'] = {
			group_id: 'This field is required'
		};
	}


	return errors;
};