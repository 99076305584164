export const userConstants = {

    FETCH_USER_DATA: 'FETCH_USER_DATA',
    FETCH_USER_DATA_FULFILLED: 'FETCH_USER_DATA_FULFILLED',

    DELETE_USER: 'DELETE_USER',
    DELETE_USER_FULFILLED: 'DELETE_USER_FULFILLED',

    UPDATE_USER: 'UPDATE_USER',
    UPDATE_USER_FULFILLED: 'UPDATE_USER_FULFILLED',

    CREATE_USER: 'CREATE_USER',
    CREATE_USER_FULFILLED: 'CREATE_USER_FULFILLED',

    GET_MEMBER_GROUPS: 'GET_MEMBER_GROUPS',
    GET_MEMBER_GROUPS_FULFILLED: 'GET_MEMBER_GROUPS_FULFILLED'
};