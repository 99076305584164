/**
 * Constants for the alert form
 * @type {Object}
 */
export const AlertTypes = Object.freeze({
    TEMPERATURE: { name: 'temperature'},
    HUMIDITY: {name: 'humidity'},
    CONNECTION: {name: 'connection'},
    BATTERY: {name: 'battery'},
    GEOFENCE: {name: 'geofence'},
    METRIC: {name: 'metric'}
});