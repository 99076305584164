import React from "react";
import {Field} from 'redux-form';
import {SelectField} from "../../ui/Input/SelectField";
import {ValidationFunction} from "../../utils/validateFunctions";


export default class FormSelectFieldComponent extends React.Component<{
    label: string,
    name: string,
    items: {value: any, label: string}[],
    default: {value: any, label: string} | {},
    multiple: boolean,
    validate: ValidationFunction[],
}, {}> {
    constructor(props) {
        super(props);
    }

    renderField = ({input, label, items, meta: {touched, error}, multiple }) => {
        return <SelectField label={label}
                            placeholder=""
                            error={touched && error}
                            errorLabel={error}
                            value={input.value}
                            {...input}
                            items={items}
                            multiple={multiple}
        />
    }

    render() {
        return (
            <div className="form-group">
                <Field
                    name={this.props.name}
                    component={this.renderField}
                    items={this.props.items}
                    label={this.props.label}
                    multiple={this.props.multiple}
                    validate={this.props.validate}
                />
            </div>
        )
    }
}

