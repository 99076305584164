import React from 'react';
import 'react-table/react-table.css';
import {Link} from 'react-router';

export class GroupListView extends React.Component {

    constructor(props) {
        super(props);
    }


    render() {
        return (
                <ul className="container mb-0">
                    {this.renderListItems()}
                </ul>

        );

    }

    renderListItems() {
        return this.props.items.map((item, idx) => {
            return <GroupListItem index={item.id} key={idx} group={item} onDeleteGroup={this.props.onDeleteGroup}/>
        })
    }
}

class GroupListItem extends React.Component {

    render() {
        return (
            <li key={this.props.index}
                className="row p-3 emp-group-list-item emp_list_group_card">
                <div className="col-6">
                    <Link to={`/group/${this.props.group.id}`}
                          className="">
                      <span
                          className="emp-group-label">{this.props.group.name}</span>
                        <br/>
                        <span
                          className="emp-group-secondary-label">{this.props.group.gateway_count} Gateways • {this.props.group.sensor_count} sensors
                        </span>
                    </Link>
                </div>
                <div className="col-3 ml-auto">
                    <Link to={`/group/${this.props.group.id}`} className="d-inline-block mr-2 mb-2">
                      <span className="group-step-icn">
                        <i className="fa fa-pencil"></i>
                      </span>
                    </Link>
                    <a className="d-inline-block"
                        onClick={(e) => this.props.onDeleteGroup(this.props.group)}>
                        <img
                            src={require("../../styles/images/icon/icn_trash.png")}
                            alt="delete"/>
                    </a>
                </div>
            </li>
        );

    }
}
