import { deviceTypeMetricsConstants } from "../constants/deviceTypeMetrics.constants";
import * as deviceTypeMetricsApi from "../api/deviceTypeMetricsApi";

interface QueryParams {
    deviceTypeId: string;
    expand?: string;
}

export const listDeviceTypeMetrics = ({ deviceTypeId, expand }: QueryParams) => {
    return {
        type: deviceTypeMetricsConstants.LIST_DEVICE_TYPE_METRICS,
        payload: deviceTypeMetricsApi.listDeviceTypeMetrics(deviceTypeId, {
            expand: expand,
        }),
    };
};

export const getDeviceTypeMetric = (deviceTypeId: string, name: string) => ({
    type: deviceTypeMetricsConstants.GET_DEVICE_TYPE_METRIC,
    payload: deviceTypeMetricsApi.getDeviceTypeMetric(deviceTypeId, name),
});

export const createDeviceTypeMetric = (deviceTypeId: string, values: any) => ({
    type: deviceTypeMetricsConstants.CREATE_DEVICE_TYPE_METRIC,
    payload: deviceTypeMetricsApi.createDeviceTypeMetric(deviceTypeId, values),
});

export const updateDeviceTypeMetric = (
    deviceTypeId: string,
    name: string,
    values: any
) => ({
    type: deviceTypeMetricsConstants.UPDATE_DEVICE_TYPE_METRIC,
    payload: deviceTypeMetricsApi.updateDeviceTypeMetric(deviceTypeId, name, values),
});

export const deleteDeviceTypeMetric = (
    deviceTypeId: string,
    name: string
) => ({
    type: deviceTypeMetricsConstants.DELETE_DEVICE_TYPE_METRIC,
    payload: deviceTypeMetricsApi.deleteDeviceTypeMetric(deviceTypeId, name),
});
