import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import { withRouter } from 'react-router';
import {Form, reduxForm} from 'redux-form';
import {InputField} from '../InputField/InputField';
import {SelectField} from '../SelectField/SelectField';
import {FileInputField} from '../FileInputField/FileInputField';
import * as generalSettingAction from '../../actions/generalSettingAction';
import { Link } from 'react-router';
import {RadioBtn} from '../RadioBtn/RadioBtn';
import store from '../../store/store';
import * as $ from 'jquery';
import {imagePath} from '../../utils/imagePath';
import {isAdmin} from '../../utils/utils';
import validate from './generalSettingValidate';

export class GeneralSetting extends React.Component {
    constructor(props){
        super(props);
        this.state={
            alertClass: 'd-none',
            organization: {}
        };
    }
    componentWillReceiveProps (nextProps) {
        let state = store.getState();
        if (nextProps.generalSetting.updated){
            this.setState({alertClass: 'fade in'});
            setTimeout(() => this.setState({alertClass: 'fade up'}) ,2000);
            nextProps.generalSetting.updated = false;
        }
        if (nextProps.generalSetting.organization['id'] && !this.state.organization['id']) {
            state.form.generalSettingForm.initial.organization = nextProps.generalSetting.organization;
            this.setState({organization: nextProps.generalSetting.organization});
        }

    }

    componentDidMount(){
        //Custom();
    }
    render(){
        const { initialValues,handleSubmit,pristine,auth}= this.props;
        const handleFiles = (e) => {
            let name = e.currentTarget.files.length > 0 ? $.trim(e.currentTarget.files[0].name).substring(0, 30).trim(this) + "..." : '';
            $('.organization_logo').html(name);
            if (e.target.files.length > 0) {
                let url = URL.createObjectURL(e.target.files[0]);
                $('#preview_img').attr('src', url);
            }else{
                $('#preview_img').attr('src', require("../../styles/images/icon/logo.png"));
            }
        };

        const setFileName = (obj) => {
            if (obj && obj.logo_image){
                    let name = obj.logo_image.split('/')[obj.logo_image.split('/').length-1];
                    return $.trim(name).substring(0, 30).trim(this) + "..."
                }
                else{
                    return ''
                }
        };
        return(
            <main className="app-content">
                <div className={`login w-25 alert alert-dismissible alert-success ${this.state.alertClass}`}>
                    Settings updated successfully
                    <button className="close" onClick={()=> this.setState({alertClass: 'd-none'})}>x</button>
                </div>
                <section className="emp-sec organization-setting emp-active-device-sec emp_org_settings_sec emp_genral_sec general_Settings_sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-8 offset-xl-1 col-lg-10 offset-lg-1 col-md-12 offset-md-0 col-sm-12 offset-sm-0">
                                <div className="emp-active-title">
                                    <h5>Settings</h5>
                                </div>
                                <div className="emp-active-content">
                                </div>
                                <Form onSubmit={handleSubmit} noValidate={true}>
                                    <div className={`${isAdmin(this.props.user) ? '' : 'pointer-none'} card shadow emp-form-card`}>
                                        <div className="card-body">

                                                <div className="row">
                                                    <div className="col-xl-6">
                                                        <InputField
                                                            type="text"
                                                            name="organization[name]"
                                                            className="form-control input_modifier"
                                                            label="Organization Name"
                                                            placeholder="Empiric Solutions"
                                                            />
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <SelectField
                                                            name="organization[system_of_units]"
                                                            className="custom-select cst-select-box input_modifier shadow_select_hover"
                                                            type="select"
                                                            label="System of Units (for display)"
                                                            options={['metric','imperial']}
                                                            parentClass="form-group"
                                                            />
                                                    </div>
                                                </div>
                                            <div className="clearfix"></div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <InputField
                                                        type="text"
                                                        name="organization[address][address1]"
                                                        className="form-control input_modifier"
                                                        label="Street Address"
                                                        placeholder="Street Address"
                                                    />
                                                </div>
                                            </div>
                                            <div className="clearfix"></div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <InputField
                                                        type="text"
                                                        name="organization[address][city]"
                                                        className="form-control input_modifier"
                                                        label="City"
                                                        placeholder="City"
                                                    />
                                                </div>
                                                <div className="col-4">
                                                    <InputField
                                                        type="text"
                                                        name="organization[address][state]"
                                                        className="form-control input_modifier"
                                                        label="State"
                                                        maxLength="2"
                                                        placeholder="CA"
                                                    />
                                                </div>

                                                <div className="col-4">
                                                    <InputField
                                                        type="text"
                                                        name="organization[address][zip]"
                                                        className="form-control input_modifier"
                                                        label="Zip"
                                                        maxLength="5"
                                                        placeholder="Postal Code"
                                                    />
                                                </div>
                                            </div>
                                                <div className="clearfix"></div>
                                                {/*<div className="row">*/}
                                                {/*    <div className="col-xl-12">*/}
                                                {/*        <div className="form-group">*/}
                                                {/*            <label className="label_modifier">Logo</label>*/}
                                                {/*            <div className="custom-control custom-radio">*/}
                                                {/*                <RadioBtn*/}
                                                {/*                    name="organization[logo_type]"*/}
                                                {/*                    type="radio"*/}
                                                {/*                    value="domain"*/}
                                                {/*                    handleChange={(e) => this.handleChange(e)}*/}
                                                {/*                    label="Use logo from this domain:"*/}
                                                {/*                    labelClass="custom-control-label"*/}
                                                {/*                    id={`option_1`}*/}
                                                {/*                    htmlFor={`option_1`}*/}

                                                {/*                    />*/}
                                                {/*            </div>*/}

                                                {/*        </div>*/}
                                                {/*    </div>*/}
                                                {/*    <div className="col-xl-12">*/}
                                                {/*        <InputField*/}
                                                {/*            type="text"*/}
                                                {/*            name="organization[logo_url]"*/}
                                                {/*            className="form-control transform-inherit input_modifier"*/}
                                                {/*            placeholder="https://"*/}
                                                {/*            />*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className="clearfix"></div>
                                                <div className="row">
                                                    <div className="clearfix"></div>
                                                    <div className="col-xl-12">
                                                        <div className="form-group">
                                                            <div className="card border-0">
                                                                <div className="card-body bg-gray text-center emp_border_radius_6">
                                                                    <div className="emp_setting_logo">
                                                                        <img src={this.props.generalSetting.organization && this.props.generalSetting.organization.logo_image ? imagePath(this.props.generalSetting.organization.logo_image) : require("../../styles/images/icon/logo.png")}
                                                                                  id="preview_img" alt="Logo" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="form-group emp_file_input">

                                                            <FileInputField
                                                                type="file"
                                                                name="organization[logo_image]"
                                                                placeholder="New York, NY, USA"
                                                                //label="Choose Logo "
                                                                HandleChange={(e) =>handleFiles(e)}
                                                                //iconClass="fa fa-info-circle"
                                                                className="custom-file-input form-control input_modifier pointer-none"
                                                                inputGroupLabel={setFileName(this.props.generalSetting.organization)}
                                                                inputGroupLabelClass="custom-file-label emp_cst_file_label organization_logo pointer-none"
                                                                inputGroupClass="input-group mb-3 cst-upload"
                                                                htmlFor="inputGroupFile01"
                                                                HandleClick={(e)=>handleFiles(e)}
                                                                id="inputGroupFile01"
                                                                />

                                                        </div>
                                                    </div>
                                                </div>

                                        </div>
                                    </div>
                                    <div className="row">
                                        {
                                            isAdmin(this.props.user) &&
                                            <div className="col-sm-12 text-right">
                                                <button type="submit" className="btn btn-next btn-primary emp-cst-btn emp-btn mt-3">Save</button>
                                            </div>
                                        }

                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>

                </section>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        generalSetting: state.generalSettingReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    generalSettingAction: bindActionCreators(generalSettingAction, dispatch)
});


export default reduxForm({
    form: 'generalSettingForm',
    validate,
    initialValues: {organization: {}},
    onSubmit: (values, dispatch) => dispatch(generalSettingAction.updateGeneralSetting(values))
})(connect(mapStateToProps, mapDispatchToProps)(GeneralSetting));