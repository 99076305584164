import React from 'react';
import {GoogleMap, Polygon} from "@react-google-maps/api";

const ZOOM_LEVEL = 17;
const MAP_CENTER = { lat: 34.035083, lng: -118.378808 };

const findPolygonCenter = (coordinates) => {
    const yCoordinates = coordinates.map(coord => coord.lng);
    const xCoordinates = coordinates.map(coord => coord.lat);
    const minX = Math.min(...xCoordinates);
    const maxX = Math.max(...xCoordinates);

    const minY = Math.min(...yCoordinates);
    const maxY = Math.max(...yCoordinates);

    const lat = minX + ((maxX - minX)/2);
    const lng = minY + ((maxY - minY)/2);

    return {lat,lng}
}

export class AreaMap extends React.Component<{
    coordinates: [[[number, number]]]
}, {mapCoordinates: {lat: number, lng: number}, map: any}> {
    private polygonRef: any;
    private listenersRef: any;

    constructor(props) {
        super(props);
        this.state = {
            mapCoordinates: MAP_CENTER,
            map: null
        }
    }

    componentDidMount() {
        const coordinates = this.props.coordinates.map(coordinates => coordinates.map(coordinate => ({lat: coordinate[1], lng: coordinate[0]})));
        this.setState({
            mapCoordinates: findPolygonCenter(coordinates[0]),
        });
    }

    renderMap = () => {
        const {mapCoordinates} = this.state;
        const {coordinates} = this.props;
        const onMapLoad = (e) => {
            this.setState({
            map: e});
        };

        return <GoogleMap
            mapContainerClassName="App-map"
            center={mapCoordinates}
            zoom={ZOOM_LEVEL}
            // version="weekly"
            onLoad={onMapLoad}
            clickableIcons={false}
            options={{
                styles: [{
                    elementType: "labels",
                    featureType: "poi.business",
                    stylers: [{visibility: "off",}],
                }],
            }}
        >

            {coordinates && coordinates.length && (
                <Polygon
                    options={{
                        strokeColor:"#d34052",
                        fillColor:"#d34052",
                        strokeOpacity:0.5,
                        strokeWeight:2
                    }}
                    paths={coordinates.map(coordinates => coordinates.map(coordinate => ({lat: coordinate[1], lng: coordinate[0]})))}
                />
            )}


        </GoogleMap>
    }

    render() {
        const {coordinates} = this.props;
        if (!coordinates) {
            return;
        }

        return this.renderMap();
    }
}
