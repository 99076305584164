import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';


export const updateSensor = (deviceValues) => {
    if (deviceValues['device']['id']){
        return axios.put(`${BASE_URL()}/api/v1/sensors/${deviceValues['device']['id']}`,deviceValues, { headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response =>  response.data);
    }
    else{
        return axios.post(`${BASE_URL()}/api/v1/sensors`,deviceValues, { headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response =>  response.data);
    }

};

export const deleteSensor = (values) => {
    return axios.delete(`${BASE_URL()}/api/v1/sensors/${values}`, { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': storage.get('token')
    }}).then(response => {
        return response.data;
    });

};
export const getAllSensors = (data) => {
    return axios.get(`${BASE_URL()}/api/v1/sensors`, {
        params: data,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response => response.data);
};

export const searchSensorData = (data) => {
    return axios.get(`${BASE_URL()}/api/v1/sensors/search_group`, {
        params: data,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response => response.data);
};

export const getSensor = (data) => {
    return axios.get(`${BASE_URL()}/api/v1/sensors/${data}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response => response.data);
};