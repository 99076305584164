import React from 'react';
import {ApiDevice} from '../../../api/model/Device';
import Moment from 'moment';
import MetricGraph, {MetricData} from '../../Visualization/MetricGraph';
import {SystemOfUnits} from "../../../utils/formatHelper";
import {getMetric} from '../../../api/metricApi';

type Props = {
    device: ApiDevice;
    getMetric: typeof getMetric;
};

type State = {
    metricsData: any[];
};

export class MeasurementsTabPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            metricsData: [],
        };
    }

    async componentDidMount() {
        await this.updateMetrics();
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
        if (prevProps.device.identity !== this.props.device.identity) {
            await this.updateMetrics();
        }
    }

    async updateMetrics() {
        const {device} = this.props;
        const end_time = Moment().toISOString()
        const start_time = Moment().subtract(1, 'days').toISOString()

        if (device.device_type && device.device_type.device_type_metrics) {
            const metricsData: any[] = [];
            for (const metric of device.device_type.device_type_metrics) {
                const response = await this.props.getMetric(
                    device.identity,
                    metric.name,
                    start_time,
                    end_time,
                    "5m",
                    null,
                    SystemOfUnits.Metric,
                    null
                );
                console.log("Response")
                const metricData = response.items;
                const timestamps = metricData.map(item => Moment.utc(item.timestamp).toDate());
                let metric_values = metricData
                    .map(item => Number(item.value.toFixed(2)));

                const metrics = [
                    new MetricData(
                        metric.name,
                        timestamps,
                        metric_values
                    )
                ];

                metricsData.push({
                    metrics,
                    metric: metric.name,
                    title: metric.display_name,
                    xPlotBands: [],
                    yPlotLines: []
                });
            }
            this.setState((prevState) => ({
                ...prevState,
                metricsData: metricsData
            }));
        }
    }

    render() {
        const {metricsData} = this.state;

        if (!metricsData) {
            return null;
        }
        
        return (
            <>
                {metricsData.map((data, index) => (
                    <MetricGraph
                        key={index}
                        title={data.title}
                        metrics={data.metrics}
                        xPlotBands={data.xPlotBands}
                        yPlotLines={data.yPlotLines}
                        tickInterval={300 * 1000}
                    />
                ))}
            </>
        );
    }
}