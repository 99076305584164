import React from "react";


export class TextField extends React.Component<{
    label: string,
    name?: string
    type?: string,
    id?: string,
    onChange?: (e) => void,
    onFocus?: (e) => void,
    onDragStart?: (e) => void,
    onBlur?: (e) => void,
    value?: string,
    error?: boolean,
    errorLabel?: string,
    pattern?: string,
    helpText?: string;
}, {}> {
    render() {
        return (
            <>
                <div className={"emp-input-container" + (this.props.error ? ' input_error' : '')}>
                    <input type={this.props.type || "text"} {...this.props} className="emp-type-subtitle1"/>
                    <label className={this.props.value && 'filled'}>
                        {this.props.label}
                    </label>
                </div>
                {this.props.error ? <span className="emp-type-caption emp-color-error">{this.props.errorLabel}</span> :
                    <span className="emp-color-helper-text emp-type-caption">{this.props.helpText}</span>}
            </>
        );
    }
}
