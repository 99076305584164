import React from 'react'
import { FieldGroup } from '../FormFields/Group'
import FieldTypes from '../FormFields'
import { mapToFieldInstances } from '../../../utils/formUtils'

const FieldList = ({ fieldInstances, standardYield, handleFieldUpdate }) => {
  return (
    <>
      {fieldInstances.map((instance, index) => {
        if (instance.field.field_type === 'group') {
          return (
            <FieldGroup
              instance={instance}
              groupInstances={mapToFieldInstances(
                fieldInstances,
                instance.field.children
              )}
              key={index}
              standardYield={standardYield}
              onChange={handleFieldUpdate}
            />
          )
        }
        if (!instance.field.parent_id) {
          return (
            <FieldTypes
              instance={instance}
              key={index}
              onChange={handleFieldUpdate}
            />
          )
        }
        return null
      })}
    </>
  )
}

export default FieldList