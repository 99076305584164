import React from 'react';
import { DrawingManager } from "@react-google-maps/api";

export class DrawingCmp extends React.Component {
    render(){
        const {onLoad, onOverlayComplete, onPolygonComplete} = this.props;
        return (
            <DrawingManager
                onLoad={onLoad}
                drawingMode="polygon"
                onOverlayComplete={onOverlayComplete}
                onPolygonComplete={onPolygonComplete}
                options={{
                    drawingControl: true,
                    onMouseUp: (e)=>console.log(e),
                    mouseup: (e)=>console.log(e),
                    drawingControlOptions: {
                        position: 11,
                        drawingModes: ["polygon"]
                    },
                    polygonOptions: { 
                        editable: true,
                        onMouseUp: (e)=>console.log(e),
                        draggable: true,
                    },
                }}
            />
        );
    }
}
