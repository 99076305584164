import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import * as $ from 'jquery';

const renderField = ( { input: {value: omitValue, ...inputProps }, id, label, placeholder, inputGroupLabelClass,inputGroupLabel, inputGroupClass, type,className,iconClass,htmlFor,multiple, meta: { touched, asyncValidating, error },...props }) => {
    const handleClick = () => {
        $('.emp_cst_file_label').click();
    };
    return (
        <div className="form-group">
            { label !== null && <label className="form-group__label">{label}<i  className={iconClass}></i></label> }
            <div className={`form-group__field ${inputGroupClass} ${asyncValidating ? 'async-validating' : ''}`}>
                <div className="input-group emp_file_input">
                    <div className="custom-file">
                        <input
                            {...inputProps} {...props}
                            placeholder={placeholder}
                            type={type}
                            multiple={multiple}
                            className={className}
                            id={id}
                            accept='.jpg, .png, .jpeg'
                            />
                        <label className={inputGroupLabelClass} htmlFor={htmlFor}>{inputGroupLabel}</label>
                    </div>
                    <div className="input-group-append">
                        <button className="btn btn-primary btn_file_input" onClick={() => handleClick()} type="button">
                            <span htmlFor="inputGroupFile01" className="mr-2 emp_icn_middle">
                                <img src={require("../../styles/images/icon/icn_link.png")} alt="link" />
                            </span>  Choose a file
                        </button>
                    </div>
                </div>
            </div>
            { touched && error && <div className="form-group__error">{error}</div>}
        </div>
    );
};

export const FileInputField = ({ type, name,id, label, placeholder, inputGroupLabelClass, inputGroupClass ,className,iconClass,htmlFor,inputGroupLabel,HandleChange,multiple}) => (
    <Field
        type={type}
        placeholder={placeholder}
        name={name}
        id={id}
        label={label}
        inputGroupLabelClass={inputGroupLabelClass}
        inputGroupClass={inputGroupClass}
        className={className}
        iconClass={iconClass}
        htmlFor={htmlFor}
        multiple={multiple}
        inputGroupLabel={inputGroupLabel}
        onChange={HandleChange}
        component={renderField}
        />
);

FileInputField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    iconClass: PropTypes.string,
    inputGroupLabelClass: PropTypes.string,
    inputGroupLabel: PropTypes.string,
    inputGroupClass: PropTypes.string,
    HandleChange: PropTypes.func,
    htmlFor: PropTypes.string
};

FileInputField.defaultProps = {
    type: 'file',
    id: '',
    label: null,
    multiple: false,
    placeholder: '',
    className: '',
    inputGroupLabelClass: '',
    inputGroupLabel: '',
    inputGroupClass: '',
    htmlFor: ''
};