import React from 'react';

class WifiForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: props.wifi.name || "",
            security_type: props.wifi.security_type || "",
            username: props.wifi.username || "",
            password: props.wifi.password || "",
            errors: {},
            priority: props.wifi.priority || 0,
            identifier: props.wifi.identifier || "",
            databaseId: props.wifi.id
        }
    }

    componentDidUpdate(prevProps) {
        // this check is required so form can be used in modal div with current wifi selection
        if (this.props.wifi !== prevProps.wifi) {
            this.setState({
                name: this.props.wifi.name || "",
                security_type: this.props.wifi.security_type || "",
                username: this.props.wifi.username || "",
                password: this.props.wifi.password || "",
                errors: {},
                priority: this.props.wifi.priority || 0,
                identifier: this.props.wifi.identifier || "",
                databaseId: this.props.wifi.id
            })
        }
    }

    handleChange = e => {
        let errors = { ...this.state.errors }
        delete errors[e.target.name]
        this.setState({ [e.target.name]: e.target.value, errors: errors })
    }

    isWPAEnterprise = () => this.state.security_type === 'wpa_enterprise'

    validateInput = () => {
        let errors = {}
        if (!this.state.name)
            errors.name = 'SSID is required'
        if (!this.state.identifier)
            errors.identifier = 'Name is required'
        if (!this.state.password)
            errors.password = 'Password is required'
        if (!this.state.security_type)
            errors.security_type = 'Network security type is required'
        if (this.state.priority < -99 || this.state.priority > 99)
            errors.priority = 'Priority required needs to be between -99 and +99';
        else if (this.isWPAEnterprise() && !this.state.username)
            errors.username = `User Name is required`
        this.props.networkList.forEach(network => {
            if(!network.identifier) return;
            if(this.state.identifier.toUpperCase() === network.identifier.toUpperCase()){
                if(this.state.databaseId !== network.id)
                    errors.identifier = 'A network with this name already exists, please choose a unique name';
            }
        });
        this.setState({ errors: errors })
        return Object.keys(errors).length === 0
    }

    handleSubmit = (ev) => {
        if (this.validateInput()) {
            let wifi = {
                name: this.state.name,
                security_type: this.state.security_type,
                password: this.state.password,
                priority: this.state.priority,
                identifier: this.state.identifier
            }
            if (this.isWPAEnterprise())
                wifi.username = this.state.username
            this.props.handleSubmit(wifi);
        }
    }

    render() {
        const errors = this.state.errors;
        const { options } = this.props;
        const disabled = !this.isWPAEnterprise()

        return (
            <div >
                <div className="modal-body">
                    <div className="form-group">
                        <label>SSID:</label>
                        <input onChange={this.handleChange}
                            type="text" className="form-control"
                            name="name" placeholder="SSID"
                            value={this.state.name} />
                        {errors.name && <div className="form-group__error">{errors.name}</div>}
                    </div>

                    <div className="form-group">
                        <label>Network Name:</label>
                        <input onChange={this.handleChange}
                            type="text" className="form-control"
                            name="identifier" placeholder="Network name"
                            value={this.state.identifier} />
                        {errors.identifier && <div className="form-group__error">{errors.identifier}</div>}
                    </div>

                    <div className="form-group">
                        <label>Network Security Type:</label>
                        <select className="form-control" name="security_type"
                            onChange={this.handleChange} value={this.state.security_type} >
                            {!this.state.security_type && <option disabled value="">Select Network</option>}
                            {options.map(opt => <option key={opt.value} value={opt.value}>{opt.label}</option>)}
                        </select>
                        {errors.security_type && <div className="form-group__error">{errors.security_type}</div>}
                    </div>

                    <div className="form-group">
                        <label>Username:</label>
                        <input disabled={disabled} onChange={this.handleChange}
                            type="text" className="form-control"
                            value={this.state.username} name="username"
                            placeholder="802.1x user name (for WPA Enterprise)" />
                        {errors.username && <div className="form-group__error">{errors.username}</div>}
                    </div>

                    <div className="form-group">
                        <label>Password:</label>
                        <input onChange={this.handleChange} type="password"
                            className="form-control" value={this.state.password}
                            name="password" placeholder="802.1x password" />
                        {errors.password && <div className="form-group__error">{errors.password}</div>}
                    </div>

                    <div className="form-group">
                        <label>Priority:</label>
                        <input type="number" onChange={this.handleChange} 
                            name="priority" className="form-control" value={this.state.priority}/>
                        {errors.priority && <div className="form-group__error">{errors.priority}</div>}
                    </div>
                </div>
                
                <div className="modal-footer justify-content-between border-0 pt-5">
                    <a href="#" className="create-group text-left text-primary" data-dismiss="modal">
                        <img src={require("../../styles/images/icon/icn_close.png")} alt="close" />
                        <span className="ml-2  cancel_btn">Cancel</span>
                    </a>
                    <button onClick={this.handleSubmit} className="btn btn-next btn-primary emp-cst-btn emp-btn">
                        <span className="mr-2 emp_icn_middle">
                            <img src={require("../../styles/images/icon/ic_checking.png")} alt="check" />
                        </span>
                        Save
                    </button>
                </div>
            </div>
        )
    }
}

export default WifiForm;