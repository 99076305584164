import {getBatchTelemetryData} from "./telemetry";
import * as Moment from 'moment';
import {formatTimestampDateTime, SystemOfUnits} from "../utils/formatHelper";
import {listReports} from "./incidentReportApi";
import {getMetric} from "./metricApi";
import {getSensorGatewayConnectionHistoryData} from "./sensorGatewayConnectionsApi";
import {searchDeviceActiveByType} from "./deviceApi";

function offsetTime(minutes) {
    let timeNow = Moment(Date.now());
    timeNow.subtract(minutes, 'minutes');
    return timeNow;
}

export const downloadBatchData = async (values) => {
    switch (values['schema']) {
        case 'sensor':
            return downloadBatchDeviceData(values)
        case 'gatewayAssociation':
            return downloadGatewayAssociationData(values);
        case 'incident':
            return fetchIncidentData(values);
        case 'metric':
            return fetchMetricData(values);
        case 'sensorAssociation':
            return fetchFakeSensorAssociationData(values)
        default:
            return downloadBatchDeviceData(values)
    }
}
export const downloadGatewayAssociationData = async (values) => {
    let items = [{
        'timestamp': formatTimestampDateTime(Date.now()),
        'sensor': 'PKG#453112',
        'gateway': 'Austin TX Distribution Center',
    }, {
        'timestamp': formatTimestampDateTime(offsetTime(300)),
        'sensor': 'PKG#453112',
        'gateway': 'Dallas TX Distribution Center',
    }, {
        'timestamp': formatTimestampDateTime(offsetTime(2000)),
        'sensor': 'PKG#453112',
        'gateway': 'Tempe AZ Distribution Center',
    }, {
        'timestamp': formatTimestampDateTime(offsetTime(5000)),
        'sensor': 'PKG#723413',
        'gateway': 'San Francisco CA Distribution Center',
    }, {
        'timestamp': formatTimestampDateTime(offsetTime(9000)),
        'sensor': 'PKG#723413',
        'gateway': 'Barstow CA Distribution Center',
    }, {
        'timestamp': formatTimestampDateTime(offsetTime(15000)),
        'sensor': 'PKG#723413',
        'gateway': 'Los Angeles CA Port Intake',
    }
    ]

    return {
        items: items
    }
}


export const downloadBatchDeviceData = async (values) => {
    let start_time;
    let end_time;

    if (values && values.start_date) {
        start_time = Moment(values.start_date, 'MM-DD-YYYY hh:mm A').toISOString();
        end_time = Moment(values.end_date, 'MM-DD-YYYY hh::mm A').toISOString();
    } else {
        start_time = new Date((new Date()).valueOf() - 1000 * 3600 * 24).toISOString();
        end_time = new Date().toISOString();
    }

    let request = {
        'start_time': start_time,
        'end_time': end_time,
        'unit': values.period,
        'format': values.format || SystemOfUnits.Metric,
        'max_items': 5000
    };

    let results = await getBatchTelemetryData(request);

    return {
        items: results.items,
    }

};

export const fetchIncidentData = async (values) => {
    let start_time;
    let end_time;

    if (values && values.start_date) {
        start_time = Moment(values.start_date, 'MM-DD-YYYY hh:mm A').toISOString();
        end_time = Moment(values.end_date, 'MM-DD-YYYY hh::mm A').toISOString();
    } else {
        start_time = new Date((new Date()).valueOf() - 1000 * 3600 * 24).toISOString();
        end_time = new Date().toISOString();
    }

    const response = await listReports(start_time, end_time);

    return {
        items: response,
    }
}

export const fetchMetricData = async (values) => {
    let start_time;
    let end_time;

    if (values && values.start_date) {
        start_time = Moment(values.start_date, 'MM-DD-YYYY hh:mm A').toISOString();
        end_time = Moment(values.end_date, 'MM-DD-YYYY hh::mm A').toISOString();
    } else {
        start_time = new Date((new Date()).valueOf() - 1000 * 3600 * 24).toISOString();
        end_time = new Date().toISOString();
    }

    let items = [];
    let result = await getMetric(null, null, start_time, end_time, values.period, null, values.format || SystemOfUnits.Metric, null);
    items = items.concat(result.items);
    if (result.nextCursor) {
        do {
            result = await getMetric(null, null, start_time, end_time, values.period, null, values.format || SystemOfUnits.Metric, result.nextCursor);
            items = items.concat(result.items);
        } while (result.nextCursor);
    }

    return {
        items: items
    }

}


export const fetchFakeSensorAssociationData = async (values) => {
    return {
        items: [
            {
                timestamp: new Date().toISOString(),
                sensorId: "timer#2139a",
                gatewayName: "RoomAGateway",
                status: "Connected",
                lastConnected: formatTimestampDateTime(new Date().toISOString())
            },
            {
                timestamp: new Date().toISOString(),
                sensorId: "timer#3217b",
                gatewayName: "RoomBGateway",
                status: "Connected",
                lastConnected: formatTimestampDateTime(new Date().toISOString())
            },
            {
                timestamp: new Date().toISOString(),
                sensorId: "bufferSolution#2139a",
                gatewayName: "RoomAGateway",
                status: "Connected",
                lastConnected: formatTimestampDateTime(new Date().toISOString())
            },
            {
                timestamp: new Date().toISOString(),
                sensorId: "timer#2138a",
                gatewayName: "RoomAGateway",
                status: "Disconnected",
                lastConnected: formatTimestampDateTime(new Date().toISOString())
            },
            {
                timestamp: new Date().toISOString(),
                sensorId: "Pipette#10",
                gatewayName: "RoomBGateway",
                status: "Connected",
                lastConnected: formatTimestampDateTime(new Date().toISOString())
            },
            {
                timestamp: new Date().toISOString(),
                sensorId: "Pipette#8",
                gatewayName: "RoomAGateway",
                status: "Connected",
                lastConnected: formatTimestampDateTime(new Date().toISOString())
            },
            {
                timestamp: new Date().toISOString(),
                sensorId: "Timer#8",
                gatewayName: "RoomAGateway",
                status: "Connected",
                lastConnected: formatTimestampDateTime(new Date().toISOString())
            },

        ]
    }
}
export const fetchSensorAssociationData = async (values) => {
    const sensors = await searchDeviceActiveByType({name: '', device_type: "sensor"});

    const sensorIds = sensors.map(sensor => sensor.identity);
    const requests = sensorIds.map(sensorId => {
        return getSensorGatewayConnectionHistoryData({
            sensorId: sensorId
        });
    });

    const results = await Promise.all(requests)
    const items = results.reduce((acc, connectionHistory) => {
        acc = acc.concat(connectionHistory.history);
        return acc;
    }, []);

    return {
        items: items.map(item => ({
            timestamp: item.connectionStartTime,
            sensorId: item.sensorId,
            gatewayName: item.gatewayName
        }))
    }
}