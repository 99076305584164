import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import {AssetType} from "./model/AssetType";
import {buildAxiosErrorHandlerConfig} from "../utils/utils";


export const convertApiResponseToAssetType = (data) => {
    return new AssetType(
        data.id,
        data.organization_id,
        data.name,
        data.description,
        data.asset_category,
        data.icon_url ? data.icon_url.url : null,
        data.image_url ? data.image_url.url : null,
        data.created_by_id,
        data.updated_by_id,
        new Date(data.created_at),
        new Date(data.updated_at)
    );
}

const convertAssetTypeFormToApiRequest = (values) => {
    return {
        id: values.id,
        name: values.name,
        description: values.description,
        asset_category: values.assetCategory,
        ...(values.icon && values.icon.length ? {icon_url: values.icon[0]} : {}),
        ...(values.image && values.image.length ? {image_url: values.image[0]} : {}),
    };
}


export const listAssetTypes = (data) => {
    return axios.get(`${BASE_URL()}/api/v1/asset_types`, {
        params: data,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data.items.map(item => convertApiResponseToAssetType(item)));
};

export const getAssetType = (id) => {
    return axios.get(`${BASE_URL()}/api/v1/asset_types/${id}`, buildAxiosErrorHandlerConfig({
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    })).then(response => convertApiResponseToAssetType(response.data));
};

export const createAssetType = (data) => {
    const formData = new FormData();
    const request = convertAssetTypeFormToApiRequest(data);

    Object.keys(request).forEach(key => {
        formData.append(`asset_type[${key}]`, request[key]);
    });
    return axios.post(`${BASE_URL()}/api/v1/asset_types`, formData, buildAxiosErrorHandlerConfig({
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    })).then(response => convertApiResponseToAssetType(response.data));
};

export const updateAssetType = (id, data) => {
    const formData = new FormData();
    const request = convertAssetTypeFormToApiRequest(data);

    Object.keys(request).forEach(key => {
        formData.append(`asset_type[${key}]`, request[key]);
    });

    return axios.put(`${BASE_URL()}/api/v1/asset_types/${id}`, formData, buildAxiosErrorHandlerConfig({
        headers: {
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    })).then(response => convertApiResponseToAssetType(response.data));
};

export const deleteAssetType = (id) => {
    return axios.delete(`${BASE_URL()}/api/v1/asset_types/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    })
        .then(response => response.data);
};
