import React, {Component} from 'react';
import $ from "jquery";

function Checkbox ({label, value, isChecked, handleCheckboxChange }) {
    return (
      <div className="checkbox">
        <label>
          <input
            type="checkbox"
            value={value}
            checked={isChecked}
            onChange={ (event) => handleCheckboxChange(event.target.value)}
            style={{"vertical-align": 'middle'}}
          />
            <span className="ml-1" style={{"vertical-align": "middle"}}>{label}</span>
        </label>
      </div>
    );
}

class ReportColumnSelector extends Component {

    constructor(props) {
        super(props)
        this.handleFormSubmit = this.handleFormSubmit.bind(this)
        this.toggleCheckbox = this.toggleCheckbox.bind(this)
    }

    handleFormSubmit(event){
        event.preventDefault();
        $("#select_columns").modal("hide");
    }

    toggleCheckbox = (index) => {
        this.props.onColumnSelected(Number(index))
    }


    render() {
        const { columns } = this.props;
        return (
            <div className="modal fade" id="select_columns" tabIndex="-1" role="dialog"
            aria-labelledby="select_columns" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <form onSubmit={this.handleFormSubmit}>
                        <div className="modal-header">
                            <h5 className="modal-title">Select Report Columns</h5>
                            <button type="button" className="close_sensor_modal close emp_opacity_1" data-dismiss="modal" aria-label="Close">
                                <span className="mr-2 emp_icn_middle">
                                    <img src={require("../../styles/images/icon/icn_close.png")} alt="Icon"/>
                                </span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className='emp-2columns'>
                                {columns.map ( (c,index) => 
                                    <Checkbox
                                        label={c.columnName} key={c.columnName} value={index}
                                        handleCheckboxChange={this.toggleCheckbox}
                                        isChecked={c.show}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="modal-footer justify-content-between">
                            <a href="#" className="create-group text-left text-primary" data-dismiss="modal">
                                <span className="mr-2 emp_icn_middle">
                                    <img src={require("../../styles/images/icon/icn_close.png")} alt="Icon"/>
                                </span>
                                <span className="ml-2 cancel_btn">Cancel</span>
                            </a>
                            <button type="submit" className="btn btn-next btn-primary emp-cst-btn emp-btn">
                                <span className="mr-2 emp_icn_middle">
                                    <img src={require("../../styles/images/icon/ic_checking.png")} alt="Icon"/>
                                </span>
                                Update
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        )
    }
}

export default ReportColumnSelector;
