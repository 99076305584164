import {areaTypeConstants} from '../constants/areaType.constants';
import * as areaTypeApi from "../api/areaTypeApi";

export const listAreaTypes = (data)=> ({
    type: areaTypeConstants.LIST_AREA_TYPES,
    payload: areaTypeApi.listAreaTypes(data)
});

export const getAreaType = (values)=> ({
    type: areaTypeConstants.GET_AREA_TYPE,
    payload: areaTypeApi.getAreaType(values)
});

export const createAreaType = (values)=> ({
    type: areaTypeConstants.CREATE_AREA_TYPE,
    payload: areaTypeApi.createAreaType(values)
});

export const updateAreaType = (id,values)=> ({
    type: areaTypeConstants.UPDATE_AREA_TYPE,
    payload: areaTypeApi.updateAreaType(id,values)
});

export const deleteAreaType = (id)=> ({
    type: areaTypeConstants.DELETE_AREA_TYPE,
    payload: areaTypeApi.deleteAreaType(id)
});

export const resetAreaType = () => ({
    type: areaTypeConstants.RESET_AREA_TYPE,
    payload: async () => {}
})