import {deviceTypeConstants} from '../constants/deviceTypes.constants';
import DeviceType from "../api/model/DeviceType";

interface DeviceTypesState {
    deviceType: DeviceType;
    deviceTypes: DeviceType[];
}


const initialState: DeviceTypesState = {
    deviceType: {} as DeviceType,
    deviceTypes: [],
};

export const deviceTypesReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case deviceTypeConstants.GET_DEVICE_TYPE_FULFILLED:
            state = {
                ...state,
                deviceType: action.payload,
            };
            return state;

        case deviceTypeConstants.LIST_DEVICE_TYPES_FULFILLED:
            state = {
                ...state,
                deviceTypes: action.payload,
            };
            return state;

        case deviceTypeConstants.CREATE_DEVICE_TYPE_FULFILLED:
            state = {
                ...state,
                deviceTypes: [...state.deviceTypes, action.payload],
            };
            return state;

        case deviceTypeConstants.DELETE_DEVICE_TYPE_FULFILLED:
            state = {
                ...state,
                deviceTypes: state.deviceTypes.filter(
                    (deviceType: any) => deviceType.id !== action.payload,
                ),
            };
            return state;

        case deviceTypeConstants.UPDATE_DEVICE_TYPE_FULFILLED:
            state = {
                ...state,
                deviceType: action.payload,
                deviceTypes: state.deviceTypes.map((deviceType: DeviceType) =>
                    deviceType.id === action.payload.id ? action.payload : deviceType,
                ),
            };
            return state;

        case deviceTypeConstants.RESET_DEVICE_TYPE_FULFILLED:
            state = {
                ...state,
                deviceType: {} as DeviceType,
            };
            return state;

        default:
            return state;
    }
};
