import React from 'react';
import {connect} from 'react-redux';
import {TextField} from "../../ui/Input/TextField";
import {SelectField} from "../../ui/Input/SelectField";
import {ScheduledJobListItem} from "./ScheduledJobListItem";
import {ScheduledJobStatus} from "../../api/model/ScheduledJob";


export class ScheduledJobPage extends React.Component<{
    workflowScheduleReducer,
    workflowReducer,
    scheduledJobReducer,
    loadMoreJobs: Function
}, {
    workflowTypeById: Record<string, string>,
    searchTerm: string,
    scheduleByTitle: Record<string, string>,
    workflowTypeByScheduleTitle: Record<string, string>,
    workflowTypeFilter: string,
    stateFilter: string,
    workflowTypes: string[]
}> {
    state;
    lastElementRef: React.RefObject<HTMLDivElement>;
    observer: IntersectionObserver | null;

    constructor(props) {
        super(props);
        this.state = {
            workflowTypeById: {},
            scheduleByTitle: {},
            workflowTypeByScheduleTitle: {},
            searchTerm: '',
            workflowTypeFilter: '',
            stateFilter: '',
            workflowTypes: []
        }
        this.lastElementRef = React.createRef();
        this.observer = null;
    }

    componentWillUnmount() {
        if(this.observer){
            this.observer.disconnect();
        }
    }

    setupObserver() {
        const options: any = {
            root: null,
            rootMargin: "150px",
            threshold: 1.0
        };

        this.observer = new IntersectionObserver(this.handleObserver.bind(this), options);
        if (this.lastElementRef.current) {
            this.observer.observe(this.lastElementRef.current);
        }
    }

    handleObserver(entries) {
        const target = entries[0];
        if (target.isIntersecting) {
            // Fetch more scheduledJobs here
            this.props.loadMoreJobs();
        }
    }

    componentDidUpdate() {
        if (this.lastElementRef.current && this.observer) {
            this.observer.disconnect();
            this.observer.observe(this.lastElementRef.current);
        }
    }

    componentDidMount() {
        const scheduleByTitle = {};
        const schedules = this.props.workflowScheduleReducer.workflowSchedules;
        schedules.forEach((schedule) => {
            scheduleByTitle[schedule.id] = schedule.title;
        });

        const workflows = this.props.workflowReducer.workflows;
        const workflowTypeById = {};
        workflows.forEach((workflow) => {
            workflowTypeById[workflow.id] = workflow.workflow_type;
        });

        const workflowTypes: string[] = Object.values(workflowTypeById);

        const workflowTypeByScheduleTitle = {};
        schedules.forEach((schedule) => {
            workflowTypeByScheduleTitle[schedule.id] = workflowTypeById[schedule.workflow_id];
        });

        this.setState({
            ...this.state,
            scheduleByTitle: scheduleByTitle,
            workflowTypeById: workflowTypeById,
            workflowTypeByScheduleTitle: workflowTypeByScheduleTitle,
            workflowTypes: workflowTypes
        });

        this.setupObserver();
    }

    renderScheduledJobs() {
        const scheduledJobs = this.props.scheduledJobReducer.scheduledJobs;

        const filteredByState = scheduledJobs.filter(scheduled_job =>
            scheduled_job.status.toLowerCase().includes(this.state.stateFilter.toLowerCase())
        );

        const filteredByWorkflowType = filteredByState.filter(scheduled_job => {
            //not sure if we need this, there might be orphaned schedules or jobs?
            if (!scheduled_job.schedule_id || !this.state.workflowTypeByScheduleTitle[scheduled_job.schedule_id]) {
                return false;
            }
            return this.state.workflowTypeByScheduleTitle[scheduled_job.schedule_id].includes(this.state.workflowTypeFilter);
        });

        const filteredByTitle = filteredByWorkflowType.filter(scheduled_job => {
                const title = this.state.scheduleByTitle[scheduled_job.schedule_id]
                //not sure if we need this, there might be orphaned schedules or jobs?
                if (!title) {
                    return false;
                }
                return title.toLowerCase().includes(this.state.searchTerm.toLowerCase())
            }
        );

        return filteredByTitle.map((scheduledJob, idx) => {
            const title = this.state.scheduleByTitle[scheduledJob.schedule_id];
            const workflowType = this.state.workflowTypeByScheduleTitle[scheduledJob.schedule_id];
            const item = (<ScheduledJobListItem
                    scheduledJob={scheduledJob}
                    key={idx}
                    title={title}
                    workflowType={workflowType}
                />
            )

            if (idx === filteredByTitle.length - 1){
                return <div ref={this.lastElementRef} key={idx}>{item}</div>
            } else {
                return item;
            }
        });
    }


    render() {
        const workflowTypeItems = [{value: '', label: 'All'},
            ...this.state.workflowTypes.map((workflowType) => ({value: workflowType, label: workflowType}))];

        const stateItems = [{value: '', label: 'All'},
            ...Object.entries(ScheduledJobStatus).map(([value, label]) => ({value, label}))];

        return (
            <main className="app-content">
                <div className="app-title border-bottom">
                    <div>
                        <h1>Scheduled Jobs</h1>
                    </div>
                </div>
                <section className="emp_org_settings_sec emp_report_sec">
                    <div className="row">
                        <div className="col-12">
                            <div className="emp-device-card">
                                <TextField
                                    label={"Search by Schedule Title"}
                                    value={this.state.searchTerm}
                                    onChange={(e) => this.setState({...this.state, searchTerm: e.target.value})}/>
                            </div>
                        </div>
                    </div>
                    <div className="row emp-device-card d-flex">
                        <div className="col-6 pl-0">
                            <SelectField
                                label={"Filter by State"}
                                items={stateItems}
                                value={this.state.stateFilter}
                                onChange={(e) => this.setState({...this.state, stateFilter: e.target.value})}/>
                        </div>
                        <div className="col-6 pr-0">
                            <SelectField
                                label={"Filter by Workflow Type"}
                                value={this.state.workflowTypeFilter}
                                items={workflowTypeItems}
                                onChange={(e) => this.setState({...this.state, workflowTypeFilter: e.target.value})}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            {this.renderScheduledJobs()}
                        </div>
                        {this.props.scheduledJobReducer.cursor ? (
                            <div className="col-12 text-align-center">
                                ...Loading
                            </div>
                        ): null}
                    </div>
                </section>
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        workflowScheduleReducer: state.workflowScheduleReducer,
        workflowReducer: state.workflowReducer,
        scheduledJobReducer: state.scheduledJobReducer
    });
};

export default connect(mapStateToProps, {})(ScheduledJobPage);
