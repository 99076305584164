import {ApiDevice, Device} from "./Device";
import {Asset} from "./Asset";

export class DeviceAssignment {
    id: string;
    areaId: string;
    assetId: string;
    deviceId: string;
    status: string;
    activeDate: string;
    releasedDate: string;
    createdById: string;
    updatedById: string;
    createdAt: string;
    updatedAt: string;
    device?: Device;
    asset?: Asset;

    constructor(
        id: string,
        areaId: string,
        assetId: string,
        deviceId: string,
        status: string,
        activeDate: string,
        releasedDate: string,
        createdById: string,
        updatedById: string,
        createdAt: string,
        updatedAt: string,
        device?: Device,
    asset?: Asset
) {
        this.id = id;
        this.areaId = areaId;
        this.assetId = assetId;
        this.deviceId = deviceId;
        this.status = status;
        this.activeDate = activeDate;
        this.releasedDate = releasedDate;
        this.createdById = createdById;
        this.updatedById = updatedById;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;

        if (device) {
            this.device = new Device(
                device["id"],
                device["name"],
                device["mac_address"],
                device["serial_number"],
                device["device_type"],
                device["status"],
                device["organization_id"],
                device["created_at"],
                device["updated_at"],
                device["product_id"],
                device["gateway_id"],
                device["note"],
                device["thing_identity"],
                device["temperature_offset"],
                device["identity"],
                device["hardware_version"],
                device['image_url']
            )
        }

        if (asset) {
            this.asset = new Asset(
                asset["id"],
                asset["name"],
                asset['description'],
                asset['asset_type_id'],
                asset['parent_id'],
                asset['created_by_id'],
                asset['updated_by_id'],
                new Date(asset['created_at']),
                new Date(asset['updated_at']),
                asset['image_url'] ? asset['image_url']['url'] : null,
                asset['icon_url'] ? asset['icon_url']['url'] : null,
                null
            )
        }
    }
}