import React from "react";
import FormCheckBoxComponent from "./FormCheckBoxComponent";
import FormDateTimeComponent from "./FormDateTimeComponent";
import FormInputComponent from "./FormInputComponent";
import FormInvisibleInputComponent from "./FormInvisibleInputComponent";
import FormSelectFieldComponent from "./FormSelectFieldComponent";
import ObjectSetDropdown from "./ObjectSetDropdown";
import FormArrayComponent from "./FormArrayComponent";
import {WorkflowParamsInterface, UiSchemaInterface} from "../../api/model/Workflow";
import {
    required,
    isEmailValid,
    ValidationFunction,
    validateDateTimeFormat,
    validateDateFormat
} from "../../utils/validateFunctions";
import {getFormArrayInputComponent} from "./getFormArrayInputComponent";


export class WorkflowParamsFieldFactory {
    private readonly uiSchema: UiSchemaInterface;
    private readonly params: WorkflowParamsInterface;

    constructor(uiSchema: UiSchemaInterface, params: WorkflowParamsInterface) {
        this.uiSchema = uiSchema;
        this.params = params;
    }

    public createComponents(uiSchema: UiSchemaInterface, params: WorkflowParamsInterface): any[] {
        const components: React.ReactElement[] = [];
        const order = uiSchema['ui:order'];

        for (const key of order) {
            const paramValues = params.properties[key];
            const uiValues = uiSchema.properties[key];
            if (!params) {
                //there might be a case when we have a parameter in uiSchema but not in params
                continue;
            }
            const widget = uiValues['ui:widget'];
            const validationFunctions: ValidationFunction[] = params.required.includes(key) ? [required] : [];
            switch (true) {
                case widget === 'input':
                    const type: string = uiValues.type === "integer" ? "number" : uiValues.type;
                    const isHidden = uiValues.hasOwnProperty("ui:visible") && !uiValues["ui:visible"];
                    if (isHidden) {
                        components.push(<FormInvisibleInputComponent
                            name={`workflow_params.${key}`}
                            type={type}
                            value={uiValues["ui:default"]}
                            key={key}/>)
                    } else {
                        const multiple: boolean = uiValues.multiple ? uiValues.multiple : false;

                        if (type === "email") {
                            validationFunctions.push(isEmailValid);
                        }

                        components.push(<FormInputComponent
                            label={paramValues.title}
                            name={`workflow_params.${key}`}
                            type={type}
                            key={key}
                            multiple={multiple}
                            helpText={paramValues.help_text}
                            validate={validationFunctions}/>)
                    }
                    break;
                case widget.startsWith('objectset'):
                    const objectSet = widget.split(':')[1];
                    const isMulti: boolean = uiValues.multiple ? uiValues.multiple : false;
                    components.push(<ObjectSetDropdown
                        key={key}
                        name={`workflow_params.${key}`}
                        label={paramValues.title}
                        isMulti={isMulti}
                        objectSet={objectSet}
                        validate={validationFunctions}/>);
                    break;
                case widget === 'select':
                    components.push(<FormSelectFieldComponent
                        key={key}
                        name={`workflow_params.${key}`}
                        label={paramValues.title}
                        items={uiValues["ui:enum"]}
                        default={uiValues["ui:default"] || {}}
                        multiple={paramValues.multiple}
                        validate={validationFunctions}
                    />);
                    break;
                case widget === 'checkbox':
                    components.push(<FormCheckBoxComponent
                        key={key}
                        name={`workflow_params.${key}`}
                        items={uiValues["ui:enum"]}
                        default={uiValues["ui:default"] || []}
                        helpText={paramValues.help_text}
                        validate={validationFunctions}
                        label={paramValues.title}
                    />);
                    break;
                case widget === 'date_time':
                    components.push(<FormDateTimeComponent
                        key={key}
                        name={`workflow_params.${key}`}
                        label={paramValues.title}
                        validate={[...validationFunctions, validateDateTimeFormat]}
                    />);
                    break;
                case widget.startsWith('Array'):
                    const arrayType = widget.split(':')[1];
                    const inputComponent = getFormArrayInputComponent(arrayType);
                    if (arrayType === 'DateTime') {
                        validationFunctions.push(validateDateTimeFormat);
                    } else if (arrayType === 'Date') {
                        const dateFormat = "YYYY-MM-DD";
                        validationFunctions.push((value) => validateDateFormat(value, dateFormat));
                    }
                    components.push(<FormArrayComponent
                        key={key}
                        name={`workflow_params.${key}`}
                        label={paramValues.title}
                        validate={validationFunctions}
                        inputComponent={inputComponent}
                    />);
                    break;
                default:
                    throw new Error(`No matching component found for ${key}`);
            }
        }
        return components;
    }
}
