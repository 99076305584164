import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {reset} from 'redux-form';
import * as UserActions from '../../actions/userAction';
import store from '../../store/store';
import * as $ from 'jquery';
import swal from 'sweetalert';
import UserProfileForm from './UserProfileForm';
import {phoneToE164Format, parseE164PhoneNumber} from '../../utils/phoneHelpers'

export class UserSetting extends React.Component<{
    user: any,
    userReducer: any,
    userActions: any
    reset: any
}, {
    title: string,
    alertClass: string,
    successMessage: string,
    userList: any[],
    currentUser: any,
    userDispatchAction: any
}> {
    defaultValues = {id: '', first_name: '', last_name: '', phone: null, country: '+1', email: '', role: 'user'};

    constructor(props) {
        super(props);
        this.state = {
            title: "Add User",
            alertClass: 'd-none',
            successMessage: '',
            userList: [],
            currentUser: this.defaultValues,
            userDispatchAction: UserActions.createUser
        }
        this.setCurrentUser = this.setCurrentUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.onUserFormSubmit = this.onUserFormSubmit.bind(this);
        this.onUserFormCancel = this.onUserFormCancel.bind(this);
    }

    onUserFormSubmit(values, dispatch) {
        if (values['phone'] && values['country']) {
            let work = phoneToE164Format(values['country'], values['phone'])
            values['phone'] = work;
        } else {
            values['phone'] = null;
        }
        delete values['country'];
        // ToDo: error handling logic testing. Mayb async await?
        dispatch(this.state.userDispatchAction(
            this.state.currentUser['id'],
            values));
        $("#add_user").modal("hide");
        dispatch(this.props.reset('userForm'));
    }

    onUserFormCancel() {
        $("#add_user").modal("hide");
    }

    setCurrentUser(user) {
        if (user['id']) {
            // from db, phone is in e164 format.  For user interface, split into country+number
            let userClone = JSON.parse(JSON.stringify(user));
            if (userClone.phone && userClone.country) {
                let work = parseE164PhoneNumber(userClone.phone);
                userClone.country = work.country;
                userClone.phone = work.phone;
            }

            this.setState({
                title: 'Edit User',
                currentUser: userClone,
                successMessage: 'User updated successfully',
                userDispatchAction: UserActions.updateUser
            }, () => $("#add_user").modal("show"));
        } else {
            this.setState({
                title: 'Add User',
                currentUser: this.defaultValues,
                successMessage: 'User created successfully',
                userDispatchAction: UserActions.createUser
            }, () => $("#add_user").modal("show"))
        }
    }

    deleteUser(id) {
        swal({
            title: "Are you sure?",
            text: "You will not be able to recover this User!",
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    className: "btn btn-default class4"
                },
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "btn btn-primary ",
                    closeModal: true
                }
            }
        }).then(function (isConfirm) {
            if (isConfirm) {
                store.dispatch(UserActions.deleteUser(id));
            }
        });
    }

    render() {
        return (
            <main className="app-content">
                <div className={`login w-25 alert alert-dismissible alert-success ${this.state.alertClass}`}>
                    {this.state.successMessage}
                    <button className="close" onClick={() => this.setState({alertClass: 'd-none'})}>x</button>
                </div>

                <section className="emp_org_settings_sec emp_report_sec settings_users_sec">
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card shadow emp-form-card mb-3 emp_create_report_card border-0">
                                <div className="card-body emp_create_report_body pb-0">
                                    <div className="active-device-btn text-right">
                                        {
                                            this.props.user && this.props.user.role !== "user" &&
                                            <a className="create-group" data-toggle="modal"
                                               onClick={() => this.setCurrentUser({})}>
                                                <img src={require("../../styles/images/icon/ic_add.png")} alt="ic_add"/>
                                                <span>Add a User</span>
                                            </a>
                                        }
                                    </div>
                                    <div className="table-wrapper mt-3">
                                        <div
                                            className="table-responsive-lg table-responsive-md table-responsive-sm table-responsive1 text-nowrap">
                                            {this.renderUserTable()}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="modal fade" id="add_user" tabIndex={-1} role="dialog"
                                 aria-labelledby="add_user" aria-hidden="true">
                                <div className="modal-dialog modal-dialog-centered" role="document">
                                    <div className="modal-content">
                                        <UserProfileForm
                                            title={this.state.title}
                                            initialValues={this.state.currentUser}
                                            onFormSubmit={this.onUserFormSubmit}
                                            onFormCancel={this.onUserFormCancel}/>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </section>
            </main>
        );
    }

    private renderUserTable() {

        const truncate = (user) => {
            let name = user.first_name + ' ' + user.last_name;
            return name.length > 20 ? $.trim(name).substring(0, 30).trim(this) + "..." : name;
        };
        const sortByName = (a, b) => {
            if (a.first_name.toUpperCase() < b.first_name.toUpperCase()) return -1;
            if (a.first_name.toUpperCase() > b.first_name.toUpperCase()) return 1;
            return 0;
        }
        return <table className="table table_history mb-0">
            <thead>
            <tr className="border-top border-bottom">
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th scope="col">Role</th>
                {
                    this.props.user && this.props.user.role != "user" &&
                    <th scope="col">Actions</th>
                }
            </tr>
            </thead>
            <tbody>
            {
                this.props.userReducer.userList.sort(sortByName).map((user, index) => {
                    return (
                        <tr key={index}>
                            <td>{truncate(user)}</td>
                            <td>{user.email}</td>
                            <td>{user.role}</td>
                            {
                                this.props.user && this.props.user.role != "user" &&
                                <td>
                                    <a href="#" data-toggle="modal"
                                       onClick={() => this.setCurrentUser(user)}><img
                                        className="mr-2"
                                        src={require("../../styles/images/icon/pencil_icn.png")}
                                        alt="pencil"/></a>
                                    <a href="#"
                                       onClick={() => this.deleteUser(user.id)}><img
                                        src={require("../../styles/images/icon/icn_trash.png")}
                                        alt="icn"/></a>
                                </td>
                            }
                        </tr>
                    )
                })
            }

            </tbody>
        </table>;
    }
}

const mapStateToProps = (state) => {
    return ({
        device: state.deviceReducer,
        userReducer: state.userReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    userActions: bindActionCreators(UserActions, dispatch),
    reset: reset
});


export default connect(mapStateToProps, mapDispatchToProps)(UserSetting);
