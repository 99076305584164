import axios, {AxiosResponse, AxiosRequestConfig} from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import {DatasetDbModel} from "./model/DatasetDb";
import {Table} from "./model/DatasetTable";

export class DatasetTablesApi {
    constructor() {
    }

    async getDatasetTable(databaseName: string, tableName: string): Promise<Table> {
        const config: {
            headers: { Authorization: any | string | undefined; Accept: string; "Content-Type": string }
        } = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': storage.get('token')
            },
        };

        try {
            const response: AxiosResponse = await axios.get(`${BASE_URL()}/api/v1/databases/${databaseName}/tables/${tableName}`, config);
            return Table.from_json(response.data.item);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async queryDatasetTables(databaseName: string, limit: number = 500, cursor: string | null = null): Promise<Table[]> {
        const config: {
            headers: { Authorization: any | string | undefined; Accept: string; "Content-Type": string };
            params: { limit: number }
        } = {
            params: {
                limit: limit,
            },
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': storage.get('token')
            },
        };

        try {
            const response: AxiosResponse = await axios.get(`${BASE_URL()}/api/v1/databases/${databaseName}/tables`, config);
            return response.data['tables'].map((item: any) => Table.from_json(item));
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}
