import React from 'react';
import {Link} from 'react-router';

import {formatTimestampDateTime, SystemOfUnits} from '../../utils/formatHelper';

export class DashboardDeviceCard extends React.Component<
    { units: string, device: { device: any, mac_address: string, telemetry: any[] } }, {}> {

    getTemperatureString(value) {
        if (this.props.units === SystemOfUnits.Imperial) {
            return `${parseInt(value)}°F`;
        } else {
            return `${parseInt(value)}°C`;
        }
    }

    renderTemperatures(telemetry) {
        let probeTemp = null;
        let ambientTemp = null;
        if (telemetry.length > 0) {
            probeTemp = telemetry[0]['probe_temperature'] || null;
            ambientTemp = telemetry[0]['ambient_temperature'] || null;
        }
        let probeTempJSX = (probeTemp) ?
            <span className="emp-device-card-color2">
                {this.getTemperatureString(probeTemp)}
            </span>
            :
            <i className="fa fa-minus"></i>
        ;
        let ambientTempJSX = (ambientTemp) ?
            <span className="emp-device-card-color3">
                {this.getTemperatureString(ambientTemp)}
            </span>
            :
            <i className="fa fa-minus"></i>
        ;
        return (
            <React.Fragment>
                {
                    this.props.device.device && this.props.device.device.probe_enabled ?
                        <>
                            {probeTempJSX}
                            <span>&nbsp;/&nbsp;</span>
                        </> : null
                }
                {ambientTempJSX}
            </React.Fragment>
        );
    }

    renderHumidity(telemetry) {
        let humidity;
        humidity = (telemetry.length > 0 && telemetry[0]['humidity']) ?
            <span className="emp-device-card-color1">
                {parseInt(telemetry[0]['humidity'])}%
            </span>
            :
            <i className="fa fa-minus"></i>
        ;
        return (
            <React.Fragment>
                {humidity}
            </React.Fragment>
        );
    }

    renderTimestamp(telemetry) {
        if (telemetry.length === 0)
            return '';
        return formatTimestampDateTime(telemetry[0]['timestamp']);
    }

    render() {
        const {device, telemetry} = this.props.device;

        return (
            <div className="card shadow emp-device-card">
                <div className="card-body emp-device-card-body">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="row">
                                <Link to={`/environment/device/${device.mac_address}`}>
                                    <span className="card-title emp-device-card-title">
                                    {device.name}
                                    </span>
                                </Link>
                            </div>
                            <div className="row">
                                <span className="emp-device-card-secondary-text">
                                    {this.renderTimestamp(telemetry)}
                                </span>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="row emp-device-card-data">
                                <div className="col-sm-6 offset-sm-6">
                                    {this.renderTemperatures(telemetry)}
                                </div>
                            </div>
                            <div className="row emp-device-card-data mt-3">
                                <div className="col-sm-6 offset-sm-6">
                                    {this.renderHumidity(telemetry)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default DashboardDeviceCard;
