import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';

const renderField = ({ input, id, className, label, labelClass, parentClass, htmlFor, initialCheck }) => {
    let checked = typeof input.value === "boolean" ? input.value === true : initialCheck
    return (
       <div className={parentClass}>
           <input type="checkbox" id={id} className={className} {...input} checked={checked}/>
           <label className={labelClass} htmlFor={htmlFor}>{label} </label>
       </div>
    );
}

export const CheckBoxBtn = ({ name, id, className, label, labelClass, parentClass, htmlFor, 
    handleChange, initialCheck}) => (
    <Field
        name={name}
        id={id}
        className={className}
        label={label}
        labelClass={labelClass}
        parentClass={parentClass}
        htmlFor={htmlFor}
        onChange={handleChange}
        component={renderField}
        initialCheck={initialCheck}
        />
);

CheckBoxBtn.propTypes = {
    name: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    labelClass: PropTypes.string,
    htmlFor: PropTypes.string,
    id: PropTypes.string,
    handleChange: PropTypes.func,
    parentClass: PropTypes.string,
    initialCheck: PropTypes.bool
};

CheckBoxBtn.defaultProps = {
    label: null,
    id: '',
    className:'',
    labelClass: '',
    htmlFor: '',
    parentClass:'',
    initialCheck: false
};