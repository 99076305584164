export const alertConstants = {
    FETCH_ALERT_DATA: 'FETCH_ALERT_DATA',
    FETCH_ALERT_DATA_FULFILLED: 'FETCH_ALERT_DATA_FULFILLED',

    CREATE_ALERT: 'CREATE_ALERT',
    CREATE_ALERT_FULFILLED: 'CREATE_ALERT_FULFILLED',

    GET_ALERT: 'GET_ALERT',
    GET_ALERT_FULFILLED: 'GET_ALERT_FULFILLED',

    UPDATE_ALERT: 'UPDATE_ALERT',
    UPDATE_ALERT_FULFILLED: 'UPDATE_ALERT_FULFILLED',

    SEARCH_ALERT: 'SEARCH_ALERT',
    SEARCH_ALERT_FULFILLED: 'SEARCH_ALERT_FULFILLED',

    RESET_ALERT_FOR_EDIT: 'RESET_ALERT_FOR_EDIT',
    INITIALIZE_ALERT_FOR_EDIT: 'INIITALIZE_ALERT_FOR_EDIT',

    DELETE_ALERT: 'DELETE_ALERT',
    DELETE_ALERT_FULFILLED: 'DELETE_ALERT_FULFILLED',

    DELETE_ALERTS: 'DELETE_ALERTS',
    DELETE_ALERTS_FULFILLED: 'DELETE_ALERTS_FULFILLED',
};