import {AreaType} from "./AreaType";


type GeoJsonBounds = {
    type: string,
    geometry: {
        type: string,
        coordinates: [[[number, number]]]
    }
}
export class Area {
    id: string;
    name: string;
    description: string;
    areaTypeId: string;
    parentId: string;
    createdById: string;
    updatedById: string;
    createdAt: Date;
    updatedAt: Date;
    imageUrl: string | null;
    iconUrl: string | null;
    areaType: AreaType | null;
    parent: Area | null;
    children: Area[];
    bounds: GeoJsonBounds | null

    constructor(
        id: string,
        name: string,
        description: string,
        areaTypeId: string,
        parentId: string,
        createdById: string,
        updatedById: string,
        createdAt: Date,
        updatedAt: Date,
        imageUrl: string | null,
        iconUrl: string | null,
        areaType: AreaType | null,
        parent: Area | null,
        children: Area[],
        bounds: GeoJsonBounds | null
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.areaTypeId = areaTypeId;
        this.parentId = parentId;
        this.createdById = createdById;
        this.updatedById = updatedById;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.imageUrl = imageUrl;
        this.iconUrl = iconUrl;
        this.areaType = areaType;
        this.parent = parent;
        this.children = children;
        this.bounds = bounds;
    }
}
