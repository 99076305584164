import {AssetType} from "./AssetType";

export class Asset {
    id: string;
    name: string;
    description: string;
    assetTypeId: string;
    parentId: string;
    createdById: string;
    updatedById: string;
    createdAt: Date;
    updatedAt: Date;
    imageUrl: string;
    iconUrl: string;
    assetType: AssetType | null;

    constructor(
        id: string,
        name: string,
        description: string,
        assetTypeId: string,
        parentId: string,
        createdById: string,
        updatedById: string,
        createdAt: Date,
        updatedAt: Date,
        imageUrl: string,
        iconUrl: string,
        assetType: AssetType | null
    ) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.assetTypeId = assetTypeId;
        this.parentId = parentId;
        this.createdById = createdById;
        this.updatedById = updatedById;
        this.createdAt = createdAt;
        this.updatedAt = updatedAt;
        this.imageUrl = imageUrl;
        this.iconUrl = iconUrl;
        this.assetType = assetType;
    }
}
