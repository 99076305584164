import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import * as utils from "../utils/utils";
import {convertTemperature, MeasurementUnit, SystemOfUnits} from "../utils/formatHelper";
import {AlertModel} from "./model/AlertModel";

const convertConditionsToCelsius = (conditions, field, values_format) => {
    if(!utils.isTemperatureField(field)) return conditions;

    const currentUnits = values_format === SystemOfUnits.Imperial ? MeasurementUnit.Fahrenheit : MeasurementUnit.Celsius;
    const desiredUnits = MeasurementUnit.Celsius;
    const newConditions = conditions.map(condition => {
        condition.threshold = convertTemperature(Number(condition.threshold), currentUnits, desiredUnits, 5);
        return condition;
    });
    return newConditions;
}

export const createAlert = (values, successCallback, errorCallback, values_format=null) => {
    const newConditions = convertConditionsToCelsius(values['conditions'], values['field'], values_format);

    let payload = {
        'title': values['title'] || 'NewAlert',
        'level': values['level'],
        'conditions': newConditions,
        'field': values['field'],
        'schema': values['schema'],
        'active': true,
        'trigger_minutes': values['trigger_minutes'],
        'trigger_data_points': values['trigger_data_points'],
        'description': values['description'] || '',
        'period': values['period'] || 300,
        'devices': values['devices'].map((device) => device.identity),
        'groups': values['groups'].map((user) => user.id),
    };
    return axios.post(`${BASE_URL()}/api/v1/alerts`, payload, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        successCallback(response.data || '');
    }).catch(function (response) {
        errorCallback(response.data ? response.data.errors || 'Unknown Error' : 'Unknown Error')
    })
};

export const updateAlert = (id, values, values_format=null) => {
    const newConditions = convertConditionsToCelsius(values['conditions'], values['field'], values_format);

    let payload = {
        'title': values['title'] || 'NewAlert',
        'level': values['level'],
        'conditions': newConditions,
        'field': values['field'],
        'schema': values['schema'],
        'active': values['active'],
        'trigger_minutes': values['trigger_minutes'],
        'trigger_data_points': values['trigger_data_points'],
        'description': values['description'] || '',
        'period': values['period'] || 300,
        'devices': values['devices'].map((device) => device.identity),
        'groups': values['groups'].map((user) => user.id),
        'trigger_id': id,
    };
    return axios.put(`${BASE_URL()}/api/v1/alerts/${id}`, payload, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    }).then(response => {
        return AlertModel.fromJson(response.data.items[0])
    })
};

export const getAlert = (triggerId, values_format=null) => {
    return axios.get(`${BASE_URL()}/api/v1/alerts/${triggerId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return AlertModel.fromJson(response.data, values_format);
    });
};

export const fetchAlertData = () => {
    return axios.get(`${BASE_URL()}/api/v1/alerts`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response.data['items'].map(item => AlertModel.fromJson(item));
    });
};

export const searchAlert = (values) => {

    return axios.get(`${BASE_URL()}/api/v1/alerts`, {
        params: values,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);

};

export const deleteAlert = (triggerId) => {
    return axios.delete(`${BASE_URL()}/api/v1/alerts/${triggerId}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response.data['items'].map(item => AlertModel.fromJson(item));
    });
};

export const deleteAlerts = (triggerIds) => {
  return axios.delete(`${BASE_URL()}/api/v1/alerts/bulk_delete`, {
      params: { ids: triggerIds},
      headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': storage.get('token')
      }
  }).then(response => response.data);
};