import moment from 'moment';

export default (pdfDoc, address, startX, startY, fontSizes, lineSpacing ) => {

    pdfDoc.setFontSize(fontSizes.paragraph)
    pdfDoc.text("Empiric.io", startX, startY)

    address = Object.values(address)

    address.map(text => {
        if (text) {
            pdfDoc.text(text, startX, startY += lineSpacing);
        }
    });

    pdfDoc.text(`Date Created: ${moment().format('MMMM Do YYYY h:mm:ss a')}`, startX, startY+=lineSpacing)

    return startY;
}