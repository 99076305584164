import React from 'react';
import {bindActionCreators} from 'redux';
import {browserHistory, Link, withRouter} from 'react-router';
import {connect} from 'react-redux';
import * as DeviceActions from '../../actions/deviceAction';
import * as GroupActions from '../../actions/groupAction';
import swal from 'sweetalert';
import {GroupListView} from "./GroupListView";
import LoadingMessage from '../LoadingMessage/LoadingMessage';


export class GroupsAndDevices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            alertClass: 'd-none',
            loading: false
        };
        this.checkGroup = this.checkGroup.bind(this);
        this.deleteGroup = this.deleteGroup.bind(this);
    }

    async componentDidMount() {
        this.setState({loading: true});
        try{
            await this.props.groupActions.fetchGroupData();
        } catch(e){
            console.log(e)
        }
        this.setState({loading: false});
    }

    checkGroup() {
        browserHistory.push('/new_device');
    }

    deleteGroup(group) {
        swal({
            title: `Delete Group ${group.name}`,
            text: "Are you sure?",
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    className: "btn btn-default class4"
                },
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "btn btn-primary ",
                    closeModal: true
                }
            }
        }).then((isConfirm) => {
            if (isConfirm) {
                this.props.groupActions.deleteGroup(group.id);
            }
        });
    }

    render() {
        if(this.state.loading){
            return <LoadingMessage/>
        }
        const {handleSubmit, submitting, asyncValidating} = this.props;

        return (
            <main className="app-content">
                <div className={`login w-25 alert alert-dismissible alert-danger ${this.state.alertClass}`}>
                    Please create group to activate Device
                    <button className="close" onClick={() => this.setState({alertClass: 'd-none'})}>x</button>
                </div>
                <button type="button"
                        className={`btn btn-next btn-primary emp-cst-btn group-success-message emp-btn d-none`}
                        data-toggle="modal" data-target="#create_group_alert"></button>
                <div className="app-title border-bottom">
                    <div>
                        <h1>Groups and Devices</h1>
                    </div>
                </div>
                <section className="emp-sec emp-active-device-sec">
                    <div className="container">
                        <div className="row">
                            <div
                                className="col-xl-6 offset-xl-3 col-lg-8 offset-lg-2 col-md-8 offset-md-2 col-sm-12 offset-sm-0">

                                <div className="emp_setup_list_block">
                                    <div className="card shadow emp-form-card emp-group-card emp-devices-card">
                                        <GroupListView items={this.props.groupReducer.groups} onDeleteGroup={this.deleteGroup.bind(this)}/>
                                    </div>
                                    {this.renderBottomActions()}
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
            </main>
        );
    }

    renderGroupItem(index, group) {
        return (<li key={index}
                   className="row">
            <Link to={`/group/${group.id}`}
                  className="col-sm-6">
   <span
       className="group-step-label">{group.name}<br/><span
       className="emp_small_txt">{group.gateway_count}
       Gateways • {group.sensor_count}
       sensors</span></span>
            </Link>
            <Link to={`/group/${group.id}`}
                  className="col-sm-3">
   <span className="group-step-icn">
   <i className="fa fa-pencil"></i>
   </span>
            </Link>
            <a className=""
               onClick={(e) => this.deleteGroup(group)}>
                <img
                    src={require("../../styles/images/icon/icn_trash.png")}
                    alt="delete"/>
            </a>
        </li>);
    }

    renderBottomActions() {
        return <div className="row">
            <div className="col-sm-12">
                <div className="active-device-btn float-left">
                    <button onClick={() => this.checkGroup()}
                            className="cursor-pointer btn btn-next btn-primary emp-cst-btn emp-btn">

                                                    <span className="mr-2 emp_icn_middle">
                                                        <img src={require('../../styles/images/icon/ic_checking.png')}
                                                             alt="add_img"/>
                                                    </span>Activate Device
                    </button>
                </div>
                <div className="active-device-btn float-right">
                    <Link to="/new_group" className="create-group">
                        <img src={require('../../styles/images/icon/ic_add.png')}
                             alt="add_img"/> <span>Create Group</span>
                    </Link>
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = (state) => {

    return ({
        groupReducer: state.groupReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    deviceActions: bindActionCreators(DeviceActions, dispatch),
    groupActions: bindActionCreators(GroupActions, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(GroupsAndDevices));
