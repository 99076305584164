import React from 'react';
import { Link } from 'react-router';
import * as authApi from '../../api/authApi';
import  {validateEmail}  from './validateEmail';
import store from '../../store/store';


export default class ForgotPasswordPage extends React.Component {
    state = {
        email: '',
        loading: false,
        isResetPasswordLinkSent: false,
        isEmailValid: false,
        errorMessage: ''
    };

    constructor () {
        super();

        this.onEmailChange = this.onEmailChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    validateEmail (email) {
        let emailValidation = validateEmail(email);

        this.setState({
            isEmailValid: emailValidation.valid,
            errorMessage: emailValidation.message
        });
    }

    onEmailChange (e) {
        let value = e.target.value;

        this.setState({ email: e.target.value }, this.validateEmail(value));
    }

    async sendResetPasswordLink () {
        try {
            let state = store.getState();
            let response = await authApi.sendResetPasswordLink(this.state.email);
            if (response.data.success){
                this.setState({ loading: true });
                this.setState({
                    loading: false,
                    isResetPasswordLinkSent: true
                });
                state.authReducer.message=response.data.message;
                //swal({
                //    icon: 'success',
                //    title: 'Sent',
                //    text: response.data.message,
                //    className: 'error-handler-modal'
                //});
                ///browserHistory.push('/auth/login');
            }
            else{
                this.setState({ loading: false });
                state.authReducer.message=response.data.message;
                this.setState({
                    errorMessage: response.data.message
                })
            }

        } catch (e) {
            this.setState({
                loading: false,
                errorMessage: `Looks like we don't have this email in our database.`,
                isEmailValid: false
            });
        }
    }

    onSubmit(e) {
        e.preventDefault();

        this.validateEmail(this.state.email);

        if (!this.state.isEmailValid) return;

        return this.sendResetPasswordLink();
    }

    render () {
        const { email, loading, errorMessage, isResetPasswordLinkSent } = this.state;

        return (
            <section className="emp-sec emp-login-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <div className="emp-brand">
                                <img src={require('../../styles/images/icon/logo.png')} alt="Logo" />
                            </div>
                        </div>
                    </div>
                    { !isResetPasswordLinkSent &&
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
                                <div className="emp-login-form">
                                    <div className="card shadow emp-form-card">
                                        <div className="card-body">
                                            <div className="emp-form-heading text-center">
                                                <h2>Password Reset</h2>
                                            </div>
                                            <form className="forgot-password-form__form" onSubmit={this.onSubmit}
                                                  noValidate={true}>
                                                <div className="form-group">
                                                    <label className="label_modifier">Email</label>
                                                    <input
                                                        value={email}
                                                        onChange={this.onEmailChange}
                                                        type="email"
                                                        name="email"
                                                        label="Email"
                                                        className="form-control input_modifier"
                                                        placeholder="Enter your email address"
                                                        />

                                                    <div className="form-group__error">{errorMessage}</div>
                                                </div>
                                                {/*<div className="form-group">
                                                 <label className="label_modifier">Email</label>
                                                 <input type="email" className="form-control input_modifier" placeholder="Enter your email address" />
                                                 </div>*/}
                                                <div className="form-group text-center">
                                                    <button type="submit" disabled={loading}
                                                            className="btn btn-primary emp-btn emp-form-btn">Reset password
                                                    </button>
                                                </div>
                                                <div className="form-group text-center form-bottom-links">
                                                    <p>Back to <Link to="/auth/login">Login</Link></p>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {isResetPasswordLinkSent &&
                        <div className="row">
                            <div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3">
                                <div className="emp-login-form">
                                    <div className="card shadow emp-form-card">
                                        <div className="card-body">
                                            <div className="emp-form-heading text-center">
                                                <h2>Password reset confirmation sent!</h2>
                                            </div>
                                            <div className="emp-text-block text-center">
                                                <p className="text-confirm">Weʼve sent you an email containing a link that will allow you to reset your
                                                    password for the next 24 hours. Please check your spam folder if the email
                                                    doesnʼt appear within a few minutes.</p>
                                                <Link to="/auth/login" class="btn btn-primary emp-btn emp-form-btn">Return to Sign In</Link>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
             </section>
        );
    }
}

