import {scheduledJobConstants} from '../constants/scheduledJob.constants';
import * as scheduledJobApi from "../api/scheduledJobApi";
import {ScheduledJobModel} from '../api/model/ScheduledJob';


interface ScheduledJobAction {
    type: string;
    payload: ScheduledJobModel;
}

interface ClearScheduledJobAction {
    type: string;
}

export const listScheduledJobs = (scheduleId: string, cursor: string | null = null) => (dispatch: Function) => {
    return dispatch({
        type: scheduledJobConstants.LIST_SCHEDULED_JOBS,
        payload: scheduledJobApi.listScheduledJobs(scheduleId, cursor)
    });
};

export const getScheduledJob = (scheduledJobId: string) => (dispatch: Function) => {
    return dispatch({
        type: scheduledJobConstants.GET_SCHEDULED_JOB,
        payload: scheduledJobApi.getScheduledJob(scheduledJobId)
    });
};

export const selectScheduledJob = (selectedScheduledJob: ScheduledJobModel): ScheduledJobAction => ({
    type: scheduledJobConstants.SELECT_SCHEDULED_JOB,
    payload: selectedScheduledJob
});

export const clearScheduledJobs = (): ClearScheduledJobAction => {
    return {
        type: scheduledJobConstants.CLEAR_SCHEDULED_JOBS
    };
}
