import * as Moment from 'moment';
import * as utils from "./utils";

export function formatTemperature (temperature) {
    return parseFloat(temperature).toFixed(2);
}

export function formatTimestampDateTime (timestamp) {
    return Moment.utc(timestamp).local().format('YYYY/MM/DD hh:mm a')
}

export const SystemOfUnits = {
    Metric: 'metric',
    Imperial: 'imperial'
}

export const MeasurementUnit = {
    Celsius: 'celsius',
    Fahrenheit: 'fahrenheit',
    MetersPerSecondSquared: 'm/s^2',
    Millivolts: "millivolts",
    Percent: "percent",
    None: null
}

export const MetricToImperialUnits = {
    [MeasurementUnit.Celsius] : MeasurementUnit.Fahrenheit,
    [MeasurementUnit.None]: MeasurementUnit.None
}

export const ImperialToMetricUnits = {
    [MeasurementUnit.Fahrenheit] : MeasurementUnit.Celsius,
    [MeasurementUnit.None]: MeasurementUnit.None
}

export const UnitsToSystemOfUnits = {
    [MeasurementUnit.Celsius]: SystemOfUnits.Metric,
    [MeasurementUnit.Fahrenheit]: SystemOfUnits.Imperial,
    [MeasurementUnit.None]: SystemOfUnits.Metric
}

export const MeasurementUnitShortForm = {
    [MeasurementUnit.Celsius] : '°C',
    [MeasurementUnit.Fahrenheit] : '°F',
    [MeasurementUnit.Millivolts] : 'mV',
    [MeasurementUnit.MetersPerSecondSquared] : 'm/s^2',
    [MeasurementUnit.Percent] : '%'
}

/**
 * Function for retrieving the desired unit conversion for a given system of units
 * If conversion is not mapped, returns original units
 * @param systemOfUnits enum System of Units
 * @param units Units to be converted
 * @returns {string|*} converted MeasurementUnit
 */
export function getDesiredUnits(systemOfUnits, units) {
    let originalSystemOfUnits = UnitsToSystemOfUnits.hasOwnProperty(units) ? UnitsToSystemOfUnits[units] : SystemOfUnits.Metric;

    if (originalSystemOfUnits === systemOfUnits) {
        return units;
    } else {
        switch (systemOfUnits) {
            case SystemOfUnits.Metric:
                return ImperialToMetricUnits.hasOwnProperty(units) ? ImperialToMetricUnits[units] : units
            case SystemOfUnits.Imperial:
                return MetricToImperialUnits.hasOwnProperty(units) ? MetricToImperialUnits[units] : units;
            default:
                return units
        }
    }
}

export class UnitOfMeasurement {
    value;
    unitOfMeasurement;
    constructor(value, unitOfMeasurement) {
        this.value = value;
        this.unitOfMeasurement = unitOfMeasurement;
    }

    formatUnit(desired_unit) {
        switch (this.unitOfMeasurement) {
            case MeasurementUnit.Celsius:
                if (desired_unit === MeasurementUnit.Fahrenheit) {
                    const fahrenheit = this.value * (9/5) + 32;
                    return precisionRound(fahrenheit, 4);
                } else {
                    return this.value;
                }
            case MeasurementUnit.Fahrenheit:
                if(desired_unit === MeasurementUnit.Celsius){
                    return (this.value - 32) * (5/9);
                } else {
                    return this.value;
                }
            default:
                return this.value;
        }
        return null;

    }
}

export function convertLocationValueToCoordinates (recent_datapoints) {
    const locationData = recent_datapoints.map(datapoint => datapoint.value);
    const coordinateData = locationData.map(datapoint => {
        const coordinates = datapoint.map(value => {
            return Number.parseFloat(value).toFixed(5);
        });
        return `(${coordinates[0]},${coordinates[1]})`
    });
    return coordinateData;
}

export function precisionRound(number, precision) {
    const factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
}

export function convertTemperature(value, inputFormat, outputFormat, fractionDigits=2) {
    value = new UnitOfMeasurement(value, inputFormat).formatUnit(outputFormat);
    value = Number(value.toFixed(fractionDigits));
    return value;
}

export function getMeasurementUnitShortForm (measurementUnit) {
    return MeasurementUnitShortForm[measurementUnit];
}

export function getTemperatureString(system_of_unit) {
    switch(system_of_unit){
        case SystemOfUnits.Imperial:
            return "°F";
        case SystemOfUnits.Metric:
            return "°C";
        default:
            return "";
    }
}