import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as AreaActions from '../../actions/areaActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {browserHistory, withRouter} from 'react-router';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import AreaEditDetailPage from "../../components/Area/AreaEditDetailPage";
import * as AreaTypeActions from "../../actions/areaTypeActions";

export class AreaCreateDetailPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        await Promise.all(
            [
                this.props.areaActions.resetArea(),
                this.props.areaActions.listAreas(),
                this.props.areaTypeActions.listAreaTypes()
            ]
        );
    }

    async onSubmit(values) {
        this.setState({loading: true});
        await this.props.areaActions.createArea(values);
        this.setState({loading: false});
        browserHistory.push(`/areas/${this.props.area.id}`);
    }

    render() {
        const {auth, authActions, area, areas, areaTypes} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <AreaEditDetailPage
                        area={area}
                        areas={areas}
                        areaTypes={areaTypes}
                        onSubmit={this.onSubmit}/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    area: state.areaReducer.area,
    areas: state.areaReducer.areas,
    areaTypes: state.areaTypeReducer.areaTypes
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    areaActions: bindActionCreators(AreaActions, dispatch),
    areaTypeActions: bindActionCreators(AreaTypeActions, dispatch),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AreaCreateDetailPageContainer));