import React from "react";
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {CSVLink} from 'react-csv';
import {DateTimeField} from '../DateTimeField/NewDateTimeField';
import {reduxForm, formValueSelector} from 'redux-form';
import FilterGroup from "../FilterForm/FilterGroup";
import FilterForm from "../FilterForm/FilterForm"
import {getDatedFileName, validateDateRange} from '../../utils/utils';
import {required, validateDateTimeFormat} from '../../utils/validateFunctions';
import {formatTimestampDateTime} from "../../utils/formatHelper";
import {createFormFilters} from "../FilterForm/createFormFilters";
import ReactTable from "react-table";
import ReportPdf from "../Reports/pdf/ReportPdf";
import * as Moment from 'moment';
import * as AuditLogActions from '../../actions/auditLogAction';
import LoadingMessage from '../LoadingMessage/LoadingMessage';
import {filterConstants} from '../../constants/filterform.constants';
import {operator} from "../FilterForm/operator";
import AuditLogEventFormatter from "./AuditLogEventFormatter";

const FIELD_ARR_NAME = filterConstants.QUERY_BUILDER_ARR;


class Dimension {
    type;
    name;
    title;
    shortTitle;


    constructor(name, title, shortTitle, type) {
        this.name = name;
        this.title = title;
        this.shortTitle = shortTitle;
        this.type = type;
    }
}

class AuditLogPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            availableDimensions: [],
            columns: [
                {
                    Header: "User",
                    accessor: "modified_by",
                    columnName: "modified_by",
                    Cell: (row) => this.styleCell(row.value, 'text-center')
                },
                {
                    Header: 'Log Event',
                    accessor: 'log_event',
                    columnName: 'log_event',
                    Cell: (row) => this.styleCell(row.value, 'text-center')
                },
                {
                    Header: 'Date',
                    accessor: 'updated_at',
                    columnName: 'date',
                    Cell: (row) => this.styleCell(row.value, 'text-center')
                }
            ]
        }
        this.createTableListItems = this.createTableListItems.bind(this);
        this.onSearchSubmit = this.onSearchSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.auditLogs.updated) {
            this.setState({loading:false});
            nextProps.auditLogs.updated = false;
        }
    }

    sortByUpdatedAt(a,b){
        if(new Date(a.updated_at) > new Date(b.updated_at)) return -1;
        if(new Date(a.updated_at) < new Date(b.updated_at)) return 1;
        return 0;
    }

    createTableListItems() {
        const items = this.props.auditLogs.items.map(auditLog => {
            const logEvent = this.formatlogEvent(auditLog);

            return {
                modified_by: auditLog.modified_by ? auditLog.modified_by.email : "",
                log_event: logEvent,
                updated_at: formatTimestampDateTime(auditLog.updated_at)
            }
        });

        items.sort(this.sortByUpdatedAt);
        
        const {queryBuilder} = this.props;

        if (!queryBuilder || !queryBuilder.length) return items;

        if (Object.keys(queryBuilder[0]).length < 3) return items;

        return createFormFilters(queryBuilder)
            .reduce((i, f) => i.filter(f), items);
    }

    formatlogEvent = (auditLog) => {
       return new AuditLogEventFormatter().formatLogEvent(auditLog);
    }

    columnToDimensionAdapter = (col) => {
        return new Dimension(col.accessor, "", col.Header, "");
    }

    generatePdf = () => {
        const pdfReport = new ReportPdf(getDatedFileName({extension:"pdf"}));
        const orgAddress = this.props.settings.organization.address;
        const address = {
            person: this.props.auth.user.first_name + ' ' + this.props.auth.user.last_name,
            address: orgAddress ? orgAddress.address1 : '' ,
            city_state_zip: this._format_city_state_zip_line(this.props.settings.organization.address || ''),
            email: this.props.auth.user.email,
            phone: this.props.auth.user.phone_number,

        }

        const tableHeaderKeys = Object.keys(this.getCSVData()[0]);
        const columns = tableHeaderKeys.map(key=>`${key[0].toUpperCase()}${key.substring(1)}`.replace("_", " "))

        const data = {
            columns,
            items: this.getCSVData()
        }

        pdfReport.generate(data, address, this.props.settings.organization.default_logo.url);
    }

    _format_city_state_zip_line(address) {
        if(!address) return "";
        let city =  address.city || ''
        let state = address.state || '';
        let zip = address.zip || '';

        return `${city}, ${state}, ${zip}`
    }

    getCSVData() {
        function formatDataForExport(data){
            return data.map(item => {
                const {updated_at, modified_by, log_event} = item;

                return {
                    modified_by,
                    log_event: log_event.props ? log_event.props.children : log_event,
                    updated_at,
                }
            });
        }
        const filteredData = this.createTableListItems();
        const formattedData = formatDataForExport(filteredData);
        return formattedData.sort(this.sortByUpdatedAt)
    }

    componentDidMount(){
        const {initialValues, auditLogActions} = this.props;
        auditLogActions.fetchAuditLogDataByDate(initialValues);

        const {columns} = this.state;
        const availableDimensions = columns.map(this.columnToDimensionAdapter);
        this.setState({...this.setState, availableDimensions, loading: true});
    }

    onSearchSubmit(values, dispatch) {
        this.setState({loading: true});
        this.props.auditLogActions.fetchAuditLogDataByDate(values);
    }

    renderAuditDataForm(handleSubmit) {
        return(
            <form onSubmit={handleSubmit(this.onSearchSubmit)}>
                <ul className="app-breadcrumb breadcrumb">
                    <li className="emp-title-item">
                        <FilterForm dimensions={this.state.availableDimensions}/>
                    </li>
                    <li className="emp-title-item">
                        <div className="dropdown">
                            <a className="btn btn-primary dropdown-toggle" href="#" role="button" id="dropdownMenuLink"
                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Export As</a>
                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <CSVLink filename={getDatedFileName({extension:"csv"})} data={this.getCSVData()} className="dropdown-item">
                                CSV
                            </CSVLink>
                            <a className="dropdown-item" href='#' onClick={() => this.generatePdf()}>PDF</a>
                        </div>
                    </div>
                    </li>
                    <li className="emp-title-item">
                        <button className="btn btn-primary submit_button">Run</button>
                    </li>
                    <li className="emp-title-item env-date date-field">
                        <DateTimeField
                            type="text"
                            name="start_date"
                            placeholder="Start Date"
                            className="form-control input_modifier border-0 app-title-shadow datepicker--input"
                            parentClass="app-title-shadow"
                            validate={[validateDateTimeFormat, required]}
                        />
                        </li>
                        <li className="emp-title-item">
                            <div className="app-title-right-widget-small">
                                <div className="info border-info">
                                    <hr className="border-top-info"/>
                                </div>
                            </div>
                        </li>
                        <li className="emp-title-item env-date date-field">
                            <DateTimeField
                                type="text"
                                name="end_date"
                                placeholder="End Date"
                                className="form-control input_modifier border-0 app-title-shadow datepicker--input"
                                parentClass="app-title-shadow"
                                validate={[validateDateTimeFormat, required]}
                            />
                        </li>
                        <li className="emp-title-item">
                            <div className="app-title-right-widget-small">
                                <div className="info title-icn-calender bg-transparent">
                                    <img src={require("../../styles/images/icon/icn_calender.png")}
                                        alt="Calender Icon"/>
                                </div>
                            </div>
                        </li>
                    </ul>
                {this.props.queryBuilder && this.props.queryBuilder.map((field, index) => {
                    return (
                        <FilterGroup
                            dimensions={this.state.availableDimensions}
                            operators={Object.values(operator)}
                            key={index}
                            filterGroup={`${FIELD_ARR_NAME}[${index}]`}
                            index={index}
                            fields={this.props.filterFields}
                        />
                    )
                })}
            </form>
        ) 
    }

    renderAuditDataTable(){
        return (
            <div>
                <ReactTable
                    columns={this.state.columns}
                    data={this.createTableListItems()}
                    className='-striped'
                    showPagination={true}
                    minRows={5}
                />
            </div>
        )
    }

    render() {
        if (this.state.loading)
            return (<LoadingMessage/>)
        const {handleSubmit} = this.props;
        return (
            <main className="app-content">
                <div className="app-title border-bottom">
                    <div>
                        <h1>Audit Logs</h1>
                    </div>
                    {this.renderAuditDataForm(handleSubmit)}
                </div>
                {this.renderAuditDataTable()}
            </main>
        );
    }

    styleCell = (label, classes) => {
        if (classes)
            return <div className={classes}>{label}</div>
        return <div>{label}</div>
    }
}

const selector = formValueSelector('createAuditLogForm');

const mapStateToProps = (state) => {
    const queryBuilder = selector(state, FIELD_ARR_NAME);

    let format = 'MM-DD-YYYY hh:mm A';
    let start_date = Moment.utc(new Date((new Date()).valueOf() - 1000 * 2 * 3600 * 24).toISOString()).format(format);
    let end_date = Moment.utc(new Date((new Date()).valueOf() + 1000 * 3600 * 24).toISOString()).format(format);

    return {
        initialValues: {
            period: '1h',
            start_date: start_date,
            end_date: end_date
        },
        settings: state.generalSettingReducer,
        auth: state.authReducer,
        queryBuilder,
        auditLogs:state.auditLogReducer,
        filterFields: state.filterFormReducer

    }
}

const mapDispatchToProps = (dispatch) => ({
    auditLogActions: bindActionCreators(AuditLogActions, dispatch),
});

export default connect
(mapStateToProps, mapDispatchToProps)
(reduxForm({form: 'createAuditLogForm', validate: validateDateRange})
(AuditLogPage));