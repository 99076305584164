import React, { FC } from "react";
import { Field, Form, reduxForm, InjectedFormProps } from "redux-form";
import * as $ from "jquery";
import { NoLabelFileInputField } from "../FileInputField/NoLabelFileInputField";
import { connect } from "react-redux";
import { TextField } from "../../ui/Input/TextField";
import { SelectField } from "../../ui/Input/SelectField";

interface Props {
    device: any;
    onSubmit: any;
}

interface StateProps {
    device: any;
}

interface IFieldProps {
    input: any;
    label: string;
    meta: any;
    items?: any;
}


const titleField: FC<IFieldProps> = ({input, label, meta: {touched, error}}) => {
    return (
        <TextField label={label} {...input}/>
    );

}

const selectField: FC<IFieldProps> = ({input, label, items}) => {
    return <SelectField label={label}
                        placeholder=""
                        value={input.value}
                        {...input}
                        items={items}
    />
}

class DeviceForm extends React.Component<Props & InjectedFormProps<{}, Props>>{
    constructor(props) {
        super(props);
    }

    render() {
        const {error, handleSubmit, pristine, reset, submitting, device} = this.props;

        return <Form onSubmit={handleSubmit(this.props.onSubmit)}>
            <div className={"container-fluid"}>
                <div className={"row mt-2"}>
                    <span className={"emp-type-h2 col"}>{device.name || 'Device'}</span>
                </div>

                <div className={"row"}>
                    <div className={"col"}>
                        <div className="emp-type-subtitle1">
                            <div className={"mt-4"}>
                                {this.renderCard()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>;
    }

    renderTextRow(fieldName, label) {
        return (
            <div className="row mb-3">
                <div className={"col-12 col-sm-6"}>
                    <Field name={fieldName} label={label} component={titleField}/>
                </div>
            </div>
        );
    }

    renderSelectFieldRow(fieldName, label, items) {
        return (
            <div className="row mt-2 mb-3">
                <div className={"col-12 col-sm-6"}>
                    <Field name={fieldName} label={label} items={items} component={selectField}/>
                </div>
            </div>
        );
    }

    renderImage() {
        const handleFiles = (e) => {
            let name = e.currentTarget.files.length > 0 ? $.trim(e.currentTarget.files[0].name).substring(0, 30).trim(this) + "..." : '';
            $('.organization_logo').html(name);
            if (e.target.files.length > 0) {
                let url = URL.createObjectURL(e.target.files[0]);
                $('#preview_img').attr('src', url);
            } else {
                $('#preview_img').attr('src', require("../../styles/images/icon/logo.png"));
            }
        };

        const {device} = this.props;
        const imageUrl = device.image_url && device.image_url.url ? device.image_url.url : "https://via.placeholder.com/250?text=Device+PlaceHolder";
        return <>
            <div className={"row mb-3 mb-sm-2"}>
                <div className={"col-12"}>
                    <img id="preview_img" className="placeholder img-thumbnail"
                         src={imageUrl}></img>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-12"}>
                    <NoLabelFileInputField
                        type="file"
                        name="image"
                        placeholder="New York, NY, USA"
                        //label="Choose Logo "
                        HandleChange={(e) => handleFiles(e)}
                        //iconClass="fa fa-info-circle"
                        className="display-none"
                        inputGroupLabel={""}
                        inputGroupLabelClass="custom-file-label emp_cst_file_label organization_logo pointer-none"
                        inputGroupClass="input-group mb-3 cst-upload"
                        htmlFor="inputGroupFile01"
                        // @ts-ignore
                        HandleClick={(e) => handleFiles(e)}
                        id="inputGroupFile01"
                    />
                </div>
            </div>
        </>
    }


    renderCard() {
        const {error, handleSubmit, pristine, reset, submitting, deviceTypes} = this.props;
        console.log(deviceTypes);
        const deviceTypeItems = deviceTypes
            .map(deviceType => ({value: deviceType.id, label: deviceType.name}))

        return (
            <>
                <div className="row mb-2">
                    <div className="col-12 col-sm-4">
                        {this.renderImage()}
                    </div>

                    <div className="col">
                        <>
                            {this.renderTextRow("name", "Name")}
                            {this.renderTextRow("description", "Description")}
                            {this.renderSelectFieldRow("device_type_id", "Device Type", deviceTypeItems)}
                            <div className={"row"}>
                                <div className={"col-0 col-sm"}></div>
                                <div className={"col-12 col-sm-8"}>
                                    <button type="submit" disabled={submitting} className={"btn btn-primary"}><span
                                        className={"emp-type-btn-primary"}>SAVE</span>
                                    </button>
                                </div>
                            </div>
                        </>
                    </div>
                </div>

            </>
        );
    }
}

export const validate = (values: any) => {
    const errors = {};
    return errors;
};

export const mapStateToProps = (state: any): StateProps & InjectedFormProps => ({
    device: state.deviceReducer.device && state.deviceReducer.device.device ? state.deviceReducer.device.device : {},
    deviceTypes: state.deviceTypesReducer.deviceTypes ?? [],
    initialValues: state.deviceReducer.device && state.deviceReducer.device.device ? state.deviceReducer.device.device : {},
});

export const FORM_NAME = "devicesForm";

const wrappedDeviceForm = connect<StateProps, {}, Props>(mapStateToProps)(
    reduxForm<{}, Props>({
        form: FORM_NAME,
        enableReinitialize: true,
        validate,
    })(DeviceForm)
);
export default wrappedDeviceForm;