import {getCustomDateField, getCustomDateTimeField, getTextField} from "./CustomInputComponents";

export const getFormArrayInputComponent = (arrayType: string) => {
    switch (arrayType) {
        case "Date":
            return getCustomDateField;
        case "DateTime":
            return getCustomDateTimeField;
        case "String":
            return getTextField;
        default:
            console.log("No input component found for array type: " + arrayType);
    }
};