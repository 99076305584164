import React from 'react';
import {Form} from 'redux-form';
import {DateTimeField} from '../DateTimeField/DateTimeField';

export class IncidentHeaderSelector extends React.Component {

    render() {
        return <Form onSubmit={this.props.handleSubmit} noValidate={true}>
            <ul className="app-breadcrumb breadcrumb">
                {/*<li className="emp-title-item em-title-checkbox">*/}
                    {/*<CheckBoxBtn*/}
                        {/*name={`device_data[warning]`}*/}
                        {/*className="custom-control-input"*/}
                        {/*type="checkbox"*/}
                        {/*value="temperature"*/}
                        {/*id={`customCheck1`}*/}
                        {/*htmlFor={`customCheck1`}*/}
                        {/*label="Warning's"*/}
                        {/*labelClass="custom-control-label"*/}
                        {/*//handleChange={e => this.handleDevice(e)}*/}
                        {/*parentClass="custom-control custom-checkbox custom-checkbox-yellow"*/}
                    {/*/>*/}

                {/*</li>*/}
                {/*<li className="emp-title-item em-title-checkbox mr-0">*/}
                    {/*<CheckBoxBtn*/}
                        {/*name={`device_data[alert]`}*/}
                        {/*className="custom-control-input"*/}
                        {/*type="checkbox"*/}
                        {/*value="temperature"*/}
                        {/*id={`customCheck2`}*/}
                        {/*htmlFor={`customCheck2`}*/}
                        {/*label="Critical alerts"*/}
                        {/*labelClass="custom-control-label"*/}
                        {/*//handleChange={e => this.handleDevice(e)}*/}
                        {/*parentClass="custom-control custom-checkbox custom-checkbox-red"*/}
                    {/*/>*/}
                {/*</li>*/}
                {/*<li className="emp-title-item ">*/}
                {/*<a className="app-nav__item duration_list text-initial" href="images/icon/icn_pdf.png" download="">*/}
                {/*Export to PDF*/}
                {/*</a>*/}
                {/*</li>*/}
                {/*<li className="emp-title-item emp_select_itme mr-0">*/}
                {/*<select className="custom-select cst-select-box input_modifier shadow_select_hover app-title-shadow">*/}
                {/*<option selected="">Alert</option>*/}
                {/*<option value="1">One</option>*/}
                {/*<option value="2">Two</option>*/}
                {/*<option value="3">Three</option>*/}
                {/*</select>*/}
                {/*</li>*/}
                {/*<li className="emp-title-item emp_select_itme mr-0">*/}
                {/*<select className="custom-select cst-select-box input_modifier shadow_select_hover app-title-shadow">*/}
                {/*<option selected="">Status</option>*/}
                {/*<option value="1">One</option>*/}
                {/*<option value="2">Two</option>*/}
                {/*<option value="3">Three</option>*/}
                {/*</select>*/}
                {/*</li>*/}
                <li className="emp-title-item env-date date-field">
                    <DateTimeField
                        type="text"
                        name="query_params[start_date]"
                        placeholder="12 Aug 2017"
                        className="form-control input_modifier border-0 app-title-shadow datepicker--input"
                        dataFormat="YYYY-MM-DD"
                        parentClass="info_cal app-title-shadow"
                    />
                </li>
                <li className="emp-title-item">
                    <div className="app-title-right-widget-small">
                        <div className="info border-info">
                            <hr className="border-top-info"/>
                        </div>
                    </div>
                </li>
                <li className="emp-title-item env-date date-field">
                    <DateTimeField
                        type="text"
                        name="query_params[end_date]"
                        placeholder="12 Aug 2017"
                        className="form-control input_modifier border-0 app-title-shadow datepicker--input"
                        dataFormat="YYYY-MM-DD"
                        parentClass="info_cal app-title-shadow"
                    />
                </li>
                <li className="emp-title-item">
                    <div className="app-title-right-widget-small">
                        <div className="info title-icn-calender bg-transparent">
                            <img src={require("../../styles/images/icon/icn_calender.png")} alt="Calender Icon"/>
                        </div>
                    </div>
                </li>
                <button className="btn btn-primary submit_button">submit</button>
            </ul>

        </Form>;
    }
}