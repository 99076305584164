export const deviceTypeMetricsConstants = {
    GET_DEVICE_TYPE_METRIC: "GET_DEVICE_TYPE_METRIC",
    GET_DEVICE_TYPE_METRIC_FULFILLED: "GET_DEVICE_TYPE_METRIC_FULFILLED",

    LIST_DEVICE_TYPE_METRICS: "LIST_DEVICE_TYPE_METRICS",
    LIST_DEVICE_TYPE_METRICS_FULFILLED: "LIST_DEVICE_TYPE_METRICS_FULFILLED",

    CREATE_DEVICE_TYPE_METRIC: "CREATE_DEVICE_TYPE_METRIC",
    CREATE_DEVICE_TYPE_METRIC_FULFILLED: "CREATE_DEVICE_TYPE_METRIC_FULFILLED",

    DELETE_DEVICE_TYPE_METRIC: "DELETE_DEVICE_TYPE_METRIC",
    DELETE_DEVICE_TYPE_METRIC_FULFILLED: "DELETE_DEVICE_TYPE_METRIC_FULFILLED",

    UPDATE_DEVICE_TYPE_METRIC: "UPDATE_DEVICE_TYPE_METRIC",
    UPDATE_DEVICE_TYPE_METRIC_FULFILLED: "UPDATE_DEVICE_TYPE_METRIC_FULFILLED",
};
