export const groupConstants = {
    FETCH_GROUP: 'FETCH_GROUP',
    FETCH_GROUP_FULFILLED: 'FETCH_GROUP_FULFILLED',

    FETCH_GROUP_DATA: 'FETCH_GROUP_DATA',
    FETCH_GROUP_DATA_FULFILLED: 'FETCH_GROUP_DATA_FULFILLED',

    CREATE_GROUP: 'CREATE_GROUP',
    CREATE_GROUP_FULFILLED: 'CREATE_GROUP_FULFILLED',

    DELETE_GROUP: 'DELETE_GROUP',
    DELETE_GROUP_FULFILLED: 'DELETE_GROUP_FULFILLED',

    UPDATE_GROUP: 'UPDATE_GROUP',
    UPDATE_GROUP_FULFILLED: 'UPDATE_GROUP_FULFILLED',
};