import {browserHistory} from 'react-router';
import {authConstants} from '../constants/auth.constants';
import storage from '../utils/storage';
import store from '../store/store';
import * as authApi from '../api/authApi';
import parseJWT from '../utils/parseJWT';
import { SubmissionError } from 'redux-form';  // ES6

// ToDo: login() is likely not used anywhere!
export const login = (credentials) => (dispatch) => {
    return dispatch({
        type: authConstants.LOGIN,
        payload: authApi.login(credentials)
            .then((response) => authSuccess(response)).catch((error) => authError(error))
    });
};

 export const signup = (credentials) => (dispatch) => {
     return dispatch({
         type: authConstants.SIGNUP,
         payload: authApi.signup(credentials)
             .then((response) => authSuccess(response))
     });
 };

export const changePassword = (credentials) => (dispatch) => {
    return dispatch({
        type: authConstants.CHANGE_PASSWORD,
        payload: authApi.change_password(credentials)
            .then((response) => authSuccess(response))
    });
};

export const updatePassword = (credentials) => (dispatch) => {
    return dispatch({
        type: authConstants.UPDATE_PASSWORD,
        payload: authApi.updatePassword(credentials)
        .then((response) => {
                let state = store.getState();
                if (response.errors){
                    state.form.passwordChangeForm.syncErrors.user['temporary_password'] = response.errors;
                    return false;
                }
                else{
                   return response;
                }
            })
    });
};

export const authorize = (token) => {
    storage.set('token', token);
    const user = parseJWT(token) || {};
    storage.set('user', user);

    return {
        type: authConstants.AUTHORIZE,
        payload: {
            token,
            user
        }
    }
};

export const logout = (redirect = '/') => (dispatch) => {
    storage.remove('token');

    browserHistory.push(redirect);

    return dispatch({
        type: authConstants.LOGOUT
    });
};

export const authError = (response) => {
    let state = store.getState();
    console.error(response);
    if (response && response.data)
        state.authReducer.error = response.data.error.message || "Error";
    throw new SubmissionError({"_error":response.data.error.message});
};

export const authSuccess = (response) => {
    let current_user = storage.get('user');
    // const currentLocation = browserHistory.getCurrentLocation();
    let redirectTo = "";
    if (current_user.reset_password_tokan){
        redirectTo = `/update_password`;
        browserHistory.push(redirectTo);
    } else {
        browserHistory.push(redirectTo);
        // We reload here to refresh the root tree so the experience reflects the logged in users experiments
        window.location.reload();
    }

    return response.data
};