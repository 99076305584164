import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import {DeviceAssignment} from "./model/DeviceAssignment";


const convertApiResponseToDeviceAssignment = (response) => {
    return new DeviceAssignment(
        response['id'],
        response['area_id'],
        response['asset_id'],
        response['device_id'],
        response['status'],
        response['active_date'],
        response['released_date'],
        response['created_by_id'],
        response['updated_by_id'],
        response['created_at'],
        response['updated_at'],
        response['device'],
        response['asset']
    )
}


export const listDeviceAssignments = (data) => {
    return axios.get(`${BASE_URL()}/api/v1/device_assignments`, {
        params: data,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data.items.map(item => convertApiResponseToDeviceAssignment(item)));
};

export const getDeviceAssignment = (id) => {
    return axios.get(`${BASE_URL()}/api/v1/device_assignments/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    }).then(response => convertApiResponseToDeviceAssignment(response.data));
};

export const createDeviceAssignment = (data) => {
    return axios.post(`${BASE_URL()}/api/v1/device_assignments`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    }).then(response => convertApiResponseToDeviceAssignment(response.data));
};

export const updateDeviceAssignment = (id, data) => {
    return axios.put(`${BASE_URL()}/api/v1/device_assignments/${id}`, data, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    }).then(response => convertApiResponseToDeviceAssignment(response.data));
};

export const deleteDeviceAssignment = (id) => {
    return axios.delete(`${BASE_URL()}/api/v1/device_assignments/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    })
        .then(response => ({id: id}));
};