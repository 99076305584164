import React from 'react';
import {Link} from 'react-router';
import {connect} from 'react-redux';
import ReactTable from 'react-table';
import swal from 'sweetalert';
import {isAdmin} from '../../utils/utils';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";

class AlertListView extends React.Component {

    constructor(props) {
        super(props);
        this.deleteAlert = this.deleteAlert.bind(this);
        this.bulkDeleteAlert = this.bulkDeleteAlert.bind(this);
        this.state = {
            selectedRows: {},
            columns: [], // react table columns definition
            loading: false
        }
    }

    componentDidMount() {
        const isDisabled = isAdmin(this.props.auth.user) ? false : true;
        const columns = isDisabled ? [] : [{
            Header: this.renderBulkSelect(),
            accessor: 'checkAction', columnName: ' ', width: 75,
            sortable: false,
            Cell: (row) => this.styleCheckboxCell(row.value, null),
        }]
        columns.push(
            {
                Header: '',
                accessor: 'toggleAction', columnName: ' ', width: 150,
                sortable: false,
                Cell: (row) => this.styleToggleCell(row.value, null, isDisabled)
            },
            {
                Header: () => this.styleCell('Alert Title', null),
                accessor: 'title',
                columnName: 'Alert Title',
                Cell: (row) => this.styleTitleCell(row.value, 'text-blue'),
                filterable: true,
                filterMethod: (filter, row) => {
                    const id = filter.pivotId || filter.id
                    return row[id] !== undefined ? String(row[id].title.toLowerCase()).includes(filter.value.toLowerCase()) : true
                },
                Filter: ({ filter, onChange }) =>
                    <input
                        onChange={event => onChange(event.target.value)}
                        style={{ margin: "auto", width: "50%"}}
                        value={filter ? filter.value : ""}
                        className="form-control"
                        placeholder="Search..."
                    />

            },
            {
                Header: () => this.styleCell('Name', null),
                accessor: 'name',
                columnName: 'Name',
                Cell: (row) => this.styleCell(row.value, 'text-center')
            },
            {
                Header: () => this.styleCell('Actions', null),
                accessor: 'deleteAction',
                columnName: 'Actions',
                sortable: false,
                Cell: (row) => this.styleDeleteActionCell(row.value, 'text-center', isDisabled)
            },
        );
        this.setState({...this.state, columns});
    }

    componentWillReceiveProps(nextProps) { // Deprecated: useEffect() watching for props.items can be used instead
        if (nextProps.items.length !== this.props.items.length) { // just looking for delete changes
            // for add & change, component is being re-created and so we lose any selections...
            this.setState({selectedRows: {}, bulkSelect: false, loading: false});
        }
    }

    styleCell = (label, classes) => {
        if (classes)
            return <div className="alert-table-text-cell justify-content-center">{label}</div>
        return <div>{label}</div>
    }

    styleTitleCell = (value, classes) => {
        return (
            <div className="alert-table-text-cell justify-content-center">
                <Link to={`/alerts/${value.trigger_id}/edit`}>
                    <span className="text-blue">{value.title}</span>
                </Link>
            </div>
        )
    }
    styleOwnerCell = (value, classes) => {
        return (
            <div>
                {value.name}<br/>{value.email}
            </div>
        )
    };

    styleDeviceCell = (value, classes) => {
        return (
            <div>
                {value.device_name}<br/>{value.email}
            </div>
        )
    }
    styleDeleteActionCell = (value, classes, isDisabled = false) => {
        const handleClick = (e) => {
            if (isDisabled) e.preventDefault();
            else this.deleteAlert(value);
        }
        return (
            <div className={classes}>
                <a onClick={handleClick.bind(this)}>
                    <img src={require("../../styles/images/icon/icn_trash.png")} alt="delete"/>
                </a>
            </div>
        )
    }
    styleToggleCell = (alert, classes, isDisabled = false) => {
        const disabledClass = isDisabled ? "disabled" : "";
        return (
            <div className="text-center">
                <label className="emp_switch">
                    <input checked={alert.active} type="checkbox" disabled={isDisabled} onChange={event => {
                        this.props.onActiveChange(alert, event.target.checked);
                    }}
                    />
                    <span className={`emp_slider round ${disabledClass}`}></span>
                </label>
            </div>
        )

    }
    styleCheckboxCell = (id, classes) => {
        return (
            <div className="table_checkbox_container">
                <input
                    checked={this.state.selectedRows[id] || false}
                    type="checkbox"
                    className="table_checkbox"
                    onChange={event => {
                        const selections = {...this.state.selectedRows};
                        if (selections[id])
                            delete selections[id]
                        else
                            selections[id] = true;
                        this.setState({
                            selectedRows: selections,
                        })
                    }}
                />
            </div>
        )
    }

    async confirmDeleteAlert(msg) {
        let confirm = await swal({
            title: msg,
            text: "Are you sure?",
            buttons: {
                cancel: {
                    text: "Cancel",
                    value: false,
                    visible: true,
                    className: "btn btn-default class4"
                },
                confirm: {
                    text: "OK",
                    value: true,
                    visible: true,
                    className: "btn btn-primary ",
                    closeModal: true
                }
            }
        })
        return confirm;
    }

    async deleteAlert(alert) {
        let confirm = await this.confirmDeleteAlert(`Delete Alert ${alert.title}`)
        if (confirm) {
            this.setState({...this.state, loading: true});
            this.props.onDeleteAlert(alert.trigger_id);
        }
    }

    async bulkDeleteAlert() {
        let confirm = await this.confirmDeleteAlert(`Delete Selected Alerts`)
        if (confirm) {
            this.setState({...this.state, loading: true});
            let triggerIds = Object.keys(this.state.selectedRows);
            this.props.onDeleteAlerts(triggerIds);
        }
    }

    updateSelectedRows = (checked) => {
        let selections = {}
        if (checked)
            this.props.items.forEach(alert => selections[alert.trigger_id] = true)
        this.setState({
            selectedRows: selections,
            bulkSelect: checked
        })
    }

    render() {
        return (
            <div className="table-wrapper">
                {this.renderBulkSelectActions()}

                <div className="table-responsive-sm">
                    {this.state.loading ? <LoadingMessage/> :
                    <ReactTable
                        columns={this.state.columns}
                        data={this.createTableListItems()}
                        className='-striped'
                        showPagination={true}
                        minRows={5}
                    />}
                </div>
            </div>
        );
    }

    renderBulkSelectActions() {
        return <>
            {isAdmin(this.props.auth.user) &&
            <div className="mb-2 d-flex align-items-center">
                {Object.keys(this.state.selectedRows).length ?
                    <a className="ml-4" onClick={() => this.bulkDeleteAlert()}>
                        <img src={require("../../styles/images/icon/trash-dodgerblue.png")} alt="delete"/>
                    </a> : null
                }
            </div>
            }
        </>;
    }

    renderBulkSelect() {
        return (
            <>
                {isAdmin(this.props.auth.user) && <div className="table_checkbox_container mb-1">
                    <input
                        checked={this.state.bulkSelect} type="checkbox"
                        className="table_checkbox"
                        onChange={() => this.updateSelectedRows(!this.state.bulkSelect)}
                    />
                </div>
                }
            </>

        );
    }

    createTableListItems() {
        // To Do: this should be maybe kept in state so not re-mapped on each render?
        const tableData = this.props.items.map((alert, index) => {
            return (
                {
                    title: {title: alert.title, trigger_id: alert.trigger_id},
                    name: alert.devices.length > 0 ? alert.devices[0].name : '',
                    deleteAction: {title: alert.title, trigger_id: alert.trigger_id},
                    toggleAction: alert,
                    checkAction: alert.trigger_id,
                }
            )
        });
        return tableData.sort(function(a,b){
            if(a.title.title < b.title.title) { return -1; }
            if(a.title.title > b.title.title) { return 1; }
            return 0;
        });
    }

}

const mapStateToProps = (state) => ({
    auth: state.authReducer
});

AlertListView = connect(mapStateToProps, {})(AlertListView);

export {AlertListView};
