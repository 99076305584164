import React from "react";
import FieldTypes from "../FormFields";
import { FieldInstanceProps, FormInstanceProps, FormTemplateProps } from "../../../formSchema";
import { mapToFieldInstances } from "../../../utils/formUtils";

type FieldGroupProps = {
    instance: FieldInstanceProps;
    groupInstances: FieldInstanceProps[];
    standardYield?: string
    onChange: ({ id, value }: { id: string; value: string }) => void;
};

export const FieldGroup = ({
  instance,
  groupInstances,
  standardYield,
  onChange
}: FieldGroupProps) => {
  // Test groups
  if (instance.field.field_name.includes('test')) {
    return (
      <div className="row row-cols-5">
        {groupInstances.map((instance, index) => (
          <div className="px-2">
            <FieldTypes
              instance={instance}
              key={index}
              classes={'test-col'}
              onChange={onChange}
            />
          </div>
        ))}
      </div>
    )
  }

  // If orientation is vertical, it should be a full width group
  const colSize = Math.round(12 / groupInstances.length)
  const colClass = `col-sm-${colSize}`
  const tableHeaderClass = instance.field.field_name.includes('table-heading') ? 'tw-bg-[#E6EEFE]' : ''
  const verticalFormGroup = instance.field.orientation === 'vertical' ? 'flex-column align-items-start' : ''

  return (
    <div className={`${tableHeaderClass} ${verticalFormGroup} form-group my-n2`}>
      {groupInstances.map((instance) =>
        instance.field.field_type === 'group' ? (
          <FieldGroup
            key={instance.id}
            instance={instance}
            groupInstances={mapToFieldInstances(
              groupInstances,
              instance.field.children
            )}
            standardYield={standardYield}
            onChange={onChange}
          />
        ) : (
          <FieldTypes
            key={instance.id}
            classes={colClass}
            instance={instance}
            onChange={onChange}
          />
        )
      )}
    </div>
  )
}