import axios from 'axios';
import store from '../store/store';
import storage from "../utils/storage";
import {BASE_URL} from '../config';


export const createWifiNetwork = (values) => {
    let state = store.getState();
    if (!values['wifi_network']['organization_id'])
        values['wifi_network']['organization_id'] = state.authReducer.user.organization_id;
    if (values['wifi_network']['id']){
        return axios.put(`${BASE_URL()}/api/v1/wifi_networks/${values['wifi_network']['id']}`, values,{ headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response => {
            return response.data;
        });
    }
    else{
        return axios.post(`${BASE_URL()}/api/v1/wifi_networks`, values,{ headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response => {
            return response.data;
        });
    }

};

export const fetchWifiData = () => {
    return axios.get(`${BASE_URL()}/api/v1/wifi_networks`,  {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response => response.data);
};

export const deleteWifi = (id) => {
    return axios.delete(`${BASE_URL()}/api/v1/wifi_networks/${id}`,  {
        defaultErrorHandler: true,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response => response.data);
};
