import React, {Component} from 'react';
import {Link} from 'react-router';
import {Feature} from '@empiric/empiric-flagged-react';

const AlertCard: React.FC<{
    to: string,
    imageSrc?: string,
    altText: string,
    cardText: string,
    style: Record<string, any>,
    circleColor?: string,
    classNames?: string
}> = ({to, imageSrc, altText, cardText, style, circleColor, classNames}) => (
    <div className="col-xl-3 col-lg-6 col-md-6 col-sm-12">
        <Link to={to}>
            <div className="card text-center border-0 emp_circle_card">
                <div className="card-body">
                    <div className={`emp_circle emp_shadow1 wow rotateIn ${circleColor}`} style={style}>
                        {imageSrc ? <img src={require(`../../styles/images/icon/${imageSrc}`)} alt={altText}/>
                            : <i
                                className={classNames}></i>
                        }
                    </div>
                    <div className="clearfix"></div>
                    <p className="card-text wow fadeInUp">{cardText}</p>
                </div>
            </div>
        </Link>
    </div>
);

export class CreateAlertPage extends Component {
    render() {
        const inStyle: Record<string, any> = {
            visibility: 'visible',
            animationName: 'rotateIn'
        };

        return (
            <main className="app-content">
                <section className="emp_org_settings_sec emp_report_sec emp_circle_box_sec">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="app-title border-bottom">
                                <div>
                                    <h1>Create an Alert</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-10">
                            <h3 className="emp_heading">Choose an alert type to configure</h3>
                            <div className="row">
                                <AlertCard to="temperature_alert" imageSrc="icn_temp.png" altText="Temperature"
                                           cardText="Temperature" style={inStyle} circleColor="em_circle_orange"/>
                                <AlertCard to="humidity_alert" imageSrc="ic_percentage.png" altText="Humidity"
                                           cardText="Humidity" style={inStyle} circleColor="em_circle_orange"/>
                                <Feature name="geofenceExperiment">
                                    <AlertCard to="geofence_alert" imageSrc="icn_truck.png" altText="Geofence"
                                               cardText="Geofence" style={inStyle} circleColor="em_circle_yellow"/>
                                </Feature>
                                <AlertCard to="connection_alert" imageSrc="ic_wifi.png" altText="Connection"
                                           cardText="Connection" style={inStyle} circleColor="em_circle_yellow"/>
                                <AlertCard to="battery_alert" imageSrc="icn_battery.png" altText="Battery Level"
                                           cardText="Battery Level" style={inStyle} circleColor="em_circle_yellow"/>
                                <AlertCard to="metric_alert" altText="Metric" cardText="Metric"
                                           style={{...inStyle, color: "white"}}
                                           circleColor="em_circle_yellow" classNames="fa fa-2x fa-chart-bar"/>
                            </div>
                            <div className="clearfix"></div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}
