import axios from 'axios';
import {BASE_URL} from '../config';
import storage from "../utils/storage";
import * as Moment from 'moment';
import * as utils from "../utils/utils";
import {
    convertLocationValueToCoordinates,
    convertTemperature,
    MeasurementUnit, SystemOfUnits,
} from "../utils/formatHelper";

export function getByClientId(values, values_format=null) {
    let {start_date, end_date, limit} = values.query_params;
    let format = 'YYYY-MM-DD';

    let data = {
        'start_time': start_date ? Moment.utc(start_date).format(format) : null,
        'end_time': end_date ? Moment.utc(end_date).format(format) : null,
        'limit': limit || 1000
    };


    return axios.get(`${BASE_URL()}/api/v1/incident`, {
        params: data,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        return response.data.items.map(item => IncidentHistoryModel.fromJson(item, values_format))
    });
}

export function getById(values, values_format=null) {
    let {incident_id} = values;


    return axios.get(`${BASE_URL()}/api/v1/incident/${incident_id}`, {

        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        let incident = response.data.items[0];
        return IncidentHistoryModel.fromJson(incident, values_format)
    });
}


export class IncidentHistoryModel {
    trigger_id;
    timestamp;
    client_id;
    detail_type;
    detail;
    title;
    description;
    metric;
    status;
    trigger_data_points;
    user;
    incident_id;
    report_id;
    device_name;
    trigger;

    constructor(trigger_id, timestamp, client_id, detail_type, detail, title, description, metric, status, trigger_data_points, user, incident_id, report_id, device_name = "", trigger = null) {
        this.trigger_id = trigger_id;
        this.timestamp = timestamp;
        this.client_id = client_id;
        this.detail_type = detail_type;
        this.detail = detail;
        this.title = title;
        this.description = description;
        this.metric = metric;
        this.status = status;
        this.trigger_data_points = trigger_data_points;
        this.user = user;
        this.incident_id = incident_id;
        this.report_id = report_id;
        this.device_name = device_name
        this.trigger = trigger;
    }

    static fromJson(json, format=null) {
        let detailModel = DetailMap.fromJson(json.detail);

        if (utils.isTemperatureField(json.trigger.field)) {
            const desiredUnits = format === SystemOfUnits.Imperial ? MeasurementUnit.Fahrenheit : MeasurementUnit.Celsius;

            detailModel.state.stateReasonData.recent_datapoints = detailModel.state.stateReasonData.recent_datapoints.map(datapoint => {
                return convertTemperature(Number(datapoint), MeasurementUnit.Celsius, desiredUnits, 2);
            })
            json.trigger.conditions = json.trigger.conditions.map(condition => {
                condition.threshold = convertTemperature(Number(condition.threshold), MeasurementUnit.Celsius, desiredUnits, 2);
                return condition;
            });

            detailModel.state.stateReasonData.thresholds = detailModel.state.stateReasonData.thresholds.map(threshold =>  {
                return convertTemperature(Number(threshold), MeasurementUnit.Celsius, desiredUnits, 2);
            });
        } else if (json.trigger.schema === 'geofence') {
            detailModel.state.stateReasonData.recent_datapoints = convertLocationValueToCoordinates(detailModel.state.stateReasonData.recent_datapoints);
        }


        return new IncidentHistoryModel(
            json.trigger_id,
            json.timestamp,
            json.client_id,
            json.detail_type,
            detailModel,
            json.title,
            json.description,
            json.metric,
            json.status,
            json.trigger_data_points,
            json.user,
            json.incident_id,
            json.report_id,
            json.name,
            json.trigger)
    }
}

class StateValue {
    static INSUFFICIENT_DATA = "INSUFFICIENT_DATA";
    static OK = "OK";
    static  ALARM = "ALARM"
}

export class DetailMap {
    state;
    previous_state;

    constructor(state, previous_state) {
        this.state = state;
        this.previous_state = previous_state;
    }

    static fromJson(json) {
        let previousState;
        if (!json.previous_state) {
            previousState = null;
        } else {
            previousState = StateMap.fromJson(json.previous_state)
        }
        return new DetailMap(
            StateMap.fromJson(json.state),
            previousState
        )
    }
}


export class StateMap {
    constructor(value, reason, timestamp, stateReasonData) {
        this.value = StateValue[value];
        this.reason = reason;
        this.timestamp = timestamp;
        this.stateReasonData = stateReasonData;
    }

    static fromJson(json) {
        let stateReasonData = StateReasonData.fromJson(json.state_reason_data);
        return new StateMap(StateValue[json.value], json.reason, json.timestamp, stateReasonData);
    }
}

class StateReasonData {
    version;
    query_date;
    start_date;
    statistic;
    period;
    recent_datapoints;
    threshold;
    thresholds;

    constructor(version, query_date, start_date, statistic, period, recent_datapoints, threshold, thresholds) {
        this.version = version;
        this.query_date = query_date;
        this.start_date = start_date;
        this.statistic = statistic;
        this.period = period;
        this.recent_datapoints = recent_datapoints;
        this.threshold = threshold;
        this.thresholds = thresholds;
    }

    static fromJson(json) {
        return new StateReasonData(json.version,
            json.query_date,
            json.start_date,
            json.statistic,
            json.period,
            json.recent_datapoints,
            json.threshold,
            json.thresholds);
    }
}




