import React from 'react';
import {connect} from 'react-redux';
import ReactTable from 'react-table';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import {bindActionCreators} from "redux";
import * as ProbesActions from "../../actions/probesActions";
import * as Moment from "moment";

class ProbesListView extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            columns: [], // react table columns definition
            loading: false,
        }
        this.renderListView = this.renderListView.bind(this);
    }

    componentDidMount() {
        const columns = [];
        columns.push(
            {
                Header: () => this.styleCell('Probe Identifier', null),
                accessor: 'probe_identifier',
                columnName: 'Probe Identifier',
                Cell: (row) => this.styleCell(row.value, 'text-center')
            },
            {
                Header: () => this.styleCell('Probe Manufacturer', null),
                accessor: 'probe_manufacturer',
                columnName: 'Probe Manufacturer',
                Cell: (row) => this.styleCell(row.value, 'text-center')
            },
            {
                Header: () => this.styleCell('Probe Certificate Date', null),
                accessor: 'probe_cert_date',
                columnName: 'Probe Certificate Date',
                Cell: (row) => this.styleCertDateCell(row.value, 'text-center')

            },
            {
                Header: () => this.styleCell('Certificate Url', null),
                accessor: 'certificate_uri',
                columnName: 'Certificate Url',
                Cell: (row) => this.styleCertUrlCell(row.value, 'text-center')
            },
            {
                Header: () => this.styleCell('Device', null),
                accessor: 'device_name',
                columnName: 'Device',
                Cell: (row) => this.styleCell(row.value, 'text-center')
            },
            {
                Header: () => this.styleCell('Status', null),
                accessor: 'status',
                columnName: 'Status',
                Cell: (row) => this.styleCell(row.value, 'text-center')
            }
        );
        this.setState({...this.state, columns});
    }

    componentWillReceiveProps(nextProps) { // Deprecated: useEffect() watching for props.items can be used instead
        if (nextProps.items.length !== this.props.items.length) { // just looking for delete changes
            // for add & change, component is being re-created and so we lose any selections...
            this.setState({selectedRows: {}, bulkSelect: false, loading: false});
        }
    }

    styleCell = (label, classes) => {
        if (classes)
            return <div className="alert-table-text-cell justify-content-center">{label}</div>
        return <div>{label}</div>
    }

    styleCertDateCell = (label, classes) => {
        if (classes)
            return <div className="alert-table-text-cell justify-content-center">{Moment(label).local().format("MM-DD-YYYY")}</div>
        return <div>{Moment(label).local().format("MM-DD-YYYY")}</div>
    }

    styleCertUrlCell = (label, classes) => {
        if (!label.hasOwnProperty("url") || !label.url){
            return null;
        }
        if (classes)
            return <div className="alert-table-text-cell justify-content-center">{<a href={label.url} target="_blank">Certificate</a>}</div>
        return <a href={label.url} target="_blank">Certificate</a>;
    }

    renderListView() {
        return (
            <ReactTable
                columns={this.state.columns}
                data={this.createTableListItems()}
                className='-striped'
                showPagination={true}
                minRows={5}
            />)
    }

    render() {

        return (
            <div className="table-wrapper">
                <div className="table-responsive-sm">
                    {this.state.loading ? <LoadingMessage/> : this.renderListView()}
                </div>
            </div>
        );
    }

    createTableListItems() {
        const tableData = this.props.items.map((probe, index) => {
            return (
                {
                    id: probe.id,
                    probe_identifier: probe.probe_identifier,
                    probe_manufacturer: probe.probe_manufacturer,
                    probe_cert_date: probe.probe_cert_date,
                    certificate_uri: probe.certificate_uri,
                    device_name: probe.device_name,
                    status: probe.status
                }
            )
        });

        tableData.sort((a, b) => {
            if (!a.identifier) return 1;
            if (!b.identifier) return 1;

            const nameA = a.identifier.toUpperCase();
            const nameB = b.identifier.toUpperCase();
            if (nameA < nameB) {
                return -1;
            }
            if (nameA > nameB) {
                return 1;
            }

            return 0;
        });

        return tableData;
    }

}

const mapStateToProps = (state) => ({
    auth: state.authReducer
});

const mapDispatchToProps = (dispatch) => ({
    probesActions: bindActionCreators(ProbesActions, dispatch),
});

ProbesListView = connect(mapStateToProps, mapDispatchToProps)(ProbesListView);

export {ProbesListView};
