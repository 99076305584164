import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as WorkflowScheduleActions from "../../actions/workflowScheduleActions";
import * as ScheduledJobActions from "../../actions/scheduledJobAction";
import * as WorkflowActions from "../../actions/workflowActions";
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {withRouter} from 'react-router';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import WorkflowScheduleDetailPage from "../../components/Workflow/WorkflowScheduleDetailPage";

export class WorkflowScheduleDetailContainer extends React.Component<{
    params,
    workflowSchedules,
    workflowScheduleActions,
    workflowActions,
    scheduledJobReducer,
    scheduledJobActions,
    authActions,
    auth
},{
    loading: boolean
}>{
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    async componentDidMount() {
        this.setState({loading: true});
        const scheduleId = this.props.params.scheduleId;
        const {selectedWorkflow} = this.props.workflowSchedules;
        this.props.scheduledJobActions.clearScheduledJobs();
        const promises = [this.props.scheduledJobActions.listScheduledJobs(scheduleId)];
        if (!selectedWorkflow || selectedWorkflow.id !== scheduleId) {
            promises.push(this.props.workflowScheduleActions.getWorkflowSchedule(scheduleId));
        }
        const results = await Promise.all(promises).catch(e => console.log(e));
        let workflowId;

        if(!results){
            return this.setState({loading: false});
        }

        results.forEach(res => {
            if (res.value.hasOwnProperty("workflow_id")) {
                workflowId = res.value.workflow_id;
            }
        });
        this.props.workflowActions.getWorkflow(workflowId);
        this.setState({loading: false});
    }

    async loadMoreJobs(){
        if (!this.props.scheduledJobReducer.cursor) {
            return;
        }
        const scheduleId = this.props.params.scheduleId;
        await this.props.scheduledJobActions.listScheduledJobs(scheduleId, this.props.scheduledJobReducer.cursor);
    }

    render() {
        const {auth, authActions} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <WorkflowScheduleDetailPage
                        scheduleId={this.props.params.scheduleId}
                        loadMoreJobs={this.loadMoreJobs.bind(this)}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    workflowSchedules: state.workflowScheduleReducer,
    scheduledJobReducer: state.scheduledJobReducer
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    workflowScheduleActions: bindActionCreators(WorkflowScheduleActions, dispatch),
    scheduledJobActions: bindActionCreators(ScheduledJobActions, dispatch),
    workflowActions: bindActionCreators(WorkflowActions, dispatch),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(WorkflowScheduleDetailContainer));