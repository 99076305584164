import React, {ReactElement} from 'react';
import {change, Field, formValueSelector, reduxForm, Form} from 'redux-form';
import {connect} from "react-redux";
import {DateTimeField} from '../DateTimeField/NewDateTimeField';
import {IntervalType} from '../../api/model/WorkflowSchedule'
import {SelectField} from "../../ui/Input/SelectField";
import {TextField} from "../../ui/Input/TextField";
import {WorkflowModel, WorkflowParamsInterface, UiSchemaInterface} from "../../api/model/Workflow";
import {WorkflowParamsFieldFactory} from "./WorkflowParamsFieldFactory";
import FormRadioButtonComponent from "./FormRadioButtonComponent";
import CronJobBuilder from "./CronJobBuilder";
import {required, validateDateTimeFormat} from "../../utils/validateFunctions";


const getInputComponent = (inputProps, label) => <TextField label={label} {...inputProps}/>

const titleField = ({input, label, meta: {touched, error}}) => {
    return (
        <TextField label={label} error={touched && error} errorLabel={error} {...input}/>
    );
}

export class WorkflowScheduleForm extends React.Component<{
    workflows: WorkflowModel[],
    intervalType: Object,
    workflow_params: { properties: Object, required: string[] },
    dispatch: Function,
    workflowReducer: Object,
    onWorkflowChange: (e) => void,
    selectedWorkflow: WorkflowModel,
    handleSubmit: Function,
    onFormSubmit: (e) => void,
    buttonText: string,
}, {
    intervalType: string,
}> {
    constructor(props) {
        super(props);
        const intervalType = props.initialValues?.interval_type ? props.initialValues.interval_type : null;
        this.renderSelectWorkflowField = this.renderSelectWorkflowField.bind(this);

        this.state = {
            intervalType: intervalType,
        }
    }

    renderField({input, className, disabled, type, placeholder, meta: {touched, error, submitFailed}}) {
        let fieldStyle = "";
        let errorMessage: React.ReactElement | undefined;
        if (touched && error) {
            fieldStyle = "input_error"
            errorMessage = <div className="form-group__error">{error}</div>
        }
        return (<div>
            <div>
                <input {...input} placeholder={placeholder} disabled={disabled}
                       className={`form-control textarea_modifier shadow_select_hover ${fieldStyle}`}
                       type={type}/>
            </div>
            <div>
                {errorMessage}
            </div>
        </div>)
    }

    selectIntervalField() {
        const items = [{value: IntervalType.SIMPLE, label: 'Simple Schedule'}, {
            value: IntervalType.CRON,
            label: 'Custom Schedule'
        }, {value: null, label: 'No Schedule'}]
        return <SelectField label={"Interval Type"}
                            onChange={this.handleIntervalTypeChange.bind(this)}
                            value={this.state.intervalType}
                            items={items}
        />
    }

    renderSelectComputeSize({input, label, items, meta: {touched, error}}) {
        return <div className={"pb-3"}><SelectField label={label}
                            placeholder=""
                            error={touched && error}
                            errorLabel={error}
                            value={input.value}
                            {...input}
                            items={items}
        /></div>
    }

    renderSelectWorkflowField({input, label, items, meta: {touched, error}}) {
        const onChangeHandler = (e, props) => {
            const selectedValue = e.target.value;
            const workflow = props.workflows.find(w => w.id === selectedValue);
            if (!workflow) {
                console.log("selected workflow could not be found in the workflow list", selectedValue);
                return null;
            }
            props.onWorkflowChange(workflow);
            input.onChange(selectedValue);
        }

        return <SelectField label={label}
                            placeholder=""
                            error={touched && error}
                            errorLabel={error}
                            value={input.value}
                            {...input}
                            items={items}
                            onChange={(e) => onChangeHandler(e, this.props)}
        />
    }

    handleIntervalTypeChange(e) {
        if (e.target.value === IntervalType.SIMPLE) {
            this.props.dispatch(change(WORKFLOW_SCHEDULE_FORM_NAME, 'interval', INTERVAL_SCHEDULE_OPTS[0].value));
            this.props.dispatch(change(WORKFLOW_SCHEDULE_FORM_NAME, 'interval_type', IntervalType.SIMPLE));
        } else if (e.target.value === IntervalType.CRON) {
            this.props.dispatch(change(WORKFLOW_SCHEDULE_FORM_NAME, 'interval', '* * * * *'));
            this.props.dispatch(change(WORKFLOW_SCHEDULE_FORM_NAME, 'interval_type', IntervalType.CRON));
        } else {
            this.props.dispatch(change(WORKFLOW_SCHEDULE_FORM_NAME, 'interval', null));
            this.props.dispatch(change(WORKFLOW_SCHEDULE_FORM_NAME, 'interval_type', null));
        }

        this.setState({intervalType: e.target.value});
    }

    renderSimpleIntervalFields() {
        return (
            <>
                <div className="form-group">
                    <FormRadioButtonComponent
                        name={"interval"}
                        items={INTERVAL_SCHEDULE_OPTS}
                        label={"Schedule"}/>
                </div>
            </>
        )
    }

    renderCronIntervalFields() {
        return (
            <>
                <div className="form-group">
                    <div
                        className={`emp-title-item`}>
                        <label className="label_modifier pl-0 mb-0 col-xl-6 col-lg-6 col-md-6 col-sm-6">Cron
                            Job Builder:</label>
                    </div>
                    <CronJobBuilder
                        dispatch={this.props.dispatch}
                    />
                </div>
            </>)
    }

    renderStartDateTimeField() {
        return (
            <div className="form-group pl-0 env-date date-field flex-center">
                <label
                    className="label_modifier col-sm-6 col-xl-6 col-lg-6 col-md-6 pl-0">Start
                    Date:</label>
                <DateTimeField
                    type="text"
                    name="start_date"
                    className="form-control input_modifier app-title-shadow"
                    parentClass="app-title-shadow"
                    renderInput={(props) => getInputComponent(props, "Start Date")}
                    validate={[required, validateDateTimeFormat]}
                />
            </div>)
    }

    renderIntervalFields() {
        return (<>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                    <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 pl-0 pr-0 pr-lg-3">
                        {this.selectIntervalField()}
                        <div className={"pt-3 pb-3"}>
                            {this.props.intervalType === IntervalType.SIMPLE && this.renderSimpleIntervalFields()}
                            {this.props.intervalType === IntervalType.CRON && this.renderCronIntervalFields()}
                            {this.renderStartDateTimeField()}
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-12  col-sm-12">
                    </div>
                </div>
            </>
        );
    }

    renderWorkflowScheduleForm() {
        if (!this.props.selectedWorkflow.hasOwnProperty("workflow_params")) return null;

        const workflowParams: WorkflowParamsInterface = this.props.selectedWorkflow.workflow_params;
        const uiSchema: UiSchemaInterface = this.props.selectedWorkflow.ui_schema;
        const factory = new WorkflowParamsFieldFactory(uiSchema, workflowParams);
        const fields: ReactElement[] = factory.createComponents(uiSchema, workflowParams);

        return (<>
            <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                <div className="form-group">
                    {/*<label className="label_modifier col-sm-12 pl-0">Title</label>*/}
                    <Field name="title" placeholder="Type in a title" label={"Title"}
                           component={titleField} type="input"/>
                </div>
                <div className="form-group">
                    {/*<label className="label_modifier col-sm-12 pl-0">Description</label>*/}
                    <Field name="description" placeholder="Type in a description"
                           component={titleField} type="input" label={"Description"}/>
                </div>
                {fields}
                <Field name={"compute_size"} label={"Compute Size"} items={COMPUTE_SIZE_OPTIONS}
                       component={this.renderSelectComputeSize}/>
            </div>
            {this.renderIntervalFields()}

        </>)
    }

    renderForm() {
        const {handleSubmit} = this.props;
        const options: object[] = this.props.workflows.map(workflow => {
            return {label: workflow.title, value: workflow.id}
        });

        return (
            <Form
                key="workflowForm"
                onSubmit={handleSubmit(this.props.onFormSubmit)}
                className="emp_create_report_form mt-3"
                noValidate
            >
                <div
                    className="card shadow emp-form-card mb-3 emp_create_report_card workflow_schedule_form">
                    <div className="card-header emp_create_report_header p-4">
                        <div className="form-group col-xl-6 col-lg-6 pl-0 pr-0 pr-lg-3">
                            {/*<div className="form-group col-xl-6 col-lg-6 pl-0 pr-0">*/}
                            {/*<label className="label_modifier col-sm-12 pl-0">Workflow Type</label>*/}
                            <Field name={"workflow_id"} label={"Workflow Type"} items={options}
                                   component={this.renderSelectWorkflowField}/>

                            {!this.props.selectedWorkflow.hasOwnProperty("workflow_params") &&
                                <label className="label_modifier col-sm-12 pl-0">Start by selecting
                                    a
                                    workflow type</label>}
                        </div>
                        <div
                            className="card-body emp_create_report_body pb-2 p-0 emp_create_notify_body">
                            <div className="row emp_v_pipe_box">
                                {this.renderWorkflowScheduleForm()}
                                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*padding is needed to make the date time picker visible on screen*/}
                <div className="active-device-btn text-right pb-40">
                    <a href="/workflows" className="create-group mr-4">
                        <img src={require("../../styles/images/icon/icn_close.png")}
                             alt="Icon"
                        />
                        <span className="ml-2">Cancel</span>
                    </a>
                    <button className="btn btn-next btn-primary emp-cst-btn emp-btn">
                            <span className="mr-2 emp_icn_middle">
                                <img
                                    src={require("../../styles/images/icon/ic_checking.png")}
                                    alt="Icon"
                                />
                            </span>
                        {this.props.buttonText}
                    </button>
                </div>
            </Form>
        )
    }

    render() {
        return this.renderForm();
    }
}

export const WORKFLOW_SCHEDULE_FORM_NAME = 'workflowScheduleForm';
export const INTERVAL_SCHEDULE_OPTS = [{label: "Daily", value: "@daily"}, {label: "Weekly", value: "@weekly"},
    {label: "Monthly", value: "@monthly"}, {label: "Yearly", value: "@yearly"}];
export const COMPUTE_SIZE_OPTIONS = [{label: "Small", value: "small"}, {label: "Medium", value: "medium"},
    {label: "Large", value: "large"}, {label: "X-Large", value: "xlarge"}, {label: "Default", value: "default"}];

const mapStateToProps = (state) => {
    const selector = formValueSelector(WORKFLOW_SCHEDULE_FORM_NAME);

    const intervalType = selector(state, 'interval_type');
    const workflow_params = selector(state, 'workflow_params');

    return {
        intervalType: intervalType,
        workflow_params: workflow_params || {},
        auth: state.authReducer,
        workflowReducer: state.workflowReducer,
    }
}

const validate = values => {
    const errors = {};
    const requiredFields = ["title", "description", "interval"];

    for (let field of requiredFields) {
        if (!values[field]) errors[field] = 'Required';
    }

    return errors
}

export default connect(mapStateToProps, {})(reduxForm({
    form: WORKFLOW_SCHEDULE_FORM_NAME,
    enableReinitialize: true,
    validate: validate,
})(WorkflowScheduleForm));
