import React from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';


export class MetricData {
    /**
     * Container for graphable metric data
     * @param metricName name of metric
     * @param timestamps list of timestamps for given metric
     * @param values list of values for given metric
     */
    constructor(metricName, timestamps, values) {
        this.metricName = metricName;
        this.timestamps = timestamps;
        this.values = values;
    }
}

export class MetricPlotBand {
    start_pos;
    end_pos;
    constructor(start_pos, end_pos) {
        this.start_pos = start_pos;
        this.end_pos = end_pos;
    }
}

export class MetricPlotLine {
    constructor(value) {
        this.value = value;
    }
}


export default class MetricGraph extends React.Component {

    constructor(props) {
        super(props);
        this.tickInterval = this.props.tickInterval || 5 * 60 * 1000;
    }

    setupOptions(title, metrics) {
        const series = this.setupSeries(metrics, this.props.yPlotLines)
        const xPlotBands = this.setupXPlotBands(this.props.xPlotBands);
        const yPlotLines = this.setupPlotLines(this.props.yPlotLines);
        
        return {
            chart: {
                zoomType: 'x'
            },

            time: {
                useUTC: false,
                timezoneOffset: new Date().getTimezoneOffset()
            },

            xAxis: {
                type: 'datetime',
                dateTimeLabelFormats: {
                    hour: '%I %p',
                    minute: '%I:%M %p'
                },
                tickInterval: this.tickInterval,
                gapSize: 2,
                plotBands: xPlotBands
            },

            yAxis: [{
                lineWidth: 1,
                labels: {
                    format: '{value}'
                },
                title: {
                    text: ''
                },
                plotLines: yPlotLines,
            }],
            tooltip: {
                backgroundColor: 'rgba(0,0,0,0.8)',
                borderColor: 'rgba(0,0,0,0.2)',
                style: {
                    color: 'white',

                },
                dateTimeLabelFormats: {
                    hour: '%A, %b %e, %I:%M %p',
                    minute: '%A, %b %e, %I:%M %p',
                },

                borderRadius: 4,
                borderWidth: 1,
                crosshairs: true,
            },

            plotOptions: {
                series: {
                    gapSize: 1,
                    marker: {
                        enabled: false
                    },
                    events: {
                        legendItemClick: function (event) {
                            return true

                        }
                    },
                    ordinal: false,
                    tickInterval: this.tickInterval,
                }
            },
            title: {
                text: title,
                align: 'left'

            },

            series: series
        };
    }

    styles = {
        lineColors: ['#0273eb', '#DEACE3', '#43dfdf']
    }

    setupSeries(metrics, yPlotLines) {
        const result = metrics.map((metric, index) => {
            return {
                name: metric.metricName,
                color: this.styles.lineColors[index % (this.styles.lineColors.length - 1)],
                lineWidth: '1.5',
                type: 'spline',
                gapSize: 1,
                data: metric.timestamps.map((timestamp, index) => [Moment.utc(timestamp).valueOf(), metric.values[index]]),
                marker: {radius: 3}
            }
        })

        // This adds a non-visible line for the min and maximum value in a yPlotLine. Highcharts has a bug where it will only
        // show the y-axis in range of original data, rather than data + plotLines.
        result.push({
            type: 'scatter',
            data: metrics[0].timestamps.map((timestamp, index) => [Moment.utc(timestamp).valueOf(), Math.max(...yPlotLines.map(plotLine => plotLine.value))]),
            marker: {enabled: false},
            showInLegend: false,
            showInToolTip: false,
            enableMouseTracking: false
        })

        result.push({
            type: 'scatter',
            data: metrics[0].timestamps.map((timestamp, index) => [Moment.utc(timestamp).valueOf(), Math.min(...yPlotLines.map(plotLine => plotLine.value))]),
            marker: {enabled: false},
            showInLegend: false,
            showInToolTip: false,
            enableMouseTracking: false
        })
        return result;
    }

    render() {
        const options = this.setupOptions(this.props.title, this.props.metrics);
        return (<div>
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>);
    }

    setupXPlotBands(plotBands) {
        if (plotBands) {
            return plotBands.map(band => {
                return {
                    color: '#F5F5F5', // Red
                    width: 2,
                    from: band.start_pos,
                    to: band.end_pos,
                    label: {
                        text: 'Evaluation Period',
                        style: {
                            color: '#606060'
                        }
                    }

                }
            })
        } else {
            return []
        }
    }

    setupPlotLines(plotLines) {
        if (plotLines) {

            return plotLines.map(band => {
                return {
                    color: '#EB4511', // Red
                    width: 2,
                    value: band.value,
                    label: {
                        text: 'Threshold',
                        style: {
                            color: '#EB4511'
                        }
                    }

                }
            })
        } else {
            return []
        }
    }
}

MetricGraph.propTypes = {
    title: PropTypes.string,
    metrics: PropTypes.array,
    xPlotBands: PropTypes.array,
    yPlotLines: PropTypes.array
}