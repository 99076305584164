import {shipmentContactsConstants} from "../constants/shipmentContacts.constants";


const initialState = {
    shipmentContacts: [],
};

export const shipmentContactsReducer = (state = initialState, action) => {
    switch (action.type) {
        case shipmentContactsConstants.CREATE_SHIPMENT_CONTACT_FULFILLED:
            state = {
                ...state,
                shipmentContacts: [...state.shipmentContacts, action.payload]
            };
            return state;
        case shipmentContactsConstants.QUERY_SHIPMENT_CONTACTS_DATA_FULFILLED:
            state = {
                ...state,
                shipmentContacts: action.payload
            };
            return state;
        case shipmentContactsConstants.DELETE_SHIPMENT_CONTACT_FULFILLED:
            state = {
                ...state,
                shipmentContacts: state.shipmentContacts.filter(contact=>contact.id !== action.payload.id)
            };
            return state;
        case shipmentContactsConstants.UPDATE_SHIPMENT_CONTACT_FULFILLED:
            state = {
                ...state,
                shipmentContacts: state.shipmentContacts.map(contact =>
                    contact.id === action.payload.id ? action.payload : contact
                ),
            };
            return state;
        default:
            return state;
    }
};