import {failureModeConstants} from '../constants/failureMode.constants';

const initialState = {
    failureModes: [],
    failureMode: {}
};

export const failureModeReducer = (state = initialState, action) => {
    switch (action.type) {
        case failureModeConstants.LIST_FAILURE_MODES:
        case failureModeConstants.LIST_FAILURE_MODES_FULFILLED:
            state = {
                ...state,
                failureModes: action.payload
            };
            return state;
        case failureModeConstants.GET_FAILURE_MODE:
        case failureModeConstants.GET_FAILURE_MODE_FULFILLED:
            state = {
                ...state,
                failureMode: action.payload
            };
            return state;
        default:
            return state;
    }
};