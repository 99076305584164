import React from 'react';
import {ListView} from "../../ui/ListView/ListView";
import {Link} from "react-router";
import {SearchInputField} from "../../ui/Input/SearchInputField";
import {AssetType} from "../../api/model/AssetType";


const renderTextRow = (label, text) => {
    return (
        <div className="row mt-2">
            <span className="col-6 col-sm-4 col-md-4 col-lg-3 emp-type-subtitle1">{label}</span>
            <span className={"col-6 col-sm col-md col-lg emp-type-subtitle1"}>{text}</span>
        </div>
    );
}



export class AssetTypesPage extends React.Component<{
    assetTypes: AssetType[],
    onItemClick: (item: AssetType) => void,
    onDeleteItemClick: (item: AssetType) => void,
    onSearchInput: (search: string) => void }> {
    renderAssetTypesListItem = (item, index) => {
        return <div className="container-fluid">
            <div className={"row"}>
                <div className={"col"}/>
                <div className="col-1">
                    <button className={"btn btn-link"} onClick={(e) => {
                        e.stopPropagation();
                        this.props.onDeleteItemClick(item);
                    }}><i
                        className="fa fa-trash" aria-hidden="true"></i></button>
                </div>
            </div>
            <div className="row">
                <div className="col-2">
                    <img className={"placeholder img-thumbnail"}
                         src={item.imageUrl || `https://via.placeholder.com/150?text=${item.name}`}/>
                </div>
                <div className="col">
                    {renderTextRow("Name", item.name)}
                    {renderTextRow("Description", item.description)}
                    {renderTextRow("Asset Category", item.assetCategory)}
                </div>
            </div>
        </div>
    }
    render() {
        const {assetTypes, onItemClick} = this.props;
        return (
            <main className="app-content">
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <h2 className="emp-type-h2">Asset Types</h2>
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col">
                            <Link to="/asset_types/new">
                                <button className="btn btn-primary emp-type-btn-primary">CREATE</button>
                            </Link>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12">
                            <SearchInputField label={"Search"} onChange={this.props.onSearchInput}/>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <ListView
                                datasource={assetTypes}
                                renderItem={this.renderAssetTypesListItem}
                                onItemClick={onItemClick}
                            />
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
