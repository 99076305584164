import {deviceConstants} from '../constants/device.constants.js';

const initialState = {
    device: {},
    devices: [],
    deactivated: false
};

export const deviceReducer = (state = initialState, action) => {
    switch (action.type) {

        case deviceConstants.FETCH_DEVICE_DATA_FULFILLED:
            state = {
                ...state,
                data: action.payload
                //event: action.payload.sponsor
            };
            return state;

        case deviceConstants.DEACTIVATE_DEVICE_FULFILLED:
            state = {
                ...state,
                deactivated: true
            };
            return state;

        case deviceConstants.DEVICE_FULFILLED:   // used for update device response
            let device = action.payload ? action.payload.device : {};
            state = {
                ...state,
                device: device
            };
            return state;
        case deviceConstants.GET_DEVICE_FULFILLED:  // retrieve device by id
            state = {
                ...state,
                device: action.payload
            };
            return state;
        case deviceConstants.SEARCH_DEVICE_DATA_FULFILLED:
            state = {
                ...state,
                devices: action.payload
            };
            return state;
        case deviceConstants.UPDATE_DEVICE_FULFILLED:
            state = {
                ...state,
                device: action.payload,
                devices: [
                    ...state.devices.filter(device => device.identity !== action.payload.identity),
                    action.payload
                ]
            }
            return state;
        default:
            return state;
    }
};