export const incidentReportConstants = {
    CLEAR_REPORT_FOR_EDIT: 'CLEAR_REPORT_FOR_EDIT',

    GET_REPORT: 'GET_REPORT',  
    GET_REPORT_FULFILLED: 'GET_REPORT_FULFILLED',

    NEW_REPORT: 'NEW_REPORT',
    NEW_REPORT_FULFILLED: 'NEW_REPORT_FULFILLED',
    
    SAVE_REPORT: 'SAVE_REPORT',
    SAVE_REPORT_FULFILLED: 'SAVE_REPORT_FULFILLED',
};
