import React from 'react';
import Tabs from "../../ui/Tabs/Tabs";
import {formatTimestampDateTime} from "../../utils/formatHelper";
import {Link} from "react-router";
import {SubAreasTabPage} from "./Tabs/SubAreasTabPage";
import {AreaAssignmentsTabPage} from "./Tabs/AreaAssignmentsTabPage";
import {AreaMap} from "./AreaMap";
import {Area} from '../../api/model/Area';
import {DeviceAssignment} from "../../api/model/DeviceAssignment";
import {TabWrapper} from '../../ui/Tab/TabWrapper';
import {Asset} from "../../api/model/Asset";

export class AreaDetailPage extends React.Component<{
    area: Area,
    devices: any[],
    onAssignItemClick: (item) => void,
    onAssignSubmit: (values) => Promise<void>,
    onDeleteAssignmentClick: (item) => void,
    onTabChange: (tab) => void
    onSubAreasItemClick: (item) => void,
    deviceAssignments: DeviceAssignment[],
    assets: Asset[]
}> {
    constructor(props) {
        super(props);
    }

    renderTextRow(label, text) {
        return (
            <div className="row mt-2">
                <span className="col-6 col-sm-4 col-md-4 col-lg-3 emp-type-subtitle1">{label}</span>
                <span className={"col-6 col-sm col-md col-lg emp-type-subtitle1"}>{text}</span>
            </div>
        );
    }

    renderLinkTextRow(label, text, url) {
        return (
            <div className="row mt-2">
                <span className="col-6 col-sm-4 col-md-4 col-lg-3 emp-type-subtitle1">{label}</span>
                <Link to={url}><span className={"col-6 col-sm col-md col-lg emp-type-subtitle1"}>{text}</span></Link>
            </div>
        );

    }

    renderTabs() {
        // @ts-ignore
        const tabs = [
            <TabWrapper key="1" className="emp-type-subtitle1" label="Summary">
                <div className={"mt-4"}>
                    {this.renderAreaCard()}
                </div>
            </TabWrapper>,
            this.props.area.children && this.props.area.children.length ?
                <TabWrapper key="2" label="Sub Areas" className="emp-type-subtitle1">
                    <SubAreasTabPage subAreas={this.props.area.children} onItemClick={this.props.onSubAreasItemClick}/>
                </TabWrapper> : null,
            <TabWrapper key="3" className="emp-type-subtitle1" label={"Assignments"}>
                <AreaAssignmentsTabPage
                    areaId={this.props.area.id}
                    onItemClick={this.props.onAssignItemClick}
                    onAssignSubmit={this.props.onAssignSubmit}
                    onDeleteAssignmentClick={this.props.onDeleteAssignmentClick}
                    deviceAssignments={this.props.deviceAssignments}
                    devices={this.props.devices}
                    assets={this.props.assets}
                /></TabWrapper>
        ].filter(tab => !!tab)
        return (
            <>
                <Tabs onChange={this.props.onTabChange}>
                    {tabs}
                </Tabs>
            </>
        )
    }

    render() {
        return (
            <main className="app-content">
                <section className="emp_org_settings_sec emp_report_sec emp_circle_box_sec">
                    <div className="card emp-box emp-shadow">
                        <div className={"bg-white container-fluid mb-4"}>
                            <div className={"row mt-2"}>
                                <h1 className={"emp-type-h2 col"}>{this.props.area.name}</h1>
                            </div>

                            <div className={"row"}>
                                <div className={"col"}></div>
                                <div className={"col-4 col-sm-3"}>
                                    <Link to={`/areas/${this.props.area.id}/edit`}>
                                        <button className={"btn-outline-primary"}><span
                                            className={"emp-type-btn-primary"}>Edit Area</span></button>
                                    </Link>
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    {this.renderTabs()}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        );
    }

    renderAreaCard() {
        const {area} = this.props;
        const imageUrl = area.imageUrl ? area.imageUrl : `https://via.placeholder.com/250?text=${area.areaType && area.areaType.name || 'Area'}`

        return (
            <div className="container-fluid">

                <div className="row mb-2">
                    <div className="col-12 col-sm-2"><img className="placeholder img-thumbnail"
                                                          src={imageUrl}></img>
                    </div>
                    <div className="col-12 col-sm-10">
                        <div className={"container-fluid"}>
                            {this.renderTextRow("Name", area.name)}
                            {this.renderTextRow("Area Type", area.areaType ? area.areaType.name : area.areaTypeId)}
                            {area.parent ? this.renderLinkTextRow("Parent", area.parent.name, `/areas/${area.parentId}`) : null}
                            {this.renderTextRow("Description", area.description)}
                            {this.renderTextRow("Created Date", formatTimestampDateTime(area.createdAt))}
                            {this.renderTextRow("Updated Date", formatTimestampDateTime(area.updatedAt))}
                        </div>
                    </div>
                </div>
                {area.bounds && area.bounds.geometry && <div className={"row mt-2 mb-2"}>
                    <div className={"col-12 col-sm-6"}>
                        <AreaMap coordinates={
                            area.bounds.geometry.coordinates
                        }/>
                    </div>
                </div>}
            </div>
        );
    }
}
