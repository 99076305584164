import React from "react";

export class AssetEventsTabPage extends React.Component {
    constructor(props) {
        super(props);

    }

    render() {
        return (
            <>
                Nothing to see here, this tab is <em>extinct</em>!
            </>
        )
    }

}