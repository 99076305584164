import {locationTelemetryConstants} from '../constants/locationTelemetry.constants';

const initialState = {
    updated: false,
    telemetryData: [],
};

export const locationTelemetryReducer = (state = initialState, action) => {
    switch (action.type) {
        case locationTelemetryConstants.GET_DEVICE_LOCATION_FULFILLED:
            state = {
                ...state,
                telemetryData: action.payload,
                updated: true
            };
            return state;

        case locationTelemetryConstants.LIST_DEVICE_LOCATION_FULFILLED:
            state = {
                ...state,
                telemetryData: action.payload,
                updated: true
            };
            return state;
        default:
            return state;
    }
};

