export const assetTypeConstants = {
    GET_ASSET_TYPE: 'GET_ASSET_TYPE',
    GET_ASSET_TYPE_FULFILLED: 'GET_ASSET_TYPE_FULFILLED',

    LIST_ASSET_TYPES: 'LIST_ASSET_TYPES',
    LIST_ASSET_TYPES_FULFILLED: 'LIST_ASSET_TYPES_FULFILLED',

    CREATE_ASSET_TYPE: 'CREATE_ASSET_TYPE',
    CREATE_ASSET_TYPE_FULFILLED: 'CREATE_ASSET_TYPE_FULFILLED',

    DELETE_ASSET_TYPE: 'DELETE_ASSET_TYPE',
    DELETE_ASSET_TYPE_FULFILLED: 'DELETE_ASSET_TYPE_FULFILLED',

    UPDATE_ASSET_TYPE: 'UPDATE_ASSET_TYPE',
    UPDATE_ASSET_TYPE_FULFILLED: 'UPDATE_ASSET_TYPE_FULFILLED',

    RESET_ASSET_TYPE: 'RESET_ASSET_TYPE',
    RESET_ASSET_TYPE_FULFILLED: 'RESET_ASSET_TYPE_FULFILLED'
};