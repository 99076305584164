import {WorkEventApi} from "../api/workEventApi";
import {workEventConstants} from "../constants/workEvents.constants";

const workEventApi = new WorkEventApi();

export const queryWorkEvents = () => {
    return {
        type: workEventConstants.QUERY_WORK_EVENTS_DATA,
        payload: workEventApi.queryWorkEvents()
    }
};

export const createWorkEvent = (workEvent) => ({
    type: workEventConstants.CREATE_WORK_EVENT,
    payload: workEventApi.createWorkEvent(workEvent)
});

export const getWorkEvent = (workEventId) => ({
    type: workEventConstants.GET_WORK_EVENT,
    payload: workEventApi.getWorkEvent(workEventId)
});

export const updateWorkEvent = (workEvent) => ({
    type: workEventConstants.UPDATE_WORK_EVENT,
    payload: workEventApi.updateWorkEvent(workEvent)
});
