import moment from 'moment';

export function isAdmin(user) {
    return user && (user.role === 'admin' || user.role === 'super_admin')
}

export function validateDateRange(values) {
    const errors = {};
    if (new Date(values["start_date"]) > new Date(values["end_date"])) {
        errors["start_date"] = "Start date must be before end date."
    }

    if (new Date(values["end_date"]) < new Date(values["start_date"])) {
        errors["end_date"] = "end date must be after start date."
    }
    return errors
}

export function validateDateString(dateStr, format="MM-DD-YYYY hh:mm A") {
    return moment(dateStr, format, true).isValid()
}

export function validateTimeString(timeStr, format="hh:mm A") {
    return moment(timeStr, format, true).isValid()
}

export function getTimeDifference(startDate, endDate, unitOfTime) {
    return moment(endDate).diff(moment(startDate), unitOfTime);
}

export function getDatedFileName({extension}) {
    let d = new Date();
    return (
        `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}-${d.getHours()}${d.getMinutes()}${d.getSeconds()}-${d.getMilliseconds()}.${extension}`
    )
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const temperatureFields = {
    'ambient_temperature': true,
    'ambient_temperature_max': true,
    'ambient_temperature_min': true,
    'probe_temperature': true,
    'probe_temperature_max': true,
    'probe_temperature_min': true,
};

/**
 * Hack for determining if the provided field is a temperature-based field
 * @param field
 * @returns {boolean}
 */
export function isTemperatureField(field) {
    return temperatureFields.hasOwnProperty(field);
}

export interface AxiosRequestConfig {
    [key: string]: any;
}

export function buildAxiosErrorHandlerConfig({
                                                 params,
                                                 headers,
                                                 defaultErrorHandler
                                             }: { params?: Record<string, any>, headers?: Record<string, any>, defaultErrorHandler?: boolean }): AxiosRequestConfig {
    return {
        params,
        headers,
        defaultErrorHandler
    };
}

type PickRandomColor = (colors: string[], usedColors: string[]) => string;
export const pickRandomColor: PickRandomColor = (colors, usedColors) => {
    if (colors.length === usedColors.length) {
        usedColors = [];
    }

    let newColor;
    do {
        newColor = colors[Math.floor(Math.random() * colors.length)];
    } while (usedColors.includes(newColor));

    usedColors.push(newColor);

    return newColor;
};