import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import {ShipmentContact} from "./model/ShipmentContact";


export class ShipmentContactsApi {
    constructor() {

    }

    async createShipmentContact(shipmentContact) {
        try {
            let response = await axios.post(`${BASE_URL()}/api/v1/shipment_contacts`, shipmentContact, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': storage.get('token')
                }
            });
            return ShipmentContact.fromJson(response.data.item);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async updateShipmentContact(shipmentContact) {
        try {
            let response = await axios.put(`${BASE_URL()}/api/v1/shipment_contacts/${shipmentContact.id}`, shipmentContact, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': storage.get('token')
                }
            });
            return ShipmentContact.fromJson(response.data.item);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async getShipmentContact(shipmentContactId) {
        try {
            let response = await axios.get(`${BASE_URL()}/api/v1/shipment_contacts/${shipmentContactId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': storage.get('token')
                }
            });
            return ShipmentContact.fromJson(response.data.item);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async deleteShipmentContact(shipmentContactId) {
        try {
            let response = await axios.delete(`${BASE_URL()}/api/v1/shipment_contacts/${shipmentContactId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': storage.get('token')
                }
            });
            return ShipmentContact.fromJson({id:shipmentContactId});
        } catch (e) {
            console.error(e);
            throw e;
        }
    }

    async queryShipmentContacts(limit=500, orderBy=["name"]) {
        try {
            let response = await axios.get(`${BASE_URL()}/api/v1/shipment_contacts`, {
                params: {
                    limit: limit,
                    order_by: orderBy
                },
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': storage.get('token')
                }
            });
            return response.data['items'].map(item => ShipmentContact.fromJson(item));
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}