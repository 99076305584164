import { customDashboardConstants } from '../constants/customDashboard.constants';
import * as customDashboardApi from "../api/customDashboardApi";
import { CustomDashboardModel } from "../api/model/CustomDashboard";
import { Dispatch } from 'redux';

interface Action {
    type: string;
    payload: any;
}

export const createCustomDashboard = (values: any) => (dispatch: Dispatch<Action>) => {
    return dispatch({
        type: customDashboardConstants.CREATE_CUSTOM_DASHBOARD,
        payload: customDashboardApi.createCustomDashboard(values)
    });
};

export const listCustomDashboards = () => (dispatch: Dispatch<Action>) => {
    return dispatch({
        type: customDashboardConstants.LIST_CUSTOM_DASHBOARDS,
        payload: customDashboardApi.listCustomDashboards()
    });
};

export const updateCustomDashboard = (dashboard: CustomDashboardModel) => (dispatch: Dispatch<Action>) => {
    return dispatch({
        type: customDashboardConstants.UPDATE_CUSTOM_DASHBOARD,
        payload: customDashboardApi.updateCustomDashboard(dashboard)
    });
};

export const deleteCustomDashboard = (id: string) => (dispatch: Dispatch<Action>) => {
    return dispatch({
        type: customDashboardConstants.DELETE_CUSTOM_DASHBOARD,
        payload: customDashboardApi.deleteCustomDashboard(id)
    });
};

export const deleteCustomDashboards = (dashboardIds: string[]) => (dispatch: Dispatch<Action>) => {
    return dispatch({
        type: customDashboardConstants.BULK_DELETE_CUSTOM_DASHBOARD,
        payload: customDashboardApi.deleteCustomDashboards(dashboardIds)
    });
};

export const getCustomDashboard = (dashboardId: string) => (dispatch: Dispatch<Action>) => {
    return dispatch({
        type: customDashboardConstants.GET_CUSTOM_DASHBOARD,
        payload: customDashboardApi.getCustomDashboard(dashboardId)
    });
};

export const selectCustomDashboard = (selectedDashboard: CustomDashboardModel) => ({
    type: customDashboardConstants.CHANGE_CUSTOM_DASHBOARD,
    payload: selectedDashboard
});
