import React from 'react';
import {bindActionCreators} from 'redux';
import {browserHistory, withRouter} from 'react-router';
import {connect} from 'react-redux';
import {reset} from "redux-form";

import * as AuthActions from '../../actions/authActions';
import * as DeviceActions from '../../actions/deviceAction';
import * as WorkflowActions from "../../actions/workflowActions";
import * as WorkflowScheduleActions from "../../actions/workflowScheduleActions";

import Header from '../../components/Header/Header';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {WorkflowSchedulePage} from "../../components/Workflow/WorkflowSchedulePage";
import {
    formatScheduleIntervalForServer,
    formatWorkflowParamsForServer,
    formatWorkflowParamsForEditForm,
    formatScheduleValuesForEditForm
} from "../../components/Workflow/FormValueConversionHelper";

export class EditWorkflowScheduleContainer extends React.Component<{
    workflowScheduleReducer,
    workflowActions,
    workflowReducer,
    workflowScheduleActions,
    deviceActions,
    params,
    auth,
    authActions,
    onFormSubmit: (values: any, dispatch: any) => Promise<void>,
    devices,
}, {
    loading: boolean,
    initialValues: object
}> {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            initialValues: {},
        }
        this.onWorkflowFormSubmit = this.onWorkflowFormSubmit.bind(this);
    }

    async componentDidMount() {
        this.setState({...this.state, loading: true});
        let workflow;
        if (this.props.workflowScheduleReducer.selectedWorkflowSchedule.id) {
            await this.props.deviceActions.searchDevices({});
            if (!this.props.workflowReducer.selectedWorkflow.id){
                const workflowId = this.props.workflowScheduleReducer.selectedWorkflowSchedule.workflow_id;
                await this.props.workflowActions.getWorkflow(workflowId);
            }
            workflow = this.props.workflowReducer.selectedWorkflow;
        } else {
            await Promise.all([
                this.props.workflowScheduleActions.getWorkflowSchedule(this.props.params.scheduleId),
                this.props.workflowActions.listWorkflows(),
                this.props.deviceActions.searchDevices({})]);

            const {workflow_id} = this.props.workflowScheduleReducer.selectedWorkflowSchedule;
            workflow = this.props.workflowReducer.workflows.find(workflow => workflow.id === workflow_id);
            if (!workflow) {
                console.error("workflow was not found");
                return;
            }
            this.props.workflowActions.selectWorkflow(workflow);
        }

        const selectedWorkflowSchedule = this.props.workflowScheduleReducer.selectedWorkflowSchedule;
        const convertedScheduleValues = formatScheduleValuesForEditForm(selectedWorkflowSchedule);

        //TODO: refactor this into its own function with more cases than just Devices
        // loop over values in initialValues['workflow_params'] and check if they are of type objectset in the uischema
        // if they are, then we need to get the objectset type and convert them to {label: <name>, value: <id>}
        const uiSchema = workflow['ui_schema'].properties;
        const convertedParamValues = formatWorkflowParamsForEditForm({...selectedWorkflowSchedule.workflow_params},
            workflow.ui_schema);

        for (const [key, value] of Object.entries(convertedParamValues)) {
            const uiSchemaForKey = uiSchema[key];

            if (uiSchemaForKey && uiSchemaForKey['ui:widget'].startsWith('objectset:')) {
                const objectSetType = uiSchemaForKey['ui:widget'].split(':')[1];

                if (objectSetType === 'Devices') {
                    const deviceIds: any = value;
                    const devices = deviceIds
                        .map((deviceId) => {
                            const device = this.props.devices.devices.find((device) => device.identity === deviceId);
                            return device && {value: device.identity, label: device.name};
                        })
                        //filter out any devices that were not found and resulted in undefined
                        .filter(Boolean);

                    convertedParamValues[key] = devices;
                }
            }
        }

        this.setState({
            ...this.state,
            loading: false,
            initialValues: {...convertedScheduleValues, workflow_params: convertedParamValues}
        });
    }

    async onWorkflowFormSubmit(values, dispatch) {
        const workflow = this.props.workflowReducer.selectedWorkflow;
        const convertedParamValues = formatWorkflowParamsForServer({...values.workflow_params}, workflow.ui_schema);
        const newValues = {...values, workflow_params: convertedParamValues};
        const scheduleValues = formatScheduleIntervalForServer({...values});

        try {
            newValues["id"] = this.props.params.scheduleId;
            const newSchedule = await this.props.workflowScheduleActions.updateWorkflowSchedule({...newValues, ...scheduleValues});
            browserHistory.push(`/workflow_schedules/${newSchedule.value.id}`);
        } catch (e) {
            console.error(e)
        }
    }

    render() {
        const {auth, authActions} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <WorkflowSchedulePage
                        onFormSubmit={this.onWorkflowFormSubmit}
                        workflows={this.props.workflowReducer.workflows}
                        initialValues={this.state.initialValues}
                        buttonText={"Update"}
                        onWorkflowChange={this.props.workflowActions.selectWorkflow}
                        selectedWorkflow={this.props.workflowReducer.selectedWorkflow}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    devices: state.deviceReducer,
    workflowReducer: state.workflowReducer,
    workflowScheduleReducer: state.workflowScheduleReducer,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    deviceActions: bindActionCreators(DeviceActions, dispatch),
    workflowScheduleActions: bindActionCreators(WorkflowScheduleActions, dispatch),
    workflowActions: bindActionCreators(WorkflowActions, dispatch),
    reset: reset
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(EditWorkflowScheduleContainer));