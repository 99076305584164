

import {deviceAssignmentConstants} from '../constants/deviceAssignments.constants';
import * as deviceAssignmentApi from "../api/deviceAssignmentApi";

export const listDeviceAssignments = ({assetId, areaId, expand})=> {
    console.log(assetId, areaId, expand);
    console.log("params");
   return  {
    type: deviceAssignmentConstants.LIST_DEVICE_ASSIGNMENTS,
    payload: deviceAssignmentApi.listDeviceAssignments({asset_id: assetId, area_id: areaId, expand: expand})
}};

export const getDeviceAssignment = (values)=> ({
    type: deviceAssignmentConstants.GET_DEVICE_ASSIGNMENT,
    payload: deviceAssignmentApi.getDeviceAssignment(values)
});

export const createDeviceAssignment = (values)=> ({
    type: deviceAssignmentConstants.CREATE_DEVICE_ASSIGNMENT,
    payload: deviceAssignmentApi.createDeviceAssignment(values)
});

export const updateDeviceAssignment = (id,values)=> ({
    type: deviceAssignmentConstants.UPDATE_DEVICE_ASSIGNMENT,
    payload: deviceAssignmentApi.updateDeviceAssignment(id,values)
});

export const deleteDeviceAssignment = (id)=> ({
    type: deviceAssignmentConstants.DELETE_DEVICE_ASSIGNMENT,
    payload: deviceAssignmentApi.deleteDeviceAssignment(id)
});