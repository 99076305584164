import React from 'react';
import { CheckBoxBtn } from '../CheckBoxBtn/CheckBoxBtn';

export class IncidentReportCheckboxSection extends React.Component {
        
    isChecked(id) {
        return this.props.selected ?
            this.props.selected.find((s) => s.id === id) ? true : false
            : false
    }

    render() {
        let { choicesArray, label, name} = this.props;
        return (
            <div className="card shadow emp-form-card mb-3 emp_create_report_card report_section">
                <div className="card-header emp_create_report_header">
                    {label}
                </div>
                <div className="card-body emp_create_report_body pb-2">
                    <div className="row">  
                        { choicesArray.map ( (choice, index) => {
                            let unique = name+choice.id;
                            return (
                                <div className="col-sm-4" key={unique}>
                                    <CheckBoxBtn
                                        name={`${name}[${index}]`}
                                        className="custom-control-input"
                                        value={choice.id}
                                        id={unique.toString()} 
                                        htmlFor={unique.toString()}
                                        label={choice.label}
                                        labelClass="custom-control-label"
                                        parentClass="custom-control custom-checkbox custom-checkbox-primary"
                                        initialCheck={this.isChecked(choice.id)}
                                    />             
                                </div>
                            )
                        })}
                    </div>
                </div>
             </div>            
        )
    }
}

export default IncidentReportCheckboxSection;