import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function isValidPhoneNumber(country, phone) {
    let work = parsePhoneNumberFromString(country+phone);
    return (work && work.isValid()) ? true : false; 
}

export function phoneToE164Format(country, phone){
    const phoneNumber = parsePhoneNumberFromString(country+phone)
    if (phoneNumber && phoneNumber.isValid())
        return phoneNumber.number;
    return country+phone;
}

export function parseE164PhoneNumber(phoneNumber) {
    let work = parsePhoneNumberFromString(phoneNumber);
    if (work && work.isValid())
        return { country: `+${work.countryCallingCode}`, phone: work.nationalNumber};
    return { country: '', phone: ''}
}