import React from "react";

import * as Moment from "moment";
import LoadingMessage from "../LoadingMessage/LoadingMessage";
import {getMeasurementUnitShortForm} from "../../utils/formatHelper";
import {TypeToChartComponent} from "./TypeToChartComponent";
import {IMetricData} from "./MetricWidget";

interface IMetricSeriesData {
    x: number[];

    [key: number]: {
        values: number[];
        colorHex: string;
        name: string;
    }
}

interface IMetricCartData {
    x: number;
    [key: number]: number;
}

const getAxisYDomain = (from, to, x_axis, y_axis) => {
    const fromIndex = x_axis.findIndex(x => x === from);
    const toIndex = x_axis.findIndex(x => x === to);

    const refData = y_axis.slice(fromIndex, toIndex);

    const yAxisMin = Math.min(...refData);
    const yAxisMax = Math.max(...refData);
    return [yAxisMin, yAxisMax];
};

const getAllUniqueTimestamps = (data: IMetricData[]): number[] => {
    const timestamps = data.flatMap(d =>
        d.timestamps.map(timestamp =>
            Moment.utc(timestamp, 'YYYY-MM-DD hh:mm').valueOf()
        )
    );

    //@ts-ignore
    return [...new Set(timestamps)]
}


const convertData = (data: IData[]): IMetricSeriesData => {
    const timestamps = getAllUniqueTimestamps(data);

    const series = {
        x: timestamps,
    };

    data.forEach((d, i) => {
        series[i] = {
            values: d.values,
            colorHex: d.colorHex,
            name: `${d.metric} ${d.unit ? "(" + getMeasurementUnitShortForm(d.unit) + ")" : ""}`,
            unit: getMeasurementUnitShortForm(d.unit),
            instance_id: d.instance_id
        };
    });

    return series;
};

const processData = (series: IMetricSeriesData): IMetricCartData[] => {
    const result = series.x.map(x => ({ x }));

    Object.keys(series).forEach(key => {
        if (!isNaN(Number(key))) {
            series[key].values.forEach((value, i) => {
                result[i][key] = value;
            });
        }
    });

    return result;
};

const getAllYDataPoints = (data: IMetricCartData[]): number[] => {
    const allYDataPoints: any = [];
    for (let i = 0; i < data.length; i++) {
        for (let key in data[i]){
            if (key === "x") continue;
            allYDataPoints.push(data[i][key]);
        }
    }
    return allYDataPoints;
}

const DATA_MAX: string = 'dataMax'; //valid value for maxmimum https://recharts.org/en-US/api/XAxis#domain
const DATA_MIN: string = 'dataMin'; // valid value for minimum https://recharts.org/en-US/api/XAxis#domain

interface IData {
    instance_id?: string;
    timestamps: string[];
    values: number[];
    metric: string;
    unit: string | null;
    colorHex: string;
}

interface IMetricChartProps {
    chartHeight: number;
    chartType: string;
    data: IData[] | null;
    loading: boolean;
    error: string;
    title: string;
    width?: number;
    layoutSize?: number;
}

interface IMetricChartState {
    xAxisMin: string;
    xAxisMax: string;
    zoomedXMin: string;
    zoomedXMax: string;
    yAxisMax: number | null;
    yAxisMin: number | null;
}

export default class MetricChart extends React.Component<IMetricChartProps, IMetricChartState> {

    constructor(props: IMetricChartProps) {
        super(props);
        this.state = {
            xAxisMin: DATA_MIN,
            xAxisMax: DATA_MAX,
            zoomedXMin: '',
            zoomedXMax: '',
            yAxisMax: null,
            yAxisMin: null,
        }
        this.zoom = this.zoom.bind(this);
    }

    zoomOut() {
        this.setState({
            ...this.state,
            zoomedXMin: "",
            zoomedXMax: "",
            xAxisMin: DATA_MIN,
            xAxisMax: DATA_MAX,
            yAxisMax: null,
            yAxisMin: null,
        });
    }

    zoom() {
        if (!this.props.data) {
            return;
        }
        let {zoomedXMin, zoomedXMax} = this.state;

        if (zoomedXMin === zoomedXMax || zoomedXMax === "") {
            this.setState(() => ({
                ...this.state,
                zoomedXMin: "",
                zoomedXMax: ""
            }));
            return;
        }

        // xAxis domain
        if (zoomedXMin > zoomedXMax) {
            [zoomedXMin, zoomedXMax] = [zoomedXMax, zoomedXMin];
        }
        // yAxis domain
        const series: IMetricSeriesData = convertData(this.props.data);
        const chartData: IMetricCartData[] = processData(series);
        const allYDataPoints: number[] = getAllYDataPoints(chartData);

        const [yAxisMin, yAxisMax] = getAxisYDomain(zoomedXMin, zoomedXMax, series.x, allYDataPoints);

        this.setState(() => ({
            zoomedXMin: "",
            zoomedXMax: "",
            xAxisMin: zoomedXMin,
            xAxisMax: zoomedXMax,
            yAxisMin,
            yAxisMax,
        }));
    }

    render() {
        if (this.props.loading) {
            return <div className="card-body emp-device-card-body text-align-center">
                <LoadingMessage/>
            </div>
        }
        const ChartComponent = TypeToChartComponent[this.props.chartType];
        if (this.props.data) {
            const series: IMetricSeriesData = convertData(this.props.data);
            const chartData: IMetricCartData[] = processData(series);
            const allYDataPoints: number[] = getAllYDataPoints(chartData);
            const xAxisMin = this.state.xAxisMin === DATA_MIN ? Math.min(...series.x) : this.state.xAxisMin;
            const xAxisMax = this.state.xAxisMax === DATA_MAX ? Math.max(...series.x) : this.state.xAxisMax;
            const yDomain = this.state.yAxisMax === null ? [Math.min(...allYDataPoints), Math.max(...allYDataPoints)] :
                [this.state.yAxisMin, this.state.yAxisMax]; //checks if original dataset or zoomed in dataset it supposed to be used

            return (
                <>
                    {this.state.yAxisMax &&
                        <i onClick={this.zoomOut.bind(this)} className="fa fa-search-minus fa-lg" aria-hidden="true"/>
                    }
                    <h3 className="card-title m-3">{this.props.title}</h3>
                    {ChartComponent(
                        {
                            series: series,
                            chartData: chartData,
                            yDomain: yDomain,
                            height: this.props.chartHeight,
                            loading: this.props.loading,
                            onMouseDown: (e) => e && this.setState({...this.state, zoomedXMin: e.activeLabel}),
                            onMouseUp: this.zoom,
                            xAxisMin: xAxisMin,
                            xAxisMax: xAxisMax,
                            onMouseMove: (e) => e && this.state.zoomedXMin && this.setState({
                                ...this.state,
                                zoomedXMax: e.activeLabel
                            }),
                            zoomedXMin: this.state.zoomedXMin,
                            zoomedXMax: this.state.zoomedXMax,
                            width: this.props.width,
                            layoutSize: this.props.layoutSize
                        }
                    )
                    }
                </>
            );
        } else {
            return <></>
        }
    }
}