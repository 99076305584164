import 'jspdf-autotable'

export default (doc, header, printData, startY, fontSize, lineSpacing) => {

    const items = Object.values(printData);
    doc.autoTable({
        headStyles: {
            overflow: 'linebreak',
        },
        // styles: {
        //     overflow: 'linebreak',
        // },
        head: [header],
        rowPageBreak: 'auto',
        body: items.map(item => Object.values(item)),
        startY: startY,
        margin: {left: 25, right: 25}
    });

    return startY;
}