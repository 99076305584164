import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';
import {WorkActivityModel} from './model/WorkActivity';

export class WorkActivityApi {
    constructor() {

    }

    async listWorkActivities() {
        return axios.get(`${BASE_URL()}/api/v1/work_activities`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': storage.get('token')
            }
        }).then(response => {
            return response.data['items'].map(item => WorkActivityModel.fromJson(item));
        });
    }

    async getWorkActivity(workActivityId){
        return axios.get(`${BASE_URL()}/api/v1/work_activities/${workActivityId}`, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': storage.get('token')
            }
        }).then(response => {
            return WorkActivityModel.fromJson(response.data.item);
        });
    }
}
