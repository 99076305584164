export function create(values, successCallback, errorCallback) {
    return new Promise((resolve, reject) => {
        const schema = {
            id: 3,
            title: values.title,
            description: values.description,
            fields: values.fields
        }

        resolve(schema);
    })
}

export function list(values) {
    return new Promise((resolve, reject) => {
        const schemas = [
            {
                id: 1,
                title: "Sensor Data",
                description: "Schema for temperature monitors",
                fields: [{
                    type: "string",
                    title: "ambient_temperature",
                    shortTitle: "Ambient Temperature"
                }],

                owner: "Nirat Keswani"
            },
            // {
            //     id: 2,
            //     title: "Trackonomy Data",
            //     description: "Trackonomy data Imported from Devices",
            //     fields: [
            //         {
            //             type: "string",
            //             title: "timestamp",
            //             shortTitle: "Timestamp"
            //         }, {
            //             type: "string",
            //             title: "name",
            //             shortTitle: "Device Name"
            //         }, {
            //             type: "string",
            //             title: "location",
            //             shortTitle: "Location"
            //         },
            //         {
            //             type: "string",
            //             title: "action",
            //             shortTitle: "Action"
            //         }
            //     ],
            //     owner: "Nirat Keswani"
            // },

            {
                id: 3,
                title: "Incidents",
                description: "Incidents generated in Empiric's Alert System",
                fields: [
                    {
                        type: "string",
                        title: "timestamp",
                        shortTitle: "Timestamp",
                        format: "date-time"
                    }, {
                        type: "array",
                        items: {
                            "type": "object"
                        },
                        title: "reasons",
                        shortTitle: "Reasons",
                    }, {
                        type: "array",
                        items: {
                            "type": "object"
                        },
                        title: "storage_unit_types",
                        shortTitle: "Location"
                    },
                ],
                owner: "Nirat Keswani"
            },

        ]

        resolve(schemas);
    });
}