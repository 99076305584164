import {workflowScheduleConstants} from '../constants/workflowSchedule.constants';

const initialState = {
    workflowSchedules: [],
    createdWorkflowSchedule: {},
    updatedWorkflowSchedule: {},
    selectedWorkflowSchedule: {}
};

export const workflowScheduleReducer = (state = initialState, action) => {
    let newWorkflowSchedules;
    switch (action.type) {
        case workflowScheduleConstants.LIST_WORKFLOW_SCHEDULES:
        case workflowScheduleConstants.LIST_WORKFLOW_SCHEDULES_FULFILLED:
            state = {
                ...state,
                workflowSchedules: action.payload
            };
            return state;
        case workflowScheduleConstants.UPDATE_WORKFLOW_SCHEDULE:
        case workflowScheduleConstants.UPDATE_WORKFLOW_SCHEDULE_FULFILLED:
            newWorkflowSchedules = state.workflowSchedules.filter(schedule => schedule.id !== action.payload.id);
            newWorkflowSchedules.push(action.payload);
            state = {
                ...state,
                updatedWorkflowSchedule: action.payload,
                workflowSchedules: newWorkflowSchedules,
                selectedWorkflowSchedule: action.payload,
            };
            return state;
        case workflowScheduleConstants.CREATE_WORKFLOW_SCHEDULE:
        case workflowScheduleConstants.CREATE_WORKFLOW_SCHEDULE_FULFILLED:
            state = {
                ...state,
                createdWorkflowSchedule: action.payload,
                workflowSchedules: [...state.workflowSchedules, action.payload],
                selectedWorkflowSchedule: action.payload
            };
            return state;
        case workflowScheduleConstants.SELECT_WORKFLOW_SCHEDULE:
            state = {
                ...state,
                selectedWorkflowSchedule: action.payload,
            };
            return state;
        case workflowScheduleConstants.DELETE_WORKFLOW_SCHEDULE:
        case workflowScheduleConstants.DELETE_WORKFLOW_SCHEDULE_FULFILLED:
            newWorkflowSchedules = state.workflowSchedules.filter(schedule => schedule.id !== action.payload.id);
            state = {
                ...state,
                selectedWorkflowSchedule: {},
                workflowSchedules: newWorkflowSchedules
            };
            return state;
        case workflowScheduleConstants.GET_WORKFLOW_SCHEDULE:
        case workflowScheduleConstants.GET_WORKFLOW_SCHEDULE_FULFILLED:
            state = {
                ...state,
                selectedWorkflowSchedule: action.payload
            };
            return state;
        default:
            return state;
    }
};