//This component Automatically formatting number fields - Forms
import React, { useState } from "react";
import { horizontalClasses } from "../FormFields";

const FormatNumbersFields = ({
  label,
  value,
  onChange,
  classes,
  orientation,
}) => {
  const [rawValue, setRawValue] = useState(value);

  // This is a function that format number with commas
  const formatNumbersForms = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const handleChange = (event) => {
    let value = event?.target.value;

    // Validate the numbers and keep only numeric values
    let numericValue = value.replace(/[^0-9]/g, "");

    // Then Update the raw value state
    setRawValue(numericValue); //ui
    onChange(numericValue); // data on the database
  };
  return (
    <div
      className={`form-input my-4  ${classes || "col-sm-6"} ${
        orientation === "horizontal" && horizontalClasses
      }`}
    >
      {label && <label>{label}:</label>}

      <input
        className="form-control"
        type="text"
        value={formatNumbersForms(rawValue)}
        onChange={handleChange}
      />
    </div>
  );
};

export default FormatNumbersFields;
