import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import store from '../../store/store';
import * as SensorActions from '../../actions/sensorAction';
import {fetchSensor} from '../../utils/fetchDataHelper';

export class SensorPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sensor: {}
        };

    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.sensor['id'] && nextProps.sensorReducer.sensor['id']) {
            this.setState({sensor: nextProps.sensorReducer.sensor});
        }
    }

    componentDidMount() {
        fetchSensor(this.props.params['id'])
    }

    render() {
        return (
            <main className="app-content">
                {this.renderHeaderSelectionOptions()}
                <section className="emp_org_settings_sec emp_report_sec settings_users_sec emp_gatway_box_scc">
                    <div className="row">
                        <div className="col-xl-10">
                            <div className="row">
                                <div className="col-xl-4 offset-xl-2 col-lg-6 col-md-6">
                                    {this.renderGatewayCard()}
                                </div>
                                <div className="col-xl-4 offset-xl-1 col-lg-6 col-md-6">
                                    {this.renderBatteryCard()}
                                </div>
                            </div>
                        </div>
                    </div>
                    {this.renderDeviceDataTable()}
                </section>
            </main>
        );
    }


    renderGatewayCard() {
        return <div className="card shadow emp_gatway_box_card">
            <div className="card-body">
                <h5 className="card-title text-center">Gateway Name</h5>
                <div className="media">
                    <div className="circle_box text-center mr-4">
                        <i className="fa fa-wifi icn_wifi_big"></i>
                        <p className="circle_sm_txt">Connection Status</p>
                    </div>
                    <div className="media-body circle_box_gray">
                        <div className="card-body bg_gray_box">
                            <ul className="list-unstyled mb-0">
                                <li className="media d-flex align-items-center">
                                    <i className="mr-3 fa fa-circle text-success icn_dots"></i>
                                    <div className="media-body">
                                        online
                                    </div>
                                </li>
                                <li className="media my-3 d-flex align-items-center">
                                    <i className="mr-3 fa fa-circle icn_warning icn_dots"></i>
                                    <div className="media-body">
                                        Low
                                    </div>
                                </li>
                                <li className="media d-flex align-items-center">
                                    <i className="mr-3 fa fa-circle text-danger icn_dots"></i>
                                    <div className="media-body">
                                        Offline
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card_footer_box">
                    <div className="table-responsive text-nowrap">
                        <table className="table table-borderless sensor_id_table">
                            <thead className="border-bottom">
                            <tr>
                                <th scope="col">Sensor ID</th>
                                <th scope="col">Signal</th>
                                <th scope="col">Online</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th>f0:0b:a1:80:50:48</th>
                                <td>&nbsp;</td>
                                <td>&nbsp;</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>;
    }

    renderBatteryCard() {
        return <div className="card shadow emp_gatway_box_card">
            <div className="card-body">
                <h5 className="card-title text-center">Battery</h5>
                <div className="media">
                    <div className="circle_box text-center mr-4">
                        <img
                            src={require("../../styles/images/icon/battery-status-full.png")}
                            alt="status"/>
                        <p className="circle_sm_txt">Battery Level</p>
                    </div>
                    <div className="media-body circle_box_gray">
                        <div className="card-body bg_gray_box">
                            <ul className="list-unstyled mb-0">
                                <li className="media d-flex align-items-center">
                                    <i className="mr-3 fa fa-circle text-success icn_dots"></i>
                                    <div className="media-body">
                                        High
                                    </div>
                                </li>
                                <li className="media my-3 d-flex align-items-center">
                                    <i className="mr-3 fa fa-circle icn_warning icn_dots"></i>
                                    <div className="media-body">
                                        Low
                                    </div>
                                </li>
                                <li className="media d-flex align-items-center">
                                    <i className="mr-3 fa fa-circle text-danger icn_dots"></i>
                                    <div className="media-body">
                                        Dead
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="card_footer_box">
                    <div className="table-responsive text-nowrap">
                        <table className="table table-borderless sensor_id_table">
                            <thead className="border-bottom">
                            <tr>
                                <th scope="col">Sensor ID</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th>{this.state.sensor.mac_address}</th>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>;
    }

    renderHeaderSelectionOptions() {
        return <div className="app-title border-bottom">
            <div className="media-body">
                <span className="sub_txt_title setting_user_txt_title">EMPIRIC SOLUTIONS - TEST</span>
                <h5 className="mt-0 settings_users_hd_title">{this.state.sensor.name}</h5>
            </div>
            <ul className="app-breadcrumb breadcrumb gatway_block_search_l395">
                <li className="emp-title-item">
                    <a className="app-nav__item duration_list text-initial" href="images/icon/icn_pdf.png" download="">
                        Export to PDF
                    </a>
                </li>
                <li className="emp-title-item">
                    <a className="app-nav__item duration_list text-initial" href="images/icon/icn_pdf.png" download="">
                        Export to CSV
                    </a>
                </li>
                <li className="emp-title-item">
                    <span className="v-pipe-top"></span>
                </li>
                <li className="emp-title-item">
                    <div className="info_cal app-title-shadow">
                        <input id="datepicker1" className="form-control input_modifier border-0 app-title-shadow"
                               placeholder="1 Aug 2017"/>
                    </div>
                </li>
                <li className="emp-title-item">
                    <div className="app-title-right-widget-small">
                        <div className="info border-info">
                            <hr className="border-top-info"/>
                        </div>
                    </div>
                </li>
                <li className="emp-title-item">
                    <div className="info_cal app-title-shadow">
                        <input id="datepicker2" className="form-control input_modifier border-0 app-title-shadow"
                               placeholder="12 Aug 2017"/>
                    </div>
                </li>
                <li className="emp-title-item">
                    <div className="app-title-right-widget-small">
                        <div className="info title-icn-calender bg-transparent">
                            <img src={require("../../styles/images/icon/icn_calender.png")} alt="Calender Icon"/>
                        </div>
                    </div>
                </li>
            </ul>
        </div>;
    }

    renderDeviceDataTable() {
        return <div className="row mt-3">
            <div className="col-xl-12">
                <div className="table-wrapper mt-3">
                    <div className="table-responsive text-nowrap">
                        <table className="table table-striped table_history">
                            <thead>
                            <tr>
                                <th scope="col" className="text-black1"><strong>When</strong></th>
                                <th scope="col">Incidents</th>
                                <th scope="col">Temp Min</th>
                                <th scope="col">Temp Max</th>
                                <th scope="col">Temp Avg</th>
                                <th scope="col">Humidity Min</th>
                                <th scope="col">Humidity Max</th>
                                <th scope="col">Humidity Avg</th>

                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>1 August 2017, 7:30pm</td>
                                <td className="text-primary">1</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33%</td>
                                <td>33%</td>
                                <td>33%</td>
                            </tr>

                            <tr>
                                <td>1 August 2017, 7:30pm</td>
                                <td className="text-primary">2</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33%</td>
                                <td>33%</td>
                                <td>33%</td>
                            </tr>

                            <tr>
                                <td>2 August 2017, 7:30pm</td>
                                <td className="text-primary">3</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33%</td>
                                <td>33%</td>
                                <td>33%</td>
                            </tr>

                            <tr>
                                <td>3 August 2017, 7:30pm</td>
                                <td className="text-primary">5</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33%</td>
                                <td>33%</td>
                                <td>33%</td>
                            </tr>

                            <tr>
                                <td>4 August 2017, 7:30pm</td>
                                <td className="text-primary">10</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33%</td>
                                <td>33%</td>
                                <td>33%</td>
                            </tr>

                            <tr>
                                <td>4 August 2017, 7:30pm</td>
                                <td className="text-primary">14</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33%</td>
                                <td>33%</td>
                                <td>33%</td>
                            </tr>

                            <tr>
                                <td>4 August 2017, 7:30pm</td>
                                <td className="text-primary">4</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33 <sup>0</sup>F</td>
                                <td>33%</td>
                                <td>33%</td>
                                <td>33%</td>
                            </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>;
    }
}

const mapStateToProps = (state) => {

    return ({
        sensorReducer: state.sensorReducer
    });
};

const mapDispatchToProps = (dispatch) => ({
    SensorActions: bindActionCreators(SensorActions, dispatch)
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SensorPage));