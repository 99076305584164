import {areaConstants} from '../constants/area.constants.js';

const initialState = {
    area: {},
    areas: [],
    updated: false,
};

export const areaReducer = (state = initialState, action) => {
    switch (action.type) {
        case areaConstants.GET_AREA_FULFILLED:
            state = {
                ...state,
                area: action.payload,
                updated: true
            };
            return state;
        case areaConstants.LIST_AREAS_FULFILLED:
            state = {
                ...state,
                areas: action.payload,
                updated: true
            }
            return state;
        case areaConstants.UPDATE_AREA_FULFILLED:
        case areaConstants.CREATE_AREA_FULFILLED:
            state = {
                ...state,
                area: action.payload,
                updated: true
            };
            return state;

        case areaConstants.DELETE_AREA_FULFILLED:
            state = {
                ...state,
                areas: state.areas.filter(area => area.id !== action.payload.id),
                updated: true
            }
            return state;
        case areaConstants.RESET_AREA_FULFILLED:
            state = {
                ...state,
                area: {},
                updated: true
            }
            return state;
        default:
            return state;
    }
};