import {workflowConstants} from '../constants/workflow.constants';

const initialState = {
    workflows: [],
    selectedWorkflow: {}
};

export const workflowReducer = (state = initialState, action) => {
    let newWorkflows;
    switch (action.type) {
        case workflowConstants.LIST_WORKFLOWS:
        case workflowConstants.LIST_WORKFLOWS_FULFILLED:
            state = {
                ...state,
                workflows: action.payload
            };
            return state;
        case workflowConstants.SELECT_WORKFLOW:
            state = {
                ...state,
                selectedWorkflow: action.payload,
            };
            return state;
        case workflowConstants.DELETE_WORKFLOW:
        case workflowConstants.DELETE_WORKFLOW_FULFILLED:
            newWorkflows = state.workflows.filter(workflow => workflow.id !== action.payload.id);
            state = {
                ...state,
                selectedWorkflow: {},
                workflows: newWorkflows
            };
            return state;
        case workflowConstants.GET_WORKFLOW:
        case workflowConstants.GET_WORKFLOW_FULFILLED:
            state = {
                ...state,
                selectedWorkflow: action.payload,
            };
            return state;
        default:
            return state;
    }
};