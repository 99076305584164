import {formCommentConstants} from '../constants/formComment.constants';
import { CommentProps } from '../formSchema';

const initialState: CommentProps[] = [];

export const formCommentReducer = (state = initialState, action) => {
    switch (action.type) {
        case formCommentConstants.FETCH_FORM_COMMENTS_FULFILLED:
            state = action.payload
            return state;

        case formCommentConstants.SUBMIT_FORM_COMMENT:
            return state;

        default:
            return state;
    }
};