import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as DatasetTableActions from '../../actions/datasetTablesActions';
import * as AuthActions from '../../actions/authActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import {browserHistory} from "react-router";
import {Table} from "../../api/model/DatasetTable";
import {TablesPage} from "../../components/Databases/Tables/TablesPage";

interface IProps {
    auth: any;
    authActions: typeof AuthActions;
    datasetTableActions: typeof DatasetTableActions;
    tables: Table[];
}

interface IState {
    loading: boolean;
}

function debounce(callback: (...args: any[]) => void, delay: number) {
    let timeout: NodeJS.Timeout;

    return function (...args: any[]) {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            callback(...args);
        }, delay);
    }
}

class TablesContainer extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
        }
        this.debounceSearch = debounce(this.onSearchInput.bind(this), 600);
    }

    debounceSearch: (...args: any[]) => void;

    async componentDidMount() {
        this.setState({loading: true})
        this.props.datasetTableActions.listTables("default");
        this.setState({loading: false})
    }

    onItemClick = (item: Table) => {
        const {database_name: databaseName, table_name} = item;
        browserHistory.push(`/databases/${databaseName}/tables/${item.table_name}`);
    }

    onSearchInput = async (value: string) => {
        this.props.datasetTableActions.listTables("default");
    }

    render() {
        const {auth, authActions, tables} = this.props;
        const {loading} = this.state;

        return (
            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage/> :
                    <TablesPage tables={tables}
                                onItemClick={this.onItemClick}
                                onSearchInput={this.debounceSearch}
                    />
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    tables: state.datasetTablesReducer.tables

});

const mapDispatchToProps = (dispatch) => ({
    datasetTableActions: bindActionCreators(DatasetTableActions, dispatch),
    authActions: bindActionCreators(AuthActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(TablesContainer);
