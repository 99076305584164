import React from 'react';
import {ListView} from "../../ui/ListView/ListView";
import {DeviceSearchInputForm} from "./DeviceSearchInputForm";
import {ApiDevice} from "../../api/model/Device";
import {Link} from "react-router";

const renderTextRow = (label: string, text: string) => {
    return (
        <div className="row mt-2">
            <span className="col-6 col-sm-4 col-md-4 col-lg-3 emp-type-subtitle1">{label}</span>
            <span className={"col-6 col-sm col-md col-lg emp-type-subtitle1"}>{text}</span>
        </div>
    );
}

const renderDeviceListItem = (item: ApiDevice, index: number) => {
    return <div className="container-fluid">
        <div className="row">
            <div className="col-2">
                <img className={"placeholder img-thumbnail"}
                     src={item.image_url?.url || `https://via.placeholder.com/150?text=Device`}/>
            </div>
            <div className="col">
                {renderTextRow("Name", item.name)}
                {renderTextRow("Description", item.description)}
            </div>
        </div>
    </div>
}

interface Props {
    devices: ApiDevice[],
    onItemClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    onSearchInput: (item: string) => void
}

export class DevicesPage extends React.Component<Props> {
    render() {
        const {devices, onItemClick} = this.props;
        if (!devices) {
            return null;
        }
        return (
            <main className="app-content">
                <div className={"container-fluid"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <h2 className="emp-type-h2">Devices</h2>
                        </div>
                    </div>

                    {/*<div className="row mb-2">*/}
                    {/*    <div className="col">*/}
                    {/*        <Link to="/devices/new">*/}
                    {/*            <button className="btn btn-primary emp-type-btn-primary">CREATE</button>*/}
                    {/*        </Link>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="row mb-2">
                        <div className="col-12">
                            <DeviceSearchInputForm label={"Search"} onChange={this.props.onSearchInput}/>
                        </div>
                    </div>
                    <div className="row mb-2">
                        <div className="col-12">
                            <ListView
                                datasource={devices}
                                renderItem={renderDeviceListItem}
                                onItemClick={onItemClick}
                            />
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}
