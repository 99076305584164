import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import * as $ from 'jquery';

const renderField = ({
                         input: {value: omitValue, ...inputProps},
                         id,
                         label,
                         placeholder,
                         inputGroupLabelClass,
                         inputGroupLabel,
                         inputGroupClass,
                         type,
                         className,
                         iconClass,
                         htmlFor,
                         multiple,
                         meta: {touched, asyncValidating, error},
                         ...props
                     }) => {
    const uploadInputRef = React.createRef();
    const handleClick = () => {
        uploadInputRef.current.click();
    };
    return (
        <div className="form-group">
            <div className={`form-group__field ${inputGroupClass} ${asyncValidating ? 'async-validating' : ''}`}>
                <div className="">
                    <div className="display-none">
                        <input
                            ref={uploadInputRef}
                            {...inputProps} {...props}
                            placeholder={placeholder}
                            type={type}
                            multiple={multiple}
                            className={className}
                            id={id}
                            accept='.jpg, .png, .jpeg'
                        />
                    </div>

                    <button className="btn btn-link btn_file_input emp-type-btn-primary"
                            onClick={() => handleClick()} type="button">
                            <span htmlFor="inputGroupFile01" className="mr-2 emp_icn_middle">
                            </span> CHANGE
                    </button>
                </div>
            </div>
            {touched && error && <div className="form-group__error">{error}</div>}
        </div>
    );
};

export const NoLabelFileInputField = ({
                                          type,
                                          name,
                                          id,
                                          label,
                                          placeholder,
                                          inputGroupLabelClass,
                                          inputGroupClass,
                                          className,
                                          iconClass,
                                          htmlFor,
                                          inputGroupLabel,
                                          HandleChange,
                                          multiple
                                      }) => (
    <Field
        type={type}
        placeholder={placeholder}
        name={name}
        id={id}
        label={label}
        inputGroupLabelClass={inputGroupLabelClass}
        inputGroupClass={inputGroupClass}
        className={className}
        iconClass={iconClass}
        htmlFor={htmlFor}
        multiple={multiple}
        inputGroupLabel={inputGroupLabel}
        onChange={HandleChange}
        component={renderField}
    />
);

NoLabelFileInputField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    iconClass: PropTypes.string,
    inputGroupLabelClass: PropTypes.string,
    inputGroupLabel: PropTypes.string,
    inputGroupClass: PropTypes.string,
    HandleChange: PropTypes.func,
    htmlFor: PropTypes.string
};

NoLabelFileInputField.defaultProps = {
    type: 'file',
    id: '',
    label: null,
    multiple: false,
    placeholder: '',
    className: '',
    inputGroupLabelClass: '',
    inputGroupLabel: '',
    inputGroupClass: '',
    htmlFor: ''
};