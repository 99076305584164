import { PASSWORD_MIN_LEN} from '../../config';

export default function validate (values) {
    const errors = {};
    errors['device'] = {};
    let value  = values['device'];
    if (value){

        if (!value['name']) {
            errors['device']['name'] = 'Name is required';
        }

        if (!value['product_id']) {
            errors['device']['product_id'] = 'Product  is required';
        }
        if (!value['mac_address']) {
            errors['device']['mac_address'] = `Mac Id is required.`;
        }

    }
    else{
    	errors['device'] = {
    		name: 'Name is required',
    		product_id: 'Product Id is required',
    		mac_address: 'Mac Id is required'
    	};
    }


    return errors;
};