import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';

import {GoogleMap, Marker} from "@react-google-maps/api";

import {ListCurrentLocationTelemetryRequest} from "../../api/model/locationTelemetryRequest";
import * as LocationTelemetryActions from "../../actions/locationTelemetryAction";
import {formatTimestampDateTime} from "../../utils/formatHelper";
import LoadingMessage from "../LoadingMessage/LoadingMessage";

const DEFAULT_MAP_CENTER = {lat: 47.615083, lng: -122.341108};
const DEFAULT_ZOOM_LEVEL = 14;
const ACTIVE_CARD_ZOOM_LEVEL = 16;

export class OverviewPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            devices: [],
            activeCard: null,
            mapCenter: DEFAULT_MAP_CENTER,
            mapZoomLevel: DEFAULT_ZOOM_LEVEL,
            map: null
        };
    }

    componentDidMount() {
        this.props.locationTelemetryActions.listDeviceLocations(new ListCurrentLocationTelemetryRequest(1));
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.locationTelemetryReducer.updated) {
            const devices = nextProps.locationTelemetryReducer.telemetryData.map((item) => {
                return {
                    title: item.deviceId,
                    location: "",
                    position: {lat: item.geometry.coordinates[0], lng: item.geometry.coordinates[1]},
                    user: "Nirat",
                    timestamp: item.timestamp,
                    alive: true,
                    device_name: item.device_name
                }
            });
            this.setState(state => {
                return {
                    loading: false,
                    devices: devices
                }
            });
            nextProps.locationTelemetryReducer.updated = false;
        }
    }

    componentDidUpdate(){
        if(this.state.devices.length < 2) return;
        if(this.state.map && this.state.map.renderingType !== "UNINITIALIZED" && this.state.activeCard === null){
            const bounds = new window.google.maps.LatLngBounds();
            this.state.devices.forEach(device => {
                bounds.extend(device.position);
            });
            this.state.map.fitBounds(bounds);
        }
    }

    renderDevices() {
        const handleCardClick = (device, idx) => {
            this.setState({...this.state, activeCard: idx});
            this.state.map.setZoom(ACTIVE_CARD_ZOOM_LEVEL);
            this.state.map.setCenter(device.position);
        }

        const {devices} = this.state;
        return devices.map((device, idx) => {
            const {device_name, user, location, timestamp, alive} = device;
            const {activeCard} = this.state;
            const active = idx === activeCard ? "active-card" : "";
            const aliveColor = alive ? "#3FAF5E" : "#FF545E";
            return (
                <div className={`card overview-page-card ${active}`} style={{width: "18rem"}} key={idx}
                     onClick={()=>handleCardClick(device, idx)}>
                    <div className="card-body">
                        <h5 className="card-title">{device_name} {/*<i className="fa far fa-cog float-right"
                                                                     style={{color: "#0273EB"}></i>*/}</h5>
                        {location && <h6 className="card-subtitle mb-2 text-muted"> <i
                        className="fa fas fa-map-marker padding-right-10"></i>{location}</h6>}
                        {/*<p className="card-text"><i className="fa fas fa-user padding-right-10"></i>{user}</p>*/}
                        <p className="card-text"><i className="fa fas fa-circle padding-right-10"
                                                    style={{color: aliveColor}}></i>Last Updated: {formatTimestampDateTime(timestamp)}</p>
                    </div>
                </div>
            )
        })
    }

    renderMarkers() {
        return this.state.devices.map((device, idx) => {
            const {position, title} = device;
            return (
                <Marker
                    key={idx}
                    position={position}
                    draggable={false}
                    title={title}
                    icon={{url: require("../../styles/images/icon/ic_gps.png")}}
                />
            )
        });
    }

    renderActiveMarker() {
        if (!window.google) return;

        const {activeCard, devices} = this.state;
        if (!devices.length || activeCard === null) return;

        const {position, title} = devices[activeCard];

        return (
            <Marker
                position={position}
                title={title}
                origin={new window.google.maps.Point(50, 50)}
                label={{
                    text: title,
                    className: "active-card-label",
                    color: "white",
                }}
            />
        )
    }


    render() {
        return (
            <main className="app-content">
                <div className="app-title border-bottom">
                    <div>
                        <h1>Devices Overview</h1>
                    </div>
                </div>
                <div className="overview-page">
                    {this.state.loading ?
                        <div className={"m-auto"}><LoadingMessage/></div> :
                        <div>{this.renderDevices()}</div>}
                        <GoogleMap
                            mapContainerClassName="Overview-map"
                            center={this.state.mapCenter}
                            zoom={this.state.mapZoomLevel}
                            onLoad={(map)=>this.setState({...this.state, map:map})}
                            version="weekly"
                            clickableIcons={false}
                            options={{ styles:[{elementType:"labels", featureType: "poi.business", stylers:[{ visibility: "off",}],}],}}
                            on
                        >
                            {this.renderMarkers()}
                            {this.renderActiveMarker()}
                        </GoogleMap>
                </div>
                {/* <section className="emp_org_settings_sec emp_report_sec">
                    {this.getContentArea()}
                </section> */}
            </main>
        );
    }
}

const mapStateToProps = (state) => {
    return ({
        environmentReducer: state.environmentReducer,
        deviceReducer: state.deviceReducer,
        groupReducer: state.groupReducer,
        locationTelemetryReducer: state.locationTelemetryReducer,
    });
};

const mapDispatchToProps = (dispatch) => ({
    locationTelemetryActions: bindActionCreators(LocationTelemetryActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(OverviewPage);
