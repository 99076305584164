import { deviceTypeMetricsConstants } from "../constants/deviceTypeMetrics.constants";

interface DeviceMetric {
    id: number;
    name: string;
    displayTitle: string;
}

interface DeviceTypeMetricsState {
    deviceTypeMetric: DeviceMetric;
    deviceTypeMetrics: Array<DeviceMetric>;
    updated: boolean;
}

interface Action {
    type: string;
    payload: any;
}

const initialState: DeviceTypeMetricsState = {
    deviceTypeMetric: {} as DeviceMetric,
    deviceTypeMetrics: [],
    updated: false,
};
export const deviceTypeMetricsReducer = (state = initialState, action) => {
    switch (action.type) {
        case deviceTypeMetricsConstants.GET_DEVICE_TYPE_METRIC_FULFILLED:
            state = {
                ...state,
                deviceTypeMetric: action.payload,
                updated: true,
            };
            return state;
        case deviceTypeMetricsConstants.LIST_DEVICE_TYPE_METRICS_FULFILLED:
            state = {
                ...state,
                deviceTypeMetrics: action.payload,
                updated: true,
            };
            return state;
        case deviceTypeMetricsConstants.UPDATE_DEVICE_TYPE_METRIC_FULFILLED:
            state = {
                ...state,
                deviceTypeMetric: action.payload,
                deviceTypeMetrics: [
                    ...state.deviceTypeMetrics.filter(
                        (metric) => metric.id !== action.payload.id
                    ),
                    action.payload,
                ],
                updated: true,
            };
            return state;
        case deviceTypeMetricsConstants.CREATE_DEVICE_TYPE_METRIC_FULFILLED:
            state = {
                ...state,
                deviceTypeMetric: action.payload,
                deviceTypeMetrics: [...state.deviceTypeMetrics, action.payload],
                updated: true,
            };
            return state;

        case deviceTypeMetricsConstants.DELETE_DEVICE_TYPE_METRIC_FULFILLED:
            state = {
                ...state,
                deviceTypeMetrics: state.deviceTypeMetrics.filter(
                    (metric) => metric.id !== action.payload.id
                ),
                updated: true,
            };
            return state;
        default:
            return state;
    }
};
