import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as DeviceActions from '../../actions/deviceAction';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {Footer} from '../../components/Footer/Footer';
import { withRouter } from 'react-router';
import DeviceForm from '../../components/DeviceForm/DeviceForm'

export class DeviceFormContainer extends React.Component {
    constructor(props){
        super(props);
        this.state={
            update: true
        }

    }

    render() {
        const {authActions, auth } = this.props;
        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                    />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                <DeviceForm />
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    auth: state.authReducer
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
});


export default withRouter(connect(mapStateToProps,mapDispatchToProps)(DeviceFormContainer));