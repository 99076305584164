import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';


export const createGroup = (data) => {
    return axios.post(`${BASE_URL()}/api/v1/groups`,data, { 
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    }).then( response => response.data );
};

export const signup = (credentials) => {
    return axios.post(`${BASE_URL()}/users`, credentials,{
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        defaultErrorHandler: true
    });
};
export const updateGroup = (id,data) => {
    return axios.put(`${BASE_URL()}/api/v1/groups/${id}`,data, { 
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    }).then(response =>  response.data);
};

export const searchGroup = (data) => {
    return axios.get(`${BASE_URL()}/api/v1/groups`,  {
        params: data,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response => response.data);
};

export const fetchGroupData = () => {
    return axios.get(`${BASE_URL()}/api/v1/groups`,  {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response => response.data);
};

export const fetchGroup = (id) => {
    return axios.get(`${BASE_URL()}/api/v1/groups/${id}`,  {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        },
        defaultErrorHandler: true
    }).then(response => response.data);
};

export const deleteGroup = (id) => {
    return axios.delete(`${BASE_URL()}/api/v1/groups/${id}`, { headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }})
        .then(response =>  response.data);
};