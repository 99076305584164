import React from 'react';
import {connect} from 'react-redux';
import {Field, reduxForm} from 'redux-form';
import {getTimeDifference} from "../../utils/utils";
import {DateTimeField} from '../DateTimeField/NewDateTimeField';
import {PeriodIntervalSelector} from "../../PeriodIntervalSelector/PeriodIntervalSelector";
import {required, validateDateTimeFormat} from "../../utils/validateFunctions";
import {TextField} from "../../ui/Input/TextField";
import moment from 'moment';


const Moment = moment;
const DATE_FORMAT = 'MM-DD-YYYY hh:mm A';

const ONE_HOUR_AGO = 1000 * 3600;
const THREE_HOURS_AGO = 3 * 1000 * 3600;
const TWELVE_HOURS_AGO = 12 * 1000 * 3600;
const ONE_DAY_AGO = 1000 * 3600 * 24;
const ONE_WEEK_AGO = 1000 * 7 * 3600 * 24;

const getInputComponent = (inputProps, label) => <TextField label={label} {...inputProps}/>

function convertPeriodFromTimeWindow(startDate, endDate, period) {
    const diff = getTimeDifference(startDate, endDate, "days");
    if (diff <= 12) {
        // 12 and less days, any period is allowed
        return period;
    } else if (diff > 12 && diff <= 120) {
        //between 12 days and 4 months, only 1h and up should be allowed
        if (period == "5m") {
            return "1h";
        } else {
            return period;
        }
    } else {
        //anything greater should only return in days or higher
        if (period == "5m" || period == "1h") {
            return "1d";
        } else {
            return period;
        }
    }
}

export class DashboardSelectionNav extends React.Component<{
    handleSubmit,
    onSubmitHandler,
    dispatch,
    desiredStartTime,
    className,
    change
},{}> {

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    renderButton(props) {
        return <button type="button" className="btn btn-outline-primary" onClick={this.props.handleSubmit(data => {
            const now = new Date();
            const newStartTime = now.valueOf() - props.desiredStartTime;
            const startDate = Moment(new Date(newStartTime).toISOString()).format(DATE_FORMAT);
            const endDate = Moment(new Date(now.valueOf()).toISOString()).format(DATE_FORMAT);
            const newPeriod = convertPeriodFromTimeWindow(Moment(startDate, DATE_FORMAT).toISOString(), Moment(endDate, DATE_FORMAT).toISOString(), data["device_data"]["period"]);
            data["device_data"]["period"] = newPeriod;
            data["device_data"]["start_date"] = startDate;
            data["device_data"]["end_date"] = endDate;
            this.props.onSubmitHandler(data, this.props.dispatch);
        })}>
            {props.title}
        </button>
    }

    handleSubmit(e) {
        e.preventDefault();
        const {dispatch} = this.props;

        dispatch(this.props.handleSubmit(data => {
            const startDate = Moment(data["device_data"]["start_date"], DATE_FORMAT).toISOString();
            const endDate = Moment(data["device_data"]["end_date"], DATE_FORMAT).toISOString();
            const newPeriod = convertPeriodFromTimeWindow(startDate, endDate, data["device_data"]["period"]);
            data["device_data"]["period"] = newPeriod;
            this.props.onSubmitHandler(data, dispatch);
        }));
    }

    render() {
        return (
            <form className={this.props.className} key="deviceDataSearchForm" onSubmit={this.handleSubmit}
                  noValidate={true}>
                <ul className="app-breadcrumb breadcrumb flex-center">
                    <li className="emp-input-container emp-date-btn-short">
                        <Field name="device_data[start_date]" desiredStartTime={ONE_HOUR_AGO} title={"1h"}
                               changeFormValue={this.props.change}
                               component={this.renderButton.bind(this)}/>
                    </li>
                    <li className="emp-input-container emp-date-btn-short">
                        <Field name="device_data[start_date]" desiredStartTime={THREE_HOURS_AGO} title={"3h"}
                               changeFormValue={this.props.change}
                               component={this.renderButton.bind(this)}/>
                    </li>
                    <li className="emp-input-container emp-date-btn-short">
                        <Field name="device_data[start_date]" desiredStartTime={TWELVE_HOURS_AGO} title={"12h"}
                               changeFormValue={this.props.change}
                               component={this.renderButton.bind(this)}/>
                    </li>
                    <li className="emp-input-container emp-date-btn-short">
                        <Field name="device_data[start_date]" desiredStartTime={ONE_DAY_AGO} title={"1d"}
                               changeFormValue={this.props.change}
                               component={this.renderButton.bind(this)}/>
                    </li>
                    <li className="emp-input-container emp-date-btn-short emp-date-btn-short-last">
                        <Field name="device_data[start_date]" desiredStartTime={ONE_WEEK_AGO} title={"1w"}
                               changeFormValue={this.props.change}
                               component={this.renderButton.bind(this)}/>
                    </li>
                    <li className="emp-title-item env-date date-field">
                        <DateTimeField
                            type="text"
                            name="device_data[start_date]"
                            placeholder="Start Date"
                            className="form-control input_modifier border-0 app-title-shadow datepicker--input"
                            parentClass="app-title-shadow"
                            renderInput={(props) => getInputComponent(props, "Start Date")}
                            validate={[required, validateDateTimeFormat]}
                        />
                    </li>
                    <li className="emp-title-item">
                        <div className="app-title-right-widget-small">
                            <div className="info border-info">
                                <hr className="border-top-info"/>
                            </div>
                        </div>
                    </li>
                    <li className="emp-title-item env-date date-field">
                        <DateTimeField
                            type="text"
                            name="device_data[end_date]"
                            placeholder="End Date"
                            className="form-control input_modifier border-0 app-title-shadow datepicker--input"
                            parentClass="app-title-shadow"
                            renderInput={(props) => getInputComponent(props, "End Date")}
                            validate={[required, validateDateTimeFormat]}
                        />
                    </li>
                    <li className="emp-title-item em-title-widget">
                        <div className="app-title-right-widget-small">
                            <PeriodIntervalSelector fieldName="device_data[period]"/>
                        </div>
                    </li>
                    <li className="emp-title-item em-title-widget">
                        <div className="app-title-right-widget-small">
                            <button className="btn btn-primary">Submit</button>
                        </div>
                    </li>
                </ul>
            </form>
        );
    };
}

const mapStateToProps = (state) => {
    let start_date = Moment(new Date((new Date()).valueOf() - ONE_DAY_AGO).toISOString()).format(DATE_FORMAT);
    let end_date = Moment(new Date((new Date()).valueOf()).toISOString()).format(DATE_FORMAT);
    return ({
        initialValues: {
            device_data: {
                period: '5m',
                start_date: start_date,
                end_date: end_date
            }
        },
    });
};

export default connect(mapStateToProps)(reduxForm({
    form: 'deviceDataSearchForm',
})(DashboardSelectionNav));