import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import UpdatePasswordPage from '../../components/ChangePasswordPage/UpdatePasswordPage';
import { withRouter } from 'react-router';

//import './login.scss';

export const UpdatePassword = (props) => {
    const { auth,authActions } = props
    return (

        <div className="my-login-page">
            <UpdatePasswordPage auth={auth}
                                logout={authActions.logout}/>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.authReducer
});
const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch)
});

export default withRouter(connect(mapStateToProps,mapDispatchToProps)(UpdatePassword));