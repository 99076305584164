import Moment from 'moment';
export const BASE_URL = () => {
    let url;
    if (process.env.NODE_ENV === 'development')
        url = `http://localhost:3000`;

    if (process.env.NODE_ENV === 'production') {
        url = process.env.APP_API_URL || `https://api.empiric.io`
    }

    if (process.env.NODE_ENV === 'staging') {
        url = process.env.APP_API_URL || `https://api.beta.empiric.io`
    }

    if (!!process.env.APP_API_URL) {
        url = process.env.APP_API_URL;
    }
    // Once all env are set up, this should be removed
    // url = `https://api.beta.empiric.io`

    return url
};

export const PASSWORD_MIN_LEN = 6;
export const FIRST_NAME_REGEXP = /^[a-zA-Z]+$/;
//export const URL_REGEXP = /^((https?):\/\/)?([w|W]{3}\.)+[a-zA-Z0-9\-\.]{3,}\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/
export const URL_REGEXP = /^(http|https)?:\/\/[a-zA-Z0-9-\.]+\.[a-z]{2,4}/;
export const TWITTER_REGEXP = /(?:http:\/\/)?(?:www\.)?twitter\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/
export const FACEBOOK_REGEXP = /^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/;
export const LAST_NAME_REGEXP = /^[a-zA-Z]+$/;
export const EMAIL_REGEXP = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/i;

export const DASHBOARD_GRID_BPS = {lg: 1000,sm: 700};
export const DASHBOARD_GRID_COLS = {lg: 12, sm: 6};
let date = [];
let moment = Moment;

let a =moment().subtract({minutes: 30}).toDate().getTime()
let b = moment().subtract({minutes: 34}).toDate().getTime()
let c =moment().subtract({minutes: 31}).toDate().getTime()
let d =moment().subtract({minutes: 30}).toDate().getTime()
let e =moment().subtract({minutes: 28}).toDate().getTime()
let f =moment().subtract({minutes: 25}).toDate().getTime()
let g =moment().subtract({minutes: 22}).toDate().getTime()
let h =moment().subtract({minutes: 20}).toDate().getTime()
let i =moment().subtract({minutes: 19}).toDate().getTime()
let j =moment().subtract({minutes: 18}).toDate().getTime()
let k =moment().subtract({minutes: 17}).toDate().getTime()
let r =moment().subtract({minutes: 15}).toDate().getTime()
let l =moment().subtract({minutes: 12}).toDate().getTime()
let m =moment().subtract({minutes: 10}).toDate().getTime()
let n =moment().subtract({minutes: 8}).toDate().getTime()
let o =moment().subtract({minutes: 7}).toDate().getTime()
let p =moment().subtract({minutes: 5}).toDate().getTime()
let q =moment().subtract({minutes: 2}).toDate().getTime()
let day1 =moment().subtract({hours: 20}).toDate().getTime()
let day2 =moment().subtract({hours: 15}).toDate().getTime()
let day3 =moment().subtract({hours: 12}).toDate().getTime()
let day4 =moment().subtract({hours: 8}).toDate().getTime()
let day5 =moment().subtract({hours: 5}).toDate().getTime()
let day6 =moment().subtract({hours: 3}).toDate().getTime()
let s =moment().subtract({hours: 2}).toDate().getTime()
let t =moment().subtract({hours: 1}).toDate().getTime()
let u =moment().subtract({day: 2}).toDate().getTime()
let v =moment().subtract({day: 1}).toDate().getTime()

export const DASHBOARD_COLORS = ["#c624c9", "#43dfdf", "#0273eb", "#bd00ff", "#389e0d", "#9d4a75", "#1eae98", "#b8336a", "#0b8793","#ff6f00"];

export const DUMMY_CHART_DATA= {
    "status": "ok",
    "items": [
        {
            "node_address": "16bf2bb4-ec43-4f24-a3db-53b1852010d6",
            "timestamp": a,
            "battery_voltage": "52",
            "humidity": "63",
            "ambient_temperature": "39",
            "client_id": "test-client-id",
            "probe_temperature": "22",
            "sensor_id": "test-sensor-id-1"
        },
        {
            "node_address": "19e7eef6-6ff4-4bc7-a95f-5945317b3dc3",
            "timestamp": b,
            "battery_voltage": "95",
            "humidity": "40",
            "ambient_temperature": "60",
            "client_id": "test-client-id",
            "probe_temperature": "34",
            "sensor_id": "test-sensor-id-1"
        },
        {
            "node_address": "19e7eef6-6ff4-4bc7-a95f-5945317b3dc3",
            "timestamp": c,
            "battery_voltage": "85",
            "humidity": "85",
            "ambient_temperature": "20",
            "client_id": "test-client-id",
            "probe_temperature": "34",
            "sensor_id": "test-sensor-id-1"
        },
        {
            "node_address": "19e7eef6-6ff4-4bc7-a95f-5945317b3dc3",
            "timestamp": d,
            "battery_voltage": "95",
            "humidity": "35",
            "ambient_temperature": "60",
            "client_id": "test-client-id",
            "probe_temperature": "34",
            "sensor_id": "test-sensor-id-1"
        },
        {
            "node_address": "19e7eef6-6ff4-4bc7-a95f-5945317b3dc3",
            "timestamp": e,
            "battery_voltage": "75",
            "humidity": "45",
            "ambient_temperature": "20",
            "client_id": "test-client-id",
            "probe_temperature": "34",
            "sensor_id": "test-sensor-id-1"
        },
        {
            "node_address": "19e7eef6-6ff4-4bc7-a95f-5945317b3dc3",
            "timestamp": f,
            "battery_voltage": "65",
            "humidity": "65",
            "ambient_temperature": "30",
            "client_id": "test-client-id",
            "probe_temperature": "24",
            "sensor_id": "test-sensor-id-1"
        },
        {
            "node_address": "19e7eef6-6ff4-4bc7-a95f-5945317b3dc3",
            "timestamp": g,
            "battery_voltage": "62",
            "humidity": "55",
            "ambient_temperature": "28",
            "client_id": "test-client-id",
            "probe_temperature": "44",
            "sensor_id": "test-sensor-id-1"
        },
        {
            "node_address": "19e7eef6-6ff4-4bc7-a95f-5945317b3dc3",
            "timestamp": h,
            "battery_voltage": "62",
            "humidity": "55",
            "ambient_temperature": "28",
            "client_id": "test-client-id",
            "probe_temperature": "44",
            "sensor_id": "test-sensor-id-1"
        },
        {
            "node_address": "19e7eef6-6ff4-4bc7-a95f-5945317b3dc3",
            "timestamp": i,
            "battery_voltage": "56",
            "humidity": "55",
            "ambient_temperature": "23",
            "client_id": "test-client-id",
            "probe_temperature": "42",
            "sensor_id": "test-sensor-id-1"
        },
        {
            "node_address": "19e7eef6-6ff4-4bc7-a95f-5945317b3dc3",
            "timestamp": j,
            "battery_voltage": "52",
            "humidity": "55",
            "ambient_temperature": "38",
            "client_id": "test-client-id",
            "probe_temperature": "24",
            "sensor_id": "test-sensor-id-1"
        },
        {
            "node_address": "19e7eef6-6ff4-4bc7-a95f-5945317b3dc3",
            "timestamp": k,
            "battery_voltage": "62",
            "humidity": "55",
            "ambient_temperature": "28",
            "client_id": "test-client-id",
            "probe_temperature": "44",
            "sensor_id": "test-sensor-id-1"
        },
        {
            "node_address": "19e7eef6-6ff4-4bc7-a95f-5945317b3dc3",
            "timestamp": l,
            "battery_voltage": "31",
            "humidity": "25",
            "ambient_temperature": "68",
            "client_id": "test-client-id",
            "probe_temperature": "44",
            "sensor_id": "test-sensor-id-1"
        },
        {
            "node_address": "19e7eef6-6ff4-4bc7-a95f-5945317b3dc3",
            "timestamp": m,
            "battery_voltage": "62",
            "humidity": "55",
            "ambient_temperature": "28",
            "client_id": "test-client-id",
            "probe_temperature": "44",
            "sensor_id": "test-sensor-id-1"
        },
        {
            "node_address": "19e7eef6-6ff4-4bc7-a95f-5945317b3dc3",
            "timestamp": n,
            "battery_voltage": "34",
            "humidity": "49",
            "ambient_temperature": "30",
            "client_id": "test-client-id",
            "probe_temperature": "44",
            "sensor_id": "test-sensor-id-1"
        },
        {
            "node_address": "19e7eef6-6ff4-4bc7-a95f-5945317b3dc3",
            "timestamp": o,
            "battery_voltage": "39",
            "humidity": "35",
            "ambient_temperature": "18",
            "client_id": "test-client-id",
            "probe_temperature": "44",
            "sensor_id": "test-sensor-id-1"
        },
        {
            "node_address": "19e7eef6-6ff4-4bc7-a95f-5945317b3dc3",
            "timestamp": p,
            "battery_voltage": "42",
            "humidity": "25",
            "ambient_temperature": "48",
            "client_id": "test-client-id",
            "probe_temperature": "44",
            "sensor_id": "test-sensor-id-1"
        },
        {
            "node_address": "19e7eef6-6ff4-4bc7-a95f-5945317b3dc3",
            "timestamp": q,
            "battery_voltage": "32",
            "humidity": "15",
            "ambient_temperature": "58",
            "client_id": "test-client-id",
            "probe_temperature": "24",
            "sensor_id": "test-sensor-id-1"
        }
    ],

    "last_evaluated_key": {
        "timestamp": {
            "S": "2018-10-14T00:05:00"
        },
        "sensor_id": {
            "S": "test-sensor-id-1"
        }
    },
    "unit": "5m"
};