import axios from "axios";
import storage from "../utils/storage";
import {BASE_URL} from "../config";
import DeviceType from "./model/DeviceType";
import {buildAxiosErrorHandlerConfig} from "../utils/utils";

export const convertApiResponseToDeviceType = (data: any): DeviceType => {
    return {
        id: data.id,
        organization_id: data.organization_id,
        name: data.name,
        description: data.description,
        icon_url: data.icon_url ? data.icon_url.url : null,
        image_url: data.image_url ? data.image_url.url : null,
        created_by_id: data.created_by_id,
        updated_by_id: data.updated_by_id,
        created_at: new Date(data.created_at),
        updated_at: new Date(data.updated_at),
    };
};

const convertDeviceTypeFormToApiRequest = (values: any) => {
    return {
        id: values.id,
        name: values.name,
        description: values.description,
        ...(values.icon && values.icon.length ? {icon_url: values.icon[0]} : {}),
        ...(values.image && values.image.length
            ? {image_url: values.image[0]}
            : {}),
    };
};

export const listDeviceTypes = (data: any) => {
    return axios
        .get(`${BASE_URL()}/api/v1/device_types`, {
            params: data,
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: storage.get("token"),
            },
        })
        .then((response) =>
            response.data.items.map((item: any) => convertApiResponseToDeviceType(item))
        );
};

export const getDeviceType = (id: string) => {
    return axios
        .get(`${BASE_URL()}/api/v1/device_types/${id}`, buildAxiosErrorHandlerConfig({
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: storage.get("token"),
            },
            defaultErrorHandler: true,
        }))
        .then((response) => convertApiResponseToDeviceType(response.data));
};

export const createDeviceType = (data: any) => {
    const formData = new FormData();
    const request = convertDeviceTypeFormToApiRequest(data);

    Object.keys(request).forEach((key) => {
        formData.append(`device_type[${key}]`, request[key]);
    });
    return axios
        .post(`${BASE_URL()}/api/v1/device_types`, formData, buildAxiosErrorHandlerConfig({
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: storage.get("token"),
            },
            defaultErrorHandler: true,
        }))
        .then((response) => convertApiResponseToDeviceType(response.data));
};

export const updateDeviceType = (id: string, data: any) => {
    const formData = new FormData();
    const request = convertDeviceTypeFormToApiRequest(data);

    Object.keys(request).forEach((key) => {
        formData.append(`device_type[${key}]`, request[key]);
    });

    return axios
        .put(`${BASE_URL()}/api/v1/device_types/${id}`, formData, buildAxiosErrorHandlerConfig({
            headers: {
                Accept: "application/json",
                Authorization: storage.get("token"),
            },
            defaultErrorHandler: true,
        }))
        .then((response) => convertApiResponseToDeviceType(response.data));
};

export const deleteDeviceType = (id: string) => {
    return axios
        .delete(`${BASE_URL()}/api/v1/device_types/${id}`, {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: storage.get("token"),
            },
        });
}
