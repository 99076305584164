import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';


export const updateGateway = (deviceValues) => {
    if (deviceValues['device']['id']){
        return axios.put(`${BASE_URL()}/api/v1/gateways/${deviceValues['device']['id']}`,deviceValues, { headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response =>  response.data);
    }
    else{
        return axios.post(`${BASE_URL()}/api/v1/gateways`,deviceValues, { headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response =>  response.data);
    }

};

export const deleteGateway = (values) => {
    return axios.delete(`${BASE_URL()}/api/v1/gateways/${values}`, { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': storage.get('token')
    }}).then(response => {
        return response.data;
    });

};
export const fetchGatewayData = (data) => {
    return axios.get(`${BASE_URL()}/api/v1/gateways`, {
        params: data,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response => response.data);
};
export const searchGatewayData = (data) => {
    return axios.get(`${BASE_URL()}/api/v1/gateways/search_group`, {
        params: data,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response => response.data);
};

export const fetchGateway = (data) => {
    return axios.get(`${BASE_URL()}/api/v1/gateways/${data}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }}).then(response => response.data);
};