import React from 'react';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import * as Datetime from 'react-datetime';
import moment from 'moment';

const renderField = ({input, label, placeholder, maxLength, value, autoComplete, type, parentClass, className, inputGroup, pickerType, dataFormat, timeFormat, renderInput, meta: {touched, asyncValidating, error}}) => {
    const showError = touched && error;
    return (
        <div className={parentClass}>
            {label !== null && <label className="form-group__label label_modifier">{label}</label>}
            <div
                className={`form-group__field ${inputGroup.length > 0 ? inputGroup[0].inputGroupClass : ''} ${asyncValidating ? 'async-validating' : ''} ${showError ? 'border-error' : ''}`}>
                <div className="date_time_react">

                    <Datetime inputProps={{...input, className: className, placeholder: convertPlaceholder(input)}}
                              dateFormat={dataFormat}
                              onChange={param => {
                                  if (typeof (param) === "string") return;
                                  input.onChange(param.format(dataFormat)
                                  )
                              }}
                              renderInput={renderInput || null}
                              timeFormat={timeFormat}/>
                </div>
            </div>
            {showError && <div className="form-group__error">{error}</div>}
        </div>
    );
};

export const DateTimeField = ({type, name, label, placeholder, maxLength, value, autoComplete, parentClass, className, inputGroup, pickerType, dataFormat, renderInput, validate, timeFormat}) => (
    <Field
        type={type}
        placeholder={placeholder}
        name={name}
        label={label}
        maxLength={maxLength}
        autoComplete={autoComplete}
        className={className}
        parentClass={parentClass}
        inputGroup={inputGroup}
        pickerType={pickerType}
        dataFormat={dataFormat}
        component={renderField}
        renderInput={renderInput}
        timeFormat={timeFormat}
        validate={validate}
    />
);

DateTimeField.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    className: PropTypes.string,
    parentClass: PropTypes.string,
    maxLength: PropTypes.number,
    inputGroup: PropTypes.array,
    pickerType: PropTypes.string,
    value: PropTypes.string,
    dataFormat: PropTypes.string,
    autoComplete: PropTypes.oneOf(['on', 'off']),
    renderInput: PropTypes.func,
    validate: PropTypes.array,
    timeFormat: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string
    ])
};

DateTimeField.defaultProps = {
    type: 'text',
    label: null,
    placeholder: '',
    className: '',
    parentClass: '',
    value: '',
    inputGroup: [],
    maxLength: null,
    pickerType: '',
    dataFormat: 'MM-DD-YYYY hh:mm A',
    autoComplete: null,
    renderInput: undefined,
    validate: undefined,
    timeFormat: 'hh:mm a'
};

function convertPlaceholder(input) {
    const convertedValue = getValueFromPlaceholder(input.value);

    if (convertedValue) {
        input.onChange(convertedValue.format('MM-DD-YYYY hh:mm A'));
    }
    return "";
}

function getValueFromPlaceholder(placeholder) {
    switch (placeholder) {
        case "NOW":
            return moment();
        case "TWO_DAYS_AGO":
            return moment().subtract(2, 'days').startOf('day');
        case "TWO_DAYS_FROM_NOW":
            return moment().add(2, 'days');
        default:
            return null;
    }
}