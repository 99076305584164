import jsPDF from 'jspdf'
import moment from 'moment'

import { FormSectionProps, FormTemplateProps, PDFFields } from '../formSchema'
import { SORT_BY_FIELD_INDEX } from './formUtils'

const maxPageHeight = 280

const generatePageHeading = (
  doc: jsPDF,
  section: FormSectionProps,
  heading: string[]
) => {
  // Page heading
  doc.setFontSize(10)
  doc.text(heading[0], 10, 10)
  doc.text(heading[1], 200, 10, { align: 'right' })

  // Section heading
  const page = section.order_index;
  let pageNumberYPos = 20
  // Section heading
  doc.setFontSize(14)
  doc.text(section.section_name, 10, 20)

  // Section description & page number
  doc.setFontSize(12)

  // Section description
  if (section.section_description) {
    doc.text(section.section_description, 10, 26)
    // Update page number position
    pageNumberYPos = 26
  }

  // Add page number
  doc.text(`Page ${page}`, 200, pageNumberYPos, { align: 'right' })
  doc.line(10, pageNumberYPos + 2, 200, pageNumberYPos + 2)
}

const addFieldToPage = (
  doc: jsPDF,
  fieldVal: string,
  yPos: number,
  maxWidth: number = 190,
  xPos: number = 10,
  group: boolean = false
) => {
  // Add value to page
  doc.text(fieldVal, xPos, yPos, { maxWidth: maxWidth })

  // Update Y position
  if (!group) {
    yPos += 10
  }

  return yPos
}

const generateFormFields = (
  doc: jsPDF,
  section: FormSectionProps,
  yPos: number,
  initalYPos: number,
  heading: string[],
  template: FormTemplateProps
) => {
  const { field_instances } = section

  const parentFields = field_instances
    .filter((field) => !field.parent_id)
    .sort(SORT_BY_FIELD_INDEX)
  const childFields = field_instances.filter((field) => field.parent_id)

  // Map children fields to parent fields
  parentFields.map((field, index) => {
    const lastField = index < field_instances.length - 1

    // If group, organize in row
    if (field.field_type === 'group') {
        // Get children fields
      const children = childFields
        .filter((child) => child.parent_id === field.id)
        .sort(SORT_BY_FIELD_INDEX)

        // Map children fields
        // Add children fields to page
      children.map((child, index) => {
        const maxWidth = 190 / children.length
        const xPos = 10 + maxWidth * index
        let val = fieldValue(child, template)

        if (section.section_name.includes('QC')) {
          val = generateQCFieldVal(child)
        }

        addFieldToPage(doc, val, yPos, maxWidth, xPos, true)

        // doc.text(val, xPos, yPos, { maxWidth: maxWidth})
      })

      // Increment Y position
      yPos += 10

    } else {
      const val = fieldValue(field, template)

      if (val) {
        yPos = addFieldToPage(doc, val, yPos)
      }
    }

    if (yPos > maxPageHeight && !lastField) {
      doc.addPage()
      yPos = initalYPos
      generatePageHeading(doc, section, heading)
    }
  })
}

const generateQCFieldVal = (field: PDFFields) => {
  const fieldName = field.field_name ? `${field.field_name}` : ''
  const fieldVal = field.value
    ? `${fieldName}${fieldName && ':'} ${field.value}`
    : fieldName

  return fieldVal
}

const fieldValue = (field: PDFFields, template?: FormTemplateProps) => {
  const fieldWithNoInput = ['heading', 'heading-bold', 'instruction', 'note']

  // Empty field - found in tests
  // Group fields
  if (!field.field_name || field.field_type === 'group') return

  // Non-input fields
  if (fieldWithNoInput.includes(field.field_type)) {
    return field.field_name
  }

  // Template fields
  else if (
    field.field_type === 'template' &&
    field.template_value &&
    template
  ) {
    const value = template[field.template_value] || 'N/A'
    if (!field.field_name) return value
    return `${field.field_name}: ${value}`
  }

    //   Time field
    else if (field.field_type.includes('time')) {
        const value = field.value ? `${moment(field.value).format('LLLL')}` : 'N/A'
        if (!field.field_name) return value
        return `${field.field_name}: ${value}`
    }


  // Input fields
  else {
    const value = field.value ? `${field.value}` : 'N/A'
    if (!field.field_name) return value
    return `${field.field_name}: ${value}`
  }
}

export const generatePdf = (formData: {
  template: FormTemplateProps
  sections: FormSectionProps[]
  updated_at: string
}) => {
  const doc = new jsPDF()

  const { template, sections } = formData

  // Page heading
  const heading = [
    template.product_name,
    'Last Updated: ' + moment(formData.updated_at).format('LLL')
  ]

  //Sections
  sections.forEach((section, index) => {
    const { field_instances } = section
    const initalYPos = section.section_description ? 36 : 32

    // Starting position of fields
    let fieldsYPos = initalYPos

    if (index > 0) {
      doc.addPage()
    }

    // Section heading
    generatePageHeading(doc, section, heading)

    // Add fields to page, based on groups or individual fields
    generateFormFields(doc, section, fieldsYPos, initalYPos, heading, template)

  })

  return doc.output('datauristring')
}
