import React from 'react';
import Slider,{Range} from 'rc-slider';
import Tooltip from 'rc-tooltip';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import Moment from 'moment';
const createSliderWithTooltip = Slider.createSliderWithTooltip;
//const Range = createSliderWithTooltip(Slider.Range);
const Handle = Slider.Handle;
const moment = Moment;

export default class TimeSlider extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            startTime: 0,
            endTime: 0
        };
        this.handle = this.handle.bind(this);
        this.tipFormat = this.tipFormat.bind(this);
        this.setTime = this.setTime.bind(this);
    }
    setTime (nxtProps) {
        this.setState({startTime: nxtProps[0],endTime: nxtProps[1]});
    }
    handle = (props) => {
        const { value, dragging, index, ...restProps } = props;
        //this.setTime(props);
        return (
            <Tooltip
                prefixCls="rc-slider-tooltip"
                overlay={value}
                visible={dragging}
                placement="bottom"
                key={index}
                style= {{backgroundColor: "#000"}}
                overlay= {(e)=> {return this.tipFormat(value)}}
                >
                <Handle value={value} {...restProps} />
            </Tooltip>
        );
    };
    tipFormat = (value) => {
        let hours = Math.floor(value/60);
        let minutes = Math.round((value % 60)*100)/100;
        return moment(hours + ':' + minutes,"LT").format('hh:mm A');
    };
    render() {
        return (
            <div>
                {/*<Slider min={0} max={20} range={true} toolTipVisibleAlways={true} defaultValue={3} handle={handle} />*/}
                <Range  min={360} max={1440} step={1}
                        onAfterChange={e => this.setTime(e)}
                        railStyle={{background: '#D7D7D7'}}
                        trackStyle={[{ backgroundColor: '#0273eb' }, { backgroundColor: '#0273eb' }]}
                        handleStyle={[{cursor: 'pointer',backgroundColor: '#0273eb',border: 'solid 1px #0273eb'  }, {cursor: 'pointer',backgroundColor: '#0273eb',border: 'solid 1px #0273eb'  }]}
                        allowCross={false} defaultValue={[390, 1410]} handle={this.handle} />
            </div>
        )
    }
i}