import React from 'react';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import * as usersApi from '../../api/userApi.js';
import * as deviceApi from '../../api/deviceApi.ts';
import * as groupApi from '../../api/groupApi.js';
import store from '../../store/store';
//import './destinationSearchBar.scss';

export default class SearchInput extends React.Component {
    state = {
        value: '',
        selectedSuggestion: null,
        loading: false,
        dataList: []
    };

    lastRequestId = null;

    static contextTypes = {
        router: PropTypes.object
    };

    static propTypes = {
        size: PropTypes.string,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        shouldTakeValue: PropTypes.bool
    };

    static defaultProps = {
        size: 'md',
        className: '',
        placeholder: '',
        shouldTakeValue: false
    };

    constructor (props) {
        super(props);

        this.onInputChange = this.onInputChange.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        this.renderSuggestionItem = this.renderSuggestionItem.bind(this);
        this.dropdownData = this.dropdownData.bind(this);
    }

    defineValue (newValue) {
        this.setState({
            value: newValue
        });
    }

    componentDidMount () {
        if (!this.props.shouldTakeValue) return;

        const routerParams = this.context.router.params;
        if (routerParams && routerParams.locationName) {
            const isSameValue = routerParams.locationName === this.state.value;

            if (isSameValue) return;

            this.defineValue(routerParams.locationName);
        }
    }

    async fetchSuggestions (name) {
        try {
            if (this.lastRequestId) {
                this.lastRequestId.cancel('Operation canceled by the user.');
            }

            this.setState({ loading: true });
            let dataList;
            if (this.props.type === 'member')
                dataList = await usersApi.searchMember(name);
            else if (this.props.type === 'group') {
                dataList = await groupApi.searchGroup({name: name});
                dataList = dataList.groups;
            }
            else if (this.props.type === 'device-active') {
                dataList = await deviceApi.searchDeviceActive(name);
            }
            else if (this.props.type === 'group-and-device-active') {
                const requests = await Promise.all(
                    [deviceApi.searchDeviceActive(name), groupApi.searchGroup({name: name})]
                ).catch(e=>console.error(e));
                const tmp = []
                requests.forEach(element=>{
                    if(element.hasOwnProperty("groups")){
                        tmp.push(...element.groups);
                    }
                    else{
                        tmp.push(...element);
                    }
                });
                dataList = tmp;
            }
            else if (this.props.type === 'group-and-sensor-device-active') {
                const requests = await Promise.all([
                    groupApi.searchGroup({name: name}), deviceApi.searchDeviceActiveByType({name, device_type: "sensor"})
                ]).catch(e=>console.error(e));
                const tmp = []
                requests.forEach(element=>{
                    if(element.hasOwnProperty("groups")){
                        tmp.push(...element.groups);
                    }
                    else{
                        tmp.push(...element);
                    }
                });
                dataList = tmp;
            }
            else {
                dataList = await deviceApi.searchDevice(name);
            }
            this.setState({
                loading: false,
                dataList: dataList
            });

            this.lastRequestId = null;
        } catch (e) {
            if (!axios.isCancel(e)) {
                this.setState({ loading: false });
            }
        }
    }

    onInputChange (e, { newValue }) {
//state.usersReducer. = newValue;
        this.setState({
            value: newValue,
            selectedSuggestion: null
        });
    }
    async onSuggestionSelected (e, { suggestion }) {
        let type = this.props.type === "member" ? 'add' : this.props.type;
        if((this.props.type === 'group-and-device-active' || this.props.type === 'group-and-sensor-device-active') && suggestion.hasOwnProperty("group") && suggestion.group){
            const selectedGroup = await groupApi.fetchGroup(suggestion.id);
            const devices = selectedGroup.group.devices;
            devices.forEach(device=>{
                this.props.handleChange(device,type);
            });
        }else{
            this.props.handleChange(suggestion,type);
        }
    }
    onSuggestionsFetchRequested ({ value }) {
        const LOCATION_REGEXP = /^[A-Za-z0-9\s]*$/;

        if (value === '' || !LOCATION_REGEXP.test(value)) return;

        this.fetchSuggestions(value);
    }
    componentWillReceiveProps (nextProps) {
        if (this.props.type === 'group') {
            let value = nextProps.value ? nextProps.value : '';
            this.setState({value: value});
        }
    }
    onSuggestionsClearRequested () {
        this.setState({ dataList: [], loading: false });
    }

    renderSuggestionItem (suggestion) {
        let title;
        let option;
        if (this.props.type === "device" || this.props.type === "device-active"){
            title = suggestion.serial_number;
            option =   this.dropdownData(title,suggestion.name,require("../../styles/images/icon/ic_gateway.png"));
        }
        else if (this.props.type === "group-and-device-active" || this.props.type === "group-and-sensor-device-active"){
            if(suggestion.hasOwnProperty("group") && suggestion.group){
                title = suggestion.serial_number;
                option = this.dropdownData(title,suggestion.name,require("../../styles/images/icon/ic_group.png"));
            }
            else {
                title = suggestion.name;
                option = this.dropdownData(title,'',require("../../styles/images/icon/ic_gateway.png"));
            }
        }
        else if (this.props.type === "group"){
            title = suggestion.name;
            option =   this.dropdownData(title,'',require("../../styles/images/icon/ic_group.png"));
        }
        else{
            title = suggestion.first_name;
            option =  this.dropdownData(title,suggestion.email,require("../../styles/images/icon/icn_user.png"));
        }
        return option;
    }

    dropdownData(title,data,img) {
        return (
            <div className="emp_create_notify_box">
                <div className="card border-0">
                    <div className="card-body">
                        <div className="media align-items-center">
                            <div className="emp_user mr-3">
                                <img className="rounded-circle" src={img} alt="Generic placeholder image" />
                            </div>
                            <div className="media-body">
                                <h6 className="mt-0 mb-0">{title}</h6>
                                <span className="emp_txt_blured">{data}</span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    getSuggestionValue(suggestion) {
        let title = suggestion.email ? suggestion.email : suggestion.name;
        return (suggestion.group ? title : '');
    }

    render () {

        const { name,size, className, placeholder } = this.props;
        const { value, dataList } = this.state;
        let disabled;
        if (this.props.hasOwnProperty("isDisabled") && this.props.isDisabled === true) {
            // alert is being edited, sensor field should be disabled because we can't add multiple sensors with put requests
            disabled = true;
        }
        else{
            disabled = false;
        }

        const inputProps = {
            value: value,
            type: 'search',
            placeholder: placeholder || '',
            name: name,
            className: `form-control input_modifier`,
            onChange: this.onInputChange,
            onKeyDown: this.onKeyDown,
            onClick: event => event.target.select(),
            disabled: disabled
        };
        return (
            <div className={`destination-search-bar destination-search-bar--${size} ${className}`}>

                <Autosuggest
                    inputProps={inputProps}
                    suggestions={dataList}
                    highlightFirstSuggestion={true}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestionItem}
                    />


            </div>
        );
    }
}