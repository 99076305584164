import React from "react";
import {Field, Form, reduxForm} from "redux-form";
import * as $ from "jquery";
import {NoLabelFileInputField} from "../FileInputField/NoLabelFileInputField";
import {connect} from "react-redux";
import PropTypes from 'prop-types';
import {TextField} from "../../ui/Input/TextField";
import {SelectField} from "../../ui/Input/SelectField";
import {Asset} from "../../api/model/Asset";
import {AssetType} from "../../api/model/AssetType";

const titleField = ({input, label, meta: {touched, error}}) => {
    return (
        <TextField label={label} error={touched && error} errorLabel={error} {...input}/>
    );

}

const selectField = ({input, label, items, meta: {touched, error}}) => {
    return <SelectField label={label}
                        placeholder=""
                        error={touched && error}
                        errorLabel={error}
                        value={input.value}
                        {...input}
                        items={items}
    />
}

class AssetForm extends React.Component<{
    handleSubmit: (e) => void,
    submitting: boolean,
    asset: Asset,
    assetTypes: AssetType[],
    onAssetSubmit: (e) => void
}> {
    constructor(props) {
        super(props);
    }

    render() {
        const {handleSubmit, asset, onAssetSubmit} = this.props;

        return <Form onSubmit={handleSubmit(onAssetSubmit)}>
            <div className={"container-fluid"}>
                <div className={"row mt-2"}>
                    <span className={"emp-type-h2 col"}>{asset.name || 'Asset'}</span>
                </div>

                <div className={"row"}>
                    <div className={"col"}>
                        <div className="emp-type-subtitle1">
                            <div className={"mt-4"}>
                                {this.renderAssetCard()}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Form>;
    }

    renderTextRow(fieldName, label) {
        return (
            <div className="row mb-3">
                <div className={"col-12 col-sm-6"}>
                    <Field name={fieldName} label={label} component={titleField}/>
                </div>
            </div>
        );
    }

    renderSelectFieldRow(fieldName, label, items) {
        return (
            <div className="row mt-2 mb-3">
                <div className={"col-12 col-sm-6"}>
                    <Field name={fieldName} label={label} items={items} component={selectField}/>
                </div>
            </div>
        );
    }

    renderAssetImage() {
        const handleFiles = (e) => {
            let name = e.currentTarget.files.length > 0 ? $.trim(e.currentTarget.files[0].name).substring(0, 30).trim(this) + "..." : '';
            $('.organization_logo').html(name);
            if (e.target.files.length > 0) {
                let url = URL.createObjectURL(e.target.files[0]);
                $('#preview_img').attr('src', url);
            } else {
                $('#preview_img').attr('src', require("../../styles/images/icon/logo.png"));
            }
        };

        const {asset} = this.props;
        const imageUrl = asset.imageUrl ? asset.imageUrl : "https://via.placeholder.com/250?text=Asset+PlaceHolder";
        return <>
            <div className={"row mb-3 mb-sm-2"}>
                <div className={"col-12"}>
                    <img id="preview_img" className="placeholder img-thumbnail"
                         src={imageUrl}></img>
                </div>
            </div>
            <div className={"row"}>
                <div className={"col-12"}>
                    <NoLabelFileInputField
                        type="file"
                        name="image"
                        placeholder="New York, NY, USA"
                        //label="Choose Logo "
                        HandleChange={(e) => handleFiles(e)}
                        //iconClass="fa fa-info-circle"
                        className="display-none"
                        inputGroupLabel={""}
                        inputGroupLabelClass="custom-file-label emp_cst_file_label organization_logo pointer-none"
                        inputGroupClass="input-group mb-3 cst-upload"
                        htmlFor="inputGroupFile01"
                        id="inputGroupFile01"
                    />
                </div>
            </div>
        </>
    }


    renderAssetCard() {
        const {submitting, assetTypes} = this.props;

        const assetTypeItems = assetTypes.map(assetType => ({value: assetType.id, label: assetType.name}))
        return (
            <>
                <div className="row mb-2">
                    <div className="col-12 col-sm-4">
                        {this.renderAssetImage()}
                    </div>

                    <div className="col">
                        <>
                            {this.renderTextRow("name", "Name")}
                            {this.renderTextRow("description", "Description")}
                            {this.renderSelectFieldRow("assetTypeId", "Asset Type", assetTypeItems)}
                            <div className={"row"}>
                                <div className={"col-0 col-sm"}></div>
                                <div className={"col-12 col-sm-8"}>
                                    <button type="submit" disabled={submitting} className={"btn btn-primary"}><span
                                        className={"emp-type-btn-primary"}>SAVE</span>
                                    </button>
                                </div>
                            </div>
                        </>
                    </div>
                </div>

            </>
        );
    }
}

export const validate = (values) => {
    const errors = {};
    let requiredFields = ['name', 'description', 'assetTypeId'];

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Required';
        }
    });
    return errors;
};
export const mapStateToProps = (state) => {
    return ({
        asset: state.assetReducer.asset,
        assetTypes: state.assetTypeReducer.assetTypes,
        initialValues: state.assetReducer.asset
    });
};
export const FORM_NAME = "assetForm";

const wrappedAssetForm = connect(mapStateToProps)(
    reduxForm({
        form: FORM_NAME,
        enableReinitialize: true,
        validate
    })(AssetForm)
);

export default wrappedAssetForm;