import {createStore, applyMiddleware, compose} from 'redux';
import loggerMiddleware from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import promiseMiddleware from 'redux-promise-middleware';

import reducers from '../reducers';

export function configureStore (initialState = {}) {
    let composeEnhancers = compose;
    let middleWares = [promiseMiddleware(), thunkMiddleware];

    if (process.env.NODE_ENV === 'development') {
        if (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) {
            composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
        }
        middleWares.push(loggerMiddleware);
    }

    const enhancer = applyMiddleware(...middleWares);
    const store = createStore(reducers, initialState, composeEnhancers(enhancer));

    if (module.hot && process.env.NODE_ENV === 'development') {
        module.hot.accept('../reducers', () => {
            store.replaceReducer(require('../reducers').default)
        });
    }

    return store;
}

export default configureStore();