import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import * as AssetTypeActions from '../../actions/assetTypeActions';
import Header from '../../components/Header/Header';
import {Sidebar} from '../../components/Sidebar/Sidebar';
import {browserHistory, withRouter} from 'react-router';
import LoadingMessage from "../../components/LoadingMessage/LoadingMessage";
import {AssetTypeEditDetailPage} from "../../components/AssetTypes/AssetTypeEditDetailPage";

class AssetTypeEditDetailPageContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            metrics: []
        }
        this.onSubmit = this.onSubmit.bind(this);
    }

    async componentDidMount() {
        const id = this.props.params.asset_type_id;
        this.setState({loading: true})
        await this.props.assetTypeActions.getAssetType(id);
        this.setState({loading: false})
    }

    async onSubmit(values) {
        this.setState({loading: true});
        values.id = this.props.params.asset_type_id;
        await this.props.assetTypeActions.updateAssetType(this.props.params.asset_type_id, values);
        this.setState({loading: false});
        browserHistory.push(`/asset_types/${this.props.params.asset_type_id}`);
    }

    render() {
        const {auth, authActions, assetType} = this.props;
        const {loading} = this.state;

        return (

            <div className="app sidebar-mini rtl emp-sidebar">
                <Header
                    isLoggedIn={auth.isLoggedIn}
                    user={auth.user}
                    logout={authActions.logout}
                    headerclassName="app-header"
                />
                <Sidebar isLoggedIn={auth.isLoggedIn}
                         user={auth.user}
                         logout={authActions.logout}
                         headerclassName="app-header"/>
                {loading ? <LoadingMessage /> :
                    <AssetTypeEditDetailPage assetType={assetType} onSubmit={this.onSubmit}/>
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    assetType: state.assetTypeReducer.assetType,
});

const mapDispatchToProps = (dispatch) => ({
    authActions: bindActionCreators(AuthActions, dispatch),
    assetTypeActions: bindActionCreators(AssetTypeActions, dispatch),
});


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AssetTypeEditDetailPageContainer));