export const scheduledReportConstants = {

    FETCH_SCHEDULED_REPORT_DATA: 'FETCH_SCHEDULED_REPORT_DATA',
    FETCH_SCHEDULED_REPORT_DATA_FULFILLED: 'FETCH_SCHEDULED_REPORT_DATA_FULFILLED',

    DELETE_SCHEDULED_REPORT: 'DELETE_SCHEDULED_REPORT',
    DELETE_SCHEDULED_REPORT_FULFILLED: 'DELETE_SCHEDULED_REPORT_FULFILLED',

    CREATE_SCHEDULED_REPORT: 'CREATE_SCHEDULED_REPORT',
    CREATE_SCHEDULED_REPORT_FULFILLED: 'CREATE_SCHEDULED_REPORT_FULFILLED'
};