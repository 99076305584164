import React from 'react';
import ReactTable from 'react-table';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';

import * as EnvironmentActions from '../../actions/environmentAction';
import LoadingMessage from "../LoadingMessage/LoadingMessage";
import {formatTimestampDateTime} from "../../utils/formatHelper";

class DeviceConnectionTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            columns: [
                {
                    Header: () => this.styleCell('Gateway Name',null),
                    accessor: "gateway_name",
                    columnName: "Gateway Name",
                    Cell: (row) => this.styleCell(row.value, 'text-center')
                },
                {
                    Header: () => this.styleCell('Connection Time',null),
                    accessor: 'connection_start_time',
                    columnName: 'Connection Time',
                    Cell: (row) => this.styleCell(row.value, 'text-center')
                }
            ]
        };
        this.renderTable = this.renderTable.bind(this);
    }

    componentDidMount() {
        this.props.environmentActions.getGatewayConnectionHistory({device_id: this.props.deviceId});
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.environmentReducer.gatewayConnectionHistoryUpdated) {
            this.setState(state => {
                return {
                    loading: false,
                    device: nextProps.environmentReducer.detailDeviceData
                }
            });
            nextProps.environmentReducer.gatewayConnectionHistoryUpdated = false;
        }
    }

    createListItems(sensorGatewayConnections) {
        const data = sensorGatewayConnections.map(item => {
            return {
                "gateway_name": item.gatewayName,
                "connection_start_time": formatTimestampDateTime(item.connectionStartTime)
            }
        });
        return data.sort((a, b) =>{
            return new Date(b.connection_start_time).getTime() - new Date(a.connection_start_time).getTime()
        });
    }

    renderTable(){
        return (
            <div>
                    <h2 className="text-align-center">Gateway Connection Table</h2>
                    <ReactTable
                        columns={this.state.columns}
                        data={this.createListItems(this.props.environmentReducer.gatewayConnectionHistory)}
                        defaultPageSize={5}
                        className='-striped'
                        showPagination={true}
                        minRows={5}
                    />

            </div>
        ) 
    }

    render() {
        let contentArea = (this.state.loading) ?
              <LoadingMessage />
             : this.renderTable()
        return contentArea;
    }

    styleCell = (label, classes) => {
        if (classes)
            return <div className={classes}>{label}</div>
        return <div>{label}</div>
    }
}

const mapStateToProps = (state) => {
    return ({
        environmentReducer: state.environmentReducer,
    });
};

const mapDispatchToProps = (dispatch) => ({
    environmentActions: bindActionCreators(EnvironmentActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(DeviceConnectionTable);
