export const operator = {
    equals: '=',
    notEquals: '!=',
    contains: 'contains',
    notContains: 'not Contains',
    gt: '>',
    gte: '>=',
    le: '<',
    lte: '<=',
    isOneOf: 'is One Of',
}