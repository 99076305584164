import React from 'react';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import * as groupApi from '../../api/groupApi.js';


export default class GroupDropDownField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            value: '',
            selectedSuggestion: null,
            loading: false,
            dataList: []
        };

        this.onInputChange = this.onInputChange.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.renderSuggestionItem = this.renderSuggestionItem.bind(this);
        this.dropdownData = this.dropdownData.bind(this);
    }

    onInputChange(e, {newValue}) {
        this.setState({
            value: newValue,
            selectedSuggestion: null
        });
    }

    async onSuggestionsFetchRequested({value}) {
        try {

            const results = await groupApi.searchGroup({name: value});
            const dataList = results.groups;

            const selections = this.props.selections || [];
            const selectedIds = selections.map(item => item.id);
            const filteredDataList = dataList.filter(item => !selectedIds.includes(item.id));

            this.setState({
                loading: false,
                dataList: filteredDataList
            });

        } catch (e) {
            if (!axios.isCancel(e)) {
                this.setState({loading: false});
            }
            console.error(e)
        }
    }

    renderSuggestionItem(suggestion) {
        const title = suggestion.name;
        const option = this.dropdownData(title, '', require("../../styles/images/icon/ic_group.png"));
        return option;
    }

    componentWillReceiveProps(nextProps) {
        const value = nextProps.value ? nextProps.value : '';
        this.setState({value: value});
    }

    dropdownData(title, data, img) {
        return (
            <div className="emp_create_notify_box">
                <div className="card border-0">
                    <div className="card-body">
                        <div className="media align-items-center">
                            <div className="emp_user mr-3">
                                <img className="rounded-circle" src={img} alt="Generic placeholder image"/>
                            </div>
                            <div className="media-body">
                                <h6 className="mt-0 mb-0">{title}</h6>
                                <span className="emp_txt_blured">{data}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const {name, size, className, placeholder} = this.props;
        const {value, dataList} = this.state;
        // alert is being edited, sensor field should be disabled because we can't add multiple sensors with put requests
        const disabled = this.props.isDisabled ? true : false;

        const inputProps = {
            value: value,
            type: 'search',
            placeholder: placeholder || '',
            name: name,
            className: `form-control input_modifier ${this.props.classNames}`,
            onChange: this.onInputChange,
            onKeyDown: this.onKeyDown,
            onClick: event => event.target.select(),
            disabled: disabled
        };
        return (
            <div className={`destination-search-bar destination-search-bar--${size} ${className}`}>
                <Autosuggest
                    inputProps={inputProps}
                    suggestions={dataList}
                    highlightFirstSuggestion={true}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={() => this.setState({dataList: [], loading: false})}
                    onSuggestionSelected={(e, {suggestion}) => this.props.handleChange(suggestion)}
                    getSuggestionValue={(suggestion) => suggestion.name}
                    renderSuggestion={this.renderSuggestionItem}
                    shouldRenderSuggestions={this.props.shouldRenderSuggestions}
                    renderInputComponent={this.props.renderInputComponent}
                />
            </div>
        );
    }
}

const propTypes = {
    placeholder: PropTypes.string,
    classNames: PropTypes.string,
    isDisabled: PropTypes.bool,
    shouldRenderSuggestions: PropTypes.func,
    handleChange: PropTypes.func,
    selections: PropTypes.array,
    renderInputComponent: PropTypes.func
};

const defaultProps = {
    placeholder: '',
    classNames: '',
    shouldRenderSuggestions: () => true,
    isDisabled: false,
    handleChange: (element) => element,
    selections: [],
    renderInputComponent: undefined
};