import {shipmentInspectionsConstants} from "../constants/shipmentInspections.constants";


const initialState = {
    inspections: [],
    loading: false,
    inspection: null
};

export const shipmentInspectionsReducer = (state = initialState, action) => {
    switch (action.type) {
        case shipmentInspectionsConstants.CREATE_SHIPMENT_INSPECTION_FULFILLED:
            state = {
                ...state,
                inspections: [...state.inspections, action.payload]
            };
            return state;
        case shipmentInspectionsConstants.GET_SHIPMENT_INSPECTION_FULFILLED:
            state = {
                ...state,
                inspection: action.payload
            };
            return state;
        case shipmentInspectionsConstants.QUERY_SHIPMENT_INSPECTIONS_DATA_FULFILLED:
            state = {
                ...state,
                inspections: action.payload
            };
            return state;
        case shipmentInspectionsConstants.DELETE_SHIPMENT_INSPECTION_FULFILLED:
            state = {
                ...state,
                inspections: state.inspections.filter(inspection=>inspection.id !== action.payload.id),
            };
            return state;
        case shipmentInspectionsConstants.UPDATE_SHIPMENT_INSPECTION_FULFILLED:
            state = {
                ...state,
                inspections: state.inspections.map(inspection =>
                    inspection.id === action.payload.id ? action.payload : inspection
                ),
            };
            return state;
        default:
            return state;
    }
};