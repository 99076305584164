import React from 'react';
import axios from 'axios';
import Autosuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import {Field} from 'redux-form';
import * as sensorApi from '../../api/sensorApi.js';
import * as gatewayApi from '../../api/gatewayApi.js';
import * as $ from 'jquery';
import store from '../../store/store';
//import './destinationSearchBar.scss';

export default class SearchSensor extends React.Component {
    state = {
        value: '',
        selectedSuggestion: null,
        loading: false,
        destinationsList: []
    };

    lastRequestId = null;

    static contextTypes = {
        router: PropTypes.object
    };

    static propTypes = {
        size: PropTypes.string,
        className: PropTypes.string,
        placeholder: PropTypes.string,
        shouldTakeValue: PropTypes.bool
    };

    static defaultProps = {
        size: 'sm',
        className: '',
        placeholder: 'New York, NY',
        shouldTakeValue: false
    };

    constructor (props) {
        super(props);

        this.onInputChange = this.onInputChange.bind(this);
        this.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested.bind(this);
        this.onSuggestionsClearRequested = this.onSuggestionsClearRequested.bind(this);
        this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
        //this.dropdownData = this.dropdownData.bind(this);
        //this.onSubmitClicked = this.onSubmitClicked.bind(this);
        //this.onKeyDown = this.onKeyDown.bind(this);
    }

    defineValue (newValue) {
        this.setState({
            value: newValue
        });
    }

    componentDidMount () {
        if (!this.props.shouldTakeValue) return;

        const routerParams = this.context.router.params;
        if (routerParams && routerParams.locationName) {
            const isSameValue = routerParams.locationName === this.state.value;

            if (isSameValue) return;

            this.defineValue(routerParams.locationName);
        }
    }

    async fetchSuggestions (param) {
        try {
            if (this.lastRequestId) {
                this.lastRequestId.cancel('Operation canceled by the user.');
            }

            this.setState({ loading: true });
            let destinationsList;
            let state;
            if (this.props.type === 'sensor') {
                destinationsList = await sensorApi.searchSensorData({name: param});
                state = store.getState();
                state.sensorReducer.groupList = destinationsList;
            }else{
                destinationsList = await gatewayApi.searchGatewayData({name: param});
                state = store.getState();
                state.gatewayReducer.groupList = destinationsList;
            }
            console.log(destinationsList);
            this.setState({
                loading: false,
                destinationsList: Object.keys(destinationsList)
            });

            this.lastRequestId = null;
        } catch (e) {
            if (!axios.isCancel(e)) {
                this.setState({ loading: false });
            }
        }
    }

    onInputChange (e, { newValue }) {
        let state = store.getState();
        //state.eventsReducer.event.location = newValue;
        this.setState({
            value: newValue,
            selectedSuggestion: null
        });
    }

    dropdownData(title) {
        return (
            <div className="emp_create_notify_box">
                <div className="card border-0">
                    <div className="card-body">
                        <div className="media align-items-center">
                            <div className="emp_user mr-3">
                                <img className="rounded-circle" src={require("../../styles/images/icon/ic_gateway.png")} alt="Generic placeholder image" />
                            </div>
                            <div className="media-body">
                                <h6 className="mt-0 mb-0">{title}</h6>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
    //onKeyDown (e) {
    //    if (e.keyCode === 13 && this.state.selectedSuggestion) {
    //        this.onSubmitClicked();
    //    }
    //}

    onSuggestionSelected (e, { suggestion }) {
        let state = store.getState();
        this.props.handleClick(suggestion);
       // state.eventsReducer.event.location = suggestion.formattedAddress;
        this.setState({ selectedSuggestion: suggestion });
    }
    onSuggestionsFetchRequested ({ value }) {
        const LOCATION_REGEXP = /^[A-Za-z0-9\s]*$/;

        if (value === '' || !LOCATION_REGEXP.test(value)) return;

        this.fetchSuggestions(value);
    }

    onSuggestionsClearRequested () {
        this.setState({ destinationsList: [], loading: false });
    }

    //onSubmitClicked () {
    //    let { selectedSuggestion } = this.state;
    //
    //    if (selectedSuggestion === null) return;
    //
    //    let { id, name, formattedAddress } = selectedSuggestion;
    //
    //
    //    this.context.router.push(`/destination/${formattedAddress}/${id}`);
    //}

    renderSuggestionItem (suggestion) {
        return (
            <div className="">
                <div className="card border-0">
                    <div className="card-body p-1">
                        <div className="media align-items-center">
                            {/*<div className="emp_user mr-3">
                                <img className="rounded-circle" src={require("../../styles/images/icon/ic_gateway.png")} alt="Generic placeholder image" />
                            </div>*/}
                            <div className="media-body">
                                <h6 className="mt-0 mb-0">{suggestion}</h6>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    //renderSubmitBtn () {
    //    return (
    //        <button
    //            onClick={this.onSubmitClicked}
    //            type="button"
    //            className="btn btn-primary btn-submit">
    //            Go Do
    //        </button>
    //    );
    //}

    getSuggestionValue(suggestion) {
        return suggestion;
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.location && nextProps.location.name && !this.state.value) {

            let value = nextProps.location ? nextProps.location.name : '';
            this.setState({value: value});
        }

    }

    render () {

        const { name,size, className, placeholder } = this.props;
        const { value, destinationsList, loading, selectedSuggestion } = this.state;

        const inputProps = {
            placeholder: placeholder,
            value: value,
            type: 'search',
            name: name,
            className: `form-control search-field`,
            onChange: this.onInputChange,
            onKeyDown: this.onKeyDown,
            onClick: event => event.target.select()
        };
        return (
            <div className={`app-search__input w-100  p-0 destination-search-bar--${size} ${className}`}>

                <Autosuggest
                    inputProps={inputProps}
                    suggestions={destinationsList}
                    highlightFirstSuggestion={true}
                    onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                    onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                    onSuggestionSelected={this.onSuggestionSelected}
                    getSuggestionValue={this.getSuggestionValue}
                    renderSuggestion={this.renderSuggestionItem}
                    />
                <button className="app-search__button"><i className="fa fa-search text-primary"></i></button>

            </div>
        );
    }
}