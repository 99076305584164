export const workflowScheduleConstants = {
    LIST_WORKFLOW_SCHEDULES: 'LIST_WORKFLOW_SCHEDULES',
    LIST_WORKFLOW_SCHEDULES_FULFILLED: 'LIST_WORKFLOW_SCHEDULES_FULFILLED',

    CREATE_WORKFLOW_SCHEDULE: 'CREATE_WORKFLOW_SCHEDULE',
    CREATE_WORKFLOW_SCHEDULE_FULFILLED: 'CREATE_WORKFLOW_SCHEDULE_FULFILLED',

    CREATE_WORKFLOW_SCHEDULE_RUN: 'CREATE_WORKFLOW_SCHEDULE_RUN',
    CREATE_WORKFLOW_SCHEDULE_RUN_FULFILLED: 'CREATE_WORKFLOW_SCHEDULE_RUN_FULFILLED',

    UPDATE_WORKFLOW_SCHEDULE: 'UPDATE_WORKFLOW_SCHEDULE',
    UPDATE_WORKFLOW_SCHEDULE_FULFILLED: 'UPDATE_WORKFLOW_SCHEDULE_FULFILLED',

    DELETE_WORKFLOW_SCHEDULE: 'DELETE_WORKFLOW_SCHEDULE',
    DELETE_WORKFLOW_SCHEDULE_FULFILLED: 'DELETE_WORKFLOW_SCHEDULE_FULFILLED',

    SELECT_WORKFLOW_SCHEDULE: 'SELECT_WORKFLOW_SCHEDULE',

    GET_WORKFLOW_SCHEDULE: 'GET_WORKFLOW_SCHEDULE',
    GET_WORKFLOW_SCHEDULE_FULFILLED: 'GET_WORKFLOW_SCHEDULE_FULFILLED',
};