/**
 * POJO representing a feature flag treatment.
 */
export default class ExperimentTreatment {

    clientId;
    identifier;
    featureFlagId;
    treatment;
    payload;
    constructor(clientId, identifier, featureFlagId, treatment, payload) {
        this.clientId = clientId;
        this.identifier = identifier;
        this.featureFlagId = featureFlagId;
        this.treatment = treatment;
        this.payload = payload;
    }
}