import React, {Component} from 'react';

// Component that shows children with an edit icon.  
// On edit icon click, inline form with props.value is shown for editing.
class EditableInlineField extends Component<{
    value: string,
    onUpdate: (id: any, value: string) => void,
    id?: string,
    children: any,
},{
    showForm: boolean,
    workValue: string
}> {
    
    constructor(props) {
        super(props);
        this.state = {
            showForm: props.showForm || false,
            workValue: ''
        }
        this.onEditHandler = this.onEditHandler.bind(this)
        this.onSaveHandler = this.onSaveHandler.bind(this)
        this.onCancelHandler = this.onCancelHandler.bind(this)
        this.onChangeHandler = this.onChangeHandler.bind(this);
    }
    
    onEditHandler() {
        this.setState({workValue: this.props.value, showForm: true});
    }

    onSaveHandler() {
        this.setState({...this.state, showForm: false});
        this.props.onUpdate(this.props.id, this.state.workValue)
    }

    onCancelHandler() {
        this.setState({...this.state, showForm: false});
    }  

    onChangeHandler(event) {
        this.setState({...this.state, workValue: event.target.value})
    }

    render() {
        if (this.state.showForm) return this.renderForm()
        return (
            <React.Fragment>
                { this.props.children } 
                <a onClick={this.onEditHandler}>
                    <i className="fa fa-pencil" style={{marginLeft: '10px'}}></i> 
                </a>
            </React.Fragment>
        )        
    }

    renderForm() {
        return (
            <React.Fragment>
                <input type="text" id={this.props.id} value={this.state.workValue} 
                    onChange={ event => this.onChangeHandler(event) } />
                <a onClick={this.onSaveHandler}>
                    <i className="fa fa-check" style={{marginLeft: '10px'}}></i> 
                </a>
                <a onClick={this.onCancelHandler}>
                    <i className="fa fa-times" style={{marginLeft: '10px'}}></i> 
                </a>                     
            </React.Fragment>
        )
    }
}

export default EditableInlineField;
