import {TextField} from "../../ui/Input/TextField";
import React from "react";

type Props = {
    onChange?:(item: string) => void,
    label: string
}

type State = {
    value: string
}
export class DeviceSearchInputForm extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        }
    }

    render() {
        const {onChange, label} = this.props;
        return <>
            <TextField label={label} value={this.state.value} type={"search"} onChange={(e) => {
                if (onChange) {
                    this.setState({value: e.target.value});
                    onChange(e.target.value);
                }
            }}/>
        </>
    }
}