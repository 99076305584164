export class FailureModeModel {
    id;
    title;
    description;
    updated_at;
    created_at;
    tenant_id;

    constructor(id, title, description, updated_at, created_at, tenant_id) {
        this.id = id;
        this.title = title;
        this.description = description;
        this.updated_at = updated_at;
        this.created_at = created_at;
        this.tenant_id = tenant_id;
    }

    static fromJson(json) {
        return new FailureModeModel(json['id'],
            json['title'],
            json['description'],
            json['updated_at'],
            json['created_at'],
            json['tenant_id'],
        )
    }
}