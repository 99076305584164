import React from 'react';
import {ScheduledJobStatus} from "../../api/model/ScheduledJob";


export function getTagColorByType(status) {
    switch (status) {
        case ScheduledJobStatus.UNSUBMITTED:
            return "emp-tag-yellow";
        case ScheduledJobStatus.RUNNING:
            return "emp-tag-geekblue";
        case ScheduledJobStatus.COMPLETED:
            return "emp-tag-green";
        case ScheduledJobStatus.FAILED:
            return "emp-tag-red";
        default:
            console.log("scheduled job status not expected");
            return "";
    }
}

export default function ScheduledJobStatusTag(props) {
    if (!props.status || !props.status.length) return <div/>;

    return (
        <div className={`emp-tag ${getTagColorByType(props.status)}`}>
            {props.status}
        </div>
    )
}