import React from 'react';
import AssetTypeForm from "./AssetTypeForm";


export class AssetTypeEditDetailPage extends React.Component {
    render() {
        return (
            <main className="app-content">
                <section className="emp_org_settings_sec emp_report_sec emp_circle_box_sec">
                    <div className="card emp-box emp-shadow">
                        <div className={"bg-white container-fluid mb-4"}>
                            <AssetTypeForm onSubmit={this.props.onSubmit.bind(this)}/>
                        </div>
                    </div>
                </section>
            </main>
        );
    }
}