export const deviceConstants = {
    DEVICE: 'DEVICE',
    DEVICE_FULFILLED: 'DEVICE_FULFILLED',

    ACTIVATE_DEVICE: 'ACTIVATE_DEVICE',
    ACTIVATE_DEVICE_FULFILLED: 'ACTIVATE_DEVICE_FULFILLED',

    DEACTIVATE_DEVICE: 'DEACTIVATE_DEVICE',
    DEACTIVATE_DEVICE_FULFILLED: 'DEACTIVATE_DEVICE_FULFILLED',

    FETCH_DEVICE_DATA: 'FETCH_DEVICE_DATA',
    FETCH_DEVICE_DATA_FULFILLED: 'FETCH_DEVICE_DATA_FULFILLED',

    GET_DEVICE: 'GET_DEVICE',
    GET_DEVICE_FULFILLED: 'GET_DEVICE_FULFILLED',

    SEARCH_DEVICE_DATA: 'SEARCH_DEVICE',
    SEARCH_DEVICE_DATA_FULFILLED: 'SEARCH_DEVICE_FULFILLED',

    UPDATE_DEVICE: 'UPDATE_DEVICE',
    UPDATE_DEVICE_FULFILLED: 'UPDATE_DEVICE_FULFILLED'
};