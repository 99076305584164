import axios from 'axios';
import storage from "../utils/storage";
import {BASE_URL} from '../config';


const convertFormToApiRequest = (values) => {
    return {
        name: values.name,
        address: {
            city: values['address']['city'],
            address1: values['address']['address1'],
            zip: values['address']['zip'],
            state: values['address']['state'],

        },
        system_of_units: values['system_of_units'],
        ...((values.logo_image && values.logo_image.length) && {logo_type: "domain"}),
        ...((values.logo_image && values.logo_image.length) && {logo: values.logo_image[0]}),
    }
}


export const updateGeneralSetting = (values) => {
    const formData = new FormData();

    const request = convertFormToApiRequest(values['organization']);

    Object.keys(request).forEach(key => {
        if (typeof request[key] === 'object' && key !== 'logo') {
            // Nested keys
            Object.keys(request[key]).forEach(nestedKey => {
                formData.append(`organization[${key}][${nestedKey}]`, request[key][nestedKey]);
            });
        } else {
            formData.append(`organization[${key}]`, request[key]);
        }
    });

    return axios.put(`${BASE_URL()}/api/v1/organization`, formData,{ headers: {
        'Accept': 'application/json',
        'Authorization': storage.get('token')
    }}).then(response => {
        return response.data;
    });

};
export const fetchOrganizationData = (values) => {
    return axios.get(`${BASE_URL()}/api/v1/organization`, { headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': storage.get('token')
    }}).then(response => {
        return response.data;
    });

};
