export const assetConstants = {
    GET_ASSET: 'GET_ASSET',
    GET_ASSET_FULFILLED: 'GET_ASSET_FULFILLED',

    LIST_ASSETS: 'LIST_ASSETS',
    LIST_ASSETS_FULFILLED: 'LIST_ASSETS_FULFILLED',

    CREATE_ASSET: 'CREATE_ASSET',
    CREATE_ASSET_FULFILLED: 'CREATE_ASSET_FULFILLED',

    DELETE_ASSET: 'DELETE_ASSET',
    DELETE_ASSET_FULFILLED: 'DELETE_ASSET_FULFILLED',

    UPDATE_ASSET: 'UPDATE_ASSET',
    UPDATE_ASSET_FULFILLED: 'UPDATE_ASSET_FULFILLED',

    RESET_ASSET: 'RESET_ASSET',
    RESET_ASSET_FULFILLED: 'RESET_ASSET_FULFILLED'
};